import React from "react";
import { Row } from "antd";
import { DownOutlined, ExpandAltOutlined, UpOutlined } from "@ant-design/icons";
const pointer = {
    x: 0,
    y: 0,
    pageX: 0,
    pageY: 0
};
export const PointerContext = React.createContext({ pointer });
export default class SubView extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            pointer: { x: 0, y: 0, pageX: 0, pageY: 0 },
            toggle: true
        };
        this.isResize = false;
        this.onMouseMove = (e) => {
            const x = e.pageX - this.boundingRect.left;
            const y = e.pageY - this.boundingRect.top;
            this.setState({
                pointer: { x, y, pageX: e.pageX, pageY: e.pageY }
            });
        };
        this.onMouseDown = (e) => {
            this.isResize = true;
            const saved = Object.assign({}, this.props);
            const start = {
                x: e.pageX,
                y: e.pageY
            };
            const onMouseMove = (e) => {
                const current = { x: e.pageX, y: e.pageY };
                const deltaX = current.x - start.x;
                const deltaY = current.y - start.y;
                const delta = deltaX > 0 ? Math.min(deltaX, deltaY) : Math.max(deltaX, deltaY);
                const width = saved.width + delta;
                const height = saved.height + delta;
                this.props.onResize(width, width, saved.left, saved.top);
            };
            const onMouseUp = (e) => {
                this.isResize = false;
                window.removeEventListener("mousemove", onMouseMove);
                window.removeEventListener("mouseup", onMouseUp);
            };
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
        };
    }
    componentDidMount() {
        this.reCalc();
        window.addEventListener("mousemove", this.onMouseMove);
    }
    componentWillUnmount() {
        window.removeEventListener("mousemove", this.onMouseMove);
    }
    componentWillReceiveProps(nextProps) {
        if ((nextProps.width && nextProps.width !== this.props.width) ||
            (nextProps.height && nextProps.height !== this.props.height) ||
            (nextProps.left && nextProps.left !== this.props.left) ||
            (nextProps.top && nextProps.top !== this.props.top)) {
            this.reCalc();
        }
    }
    reCalc() {
        this.boundingRect = this.container.getBoundingClientRect();
    }
    render() {
        const { canvasRef, containerRef, children, title, width, height, left, top, zIndex } = this.props;
        const { pointer, toggle } = this.state;
        return (<PointerContext.Provider value={{ pointer }}>
        <div className="sub-view" style={{ left, top, width, zIndex }} ref={el => {
            this.container = el;
            containerRef.current = el;
        }}>
          <div className="header" onMouseDown={e => {
            if (e.button === 0) {
                const start = { x: e.pageX, y: e.pageY };
                const prevPosition = {
                    left,
                    top
                };
                this.props.onSelect();
                const onMouseMove = (e) => {
                    const deltaX = e.pageX - start.x;
                    const deltaY = e.pageY - start.y;
                    this.props.onReposition(prevPosition.left + deltaX, prevPosition.top + deltaY);
                };
                const onMouseUp = (e) => {
                    window.removeEventListener("mousemove", onMouseMove);
                    window.removeEventListener("mouseup", onMouseUp);
                };
                window.addEventListener("mousemove", onMouseMove);
                window.addEventListener("mouseup", onMouseUp);
            }
        }}>
            <Row justify="space-between" align="middle">
              <h2>{title}</h2>
              <div style={{ cursor: "pointer" }} onMouseDown={() => {
            this.setState({ toggle: !toggle });
        }}>
                {toggle ? <UpOutlined style={{ color: "#fff" }}/> : <DownOutlined style={{ color: "#fff" }}/>}
              </div>
            </Row>
          </div>
          <div className="content" style={{ height: toggle ? height : 0, overflow: "hidden" }}>
            <canvas ref={canvasRef}></canvas>
            {children}
          </div>
          {toggle && (<React.Fragment>
              
              <ExpandAltOutlined onMouseDown={this.onMouseDown} className="knob" style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            color: "#fff",
            transform: "rotate(90deg)",
            cursor: "nwse-resize"
        }}/>
            </React.Fragment>)}
        </div>
      </PointerContext.Provider>);
    }
}
