import * as React from "react";
import { Layout, Menu, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { routerStore } from "../../store/routerStore";
import { appStore } from "../../store/AppStore";
import { Notify } from "./component/Notify/Notify";
import "./ManageLayout.less";
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const items = [
    {
        key: "hc-portal",
        label: (<a target="_blank" rel="noopener noreferrer" href="https://atom.supremind.com/documents/label-x.html">
        帮助中心
      </a>)
    },
    {
        key: "notify",
        label: <Notify />
    }
];
var SiderMenuKey;
(function (SiderMenuKey) {
    SiderMenuKey["LabelTask"] = "label_tasks";
    SiderMenuKey["LabelPackages"] = "label_packages";
    SiderMenuKey["LabelSandPackages"] = "label_sand_packages";
    SiderMenuKey["LabellingAssessmentPolicies"] = "labelling_assessment_policies";
    SiderMenuKey["TaskTemplate"] = "task_templates";
    SiderMenuKey["RoleManage"] = "admin/role_manage";
    SiderMenuKey["Account"] = "account";
    SiderMenuKey["TaskBusiness"] = "admin/task_businesses";
    SiderMenuKey["DailyAudit"] = "admin/daily_audit";
    SiderMenuKey["CompositeAudit"] = "admin/composite_audit";
    SiderMenuKey["PerformanceAudit"] = "admin/performance_audit";
    SiderMenuKey["RankAudit"] = "admin/rank_audit";
    SiderMenuKey["LabelSandBags"] = "label_sand_bags";
    SiderMenuKey["DailyWork"] = "daily_works";
    SiderMenuKey["SegmentTask"] = "segment_tasks";
})(SiderMenuKey || (SiderMenuKey = {}));
var SiderSubMenuKey;
(function (SiderSubMenuKey) {
    SiderSubMenuKey["SuperManage"] = "super_manage";
    SiderSubMenuKey["SelfWorkspace"] = "self_workspace";
    SiderSubMenuKey["AssignPackage"] = "assign_package";
    SiderSubMenuKey["AuditWorkload"] = "audit_workload";
    SiderSubMenuKey["AssessmentSolution"] = "assessment_solution";
})(SiderSubMenuKey || (SiderSubMenuKey = {}));
export class ManageLayout extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedKey: null,
            openKeys: []
        };
    }
    componentDidMount() {
        let selectedKey = null;
        let openKeys = [];
        const pathname = routerStore.location.pathname;
        if (new RegExp(/^\/manage\/label_task/).test(pathname)) {
            selectedKey = SiderMenuKey.LabelTask;
            openKeys = [SiderSubMenuKey.AssignPackage];
        }
        else if (new RegExp(/^\/manage\/label_package/).test(pathname)) {
            selectedKey = SiderMenuKey.LabelPackages;
            openKeys = [SiderSubMenuKey.SelfWorkspace];
        }
        else if (new RegExp(/^\/manage\/label_sand_package/).test(pathname)) {
            selectedKey = SiderMenuKey.LabelSandPackages;
            openKeys = [SiderSubMenuKey.AssignPackage, SiderSubMenuKey.AssessmentSolution];
        }
        else if (new RegExp(/^\/manage\/labelling_assessment_polic/).test(pathname)) {
            selectedKey = SiderMenuKey.LabellingAssessmentPolicies;
            openKeys = [SiderSubMenuKey.AssignPackage, SiderSubMenuKey.AssessmentSolution];
        }
        else if (new RegExp(/^\/manage\/task_template/).test(pathname)) {
            selectedKey = SiderMenuKey.TaskTemplate;
            openKeys = [SiderSubMenuKey.AssignPackage];
        }
        else if (new RegExp(/^\/manage\/admin\/role_manage/).test(pathname)) {
            selectedKey = SiderMenuKey.RoleManage;
            openKeys = [SiderSubMenuKey.SuperManage];
        }
        else if (new RegExp(/^\/manage\/admin\/task_businesses/).test(pathname)) {
            selectedKey = SiderMenuKey.TaskBusiness;
            openKeys = [SiderSubMenuKey.SuperManage];
        }
        else if (new RegExp(/^\/manage\/account/).test(pathname)) {
            selectedKey = SiderMenuKey.Account;
            openKeys = [SiderSubMenuKey.SelfWorkspace];
        }
        else if (new RegExp(/^\/manage\/daily_work/).test(pathname)) {
            selectedKey = SiderMenuKey.DailyWork;
            openKeys = [SiderSubMenuKey.SelfWorkspace];
        }
        else if (new RegExp(/^\/manage\/admin\/daily_audit/).test(pathname)) {
            selectedKey = SiderMenuKey.DailyAudit;
            openKeys = [SiderSubMenuKey.SuperManage, SiderSubMenuKey.AuditWorkload];
        }
        else if (new RegExp(/^\/manage\/admin\/composite_audit/).test(pathname)) {
            selectedKey = SiderMenuKey.CompositeAudit;
            openKeys = [SiderSubMenuKey.SuperManage, SiderSubMenuKey.AuditWorkload];
        }
        else if (new RegExp(/^\/manage\/admin\/performance_audit/).test(pathname)) {
            selectedKey = SiderMenuKey.PerformanceAudit;
            openKeys = [SiderSubMenuKey.SuperManage, SiderSubMenuKey.AuditWorkload];
        }
        else if (new RegExp(/^\/manage\/admin\/rank_audit/).test(pathname)) {
            selectedKey = SiderMenuKey.RankAudit;
            openKeys = [SiderSubMenuKey.SuperManage, SiderSubMenuKey.AuditWorkload];
        }
        else if (new RegExp(/^\/manage\/label_sand_bag/).test(pathname)) {
            selectedKey = SiderMenuKey.LabelSandBags;
            openKeys = [SiderSubMenuKey.AssignPackage, SiderSubMenuKey.AssessmentSolution];
        }
        else if (new RegExp(/^\/manage\/segment_task/).test(pathname)) {
            selectedKey = SiderMenuKey.SegmentTask;
            openKeys = [SiderSubMenuKey.AssignPackage];
        }
        this.setState({
            selectedKey: selectedKey,
            openKeys: openKeys
        });
    }
    handleSiderClick(key) {
        this.setState({ selectedKey: key });
        routerStore.push(`/manage/${key}`);
    }
    render() {
        var _a;
        const { isSystemAdmin, isGroupAdmin } = appStore.status.loginUser;
        return (<Layout className="manage-layout">
        <Sider className="manage-main-sider">
          <div className="product-name">
            <img src={require("../../asset/La.svg")} width={190} alt=""/>
          </div>
          <Menu style={{ background: "#FFFFFF" }} mode="inline" selectedKeys={[this.state.selectedKey]} openKeys={this.state.openKeys} onOpenChange={openKeys => {
            this.setState({
                openKeys: openKeys
            });
        }} onClick={param => {
            this.handleSiderClick(param.key);
        }}>
            {appStore.status.loginUser.permissions.includes("la:work:using") && (<SubMenu key={SiderSubMenuKey.SelfWorkspace} title="我的工作">
                <Menu.Item key={SiderMenuKey.DailyWork}>每日工作</Menu.Item>
                <Menu.Item key={SiderMenuKey.LabelPackages}>分包标注</Menu.Item>
              </SubMenu>)}
            {isGroupAdmin && (<SubMenu key={SiderSubMenuKey.AssignPackage} title="发包工作">
                <Menu.Item key={SiderMenuKey.LabelTask}>标注任务</Menu.Item>
                
                <Menu.Item key={SiderMenuKey.TaskTemplate}>任务模板</Menu.Item>
                <SubMenu key={SiderSubMenuKey.AssessmentSolution} title="评估方案">
                  <Menu.Item key={SiderMenuKey.LabelSandBags}>沙子库</Menu.Item>
                  <Menu.Item key={SiderMenuKey.LabellingAssessmentPolicies}>评估方法库</Menu.Item>
                </SubMenu>
                
              </SubMenu>)}
            {isSystemAdmin && (<SubMenu key={SiderSubMenuKey.SuperManage} title="系统管理">
                <SubMenu key={SiderSubMenuKey.AuditWorkload} title="工作绩效管理">
                  <Menu.Item key={SiderMenuKey.DailyAudit}>每日巡检</Menu.Item>
                  <Menu.Item key={SiderMenuKey.CompositeAudit}>综合统计</Menu.Item>
                  
                  <Menu.Item key={SiderMenuKey.RankAudit}>绩效统计</Menu.Item>
                </SubMenu>
                <Menu.Item key={SiderMenuKey.TaskBusiness}>计价类型管理</Menu.Item>
              </SubMenu>)}
          </Menu>
        </Sider>
        <Layout className="manage-main">
          <Header className="manage-header">
            <div>
              <Dropdown.Button menu={{ items }} icon={<UserOutlined />}>
                {((_a = appStore.status.loginUser) === null || _a === void 0 ? void 0 : _a.name) || "未登录"}
              </Dropdown.Button>
            </div>
          </Header>
          <Content className="manage-main-content">{this.props.children}</Content>
        </Layout>
      </Layout>);
    }
}
