var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { imageLabelMultiStore as store, imageLabelMultiStore } from "../../ImageLabelMultiStore";
import { Card, Divider, Menu, Tag } from "antd";
import { observer } from "mobx-react";
import { ClassificationTool } from "../../../../../../definition/entity/label-task";
import { toJS } from "mobx";
import Asset from "../../../asset";
import "./PainterWrapper.less";
let PainterWrapper = class PainterWrapper extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            curResource: null,
            showContextMenu: false,
            contextMenuPosition: {
                left: 0,
                top: 0
            }
        };
    }
    closeContextMenu() {
        this.setState({
            showContextMenu: false
        });
    }
    handleClickCard(resource) {
        const { curClassification } = imageLabelMultiStore.status;
        if (curClassification) {
            imageLabelMultiStore.updateResourceClassification(resource, {
                key: curClassification.key,
                tool: curClassification.tool,
                value: curClassification.value
            });
        }
    }
    handleResetClassification(resource, classification) {
        imageLabelMultiStore.updateResourceClassification(resource, {
            key: classification.key,
            tool: classification.tool,
            value: classification.value
        });
    }
    render() {
        const status = imageLabelMultiStore.status;
        return (<div className="multi-painter-wrapper-component" style={{
            display: "grid",
            gridTemplateColumns: `repeat(${status.columnNum}, calc((100% - ${(status.columnNum - 1) * 0.2}rem) / ${status.columnNum}))`,
            gridGap: "0.2rem"
        }} onClick={() => this.closeContextMenu()}>
        {status.resources.map((resource, index) => {
            var _a, _b, _c;
            return (<Card key={resource.id} className="resource-card" hoverable={true} onClick={() => {
                this.handleClickCard(resource);
            }} onContextMenu={event => {
                this.setState({
                    showContextMenu: true,
                    curResource: resource,
                    contextMenuPosition: {
                        left: event.pageX,
                        top: event.pageY
                    }
                });
                event.preventDefault();
            }}>
              {status.scale !== 0 ? (<div className="image-container" style={{
                width: "100%",
                overflow: "auto"
            }}>
                  <img alt="" style={{
                zoom: `${status.scale}`
            }} src={resource.url.replaceAll("#", "%23")}/>
                </div>) : (<div className="image-container" style={{
                backgroundImage: `url("${resource.url.replaceAll("#", "%23")}")`
            }}></div>)}
              <Divider style={{ margin: "4px 0" }}/>
              <div className="label-container">
                {_.isArray(toJS((_a = resource.annotation) === null || _a === void 0 ? void 0 : _a.classifications)) && ((_c = (_b = resource.annotation) === null || _b === void 0 ? void 0 : _b.classifications) === null || _c === void 0 ? void 0 : _c.map(classification => {
                const matchCls = _.find(status.selectableLabel.classifications, item => item.key === classification.key);
                if (!matchCls) {
                    return;
                }
                if (matchCls.tool === ClassificationTool.Switch) {
                    if (classification.value === true) {
                        return (<Tag key={classification.key} className="label-tag" closable={true} onClose={() => {
                            this.handleResetClassification(resource, {
                                key: classification.key,
                                tool: classification.tool,
                                value: false
                            });
                        }}>
                            {matchCls.locale || classification.key}
                          </Tag>);
                    }
                }
                else {
                    const option = _.find(matchCls.options, option => option.key === classification.value);
                    if (option) {
                        return (<Tag key={classification.key} className="label-tag" closable={true} color={option.color} onClose={() => {
                            this.handleResetClassification(resource, {
                                key: classification.key,
                                tool: classification.tool,
                                value: null
                            });
                        }}>
                            {option.locale || option.key}
                          </Tag>);
                    }
                }
            }))}
              </div>
            </Card>);
        })}
        {this.state.showContextMenu && (<Menu className="painter-wrapper-contextmenu" style={{
            position: "fixed",
            left: this.state.contextMenuPosition.left,
            top: this.state.contextMenuPosition.top,
            borderRadius: "5px"
        }} onClick={param => {
            var _a, _b;
            switch (param.key) {
                case "view-image": {
                    if ((_a = this.state.curResource) === null || _a === void 0 ? void 0 : _a.url) {
                        window.open((_b = this.state.curResource) === null || _b === void 0 ? void 0 : _b.url, "_blank");
                    }
                }
            }
        }}>
            <Menu.Item key="view-image">查看原图</Menu.Item>
          </Menu>)}
        {store.status.isLoading && (<div style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#abababcf"
        }}>
            <img alt="" style={{
            width: "50%",
            height: "50%",
            transform: "translate(50%, 50%)"
        }} src={Asset.loadingSVG}/>
          </div>)}
      </div>);
    }
};
PainterWrapper = __decorate([
    observer
], PainterWrapper);
export { PainterWrapper };
