import * as React from "react";
import { Modal } from "antd";
import moment from "moment";
import "./Timeline.less";
export class Timeline extends React.Component {
    render() {
        const { timeline, onCancel } = this.props;
        return (<Modal className="time-line-modal" width="50vw" title="时间线" open={true} footer={null} onCancel={() => onCancel()}>
        {timeline ? (timeline.map(item => {
            const [timeStr, actionStr] = item;
            return (<div key={timeStr} className="timeline">
                <div className="time">{moment(timeStr).format("YYYY 年 MM 月 DD 日 HH 时 mm 分")}</div>
                <div className="action" dangerouslySetInnerHTML={{ __html: actionStr }}></div>
              </div>);
        })) : (<div>暂无数据</div>)}
      </Modal>);
    }
}
