var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as _ from "lodash";
import { observable, toJS } from "mobx";
export class KeymapStore {
    constructor() {
        this.keymap = [];
    }
    trigger(e) {
        const keymapItem = _.find(toJS(this.keymap), item => item.key === e.key);
        if (keymapItem) {
            keymapItem.action();
        }
    }
    register(keymapItem) {
        const { key, actionTag, action } = keymapItem;
        _.remove(this.keymap, item => item.actionTag === actionTag || item.key === key);
        this.keymap.push({
            key: key,
            actionTag: actionTag,
            action: action
        });
    }
    getKeyByActionTag(actionTag) {
        const item = _.find(this.keymap, item => item.actionTag === actionTag);
        return item ? item.key : "";
    }
    releaseAll() {
        this.keymap = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], KeymapStore.prototype, "keymap", void 0);
export const keymapStore = new KeymapStore();
