import * as _ from "lodash";
import { BasePainter } from "./base-painter";
import { imageLabelStore } from "../../../ImageLabelStore";
import { DetectionTool } from "../../../../../../../definition/entity/label-task";
import { PainterDom } from "../../../provider/painter-dom";
import { PainterMode } from "../../../../interface/common";
export class PathPainter extends BasePainter {
    Draw_MouseDown(event) {
        var _a;
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel, creatingElement } = status;
        if (!(curLabel.tool === DetectionTool.Path && curLabel.type === "detection"))
            return;
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        if (_.isEmpty(creatingElement)) {
            status.creatingElement = {
                id: PainterDom.generateId(curLabel.key),
                label: curLabel,
                visible: true,
                attributes: {
                    points: [[offsetX, offsetY]],
                    nextPos: null,
                    isClosed: false
                },
                detection: {
                    key: curLabel.key,
                    value: null,
                    tool: DetectionTool.Path,
                    classifications: curLabel.classifications.map(cls => ({ key: cls.key, value: cls.defaultValue })),
                    trails: []
                }
            };
        }
        else {
            if (curLabel.setPoint &&
                ((_a = creatingElement.attributes) === null || _a === void 0 ? void 0 : _a.points.length) === (curLabel === null || curLabel === void 0 ? void 0 : curLabel.points)) {
                creatingElement.attributes.isClosed = true;
                creatingElement.attributes.nextPos = null;
                status.layerElements.push(status.creatingElement);
                status.creatingElement = null;
            }
            else {
                Object.assign(creatingElement.attributes, {
                    points: creatingElement.attributes.points.concat([[offsetX, offsetY]])
                });
            }
        }
    }
    Draw_MouseMove(event) {
        if (!this.isValidCreatingEle(DetectionTool.Path, "detection"))
            return;
        const status = imageLabelStore.status;
        const { creatingElement } = status;
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        Object.assign(creatingElement.attributes, {
            nextPos: { x: offsetX, y: offsetY }
        });
    }
    Draw_ContextMenu(event) {
        var _a;
        if (!this.isValidCreatingEle(DetectionTool.Path, "detection"))
            return;
        const status = imageLabelStore.status;
        const attributes = status.creatingElement.attributes;
        if (!attributes.isClosed && attributes.points.length > 1) {
            if (status.curSelectedLabel.setPoint && attributes.points.length !== ((_a = status.curSelectedLabel) === null || _a === void 0 ? void 0 : _a.points))
                return;
            attributes.isClosed = true;
            attributes.nextPos = null;
            status.layerElements.push(status.creatingElement);
            status.creatingElement = null;
        }
    }
    Resize_MouseUp(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Path, "detection"))
            return;
        const { status, selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        element.initAttribute.selectedPointIdx = null;
        status.mode = PainterMode.UNSET;
    }
    Resize_MouseMove(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Path, "detection"))
            return;
        const { selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        const attributes = element.attributes;
        attributes.points[element.initAttribute.selectedPointIdx] = [offsetX, offsetY];
        element.attributes = attributes;
    }
}
export const pathPainter = new PathPainter();
