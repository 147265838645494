var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterMode } from "../../../interface/common";
import { toJS } from "mobx";
import { isLeftMouseClick } from "../../provider/mouse-button";
let PathDetection = class PathDetection extends React.Component {
    constructor() {
        super(...arguments);
        this.onMouseDown = event => {
            const status = imageLabelStore.status;
            if (!isLeftMouseClick(event))
                return;
            if (status.mode === PainterMode.DRAW)
                return;
            if (status.mode === PainterMode.UNSET) {
                const { element } = this.props;
                status.mode = PainterMode.MOVE;
                status.selectedElementIds = [element.id];
            }
        };
        this.finishDrawingPath = (element, index) => {
            const status = imageLabelStore.status;
            if (status.mode === PainterMode.DRAW && index === 0) {
                const attributes = element.attributes;
                if (!attributes.isClosed) {
                    if (status.curSelectedLabel.setPoint && attributes.points.length !== status.curSelectedLabel.points)
                        return;
                    attributes.isClosed = true;
                    attributes.nextPos = null;
                    status.layerElements.push(status.creatingElement);
                    status.creatingElement = null;
                }
            }
        };
    }
    render() {
        var _a;
        const { element } = this.props;
        const { label } = element;
        const attributes = element.attributes;
        const { points, nextPos, isClosed } = toJS(attributes);
        let pathRoute = "M";
        points.forEach((pos, idx) => {
            if (idx !== 0) {
                pathRoute += "L";
            }
            pathRoute += `${pos[0]} ${pos[1]}` + " ";
        });
        if (nextPos) {
            pathRoute += `L${nextPos.x} ${nextPos.y}` + " ";
        }
        pathRoute += `${isClosed ? "Z" : ""}`;
        return (<React.Fragment>
        {element.visible && (<path d={pathRoute} fill={element.label.color} fillOpacity={0.2} stroke={element.label.color} strokeLinejoin="round" strokeLinecap="round" strokeWidth={2} strokeOpacity={1} onMouseDown={this.onMouseDown}/>)}

        
        {((_a = imageLabelStore.status.creatingElement) === null || _a === void 0 ? void 0 : _a.id) === element.id &&
            points.map((point, index) => (<circle key={index} cx={point[0]} cy={point[1]} r={5} fill="#FFF" strokeWidth={1} stroke={label.color} style={{ cursor: "pointer" }} onMouseDown={event => {
                this.finishDrawingPath(element, index);
                event.stopPropagation();
            }}/>))}
      </React.Fragment>);
    }
};
PathDetection = __decorate([
    observer
], PathDetection);
export { PathDetection };
