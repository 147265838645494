var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Card, Checkbox, Collapse, DatePicker, Drawer, Dropdown, Input, InputNumber, message, Popover, Radio, Row, Select, Switch, Tag, TimePicker } from "antd";
import { PictureOutlined, DeleteOutlined, DownOutlined, BarsOutlined } from "@ant-design/icons";
import Color from "color";
import { ClassificationTool } from "definition/entity/label-task";
import * as _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Moment from "moment";
import videoLabelStore from "page/label/video/VideoLabel/VideoLabelStore";
import React from "react";
import { IconFont } from "../../../../../../component/IconFont/IconFont";
import { imageLabelStore } from "../../ImageLabelStore";
import "../LabelDisplay/LabelDisplay.less";
let SegmentDisplay = class SegmentDisplay extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isPreviewVisible: false,
            annotation: {}
        };
    }
    syncDate(ele, idx, eles) {
        const date = eles[idx - 1].detection.classifications[2].value;
        ele.detection.classifications[2].value = date;
    }
    syncPointInfo(ele, idx, eles) {
        const pointInfo = eles[idx - 1].detection.classifications[1].value;
        ele.detection.classifications[1].value = pointInfo;
    }
    renderDetections(detectionKeys, detectionElements) {
        return (<Collapse key={detectionKeys.length} defaultActiveKey={detectionKeys} expandIconPosition="end" className="label-display-detection">
        {detectionKeys.map(groupKey => {
            const matchLabel = _.find(imageLabelStore.status.selectableLabel.detections, item => item.key === groupKey);
            const matchLayerElements = detectionElements.filter(i => i.label.key === groupKey);
            return (<Collapse.Panel className="zero-classification-panel" key={matchLabel.key} header={this.renderHeader(matchLayerElements, matchLabel)}>
              <div className="layer-elements-wrapper">
                {matchLayerElements.map((ele, idx) => {
                const selected = imageLabelStore.status.selectedElementIds.includes(ele.id);
                const color = Color(matchLabel.color)
                    .alpha(0.2)
                    .toString();
                return (<div className="layer-element-item" id={ele.id} key={idx} style={{ backgroundColor: selected ? color : null }}>
                      <Row onClick={() => {
                    imageLabelStore.status.selectedElementIds = [ele.id];
                }}>
                        <div className="extra">
                          <Popover placement="bottomRight" title="历史帧" content={<div className="popover-content">
                                <div>
                                  <Button onClick={() => {
                    videoLabelStore.currentFrame = ele.detection.value.startFrame;
                }} size="small" type="link">
                                    {Math.round(ele.detection.value.startFrame)}帧-
                                    {Math.floor(ele.detection.value.startTime)}ms
                                  </Button>
                                  <Tag>首帧</Tag>
                                </div>
                                <div>
                                  <Button onClick={() => {
                    videoLabelStore.currentFrame = ele.detection.value.endFrame;
                }} size="small" type="link">
                                    {Math.round(ele.detection.value.endFrame)}帧-
                                    {Math.floor(ele.detection.value.endTime)}
                                    ms
                                  </Button>
                                  <Tag>尾帧</Tag>
                                </div>
                              </div>}>
                            <PictureOutlined />
                          </Popover>
                          <IconFont style={{ marginLeft: "6px" }} type={ele.visible ? "icon-eye-open" : "icon-eye-off"} onClick={e => {
                    ele.visible = !ele.visible;
                    e.stopPropagation();
                }}/>
                          <DeleteOutlined style={{ marginLeft: 5 }} onClick={() => {
                    _.remove(imageLabelStore.status.layerElements, { id: ele.id });
                }}/>
                        </div>
                        <div title={`${ele.label.locale || ele.label.key} (${ele.id})`} className="label-id">
                          {ele.label.locale || ele.label.key}&nbsp;({ele.id})
                        </div>
                      </Row>

                      <Row>{this.renderClassifications(ele, idx, matchLayerElements)}</Row>
                    </div>);
            })}
              </div>
            </Collapse.Panel>);
        })}
      </Collapse>);
    }
    renderClassifications(ele, idx, eles) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        const value1 = (_c = (_b = (_a = ele.detection) === null || _a === void 0 ? void 0 : _a.classifications) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.value;
        const value2 = (_f = (_e = (_d = ele.detection) === null || _d === void 0 ? void 0 : _d.classifications) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.value;
        const value3 = (_j = (_h = (_g = ele.detection) === null || _g === void 0 ? void 0 : _g.classifications) === null || _h === void 0 ? void 0 : _h[2]) === null || _j === void 0 ? void 0 : _j.value;
        const value4 = (_m = (_l = (_k = ele.detection) === null || _k === void 0 ? void 0 : _k.classifications) === null || _l === void 0 ? void 0 : _l[3]) === null || _m === void 0 ? void 0 : _m.value;
        const value5 = (_q = (_p = (_o = ele.detection) === null || _o === void 0 ? void 0 : _o.classifications) === null || _p === void 0 ? void 0 : _p[4]) === null || _q === void 0 ? void 0 : _q.value;
        const hasExtraClassification = !!ele.label.classifications.slice(5).length;
        const overlay = (<div>
        {ele.label.classifications.slice(5).map((classification, index) => {
            var _a, _b, _c;
            let matchRes = _.find(ele.detection.classifications, item => item.key === classification.key);
            let content = null;
            switch (classification.tool) {
                case ClassificationTool.Switch: {
                    content = (<div key={classification.key} className="classification-switch">
                  {classification.locale || classification.key}：
                  
                  <Switch style={{ marginLeft: "8px" }} size="small" checked={matchRes.value} onChange={checked => {
                        matchRes.value = checked;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }}/>
                </div>);
                    break;
                }
                case ClassificationTool.Radio: {
                    content = (<div key={classification.key} className="classification-radio">
                  {classification.locale || classification.key}：
                  
                  <Radio.Group className="radio-group" value={matchRes.value} onChange={event => {
                        matchRes.value = event.target.value;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }}>
                    {(_a = classification.options) === null || _a === void 0 ? void 0 : _a.map(option => (<Radio className="radio-option" key={option.key} value={option.key}>
                        {option.locale || option.key}
                      </Radio>))}
                  </Radio.Group>
                </div>);
                    break;
                }
                case ClassificationTool.Checkbox: {
                    content = (<div key={classification.key} className="classification-checkbox">
                  {classification.locale || classification.key}：
                  
                  <Checkbox.Group className="checkbox-group" value={toJS(matchRes.value)} onChange={checkedValue => {
                        matchRes.value = checkedValue;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }}>
                    {(_b = classification.options) === null || _b === void 0 ? void 0 : _b.map(option => (<Checkbox className="checkbox-option" key={option.key} value={option.key}>
                        {option.locale || option.key}
                      </Checkbox>))}
                  </Checkbox.Group>
                </div>);
                    break;
                }
                case ClassificationTool.Text: {
                    content = (<div key={classification.key} className="classification-text">
                  {classification.locale || classification.key}：
                  
                  <Input value={matchRes.value} size="small" onChange={event => {
                        matchRes.value = event.target.value;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }} onPressEnter={e => {
                        e.currentTarget.blur();
                    }}/>
                </div>);
                    break;
                }
                case ClassificationTool.Number: {
                    content = (<div key={classification.key} className="classification-text">
                  {classification.locale || classification.key}：
                  
                  <InputNumber value={matchRes.value} size="small" onChange={value => {
                        matchRes.value = value;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }} onPressEnter={e => {
                        e.currentTarget.blur();
                    }}/>
                </div>);
                    break;
                }
                case ClassificationTool.Time: {
                    content = (<div key={classification.key} className="classification-text">
                  {classification.locale || classification.key}：
                  
                  <TimePicker value={matchRes.value} size="small" onChange={value => {
                        matchRes.value = value;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }}/>
                </div>);
                    break;
                }
                case ClassificationTool.Date: {
                    content = (<div key={classification.key} className="classification-text">
                  {classification.locale || classification.key}：
                  
                  <DatePicker value={matchRes.value} size="small" onChange={value => {
                        matchRes.value = value;
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }}/>
                </div>);
                    break;
                }
                case ClassificationTool.Select: {
                    content = (<div key={classification.key} className="classification-select" onClick={e => e.stopPropagation()}>
                  {classification.locale || classification.key}：
                  
                  <Select style={{ width: "100%" }} className="select-group" size="small" value={_.isNull(matchRes.value) ? undefined : toJS(matchRes.value)} onChange={value => {
                        matchRes.value = toJS(value);
                        imageLabelStore.setStatus({
                            layerElements: imageLabelStore.status.layerElements
                        });
                    }} mode="multiple">
                    {(_c = classification.options) === null || _c === void 0 ? void 0 : _c.map(option => (<Select.Option key={option.locale || option.key}>{option.locale || option.key}</Select.Option>))}
                  </Select>
                </div>);
                    break;
                }
            }
            return (<React.Fragment key={index}>
              
              {content}
            </React.Fragment>);
        })}
      </div>);
        const summary = (<Row justify="space-between" align="top">
        <div style={{ flex: 1, lineHeight: 1.5, width: 0 }}>
          {ele.detection.classifications.slice(5).map((classification, index) => {
            var _a;
            const hasValue = _.isBoolean(classification.value)
                ? classification.value
                : !_.isEmpty(classification.value);
            const valueText = _.isBoolean(classification.value)
                ? classification.value
                    ? "是"
                    : "否"
                : (_a = classification.value) === null || _a === void 0 ? void 0 : _a.toString();
            return (<Tag key={index} style={{ marginBottom: 5 }} color={hasValue ? "red" : null}>
                {classification.key}: {valueText}
              </Tag>);
        })}
        </div>
        <DownOutlined />
      </Row>);
        return (<React.Fragment>
        <div>
          违法车辆数：
          <InputNumber value={value1} onChange={value => (ele.detection.classifications[0].value = value)} defaultValue={0} min={0} step={1} precision={0} size="small" onPressEnter={e => {
            e.currentTarget.blur();
        }}/>
        </div>
        <div>
          点位信息：
          <Button onClick={() => {
            this.syncPointInfo(ele, idx, eles);
        }} style={{ marginLeft: 5 }} size="small" disabled={idx === 0 || !((_t = (_s = (_r = eles[idx - 1]) === null || _r === void 0 ? void 0 : _r.detection) === null || _s === void 0 ? void 0 : _s.classifications) === null || _t === void 0 ? void 0 : _t[1].value)}>
            同步
          </Button>
          <Input value={value2} size="small" placeholder="点位信息" onChange={event => (ele.detection.classifications[1].value = event.target.value)} onPressEnter={e => {
            e.currentTarget.blur();
        }}/>
        </div>
        <div>
          摄像头日期：
          <Button onClick={() => {
            this.syncDate(ele, idx, eles);
        }} style={{ marginLeft: 5 }} size="small" disabled={idx === 0 || !((_w = (_v = (_u = eles[idx - 1]) === null || _u === void 0 ? void 0 : _u.detection) === null || _v === void 0 ? void 0 : _v.classifications) === null || _w === void 0 ? void 0 : _w[2].value)}>
            同步
          </Button>
          &nbsp;
          <DatePicker value={value3 && Moment(value3, "YYYY-MM-DD")} onChange={value => (ele.detection.classifications[2].value = value && value.format("YYYY-MM-DD"))} size="small" suffixIcon={<i></i>} style={{ width: 120 }}/>
        </div>
        <div>
          <TimePicker value={value4 && Moment(value4, "HH:mm:ss")} onChange={value => {
            ele.detection.classifications[3].value = value && value.format("HH:mm:ss");
        }} style={{ width: 90 }} placeholder="进入时间" size="small" suffixIcon={<i></i>}></TimePicker>
          ~
          <TimePicker value={value5 && Moment(value5, "HH:mm:ss")} onChange={value => (ele.detection.classifications[4].value = value && value.format("HH:mm:ss"))} style={{ width: 90 }} placeholder="离开时间" size="small" suffixIcon={<i></i>}></TimePicker>
        </div>
        {overlay}
        
      </React.Fragment>);
    }
    renderHeader(matchLayerElements, matchLabel) {
        return (<Row justify="space-between" align="middle" onClick={e => e.stopPropagation()}>
        <span>{`${matchLabel.key}(${matchLayerElements.length})`}</span>
        <Popover placement="bottomRight" title="批量操作" content={<div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: "4px" }}>
              <Button onClick={() => {
            matchLayerElements.forEach(ele => (ele.visible = false));
        }}>
                隐藏
              </Button>
              <Button onClick={() => {
            matchLayerElements.forEach(ele => (ele.visible = true));
        }}>
                显示
              </Button>
              <Button key="delete" onClick={() => {
            _.remove(imageLabelStore.status.layerElements, ele => matchLayerElements.map(i => i.id).includes(ele.id));
        }}>
                删除
              </Button>
            </div>}>
          <BarsOutlined />
        </Popover>
      </Row>);
    }
    render() {
        var _a;
        const detectionElements = imageLabelStore.status.layerElements.filter(e => e.label.type === "detection");
        const detectionKeys = _.uniq(detectionElements.map(item => item.label.key));
        return (<div key={(_a = imageLabelStore.status.curResource) === null || _a === void 0 ? void 0 : _a.id} className="label-display-component">
        <Card className="annotations-card" title="切割对象" bodyStyle={{ padding: 8 }}>
          {this.renderDetections(detectionKeys, detectionElements)}
        </Card>
        <div className="tools">
          <Button disabled={imageLabelStore.status.isResourceLoading} onClick={async () => {
            if (await imageLabelStore.saveCurResourceAnnotation()) {
                message.success("保存成功！");
            }
        }}>
            保存
          </Button>
          <Button onClick={() => {
            this.setState({
                isPreviewVisible: true,
                annotation: JSON.stringify(imageLabelStore.getAnnotation(), null, 2)
            });
        }}>
            切割预览
          </Button>
        </div>
        <Drawer open={this.state.isPreviewVisible} width="35vw" title="切割预览" placement="right" onClose={() => {
            this.setState({
                isPreviewVisible: false
            });
        }}>
          <pre>{this.state.annotation}</pre>
        </Drawer>
      </div>);
    }
};
SegmentDisplay = __decorate([
    observer
], SegmentDisplay);
export default SegmentDisplay;
class Dropdown2 extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            visible: false
        };
        this.overlayRef = null;
        this.childrenRef = null;
        this.clickEventHandler = e => {
            var _a, _b;
            if ((_a = this.childrenRef) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
                return;
            }
            if (!((_b = this.overlayRef) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
                this.setState({ visible: false });
            }
        };
    }
    componentDidMount() {
        window.addEventListener("click", this.clickEventHandler);
    }
    componentWillUnmount() {
        window.removeEventListener("click", this.clickEventHandler);
    }
    render() {
        const _a = this.props, { visible, overlay } = _a, rest = __rest(_a, ["visible", "overlay"]);
        return (<Dropdown open={this.state.visible} overlay={<div ref={el => (this.overlayRef = el)}>{overlay}</div>} {...rest}>
        <div ref={el => (this.childrenRef = el)} onClick={() => {
            this.setState({ visible: !this.state.visible });
        }}>
          {this.props.children}
        </div>
      </Dropdown>);
    }
}
