import * as _ from "lodash";
export const deleteNilProperty = (object) => {
    if (_.isNil(object)) {
        return null;
    }
    let resultQuery = {};
    Object.getOwnPropertyNames(object).forEach(key => {
        if (_.isNil(object[key]) || _.isNaN(object[key])) {
            return;
        }
        if (_.isArray(object[key]) && !_.isEmpty(object[key])) {
            resultQuery[key] = object[key];
        }
        else if (_.isString(object[key]) && !_.isEmpty(object[key])) {
            resultQuery[key] = object[key];
        }
        else if (_.isNumber(object[key])) {
            resultQuery[key] = object[key];
        }
        else if (_.isBoolean(object[key])) {
            resultQuery[key] = object[key];
        }
    });
    return resultQuery;
};
