class Events {
    constructor() {
        this.events = {};
        this.events = {};
    }
    on(name, callback) {
        if (typeof callback === "function") {
            if (!this.events[name]) {
                this.events[name] = [];
            }
            this.events[name].push(callback);
        }
        return this;
    }
    off(name, callback) {
        if (callback && typeof callback === "function") {
            var targetEvents = this.events[name];
            var index = targetEvents.indexOf(callback);
            if (index > -1) {
                targetEvents.splice(index, 1);
            }
        }
        else {
            this.events[name] = [];
        }
    }
    trigger(name, info) {
        if (this.events[name] && this.events[name].length) {
            for (let i = 0; i < this.events[name].length; i++) {
                this.events[name][i](info);
            }
        }
    }
}
export default Events;
