var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "api/Api";
import { appStore } from "store/AppStore";
class Status {
    constructor() {
        this.sandBags = [];
        this.users = [];
        this.isShareSandBagModal = false;
        this.curBagId = undefined;
        this.sharedTo = undefined;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "sandBags", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isShareSandBagModal", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "curBagId", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "sharedTo", void 0);
class CreatedSandBagsStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const getLabelSandBagsRes = await Api.labelSandBags.getLabelSandBags({ creatorId: appStore.status.loginUser.id });
        if (getLabelSandBagsRes.code === 200) {
            this.status.sandBags = getLabelSandBagsRes.data;
        }
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        if (getUsersRes.code === 200) {
            this.status.users = getUsersRes.data;
        }
    }
    async shareSandBag() {
        const { curBagId, sharedTo } = this.status;
        await Api.labelSandBags.shareSandBag(curBagId, sharedTo);
        await this.loadData();
        this.status.isShareSandBagModal = false;
    }
    async deleteLabelSandBag(bagId) {
        const deleteLabelSandBagRes = await Api.labelSandBags.deleteLabelSandBag(bagId);
        if (deleteLabelSandBagRes.code === 200) {
            await this.loadData();
        }
    }
}
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], CreatedSandBagsStore.prototype, "resetStatus", null);
export const createdSandBagsStore = new CreatedSandBagsStore();
