var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../../store/BasicStore";
import * as _ from "lodash";
import { action, observable } from "mobx";
import { Api } from "../../../../api/Api";
import { routerStore } from "../../../../store/routerStore";
import { appStore } from "store/AppStore";
import { randomString } from "../../../../util/random-string";
import { IResource } from "definition/entity/resource";
import { ILabelTask } from "definition/entity/label-task";
class Status {
    constructor() {
        this.isLoading = true;
        this.labelTask = null;
        this.isEnableUpdate = false;
        this.isEditingLabelTask = false;
        this.taskBusinesses = [];
        this.businessId = undefined;
        this.sandBags = [];
        this.assessmentPolicies = [];
        this.mixPolicyThresholds = [];
        this.extractPolicyThresholds = [];
        this.videoExtractPolicyThresholds = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "labelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isEnableUpdate", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isEditingLabelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskBusinesses", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "businessId", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "sandBags", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "assessmentPolicies", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "mixPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "extractPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "videoExtractPolicyThresholds", void 0);
class LabelTaskModifyStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadTaskBusinesses() {
        const taskBusinessesRes = await Api.taskBusiness.listTaskBusinesses();
        this.status.taskBusinesses = taskBusinessesRes.data;
    }
    async loadSandBags() {
        let sandBagsRes = null;
        if (appStore.status.loginUser.isSystemAdmin) {
            sandBagsRes = await Api.labelSandBags.getLabelSandBags();
        }
        else {
            sandBagsRes = await Api.labelSandBags.getLabelSandBags({ creatorId: appStore.status.loginUser.id });
        }
        this.status.sandBags = sandBagsRes.data;
    }
    async loadAssessmentPolicies() {
        const assessmentPoliciesRes = await Api.labellingAssessmentPolicy.getLabellingAssessmentPolicies();
        this.status.assessmentPolicies = assessmentPoliciesRes.data;
    }
    async loadData(labelTaskId) {
        const labelTaskRes = await Api.labelTask.getLabelTask(labelTaskId);
        this.setStatus({
            isLoading: false,
            labelTask: labelTaskRes.data,
            businessId: _.isNil(labelTaskRes.data.businessId) ? undefined : labelTaskRes.data.businessId
        });
        if (labelTaskRes.data.type === ILabelTask.Type.NewMix) {
            if (labelTaskRes.data.labelTaskType === ILabelTask.LabelTaskType.Segmentation) {
                labelTaskRes.data.policyThresholds.forEach(threshold => {
                    this.status.mixPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "box_siou_th" || v === "mask_siou_th")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "box_siou_th", value: threshold[key]["box_siou_th"] },
                                { key: "mask_siou_th", value: threshold[key]["mask_siou_th"] },
                                ...items
                            ]
                        };
                    }));
                });
            }
            else {
                labelTaskRes.data.policyThresholds.forEach(threshold => {
                    this.status.mixPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "precision" || v === "recall" || v === "iou")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "precision", value: threshold[key]["precision"] },
                                { key: "recall", value: threshold[key]["recall"] },
                                { key: "iou", value: threshold[key]["iou"] },
                                ...items
                            ]
                        };
                    }));
                });
            }
        }
        if (labelTaskRes.data.type === ILabelTask.Type.Extract) {
            labelTaskRes.data.policyThresholds.forEach(threshold => {
                if (this.status.labelTask.resourceMime === IResource.Mime.Image) {
                    this.status.extractPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "precision" || v === "recall" || v === "iou")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "precision", value: threshold[key]["precision"] },
                                { key: "recall", value: threshold[key]["recall"] },
                                { key: "iou", value: threshold[key]["iou"] },
                                ...items
                            ]
                        };
                    }));
                }
                else {
                    this.status.videoExtractPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "mota" || v === "motp" || v === "mt")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "mota", value: threshold[key]["mota"] },
                                { key: "motp", value: threshold[key]["motp"] },
                                { key: "mt", value: threshold[key]["mt"] },
                                ...items
                            ]
                        };
                    }));
                }
            });
        }
    }
    async updateLabelTask(id, params) {
        this.status.isEditingLabelTask = true;
        const updateRes = await Api.labelTask.updateLabelTask(id, {
            label: params.label,
            businessId: params.businessId,
            taskDeadline: params.taskDeadline,
            labelDeadline: params.labelDeadline,
            mix: params.mix,
            extract: params.extract,
            needAllLabeled: params.needAllLabeled,
            biz: params.biz
        });
        if (updateRes.code === 200) {
            this.status.isEditingLabelTask = false;
            routerStore.goBack();
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelTaskModifyStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelTaskModifyStore.prototype, "resetStatus", null);
export const labelTaskModifyStore = new LabelTaskModifyStore();
