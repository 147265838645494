import { PainterMode, PainterMouseEvent } from "../../../../interface/common";
import { imageLabelStore } from "../../../ImageLabelStore";
export class BasePainter {
    eventHandler(event, mode, mouseEvent) {
        switch (mode) {
            case PainterMode.DRAW:
                switch (mouseEvent) {
                    case PainterMouseEvent.MouseDown:
                        return this.Draw_MouseDown(event);
                    case PainterMouseEvent.MouseUp:
                        return this.Draw_MouseUp(event);
                    case PainterMouseEvent.MouseMove:
                        return this.Draw_MouseMove(event);
                    case PainterMouseEvent.ContextMenu:
                        return this.Draw_ContextMenu(event);
                }
                break;
            case PainterMode.MOVE:
                switch (mouseEvent) {
                    case PainterMouseEvent.MouseMove:
                        return this.Move_MouseMove(event);
                    case PainterMouseEvent.MouseUp:
                        return this.Move_MouseUp(event);
                }
                break;
            case PainterMode.RESIZE:
                switch (mouseEvent) {
                    case PainterMouseEvent.MouseUp:
                        return this.Resize_MouseUp(event);
                    case PainterMouseEvent.MouseMove:
                        return this.Resize_MouseMove(event);
                }
                break;
        }
    }
    Draw_MouseDown(event) {
    }
    Draw_MouseUp(event) {
    }
    Draw_MouseMove(event) {
    }
    Draw_ContextMenu(event) {
    }
    Move_MouseMove(event) {
    }
    Move_MouseUp(event) { }
    Resize_MouseUp(event) {
    }
    Resize_MouseMove(event) {
    }
    isValidCreatingEle(isMatchTool, labelType) {
        var _a, _b, _c, _d, _e, _f;
        return (((_c = (_b = (_a = imageLabelStore.status) === null || _a === void 0 ? void 0 : _a.creatingElement) === null || _b === void 0 ? void 0 : _b.label) === null || _c === void 0 ? void 0 : _c.tool) === isMatchTool &&
            ((_f = (_e = (_d = imageLabelStore.status) === null || _d === void 0 ? void 0 : _d.creatingElement) === null || _e === void 0 ? void 0 : _e.label) === null || _f === void 0 ? void 0 : _f.type) === labelType);
    }
    isValidSelectOneEle(isMatchTool, labelType) {
        var _a, _b;
        const { selectedElements } = imageLabelStore;
        return ((selectedElements === null || selectedElements === void 0 ? void 0 : selectedElements.length) === 1 &&
            ((_a = selectedElements[0].label) === null || _a === void 0 ? void 0 : _a.tool) === isMatchTool &&
            ((_b = selectedElements[0].label) === null || _b === void 0 ? void 0 : _b.type) === labelType);
    }
}
