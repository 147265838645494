var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { observer } from "mobx-react";
import { labelTaskModifyStore } from "./LabelTaskModifyStore";
import { Button, Card, Form, Input, PageHeader, Popconfirm, Radio, Select, message, DatePicker, Switch, InputNumber, Tag } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { routerStore } from "../../../../store/routerStore";
import { LabelEditor } from "../../component/LabelEditor/LabelEditor";
import { Loading } from "../../../../component/Loading/Loading";
import { IResource } from "../../../../definition/entity/resource";
import { PolicyThreshold } from "../LabelTaskCreate/component/PolicyThreshold/PolicyThreshold";
import { randomString } from "../../../../util/random-string";
import { ILabelTask } from "definition/entity/label-task";
let LabelTaskModify = class LabelTaskModify extends React.Component {
    constructor() {
        super(...arguments);
        this.editorRef = null;
    }
    async componentDidMount() {
        const labelTaskId = +this.props.match.params.labelTaskId;
        await labelTaskModifyStore.loadData(labelTaskId);
        await labelTaskModifyStore.loadTaskBusinesses();
        await labelTaskModifyStore.loadSandBags();
        await labelTaskModifyStore.loadAssessmentPolicies();
    }
    componentWillUnmount() {
        labelTaskModifyStore.resetStatus();
    }
    render() {
        var _a;
        const status = labelTaskModifyStore.status;
        return (<PageHeader title="编辑打标任务" style={{ background: "#FFF" }} onBack={() => routerStore.push("/manage/label_tasks")}>
        <Card>
          {status.isLoading ? (<Loading />) : (<React.Fragment>
              <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} requiredMark={false}>
                <Form.Item label="任务名称">
                  <Input disabled={true} value={status.labelTask.name}/>
                </Form.Item>
                <Form.Item label="计价类型">
                  <Select showSearch allowClear={true} placeholder="请选择计价类型" value={status.businessId} onChange={value => {
            if (!_.isNil(value)) {
                status.businessId = +value;
            }
            else {
                status.businessId = undefined;
            }
        }}>
                    {status.taskBusinesses.map(taskBusiness => (<Select.Option key={String(taskBusiness.id)} disabled={!taskBusiness.isValid} value={taskBusiness.id}>
                        {taskBusiness.name}
                      </Select.Option>))}
                  </Select>
                </Form.Item>
                <Form.Item label="优先级">
                  <Radio.Group disabled={true} value={status.labelTask.level}>
                    <Radio value={ILabelTask.Level.First}>一级</Radio>
                    <Radio value={ILabelTask.Level.Second}>二级</Radio>
                    <Radio value={ILabelTask.Level.Third}>三级</Radio>
                    <Radio value={ILabelTask.Level.Fourth}>四级</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="任务要求完成日期">
                  <DatePicker disabled={[ILabelTask.Level.First, ILabelTask.Level.Second].includes(status.labelTask.level)} disabledDate={current => {
            return (current &&
                current <
                    moment()
                        .subtract(1, "day")
                        .endOf("day"));
        }} value={_.isNil(status.labelTask.taskDeadline) ? null : moment(status.labelTask.taskDeadline)} onChange={date => {
            status.labelTask.taskDeadline = date;
        }}/>
                </Form.Item>
                <Form.Item label="标注要求完成日期">
                  <DatePicker disabledDate={current => {
            return ((current &&
                current <
                    moment()
                        .subtract(1, "day")
                        .endOf("day")) ||
                (status.labelTask.taskDeadline &&
                    current >
                        moment(status.labelTask.taskDeadline)
                            .clone()
                            .add(1, "day")));
        }} value={_.isNil(status.labelTask.labelDeadline) ? null : moment(status.labelTask.labelDeadline)} onChange={date => {
            status.labelTask.labelDeadline = date;
        }}/>
                </Form.Item>
                <Form.Item label="数据形式">
                  <Radio.Group disabled={true} value={status.labelTask.resourceMime}>
                    <Radio value={IResource.Mime.Image}>图片</Radio>
                    <Radio value={IResource.Mime.Video}>视频</Radio>
                    <Radio value={IResource.Mime.Cloud}>点云</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="任务类型">
                  <Radio.Group disabled={true} value={status.labelTask.labelTaskType}>
                    {status.labelTask.resourceMime === IResource.Mime.Image && (<React.Fragment>
                        <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                        <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                        <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                      </React.Fragment>)}
                    {status.labelTask.resourceMime === IResource.Mime.Video && (<React.Fragment>
                        <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                        <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                        <Radio value={ILabelTask.LabelTaskType.Anchor}>时空锚</Radio>
                        <Radio value={ILabelTask.LabelTaskType.Clipping}>切割</Radio>
                      </React.Fragment>)}
                    {status.labelTask.resourceMime === IResource.Mime.Cloud && (<React.Fragment>
                        <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                        <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                      </React.Fragment>)}
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="标签配置">
                  <LabelEditor ref={ref => (this.editorRef = ref)} resourceMime={status.labelTask.resourceMime} labelTaskType={status.labelTask.labelTaskType} defaultLabel={status.labelTask.label} checkIntervalCallback={isPassed => {
            status.isEnableUpdate = isPassed;
        }}/>
                </Form.Item>
                {status.labelTask.labelTaskType === ILabelTask.LabelTaskType.Clipping && (<Form.Item label="切割预置时间">
                    <Form.Item style={{ display: "inline-block", marginRight: 10 }}>
                      前{" "}
                      <InputNumber value={status.labelTask.biz.headOffset} step={1} min={0} precision={0} onChange={val => {
            status.labelTask.biz.headOffset = val;
        }}/>
                      秒
                    </Form.Item>
                    <Form.Item style={{ display: "inline-block" }}>
                      后{" "}
                      <InputNumber value={status.labelTask.biz.tailOffset} step={1} min={0} precision={0} onChange={val => {
            status.labelTask.biz.tailOffset = val;
        }}/>
                      秒
                    </Form.Item>
                  </Form.Item>)}
                <Form.Item label="全量标注">
                  <Switch checked={status.labelTask.needAllLabeled} onChange={checked => (status.labelTask.needAllLabeled = checked)}></Switch>
                  <span style={{ color: "red" }}>只适用于3d分割标注任务</span>
                </Form.Item>

                <Form.Item label={<span className="label-required">数据文件</span>}>
                  <div>
                    {(_a = status.labelTask.dataoceanBlobs) === null || _a === void 0 ? void 0 : _a.map(blob => {
            return <Tag>{blob}</Tag>;
        })}
                  </div>
                </Form.Item>

                {status.labelTask.resourceMime === IResource.Mime.Image && (<React.Fragment>
                    {status.labelTask.labelTaskType === ILabelTask.LabelTaskType.Segmentation ? (<React.Fragment>
                        <Form.Item label="评估方案">
                          <Radio.Group value={status.labelTask.type} disabled={true}>
                            <Radio value={ILabelTask.Type.Normal}>无</Radio>
                            <Radio value={ILabelTask.Type.NewMix}>掺沙</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {status.labelTask.type === ILabelTask.Type.NewMix && (<React.Fragment>
                            <Form.Item label="沙子包">
                              <Select placeholder="请选择沙子包" disabled style={{ width: "300px" }} defaultValue={status.labelTask.sandPackageId}>
                                {status.sandBags.map(sandBag => (<Select.Option key={String(sandBag.id)} value={sandBag.id}>
                                    {sandBag.name}
                                  </Select.Option>))}
                              </Select>
                            </Form.Item>
                            <Form.Item label="评估方法">
                              <Select disabled placeholder="请选择评估方法" style={{ width: "300px" }} defaultValue={status.labelTask.policyId}>
                                {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                    {policy.name}
                                  </Select.Option>))}
                              </Select>
                            </Form.Item>
                            <Form.Item label="掺沙比例">
                              <span>{status.labelTask.ratio * 100} %</span>
                            </Form.Item>
                            <Form.Item label="评估阈值">
                              <div style={{ width: "100%" }}>
                                {status.mixPolicyThresholds.map(threshold => (<PolicyThreshold type={status.labelTask.resourceMime} key={threshold.uniqKey} name={threshold.nameConfig} item={threshold.configs} segment="2d" add={(key, val) => {
            status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 2) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                                <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            if (status.mixPolicyThresholds.length < 1) {
                status.mixPolicyThresholds.push({
                    nameConfig: { value: "log" },
                    uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                    configs: [
                        { key: "box_siou_th", value: undefined },
                        { key: "mask_siou_th", value: undefined }
                    ]
                });
            }
        }}/>
                                <DeleteOutlined onClick={() => {
            status.mixPolicyThresholds.pop();
        }}/>
                              </div>
                            </Form.Item>
                          </React.Fragment>)}
                      </React.Fragment>) : (<React.Fragment>
                        <Form.Item label="评估方案">
                          <Radio.Group value={status.labelTask.type} disabled={true}>
                            <Radio value={ILabelTask.Type.Normal}>无</Radio>
                            <Radio value={ILabelTask.Type.NewMix}>掺沙</Radio>
                            <Radio value={ILabelTask.Type.Extract}>专家</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {status.labelTask.type === ILabelTask.Type.NewMix && (<React.Fragment>
                            <Form.Item label="沙子包">
                              <Select placeholder="请选择沙子包" disabled style={{ width: "300px" }} defaultValue={status.labelTask.sandPackageId}>
                                {status.sandBags.map(sandBag => (<Select.Option key={String(sandBag.id)} value={sandBag.id}>
                                    {sandBag.name}
                                  </Select.Option>))}
                              </Select>
                            </Form.Item>
                            <Form.Item label="评估方法">
                              <Select disabled placeholder="请选择评估方法" style={{ width: "300px" }} defaultValue={status.labelTask.policyId}>
                                {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                    {policy.name}
                                  </Select.Option>))}
                              </Select>
                            </Form.Item>
                            <Form.Item label="掺沙比例">
                              <span>{status.labelTask.ratio * 100} %</span>
                            </Form.Item>
                            <Form.Item label="评估阈值">
                              <div style={{ width: "100%" }}>
                                {status.mixPolicyThresholds.map(threshold => (<PolicyThreshold type={status.labelTask.resourceMime} key={threshold.uniqKey} item={threshold.configs} name={threshold.nameConfig} segment="none" add={(key, val) => {
            status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.mixPolicyThresholds = status.mixPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 3) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                                <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            status.mixPolicyThresholds.push({
                uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                nameConfig: { value: undefined },
                configs: [
                    { key: "precision", value: undefined },
                    { key: "recall", value: undefined },
                    { key: "iou", value: undefined }
                ]
            });
        }}/>
                                <DeleteOutlined onClick={() => {
            status.mixPolicyThresholds.pop();
        }}/>
                              </div>
                            </Form.Item>
                          </React.Fragment>)}
                        {status.labelTask.type === ILabelTask.Type.Extract && (<React.Fragment>
                            <Form.Item label="评估方法">
                              <Select disabled placeholder="请选择评估方法" style={{ width: "300px" }} defaultValue={status.labelTask.policyId}>
                                {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                    {policy.name}
                                  </Select.Option>))}
                              </Select>
                            </Form.Item>
                            <Form.Item label="专家评估比例">
                              <span>{status.labelTask.ratio * 100} %</span>
                            </Form.Item>
                            <Form.Item label="评估阈值">
                              <div style={{ width: "100%" }}>
                                {status.extractPolicyThresholds.map(threshold => (<PolicyThreshold type={IResource.Mime.Image} key={threshold.uniqKey} name={threshold.nameConfig} item={threshold.configs} segment="none" add={(key, val) => {
            status.extractPolicyThresholds = status.extractPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.extractPolicyThresholds = status.extractPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 3) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                                <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            status.extractPolicyThresholds.push({
                uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                nameConfig: { value: undefined },
                configs: [
                    { key: "precision", value: undefined },
                    { key: "recall", value: undefined },
                    { key: "iou", value: undefined }
                ]
            });
        }}/>
                                <DeleteOutlined onClick={() => {
            status.extractPolicyThresholds.pop();
        }}/>
                              </div>
                            </Form.Item>
                          </React.Fragment>)}
                      </React.Fragment>)}
                  </React.Fragment>)}
                {status.labelTask.resourceMime === IResource.Mime.Video &&
            status.labelTask.labelTaskType === ILabelTask.LabelTaskType.Detection && (<React.Fragment>
                      {status.labelTask.type === ILabelTask.Type.Extract && (<React.Fragment>
                          <Form.Item label="评估方法">
                            <Select disabled placeholder="请选择评估方法" style={{ width: "300px" }} defaultValue={status.labelTask.policyId}>
                              {status.assessmentPolicies.map(policy => (<Select.Option key={String(policy.id)} value={policy.id}>
                                  {policy.name}
                                </Select.Option>))}
                            </Select>
                          </Form.Item>
                          <Form.Item label="评估阈值">
                            <div style={{ width: "100%" }}>
                              {status.videoExtractPolicyThresholds.map(threshold => (<PolicyThreshold type={IResource.Mime.Video} key={threshold.uniqKey} name={threshold.nameConfig} videoItem={threshold.configs} segment="none" add={(key, val) => {
            status.videoExtractPolicyThresholds = status.videoExtractPolicyThresholds.map(item => {
                if (item.uniqKey === threshold.uniqKey) {
                    item.configs.push({ key, value: val });
                }
                return item;
            });
        }} minus={() => {
            status.videoExtractPolicyThresholds = status.videoExtractPolicyThresholds.map(item => {
                var _a;
                if (item.uniqKey === threshold.uniqKey) {
                    if (((_a = item.configs) === null || _a === void 0 ? void 0 : _a.length) > 3) {
                        item.configs.pop();
                    }
                }
                return item;
            });
        }}/>))}
                              <PlusOutlined style={{ marginRight: 10 }} onClick={() => {
            status.videoExtractPolicyThresholds.push({
                uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                nameConfig: { value: undefined },
                configs: [
                    { key: "MOTA", value: undefined },
                    { key: "MOTP", value: undefined },
                    { key: "MT", value: undefined }
                ]
            });
        }}/>
                              <DeleteOutlined onClick={() => {
            status.videoExtractPolicyThresholds.pop();
        }}/>
                            </div>
                          </Form.Item>
                        </React.Fragment>)}
                    </React.Fragment>)}
                <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 20 }}>
                  <div style={{ float: "right" }}>
                    <Button onClick={() => {
            routerStore.goBack();
        }}>
                      取消
                    </Button>
                    <Popconfirm title="更新标签将影响所有已有标注！" disabled={!status.isEnableUpdate} onConfirm={() => {
            const { labelTask } = status;
            const { detections, classifications, anchors, segments, segments3D, detections3D } = this.editorRef.getStatus();
            if ((labelTask === null || labelTask === void 0 ? void 0 : labelTask.taskDeadline) && (labelTask === null || labelTask === void 0 ? void 0 : labelTask.labelDeadline) &&
                moment(labelTask === null || labelTask === void 0 ? void 0 : labelTask.taskDeadline).isBefore(moment(labelTask === null || labelTask === void 0 ? void 0 : labelTask.labelDeadline))) {
                message.warning("标注要求完成日期须在任务要求完成日期之前");
                return;
            }
            if (status.labelTask.type === ILabelTask.Type.NewMix) {
                if (!segments.length) {
                    for (let i = 0, l = status.mixPolicyThresholds.length; i < l; i++) {
                        const _a = status.mixPolicyThresholds[i], { uniqKey, nameConfig } = _a, rest = __rest(_a, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const data = _.zipObject(keys, values);
                        if (_.isNil(nameConfig.value) || _.isNil(data.precision) || _.isNil(data.recall)) {
                            message.warning("评估阈值不能存在空值");
                            return;
                        }
                    }
                }
            }
            if (status.labelTask.type === ILabelTask.Type.Extract) {
                for (let i = 0, l = status.extractPolicyThresholds.length; i < l; i++) {
                    const _b = status.extractPolicyThresholds[i], { uniqKey, nameConfig } = _b, rest = __rest(_b, ["uniqKey", "nameConfig"]);
                    const keys = rest.configs.map(v => v.key);
                    const values = rest.configs.map(v => v.value);
                    const data = _.zipObject(keys, values);
                    if (_.isNil(nameConfig.value) || _.isNil(data.precision) || _.isNil(data.recall)) {
                        message.warning("评估阈值不能存在空值");
                        return;
                    }
                }
            }
            labelTaskModifyStore.updateLabelTask(status.labelTask.id, Object.assign({
                label: {
                    detections: detections,
                    classifications: classifications,
                    anchors,
                    segments,
                    segments3D,
                    detections3D
                },
                businessId: status.businessId,
                taskDeadline: status.labelTask.taskDeadline,
                labelDeadline: status.labelTask.labelDeadline,
                needAllLabeled: status.labelTask.needAllLabeled,
                biz: status.labelTask.biz
            }, status.labelTask.type === ILabelTask.Type.NewMix && {
                mix: {
                    policyThresholds: status.mixPolicyThresholds.map(item => {
                        const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const configs = _.zipObject(keys, values);
                        return {
                            [`${nameConfig.value}`]: configs
                        };
                    })
                }
            }, status.labelTask.type === ILabelTask.Type.Extract && {
                extract: {
                    policyThresholds: status.labelTask.resourceMime === IResource.Mime.Image
                        ? status.extractPolicyThresholds.map(item => {
                            const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                            const keys = rest.configs.map(v => v.key);
                            const values = rest.configs.map(v => v.value);
                            const configs = _.zipObject(keys, values);
                            return {
                                [`${nameConfig.value}`]: configs
                            };
                        })
                        : status.videoExtractPolicyThresholds.map(item => {
                            const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                            const keys = rest.configs.map(v => v.key);
                            const values = rest.configs.map(v => v.value);
                            const configs = _.zipObject(keys, values);
                            return {
                                [`${nameConfig.value}`]: configs
                            };
                        })
                }
            }));
        }}>
                      <Button style={{ marginLeft: "10px" }} disabled={!status.isEnableUpdate} loading={status.isEditingLabelTask}>
                        更新
                      </Button>
                    </Popconfirm>
                  </div>
                </Form.Item>
              </Form>
            </React.Fragment>)}
        </Card>
      </PageHeader>);
    }
};
LabelTaskModify = __decorate([
    observer
], LabelTaskModify);
export { LabelTaskModify };
