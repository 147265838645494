var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Card, PageHeader, Tabs } from "antd";
import { UserCompositeAudit } from "./component/UserCompositeAudit/UserCompositeAudit";
import { TaskCompositeAudit } from "./component/TaskCompositeAudit/TaskCompositeAudit";
import { ComprehensiveAudit } from "./component/ComprehensiveAudit/ComprehensiveAudit";
import { routerStore } from "../../../../../store/routerStore";
import { parseLocationSearch } from "../../../../../util/parse-location-search";
import { compositeAuditStore } from "./CompositeAuditStore";
import { observer } from "mobx-react";
let CompositeAudit = class CompositeAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            activeKey: null
        };
    }
    componentDidMount() {
        const { activeKey } = parseLocationSearch(this.props.location.search);
        if (activeKey) {
            compositeAuditStore.status.activeKey = activeKey;
        }
        else if (!compositeAuditStore.status.activeKey) {
            compositeAuditStore.status.activeKey = "userCompositeAudit";
        }
    }
    componentWillUnmount() {
        compositeAuditStore.resetStatus();
    }
    render() {
        const status = compositeAuditStore.status;
        return (<PageHeader title="综合统计" style={{ background: "#FFF" }}>
        <Card>
          <Tabs type="card" activeKey={status.activeKey} onChange={activeKey => {
            status.activeKey = activeKey;
            routerStore.replace(`?activeKey=${activeKey}`);
        }}>
            <Tabs.TabPane tab="人员巡检" key="userCompositeAudit">
              <UserCompositeAudit />
            </Tabs.TabPane>
            <Tabs.TabPane tab="任务巡检" key="taskCompositeAudit">
              <TaskCompositeAudit />
            </Tabs.TabPane>
            <Tabs.TabPane tab="综合巡检" key="comprehensiveAudit">
              <ComprehensiveAudit />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </PageHeader>);
    }
};
CompositeAudit = __decorate([
    observer
], CompositeAudit);
export { CompositeAudit };
