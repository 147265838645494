var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import cls from "classnames";
import { Button, Input, Tooltip, Select } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, FileImageOutlined, HomeOutlined } from "@ant-design/icons";
import { routerStore } from "../../../../../../store/routerStore";
import { imageLabelMultiStore } from "../../ImageLabelMultiStore";
import { observer } from "mobx-react";
import { EventBlock } from "../../../../../../component/EventBlock/EventBlock";
import { CalWorkload } from "../../../../../../component/CalWorkload/CalWorkload";
import { multiScale, tranMultiScale } from "util/constant";
import "./LabelHeader.less";
let LabelHeader = class LabelHeader extends React.Component {
    render() {
        const status = imageLabelMultiStore.status;
        return (<div className="multi-label-header-component" onKeyDown={event => {
            event.stopPropagation();
        }}>
        <div>
          <Tooltip placement="bottomLeft" title="返回">
            <div className="icon-wrapper" onClick={() => {
            routerStore.goBack();
        }}>
              <HomeOutlined className="header-icon"/>
            </div>
          </Tooltip>
          <Tooltip placement="bottomLeft" title="跳转单图标注">
            <div className="icon-wrapper" onClick={() => {
            if (status.readonly) {
                routerStore.replace(`/label/image/label_task/${status.labelTaskId}/label_package/${status.labelPackageId}?readonly=true`);
            }
            else {
                routerStore.replace(`/label/image/label_task/${status.labelTaskId}/label_package/${status.labelPackageId}`);
            }
        }}>
              <FileImageOutlined className="header-icon"/>
            </div>
          </Tooltip>
        </div>
        <div className="header-center">
          <div className={cls({
            "icon-wrapper": true,
            disable: status.pager.getCurPage() === 1
        })} onClick={() => {
            imageLabelMultiStore.prevPage();
        }}>
            <ArrowLeftOutlined className="header-icon"/>
          </div>
          <div className={cls({
            "icon-wrapper": true,
            disable: status.pager.getCurPage() === status.pager.getTotalPage()
        })} style={{ marginLeft: 8 }} onClick={() => {
            imageLabelMultiStore.nextPage();
        }}>
            <ArrowRightOutlined className="header-icon"/>
          </div>
          <div style={{ width: "110px", marginLeft: 8 }}>
            <EventBlock blockEvent="keydown">
              <Input.Search size="small" enterButton="Go" onSearch={value => {
            const result = +value;
            if (typeof result === "number") {
                imageLabelMultiStore.jumpPage(result);
            }
        }}/>
            </EventBlock>
          </div>
          <div style={{ width: "150px", marginLeft: 8 }}>
            <EventBlock blockEvent="keydown">
              <Input.Search size="small" enterButton="调整单页" onSearch={value => {
            const result = +value;
            if (typeof result === "number") {
                imageLabelMultiStore.adjustPerPage(result);
            }
        }}/>
            </EventBlock>
          </div>
          <div style={{ width: "120px", marginLeft: 8 }}>
            <EventBlock blockEvent="keydown">
              <Input.Search size="small" enterButton="调整列" onSearch={value => {
            const result = +value;
            if (typeof result === "number") {
                status.isLoading = true;
                if (!result) {
                    status.columnNum = 5;
                }
                else {
                    status.columnNum = result;
                }
                status.isLoading = false;
            }
        }}/>
            </EventBlock>
          </div>
          <div style={{ marginLeft: 8 }}>
            <Button size="small" type="primary" onClick={async () => {
            status.isLoading = true;
            await imageLabelMultiStore.loadImageInfo();
            status.isLoading = false;
        }}>
              刷新
            </Button>
          </div>
          <div style={{ marginLeft: 8 }}>
            缩放模式：
            <Select size="small" style={{ width: 80 }} value={`${status.scale}`} onChange={value => {
            const result = +value;
            if (typeof result === "number") {
                imageLabelMultiStore.adjustScale(result);
            }
        }}>
              {multiScale.map(key => (<Select.Option value={`${key}`} key={key}>
                  {tranMultiScale[key]}
                </Select.Option>))}
            </Select>
          </div>
        </div>
        <div className="header-right">
          <CalWorkload labelTaskId={status.labelTaskId} labelPackageId={status.labelPackageId} resourceCount={status.pager.getTotalCount()}/>
        </div>
      </div>);
    }
};
LabelHeader = __decorate([
    observer
], LabelHeader);
export { LabelHeader };
