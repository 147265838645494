export default class Progress {
    constructor(opts = {}) {
        this.opts = {
            minimum: 0.08,
            speed: 200,
            trickle: true,
            trickleSpeed: 800,
            onChange: (status) => { }
        };
        this.status = 0;
        this.opts = Object.assign(Object.assign({}, this.opts), opts);
    }
    start() {
        this.set(this.opts.minimum);
        const work = () => {
            setTimeout(() => {
                this.inc();
                work();
            }, this.opts.trickleSpeed);
        };
        work();
    }
    done() {
        this.inc(0.3 + 0.5 * Math.random()).set(1);
    }
    set(n) {
        if (n < 1) {
            n = clamp(n, this.opts.minimum, 1);
        }
        this.status = n;
        this.opts.onChange(n);
        return this;
    }
    inc(amount) {
        let n = this.status;
        if (n === 1)
            return this;
        if (typeof amount === "undefined") {
            if (n >= 0 && n < 0.2) {
                amount = 0.1;
            }
            else if (n >= 0.2 && n < 0.5) {
                amount = 0.04;
            }
            else if (n >= 0.5 && n < 0.8) {
                amount = 0.02;
            }
            else if (n >= 0.8 && n < 0.99) {
                amount = 0.005;
            }
            else {
                amount = 0;
            }
        }
        n = clamp(n + amount, 0, 0.994);
        return this.set(n);
    }
}
function clamp(n, min, max) {
    if (n < min)
        return min;
    if (n > max)
        return max;
    return n;
}
