var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Collapse, Input, Popover } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import * as React from "react";
import { CompactPicker } from "react-color";
import "./Detection3DItem.less";
let Detection3DItem = class Detection3DItem extends React.Component {
    render() {
        const { detection3D, onDelete } = this.props;
        return (<div className="segment3d-item-comp">
        <Collapse.Panel key={detection3D.uniqKey} header={<div className="segment-outside" onClick={e => {
            e.stopPropagation();
        }}>
              <Popover content={<CompactPicker style={{ width: 200 }} value={detection3D.color} onChange={(color) => {
            detection3D.color = color.hex;
        }}/>}>
                <div style={{ width: 40, height: 40, borderRadius: "50%", background: detection3D.color }}/>
              </Popover>
              <Input placeholder="标注 key" value={detection3D.key} onChange={event => {
            detection3D.key = event.target.value;
        }}/>
              <Input placeholder="标注说明" value={detection3D.locale} onChange={event => {
            detection3D.locale = event.target.value;
        }}/>
              <DeleteOutlined onClick={() => {
            onDelete();
        }}/>
            </div>}></Collapse.Panel>
      </div>);
    }
};
Detection3DItem = __decorate([
    observer
], Detection3DItem);
export { Detection3DItem };
