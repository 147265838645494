export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function clearCanvas(canvas) {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
}
export function drawLine(canvas, startPoint, endPoint, color, thickness = 1) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.lineCap = "round";
    ctx.beginPath();
    ctx.moveTo(startPoint[0], startPoint[1]);
    ctx.lineTo(endPoint[0], endPoint[1]);
    ctx.stroke();
    ctx.restore();
}
export function drawPolygon(canvas, points, color, thickness = 1) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i][0], points[i][1]);
    }
    ctx.closePath();
    ctx.stroke();
    ctx.restore();
}
export function drawPolygonWithFill(canvas, points, color) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i][0], points[i][1]);
    }
    ctx.closePath();
    ctx.fill();
    ctx.restore();
}
export function drawCircleWithFill(canvas, point, radius, color) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    const startAngleRad = (0 * Math.PI) / 180;
    const endAngleRad = (360 * Math.PI) / 180;
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(point[0], point[1], radius, startAngleRad, endAngleRad, false);
    ctx.fill();
    ctx.restore();
}
export function drawCircle(canvas, point, radius, startAngleDeg, endAngleDeg, color, thickness = 1) {
    const ctx = canvas.getContext("2d");
    const startAngleRad = (startAngleDeg * Math.PI) / 180;
    const endAngleRad = (endAngleDeg * Math.PI) / 180;
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.arc(point[0], point[1], radius, startAngleRad, endAngleRad, false);
    ctx.stroke();
    ctx.restore();
}
export function drawRect(canvas, rect, color, thickness = 1, dash = 0) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.setLineDash([dash, dash]);
    ctx.beginPath();
    ctx.rect(rect.x, rect.y, rect.width, rect.height);
    ctx.stroke();
    ctx.restore();
}
export function drawText(canvas, text, textSize, postion, color, textAlign = "left") {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.fillStyle = color;
    ctx.textAlign = textAlign;
    ctx.textBaseline = "middle";
    ctx.font = textSize + "px Arial";
    ctx.fillText(text, postion.x, postion.y);
    ctx.restore();
}
export function distance(a, b) {
    return Math.sqrt(Math.pow(Math.abs(a[0] - b[0]), 2) + Math.pow(Math.abs(a[1] - b[1]), 2));
}
export function genRectFromPolygon(points) {
    const xs = points.map(v => v[0]);
    const ys = points.map(v => v[1]);
    const minX = Math.min.apply(null, xs);
    const minY = Math.min.apply(null, ys);
    const maxX = Math.max.apply(null, xs);
    const maxY = Math.max.apply(null, ys);
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}
