export class Color {
    static nextColor() {
        if (this.index + 1 > this.colorArr.length) {
            this.index = 0;
            return this.nextColor();
        }
        return this.colorArr[this.index++];
    }
}
Color.index = 0;
Color.colorArr = [
    "#F47373",
    "#697689",
    "#37D67A",
    "#2CCCE4",
    "#555555",
    "#DCE775",
    "#FF8A65",
    "#BA68C8",
    "#D9E3F0"
];
