var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { dailyWorkStore } from "./DailyWorkStore";
import { Card, PageHeader, Calendar, Button, Progress } from "antd";
import { observer } from "mobx-react";
import { Loading } from "../../../component/Loading/Loading";
import { routerStore } from "store/routerStore";
import { CreateScheduleModal } from "./component/CreateSchedule/CreateSchedule";
import "./DailyWork.less";
let DailyWork = class DailyWork extends React.Component {
    componentDidMount() {
        dailyWorkStore.loadData();
    }
    render() {
        const status = dailyWorkStore.status;
        return (<PageHeader title="每日工作" className="account-component" style={{ background: "#FFF" }}>
        <Card>
          <Button type="primary" onClick={() => {
            status.showCreateModal = true;
        }}>
            新建日程
          </Button>
          {status.isLoading ? (<Loading />) : (<Calendar dateCellRender={date => {
            const filterWorks = status.works.filter(work => work.fillDate === date.format("YYYY-MM-DD"));
            if (filterWorks.length > 0) {
                const duration = filterWorks.reduce((prev, curr) => {
                    return (prev = (prev * 10 + curr.duration * 10) / 10);
                }, 0);
                return (<div style={{ height: "100%" }} onClick={() => {
                    routerStore.push(`/manage/daily_work/${date.format("YYYY-MM-DD")}/schedule`);
                }}>
                      <Progress type="circle" strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068"
                }} percent={(duration / 7) * 100} width={60}/>
                    </div>);
            }
            else {
                return (<div style={{ height: "100%" }} onClick={() => {
                    routerStore.push(`/manage/daily_work/${date.format("YYYY-MM-DD")}/schedule`);
                }}></div>);
            }
        }} disabledDate={current => {
            return current && current > moment().endOf("day");
        }}/>)}
        </Card>

        {status.showCreateModal && (<CreateScheduleModal onCancel={() => {
            status.showCreateModal = false;
        }} onOk={(fillDate, workType, affair, duration, remark, taskId) => {
            dailyWorkStore.createDailyWork({
                fillDate,
                taskId,
                remark,
                duration,
                workType,
                affair
            });
        }}/>)}
      </PageHeader>);
    }
};
DailyWork = __decorate([
    observer
], DailyWork);
export { DailyWork };
