var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Button, Divider, Popconfirm, PageHeader, Table, Card } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { dailyWorkStore } from "../../DailyWorkStore";
import { CreateScheduleModal } from "../CreateSchedule/CreateSchedule";
import { ModifyScheduleModal } from "../ModifySchedule/ModifySchedule";
import { routerStore } from "store/routerStore";
import { DailyWork } from "definition/entity/daily-work";
import { Api } from "api/Api";
let ScheduleList = class ScheduleList extends React.Component {
    constructor() {
        super(...arguments);
        this.businessesColumns = [
            {
                title: "任务名称",
                dataIndex: "taskName",
                render: (value, record) => {
                    return (record === null || record === void 0 ? void 0 : record.taskName) || (record === null || record === void 0 ? void 0 : record.affair);
                }
            },
            {
                title: "工作类型",
                align: "center",
                render: (value, record) => {
                    switch (record.workType) {
                        case DailyWork.WorkType.Label:
                            return <span>标注</span>;
                        case DailyWork.WorkType.Modify:
                            return <span>修改</span>;
                        case DailyWork.WorkType.Review:
                            return <span>复审</span>;
                        case DailyWork.WorkType.Other:
                            return <span>其它</span>;
                    }
                }
            },
            {
                title: "说明",
                align: "center",
                dataIndex: "remark"
            },
            {
                title: "时长",
                width: "160px",
                align: "center",
                render: (value, record) => {
                    return `${record.duration}h`;
                }
            },
            {
                title: "操作",
                width: "200px",
                align: "center",
                render: (value, record) => {
                    return (<div>
            <Button type="link" size="small" onClick={() => {
                        dailyWorkStore.status.showModifyModal = true;
                        dailyWorkStore.status.modifyDailyWork = record;
                    }}>
              修改
            </Button>
            <React.Fragment>
              <Divider type="vertical"/>
              <Popconfirm title="确认要删除这个日程么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={async () => {
                        await Api.dailyWork.deleteDailyWork(record.id);
                        await dailyWorkStore.loadData();
                    }}>
                <Button type="link" size="small">
                  删除
                </Button>
              </Popconfirm>
            </React.Fragment>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        const fillDate = this.props.match.params.fillDate;
        dailyWorkStore.status.fillDate = fillDate;
        dailyWorkStore.loadData();
    }
    componentWillUnmount() {
        dailyWorkStore.resetStatus();
    }
    render() {
        const status = dailyWorkStore.status;
        return (<PageHeader title={`当前日期 ${status.fillDate}`} style={{ background: "#FFFFFF" }} onBack={() => {
            dailyWorkStore.resetStatus();
            routerStore.goBack();
        }}>
        <Card>
          <Button type="primary" onClick={() => {
            status.showCreateModal = true;
        }}>
            添加日程
          </Button>
          <Table loading={status.isLoading} rowKey="id" bordered={true} style={{ marginTop: "20px" }} columns={this.businessesColumns} dataSource={toJS(status.works)} pagination={{ showSizeChanger: false }}></Table>

          {status.showCreateModal && (<CreateScheduleModal fillDate={status.fillDate} onCancel={() => {
            status.showCreateModal = false;
        }} onOk={(fillDate, workType, affair, duration, remark, taskId) => {
            dailyWorkStore.createDailyWork({
                fillDate,
                taskId,
                remark,
                duration,
                workType,
                affair
            });
        }}/>)}

          {status.showModifyModal && (<ModifyScheduleModal schedule={status.modifyDailyWork} onCancel={() => {
            status.showModifyModal = false;
        }} onOk={(fillDate, workType, affair, duration, remark, taskId) => {
            dailyWorkStore.updateDailyWork(status.modifyDailyWork.id, {
                fillDate,
                taskId,
                remark,
                duration,
                workType,
                affair
            });
        }}/>)}
        </Card>
      </PageHeader>);
    }
};
ScheduleList = __decorate([
    observer
], ScheduleList);
export { ScheduleList };
