var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { accountStore } from "./AccountStore";
import { Card, PageHeader, Tooltip } from "antd";
import { observer } from "mobx-react";
import { IconFont } from "../../../component/IconFont/IconFont";
import { PasswordModify } from "./component/PasswordModify/PasswordModify";
import { Api } from "../../../api/Api";
import { Loading } from "../../../component/Loading/Loading";
import "./Account.less";
import { appStore } from "../../../store/AppStore";
let Account = class Account extends React.Component {
    componentDidMount() {
        accountStore.loadData();
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const status = accountStore.status;
        return (<PageHeader title="账号信息" className="account-component" style={{ background: "#FFF" }}>
        <Card>
          {status.isLoading ? (<Loading />) : (<div className="profile-items">
              <dl>
                <dt>账户名称:</dt>
                <dd>{(_a = status.user) === null || _a === void 0 ? void 0 : _a.name}</dd>
              </dl>
              {((_b = status.user) === null || _b === void 0 ? void 0 : _b.nickname) && (<dl>
                  <dt>账户昵称:</dt>
                  <dd>{(_c = status.user) === null || _c === void 0 ? void 0 : _c.nickname}</dd>
                </dl>)}
              <dl>
                <dt>单位名称:</dt>
                <dd>
                  {((_e = (_d = status.user) === null || _d === void 0 ? void 0 : _d.group) === null || _e === void 0 ? void 0 : _e.name) || "暂未加入单位"}&nbsp;
                  {((_f = status.user) === null || _f === void 0 ? void 0 : _f.id) && ((_g = status.user) === null || _g === void 0 ? void 0 : _g.id) === ((_j = (_h = status.user) === null || _h === void 0 ? void 0 : _h.group) === null || _j === void 0 ? void 0 : _j.leaderUserId) && (<Tooltip placement="top" title="单位负责人">
                      <IconFont type="icon-Star" style={{ color: "#5bc5b1" }}/>
                    </Tooltip>)}
                </dd>
              </dl>
              <dl>
                <dt>账户安全:</dt>
                <dd>
                  <a onClick={() => (status.isPasswordModifyVisible = true)}>修改密码</a>
                </dd>
              </dl>
              <dl>
                <dt>GitLab 邮箱:</dt>
                <dd>
                  {((_l = (_k = status.user) === null || _k === void 0 ? void 0 : _k.gitlab) === null || _l === void 0 ? void 0 : _l.email) ? (<div>
                      <span>{status.user.gitlab.email}</span>
                      <a style={{ marginLeft: "8px" }} onClick={() => {
            accountStore.unBindGitLab(status.user.gitlab.username);
        }}>
                        解绑
                      </a>
                    </div>) : (<a onClick={async () => {
            var _a, _b;
            const gitlabUrl = await Api.oauth.getGitLabOauthUrl();
            const redirectUrl = gitlabUrl.data +
                "?origin_url=" +
                encodeURIComponent(window.location.origin +
                    `/api/oauth/gitlab/bind_callback?id=${(_a = appStore.status.loginUser) === null || _a === void 0 ? void 0 : _a.id}&name=` +
                    encodeURIComponent(`${(_b = appStore.status.loginUser) === null || _b === void 0 ? void 0 : _b.name}`));
            window.location.href = redirectUrl;
        }}>
                      绑定 GitLab 账户
                    </a>)}
                </dd>
              </dl>
            </div>)}
        </Card>
        <PasswordModify visible={status.isPasswordModifyVisible} onCancel={() => (status.isPasswordModifyVisible = false)} onOk={async (oldPwd, newPwd) => {
            await accountStore.changePassword(oldPwd, newPwd);
        }}/>
      </PageHeader>);
    }
};
Account = __decorate([
    observer
], Account);
export { Account };
