var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterMode } from "../../../interface/common";
import { PainterDom } from "../../provider/painter-dom";
import { observer } from "mobx-react";
import { isLeftMouseClick } from "../../provider/mouse-button";
let DotDetection = class DotDetection extends React.Component {
    constructor() {
        super(...arguments);
        this.onMouseDown = event => {
            const status = imageLabelStore.status;
            if (!isLeftMouseClick(event))
                return;
            if (status.mode === PainterMode.DRAW)
                return;
            if (status.mode === PainterMode.UNSET) {
                const { element } = this.props;
                const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBG(event);
                status.mode = PainterMode.MOVE;
                const attributes = element.attributes;
                element.initAttribute = {
                    cursorX: offsetX,
                    cursorY: offsetY,
                    x: attributes.cx,
                    y: attributes.cy
                };
                status.selectedElementIds = [element.id];
            }
        };
    }
    render() {
        const { element } = this.props;
        const { label, attributes } = element;
        return (element.visible && (<circle {...attributes} r={5} fill={label.color} onMouseDown={this.onMouseDown}/>));
    }
};
DotDetection = __decorate([
    observer
], DotDetection);
export { DotDetection };
