var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { DetectItemViewer } from "./component/DetectItemViewer/DetectItemViewer";
import { ClassificationItemViewer } from "./component/ClassificationItemViewer/ClassificationItemViewer";
import { SegmentItemViewer } from "./component/SegmentItemViewer/SegmentItemViewer";
let LabelEditorViewer = class LabelEditorViewer extends React.Component {
    render() {
        const { label } = this.props;
        return (<div className="label-editor-com">
        {label === null || label === void 0 ? void 0 : label.detections.map(detection => (<DetectItemViewer key={detection.uniqKey} detect={detection}/>))}
        {label === null || label === void 0 ? void 0 : label.classifications.map(classification => (<ClassificationItemViewer key={classification.uniqKey} classification={classification}/>))}
        {label === null || label === void 0 ? void 0 : label.segments.map(segment => (<SegmentItemViewer key={segment.uniqKey} segment={segment}/>))}
      </div>);
    }
};
LabelEditorViewer = __decorate([
    observer
], LabelEditorViewer);
export { LabelEditorViewer };
