import classNames from "classnames";
import Color from "color";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
export var Orientation;
(function (Orientation) {
    Orientation[Orientation["VERTICAL"] = 0] = "VERTICAL";
    Orientation[Orientation["HORIZONTAL"] = 1] = "HORIZONTAL";
})(Orientation || (Orientation = {}));
const Sash = forwardRef((props, forwardedRef) => {
    const sashRef = useRef(null);
    const savedMouseEvent = useRef({
        type: "mouse",
        target: null,
        startX: 0,
        startY: 0,
        currentX: 0,
        currentY: 0
    });
    const savedKeyboardEvent = useRef({
        type: "keyboard",
        step: 1,
        direction: null,
        nativeEvent: null
    });
    const savedValue = useRef();
    const [selected, setSelected] = useState(false);
    const { orientation = Orientation.HORIZONTAL, size = 4, position = 0, style = {}, flag, color, onStart = () => { }, onChange = () => { }, onEnd = () => { }, useKeyboard = false } = props;
    const backgroundColor = useMemo(() => {
        if (color) {
            return selected ? Color(color).darken(0.5) : color;
        }
        return null;
    }, [selected, color]);
    const computedStyle = useMemo(() => {
        return orientation === Orientation.VERTICAL
            ? Object.assign({ width: size, height: "100%", left: position, backgroundColor }, style) : Object.assign({ width: "100%", height: size, top: position, backgroundColor }, style);
    }, [style, orientation, position, size, backgroundColor]);
    function addEvents() {
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    }
    function removeEvents() {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
    }
    function addKeyboardEvents() {
        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("keyup", onKeyUp);
    }
    function removeKeyboardEvents() {
        window.removeEventListener("keydown", onKeyDown);
        window.removeEventListener("keyup", onKeyUp);
    }
    const onMouseDown = (e) => {
        addEvents();
        savedMouseEvent.current.target = e.target;
        savedMouseEvent.current.startX = e.pageX;
        savedMouseEvent.current.startY = e.pageY;
        savedValue.current = onStart(savedMouseEvent.current);
        setSelected(true);
    };
    const onMouseMove = (e) => {
        if (savedMouseEvent.current.target) {
            savedMouseEvent.current.currentX = e.pageX;
            savedMouseEvent.current.currentY = e.pageY;
            onChange(savedMouseEvent.current, savedValue.current);
        }
    };
    const onMouseUp = (e) => {
        if (savedMouseEvent.current.target) {
            savedMouseEvent.current.currentX = e.pageX;
            savedMouseEvent.current.currentY = e.pageY;
            onEnd(savedMouseEvent.current, savedValue.current);
            savedMouseEvent.current.target = null;
            savedValue.current = undefined;
        }
        removeEvents();
    };
    const onKeyDown = (e) => {
        if (selected) {
            if (e.key === ",") {
                savedKeyboardEvent.current.nativeEvent = e;
                savedKeyboardEvent.current.direction = "left";
                onChange(savedKeyboardEvent.current);
            }
            else if (e.key === ".") {
                savedKeyboardEvent.current.nativeEvent = e;
                savedKeyboardEvent.current.direction = "right";
                onChange(savedKeyboardEvent.current);
            }
        }
    };
    const onKeyUp = (e) => { };
    const onWindowMouseDown = e => {
        var _a;
        if ((_a = sashRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
            return;
        }
        setSelected(false);
    };
    useEffect(() => {
        if (useKeyboard) {
            addKeyboardEvents();
        }
        return () => removeKeyboardEvents();
    }, [useKeyboard, selected]);
    useEffect(() => {
        window.addEventListener("mousedown", onWindowMouseDown);
        return () => {
            window.removeEventListener("mousedown", onWindowMouseDown);
        };
    }, []);
    return (<div ref={inst => {
        sashRef.current = inst;
        if (forwardedRef) {
            if (typeof forwardedRef === "function") {
                forwardedRef(inst);
            }
            else {
                forwardedRef.current = inst;
            }
        }
    }} className={classNames({ sash: true }, orientation === Orientation.VERTICAL ? "vertical" : "horizontal")} style={computedStyle} onMouseDown={onMouseDown}>
      {flag && (<div style={{ borderColor: backgroundColor }} className={classNames({ flag: true, [flag]: flag })}></div>)}
    </div>);
});
export default Sash;
