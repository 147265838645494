var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Card, Input, Select } from "antd";
import { observer } from "mobx-react";
import { ClassificationTool } from "definition/entity/label-task";
import "./ClassificationItemViewer.less";
let ClassificationItemViewer = class ClassificationItemViewer extends React.Component {
    render() {
        const { classification } = this.props;
        return (<Card className="classification-item-viewer-comp">
        <div className="classification-body-wrapper">
          <div>标签说明</div>
          <div className="item-desc-detail">
            <Input placeholder="问题 key" value={classification.key}/>
            <Input placeholder="问题说明" value={classification.locale}/>
            <Select className="item-type" value={classification.tool} placeholder="选择类型">
              <Select.Option key="switch">布尔</Select.Option>
              <Select.Option key="radio">单选</Select.Option>
              <Select.Option key="checkbox" disabled={true}>
                多选
              </Select.Option>
              <Select.Option key="text" disabled={true}>
                文本
              </Select.Option>
            </Select>
          </div>
          {[ClassificationTool.Checkbox, ClassificationTool.Radio].includes(classification.tool) && (<React.Fragment>
              <div>标签选项</div>
              {classification.options.map((option, index) => (<div key={option.uniqKey} className="item-options-detail">
                  <div style={{ width: 40, height: 40, borderRadius: "50%", background: option.color }}/>
                  <Input placeholder="选项 key" value={option.key}/>
                  <Input placeholder="选项说明" value={option.locale}></Input>
                </div>))}
            </React.Fragment>)}
        </div>
      </Card>);
    }
};
ClassificationItemViewer = __decorate([
    observer
], ClassificationItemViewer);
export { ClassificationItemViewer };
