import axios from "../util/axios";
import { deleteNilProperty } from "../util/delete-nil-property";
export const listLabelTasks = (params = {}) => {
    params = deleteNilProperty(params);
    return axios.get("/label_tasks", {
        params: params
    });
};
export const listSimpleLabelTasks = () => {
    return axios.get("/label_tasks/simple");
};
export const listPendingLabelTasks = (publishUserId) => {
    return axios.get(`/label_tasks/${publishUserId}/pending_tasks`);
};
export const updatePendingTasks = (publishUserId, params) => {
    return axios.put(`/label_tasks/${publishUserId}/pending_tasks`, params);
};
export const listPendingLabelPackages = (userId) => {
    return axios.get(`/label_packages/${userId}/pending_packages`);
};
export const updatePendingPackages = (curUserId, params) => {
    return axios.put(`/label_packages/${curUserId}/pending_packages`, params);
};
export const recoverPendingPackages = (curUserId, params) => {
    return axios.put(`/label_packages/${curUserId}/recover_pending_packages`, params);
};
export const createLabelTask = (createLabelTaskReq) => {
    return axios.post("/label_task", createLabelTaskReq);
};
export const cloneLabelTask = (labelTaskId, cloneLabelTaskReq) => {
    return axios.post(`/label_task/${labelTaskId}/clone`, cloneLabelTaskReq);
};
export const getLabelTask = (labelTaskId) => {
    return axios.get(`/label_task/${labelTaskId}`);
};
export const updateLabelTask = (labelTaskId, params) => {
    return axios.post(`/label_task/${labelTaskId}`, params);
};
export const deleteLabelTask = (labelTaskId) => {
    return axios.delete(`/label_task/${labelTaskId}`);
};
export const listLabelTaskPackages = (labelTaskId) => {
    return axios.get(`/label_task/${labelTaskId}/packages`);
};
export const listAssignedPackages = (params) => {
    return axios.post("/label_packages", deleteNilProperty(params));
};
export const listSandPackages = () => {
    return axios.get(`/label_packages/is_sand`);
};
export const getPackageWithTask = (labelTaskId, labelPackageId) => {
    return axios.get(`/label_task/${labelTaskId}/label_package/${labelPackageId}/label_info`);
};
export const updateLabelPackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}`, params);
};
export const getLabelPackageResources = (params) => {
    var _a, _b;
    const { labelTaskId, labelPackageId, offset, limit, filter } = params;
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/resources`, {
        offset: offset,
        limit: limit,
        classificationKey: (_a = filter === null || filter === void 0 ? void 0 : filter.classification) === null || _a === void 0 ? void 0 : _a.key,
        classificationValue: (_b = filter === null || filter === void 0 ? void 0 : filter.classification) === null || _b === void 0 ? void 0 : _b.value
    });
};
export const getLabelPackageWorkload = (labelTaskId, labelPackageId, params) => {
    return axios.get(`/label_task/${labelTaskId}/label_package/${labelPackageId}/workload`, {
        params
    });
};
export const updateResource = (resourceId, params) => {
    const { labelTaskId, labelPackageId, annotation, meta } = params;
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/resource/${resourceId}`, {
        annotation: annotation,
        meta: meta
    });
};
export const assignPackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/assign`, params);
};
export const revokePackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/revoke`, params);
};
export const submitPackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/submit`, params);
};
export const rejectPackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/reject`, params);
};
export const archivePackage = (labelTaskId, labelPackageId, resources) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/archive`, { resources });
};
export const transferPackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/transfer`, {
        userId: params.userId
    });
};
export const startPackage = (labelTaskId, labelPackageId) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/start`);
};
export const assessPackage = (labelTaskId, labelPackageId, params) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/assess`, params);
};
export const getLabelPackageAssessLog = (labelTaskId, labelPackageId) => {
    return axios.get(`/label_task/${labelTaskId}/label_package/${labelPackageId}/logs`);
};
export const getLabelPackageAssessDetail = (labelTaskId, labelPackageId) => {
    return axios.get(`/label_task/${labelTaskId}/label_package/${labelPackageId}/details`);
};
export const previewSyntheticImage = (labelTaskId, labelPackageId, resource) => {
    return axios.post(`/label_task/${labelTaskId}/label_package/${labelPackageId}/preview`, resource);
};
export const updatePcd = params => {
    return axios.post(`/pcd/update`, params);
};
