import { ILabelPackage } from "../definition/entity/label-package";
import { ILabelPackageAssignment } from "definition/entity/label-package-assignment";
export const transformLabelPackageStatus = (record) => {
    const curLabeled = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label && item.status === ILabelPackageAssignment.Status.Finished).length;
    const curReviewed = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Review && item.status === ILabelPackageAssignment.Status.Finished).length;
    const curAssessed = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label && item.status === ILabelPackageAssignment.Status.Assessed).length;
    const curFinalReviewed = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.FinalReview && item.status === ILabelPackageAssignment.Status.Finished).length;
    switch (record.status) {
        case ILabelPackage.Status.Unassigned:
            return "待分配";
        case ILabelPackage.Status.Assigned:
            if (curLabeled > 0) {
                return `驳回标注 x ${curLabeled}`;
            }
            return "已分配标注";
        case ILabelPackage.Status.Labelling:
            if (curLabeled > 0 || curAssessed > 0) {
                return `修改中 x ${curLabeled + curAssessed}`;
            }
            return "标注中";
        case ILabelPackage.Status.AssessFailed:
            if (curAssessed > 0) {
                return `评估不通过 x ${curAssessed}`;
            }
            return "评估不通过";
        case ILabelPackage.Status.Assessing:
            if (curAssessed > 0) {
                return `评估中 x ${curAssessed}`;
            }
            return "评估中";
        case ILabelPackage.Status.AssessPassed:
            return "评估通过";
        case ILabelPackage.Status.AssessError:
            return "评估失败";
        case ILabelPackage.Status.Labeled:
            return "标注完成";
        case ILabelPackage.Status.AssignReview:
            if (curReviewed > 0) {
                return `驳回复审 x ${curReviewed}`;
            }
            return "已分配复审";
        case ILabelPackage.Status.Reviewing:
            if (curReviewed > 0) {
                return `复审中 x ${curReviewed + 1}`;
            }
            return "复审中 x 1";
        case ILabelPackage.Status.Reviewed:
            return "复审通过";
        case ILabelPackage.Status.AssignFinalReview:
            if (curFinalReviewed > 0) {
                return `驳回终审 x ${curFinalReviewed}`;
            }
            return "已分配终审";
        case ILabelPackage.Status.FinalReviewing:
            if (curFinalReviewed > 0) {
                return `终审中 x ${curFinalReviewed + 1}`;
            }
            return "终审中 x 1";
        case ILabelPackage.Status.FinalReviewed:
            return "终审通过";
        case ILabelPackage.Status.Archived:
            return "已归档";
        case ILabelPackage.Status.Slicing:
            return "切割中";
        case ILabelPackage.Status.SliceError:
            return "切割失败";
        case ILabelPackage.Status.Sliced:
            return "切割完成";
    }
};
