import * as _ from "lodash";
const validKeyMap = {
    key: true,
    tool: true,
    detections: true,
    classifications: true
};
const trim = label => {
    Object.getOwnPropertyNames(label).forEach(key => {
        if (!validKeyMap[key]) {
            delete label[key];
        }
        else {
            if (key === "detections" || key === "classifications") {
                label[key].forEach(i => {
                    trim(i);
                });
            }
        }
    });
};
const innerCompare = (node1, node2, exact) => {
    const arrCompare = (arr1, arr2) => {
        if ((arr1.length === arr2.length && exact) || !exact) {
            const length = arr2.length;
            let set = {};
            arr1.forEach(i => {
                const key = i.key;
                set[key] = i;
            });
            for (let i = 0; i < length; i++) {
                const cur = arr2[i];
                const curKey = cur.key;
                if (!set[curKey]) {
                    return exact ? false : true;
                }
                else {
                    if (innerCompare(set[curKey], cur, exact)) {
                        delete set[curKey];
                    }
                    else {
                        return false;
                    }
                }
            }
            return Object.getOwnPropertyNames(set).length === 0;
        }
        else {
            return false;
        }
    };
    if (node1.key && node2.key) {
        if (node1.key !== node2.key) {
            return false;
        }
    }
    if (node1.tool && node2.tool) {
        if (node1.tool !== node2.tool) {
            return false;
        }
    }
    if (node1.classifications || node2.classifications) {
        if (!arrCompare(node1.classifications || [], node2.classifications || [])) {
            return false;
        }
    }
    if (node1.detections || node2.detections) {
        if (!arrCompare(node1.detections || [], node2.detections || [])) {
            return false;
        }
    }
    return true;
};
export const isLabelTaskLabelEqual = (label1, label2, exact = true) => {
    if (_.isNil(label1) || _.isNil(label2)) {
        return label1 === label2;
    }
    trim(label1);
    trim(label2);
    return innerCompare(label1, label2, exact);
};
