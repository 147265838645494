import axios from "../util/axios";
import { deleteNilProperty } from "util/delete-nil-property";
export const getLabelSandBags = (params) => {
    params = deleteNilProperty(params);
    return axios.get("/label_sand_bags", { params });
};
export const createLabelSandBagByUploaded = (params) => {
    return axios.post("/label_sand_bags/_uploaded", params);
};
export const createLabelSandBagByArchived = (params) => {
    return axios.post("/label_sand_bags/_archived", params);
};
export const shareSandBag = (bagId, sharedTo) => {
    return axios.post(`/label_sand_bags/${bagId}/_share`, { sharedTo });
};
export const getSharedSandBags = () => {
    return axios.get("/shared_sand_bags");
};
export const refuseLabelSandBag = (sharedBagId) => {
    return axios.post(`/shared_sand_bags/${sharedBagId}/_refuse`);
};
export const acceptLabelSandBag = (sharedBagId) => {
    return axios.post(`/shared_sand_bags/${sharedBagId}/_accept`);
};
export const deleteLabelSandBag = (bagId) => {
    return axios.delete(`/label_sand_bags/${bagId}`);
};
