export var ILabelSandBags;
(function (ILabelSandBags) {
    let Source;
    (function (Source) {
        Source[Source["ArchivedPackage"] = 0] = "ArchivedPackage";
        Source[Source["UserUploaded"] = 7] = "UserUploaded";
    })(Source = ILabelSandBags.Source || (ILabelSandBags.Source = {}));
})(ILabelSandBags || (ILabelSandBags = {}));
export var ISharedSandBags;
(function (ISharedSandBags) {
    let Status;
    (function (Status) {
        Status[Status["Pending"] = 0] = "Pending";
        Status[Status["Accepted"] = 1] = "Accepted";
        Status[Status["Refused"] = 2] = "Refused";
    })(Status = ISharedSandBags.Status || (ISharedSandBags.Status = {}));
})(ISharedSandBags || (ISharedSandBags = {}));
