import { rectPainter } from "./rect-painter";
import { dotPainter } from "./dot-painter";
import { pathPainter } from "./path-painter";
import { boxPainter } from "./box-painter";
import { dotPainter as anchorDotPainter } from "../anchor/painter/dot";
class PainterWrapperHandler {
    handle(event, painterMode, painterMouseEvent) {
        rectPainter.eventHandler(event, painterMode, painterMouseEvent);
        dotPainter.eventHandler(event, painterMode, painterMouseEvent);
        pathPainter.eventHandler(event, painterMode, painterMouseEvent);
        anchorDotPainter.eventHandler(event, painterMode, painterMouseEvent);
        boxPainter.eventHandler(event, painterMode, painterMouseEvent);
    }
    handleKeyboard(e) {
        rectPainter.keyboard(e);
        boxPainter.keyboard(e);
    }
}
export const painterWrapperHandler = new PainterWrapperHandler();
