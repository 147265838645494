var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { CompactPicker } from "react-color";
import { Button, Collapse, Input, InputNumber, Popover, Select, Tooltip, Switch } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ClassificationItem } from "../ClassificationItem/ClassificationItem";
import { genDefaultClassification } from "../../LabelEditorStore";
import { observer } from "mobx-react";
import { DetectionTool, ILabelTask } from "definition/entity/label-task";
import "./DetectItem.less";
import { IResource } from "definition/entity/resource";
let DetectItem = class DetectItem extends React.Component {
    render() {
        const { detect, resourceMime, onDelete, labelTaskType } = this.props;
        return (<div className="detect-item-comp">
        <Collapse expandIconPosition="end">
          <Collapse.Panel key={detect.uniqKey} header={<div className="detect-outside" onClick={e => {
            e.stopPropagation();
        }}>
                <Popover content={<CompactPicker style={{ width: 200 }} value={detect.color} onChange={(color) => {
            detect.color = color.hex;
        }}/>}>
                  <div style={{ width: 40, height: 40, borderRadius: "50%", background: detect.color }}/>
                </Popover>
                <Input placeholder="标注 key" value={detect.key} onChange={event => {
            detect.key = event.target.value;
        }}/>
                <Input placeholder="标注说明" value={detect.locale} onChange={event => {
            detect.locale = event.target.value;
        }}/>
                <Select value={detect.tool} onChange={(value, option) => {
            detect.tool = value;
        }} placeholder="选择类型">
                  {labelTaskType === ILabelTask.LabelTaskType.Clipping ? (<Select.Option key={DetectionTool.Chunk}>视频切割工具</Select.Option>) : ([
            <Select.Option key={DetectionTool.Dot}>点标注</Select.Option>,
            <Select.Option key={DetectionTool.Rectangle}>矩形标注</Select.Option>,
            <Select.Option disabled={resourceMime === IResource.Mime.Video} key={DetectionTool.Path}>
                        多边形标注
                      </Select.Option>,
            <Select.Option disabled={resourceMime === IResource.Mime.Video} key={DetectionTool.Box}>
                        立方体框标注
                      </Select.Option>,
            <Select.Option disabled={resourceMime !== IResource.Mime.Video} key={DetectionTool.Chunk}>
                        分段内容标注
                      </Select.Option>
        ])}
                </Select>
                {detect.tool === DetectionTool.Path && (<Tooltip title="标注点数">
                    <Switch checked={detect.setPoint} onChange={checked => {
            detect.setPoint = checked;
            if (!checked) {
                detect.points = undefined;
            }
        }}/>
                    <InputNumber disabled={!detect.setPoint} style={{ marginLeft: 10 }} min={4} value={detect.points} onChange={value => {
            detect.points = Math.floor(value);
        }}/>
                  </Tooltip>)}
                <DeleteOutlined onClick={() => {
            onDelete();
        }}/>
              </div>}>
            {detect.classifications.map(classification => (<ClassificationItem key={classification.uniqKey} classification={classification} withDefaultValue={true} onDelete={() => {
            detect.classifications = _.filter(detect.classifications, item => item.uniqKey != classification.uniqKey);
        }}/>))}
            <Button onClick={() => {
            detect.classifications.push(genDefaultClassification());
        }}>
              添加属性
            </Button>
          </Collapse.Panel>
        </Collapse>
      </div>);
    }
};
DetectItem = __decorate([
    observer
], DetectItem);
export { DetectItem };
