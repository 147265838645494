export var DailyWork;
(function (DailyWork) {
    let WorkType;
    (function (WorkType) {
        WorkType[WorkType["Other"] = 0] = "Other";
        WorkType[WorkType["Label"] = 1] = "Label";
        WorkType[WorkType["Modify"] = 2] = "Modify";
        WorkType[WorkType["Review"] = 3] = "Review";
    })(WorkType = DailyWork.WorkType || (DailyWork.WorkType = {}));
})(DailyWork || (DailyWork = {}));
