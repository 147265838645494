import * as React from "react";
import * as _ from "lodash";
import { DetectionTool } from "../../../../../../definition/entity/label-task";
import { RectDetection } from "./RectDetection";
import { PathDetection } from "./PathDetection";
import { DotDetection } from "./DotDetection";
import { BoxDetection } from "./BoxDetection";
import { PainterDom } from "../../provider/painter-dom";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterMode } from "../../../interface/common";
export class DetectionElement extends React.Component {
    constructor() {
        super(...arguments);
        this.handleOnMouseDown = event => {
            var _a;
            const status = imageLabelStore.status;
            if (status.mode === PainterMode.DRAW)
                return;
            (_a = document.getElementById(this.props.element.id)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        };
        this.handleOnContextMenu = event => {
            const status = imageLabelStore.status;
            if (!_.isEmpty(status.creatingElement))
                return;
            const element = this.props.element;
            const { offsetX, offsetY } = PainterDom.getOffsetOfWrapper(event);
            imageLabelStore.status.selectedElementIds = [element.id];
            imageLabelStore.status.spriteContextmenu = {
                visible: true,
                element: element,
                left: offsetX,
                top: offsetY
            };
        };
        this.onDoubleClick = () => {
            imageLabelStore.status.editingElementId = this.props.element.id;
        };
    }
    render() {
        const { element, showId } = this.props;
        const { label } = element;
        return (<React.Fragment>
        <g onMouseDown={this.handleOnMouseDown} onContextMenu={this.handleOnContextMenu} onDoubleClick={this.onDoubleClick}>
          {label.tool === DetectionTool.Rectangle && <RectDetection showId={showId} element={element}/>}
          {label.tool === DetectionTool.Dot && <DotDetection element={element}/>}
          {label.tool === DetectionTool.Path && <PathDetection element={element}/>}
          {label.tool === DetectionTool.Box && <BoxDetection element={element}/>}
        </g>
      </React.Fragment>);
    }
}
