var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { labelSandPackageStore } from "./LabelSandPackageStore";
import { Button, Card, Divider, PageHeader, Popconfirm, Table } from "antd";
import { toJS } from "mobx";
import { routerStore } from "../../../store/routerStore";
import { observer } from "mobx-react";
let LabelSandPackage = class LabelSandPackage extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "名称",
                dataIndex: "name",
                ellipsis: true,
                render: (value, record) => {
                    return <div>{record.name}</div>;
                }
            },
            {
                title: "操作",
                key: "action",
                align: "center",
                width: "300px",
                render: (value, record) => {
                    return (<div>
            <Button size="small" type="link" onClick={() => {
                        routerStore.push(`/label/image/label_task/${record.taskId}/label_package/${record.id}`);
                    }}>
              进入标注
            </Button>
            <Divider type="vertical"/>
            <Button size="small" type="link" onClick={() => {
                        window.open(`/api/label_task/${record.taskId}/label_package/${record.id}/generate_annotation_file`);
                    }}>
              标注文件
            </Button>
            <Divider type="vertical"/>
            <Popconfirm title="确认要将这个包取消沙子身份么？" onConfirm={async () => {
                        await labelSandPackageStore.revertTurnToSand(record);
                    }}>
              <Button size="small" type="link">
                取消沙子
              </Button>
            </Popconfirm>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        labelSandPackageStore.loadData();
    }
    render() {
        const status = labelSandPackageStore.status;
        return (<PageHeader title="标注沙子列表" style={{ background: "#FFF" }}>
        <Card>
          <Table bordered={true} rowKey={record => "" + record.id} dataSource={toJS(status.packages)} columns={this.columns} pagination={{ showSizeChanger: false }}/>
        </Card>
      </PageHeader>);
    }
};
LabelSandPackage = __decorate([
    observer
], LabelSandPackage);
export { LabelSandPackage };
