import * as _ from "lodash";
import { genRectFromPolygon } from "./component/canvas/helper";
import { PainterDom } from "./provider/painter-dom";
import randomColor from "randomcolor";
import { toJS } from "mobx";
export default class Group {
    constructor(store) {
        this.store = store;
        this.id = 0;
        this.groups = new Map();
    }
    get groupRects() {
        return this.store.status.groupRects;
    }
    set groupRects(val) {
        this.store.status.groupRects = val;
    }
    reset() {
        this.id = 0;
        this.groups = new Map();
    }
    findGroup(key) {
        for (let group of this.groups.values()) {
            if (group.some(el => el.id === key)) {
                return group;
            }
        }
    }
    findGroupId(key) {
        for (let item of this.groups.keys()) {
            const group = this.groups.get(item);
            if (group.some(el => el.id === key)) {
                return item;
            }
        }
    }
    getGroup(groupId) {
        return this.groups.get(groupId);
    }
    deleteGroup(id) {
        const groupId = typeof id === "number" ? id : this.findGroupId(id);
        const group = this.getGroup(groupId);
        if (group) {
            group.forEach(el => {
                el = _.find(this.store.status.layerElements, { id: el.id });
                el.detection.featureId = PainterDom.generateFeatureId();
                const rect = genRectFromPolygon(el.attributes.points);
                el.color = randomColor();
                el.detection.rect = rect;
            });
            this.groups.delete(groupId);
        }
        delete this.store.status.groupRects[groupId];
    }
    exitGroup(id) {
        const groupId = this.findGroupId(id);
        const group = this.findGroup(id);
        if (group) {
            if (group.length > 2) {
                _.remove(group, v => v.id === id);
                const target = _.find(this.store.status.layerElements, { id });
                target.detection.featureId = PainterDom.generateFeatureId();
                target.detection.rect = genRectFromPolygon(target.attributes.points);
                target.color = randomColor();
                const elements = group.map(el => _.find(this.store.status.layerElements, { id: el.id }));
                const allPoints = elements.reduce((s, a) => {
                    return s.concat(toJS(a.attributes.points));
                }, []);
                this.store.status.groupRects[groupId] = genRectFromPolygon(allPoints);
            }
            else {
                this.deleteGroup(id);
            }
        }
    }
    createGroup(els) {
        if (els.length > 1) {
            this.groups.set(++this.id, els);
            const hasSameFeatureId = els.every(el => el.detection.featureId == els[0].detection.featureId);
            if (hasSameFeatureId) {
                this.store.status.groupRects[this.id] = els[0].detection.rect;
            }
            else {
                const points = els
                    .map(e => toJS(e.attributes.points))
                    .reduce((s, a) => s.concat(a), []);
                this.store.status.groupRects[this.id] = genRectFromPolygon(points);
                const featureId = PainterDom.generateFeatureId();
                els.forEach(v => {
                    v.detection.featureId = featureId;
                });
            }
            els.forEach(v => {
                v.color = els[0].color;
                v.detection.rect = null;
            });
            return this.id;
        }
    }
    set(obj) {
        const { groups, groupRects } = obj;
        this.groups = groups;
        this.groupRects = groupRects;
    }
    get() {
        return {
            groups: new Map(this.groups),
            groupRects: toJS(this.groupRects)
        };
    }
}
