var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { Button, Card, Form, Input, PageHeader, Upload, Divider, Modal, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Controlled as CodeMirror } from "react-codemirror2";
import { routerStore } from "../../../../store/routerStore";
import { labellingAssessmentPolicyCreateStore } from "./LabellingAssessmentPolicyCreateStore";
import "codemirror/lib/codemirror.css";
import "codemirror/lib/codemirror.js";
import "codemirror/theme/blackboard.css";
import "codemirror/mode/python/python.js";
import "./LabellingAssessmentPolicyCreate.less";
import "codemirror/addon/selection/active-line.js";
const { TextArea } = Input;
let LabellingAssessmentPolicyCreate = class LabellingAssessmentPolicyCreate extends React.Component {
    constructor() {
        super(...arguments);
        this.editorRef = null;
    }
    componentWillUnmount() {
        labellingAssessmentPolicyCreateStore.clearGetAssessmentPolicyVerificationLoop();
        labellingAssessmentPolicyCreateStore.resetStatus();
    }
    render() {
        const status = labellingAssessmentPolicyCreateStore.status;
        const pyFilesProps = {
            accept: ".py",
            showUploadList: false,
            beforeUpload: file => {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = result => {
                    status.policyScript = result.target.result;
                };
                return false;
            }
        };
        return (<PageHeader title="创建评估方法" className="labelling-assessment-policy-create" style={{ background: "#FFF" }} onBack={() => {
            routerStore.push("/manage/labelling_assessment_policies");
        }}>
        <Card>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Divider orientation="left">基础信息</Divider>
            <Form.Item label="方法名称">
              <div style={{
            display: "grid",
            gridTemplateColumns: "auto 10px 1fr",
            gridGap: "10px",
            placeItems: "center center"
        }}>
                <span>{status.currentDateStr}</span>
                <span>-</span>
                <Input value={status.name} placeholder="请输入方法名称" onChange={event => {
            status.name = event.target.value;
        }}/>
              </div>
            </Form.Item>
            <Form.Item label="描述">
              <TextArea value={status.remark} placeholder="请输入描述" rows={4} onChange={event => {
            status.remark = event.target.value;
        }}/>
            </Form.Item>

            <Divider orientation="left">测试用例及评估脚本</Divider>
            <Form.Item label="基准标注">
              <Upload accept=".json" beforeUpload={file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = result => {
                status.groundTruthExample = result.target.result;
            };
            return false;
        }}>
                <Button>
                  <UploadOutlined /> 上传 JSON 文件
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item label="待评价标注">
              <Upload accept=".json" beforeUpload={file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = result => {
                status.assessingExample = result.target.result;
            };
            return false;
        }}>
                <Button>
                  <UploadOutlined /> 上传 JSON 文件
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item label="评估脚本">
              <Upload {...pyFilesProps}>
                <Button>
                  <UploadOutlined /> 上传 Python 脚本
                </Button>
              </Upload>

              {!_.isNil(status.policyScript) && (<CodeMirror className="code-mirror" value={status.policyScript} autoScroll={true} options={{ mode: "python", theme: "blackboard", lineNumbers: true, styleActiveLine: true }} onBeforeChange={(editor, data, value) => {
            status.policyScript = value;
        }}/>)}
            </Form.Item>
            <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 20 }}>
              <div style={{ float: "right" }}>
                <Button disabled={!labellingAssessmentPolicyCreateStore.isEnableAssessment || status.isAssessing} onClick={() => {
            labellingAssessmentPolicyCreateStore.temporaryVerify();
        }}>
                  验证
                </Button>
                <Button style={{ marginLeft: 10 }} disabled={!status.assessingExampleResult} onClick={() => {
            status.isShowAssessingResultModal = true;
        }}>
                  日志
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={() => {
            routerStore.goBack();
        }}>
                  取消
                </Button>
                <Button style={{ marginLeft: 10 }} disabled={!labellingAssessmentPolicyCreateStore.isEnableCreate || status.isAssessing} onClick={() => {
            labellingAssessmentPolicyCreateStore.createLabellingAssessmentPolicy();
        }}>
                  创建
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
        <Modal title="验证结果" width={800} open={status.isShowAssessingResultModal} footer={null} maskClosable={false} onCancel={() => {
            status.isShowAssessingResultModal = false;
        }}>
          <pre>{status.assessingExampleResult}</pre>
        </Modal>
        
        {status.isAssessing && (<div style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#000000",
            opacity: 0.5,
            display: "grid",
            gridTemplateColumns: "1fr",
            justifyItems: "center",
            alignItems: "center"
        }}>
            <Spin tip="加载中"/>
          </div>)}
      </PageHeader>);
    }
};
LabellingAssessmentPolicyCreate = __decorate([
    observer
], LabellingAssessmentPolicyCreate);
export { LabellingAssessmentPolicyCreate };
