var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import BasicStore from "../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../api/Api";
import { ILabelPackage } from "../../../definition/entity/label-package";
import { ILabelPackageAssignment } from "../../../definition/entity/label-package-assignment";
import { routerStore } from "store/routerStore";
import Pager from "model/Pager";
import { message } from "antd";
class Status {
    constructor() {
        this.packages = [];
        this.isLoading = false;
        this.nameFilter = "";
        this.pager = new Pager(10);
        this.curPackage = null;
        this.isTransferModalVisible = false;
        this.isAssessLogModalVisible = false;
        this.assessLogList = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "packages", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "nameFilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "pager", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof ILabelPackage !== "undefined" && ILabelPackage.DtoWithAssignments) === "function" ? _a : Object)
], Status.prototype, "curPackage", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isTransferModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isAssessLogModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "assessLogList", void 0);
class LabelPackageStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const { nameFilter } = this.status;
        this.status.isLoading = true;
        const packagesRes = await Api.labelTask.listAssignedPackages({
            name: nameFilter,
            offset: this.status.pager.getOffset(),
            limit: this.status.pager.getPerPage()
        });
        this.status.packages = packagesRes.data;
        this.status.pager = this.status.pager.setTotal(packagesRes.total);
        this.status.isLoading = false;
    }
    async transferPackage(toUserId) {
        const { curPackage } = this.status;
        const transferRes = await Api.labelTask.transferPackage(curPackage.taskId, curPackage.id, {
            userId: toUserId
        });
        if (transferRes.code === 200) {
            await this.loadData();
            this.status.isTransferModalVisible = false;
        }
    }
    async getLabelPackageAssessLog(labelTaskId, labelPackageId) {
        const assessLog = await Api.labelTask.getLabelPackageAssessLog(labelTaskId, labelPackageId);
        if (assessLog.code === 200) {
            assessLog.data.forEach(v => {
                try {
                    v.log = JSON.stringify(JSON.parse(v.log), null, 2);
                }
                catch (e) { }
            });
            this.status.assessLogList = assessLog.data;
        }
    }
    async previewTaskTemplateDocument(taskTemplateId) {
        const getDocumentUrl = await Api.taskTemplate.getTaskTemplateDocumentUrl(taskTemplateId);
        window.open(getDocumentUrl.data);
    }
    async submitPackage(params) {
        const { labelTaskId, labelPackageId, curStatus, remark } = params;
        let type;
        if (curStatus === ILabelPackage.Status.Labelling || curStatus === ILabelPackage.Status.AssessPassed) {
            type = ILabelPackageAssignment.Type.Label;
        }
        else if (curStatus === ILabelPackage.Status.Reviewing) {
            type = ILabelPackageAssignment.Type.Review;
        }
        else if (curStatus === ILabelPackage.Status.FinalReviewing) {
            type = ILabelPackageAssignment.Type.FinalReview;
        }
        else {
            return;
        }
        const submitRes = await Api.labelTask.submitPackage(labelTaskId, labelPackageId, {
            type: type,
            remark: remark
        });
        if (submitRes.code === 200) {
            await this.loadData();
        }
        else {
            message.error(submitRes.msg);
        }
    }
    routerToImageLabel(taskId, packageId, query) {
        if (!_.isNil(query)) {
            let search = "";
            Object.keys(query).forEach((key, index) => {
                if (index === 0) {
                    search += "?";
                }
                if (query[key]) {
                    search += `${key}=${query[key]}`;
                }
            });
            routerStore.push(`/label/image/label_task/${taskId}/label_package/${packageId}${search}`);
        }
        else {
            routerStore.push(`/label/image/label_task/${taskId}/label_package/${packageId}`);
        }
    }
    routerToVideoLabel(taskId, packageId, query) {
        if (!_.isNil(query)) {
            let search = "";
            Object.keys(query).forEach((key, index) => {
                if (index === 0) {
                    search += "?";
                }
                if (query[key]) {
                    search += `${key}=${query[key]}`;
                }
            });
            routerStore.push(`/label/video/label_task/${taskId}/label_package/${packageId}${search}`);
        }
        else {
            routerStore.push(`/label/video/label_task/${taskId}/label_package/${packageId}`);
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelPackageStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelPackageStore.prototype, "resetStatus", null);
export const labelPackageStore = new LabelPackageStore();
