export var ILabelTask;
(function (ILabelTask) {
    let Type;
    (function (Type) {
        Type[Type["Normal"] = 1] = "Normal";
        Type[Type["Mix"] = 2] = "Mix";
        Type[Type["Extract"] = 3] = "Extract";
        Type[Type["NewMix"] = 4] = "NewMix";
    })(Type = ILabelTask.Type || (ILabelTask.Type = {}));
    let Level;
    (function (Level) {
        Level[Level["First"] = 1] = "First";
        Level[Level["Second"] = 2] = "Second";
        Level[Level["Third"] = 3] = "Third";
        Level[Level["Fourth"] = 4] = "Fourth";
    })(Level = ILabelTask.Level || (ILabelTask.Level = {}));
    let Status;
    (function (Status) {
        Status[Status["Failed"] = -1] = "Failed";
        Status[Status["Creating"] = 0] = "Creating";
        Status[Status["Created"] = 1] = "Created";
        Status[Status["Doing"] = 2] = "Doing";
        Status[Status["Completed"] = 3] = "Completed";
    })(Status = ILabelTask.Status || (ILabelTask.Status = {}));
    let TaskType;
    (function (TaskType) {
        TaskType[TaskType["Label"] = 0] = "Label";
        TaskType[TaskType["Segment"] = 1] = "Segment";
    })(TaskType = ILabelTask.TaskType || (ILabelTask.TaskType = {}));
    let LabelTaskType;
    (function (LabelTaskType) {
        LabelTaskType[LabelTaskType["Classification"] = 0] = "Classification";
        LabelTaskType[LabelTaskType["Detection"] = 1] = "Detection";
        LabelTaskType[LabelTaskType["Segmentation"] = 2] = "Segmentation";
        LabelTaskType[LabelTaskType["Anchor"] = 3] = "Anchor";
        LabelTaskType[LabelTaskType["Clipping"] = 4] = "Clipping";
    })(LabelTaskType = ILabelTask.LabelTaskType || (ILabelTask.LabelTaskType = {}));
    let LabelTaskTypeCN;
    (function (LabelTaskTypeCN) {
        LabelTaskTypeCN[LabelTaskTypeCN["\u5206\u7C7B"] = 0] = "\u5206\u7C7B";
        LabelTaskTypeCN[LabelTaskTypeCN["\u68C0\u6D4B"] = 1] = "\u68C0\u6D4B";
        LabelTaskTypeCN[LabelTaskTypeCN["\u5206\u5272"] = 2] = "\u5206\u5272";
        LabelTaskTypeCN[LabelTaskTypeCN["\u951A\u70B9"] = 3] = "\u951A\u70B9";
        LabelTaskTypeCN[LabelTaskTypeCN["\u5207\u5272"] = 4] = "\u5207\u5272";
    })(LabelTaskTypeCN = ILabelTask.LabelTaskTypeCN || (ILabelTask.LabelTaskTypeCN = {}));
})(ILabelTask || (ILabelTask = {}));
export var DetectionTool;
(function (DetectionTool) {
    DetectionTool["Rectangle"] = "rectangle";
    DetectionTool["Dot"] = "dot";
    DetectionTool["Path"] = "path";
    DetectionTool["Chunk"] = "chunk";
    DetectionTool["Box"] = "box";
})(DetectionTool || (DetectionTool = {}));
export var AnchorTool;
(function (AnchorTool) {
    AnchorTool["Dot"] = "dot";
})(AnchorTool || (AnchorTool = {}));
export var ClassificationTool;
(function (ClassificationTool) {
    ClassificationTool["Switch"] = "switch";
    ClassificationTool["Radio"] = "radio";
    ClassificationTool["Checkbox"] = "checkbox";
    ClassificationTool["Text"] = "text";
    ClassificationTool["Date"] = "date";
    ClassificationTool["Number"] = "number";
    ClassificationTool["Time"] = "time";
    ClassificationTool["Select"] = "select";
})(ClassificationTool || (ClassificationTool = {}));
export var Segment3DTool;
(function (Segment3DTool) {
    Segment3DTool["Cloud"] = "cloud";
})(Segment3DTool || (Segment3DTool = {}));
export var FilterPrefix;
(function (FilterPrefix) {
    FilterPrefix["Name"] = "name";
    FilterPrefix["Creator"] = "creator";
})(FilterPrefix || (FilterPrefix = {}));
