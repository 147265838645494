import { IResource } from "../definition/entity/resource";
export const resourceMimeDesc = (mime) => {
    switch (mime) {
        case IResource.Mime.Image:
            return "图片";
        case IResource.Mime.Video:
            return "视频";
        case IResource.Mime.Audio:
            return "音频";
        case IResource.Mime.Text:
            return "文本";
        case IResource.Mime.Cloud:
            return "点云";
        default:
            return "未知";
    }
};
