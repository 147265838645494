export var ILabelPackageAssignment;
(function (ILabelPackageAssignment) {
    let Type;
    (function (Type) {
        Type[Type["Label"] = 0] = "Label";
        Type[Type["Review"] = 1] = "Review";
        Type[Type["FinalReview"] = 2] = "FinalReview";
    })(Type = ILabelPackageAssignment.Type || (ILabelPackageAssignment.Type = {}));
    let Status;
    (function (Status) {
        Status[Status["Pending"] = 0] = "Pending";
        Status[Status["Running"] = 1] = "Running";
        Status[Status["Assessed"] = 3] = "Assessed";
        Status[Status["Finished"] = 2] = "Finished";
    })(Status = ILabelPackageAssignment.Status || (ILabelPackageAssignment.Status = {}));
})(ILabelPackageAssignment || (ILabelPackageAssignment = {}));
