import { BasePainter } from "./base-painter";
import { imageLabelStore } from "../../../ImageLabelStore";
import { DetectionTool } from "../../../../../../../definition/entity/label-task";
import { PainterDom } from "../../../provider/painter-dom";
export class DotPainter extends BasePainter {
    Draw_MouseDown(event) {
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel } = status;
        if (!(curLabel.tool === DetectionTool.Dot && curLabel.type === "detection"))
            return;
        const id = PainterDom.generateId(curLabel.key);
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        status.layerElements = status.layerElements.concat({
            id: id,
            label: curLabel,
            visible: true,
            attributes: {
                cx: offsetX,
                cy: offsetY
            },
            detection: {
                key: curLabel.key,
                value: null,
                classifications: curLabel.classifications.map(cls => ({ key: cls.key, value: cls.defaultValue })),
                tool: DetectionTool.Dot,
                trails: [
                    {
                        cx: offsetX / status.zoomRatio,
                        cy: offsetY / status.zoomRatio,
                        cover: false,
                        inview: true,
                        frame: imageLabelStore.currentFrame
                    }
                ]
            },
            display: "normal"
        });
    }
    Move_MouseMove(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Dot, "detection"))
            return;
        const { selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        const { x, y } = element.initAttribute;
        const { movementX, movementY } = PainterDom.getDotMovementOfPainterBGWithLimit({
            movementX: offsetX - x,
            movementY: offsetY - y
        }, {
            cx: x,
            cy: y
        });
        Object.assign(element.attributes, {
            cx: x + movementX,
            cy: y + movementY
        });
    }
    Move_MouseUp(event) {
        var _a, _b, _c;
        if (!this.isValidSelectOneEle(DetectionTool.Dot, "detection"))
            return;
        const { currentFrame, selectedElements, status } = imageLabelStore;
        const element = selectedElements[0];
        if (element) {
            const attributes = element.attributes;
            const targetPath = (_a = element.detection.trails) === null || _a === void 0 ? void 0 : _a.filter(v => v.frame === currentFrame)[0];
            if (targetPath) {
                targetPath.cx = attributes.cx / status.zoomRatio;
                targetPath.cy = attributes.cy / status.zoomRatio;
            }
            else {
                (_b = element.detection.trails) === null || _b === void 0 ? void 0 : _b.push({
                    cx: attributes.cx / status.zoomRatio,
                    cy: attributes.cy / status.zoomRatio,
                    inview: true,
                    cover: false,
                    frame: currentFrame
                });
                element.detection.trails = (_c = element.detection.trails) === null || _c === void 0 ? void 0 : _c.sort((a, b) => a.frame - b.frame);
            }
        }
    }
}
export const dotPainter = new DotPainter();
