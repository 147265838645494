var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Color from "color";
import _ from "lodash";
import { reaction, toJS } from "mobx";
import { observer } from "mobx-react";
import React, { createRef } from "react";
import Sash, { Orientation } from "../../../component/Sash";
import Ruler from "../image/ImageLabel/component/PainterWrapper/Ruler";
import { imageLabelStore } from "../image/ImageLabel/ImageLabelStore";
import "./Image2D.less";
import { drawCircleWithFill } from "../image/ImageLabel/component/canvas/helper";
import { clearCanvas } from "../image/ImageLabel/component/canvas/helper";
import colormap from "colormap";
let colors = colormap({
    colormap: "jet",
    nshades: 10,
    format: "hex",
    alpha: 1
});
let RenderDetections3D = class RenderDetections3D extends React.Component {
    constructor() {
        super(...arguments);
        this.boxElMap = {};
    }
    scrollIntoView() {
        const el = this.boxElMap[this.props.selectedBoxId];
        if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }
    }
    render() {
        const { detections3D = [], width, height, zoom, selectedBoxId, onChangeEnd } = this.props;
        return (<svg>
        {detections3D
            .sort(v => (v.id === selectedBoxId ? 1 : -1))
            .map((detection, idx) => {
            const { value: points = [], label, id, _uid } = detection;
            const [p0, p1, p2, p3, p4, p5, p6, p7] = points.map(p => [p[0] * zoom, p[1] * zoom]);
            const frontPoints = [p0, p1, p3, p2];
            const _frontPointsIdxMap = { 0: 0, 1: 1, 2: 3, 3: 2 };
            const frontPolygon = frontPoints.map(p => `${p[0]},${p[1]}`).join(" ");
            const backPoints = [p4, p5, p7, p6];
            const _backPointsIdxMap = { 0: 4, 1: 5, 2: 7, 3: 6 };
            const backPolygon = backPoints.map(p => `${p[0]},${p[1]}`).join(" ");
            const line1 = [p0, p4];
            const line2 = [p1, p5];
            const line3 = [p2, p6];
            const line4 = [p3, p7];
            const dash = 12 * zoom;
            const fillColor = Color(label.color)
                .alpha(0.6)
                .toString();
            const box = _.find(imageLabelStore.status.boxes, { id });
            const visible = box === null || box === void 0 ? void 0 : box.visible;
            const titleVisible = box === null || box === void 0 ? void 0 : box.titleVisible;
            const selected = selectedBoxId === id;
            const textPosition = {
                x: (p0[0] + p2[0]) / 2,
                y: p0[1]
            };
            return (<g ref={el => (this.boxElMap[id] = el)} key={idx} onMouseDown={() => {
                imageLabelStore.status.selectedBoxId = id;
            }} style={{ visibility: visible ? "visible" : "hidden" }}>
                {titleVisible && (<text x={textPosition.x} y={textPosition.y} fill="#fff" fontSize={14 * zoom}>
                    {_uid}
                  </text>)}

                <polygon strokeLinejoin="round" points={frontPolygon} style={{ stroke: label.color, fill: fillColor }}/>
                <polygon strokeLinejoin="round" points={backPolygon} style={{ stroke: label.color, fill: "transparent" }}/>

                <line x1={line1[0][0]} y1={line1[0][1]} x2={line1[1][0]} y2={line1[1][1]} strokeDasharray={`${dash} ${dash}`} style={{ stroke: label.color }}/>
                <line x1={line2[0][0]} y1={line2[0][1]} x2={line2[1][0]} y2={line2[1][1]} strokeDasharray={`${dash} ${dash}`} style={{ stroke: label.color }}/>
                <line x1={line3[0][0]} y1={line3[0][1]} x2={line3[1][0]} y2={line3[1][1]} strokeDasharray={`${dash} ${dash}`} style={{ stroke: label.color }}/>
                <line x1={line4[0][0]} y1={line4[0][1]} x2={line4[1][0]} y2={line4[1][1]} strokeDasharray={`${dash} ${dash}`} style={{ stroke: label.color }}/>
                {selected &&
                frontPoints.map((point, index) => (<Circle key={index} cx={point[0]} cy={point[1]} r={5} fill="#FFF" strokeWidth={1} stroke={label.color} style={{ cursor: "pointer" }} onDragStart1={() => {
                    return toJS(_.find(detections3D, { id }).value[_frontPointsIdxMap[index]]);
                }} onDrag1={e => {
                    const deltaX = e.current.pageX - e.start.pageX;
                    const deltaY = e.current.pageY - e.start.pageY;
                    const data = e.data;
                    const x = data[0] + deltaX / zoom;
                    const y = data[1] + deltaY / zoom;
                    _.set(_.find(detections3D, { id }).value, `[${_frontPointsIdxMap[index]}]`, [x, y]);
                }}/>))}
                {selected &&
                backPoints.map((point, index) => (<Circle key={index} cx={point[0]} cy={point[1]} r={5} fill="#FFF" strokeWidth={1} stroke={label.color} style={{ cursor: "pointer" }} onDragStart1={() => {
                    return toJS(_.find(detections3D, { id }).value[_backPointsIdxMap[index]]);
                }} onDrag1={e => {
                    const deltaX = e.current.pageX - e.start.pageX;
                    const deltaY = e.current.pageY - e.start.pageY;
                    const data = e.data;
                    const x = data[0] + deltaX / zoom;
                    const y = data[1] + deltaY / zoom;
                    _.set(_.find(detections3D, { id }).value, `[${_backPointsIdxMap[index]}]`, [x, y]);
                }}/>))}
              </g>);
        })}
      </svg>);
    }
};
RenderDetections3D = __decorate([
    observer
], RenderDetections3D);
let Image2D = class Image2D extends React.Component {
    constructor() {
        super(...arguments);
        this.imgRef = createRef();
        this.imgContainerRef = createRef();
        this.renderDetections3DRef = createRef();
        this.canvasRef = createRef();
        this.initZoom = 1;
        this.prevZoom = 1;
        this.initWidth = 400;
        this.prevSize = {
            width: this.initWidth,
            height: null
        };
        this.prev = {};
        this.naturalWidth = 0;
        this.naturalHeight = 0;
        this.state = {
            zoom: 1,
            position: {
                x: 0,
                y: 0
            },
            size: {
                width: this.initWidth,
                height: null
            },
            ruler: {
                x: -1,
                y: -1,
                text: ""
            }
        };
        this.isResize = false;
        this.isFullscreen = false;
        this.onLoad = (e) => {
            const img = e.target;
            this.naturalWidth = img.naturalWidth;
            this.naturalHeight = img.naturalHeight;
            const zoom = this.initWidth / img.naturalWidth;
            this.prevZoom = zoom;
            this.initZoom = zoom;
            this.setState({
                zoom,
                size: {
                    width: this.initWidth,
                    height: this.initZoom * this.naturalHeight
                }
            }, () => {
                this.updateCanvasSize();
                this.renderProjection();
            });
        };
        this.onWheel = (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
                const container = this.imgContainerRef.current;
                const img = this.imgRef.current;
                const deltaY = e.deltaY * -0.01;
                const prevZoom = this.state.zoom;
                const zoom = Math.max(this.state.zoom + deltaY, this.initZoom);
                const delta = zoom / prevZoom;
                const rect = img.getBoundingClientRect();
                const left = e.pageX - rect.left;
                const top = e.pageY - rect.top;
                const newScrollLeft = container.scrollLeft + (delta - 1) * left;
                const newScrollTop = container.scrollTop + (delta - 1) * top;
                this.setState({ zoom }, () => {
                    container.scrollTo(newScrollLeft, newScrollTop);
                    this.updateCanvasSize();
                    this.renderProjection();
                });
            }
        };
        this.onScroll = (e) => {
            const imgContainer = this.imgContainerRef.current;
            const img = this.imgRef.current;
            const left = imgContainer.scrollLeft;
            const top = imgContainer.scrollTop;
        };
        this.onStart = (e) => {
            this.prevZoom = this.state.zoom;
            this.prevSize = this.state.size;
            this.isResize = true;
        };
        this.onChange = (e, orientation) => {
            if (e.type === "mouse") {
                const deltaX = e.currentX - e.startX;
                const deltaY = e.currentY - e.startY;
                let width = 0;
                let height = 0;
                let zoom = 0;
                if (orientation === "horizontal") {
                    height = this.prevSize.height + deltaY;
                    zoom = height / this.naturalHeight;
                    width = zoom * this.naturalWidth;
                }
                else {
                    width = this.prevSize.width + deltaX;
                    zoom = width / this.naturalWidth;
                    height = zoom * this.naturalHeight;
                }
                this.setState({
                    zoom,
                    size: {
                        width,
                        height
                    }
                }, () => {
                    this.updateCanvasSize();
                    this.renderProjection();
                });
                this.initZoom = zoom;
            }
        };
        this.onEnd = e => {
            this.isResize = false;
        };
        this.onMouseDown = (e) => {
            const container = this.imgContainerRef.current;
            const start = { x: e.pageX, y: e.pageY };
            const prevPosition = this.state.position;
            const prevScrollPosition = {
                left: container.scrollLeft,
                top: container.scrollTop
            };
            if (this.isResize) {
                return;
            }
            const isContainerMoveable = this.props.selectedBoxId == null;
            const onMouseMove = (e) => {
                const deltaX = e.pageX - start.x;
                const deltaY = e.pageY - start.y;
                if (!isContainerMoveable) {
                    container.scrollTo(prevScrollPosition.left - deltaX, prevScrollPosition.top - deltaY);
                }
                else {
                    const newPosition = {
                        x: prevPosition.x + deltaX,
                        y: prevPosition.y + deltaY
                    };
                    this.setState({ position: newPosition });
                }
            };
            const onMouseUp = (e) => {
                window.removeEventListener("mousemove", onMouseMove);
                window.removeEventListener("mouseup", onMouseUp);
            };
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
            this.props.onSelect();
        };
        this.updateCanvasSize = () => {
            this.canvasRef.current.width = this.imgWidth;
            this.canvasRef.current.height = this.imgHeight;
        };
        this.renderProjection = () => {
            const canvas = this.canvasRef.current;
            const points = this.props.projectionPoints;
            const cloudData = this.props.cloudData || [];
            if (points) {
                clearCanvas(canvas);
                points.forEach((p, idx) => {
                    const color = colors[Math.round(cloudData[idx].z)];
                    drawCircleWithFill(canvas, p.map(v => v * this.state.zoom), 1, color);
                });
            }
        };
    }
    componentDidMount() {
        this.imgContainerRef.current.addEventListener("wheel", this.onWheel, { passive: false });
        this.imgContainerRef.current.addEventListener("scroll", this.onScroll);
        this.disposer = reaction(() => this.props.projectionPoints, () => {
            this.renderProjection();
        });
    }
    componentWillUnmount() {
        this.disposer();
    }
    renderPath(segments = [], width, height, zoom) {
        return (<svg width={width} height={height}>
        {segments.map((segment, idx) => {
            const points = segment.value || [];
            let pathRoute = "M";
            points.forEach((pos, idx) => {
                if (idx !== 0) {
                    pathRoute += "L";
                }
                pathRoute += `${pos[0] * zoom} ${pos[1] * zoom}` + " ";
            });
            return (<path key={idx} d={pathRoute} fill={segment.color} fillOpacity={0.8} stroke={"ref"} strokeLinejoin="round" strokeLinecap="round" strokeWidth={2} strokeOpacity={1}/>);
        })}
      </svg>);
    }
    get imgWidth() {
        return this.state.zoom * this.naturalWidth;
    }
    get imgHeight() {
        return this.state.zoom * this.naturalHeight;
    }
    requestFullscreen() {
        if (this.isFullscreen)
            return;
        const zoom = this.props.maxWidth / this.naturalWidth;
        const size = { width: this.naturalWidth * zoom, height: this.naturalHeight * zoom };
        const position = { x: 0, y: 0 };
        this.prev = _.cloneDeep(this.state);
        this.setState({ zoom, size, position }, () => {
            this.updateCanvasSize();
            this.renderProjection();
        });
        this.isFullscreen = true;
    }
    cancelFullscreen() {
        if (this.isFullscreen) {
            const { zoom, size, position } = this.prev;
            this.setState({ zoom, size, position }, () => {
                this.updateCanvasSize();
                this.renderProjection();
            });
            this.isFullscreen = false;
        }
    }
    toggleScreen() {
        this.isFullscreen ? this.cancelFullscreen() : this.requestFullscreen();
    }
    render() {
        const { url, segments, detections3D, selectedBoxId } = this.props;
        const { zoom, position, size: { width, height }, ruler } = this.state;
        const imgWidth = this.imgWidth;
        const imgHeight = this.imgHeight;
        const points = toJS(detections3D)
            .map(v => v.value)
            .reduce((a, b) => a.concat(b), []);
        const minimumXPoint = _.minBy(points, v => v[0]);
        const maximumXPoint = _.maxBy(points, v => v[0]);
        const minimumYPoint = _.minBy(points, v => v[1]);
        const maximumYPoint = _.maxBy(points, v => v[1]);
        const offset = {
            left: minimumXPoint && minimumXPoint[0] < 0 ? Math.abs(minimumXPoint[0] * zoom) : 0,
            top: minimumYPoint && minimumYPoint[1] < 0 ? Math.abs(minimumYPoint[1] * zoom) : 0,
            width: maximumXPoint ? maximumXPoint[0] * zoom : imgWidth,
            height: maximumYPoint ? maximumYPoint[1] * zoom : imgHeight
        };
        return (<div className="image-2d" style={{ width, height, left: position.x, top: position.y, zIndex: imageLabelStore.image2DViewZIndex }} onMouseDown={e => {
            this.onMouseDown(e);
        }} onMouseMove={e => {
            const { zoomRatio } = imageLabelStore.status;
            const { left, top } = this.imgContainerRef.current.getBoundingClientRect();
            const offsetX = e.pageX - left;
            const offsetY = e.pageY - top;
            const text = `(${(offsetX / zoomRatio).toFixed(0)}, ${(offsetY / zoomRatio).toFixed(0)})`;
            this.setState({
                ruler: {
                    x: offsetX,
                    y: offsetY,
                    text: ""
                }
            });
        }}>
        <div ref={this.imgContainerRef} className="aux" style={{ width: "100%", height: "100%", overflow: "auto", position: "relative", backgroundColor: "#fff" }}>
          <img className="image" ref={this.imgRef} draggable={false} width="100%" height="100%" onLoad={e => this.onLoad(e)} src={url} alt="" style={{ width: imgWidth, height: imgHeight, left: offset.left, top: offset.top }}/>
          <canvas ref={this.canvasRef} className="projection" style={{ width: imgWidth, height: imgHeight, left: offset.left, top: offset.top, position: "absolute" }}></canvas>
          <div className="annotation" style={offset}>
            {this.renderPath(segments, imgWidth, imgHeight, zoom)}
            <RenderDetections3D detections3D={detections3D} width={imgWidth} height={imgHeight} zoom={zoom} selectedBoxId={selectedBoxId} ref={this.renderDetections3DRef}/>
          </div>
        </div>

        <Sash position={height - 2} useKeyboard={false} orientation={Orientation.HORIZONTAL} onChange={e => this.onChange(e, "horizontal")} onStart={e => this.onStart(e)} onEnd={e => this.onEnd(e)}></Sash>
        <Sash position={width - 2} useKeyboard={false} orientation={Orientation.VERTICAL} onChange={e => this.onChange(e, "vertical")} onStart={e => this.onStart(e)} onEnd={e => this.onEnd(e)}></Sash>
        {imageLabelStore.status.curSelectedLabel && (<Ruler {...ruler} width={width} height={height} color={imageLabelStore.status.curSelectedLabel.color}></Ruler>)}
      </div>);
    }
};
Image2D = __decorate([
    observer
], Image2D);
export default Image2D;
class Circle extends React.Component {
    constructor() {
        super(...arguments);
        this.ref = createRef();
        this.onMouseDown = (e) => {
            var _a, _b;
            e.stopPropagation();
            const start = {
                pageX: e.pageX,
                pageY: e.pageY
            };
            const current = Object.assign({}, start);
            const data = (_b = (_a = this.props).onDragStart1) === null || _b === void 0 ? void 0 : _b.call(_a, { start, current });
            const onMouseMove = e => {
                var _a, _b;
                const current = {
                    pageX: e.pageX,
                    pageY: e.pageY
                };
                (_b = (_a = this.props).onDrag1) === null || _b === void 0 ? void 0 : _b.call(_a, { start, current, data });
            };
            const onMouseUp = e => {
                var _a, _b;
                const current = {
                    pageX: e.pageX,
                    pageY: e.pageY
                };
                (_b = (_a = this.props).onDragEnd1) === null || _b === void 0 ? void 0 : _b.call(_a, { start, current, data });
                window.removeEventListener("mousemove", onMouseMove);
                window.removeEventListener("mouseup", onMouseUp);
            };
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
        };
    }
    addEvents() {
        const target = this.ref.current;
        target.addEventListener("mousedown", this.onMouseDown);
    }
    removeEvents() {
        const target = this.ref.current;
        target.removeEventListener("mousedown", this.onMouseDown);
    }
    componentDidMount() {
        this.addEvents();
    }
    componentWillUnmount() {
        this.removeEvents();
    }
    render() {
        const _a = this.props, { onDrag1, onDragStart1, onDragEnd1, children } = _a, rest = __rest(_a, ["onDrag1", "onDragStart1", "onDragEnd1", "children"]);
        return <circle {...rest} ref={this.ref}/>;
    }
}
