export default class LassoSelector {
    constructor(editor, canvas) {
        this.editor = editor;
        this.canvas = canvas;
        this.polygon = [];
    }
    onDrawStart(e) {
        this.clean();
        this.polygon.push([e.start.x, e.start.y]);
        this.editor.drawMouseCanvas();
    }
    onDraw(e) {
        this.polygon.push([e.current.x, e.current.y]);
        this.editor.drawMouseCanvas();
    }
    onDrawEnd(e) {
        this.polygon.push([e.current.x, e.current.y]);
        const editor = this.editor;
        editor.drawMouseCanvas();
        const inside = [];
        const outside = [];
        const mainCloud = editor.mainCloud;
        mainCloud.cloudData.forEach((data, idx) => {
            if (mainCloud.frustumIndices.has(idx)) {
                const p = mainCloud.pixelProjection.get(data);
                const inPolygon = isInside(p.pixelX, p.pixelY, this.polygon);
                if (inPolygon) {
                    inside.push(idx);
                }
                else {
                    outside.push(idx);
                }
            }
            else {
                outside.push(idx);
            }
        });
        editor.onSelect(inside, editor.labelIndex);
    }
    clean() {
        this.polygon = [];
    }
}
function isInside(x, y, points) {
    var inside = false;
    for (var i = 0, j = points.length - 1; i < points.length; j = i++) {
        var xi = points[i][0], yi = points[i][1];
        var xj = points[j][0], yj = points[j][1];
        var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect)
            inside = !inside;
    }
    return inside;
}
