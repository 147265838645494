import React from "react";
import { Menu } from "antd";
export default class ContextMenu extends React.Component {
    render() {
        const { onClick, left, top, visible, menus } = this.props;
        return (<React.Fragment>
        {visible && (<Menu mode="vertical" style={{
            position: "absolute",
            left,
            top,
            borderRadius: "5px"
        }} onClick={e => {
            onClick(e.keyPath);
        }}>
            {menus.map(menu => {
            if (menu.children) {
                return (<Menu.SubMenu key={menu.key} title={menu.text}>
                    {menu.children.map(menu => (<Menu.Item key={menu.key}>{menu.text}</Menu.Item>))}
                  </Menu.SubMenu>);
            }
            return <Menu.Item key={menu.key}>{menu.text}</Menu.Item>;
        })}
          </Menu>)}
      </React.Fragment>);
    }
}
