var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import BasicStore from "../../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../../api/Api";
import { ILabelPackage } from "../../../../definition/entity/label-package";
import { LabelTask } from "../../../../definition/entity/label-task";
import { ILabelPackageAssignment } from "../../../../definition/entity/label-package-assignment";
import Pager from "../../../../model/Pager";
import qs from "qs";
class Status {
    constructor() {
        this.labelTaskId = null;
        this.labelTask = null;
        this.packages = [];
        this.groups = [];
        this.isAssignModalVisible = false;
        this.assignMode = undefined;
        this.selectedPackageIds = [];
        this.selectedPackages = [];
        this.isTimelineVisible = false;
        this.curTimelinePackage = null;
        this.pager = new Pager(10);
    }
}
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "labelTaskId", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof LabelTask !== "undefined" && LabelTask) === "function" ? _a : Object)
], Status.prototype, "labelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "packages", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isAssignModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof ILabelPackageAssignment !== "undefined" && ILabelPackageAssignment.Type) === "function" ? _b : Object)
], Status.prototype, "assignMode", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedPackageIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedPackages", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isTimelineVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_c = typeof ILabelPackage !== "undefined" && ILabelPackage.DtoWithAssignments) === "function" ? _c : Object)
], Status.prototype, "curTimelinePackage", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_d = typeof Pager !== "undefined" && Pager) === "function" ? _d : Object)
], Status.prototype, "pager", void 0);
class LabelTaskPackagesStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        var _a;
        this.status.selectedPackages = [];
        this.status.selectedPackageIds = [];
        const { labelTaskId } = this.status;
        const [packagesRes, groupsRes] = await Promise.all([
            Api.labelTask.listLabelTaskPackages(labelTaskId),
            Api.membership.listGroups()
        ]);
        this.status.packages = packagesRes.data;
        this.status.labelTask = (_a = packagesRes.data[0]) === null || _a === void 0 ? void 0 : _a.labelTask;
        this.status.groups = groupsRes.data;
    }
    async assignLabelPackage(userId) {
        const { labelTaskId, selectedPackageIds, assignMode } = this.status;
        await Promise.all(selectedPackageIds.map(async (packageId, index, ids) => {
            await Api.labelTask.assignPackage(labelTaskId, packageId, {
                userId: userId,
                type: assignMode,
                batch: true,
                batchEnd: index + 1 === ids.length,
                packageCount: index + 1 === ids.length ? ids.length : undefined
            });
        }));
        this.setStatus({
            selectedPackageIds: []
        });
        await this.loadData();
        this.status.isAssignModalVisible = false;
        this.status.assignMode = undefined;
    }
    async revokeLabelPackage(packageId, userId, type) {
        const { labelTaskId } = this.status;
        const revokeRes = await Api.labelTask.revokePackage(labelTaskId, packageId, {
            userId: userId,
            type: type
        });
        if (revokeRes.code === 200) {
            await this.loadData();
        }
    }
    async downPackageAnnotationFile(record, full = false, needImage = false) {
        const url = `/api/label_task/${record.taskId}/label_package/${record.id}/generate_annotation_file`;
        const query = {};
        if (full) {
            query.full = 1;
        }
        if (needImage) {
            query.needImage = 1;
        }
        const str = qs.stringify(query);
        window.open(url + "?" + str);
    }
    async downPackageSandAnnotationFile(record) {
        window.open(`/api/label_task/${record.taskId}/label_package/${record.id}/generate_sand_annotation_file`);
    }
    async downPackageOriginSandAnnotationFile(record) {
        window.open(`/api/label_task/${record.taskId}/label_package/${record.id}/generate_origin_sand_annotation_file`);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelTaskPackagesStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelTaskPackagesStore.prototype, "resetStatus", null);
export const labelTaskPackagesStore = new LabelTaskPackagesStore();
