var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../../../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../../../../../api/Api";
import { IUserRole } from "../../../../../../../definition/entity/user-role";
class Status {
    constructor() {
        this.users = [];
        this.allUsers = [];
        this.groups = [];
        this.pendingTasks = [];
        this.pendingPackages = [];
        this.isModifyModalVisible = false;
        this.isPendingTaskModalVisible = false;
        this.isConfirmPendingTaskModalVisible = false;
        this.isConfirmPendingPackageModalVisible = false;
        this.isRecoverPendingPackageModalVisible = false;
        this.isPendingPackageModalVisible = false;
        this.curUserId = undefined;
        this.curUsername = undefined;
        this.assignTaskUserId = undefined;
        this.assignPackageUserId = undefined;
        this.curModifyUser = null;
        this.nameFilter = "";
        this.selectedTaskIds = [];
        this.selectedTasks = [];
        this.selectedPackageIds = [];
        this.selectedPackages = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "allUsers", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "pendingTasks", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "pendingPackages", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isModifyModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isPendingTaskModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isConfirmPendingTaskModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isConfirmPendingPackageModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isRecoverPendingPackageModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isPendingPackageModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "curUserId", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "curUsername", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "assignTaskUserId", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "assignPackageUserId", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "curModifyUser", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "nameFilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedTaskIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedTasks", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedPackageIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedPackages", void 0);
class UserManageStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const users = await Api.user.listUsers({
            name: this.status.nameFilter
        });
        this.status.users = users.data;
    }
    async loadAllUsers() {
        const users = await Api.user.listUsers();
        this.status.allUsers = users.data;
    }
    async loadGroups() {
        const groups = await Api.membership.listGroups();
        this.status.groups = groups.data;
    }
    async loadPendingTasks() {
        const pendingTasks = await Api.labelTask.listPendingLabelTasks(this.status.curUserId);
        this.status.pendingTasks = pendingTasks.data;
    }
    async updatePendingTasks() {
        const { selectedTasks, curUserId, assignTaskUserId } = this.status;
        await Api.labelTask.updatePendingTasks(curUserId, {
            userId: assignTaskUserId,
            taskIds: selectedTasks.map(i => i.id)
        });
    }
    async updatePendingPackages() {
        const { selectedPackages, curUserId, assignPackageUserId } = this.status;
        await Api.labelTask.updatePendingPackages(curUserId, {
            userId: assignPackageUserId,
            packageIds: selectedPackages.map(i => i.id)
        });
    }
    async recoverPendingPackages() {
        const { selectedPackages, curUserId } = this.status;
        await Api.labelTask.recoverPendingPackages(curUserId, { packageIds: selectedPackages.map(i => i.id) });
    }
    async loadPendingPackages() {
        const pendingPackages = await Api.labelTask.listPendingLabelPackages(this.status.curUserId);
        this.status.pendingPackages = pendingPackages.data;
    }
    async updateModifyingUser() {
        const { id, admin, group, nickname } = this.status.curModifyUser;
        if (group) {
            await Api.membership.joinGroup(group === null || group === void 0 ? void 0 : group.id, [id]);
        }
        else {
            await Api.membership.leaveGroup([id]);
        }
        if (admin) {
            await Api.admin.grantRole({ userId: id, role: IUserRole.Role.Admin });
        }
        else {
            await Api.admin.revokeRole({ userId: id, role: IUserRole.Role.Admin });
        }
        await Api.user.updateUser(id, { nickname });
        await this.loadData();
        this.status.isModifyModalVisible = false;
    }
    async deleteUser(userId) {
        const deleteRes = await Api.user.deleteUser(userId);
        if (deleteRes.code === 200) {
            await this.loadData();
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], UserManageStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserManageStore.prototype, "resetStatus", null);
export const userManageStore = new UserManageStore();
