export const Const = {};
export var PodStatusPhase;
(function (PodStatusPhase) {
    PodStatusPhase["Pending"] = "Pending";
    PodStatusPhase["Running"] = "Running";
    PodStatusPhase["Succeeded"] = "Succeeded";
    PodStatusPhase["Failed"] = "Failed";
    PodStatusPhase["UnKnown"] = "Unknown";
})(PodStatusPhase || (PodStatusPhase = {}));
export const multiScale = [0, 0.25, 0.5, 1, 2, 3, 4];
export const tranMultiScale = {
    0: "铺满",
    0.25: "0.25倍",
    0.5: "0.5倍",
    1: "1倍",
    2: "2倍",
    3: "3倍",
    4: "4倍"
};
