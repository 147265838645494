import * as React from "react";
import { InputNumber, Tooltip, Modal, Button, Table, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Api } from "api/Api";
import { IResource } from "../../definition/entity/resource";
import { EventBlock } from "../../component/EventBlock/EventBlock";
import { DetectionTool, ClassificationTool, Segment3DTool } from "../../definition/entity/label-task";
const { Option } = Select;
export class CalWorkload extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            calType: IResource.CalType.Self,
            start: null,
            end: null,
            workload: null,
            isShowWorkloadModal: false,
            isLoading: false
        };
        this.columns = [
            {
                title: "标签",
                align: "center",
                dataIndex: "labelType"
            },
            {
                title: "类型",
                align: "center",
                render: (value, record) => {
                    switch (record.tool) {
                        case DetectionTool.Rectangle:
                            return "矩形";
                        case DetectionTool.Dot:
                            return "点";
                        case DetectionTool.Path:
                            return "路径";
                        case DetectionTool.Chunk:
                            return "分段";
                        case "trails":
                            return "锚点";
                        case ClassificationTool.Switch:
                            return "布尔";
                        case ClassificationTool.Radio:
                            return "单选";
                        case ClassificationTool.Checkbox:
                            return "多选";
                        case ClassificationTool.Text:
                            return "文本";
                        case Segment3DTool.Cloud:
                            return "点云";
                        case DetectionTool.Box:
                            return "立体框";
                        default:
                            return "未知";
                    }
                }
            },
            {
                title: "所有对象数量",
                align: "center",
                dataIndex: "count"
            },
            {
                title: "预标注数量",
                align: "center",
                dataIndex: "ai_count"
            },
            {
                title: "人工标注数量",
                align: "center",
                dataIndex: "human_count"
            }
        ];
        this.getCalWorkloadData = async () => {
            var _a, _b;
            const { labelTaskId, labelPackageId } = this.props;
            const { calType, start, end } = this.state;
            this.setState({
                isLoading: true
            });
            try {
                const workloadResList = await Api.labelTask.getLabelPackageWorkload(labelTaskId, labelPackageId, {
                    calType,
                    start,
                    end
                });
                if ((_a = workloadResList.data) === null || _a === void 0 ? void 0 : _a.some(item => item === null || item === void 0 ? void 0 : item.trails)) {
                    this.columns = this.columns.concat({
                        title: "关键帧数量",
                        align: "center",
                        dataIndex: "trails"
                    });
                }
                if ((_b = workloadResList.data) === null || _b === void 0 ? void 0 : _b.some(item => item === null || item === void 0 ? void 0 : item.segment3dCount)) {
                    this.columns = this.columns.concat({
                        title: "点云分割总量（非复用+复用）",
                        align: "center",
                        render(value, record) {
                            return ((record &&
                                record.segment3dCount &&
                                `${record.segment3dCount}(${record.segment3dCount - record.inheritedSegment3dCount} + ${record.inheritedSegment3dCount}) (个)`) ||
                                0);
                        }
                    });
                }
                this.setState({
                    workload: workloadResList.data
                });
            }
            finally {
                this.setState({
                    isLoading: false
                });
            }
        };
    }
    componentDidMount() {
        this.setState({
            calType: IResource.CalType.All,
            start: 1,
            end: this.props.resourceCount
        });
    }
    render() {
        const { calType, start, end, workload, isShowWorkloadModal, isLoading } = this.state;
        return (<React.Fragment>
        <Select size="small" value={calType} style={{ width: 70, marginRight: 8 }} onChange={value => {
            this.setState({
                calType: value
            });
        }}>
          <Option value={IResource.CalType.Self}>个人</Option>
          <Option value={IResource.CalType.All}>所有</Option>
        </Select>
        <EventBlock blockEvent="keydown">
          <InputNumber size="small" min={1} value={start} style={{ width: 80, marginRight: 8 }} onChange={value => {
            this.setState({
                start: value
            });
        }}/>
        </EventBlock>
        <span>-</span>
        <EventBlock blockEvent="keydown">
          <InputNumber size="small" disabled={!start} min={start} max={this.props.resourceCount} value={end} style={{ width: 80, marginLeft: 8 }} onChange={value => {
            this.setState({
                end: value
            });
        }}/>
        </EventBlock>
        <Tooltip placement="bottomLeft" title="工作量">
          <Button disabled={!end} size="small" style={{ marginLeft: 8 }} icon={<SearchOutlined />} onClick={() => {
            this.getCalWorkloadData();
            this.setState({
                isShowWorkloadModal: true
            });
        }}></Button>
        </Tooltip>
        <Modal title="标注量" width={800} open={isShowWorkloadModal} footer={null} maskClosable={false} onCancel={() => {
            this.setState({
                isShowWorkloadModal: false
            });
        }}>
          <Table rowKey={(record, index) => `${record.labelType} + ${index}`} bordered={true} loading={isLoading} columns={this.columns} dataSource={workload} pagination={{ showSizeChanger: false }}></Table>
        </Modal>
      </React.Fragment>);
    }
}
