import * as React from "react";
const NoPermission = () => {
    const UCLink = "//" + location.host.replace(/^[^.]+/, "portal");
    return (<div className="no-permission" style={{ textAlign: "center" }}>
      <img src={require("../asset/no-permission.png")} alt=""/>
      <p style={{ marginTop: 40 }}>
        您的账号尚未开通该应用，请联系单位管理员进行开通，返回 <a href={UCLink}>用户中心 &gt;</a>
      </p>
    </div>);
};
export default NoPermission;
