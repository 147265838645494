var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { routerStore } from "../../../../store/routerStore";
import { Button, Card, Form, Input, PageHeader, Radio, Upload, message } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { IResource } from "../../../../definition/entity/resource";
import { LabelEditor } from "../../component/LabelEditor/LabelEditor";
import { observer } from "mobx-react";
import { taskTemplateModifyStore } from "./TaskTemplateModifyStore";
import { Loading } from "../../../../component/Loading/Loading";
import "./TaskTemplateModify.less";
import { ObjectStatus } from "definition/entity/label-task-template";
import { ILabelTask } from "definition/entity/label-task";
import LabelConfigureFormItem from "page/manage/component/TaskForm/LabelConfigureFormItem";
import { extendObservable, toJS } from "mobx";
let TaskTemplateModify = class TaskTemplateModify extends React.Component {
    constructor() {
        super(...arguments);
        this.editorRef = null;
    }
    componentDidMount() {
        const taskTemplateId = +this.props.match.params.taskTemplateId;
        taskTemplateModifyStore.loadData(taskTemplateId);
    }
    componentWillUnmount() {
        taskTemplateModifyStore.resetStatus();
    }
    render() {
        var _a;
        const status = taskTemplateModifyStore.status;
        return (<PageHeader title="更新任务模板" style={{ background: "#FFF" }} onBack={() => {
            routerStore.push("/manage/task_templates");
        }}>
        <Card>
          {status.isLoading ? (<Loading />) : (<Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <Form.Item label={<span className="label-required">模板名称</span>}>
                <Input value={status.taskTemplate.name} onChange={event => {
            status.taskTemplate.name = event.target.value;
        }}/>
              </Form.Item>
              <Form.Item label="备注">
                <Input value={status.taskTemplate.remark} onChange={event => {
            status.taskTemplate.remark = event.target.value;
        }}/>
              </Form.Item>
              
              <Form.Item label="标注文档">
                {((_a = status.taskTemplate) === null || _a === void 0 ? void 0 : _a.objectName) ? (<React.Fragment>
                    <div>
                      {status.taskTemplate.objectName}&nbsp;
                      <DeleteOutlined onClick={() => {
            status.taskTemplate.objectName = null;
            status.taskTemplate.objectStatus = ObjectStatus.Pending;
        }}/>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Upload accept=".pdf" showUploadList={false} beforeUpload={file => {
            status.fileName = file.name;
            status.file = file;
            return false;
        }}>
                        <Button>
                          <UploadOutlined />
                          替换文件
                        </Button>
                      </Upload>
                      {status.fileName && (<div style={{ marginLeft: "8px" }}>
                          {status.fileName}&nbsp;
                          <DeleteOutlined onClick={() => {
            status.fileName = null;
            status.file = null;
        }}/>
                        </div>)}
                    </div>
                  </React.Fragment>) : (<div style={{ display: "flex", alignItems: "center" }}>
                    <Upload accept=".pdf" showUploadList={false} beforeUpload={file => {
            status.fileName = file.name;
            status.file = file;
            return false;
        }}>
                      <Button>
                        <UploadOutlined />
                        上传文件
                      </Button>
                    </Upload>
                    {status.fileName && (<div style={{ marginLeft: "8px" }}>
                        {status.fileName}&nbsp;
                        <DeleteOutlined onClick={() => {
            status.fileName = null;
            status.file = null;
        }}/>
                      </div>)}
                  </div>)}
              </Form.Item>
              <Form.Item label={<span className="label-required">数据形式</span>}>
                <Radio.Group value={status.taskTemplate.resourceMime} onChange={event => {
            status.taskTemplate.resourceMime = event.target.value;
        }}>
                  <Radio value={IResource.Mime.Image}>图片</Radio>
                  <Radio value={IResource.Mime.Video}>视频</Radio>
                  <Radio value={IResource.Mime.Cloud}>点云</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label={<span className="label-required">任务类型</span>}>
                <Radio.Group value={status.taskTemplate.labelTaskType} onChange={e => {
            extendObservable(status.taskTemplate, {
                labelTaskType: e.target.value
            });
            this.setState({});
        }}>
                  {status.taskTemplate.resourceMime === IResource.Mime.Image && (<React.Fragment>
                      <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                      <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                      <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                    </React.Fragment>)}
                  {status.taskTemplate.resourceMime === IResource.Mime.Video && (<React.Fragment>
                      <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                      <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                      <Radio value={ILabelTask.LabelTaskType.Anchor}>时空锚</Radio>
                    </React.Fragment>)}
                  {status.taskTemplate.resourceMime === IResource.Mime.Cloud && (<React.Fragment>
                      <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                      <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                    </React.Fragment>)}
                </Radio.Group>
              </Form.Item>
              <Form.Item label={<span className="label-required">标签配置</span>}>
                {status.taskTemplate.taskType === ILabelTask.TaskType.Label && (<LabelEditor ref={ref => (this.editorRef = ref)} resourceMime={status.taskTemplate.resourceMime} labelTaskType={status.taskTemplate.labelTaskType} defaultLabel={status.taskTemplate.label}/>)}
                {status.taskTemplate.taskType === ILabelTask.TaskType.Segment && (<LabelConfigureFormItem value={toJS(status.taskTemplate.label)} onChange={value => {
            status.taskTemplate.label = value;
        }} type="segment"/>)}
              </Form.Item>
              <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 20 }}>
                <div style={{ float: "right" }}>
                  <Button onClick={() => {
            routerStore.goBack();
        }}>
                    取消
                  </Button>
                  <Button style={{ marginLeft: "10px" }} loading={status.isUpdating} onClick={() => {
            if (this.editorRef) {
                status.taskTemplate.label = this.editorRef.getStatus();
            }
            if (!status.taskTemplate.name) {
                return message.error("请输入模版名称");
            }
            taskTemplateModifyStore.updateTaskTemplate(status.taskTemplate.id, status.taskTemplate.label);
        }}>
                    更新
                  </Button>
                </div>
              </Form.Item>
            </Form>)}
        </Card>
      </PageHeader>);
    }
};
TaskTemplateModify = __decorate([
    observer
], TaskTemplateModify);
export { TaskTemplateModify };
