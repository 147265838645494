var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../api/Api";
class Status {
    constructor() {
        this.packages = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "packages", void 0);
class LabelSandPackageStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const sandPackages = await Api.labelTask.listSandPackages();
        if (sandPackages.code === 200) {
            this.status.packages = sandPackages.data;
        }
    }
    async revertTurnToSand(record) {
        const { id, taskId } = record;
        await Api.labelTask.updateLabelPackage(taskId, id, {
            isSand: false
        });
        await this.loadData();
    }
}
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelSandPackageStore.prototype, "resetStatus", null);
export const labelSandPackageStore = new LabelSandPackageStore();
