var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { toJS } from "mobx";
import { Select, DatePicker, Button, Skeleton } from "antd";
import { userCompositeAuditStore, businessTypeDesc } from "./UserCompositeAuditStore";
import { observer } from "mobx-react";
import { getWeek } from "util/time-transform";
import "./UserCompositeAudit.less";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
let UserCompositeAudit = class UserCompositeAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.disabledDate = (current) => {
            let { calendarDate } = userCompositeAuditStore.status;
            if (!calendarDate || calendarDate.length === 0) {
                return false;
            }
            let startTime = calendarDate[0];
            const start = startTime && startTime.format("YYYYMMDD") > current.format("YYYYMMDD");
            const end = startTime &&
                moment(startTime)
                    .add(30, "days")
                    .format("YYYYMMDD") < current.format("YYYYMMDD");
            return start || end;
        };
    }
    componentDidMount() {
        userCompositeAuditStore.loadGroups();
        userCompositeAuditStore.loadUsers();
    }
    render() {
        const status = userCompositeAuditStore.status;
        return (<div className="composite-audit">
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
          <div>
            <RangePicker disabledDate={this.disabledDate} value={toJS(status.calendarDate)} onCalendarChange={date => {
            status.calendarDate = date;
        }} onChange={date => {
            status.calendarDate = date;
        }}/>
            <Select showSearch allowClear mode="multiple" placeholder="请选择单位" style={{ width: 240, marginLeft: 10 }} value={toJS(status.curGroupIds)} onChange={value => {
            status.curGroupIds = value;
            status.curUserIds = [];
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {status.groups.map(group => (<Select.Option key={group === null || group === void 0 ? void 0 : group.id} title={group.name} value={group === null || group === void 0 ? void 0 : group.id}>
                  {group.name}
                </Select.Option>))}
            </Select>
            <Select placeholder="请选择用户" allowClear showSearch mode="multiple" value={toJS(status.curUserIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curUserIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {toJS(userCompositeAuditStore.filterUsers).map(user => (<Select.Option key={user === null || user === void 0 ? void 0 : user.id} title={`${user.nickname}(${user.name})`} value={user === null || user === void 0 ? void 0 : user.id}>
                  {`${user.nickname}(${user.name})`}
                </Select.Option>))}
            </Select>
            <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={userCompositeAuditStore.isUnableSearch} onClick={() => {
            userCompositeAuditStore.loadData();
        }}></Button>
          </div>

          <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename}>
            <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={userCompositeAuditStore.isUnableDownload} onClick={() => userCompositeAuditStore.downAnnotationAuditFile()}/>
          </CSVLink>
        </div>
        <div className="audit-table-wrapper">
          {status.loading ? (<Skeleton active/>) : (<React.Fragment>
              <div className="audit-table">
                <div className="audit-table-body">
                  <table>
                    <thead className="audit-table-thead">
                      <tr>
                        <th></th>
                        {toJS(userCompositeAuditStore.tableColumns).map((column, index) => (<th key={index}>
                            <div style={{
            display: "grid",
            gridTemplateRows: "repeat(2, 1fr)",
            textAlign: "center"
        }}>
                              <span>{column}</span>
                              <span>{getWeek(column)}</span>
                            </div>
                          </th>))}
                      </tr>
                    </thead>
                    <tbody className="audit-table-tbody">
                      {toJS(userCompositeAuditStore.transformedAnnotation).map((item, index) => (<tr key={index}>
                          <td>
                            <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            justifyItems: "end"
        }}>
                              <div className="cell-column">
                                <span>{item.nickname}</span>
                              </div>
                              <div className="cell-column" style={{
            display: "grid",
            gridTemplateRows: "repeat(3, 1fr)",
            textAlign: "center"
        }}>
                                <span>每日工作填写</span>
                                <span>计价类型</span>
                                <span>总工作量</span>
                                <span>负责人</span>
                              </div>
                            </div>
                          </td>
                          {toJS(userCompositeAuditStore.tableColumns).map((column, index) => {
            return item[column] ? (<td key={index}>
                                <div style={{
                display: "grid",
                gridTemplateColumns: `repeat(${item[column].length}, 1fr)`
            }}>
                                  {item[column].map((cell, index) => (<div className="cell-column" key={index} style={{
                display: "grid",
                gridTemplateRows: "repeat(3, 1fr)",
                textAlign: "center"
            }}>
                                      <span style={{
                backgroundColor: cell.duration >= 7 ? "#52c41a" : "#ff4d4f",
                opacity: 0.2
            }}></span>
                                      <span>{cell.businessName || "未分配类型"}</span>
                                      <span>{`${cell.totalWorkload}(${businessTypeDesc(cell === null || cell === void 0 ? void 0 : cell.businessType)})`}</span>
                                      <span>{cell.createTaskUser}</span>
                                    </div>))}
                                </div>
                              </td>) : (<td key={index}>
                                <div className="cell-column" style={{
                display: "grid",
                gridTemplateRows: "repeat(3, 1fr)",
                textAlign: "center"
            }}>
                                  <span>-</span>
                                  <span>-</span>
                                  <span>-</span>
                                  <span>-</span>
                                </div>
                              </td>);
        })}
                        </tr>))}
                    </tbody>
                  </table>
                </div>
                {toJS(userCompositeAuditStore.transformedAnnotation).length === 0 && (<div className="audit-table-placeholder">
                    <div className="audit-empty audit-empty-normal">
                      <div className="audit-empty-image">
                        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                          <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                            <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                            <g fillRule="nonzero" stroke="#D9D9D9">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                              <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p className="audit-empty-description">暂无数据</p>
                    </div>
                  </div>)}
              </div>
            </React.Fragment>)}
        </div>
      </div>);
    }
};
UserCompositeAudit = __decorate([
    observer
], UserCompositeAudit);
export { UserCompositeAudit };
