import { message } from "antd";
import Color from "color";
import { DetectionTool } from "definition/entity/label-task";
import * as _ from "lodash";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterDom } from "../../provider/painter-dom";
import BaseRender from "./base";
import { distance, drawCircle, drawCircleWithFill, drawLine, drawPolygonWithFill, drawRect, drawText, genRectFromPolygon } from "./helper";
import { toJS } from "mobx";
import randomColor from "randomcolor";
export default class PolygonRender extends BaseRender {
    constructor(canvas, rectangleRender) {
        super(canvas);
        this.rectangleRender = rectangleRender;
        this.defaultAlpha = 0.1;
        this.mode = "auto";
        this.commonMode = false;
        this.reverse = false;
        this.targetElement = null;
        this.targetElementPointIndex = null;
        this.relativeElement = null;
        this.relativeElementPointIndexes = [];
        this.commonPointIndexs = null;
        this._dragChange = false;
        this._resizeChange = false;
    }
    render(elements, creatingElement, selectedPointIndex) {
        var _a, _b;
        const { offsetLeft, offsetTop } = imageLabelStore;
        const sortedElements = elements.filter(e => e.visible).sort((a, b) => b.detection.layer - a.detection.layer);
        sortedElements.forEach(element => {
            var _a, _b, _c, _d;
            const color = element.color || element.label.color;
            const points = (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.points.map(point => [point[0] + offsetLeft, point[1] + offsetTop]);
            const suggestedPoints = (_b = this.getSuggestedPoints(element)) === null || _b === void 0 ? void 0 : _b.map(point => [
                point[0] + offsetLeft,
                point[1] + offsetTop
            ]);
            drawPolygonWithFill(this.canvas, points, Color(color)
                .alpha((_c = element.detection.alpha) !== null && _c !== void 0 ? _c : this.defaultAlpha)
                .toString());
            if (imageLabelStore.status.selectedElementIds.includes(element.id)) {
                const lines = this.getLinesFromPoints(points);
                lines.forEach(line => {
                    drawLine(this.canvas, line.start, line.end, color, 2);
                });
            }
            if (((_d = imageLabelStore.status.editingElement) === null || _d === void 0 ? void 0 : _d.id) === element.id) {
                points.forEach((point, idx) => {
                    if (selectedPointIndex.includes(idx)) {
                        drawCircle(this.canvas, point, 8, 0, 360, element.label.color, 1);
                        drawCircleWithFill(this.canvas, point, 7, "rgba(255, 0, 0, 0.6)");
                    }
                    else {
                        drawCircle(this.canvas, point, 6, 0, 360, element.label.color, 1);
                        drawCircleWithFill(this.canvas, point, 5, "#fff");
                    }
                });
                suggestedPoints === null || suggestedPoints === void 0 ? void 0 : suggestedPoints.forEach(point => {
                    drawCircleWithFill(this.canvas, point, 3, Color("#fff")
                        .alpha(0.5)
                        .toString());
                    drawCircle(this.canvas, point, 4, 0, 360, element.label.color, 1);
                });
            }
            if (element.detection.rect) {
                const rect = {
                    x: element.detection.rect.x + offsetLeft,
                    y: element.detection.rect.y + offsetTop,
                    width: element.detection.rect.width,
                    height: element.detection.rect.height
                };
                if (imageLabelStore.status.selectedElementIds.includes(element.id)) {
                    drawRect(this.canvas, rect, element.label.color, 2);
                }
                else {
                    drawRect(this.canvas, rect, element.label.color, 1);
                }
            }
            const _rect = this.getRectFromPolygon(element);
            drawText(this.canvas, `${element.id} featureId: ${element.detection.featureId}`, 14, { x: offsetLeft + _rect.x, y: offsetTop + _rect.y - 9 }, color);
        });
        Object.keys(imageLabelStore.status.groupRects).forEach(groupId => {
            const group = imageLabelStore.group.getGroup(+groupId);
            const element = _.find(imageLabelStore.status.layerElements, { id: group[0].id });
            const color = element.color || element.label.color;
            const _rect = imageLabelStore.status.groupRects[groupId];
            if (_rect) {
                const rect = {
                    x: _rect.x + offsetLeft,
                    y: _rect.y + offsetTop,
                    width: _rect.width,
                    height: _rect.height
                };
                if (imageLabelStore.status.selectedElementIds.includes(element.id)) {
                    drawRect(this.canvas, rect, element.label.color, 2);
                }
                else {
                    drawRect(this.canvas, rect, element.label.color, 1);
                }
            }
        });
        if (creatingElement) {
            const points = (_a = creatingElement.attributes) === null || _a === void 0 ? void 0 : _a.points.map(point => [point[0] + offsetLeft, point[1] + offsetTop]);
            if ((_b = creatingElement.attributes) === null || _b === void 0 ? void 0 : _b.nextPos) {
                points.push([
                    creatingElement.attributes.nextPos.x + offsetLeft,
                    creatingElement.attributes.nextPos.y + offsetTop
                ]);
            }
            const lines = this.getLinesFromPoints(points).slice(0, -1);
            drawPolygonWithFill(this.canvas, points, Color(creatingElement.color || creatingElement.label.color)
                .alpha(this.defaultAlpha)
                .toString());
            lines.forEach(line => {
                drawLine(this.canvas, line.start, line.end, creatingElement.color || creatingElement.label.color, 2);
            });
            points.slice(0, -1).forEach(point => {
                drawCircleWithFill(this.canvas, point, 5, creatingElement.color || creatingElement.label.color);
            });
        }
        if (!imageLabelStore.status.hiddenDetection &&
            imageLabelStore.status.rawResource &&
            imageLabelStore.status.rawResource.annotation._segmentPlaceholders) {
            const zoomRatio = imageLabelStore.status.zoomRatio;
            imageLabelStore.status.rawResource.annotation._segmentPlaceholders.forEach(detection => {
                const x = detection.value.x * zoomRatio + offsetLeft;
                const y = detection.value.y * zoomRatio + offsetTop;
                const width = detection.value.width * zoomRatio;
                const height = detection.value.height * zoomRatio;
                drawRect(this.canvas, { x, y, width, height }, "red", 1, 5);
            });
        }
    }
    resizeTest(e) {
        const { offsetX, offsetY } = this.getOffset(e);
        let element = null;
        let pointIndex = null;
        let suggestedPointIndex = null;
        if (imageLabelStore.status.editingElement) {
            const ele = imageLabelStore.status.editingElement;
            this.getTransformHandles(ele).forEach((handle, idx) => {
                if (this.isInsideTransformHandle(offsetX, offsetY, handle)) {
                    element = ele;
                    pointIndex = idx;
                    suggestedPointIndex = null;
                }
            });
            const suggestedHandles = this.getSuggestedTransformHandles(ele);
            if (suggestedHandles) {
                suggestedHandles.forEach((handle, idx) => {
                    if (this.isInsideTransformHandle(offsetX, offsetY, handle)) {
                        element = ele;
                        pointIndex = null;
                        suggestedPointIndex = idx;
                    }
                });
            }
        }
        if (element) {
            return { element, pointIndex, suggestedPointIndex };
        }
    }
    dragTest(e) {
        var _a;
        const { offsetX, offsetY } = this.getOffset(e);
        const elements = imageLabelStore.status.layerElements
            .filter(element => element.label.tool === "path")
            .sort((a, b) => b.detection.layer - a.detection.layer);
        for (let i = elements.length - 1; i >= 0; i--) {
            const element = elements[i];
            if (this.isInside(offsetX, offsetY, (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.points)) {
                return { element };
            }
        }
    }
    getSuggestedPoints(element) {
        var _a;
        const points = (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.points;
        if (points.length > 2) {
            return points.map((point, index) => {
                let lastPoint = index === 0 ? points[points.length - 1] : points[index - 1];
                const midPoint = [(lastPoint[0] + point[0]) / 2, (lastPoint[1] + point[1]) / 2];
                return midPoint;
            });
        }
    }
    getTransformHandles(element) {
        var _a;
        let r = 4;
        return (_a = element.attributes) === null || _a === void 0 ? void 0 : _a.points.map((point, idx) => {
            if (imageLabelStore.status.selectedPointIndex.includes(idx)) {
                r = 5;
            }
            return [point[0] - r, point[1] - r, r * 2, r * 2];
        });
    }
    getSuggestedTransformHandles(element) {
        const points = this.getSuggestedPoints(element);
        if (points) {
            return points.map(point => {
                return [point[0] - 3, point[1] - 3, 6, 6];
            });
        }
    }
    getLinesFromPoints(points) {
        const lines = [];
        for (let i = 0; i < points.length - 1; i++) {
            lines.push({ start: points[i], end: points[i + 1] });
        }
        lines.push({ start: points[points.length - 1], end: points[0] });
        return lines;
    }
    isInsideTransformHandle(x, y, transformHandle) {
        return (x >= transformHandle[0] &&
            x <= transformHandle[0] + transformHandle[2] &&
            y >= transformHandle[1] &&
            y <= transformHandle[1] + transformHandle[3]);
    }
    isInside(x, y, points) {
        var inside = false;
        for (var i = 0, j = points.length - 1; i < points.length; j = i++) {
            var xi = points[i][0], yi = points[i][1];
            var xj = points[j][0], yj = points[j][1];
            var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect)
                inside = !inside;
        }
        return inside;
    }
    getOffset(e) {
        const { offsetLeft, offsetTop } = imageLabelStore;
        const offsetX = e.pageX - this.canvas.getBoundingClientRect().left - offsetLeft;
        const offsetY = e.pageY - this.canvas.getBoundingClientRect().top - offsetTop;
        return {
            offsetX,
            offsetY
        };
    }
    getLimitOffset(e) {
        const backgroundWidth = imageLabelStore.backgroundWidth;
        const backgroundHeight = imageLabelStore.backgroundHeight;
        let { offsetX, offsetY } = this.getOffset(e);
        if (offsetX < 0) {
            offsetX = 0;
        }
        if (offsetX > backgroundWidth) {
            offsetX = backgroundWidth;
        }
        if (offsetY < 0) {
            offsetY = 0;
        }
        if (offsetY > backgroundHeight) {
            offsetY = backgroundHeight;
        }
        return { offsetX, offsetY };
    }
    onDrawStart(e) {
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel, creatingElement } = status;
        const { offsetX, offsetY } = this.getLimitOffset(e.origin);
        if (_.isEmpty(creatingElement)) {
            status.creatingElement = {
                id: PainterDom.generateId(curLabel.key),
                label: curLabel,
                visible: true,
                color: curLabel.type === "mask-instance" ? randomColor() : curLabel.color,
                attributes: {
                    points: [[offsetX, offsetY]],
                    nextPos: null,
                    isClosed: false
                },
                detection: {
                    key: curLabel.key,
                    value: null,
                    tool: DetectionTool.Path,
                    featureId: PainterDom.generateFeatureId(),
                    alpha: this.defaultAlpha,
                    layer: curLabel.layer,
                    type: curLabel.type,
                    classifications: (curLabel.classifications || []).map(({ key, tool, defaultValue }) => ({
                        key,
                        tool,
                        value: defaultValue
                    }))
                }
            };
        }
        else {
            if (this.commonMode) {
                if (!imageLabelStore.status.editingElement) {
                    return message.warn("被共边对象只有在编辑模式下(即出现边缘点)，才可进行共边操作");
                }
                const ret = this.resizeTest(e.origin);
                if (ret && ret.pointIndex != null) {
                    if (this.relativeElement && this.relativeElement != ret.element) {
                        return message.error("只能同时作用于一个元素");
                    }
                    this.relativeElement = ret.element;
                    this.relativeElementPointIndexes.push(ret.pointIndex);
                    this.doCommonMode();
                }
            }
            else {
                const handles = this.getTransformHandles(creatingElement);
                if (this.isInsideTransformHandle(offsetX, offsetY, handles[0])) {
                    if (handles.length < 3) {
                        return;
                    }
                    this.completeDraw();
                }
                else {
                    Object.assign(creatingElement.attributes, {
                        points: creatingElement.attributes.points.concat([[offsetX, offsetY]])
                    });
                }
            }
        }
    }
    onDraw(e) {
        if (this.mode === "auto" && this.commonMode === false) {
            const { creatingElement } = imageLabelStore.status;
            const { offsetX, offsetY } = this.getLimitOffset(e.origin);
            const attributes = creatingElement.attributes;
            const prevPoint = attributes.points[attributes.points.length - 1];
            if (distance(prevPoint, [offsetX, offsetY]) > 10) {
                Object.assign(creatingElement.attributes, {
                    points: attributes.points.concat([[offsetX, offsetY]])
                });
            }
        }
    }
    onDrawUndo() {
        if (imageLabelStore.status.creatingElement) {
            const element = imageLabelStore.status.creatingElement;
            if (element.attributes.points.length > 1) {
                element.attributes.points.splice(-1);
            }
        }
    }
    getRectFromPolygon(element) {
        return genRectFromPolygon(element.attributes.points);
    }
    completeDraw() {
        const status = imageLabelStore.status;
        const { creatingElement } = status;
        const attributes = creatingElement.attributes;
        if (!attributes.isClosed && attributes.points.length > 2) {
            attributes.isClosed = true;
            attributes.nextPos = null;
            const status = imageLabelStore.status;
            status.creatingElement.detection.rect = this.getRectFromPolygon(status.creatingElement);
            imageLabelStore.history.push({
                path: "status.layerElements",
                getValue: (oz, cz, ov) => {
                    const els = ov || toJS(status.layerElements);
                    els.forEach(el => {
                        el.attributes.points.forEach(p => {
                            p[0] = (p[0] * cz) / oz;
                            p[1] = (p[1] * cz) / oz;
                        });
                    });
                    return els;
                }
            });
            status.layerElements.push(status.creatingElement);
            status.creatingElement = null;
        }
    }
    onDragStart(e) {
        var _a;
        (_a = document.getElementById(e.element.id)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
        if (e.origin.button == 2)
            return;
        this.currentPoints = [...e.element.attributes.points];
        this.currentRect = e.element.detection.rect ? Object.assign({}, e.element.detection.rect) : null;
        const group = imageLabelStore.group.findGroup(e.element.id);
        const targets = group ? group.map(v => v.id) : [e.element.id];
        if (e.origin.shiftKey) {
            imageLabelStore.status.selectedElementIds.push(...targets);
        }
        else {
            imageLabelStore.status.selectedElementIds = targets;
        }
        this.currentElement = toJS(e.element);
        this._dragChange = false;
    }
    onDrag(e) {
        if (imageLabelStore.status.editingElement == e.element) {
            const deltaX = e.current.x - e.start.x;
            const deltaY = e.current.y - e.start.y;
            if (this.currentPoints) {
                this.safeMove(e.element, deltaX, deltaY);
                this._dragChange = true;
            }
        }
    }
    onDragEnd() {
        if (this._dragChange) {
            imageLabelStore.history.push({
                path: `status.layerElements[${_.findIndex(imageLabelStore.status.layerElements, {
                    id: this.currentElement.id
                })}]`,
                getValue: (oz, cz, ov) => {
                    const v = ov || this.currentElement;
                    v.attributes.points.forEach(p => {
                        p[0] = (p[0] * cz) / oz;
                        p[1] = (p[1] * cz) / oz;
                    });
                    return v;
                }
            });
        }
        this.currentPoints = null;
        this.currentRect = null;
    }
    onResizeStart(e) {
        if (this.commonMode) {
        }
        if (e.pointIndex != null) {
            imageLabelStore.status.selectedPointIndex = [e.pointIndex];
            this.currentElement = toJS(e.element);
            this._resizeChange = false;
        }
    }
    onResize(e) {
        const deltaX = e.current.x - e.start.x;
        const deltaY = e.current.y - e.start.y;
        if (imageLabelStore.status.selectedPointIndex.length) {
            this.safeMovePoint(deltaX, deltaY);
            this._resizeChange = true;
        }
    }
    onResizeEnd() {
        imageLabelStore.history.push({
            path: `status.layerElements[${_.findIndex(imageLabelStore.status.layerElements, {
                id: this.currentElement.id
            })}]`,
            getValue: (oz, cz, ov) => {
                const v = ov || this.currentElement;
                v.attributes.points.forEach(p => {
                    p[0] = (p[0] * cz) / oz;
                    p[1] = (p[1] * cz) / oz;
                });
                return v;
            },
            group: imageLabelStore.group.get()
        });
    }
    addPoint(e) {
        const { offsetX, offsetY } = this.getLimitOffset(e.origin);
        const points = e.element.attributes.points;
        e.element.attributes.points = [
            ...points.slice(0, e.suggestedPointIndex),
            [offsetX, offsetY],
            ...points.slice(e.suggestedPointIndex)
        ];
    }
    renderToMask(elements, canvas) {
        elements
            .sort((a, b) => b.detection.layer - a.detection.layer)
            .forEach(element => {
            const points = element.attributes.points;
            drawPolygonWithFill(canvas, points, element.color || element.label.color);
        });
    }
    getPointsFromElement(element, start, end, reverse = false) {
        const points = toJS(element.attributes.points);
        let index = start;
        let result = [];
        let len = points.length - 1;
        while (index !== end) {
            result.push(points[index]);
            if (reverse) {
                index--;
                if (index < 0) {
                    index = len;
                }
            }
            else {
                index++;
                if (index > len) {
                    index = 0;
                }
            }
        }
        result.push(points[end]);
        return result;
    }
    getPointIndexsFromElement(element, start, end, reverse = false) {
        const points = element.attributes.points;
        let index = start;
        let result = [];
        let len = points.length - 1;
        while (index !== end) {
            result.push(index);
            if (reverse) {
                index--;
                if (index < 0) {
                    index = len;
                }
            }
            else {
                index++;
                if (index > len) {
                    index = 0;
                }
            }
        }
        result.push(end);
        return result;
    }
    doCommonMode() {
        if (this.relativeElementPointIndexes.length === 1) {
            this.targetElement.attributes.points = [
                ...this.targetElement.attributes.points.slice(0, this.targetElementPointIndex + 1),
                this.relativeElement.attributes.points[this.relativeElementPointIndexes[0]]
            ];
        }
        else {
            this.commonPointIndexs = this.getPointIndexsFromElement(this.relativeElement, this.relativeElementPointIndexes[0], this.relativeElementPointIndexes[1], this.reverse);
            const points = this.commonPointIndexs.map(index => this.relativeElement.attributes.points[index]);
            this.targetElement.attributes.points = [
                ...this.targetElement.attributes.points.slice(0, this.targetElementPointIndex + 1),
                ...points
            ];
        }
    }
    safeMove(element, deltaX, deltaY) {
        let maxDeltaX = 0;
        let maxDeltaY = 0;
        let diffX = 0;
        let diffY = 0;
        let points = this.currentPoints || [...element.attributes.points];
        let rect = this.currentRect || Object.assign({}, element.detection.rect);
        if (deltaX > 0) {
            maxDeltaX = Math.min.apply(null, points.map(p => p[0]).map(v => imageLabelStore.backgroundWidth - v));
            diffX = Math.min(maxDeltaX, deltaX);
        }
        else {
            maxDeltaX = Math.max.apply(null, points.map(p => p[0]).map(v => 0 - v));
            diffX = Math.max(maxDeltaX, deltaX);
        }
        if (deltaY > 0) {
            maxDeltaY = Math.min.apply(null, points.map(p => p[1]).map(v => imageLabelStore.backgroundHeight - v));
            diffY = Math.min(maxDeltaY, deltaY);
        }
        else {
            maxDeltaY = Math.max.apply(null, points.map(p => p[1]).map(v => 0 - v));
            diffY = Math.max(maxDeltaY, deltaY);
        }
        element.attributes.points = points.map(point => {
            return [point[0] + diffX, point[1] + diffY];
        });
        if (element.detection.rect) {
            element.detection.rect.x = rect.x + diffX;
            element.detection.rect.y = rect.y + diffY;
        }
        this.fixRect(element);
    }
    safeMovePoint(deltaX, deltaY, to = true) {
        const el = imageLabelStore.status.editingElement;
        const pointIndex = imageLabelStore.status.selectedPointIndex;
        const backgroundWidth = imageLabelStore.backgroundWidth;
        const backgroundHeight = imageLabelStore.backgroundHeight;
        pointIndex.forEach(p => {
            const point = to ? this.currentElement.attributes.points[p] : el.attributes.points[p];
            let offsetX = point[0] + deltaX;
            let offsetY = point[1] + deltaY;
            if (offsetX < 0) {
                offsetX = 0;
            }
            if (offsetX > backgroundWidth) {
                offsetX = backgroundWidth;
            }
            if (offsetY < 0) {
                offsetY = 0;
            }
            if (offsetY > backgroundHeight) {
                offsetY = backgroundHeight;
            }
            el.attributes.points[p] = [offsetX, offsetY];
            this.fixRect(el);
        });
    }
    fixRect(el) {
        if (el.detection.rect) {
            el.detection.rect = this.getRectFromPolygon(el);
        }
        else {
            const group = imageLabelStore.group.findGroup(el.id);
            const groupId = imageLabelStore.group.findGroupId(el.id);
            const points = group
                .map(e => _.find(imageLabelStore.status.layerElements, { id: e.id }))
                .map(e => toJS(e.attributes.points))
                .reduce((s, a) => s.concat(a), []);
            imageLabelStore.status.groupRects[groupId] = genRectFromPolygon(points);
        }
    }
}
