var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { observable } from "mobx";
export default class Pager {
    constructor(perPage = 10) {
        this.curPage = 1;
        this.perPage = 10;
        this.totalCount = undefined;
        this.perPage = perPage;
    }
    resetPage() {
        this.curPage = 1;
        this.totalCount = undefined;
        return this;
    }
    setPerPage(perPage) {
        if (!perPage) {
            this.perPage = 10;
        }
        else {
            this.perPage = perPage;
        }
        return this;
    }
    setCurPage(curPage) {
        this.curPage = curPage;
        return this;
    }
    setTotal(totalCount) {
        this.totalCount = totalCount;
        return this;
    }
    getCurPage() {
        return this.curPage;
    }
    getPerPage() {
        return this.perPage;
    }
    getTotalCount() {
        return this.totalCount;
    }
    getOffset() {
        return (this.curPage - 1) * this.perPage;
    }
    getTotalPage() {
        return Math.ceil(this.totalCount / this.perPage);
    }
    prevPage() {
        if (this.curPage > 1) {
            this.curPage--;
        }
        return this;
    }
    nextPage() {
        if (this.curPage < this.getTotalPage()) {
            this.curPage++;
        }
        return this;
    }
    hasMore() {
        return this.curPage * this.perPage < this.totalCount;
    }
}
__decorate([
    observable,
    __metadata("design:type", Number)
], Pager.prototype, "curPage", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Pager.prototype, "perPage", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Pager.prototype, "totalCount", void 0);
