var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Button, Col, InputNumber, Menu, Row, Tooltip } from "antd";
import { CaretRightOutlined, DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, LoadingOutlined, PauseOutlined, RightOutlined } from "@ant-design/icons";
import { DetectionTool } from "definition/entity/label-task";
import _ from "lodash";
import { observer } from "mobx-react";
import { imageLabelStore } from "page/label/image/ImageLabel/ImageLabelStore";
import { PainterDom } from "page/label/image/ImageLabel/provider/painter-dom";
import React from "react";
import Timeline from "../timeline";
import SliderBar from "../timeline/SliderBar";
import PreviewDialog from "./PreviewDialog";
import videoLabelStore from "./VideoLabelStore";
import "./VideoPainter.less";
import VideoStage from "./VideoStage";
import { toJS } from "mobx";
let VideoPainter = class VideoPainter extends React.Component {
    constructor() {
        super(...arguments);
        this.menuRef = null;
        this.state = {
            paused: true,
            loading: false,
            scrollPos: 0,
            contextMenu: {
                visible: false,
                pageX: 0,
                pageY: 0,
                element: null
            },
            playbackRate: 1
        };
        this.playbackRateRange = [1 / 16, 16];
        this.onMouseDown = e => {
            var _a;
            if (((_a = this.menuRef) === null || _a === void 0 ? void 0 : _a.contains(e.target)) || this.state.contextMenu.visible === false)
                return;
            this.setState({ contextMenu: { visible: false } });
        };
    }
    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
        window.addEventListener("click", this.onMouseDown);
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
        window.removeEventListener("click", this.onMouseDown);
        videoLabelStore.reset();
    }
    isWriteableElement(e) {
        if (e.target.tagName === "INPUT" && e.target.type === "text") {
            return true;
        }
        return false;
    }
    onKeyDown(e) {
        if (e.key === " ") {
            if (this.isWriteableElement && this.isWriteableElement(e)) {
                return;
            }
            videoLabelStore.playOrPause();
        }
    }
    onSliderDoubleClick() {
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel } = status;
        if (!curLabel)
            return;
        const id = PainterDom.generateId(curLabel.key);
        const { currentTime, currentFrame, meta } = videoLabelStore;
        const startTime = currentTime;
        const startFrame = currentFrame;
        let endTime = startTime + meta.timePerFrame * 200;
        if (endTime > meta.duration) {
            endTime = meta.duration;
        }
        const endFrame = Math.floor(endTime / meta.timePerFrame);
        imageLabelStore.status.layerElements.push({
            id: id,
            label: curLabel,
            visible: true,
            attributes: {
                startTime,
                endTime,
                startFrame,
                endFrame
            },
            detection: {
                key: curLabel.key,
                value: {
                    startTime,
                    endTime,
                    startFrame,
                    endFrame
                },
                tool: DetectionTool.Chunk,
                trails: [],
                classifications: curLabel.classifications.map(cls => ({ key: cls.key, value: cls.defaultValue }))
            },
            display: "normal"
        });
    }
    onMenuClick(e) {
        const action = _.last(e.keyPath);
        switch (action) {
            case "delete":
                _.remove(imageLabelStore.status.layerElements, { id: this.state.contextMenu.element.id });
                break;
            case "cancel":
                break;
            case "select": {
                const detectionKey = e.key;
                const element = this.state.contextMenu.element;
                const toLabel = _.find(imageLabelStore.status.selectableLabel.detections, detection => detection.key === detectionKey);
                element.label = toLabel;
                element.id = PainterDom.generateId(toLabel.key);
                element.detection.key = toLabel.key;
                break;
            }
        }
        this.setState({ contextMenu: { visible: false } });
    }
    onTimelineChange(time) {
        videoLabelStore.currentTime = time;
    }
    render() {
        var _a, _b, _c, _d;
        const { loading } = this.state;
        const chunks = imageLabelStore.status.layerElements.filter(e => e.label.tool === DetectionTool.Chunk);
        const minFrameRate = _.ceil(this.playbackRateRange[0] * videoLabelStore.meta.frameRate, 2);
        const maxFrameRate = _.ceil(this.playbackRateRange[1] * videoLabelStore.meta.frameRate, 2);
        const isChunkTask = toJS((_c = (_b = (_a = imageLabelStore.status.labelInfo.labelTask) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.detections) === null || _c === void 0 ? void 0 : _c.every(i => i.tool === DetectionTool.Chunk));
        return (<div className="video-painter">
        <VideoStage ref={el => {
            videoLabelStore.videoStage = el;
        }} src={this.props.url} width={this.props.width} onLoadingChange={loading => this.setState({ loading })} playbackRate={this.state.playbackRate}></VideoStage>
        <Row justify="space-between" align="middle" style={{ padding: "10px 0" }}>
          <Col span={18}>
            <Tooltip title={"后退10帧 快捷键 shift + ⬅️"}>
              <Button onClick={() => {
            videoLabelStore.back(10);
        }} style={{ marginRight: 5 }} icon={<DoubleLeftOutlined />}></Button>
            </Tooltip>
            <Tooltip title={"后退1帧 快捷键 ⬅️"}>
              <Button onClick={() => {
            videoLabelStore.back(1);
        }} style={{ marginRight: 5 }} icon={<LeftOutlined />}></Button>
            </Tooltip>
            <Tooltip title={"播放/暂停 快捷键 "}>
              <Button onClick={() => {
            videoLabelStore.playOrPause();
        }} style={{ marginRight: 5 }} icon={videoLabelStore.paused ? <CaretRightOutlined /> : <PauseOutlined />}></Button>
            </Tooltip>
            <Tooltip title={"前进1帧 快捷键 ➡️"}>
              <Button onClick={() => {
            videoLabelStore.forward(1);
        }} style={{ marginRight: 5 }} icon={<RightOutlined />}></Button>
            </Tooltip>
            <Tooltip title={"前进10帧 快捷键 shift + ➡️"}>
              <Button onClick={() => {
            videoLabelStore.forward(10);
        }} style={{ marginRight: 5 }} icon={<DoubleRightOutlined />}></Button>
            </Tooltip>
            <InputNumber onChange={value => {
            videoLabelStore.currentFrame = value;
        }} step={1} value={videoLabelStore.currentFrame}/>
            <span style={{ marginRight: 10 }}>帧</span>
            <span>
              {isChunkTask ? (<React.Fragment>
                  <InputNumber step={0.5} min={0.5} max={8} value={this.state.playbackRate} onChange={speed => {
            if (speed >= 0.5 && speed <= 8) {
                this.setState({
                    playbackRate: speed
                });
            }
        }}></InputNumber>
                  倍速
                </React.Fragment>) : (<React.Fragment>
                  <InputNumber step={0.01} min={minFrameRate} max={maxFrameRate} value={_.ceil(this.state.playbackRate * videoLabelStore.meta.frameRate, 2)} onChange={rate => {
            if (rate >= minFrameRate && rate <= maxFrameRate) {
                this.setState({ playbackRate: rate / videoLabelStore.meta.frameRate });
            }
        }}></InputNumber>
                  <Tooltip title={"一秒播放几帧"}>速率</Tooltip>
                </React.Fragment>)}
            </span>
            <span>{loading && <LoadingOutlined className="icon-loading"/>}</span>
          </Col>
          <Col span={6}>
            
          </Col>
        </Row>
        <Row>
          <Timeline height={40} width={this.props.width - 15} unit={videoLabelStore.unit} unitValue={videoLabelStore.unitValue} onChange={time => {
            this.onTimelineChange(time);
        }} value={videoLabelStore.currentTime} zoom={videoLabelStore.zoom} backgroundColor="transparent" end={videoLabelStore.meta.duration} sample={videoLabelStore.meta.sample}>
            <SliderBar frameRate={videoLabelStore.meta.frameRate} chunks={chunks} selectedChunkIds={imageLabelStore.status.selectedElementIds} onSelect={chunk => {
            imageLabelStore.status.selectedElementIds = [chunk.id];
        }} onContextMenu={(e, ele) => {
            this.setState({
                contextMenu: {
                    visible: true,
                    pageX: e.pageX,
                    pageY: e.pageY,
                    element: ele
                }
            });
        }} onDoubleClick={() => {
            this.onSliderDoubleClick();
        }} onChange={(chunk, value, field) => {
            if (!videoLabelStore.paused) {
                videoLabelStore.pause();
            }
            chunk.detection.value[field] = value;
            if (field === "startTime") {
                chunk.detection.value.startFrame = value / videoLabelStore.meta.timePerFrame;
            }
            else if (field === "endTime") {
                chunk.detection.value.endFrame = value / videoLabelStore.meta.timePerFrame;
            }
        }}/>
          </Timeline>
        </Row>
        {imageLabelStore.status.enableContextMenu && this.state.contextMenu.visible && (<div ref={el => (this.menuRef = el)} className="menu-wrapper">
            <Menu mode="vertical" style={{
            position: "fixed",
            left: this.state.contextMenu.pageX,
            bottom: window.innerHeight - this.state.contextMenu.pageY,
            borderRadius: 5
        }} onClick={e => this.onMenuClick(e)}>
              <Menu.SubMenu key={"select"} title={"修改标签"}>
                {(_d = imageLabelStore.status.selectableLabel.detections) === null || _d === void 0 ? void 0 : _d.filter(detection => detection.tool === DetectionTool.Chunk).map(detection => (<Menu.Item key={detection.key}>{detection.locale || detection.key}</Menu.Item>))}
              </Menu.SubMenu>
              <Menu.Item key="delete">删除时间轴</Menu.Item>
              <Menu.Item key="cancel">取消</Menu.Item>
            </Menu>
          </div>)}
        <PreviewDialog imageObjs={videoLabelStore.previewImageObjs} open={videoLabelStore.previewDialogVisible} onCancel={() => (videoLabelStore.previewDialogVisible = false)}/>
      </div>);
    }
};
VideoPainter = __decorate([
    observer
], VideoPainter);
export default VideoPainter;
