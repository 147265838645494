var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { assignModalStore } from "./AssignModalStore";
import { Form, Modal, Select, Button } from "antd";
import { observer } from "mobx-react";
import { toJS } from "mobx";
let AssignModal = class AssignModal extends React.Component {
    componentDidMount() {
        assignModalStore.loadData();
    }
    componentWillUnmount() {
        assignModalStore.resetStatus();
    }
    render() {
        const { isVisible, onCancel, onOk } = this.props;
        const status = assignModalStore.status;
        return (<Modal open={isVisible} title="人员分配" onCancel={() => onCancel()} onOk={() => {
            onOk(status.userId);
        }} footer={[
            <Button key="back" onClick={() => {
                onCancel();
            }}>
            取消
          </Button>,
            <Button key="submit" type="primary" loading={status.loading} onClick={async () => {
                status.loading = true;
                await onOk(status.userId);
                status.loading = false;
            }}>
            确定
          </Button>
        ]}>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="单位">
            <Select style={{ width: "100%" }} value={status.groupId} onChange={async (groupId) => {
            var _a;
            status.groupId = groupId;
            status.userId = (_a = _.find(status.groups, item => item.id === groupId)) === null || _a === void 0 ? void 0 : _a.leaderUserId;
            await assignModalStore.loadUsers();
        }}>
              {status.groups.map(group => (<Select.Option key={group.id} value={group.id}>
                  {group.name}
                </Select.Option>))}
            </Select>
          </Form.Item>
          <Form.Item label="人员">
            <Select style={{ width: "100%" }} showSearch={true} notFoundContent={<div>暂无数据</div>} value={status.userId} filterOption={(value, option) => {
            var _a;
            return (((_a = option === null || option === void 0 ? void 0 : option.props) === null || _a === void 0 ? void 0 : _a.children) || "").indexOf(value) > -1;
        }} onChange={(value) => {
            status.userId = value;
        }}>
              {toJS(status.users).map(user => (<Select.Option key={String(user.id)} value={user.id}>
                  {user.name}
                </Select.Option>))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>);
    }
};
AssignModal = __decorate([
    observer
], AssignModal);
export { AssignModal };
