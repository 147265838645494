var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import { toJS } from "mobx";
import BasicStore from "../../../../store/BasicStore";
import { action, observable, computed } from "mobx";
import { Api } from "../../../../api/Api";
import { routerStore } from "../../../../store/routerStore";
import { ILabellingAssessmentPolicy } from "definition/entity/labelling-assessment-policy";
import { PodStatusPhase } from "../../../../util/constant";
class Status {
    constructor() {
        this.isLoading = true;
        this.verifyId = undefined;
        this.LabellingAssessmentPolicy = null;
        this.historyLabellingAssessmentPolicies = [];
        this.policyScript = undefined;
        this.policyScriptFilelist = [];
        this.groundTruthExample = undefined;
        this.groundTruthExampleFilelist = [];
        this.assessingExample = undefined;
        this.assessingExampleFilelist = [];
        this.assessingThresholdExample = undefined;
        this.assessingThresholdExampleFilelist = [];
        this.assessingExampleResult = undefined;
        this.isShowAssessingResultModal = false;
        this.isAssessing = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "verifyId", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof ILabellingAssessmentPolicy !== "undefined" && ILabellingAssessmentPolicy.AsObject) === "function" ? _a : Object)
], Status.prototype, "LabellingAssessmentPolicy", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "historyLabellingAssessmentPolicies", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "policyScript", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "policyScriptFilelist", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "groundTruthExample", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "groundTruthExampleFilelist", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "assessingExample", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "assessingExampleFilelist", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "assessingThresholdExample", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "assessingThresholdExampleFilelist", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "assessingExampleResult", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isShowAssessingResultModal", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isAssessing", void 0);
class LabellingAssessmentPolicyDetailStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
        this.getVerifyAssessmentPolicyTimeout = null;
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData(policyId) {
        this.status.isLoading = true;
        try {
            const getLabellingAssessmentPolicyRes = await Api.labellingAssessmentPolicy.getLabellingAssessmentPolicy(policyId);
            this.status.LabellingAssessmentPolicy = getLabellingAssessmentPolicyRes.data;
            this.status.policyScript = getLabellingAssessmentPolicyRes.data.policyScript;
            this.status.groundTruthExample = getLabellingAssessmentPolicyRes.data.groundTruthExample;
            this.status.assessingExample = getLabellingAssessmentPolicyRes.data.assessingExample;
            this.status.assessingThresholdExample = getLabellingAssessmentPolicyRes.data.assessingThresholdExample;
        }
        finally {
            this.status.isLoading = false;
        }
    }
    async getHistoryAssessmentPolicyVerification() {
        const getHistoryAssessmentPolicyVerificationRes = await Api.labellingAssessmentPolicy.getLabellingAssessmentPolicies({ name: this.status.LabellingAssessmentPolicy.name });
        this.status.historyLabellingAssessmentPolicies = getHistoryAssessmentPolicyVerificationRes.data;
    }
    async getAssessmentPolicyVerificationLoop() {
        if (this.getVerifyAssessmentPolicyTimeout !== null || !this.status.isAssessing) {
            return;
        }
        this.getVerifyAssessmentPolicyTimeout = setTimeout(async () => {
            try {
                await this.onceGetAssessmentPolicyVerification();
                clearTimeout(this.getVerifyAssessmentPolicyTimeout);
                this.getVerifyAssessmentPolicyTimeout = null;
                this.getAssessmentPolicyVerificationLoop();
            }
            catch (e) {
                console.log(e);
                this.clearGetAssessmentPolicyVerificationLoop();
            }
        }, 1000);
    }
    async onceGetAssessmentPolicyVerification() {
        const getVerifyAssessmentPolicyRes = await Api.labellingAssessmentPolicy.getAssessmentPolicyVerification(this.status.verifyId);
        if (getVerifyAssessmentPolicyRes.code === 200) {
            if (getVerifyAssessmentPolicyRes.data.phase === PodStatusPhase.Failed) {
                this.getAssessmentPolicyVerificationLogs();
            }
            if (getVerifyAssessmentPolicyRes.data.phase === PodStatusPhase.Succeeded) {
                if (getVerifyAssessmentPolicyRes.data.action === ILabellingAssessmentPolicy.Action.Update) {
                    this.clearGetAssessmentPolicyVerificationLoop();
                    routerStore.push("/manage/labelling_assessment_policies");
                }
                else {
                    this.getAssessmentPolicyVerificationLogs();
                }
            }
        }
    }
    async getAssessmentPolicyVerificationLogs() {
        const getVerifyAssessmentPolicyLogsRes = await Api.labellingAssessmentPolicy.getAssessmentPolicyVerificationLogs(this.status.verifyId);
        if (getVerifyAssessmentPolicyLogsRes.code === 200) {
            this.status.assessingExampleResult = getVerifyAssessmentPolicyLogsRes.data.result;
            this.status.isShowAssessingResultModal = true;
            this.clearGetAssessmentPolicyVerificationLoop();
        }
    }
    async temporaryVerify() {
        this.clearGetAssessmentPolicyVerificationLoop();
        const { policyScript, groundTruthExample, assessingExample, assessingThresholdExample } = toJS(this.status);
        try {
            const reqParams = new FormData();
            reqParams.append("policyScript", policyScript);
            reqParams.append("groundTruthExample", groundTruthExample);
            reqParams.append("assessingExample", assessingExample);
            reqParams.append("assessingThresholdExample", assessingThresholdExample);
            const temporaryVerifyRes = await Api.labellingAssessmentPolicy.createAssessmentPolicyVerification(reqParams);
            if (temporaryVerifyRes.code === 200) {
                this.status.verifyId = temporaryVerifyRes.data.id;
                this.status.isAssessing = true;
                this.getAssessmentPolicyVerificationLoop();
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    clearGetAssessmentPolicyVerificationLoop() {
        clearTimeout(this.getVerifyAssessmentPolicyTimeout);
        this.getVerifyAssessmentPolicyTimeout = null;
        this.status.isAssessing = false;
    }
    async modifyLabellingAssessmentPolicy(policyId) {
        const { name, remark } = this.status.LabellingAssessmentPolicy;
        const updateRes = await Api.labellingAssessmentPolicy.modifyLabellingAssessmentPolicy(policyId, {
            name: name,
            remark: remark
        });
        if (updateRes.code === 200) {
            routerStore.goBack();
        }
    }
    async updateLabellingAssessmentPolicy(policyId) {
        this.clearGetAssessmentPolicyVerificationLoop();
        const { policyScript, groundTruthExample, assessingExample } = toJS(this.status);
        try {
            const updateLabellingAssessmentPolicyRes = await Api.labellingAssessmentPolicy.updateLabellingAssessmentPolicy(policyId, {
                policyScript: policyScript,
                groundTruthExample: groundTruthExample,
                assessingExample: assessingExample
            });
            if (updateLabellingAssessmentPolicyRes.code === 200) {
                this.status.verifyId = updateLabellingAssessmentPolicyRes.data.id;
                this.status.isAssessing = true;
                this.getAssessmentPolicyVerificationLoop();
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    downloadGroundTruthExample(policyId) {
        window.open(`/api/labelling_assessment_policy/${policyId}/ground_truth_example`);
    }
    downloadAssessingExample(policyId) {
        window.open(`/api/labelling_assessment_policy/${policyId}/assessing_example`);
    }
    get isEnableAssessment() {
        const { policyScript, groundTruthExample, assessingExample, assessingThresholdExample } = this.status;
        return policyScript && groundTruthExample && assessingExample && assessingThresholdExample;
    }
    get isUnableModify() {
        return _.isNil(this.status.LabellingAssessmentPolicy.name);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabellingAssessmentPolicyDetailStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabellingAssessmentPolicyDetailStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabellingAssessmentPolicyDetailStore.prototype, "isEnableAssessment", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabellingAssessmentPolicyDetailStore.prototype, "isUnableModify", null);
export const labellingAssessmentPolicyDetailStore = new LabellingAssessmentPolicyDetailStore();
