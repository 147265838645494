var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "api/Api";
class Status {
    constructor() {
        this.sharedSandBags = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "sharedSandBags", void 0);
class SharedSandBagsStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const getSharedSandBagsRes = await Api.labelSandBags.getSharedSandBags();
        if (getSharedSandBagsRes.code === 200) {
            this.status.sharedSandBags = getSharedSandBagsRes.data;
        }
    }
    async refuseLabelSandBag(sharedBagId) {
        const refuseLabelSandBagRes = await Api.labelSandBags.refuseLabelSandBag(sharedBagId);
        if (refuseLabelSandBagRes.code === 200) {
            await this.loadData();
        }
    }
    async acceptLabelSandBag(sharedBagId) {
        const acceptLabelSandBagRes = await Api.labelSandBags.acceptLabelSandBag(sharedBagId);
        if (acceptLabelSandBagRes.code === 200) {
            await this.loadData();
        }
    }
}
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SharedSandBagsStore.prototype, "resetStatus", null);
export const sharedSandBagsStore = new SharedSandBagsStore();
