import * as React from "react";
import { Button, Card, Form, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Api } from "../../../api/Api";
import { routerStore } from "../../../store/routerStore";
import "./SignUp.less";
export const SignUp = () => {
    const [form] = Form.useForm();
    return (<div className="sign-up-page">
      <Card>
        <h1>LabelX 账户注册</h1>
        <Form form={form}>
          <Form.Item name="name">
            <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }}/>} placeholder="输入账户名称"/>
          </Form.Item>
          <Form.Item name="nickname">
            <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }}/>} placeholder="输入昵称"/>
          </Form.Item>
          <Form.Item name="password">
            <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }}/>} placeholder="输入账户密码" type="password"/>
          </Form.Item>
          <Form.Item name="passwordConfirm">
            <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }}/>} placeholder="输入账户密码" type="password"/>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} type="primary" onClick={async () => {
        const { name, nickname, password, passwordConfirm } = await form.getFieldsValue();
        if (!name || !nickname) {
            message.error("账户名称和昵称不能为空");
            return;
        }
        if (password !== passwordConfirm) {
            message.warning("两次密码输入不一致");
            return;
        }
        const signUpRes = await Api.user.signUp(name, nickname, password);
        if (signUpRes.code === 200) {
            window.location.href = "/manage";
        }
    }}>
              确认
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card style={{ marginTop: "20px" }}>
        <span>已有账户？</span>
        <a onClick={() => {
        routerStore.push("/sign_in");
    }}>
          去登录
        </a>
      </Card>
    </div>);
};
