var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { DetectionTool } from "definition/entity/label-task";
import * as _ from "lodash";
import { computed, observable } from "mobx";
import { imageLabelStore } from "page/label/image/ImageLabel/ImageLabelStore";
import { randomString } from "util/random-string";
import { Api } from "../../../../api/Api";
import VideoStage from "./VideoStage";
class VideoLabelStore {
    constructor() {
        this.defaults = {
            zoom: 1,
            unit: 100,
            unitValue: 1000 * 60
        };
        this._playId = null;
        this.init();
    }
    init() {
        this.isLoading = false;
        this.meta = {
            duration: 0,
            currentTime: 0,
            frameRate: 25,
            startTime: 0,
            get timePerFrame() {
                return _.floor(1000 / this.frameRate, 3);
            },
            sample: undefined
        };
        this.paused = true;
        this.zoom = this.defaults.zoom;
        this.unit = this.defaults.unit;
        this.unitValue = this.defaults.unitValue;
        this.previewDialogVisible = false;
        this.previewImageObjs = [];
    }
    reset() {
        this.init();
    }
    get state() {
        const { labelTaskId, labelPackageId, curOffset } = imageLabelStore.status;
        return {
            labelTaskId,
            labelPackageId,
            curOffset,
            annotation: imageLabelStore.getAnnotation()
        };
    }
    get currentTime() {
        return (Math.floor((this.meta.currentTime / this.meta.timePerFrame) * 100) * this.meta.timePerFrame) / 100;
    }
    set currentTime(timems) {
        if (timems < 0) {
            timems = 0;
        }
        if (timems > this.meta.duration) {
            timems = this.meta.duration;
        }
        timems = Math.round(timems / this.meta.timePerFrame) * this.meta.timePerFrame;
        if (timems > this.meta.duration) {
            timems -= this.meta.timePerFrame;
        }
        this.videoStage.video.currentTime = timems / 1000;
        this.meta.currentTime = timems;
    }
    get currentFrame() {
        return Math.round(this.meta.currentTime / this.meta.timePerFrame);
    }
    set currentFrame(frame) {
        this.currentTime = frame * this.meta.timePerFrame;
    }
    playOrPause() {
        if (this.videoStage.video.paused) {
            this.play();
        }
        else {
            this.pause();
        }
    }
    async play(startTime, endTime) {
        const playId = (this._playId = randomString());
        if (startTime != null) {
            this.currentTime = startTime;
        }
        const onChange = () => {
            if (this._playId !== playId)
                return;
            if (this.meta.currentTime >= endTime) {
                this.pause();
            }
            else {
                this.videoStage.video.requestVideoFrameCallback(() => {
                    onChange();
                });
            }
        };
        await this.videoStage.play();
        this.paused = false;
        if (endTime != null) {
            onChange();
        }
    }
    pause() {
        this.paused = true;
        this.videoStage.pause();
    }
    back(frame) {
        let current = this.meta.currentTime;
        let time = current - frame * this.meta.timePerFrame;
        this.currentTime = time;
    }
    forward(frame) {
        let current = this.meta.currentTime;
        let time = current + frame * this.meta.timePerFrame;
        this.currentTime = time;
    }
    checkBoundary(pos) {
        const status = imageLabelStore.status;
        const painterBGSize = status.config.painterBGSize;
        const { zoomRatio } = status;
        let { x, y, width, height } = pos;
        let x0 = Math.round((x + width) / zoomRatio);
        let y0 = Math.round((y + height) / zoomRatio);
        y = Math.round(y / zoomRatio);
        x = Math.round(x / zoomRatio);
        if (x <= 0 && y > 0 && y0 < painterBGSize.height) {
            return "w";
        }
        else if (x <= 0 && y <= 0) {
            return "nw";
        }
        else if (x > 0 && y <= 0 && x0 != painterBGSize.width) {
            return "n";
        }
        else if (x0 >= painterBGSize.width && y0 < painterBGSize.height && y > 0) {
            return "e";
        }
        else if (x0 >= painterBGSize.width && y0 <= 0) {
            return "ne";
        }
        else if (x0 >= painterBGSize.width && y0 >= painterBGSize.height) {
            return "se";
        }
        else if (x0 < painterBGSize.width && y0 >= painterBGSize.height && x > 0) {
            return "s";
        }
        else if (x <= 0 && y0 >= painterBGSize.height) {
            return "sw";
        }
        else {
            return null;
        }
    }
    getTweenTrail(_trails, tool, frame) {
        const { status: { zoomRatio } } = imageLabelStore;
        let trails = [];
        let fields = [];
        if (tool === DetectionTool.Rectangle) {
            trails = _trails.map(v => {
                return Object.assign(Object.assign({}, v), { x: v.x * zoomRatio, y: v.y * zoomRatio, width: v.width * zoomRatio, height: v.height * zoomRatio });
            });
            fields = ["x", "y", "width", "height"];
        }
        else if (tool === DetectionTool.Dot) {
            trails = _trails.map(v => {
                return Object.assign(Object.assign({}, v), { cx: v.cx * zoomRatio, cy: v.cy * zoomRatio });
            });
            fields = ["cx", "cy"];
        }
        let kMap = {};
        for (let i = 0; i < trails.length; i++) {
            let pos = trails[i];
            let nextPos = trails[i + 1];
            if (frame <= pos.frame) {
                return Object.assign(Object.assign({}, pos), { frame });
            }
            if (nextPos) {
                fields.forEach(f => {
                    kMap[f] = (nextPos[f] - pos[f]) / (nextPos.frame - pos.frame);
                });
                if (pos.frame <= frame && frame < nextPos.frame) {
                    let value = {};
                    fields.forEach(f => {
                        value[f] = kMap[f] * (frame - pos.frame) + pos[f];
                    });
                    return Object.assign(Object.assign(Object.assign({}, pos), value), { frame });
                }
            }
            else {
                if (pos.inview === false && Object.keys(kMap).length && tool === DetectionTool.Rectangle) {
                    const boundary = this.checkBoundary(pos);
                    let { width, height, x, y } = pos;
                    const delta = _.fromPairs(fields.map(field => {
                        return [field, kMap[field] * (frame - pos.frame)];
                    }));
                    const { x: deltaX, y: deltaY, width: deltaWidth, height: deltaHeight } = delta;
                    switch (boundary) {
                        case "n":
                            height = pos.height + (deltaY || deltaHeight);
                            break;
                        case "ne":
                            height = pos.height + deltaY;
                            width = pos.width + deltaX;
                            x = pos.x + deltaX;
                            break;
                        case "e":
                            width = pos.width - deltaX;
                            x = pos.x + deltaX;
                            break;
                        case "se":
                            height = pos.height - deltaY;
                            width = pos.width - deltaX;
                            x = pos.x + deltaX;
                            y = pos.y + deltaY;
                            break;
                        case "s":
                            height = pos.height - deltaY;
                            y = pos.y + deltaY;
                            break;
                        case "sw":
                            width = pos.width + deltaX;
                            height = pos.height - deltaY;
                            y = pos.y + deltaY;
                            break;
                        case "w":
                            width = pos.width + (deltaX || deltaWidth);
                            break;
                        case "nw":
                            width = pos.width + deltaX;
                            height = pos.height + deltaY;
                            break;
                    }
                    return Object.assign(Object.assign({}, pos), { x, y, width, height, frame });
                }
                else {
                    return Object.assign(Object.assign({}, pos), { frame });
                }
            }
        }
    }
    async previewSyntheticImage(ancestorId) {
        const { labelTaskId, labelPackageId } = imageLabelStore.status;
        const anchorObj = imageLabelStore.getAnchors();
        const anchorArr = ancestorId !== undefined ? [[anchorObj[ancestorId]]] : Object.values(anchorObj).map(_ => [_]);
        this.previewDialogVisible = true;
        this.previewImageObjs = [];
        anchorArr.map(async (anchors, i) => {
            const { data } = await Api.labelTask.previewSyntheticImage(labelTaskId, labelPackageId, {
                url: imageLabelStore.status.curResource.url,
                annotation: { anchors, detections: [] },
                meta: imageLabelStore.status.curResource.meta
            });
            this.previewImageObjs.push(data);
        });
    }
    get drawable() {
        if (this.meta.sample) {
            return this.meta.sample.some(v => {
                if (v.start * 1000 <= this.currentTime && v.end * 1000 >= this.currentTime) {
                    return true;
                }
                return false;
            });
        }
        return true;
    }
    get totalFrames() {
        return _.floor(this.meta.duration / this.meta.timePerFrame);
    }
}
__decorate([
    observable.ref,
    __metadata("design:type", typeof (_a = typeof VideoStage !== "undefined" && VideoStage) === "function" ? _a : Object)
], VideoLabelStore.prototype, "videoStage", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], VideoLabelStore.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], VideoLabelStore.prototype, "meta", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], VideoLabelStore.prototype, "paused", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], VideoLabelStore.prototype, "zoom", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], VideoLabelStore.prototype, "unit", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], VideoLabelStore.prototype, "unitValue", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], VideoLabelStore.prototype, "previewDialogVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], VideoLabelStore.prototype, "previewImageObjs", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], VideoLabelStore.prototype, "currentTime", null);
__decorate([
    computed,
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], VideoLabelStore.prototype, "currentFrame", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], VideoLabelStore.prototype, "drawable", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], VideoLabelStore.prototype, "totalFrames", null);
const videoLabelStore = new VideoLabelStore();
export default videoLabelStore;
