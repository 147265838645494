import axios from "../util/axios";
import { deleteNilProperty } from "util/delete-nil-property";
export const listTaskBusinesses = (params) => {
    params = deleteNilProperty(params);
    return axios.get("/task_businesses", { params });
};
export const createTaskBusiness = (params) => {
    return axios.post("/task_business", params);
};
export const updateTaskBusiness = (params) => {
    return axios.post(`/task_business/${params.taskBusinessId}`, {
        name: params.name,
        type: params.type,
        isValid: params.isValid,
        standardNum: params.standardNum
    });
};
export const deleteTaskBusiness = (taskBusinessId) => {
    return axios.delete(`/task_business/${taskBusinessId}`);
};
