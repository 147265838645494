var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../../store/BasicStore";
import moment from "moment";
import { action, computed, observable, toJS } from "mobx";
import { Api } from "../../../../api/Api";
import { routerStore } from "store/routerStore";
import { ILabellingAssessmentPolicy } from "definition/entity/labelling-assessment-policy";
import { PodStatusPhase } from "../../../../util/constant";
class Status {
    constructor() {
        this.name = "";
        this.remark = "";
        this.currentDateStr = moment().format("YYYY-MM-DD");
        this.policyScript = undefined;
        this.groundTruthExample = undefined;
        this.assessingExample = undefined;
        this.assessingExampleResult = undefined;
        this.verifyId = undefined;
        this.isAssessing = false;
        this.isShowAssessingResultModal = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "remark", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "currentDateStr", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "policyScript", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "groundTruthExample", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "assessingExample", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "assessingExampleResult", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "verifyId", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isAssessing", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isShowAssessingResultModal", void 0);
class LabellingAssessmentPolicyCreateStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
        this.getVerifyAssessmentPolicyTimeout = null;
    }
    resetStatus() {
        this.status = new Status();
    }
    getAssessmentPolicyVerificationLoop() {
        if (this.getVerifyAssessmentPolicyTimeout !== null || !this.status.isAssessing) {
            return;
        }
        this.getVerifyAssessmentPolicyTimeout = setTimeout(async () => {
            try {
                await this.onceGetAssessmentPolicyVerification();
                clearTimeout(this.getVerifyAssessmentPolicyTimeout);
                this.getVerifyAssessmentPolicyTimeout = null;
                this.getAssessmentPolicyVerificationLoop();
            }
            catch (e) {
                console.log(e);
                this.clearGetAssessmentPolicyVerificationLoop();
            }
        }, 1000);
    }
    async onceGetAssessmentPolicyVerification() {
        const getVerifyAssessmentPolicyRes = await Api.labellingAssessmentPolicy.getAssessmentPolicyVerification(this.status.verifyId);
        if (getVerifyAssessmentPolicyRes.code === 200) {
            if (getVerifyAssessmentPolicyRes.data.phase === PodStatusPhase.Failed) {
                this.getAssessmentPolicyVerificationLogs();
            }
            if (getVerifyAssessmentPolicyRes.data.phase === PodStatusPhase.Succeeded) {
                if (getVerifyAssessmentPolicyRes.data.action === ILabellingAssessmentPolicy.Action.Create) {
                    this.clearGetAssessmentPolicyVerificationLoop();
                    routerStore.push("/manage/labelling_assessment_policies");
                }
                else {
                    this.getAssessmentPolicyVerificationLogs();
                }
            }
        }
    }
    async getAssessmentPolicyVerificationLogs() {
        const getVerifyAssessmentPolicyLogsRes = await Api.labellingAssessmentPolicy.getAssessmentPolicyVerificationLogs(this.status.verifyId);
        if (getVerifyAssessmentPolicyLogsRes.code === 200) {
            this.status.assessingExampleResult = getVerifyAssessmentPolicyLogsRes.data.body;
            this.status.isShowAssessingResultModal = true;
            this.clearGetAssessmentPolicyVerificationLoop();
        }
    }
    async temporaryVerify() {
        this.clearGetAssessmentPolicyVerificationLoop();
        const { policyScript, groundTruthExample, assessingExample } = toJS(this.status);
        try {
            const temporaryVerifyRes = await Api.labellingAssessmentPolicy.createAssessmentPolicyVerification({
                policyScript,
                groundTruthExample,
                assessingExample
            });
            if (temporaryVerifyRes.code === 200) {
                this.status.verifyId = temporaryVerifyRes.data.id;
                this.status.isAssessing = true;
                this.getAssessmentPolicyVerificationLoop();
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    clearGetAssessmentPolicyVerificationLoop() {
        clearTimeout(this.getVerifyAssessmentPolicyTimeout);
        this.getVerifyAssessmentPolicyTimeout = null;
        this.status.isAssessing = false;
    }
    async createLabellingAssessmentPolicy() {
        this.clearGetAssessmentPolicyVerificationLoop();
        const { name, currentDateStr, remark, policyScript, groundTruthExample, assessingExample } = toJS(this.status);
        try {
            const createLabellingAssessmentPolicyRes = await Api.labellingAssessmentPolicy.createLabellingAssessmentPolicy({
                name: `${currentDateStr}-${name}`,
                remark,
                policyScript: policyScript,
                groundTruthExample: groundTruthExample,
                assessingExample: assessingExample
            });
            if (createLabellingAssessmentPolicyRes.code === 200) {
                this.status.verifyId = createLabellingAssessmentPolicyRes.data.id;
                this.status.isAssessing = true;
                this.getAssessmentPolicyVerificationLoop();
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    get isEnableCreate() {
        const { name } = toJS(this.status);
        return this.isEnableAssessment && name;
    }
    get isEnableAssessment() {
        const { policyScript, groundTruthExample, assessingExample } = toJS(this.status);
        return policyScript && groundTruthExample && assessingExample;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabellingAssessmentPolicyCreateStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabellingAssessmentPolicyCreateStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabellingAssessmentPolicyCreateStore.prototype, "isEnableCreate", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabellingAssessmentPolicyCreateStore.prototype, "isEnableAssessment", null);
export const labellingAssessmentPolicyCreateStore = new LabellingAssessmentPolicyCreateStore();
