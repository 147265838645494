var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { CompactPicker } from "react-color";
import { Button, Card, Checkbox, Input, Popover, Radio, Select, Switch } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { ClassificationTool } from "definition/entity/label-task";
import { genDefaultOption } from "../../LabelEditorStore";
import "./ClassificationItem.less";
let ClassificationItem = class ClassificationItem extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            enableDefaultValue: true
        };
    }
    renderOption(option, classification) {
        return (<div style={{ width: "calc(100% - 50px)", display: "inline-block", verticalAlign: "middle", marginBottom: 5 }}>
        <div key={option.uniqKey} className="item-options-detail">
          <Popover content={<CompactPicker style={{ width: 200 }} value={option.color} onChange={(color) => {
            option.color = color.hex;
        }}/>}>
            <div style={{ width: 40, height: 40, borderRadius: "50%", background: option.color }}/>
          </Popover>
          <Input placeholder="选项 key" value={option.key} onChange={event => {
            option.key = event.target.value;
        }}/>
          <Input placeholder="选项说明" value={option.locale} onChange={event => {
            option.locale = event.target.value;
        }}/>
          <DeleteOutlined onClick={() => {
            classification.options = _.filter(classification.options, item => item.uniqKey !== option.uniqKey);
        }}/>
        </div>
      </div>);
    }
    render() {
        var _a, _b, _c;
        const { classification, withDefaultValue, onDelete } = this.props;
        return (<Card className="classification-item-comp">
        <div className="classification-body-wrapper">
          <div>
            标签说明
            <span style={{ marginLeft: 20 }}>
              默认值
              <Switch checked={this.state.enableDefaultValue} onChange={() => {
            this.setState({ enableDefaultValue: !this.state.enableDefaultValue });
            this.props.classification.defaultValue = null;
        }}></Switch>
            </span>
            <span style={{ marginLeft: 20 }}>
              是否必填
              <Switch checked={classification.required} onChange={v => {
            classification.required = v;
        }}></Switch>
            </span>
          </div>
          <div className="item-desc-detail">
            <Input placeholder="问题 key" value={classification.key} onChange={event => {
            classification.key = event.target.value;
        }}/>
            <Input placeholder="问题说明" value={classification.locale} onChange={event => {
            classification.locale = event.target.value;
        }}/>
            <Select className="item-type" value={classification.tool} onChange={(value, option) => {
            classification.tool = value;
            classification.defaultValue = null;
            classification.options = [];
        }} placeholder="选择类型">
              <Select.Option key="switch">布尔</Select.Option>
              <Select.Option key="radio">单选</Select.Option>
              <Select.Option key="checkbox">多选</Select.Option>
              <Select.Option key="text">文本</Select.Option>
              <Select.Option key="number">数字</Select.Option>
              <Select.Option key="date">日期</Select.Option>
              <Select.Option key="time">时间</Select.Option>
              <Select.Option key="select">多选框</Select.Option>
            </Select>
            <DeleteOutlined className="item-delete-icon" onClick={() => {
            onDelete();
        }}/>
          </div>
          {[ClassificationTool.Checkbox, ClassificationTool.Radio].includes(classification.tool) && (<React.Fragment>
              <div>标签选项</div>
              {this.state.enableDefaultValue && withDefaultValue ? (<React.Fragment>
                  {classification.tool === ClassificationTool.Radio && (<Radio.Group value={classification.defaultValue} onChange={e => {
            classification.defaultValue = e.target.value;
        }}>
                      {(_a = classification.options) === null || _a === void 0 ? void 0 : _a.map((option, index) => (<Radio key={option.uniqKey} value={option.key} style={{ display: "block" }}>
                          {this.renderOption(option, classification)}
                        </Radio>))}
                    </Radio.Group>)}
                  {classification.tool === ClassificationTool.Checkbox && (<Checkbox.Group className="checkbox-group" value={classification.defaultValue} onChange={value => {
            classification.defaultValue = value;
        }}>
                      {(_b = classification.options) === null || _b === void 0 ? void 0 : _b.map((option, index) => (<Checkbox key={option.uniqKey} value={option.key} style={{ display: "block", marginLeft: 0 }}>
                          {this.renderOption(option, classification)}
                        </Checkbox>))}
                    </Checkbox.Group>)}
                </React.Fragment>) : (<React.Fragment>
                  {(_c = classification.options) === null || _c === void 0 ? void 0 : _c.map((option, index) => {
            return this.renderOption(option, classification);
        })}
                </React.Fragment>)}
              <Button icon={<PlusOutlined />} style={{ width: "min-content" }} onClick={() => {
            classification.options.push(genDefaultOption());
        }}>
                添加选项
              </Button>
            </React.Fragment>)}
          {withDefaultValue && this.state.enableDefaultValue && (<React.Fragment>
              {classification.tool === ClassificationTool.Switch && (<Select style={{ width: 200 }} value={classification.defaultValue ? 1 : 0} onChange={value => {
            classification.defaultValue = !!value;
        }}>
                  <Select.Option value={0}>否</Select.Option>
                  <Select.Option value={1}>是</Select.Option>
                </Select>)}
              {classification.tool === ClassificationTool.Text && (<Input style={{ width: 200 }} value={classification.defaultValue} onChange={e => {
            classification.defaultValue = e.target.value;
        }}></Input>)}
            </React.Fragment>)}
        </div>
      </Card>);
    }
};
ClassificationItem = __decorate([
    observer
], ClassificationItem);
export { ClassificationItem };
