var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Card, PageHeader, Tabs } from "antd";
import { GroupManage } from "./component/GroupManage/GroupManage";
import { UserManage } from "./component/UserManage/UserManage";
import { routerStore } from "../../../../../store/routerStore";
import { parseLocationSearch } from "../../../../../util/parse-location-search";
import { adminStore } from "./AdminStore";
import { observer } from "mobx-react";
let Admin = class Admin extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            activeKey: null
        };
    }
    componentDidMount() {
        const { activeKey } = parseLocationSearch(this.props.location.search);
        if (activeKey) {
            adminStore.status.activeKey = activeKey;
        }
        else if (!adminStore.status.activeKey) {
            adminStore.status.activeKey = "user";
        }
    }
    componentWillUnmount() {
        adminStore.resetStatus();
    }
    render() {
        const status = adminStore.status;
        return (<PageHeader title="用户管理" style={{ background: "#FFF" }}>
        <Card>
          <Tabs type="card" activeKey={status.activeKey} onChange={activeKey => {
            status.activeKey = activeKey;
            routerStore.replace(`?activeKey=${activeKey}`);
        }}>
            <Tabs.TabPane tab="用户管理" key="user">
              <UserManage />
            </Tabs.TabPane>
            <Tabs.TabPane tab="单位管理" key="group">
              <GroupManage />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </PageHeader>);
    }
};
Admin = __decorate([
    observer
], Admin);
export { Admin };
