var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Collapse, Input, Select } from "antd";
import { ClassificationItemViewer } from "../ClassificationItemViewer/ClassificationItemViewer";
import { observer } from "mobx-react";
import "./DetectItemViewer.less";
let DetectItemViewer = class DetectItemViewer extends React.Component {
    render() {
        const { detect } = this.props;
        return (<div className="detect-item-viewer-comp">
        <Collapse expandIconPosition="end">
          <Collapse.Panel key={detect.uniqKey} header={<div className="detect-outside" onClick={e => {
            e.stopPropagation();
        }}>
                <div style={{ width: 40, height: 40, borderRadius: "50%", background: detect.color }}/>
                <Input placeholder="标注 key" value={detect.key}/>
                <Input placeholder="标注说明" value={detect.locale}/>
                <Select value={detect.tool} placeholder="选择类型">
                  <Select.Option key="dot">点标注</Select.Option>
                  <Select.Option disabled={true} key="line">
                    线标注
                  </Select.Option>
                  <Select.Option key="rectangle">矩形标注</Select.Option>
                  <Select.Option disabled={true} key="polygon">
                    多边形标注
                  </Select.Option>
                </Select>
              </div>}>
            {detect.classifications.map(classification => (<ClassificationItemViewer key={classification.uniqKey} classification={classification}/>))}
          </Collapse.Panel>
        </Collapse>
      </div>);
    }
};
DetectItemViewer = __decorate([
    observer
], DetectItemViewer);
export { DetectItemViewer };
