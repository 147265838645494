var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Card, PageHeader, Tabs } from "antd";
import { UserAudit } from "./component/UserAudit/UserAudit";
import { TaskAudit } from "./component/TaskAudit/TaskAudit";
import { routerStore } from "../../../../../store/routerStore";
import { parseLocationSearch } from "../../../../../util/parse-location-search";
import { dailyAuditStore } from "./DailyAuditStore";
import { observer } from "mobx-react";
let DailyAudit = class DailyAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            activeKey: null
        };
    }
    componentDidMount() {
        const { activeKey } = parseLocationSearch(this.props.location.search);
        if (activeKey) {
            dailyAuditStore.status.activeKey = activeKey;
        }
        else if (!dailyAuditStore.status.activeKey) {
            dailyAuditStore.status.activeKey = "userAudit";
        }
    }
    componentWillUnmount() {
        dailyAuditStore.resetStatus();
    }
    render() {
        const status = dailyAuditStore.status;
        return (<PageHeader title="每日巡检" style={{ background: "#FFF" }}>
        <Card>
          <Tabs type="card" activeKey={status.activeKey} onChange={activeKey => {
            status.activeKey = activeKey;
            routerStore.replace(`?activeKey=${activeKey}`);
        }}>
            <Tabs.TabPane tab="人员巡检" key="userAudit">
              <UserAudit />
            </Tabs.TabPane>
            <Tabs.TabPane tab="任务巡检" key="taskAudit">
              <TaskAudit />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </PageHeader>);
    }
};
DailyAudit = __decorate([
    observer
], DailyAudit);
export { DailyAudit };
