var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Row, Slider, Button, Select, Radio, Switch } from "antd";
import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import "./Tool3D.less";
import { imageLabelStore } from "../../ImageLabelStore";
import { toJS } from "mobx";
let Tool3D = class Tool3D extends React.Component {
    render() {
        const { tool, mode, pointSize, shaderMode, onToolChange, onModeChange, onPointSizeChange, onRotate, onShaderModeChange, onViewTypeChange, viewType, hasSegment3D, hasDetection3D } = this.props;
        return (<div className="tool3d">
        <section>
          <Row justify="center">
            {hasSegment3D && (<div style={{ textAlign: "center", width: 60 }}>
                <div>点云翻转</div>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" onClick={() => onRotate()}>
                  <defs>
                    <style type="text/css"></style>
                  </defs>
                  <path d="M506.88 107.093333L256 260.693333v306.773334l250.88 153.6 250.88-153.6V260.693333z m-20.906667 543.573334L298.666667 535.893333V314.88l188.16 115.2zM326.4 274.773333l180.48-110.08 180.906667 110.08L506.88 384z m389.546667 261.12l-188.16 114.773334v-220.586667l188.16-115.2zM765.866667 85.333333l151.466666 111.786667V85.333333" p-id="3796"></path>
                  <path d="M506.88 888.32c264.533333 0 469.333333-106.666667 469.333333-243.2 0-77.226667-66.56-145.066667-172.8-189.44v52.48c77.653333 35.413333 125.866667 85.333333 125.866667 136.96 0 105.813333-194.133333 194.986667-424.533333 194.986667s-426.666667-89.173333-426.666667-194.986667c0-52.906667 48.64-101.546667 128-136.96v-52.48c-105.813333 42.666667-170.666667 112.213333-170.666667 189.44 0 136.533333 207.36 243.2 471.466667 243.2z" p-id="3797"></path>
                </svg>
              </div>)}
            <div style={{ textAlign: "center", width: 120, height: 50 }}>
              <div>点云大小</div>
              <Slider value={pointSize} style={{ margin: 0 }} max={10} min={1} step={1} onChange={onPointSizeChange}></Slider>
            </div>
          </Row>
          {hasDetection3D && (<Row>
              <Radio.Group value={viewType} onChange={e => onViewTypeChange(e.target.value)}>
                <Radio.Button value="3d">3d view</Radio.Button>
                <Radio.Button value="top">top view</Radio.Button>
              </Radio.Group>
            </Row>)}
        </section>
        {hasSegment3D && [
            <section>
            <h3 style={{ textAlign: "center" }}>【选择工具】</h3>
            <Row justify="space-between" className="tool">
              <Button className={classNames({ selected: "lasso" === tool })} onClick={() => onToolChange("lasso")}>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M951.466667 477.866667c-12.8-12.8-25.6-17.066667-42.666667-17.066667s-29.866667 4.266667-42.666667 17.066667L512 832l-12.8 81.066667 89.6-4.266667 362.666667-349.866667c21.333333-21.333333 21.333333-59.733333 0-81.066666zM465.066667 861.866667c-17.066667-8.533333-38.4-21.333333-55.466667-34.133334-25.6-21.333333-42.666667-42.666667-42.666667-55.466666 0 0 4.266667-12.8 34.133334-51.2 21.333333-25.6 51.2-51.2 76.8-76.8 55.466667-51.2 85.333333-81.066667 89.6-106.666667 8.533333-34.133333-4.266667-55.466667-17.066667-68.266667-17.066667-17.066667-38.4-29.866667-72.533333-34.133333-34.133333-4.266667-76.8-4.266667-132.266667 0-12.8 0-25.6 4.266667-34.133333 4.266667-72.533333 8.533333-128 12.8-166.4 8.533333-34.133333-4.266667-42.666667-17.066667-46.933334-38.4 0 0 0-12.8 29.866667-42.666667 38.4-42.666667 110.933333-93.866667 192-136.533333 85.333333-46.933333 149.333333-68.266667 170.666667-68.266667 17.066667 0 34.133333-12.8 34.133333-29.866666s-12.8-34.133333-29.866667-34.133334c-21.333333 0-55.466667 8.533333-93.866666 21.333334-34.133333 12.8-72.533333 34.133333-115.2 55.466666-89.6 51.2-170.666667 110.933333-209.066667 153.6-25.6 34.133333-38.4 64-34.133333 89.6 4.266667 25.6 17.066667 46.933333 34.133333 64 17.066667 12.8 38.4 21.333333 64 25.6 46.933333 8.533333 106.666667 0 183.466667-4.266666 12.8 0 21.333333-4.266667 34.133333-4.266667 123.733333-12.8 145.066667 8.533333 149.333333 12.8 0 0 4.266667 4.266667 0 12.8 0 0-4.266667 8.533333-21.333333 25.6l-51.2 51.2c-76.8 72.533333-128 123.733333-132.266667 166.4-4.266667 38.4 17.066667 76.8 68.266667 110.933333 21.333333 17.066667 42.666667 29.866667 64 38.4 8.533333 4.266667 21.333333 4.266667 34.133333 0 8.533333-8.533333 17.066667-17.066667 12.8-29.866666 0-12.8-8.533333-21.333333-17.066666-25.6z" fill="#333333" p-id="21451"></path>
                </svg>
                <div>点线</div>
              </Button>
              <Button className={classNames({ selected: "rect" === tool })} onClick={() => onToolChange("rect")}>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M864 896H160a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32h704a32 32 0 0 1 32 32v704a32 32 0 0 1-32 32zM192 832h640V192H192v640z" p-id="8412"></path>
                </svg>
                <div>矩形</div>
              </Button>
              <Button disabled className={classNames({ selected: "circle" === tool })} onClick={() => {
                onToolChange("circle");
            }}>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M512 0L0 391.133867 195.566933 1024H1024V391.133867L512 0z m443.733333 955.733333H246.289067L76.8 415.786667 512 93.866667l443.733333 338.986666V955.733333z" fill="#040000" p-id="19121"></path>
                </svg>
                <div>多边形</div>
              </Button>
            </Row>
          </section>,
            <section>
            <h3 style={{ textAlign: "center" }}>【选择模式】</h3>
            <Row justify="space-between" className="mode">
              <Button onClick={() => onModeChange("add")} className={classNames({ selected: mode === "add" })}>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M818.99186 450.601628 573.398372 450.601628 573.398372 205.00814 450.601628 205.00814 450.601628 450.601628 205.00814 450.601628 205.00814 573.398372 450.601628 573.398372 450.601628 818.99186 573.398372 818.99186 573.398372 573.398372 818.99186 573.398372Z" p-id="25294"></path>
                </svg>
                <div>增加点</div>
              </Button>
              <Button onClick={() => onModeChange("toggle")} className={classNames({ selected: mode === "toggle" })}>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M549.376 732.16v-179.2H755.2V443.392H549.376V265.216H427.52v178.176H221.696V552.96H427.52v179.2h121.856z m205.824 149.504v-109.568H221.696v109.568H755.2z" p-id="23170"></path>
                </svg>
                <div>反选点</div>
              </Button>
              <Button onClick={() => onModeChange("remove")} className={classNames({ selected: mode === "remove" })}>
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M64 412h896v200H64z" p-id="27004"></path>
                </svg>
                <div>减去点</div>
              </Button>
            </Row>
          </section>
        ]}
        <section>
          <h3 style={{ textAlign: "center" }}>【着色模式】</h3>
          <Row justify="space-between" className="mode">
            <Select style={{ width: "100%" }} value={shaderMode} onChange={v => onShaderModeChange(v)}>
              <Select.Option key={"gray"}>灰度</Select.Option>
              <Select.Option key={"intensity"}>强度</Select.Option>
              <Select.Option key={"height"} disabled={true}>
                高度
              </Select.Option>
            </Select>
          </Row>
        </section>
        <section>
          <h3 style={{ textAlign: "center" }}>【投影】</h3>
          <Row justify="space-between" align="middle">
            <div style={{ textAlign: "center", width: 120 }}>
              <Slider value={toJS(imageLabelStore.projectionRange)} style={{ margin: 0 }} max={10} min={0} step={0.1} onChange={v => {
            imageLabelStore.projectionRange = v;
            imageLabelStore === null || imageLabelStore === void 0 ? void 0 : imageLabelStore.editor3DRef.updateCloudProjection();
        }} range></Slider>
            </div>
            <Switch checked={imageLabelStore.projection} onChange={v => {
            imageLabelStore.projection = v;
            imageLabelStore === null || imageLabelStore === void 0 ? void 0 : imageLabelStore.editor3DRef.updateCloudProjection();
        }}></Switch>
            <Button size="small" onClick={() => {
            imageLabelStore.editor3DRef.image2DRef.current.toggleScreen();
        }}>
              全屏
            </Button>
          </Row>
        </section>
        {hasSegment3D && (<section>
            <h3 style={{ textAlign: "center" }}>【继承】</h3>
            <Row justify="space-between" align="middle">
              <div style={{ textAlign: "center", width: 120 }}>
                <Slider value={toJS(imageLabelStore.inheritThreshold)} style={{ margin: 0 }} max={1} min={0} step={0.1} onChange={v => {
            imageLabelStore.inheritThreshold = v;
        }}></Slider>
              </div>
            </Row>
          </section>)}
        <div>提示信息：请在俯视角度进行标注</div>
      </div>);
    }
};
Tool3D = __decorate([
    observer
], Tool3D);
export default Tool3D;
