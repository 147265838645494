var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Card, PageHeader, Tabs } from "antd";
import { CreatedSandBags } from "./component/CreatedSandBags/CreatedSandBags";
import { SharedSandBags } from "./component/SharedSandBags/SharedSandBags";
import { TotalSandBags } from "./component/TotalSandBags/TotalSandBags";
import { routerStore } from "store/routerStore";
import { parseLocationSearch } from "util/parse-location-search";
import { labelSandBagsStore } from "./LabelSandBagsStore";
import { observer } from "mobx-react";
import { appStore } from "store/AppStore";
let LabelSandBags = class LabelSandBags extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            activeKey: null
        };
    }
    componentDidMount() {
        const { activeKey } = parseLocationSearch(this.props.location.search);
        if (activeKey) {
            labelSandBagsStore.status.activeKey = activeKey;
        }
        else if (!labelSandBagsStore.status.activeKey) {
            labelSandBagsStore.status.activeKey = "createdSandBags";
        }
    }
    componentWillUnmount() {
        labelSandBagsStore.resetStatus();
    }
    render() {
        const status = labelSandBagsStore.status;
        return (<PageHeader title="沙子库管理" style={{ background: "#FFF" }}>
        <Card>
          <Tabs type="card" activeKey={status.activeKey} onChange={activeKey => {
            status.activeKey = activeKey;
            routerStore.replace(`?activeKey=${activeKey}`);
        }}>
            <Tabs.TabPane tab="我的沙子包" key="createdSandBags">
              <CreatedSandBags />
            </Tabs.TabPane>
            <Tabs.TabPane tab="分享沙子包" key="sharedSandBags">
              <SharedSandBags />
            </Tabs.TabPane>
            {appStore.status.loginUser.isSystemAdmin && (<Tabs.TabPane tab="全部沙子包" key="totalSandBags">
                <TotalSandBags />
              </Tabs.TabPane>)}
          </Tabs>
        </Card>
      </PageHeader>);
    }
};
LabelSandBags = __decorate([
    observer
], LabelSandBags);
export { LabelSandBags };
