var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Button, Divider, Popconfirm, PageHeader, Input, Table, Card } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { taskBusinessesStore } from "./TaskBusinessesStore";
import { CreateTaskBusinessModal } from "./component/CreateTaskBusinessModal/CreateTaskBusinessModal";
import { ModifyTaskBusinessModal } from "./component/ModifyTaskBusinessModal/ModifyTaskBusinessModal";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
let TaskBusinesses = class TaskBusinesses extends React.Component {
    constructor() {
        super(...arguments);
        this.businessesColumns = [
            {
                title: "计价类型",
                dataIndex: "name"
            },
            {
                title: "计价单位",
                align: "center",
                render: (value, record) => {
                    switch (record.type) {
                        case LabelTaskBusiness.Type.Box:
                            return <span>个（检测框）</span>;
                        case LabelTaskBusiness.Type.Piece:
                            return <span>张（图片）</span>;
                        case LabelTaskBusiness.Type.Object:
                            return <span>个（关键帧）</span>;
                        case LabelTaskBusiness.Type.Chunk:
                            return <span>段（分类内容）</span>;
                        case LabelTaskBusiness.Type.Anchor:
                            return <span>个（时空锚对象）</span>;
                        case LabelTaskBusiness.Type.MaskBox:
                            return <span>个（分割框）</span>;
                        case LabelTaskBusiness.Type.Cloud:
                            return <span>个（点云）</span>;
                    }
                }
            },
            {
                title: "标准量(单位/天)",
                align: "center",
                dataIndex: "standardNum",
                render(value) {
                    return Array.isArray(value) ? value.join("|") : value;
                }
            },
            {
                title: "状态",
                width: "160px",
                align: "center",
                render: (value, record) => {
                    return record.isValid ? "启用中" : "已禁用";
                }
            },
            {
                title: "创建日期",
                width: "120px",
                align: "center",
                render: (value, record) => {
                    return <span>{moment(record.createdAt).format("YYYY-MM-DD")}</span>;
                }
            },
            {
                title: "操作",
                width: "240px",
                align: "center",
                render: (value, record) => {
                    return (<div>
            <Button type="link" size="small" onClick={() => {
                        taskBusinessesStore.status.showModifyModal = true;
                        taskBusinessesStore.status.modifyTaskBusiness = record;
                    }}>
              修改
            </Button>
            {!!record.isValid ? (<React.Fragment>
                <Divider type="vertical"/>
                <Popconfirm title="确认要禁用这个计价类型么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={() => {
                        taskBusinessesStore.status.modifyTaskBusiness = record;
                        taskBusinessesStore.modifyTaskBusiness({
                            name: record.name,
                            type: record.type,
                            isValid: 0,
                            standardNum: record.standardNum
                        });
                    }}>
                  <Button type="link" size="small">
                    禁用
                  </Button>
                </Popconfirm>
              </React.Fragment>) : (<React.Fragment>
                <Divider type="vertical"/>
                <Popconfirm title="确认要启用这个计价类型么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={() => {
                        taskBusinessesStore.status.modifyTaskBusiness = record;
                        taskBusinessesStore.modifyTaskBusiness({
                            name: record.name,
                            type: record.type,
                            isValid: 1,
                            standardNum: record.standardNum
                        });
                    }}>
                  <Button type="link" size="small">
                    启用
                  </Button>
                </Popconfirm>
              </React.Fragment>)}
            {!!record.isCanDel && (<React.Fragment>
                <Divider type="vertical"/>
                <Popconfirm title="确认要删除这个计价类型么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={() => {
                        taskBusinessesStore.deleteTaskBusiness(record.id);
                    }}>
                  <Button type="link" size="small">
                    删除
                  </Button>
                </Popconfirm>
              </React.Fragment>)}
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        taskBusinessesStore.loadData();
    }
    render() {
        const status = taskBusinessesStore.status;
        return (<PageHeader title="计价类型列表" style={{ background: "#FFFFFF" }}>
        <Card>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <Button type="primary" onClick={() => {
            status.showCreateModal = true;
        }}>
              新建
            </Button>
            <Input.Search style={{ width: "240px" }} value={status.nameFilter} enterButton="搜索" onSearch={() => {
            taskBusinessesStore.loadData();
        }} onChange={e => {
            status.nameFilter = e.target.value;
        }}></Input.Search>
          </div>
          <Table loading={status.isLoading} rowKey="id" bordered={true} style={{ marginTop: "20px" }} columns={this.businessesColumns} dataSource={toJS(status.taskBusinesses)} pagination={{ showSizeChanger: false }}></Table>

          {status.showCreateModal && (<CreateTaskBusinessModal onCancel={() => {
            status.showCreateModal = false;
        }} onOk={(name, type, standardNum) => {
            status.name = name;
            status.type = type;
            status.standardNum = standardNum;
            taskBusinessesStore.createTaskBusiness();
        }}/>)}

          {status.showModifyModal && (<ModifyTaskBusinessModal taskBusiness={status.modifyTaskBusiness} onCancel={() => {
            status.showModifyModal = false;
        }} onOk={(name, type, isValid, standardNum) => {
            taskBusinessesStore.modifyTaskBusiness({ name, type, isValid, standardNum });
        }}/>)}
        </Card>
      </PageHeader>);
    }
};
TaskBusinesses = __decorate([
    observer
], TaskBusinesses);
export { TaskBusinesses };
