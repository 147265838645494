var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import { action, computed, observable } from "mobx";
import { Api } from "../../../../api/Api";
import { LabelTaskTemplate } from "../../../../definition/entity/label-task-template";
import BasicStore from "../../../../store/BasicStore";
import { routerStore } from "../../../../store/routerStore";
class Status {
    constructor() {
        this.isLoading = true;
        this.taskTemplate = null;
        this.isEditorValid = false;
        this.isUpdating = false;
        this.fileName = "";
        this.file = null;
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof LabelTaskTemplate !== "undefined" && LabelTaskTemplate) === "function" ? _a : Object)
], Status.prototype, "taskTemplate", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isEditorValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isUpdating", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "fileName", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "file", void 0);
class TaskTemplateModifyStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData(taskTemplateId) {
        this.setStatus({
            isLoading: true
        });
        const taskTemplateRes = await Api.taskTemplate.getTaskTemplate(taskTemplateId);
        this.setStatus({
            isLoading: false,
            taskTemplate: taskTemplateRes.data
        });
    }
    async updateTaskTemplate(id, label) {
        const { taskTemplate: { name, remark, resourceMime, objectName, objectStatus, labelTaskType }, file } = this.status;
        this.status.isUpdating = true;
        let reqParams = new FormData();
        reqParams.append("name", name);
        reqParams.append("remark", remark);
        if (_.isNil(objectName)) {
            reqParams.append("objectStatus", `${objectStatus}`);
        }
        reqParams.append("resourceMime", `${resourceMime}`);
        reqParams.append("label", JSON.stringify(label));
        reqParams.append("labelTaskType", labelTaskType);
        if (file) {
            reqParams.append("labelDoc", file);
        }
        await Api.taskTemplate.updateTaskTemplate(id, reqParams);
        this.status.isUpdating = false;
        routerStore.goBack();
    }
    get taskTemplateLabel() {
        const { label: { detections = [], classifications = [], anchors = [], segments = [] } } = this.status.taskTemplate;
        return {
            detections: detections,
            classifications: classifications,
            anchors: anchors,
            segments
        };
    }
    get isEnableUpdate() {
        const { isEditorValid, taskTemplate: { name } } = this.status;
        return isEditorValid && name;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TaskTemplateModifyStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TaskTemplateModifyStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskTemplateModifyStore.prototype, "taskTemplateLabel", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskTemplateModifyStore.prototype, "isEnableUpdate", null);
export const taskTemplateModifyStore = new TaskTemplateModifyStore();
