export var IResource;
(function (IResource) {
    let Mime;
    (function (Mime) {
        Mime[Mime["Image"] = 0] = "Image";
        Mime[Mime["Video"] = 1] = "Video";
        Mime[Mime["Audio"] = 2] = "Audio";
        Mime[Mime["Text"] = 3] = "Text";
        Mime[Mime["Cloud"] = 4] = "Cloud";
    })(Mime = IResource.Mime || (IResource.Mime = {}));
    let CalType;
    (function (CalType) {
        CalType[CalType["Self"] = 0] = "Self";
        CalType[CalType["All"] = 1] = "All";
    })(CalType = IResource.CalType || (IResource.CalType = {}));
})(IResource || (IResource = {}));
