var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createRef } from "react";
import { Modal, Row, Col } from "antd";
export default class ComparisonDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.originImageRef = createRef();
        this.maskImageRef = createRef();
        this.originImageContainerRef = createRef();
        this.maskImageContainerRef = createRef();
        this._preventEvent = false;
        this.state = {
            zoom: 1,
            originImage: {
                width: null,
                height: null
            },
            maskImage: {
                width: null,
                height: null
            }
        };
        this.onWheel = (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
                const container = e.currentTarget;
                const deltaY = e.deltaY * -0.01;
                const prevZoom = this.state.zoom;
                const zoom = Math.max(this.state.zoom + deltaY, 1);
                const delta = zoom / prevZoom;
                let rect;
                if (container === this.originImageContainer) {
                    rect = this.originImageRef.current.getBoundingClientRect();
                }
                else {
                    rect = this.maskImageRef.current.getBoundingClientRect();
                }
                const left = e.pageX - rect.left;
                const top = e.pageY - rect.top;
                const newScrollLeft = container.scrollLeft + (delta - 1) * left;
                const newScrollTop = container.scrollTop + (delta - 1) * top;
                this.setState({ zoom }, () => {
                    container.scrollTo(newScrollLeft, newScrollTop);
                });
            }
        };
        this.onScroll = (e) => {
            if (e.currentTarget === this.originImageContainer) {
                if (this._preventEvent) {
                    this._preventEvent = false;
                    return;
                }
                this._preventEvent = true;
                this.maskImageContainer.scroll(this.originImageContainer.scrollLeft, this.originImageContainer.scrollTop);
            }
            else {
                if (this._preventEvent) {
                    this._preventEvent = false;
                    return;
                }
                this._preventEvent = true;
                this.originImageContainer.scroll(this.maskImageContainer.scrollLeft, this.maskImageContainer.scrollTop);
            }
        };
        this.onLoad = () => {
            this.initSize();
            const originImageContainer = this.originImageContainerRef.current;
            const maskImageContainer = this.maskImageContainerRef.current;
            originImageContainer.addEventListener("wheel", this.onWheel, { passive: false });
            originImageContainer.addEventListener("scroll", this.onScroll);
            maskImageContainer.addEventListener("wheel", this.onWheel, { passive: false });
            maskImageContainer.addEventListener("scroll", this.onScroll);
        };
    }
    get originImageContainer() {
        return this.originImageContainerRef.current;
    }
    get maskImageContainer() {
        return this.maskImageContainerRef.current;
    }
    initSize() {
        const originImageOffset = (this.originImageOffset = this.originImageRef.current.getBoundingClientRect());
        const maskImageOffset = (this.maskImageOffset = this.maskImageRef.current.getBoundingClientRect());
        this.setState({
            originImage: {
                width: originImageOffset.width,
                height: originImageOffset.height
            },
            maskImage: {
                width: maskImageOffset.width,
                height: maskImageOffset.height
            }
        });
    }
    render() {
        const _a = this.props, { aUrl, bUrl } = _a, rest = __rest(_a, ["aUrl", "bUrl"]);
        const { zoom, originImage, maskImage } = this.state;
        const extraOriginImageStyle = Object.assign({}, (originImage.width
            ? {
                width: zoom * originImage.width,
                height: zoom * originImage.height
            }
            : null));
        const extraMaskImageStyle = Object.assign({}, (maskImage.width ? { width: zoom * originImage.width, height: zoom * originImage.height } : null));
        return (<Modal width="90%" title="标注结果对比图" footer={null} {...rest} transitionName={null}>
        <div>
          <Row gutter={10}>
            <Col span={12}>
              <div ref={this.originImageContainerRef} style={{ overflow: "auto", width: originImage.width, height: originImage.height }}>
                <img onLoad={() => this.onLoad()} ref={this.originImageRef} src={aUrl} alt="" style={Object.assign({ width: "100%", display: "block" }, extraOriginImageStyle)}/>
              </div>
              <div>原图</div>
            </Col>
            <Col span={12}>
              <div ref={this.maskImageContainerRef} style={{ overflow: "auto", width: originImage.width, height: originImage.height }}>
                <img ref={this.maskImageRef} src={bUrl} alt="" style={Object.assign({ overflow: "auto", display: "block", backgroundColor: "#000" }, extraMaskImageStyle)}/>
              </div>
              <div>标注效果图</div>
            </Col>
          </Row>
        </div>
      </Modal>);
    }
}
