import React from "react";
import "./Ruler.less";
const Ruler = ({ x, y, text, color, width, height }) => {
    return (<div className="ruler">
      <div className="h-line" style={{ top: y, borderColor: color, width }}></div>
      <div className="v-line" style={{ left: x, borderColor: color, height }}></div>
      <span className="text" style={{ left: x + 5, top: y - 25, color }}>
        {text}
      </span>
    </div>);
};
export default Ruler;
