import * as React from "react";
import { Button, Card, Form, Input } from "antd";
import { Api } from "../../../api/Api";
import { routerStore } from "../../../store/routerStore";
import "./SignIn.less";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
export const SignIn = () => {
    const [form] = Form.useForm();
    return (<div className="sign-in-page">
      <Card>
        <h1>LabelX 账户登录</h1>
        <Form form={form}>
          <Form.Item name="name">
            <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }}/>} placeholder="输入账户名称"/>
          </Form.Item>
          <Form.Item name="password">
            <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }}/>} placeholder="输入账户密码" type="password"/>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} type="primary" onClick={async () => {
        const { name, password } = await form.getFieldsValue();
        const signInRes = await Api.user.signIn(name, password);
        if (signInRes.code === 200) {
            window.location.href = "/manage";
        }
    }}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <h3>其他登录方式</h3>
        <Button style={{ width: "100%" }} type="primary" onClick={async () => {
        const gitlabUrl = await Api.oauth.getGitLabOauthUrl();
        const redirectUrl = gitlabUrl.data +
            "?origin_url=" +
            encodeURIComponent(window.location.origin + "/api/oauth/gitlab/callback");
        window.location.href = redirectUrl;
    }}>
          GitLab 登录
        </Button>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <span>第一次访问？</span>
        <a onClick={() => {
        routerStore.push("/sign_up");
    }}>
          创建一个 LabelX 账户
        </a>
      </Card>
    </div>);
};
