import * as React from "react";
import * as _ from "lodash";
import { Modal, Form, Input, message, Select, InputNumber, Button } from "antd";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { PlusOutlined } from "@ant-design/icons";
export class CreateTaskBusinessModal extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: undefined,
            type: undefined,
            standardNum: [0]
        };
        this.onAdd = () => {
            this.state.standardNum.push(0);
            this.setState({
                standardNum: this.state.standardNum
            });
        };
    }
    render() {
        const { onCancel, onOk } = this.props;
        const countArr = Array.from(Array(this.state.standardNum.length));
        return (<Modal open={true} title="新建计价类型" onCancel={() => {
            onCancel();
        }} onOk={() => {
            var _a;
            const { name, type, standardNum } = this.state;
            if (!name || ((_a = name.match(/ /g)) === null || _a === void 0 ? void 0 : _a.length)) {
                return message.error("名称不能为空，不能包含空格");
            }
            if (_.isNil(type)) {
                return message.error("请选择类型");
            }
            onOk(name, type, standardNum);
        }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="计价类型名称">
            <Input value={this.state.name} placeholder="请输入名称" onChange={event => {
            this.setState({
                name: event.target.value
            });
        }}/>
          </Form.Item>
          <Form.Item label="计价单位">
            <Select value={this.state.type} placeholder="请选择计价单位" onChange={value => {
            this.setState({
                type: value
            });
        }}>
              <Select.Option value={LabelTaskBusiness.Type.Box}>个（检测框）</Select.Option>
              <Select.Option value={LabelTaskBusiness.Type.Piece}>张（图片）</Select.Option>
              <Select.Option value={LabelTaskBusiness.Type.MaskBox}>个（分割框）</Select.Option>
              <Select.Option value={LabelTaskBusiness.Type.Object}>个（关键帧）</Select.Option>
              <Select.Option value={LabelTaskBusiness.Type.Chunk}>段（分类内容）</Select.Option>
              <Select.Option value={LabelTaskBusiness.Type.Anchor}>个（时空锚对象）</Select.Option>
              <Select.Option value={LabelTaskBusiness.Type.Cloud}>个（点云）</Select.Option>
            </Select>
          </Form.Item>
          {countArr.map((v, i) => {
            return (<Form.Item label="标准量" key={i}>
                <InputNumber value={this.state.standardNum[i]} min={0} step={0.5} onChange={value => {
                this.state.standardNum[i] = value;
                this.setState({
                    standardNum: [...this.state.standardNum]
                });
            }}/>
              </Form.Item>);
        })}

          <Button onClick={() => this.onAdd()} icon={<PlusOutlined />}/>
        </Form>
      </Modal>);
    }
}
