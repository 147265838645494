var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "./global.less";
import * as Sentry from "@sentry/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import zhCN from "antd/es/locale/zh_CN";
import { routerStore } from "./store/routerStore";
import { Router, Route, Switch } from "react-router";
import { ManageLayout } from "./layout/ManageLayout/ManageLayout";
import { LabelLayout } from "./layout/LabelLayout/LabelLayout";
import { LabelTasks } from "./page/manage/label-task/LabelTasks/LabelTasks";
import { NotFound } from "./page/land/NotFound/NotFound";
import { SignUp } from "./page/land/SignUp/SignUp";
import { SignIn } from "./page/land/SignIn/SignIn";
import { LabelTaskCreate } from "./page/manage/label-task/LabelTaskCreate/LabelTaskCreate";
import { LabelTaskPackages } from "./page/manage/label-task/LabelTaskPackages/LabelTaskPackages";
import { TaskTemplates } from "./page/manage/label-template/TaskTemplates/TaskTemplates";
import { ImageLabel } from "./page/label/image/ImageLabel/ImageLabel";
import VideoLabel from "./page/label/video/VideoLabel/VideoLabel";
import { TaskTemplateCreate } from "./page/manage/label-template/TaskTemplateCreate/TaskTemplateCreate";
import { TaskTemplateModify } from "./page/manage/label-template/TaskTemplateModify/TaskTemplateModify";
import { ConfigProvider } from "antd";
import { LabelTaskModify } from "./page/manage/label-task/LabelTaskModify/LabelTaskModify";
import { LabelTaskClone } from "./page/manage/label-task/LabelTaskClone/LabelTaskClone";
import { ImageLabelMulti } from "./page/label/image/ImageLabelMulti/ImageLabelMulti";
import { Admin } from "./page/manage/admin/role-manage/RoleManage/Admin";
import { Landing } from "./page/land/Landing/Landing";
import { appStore } from "./store/AppStore";
import { observer } from "mobx-react";
import { Loading } from "./component/Loading/Loading";
import { LabelPackage } from "./page/manage/label-package/LabelPackage";
import { Account } from "./page/manage/account/Account";
import { DailyWork } from "./page/manage/daily-work/DailyWork";
import { ScheduleList } from "page/manage/daily-work/component/ScheduleList/ScheduleList";
import { TaskBusinesses } from "./page/manage/admin/task-business/TaskBusinesses/TaskBusinesses";
import { LabelSandPackage } from "./page/manage/label-sand-package/LabelSandPackage";
import { LabellingAssessmentPolicies } from "./page/manage/labelling-assessment-policy/LabellingAssessmentPolicies/LabellingAssessmentPolicies";
import { LabellingAssessmentPolicyCreate } from "./page/manage/labelling-assessment-policy/LabellingAssessmentPolicyCreate/LabellingAssessmentPolicyCreate";
import { LabellingAssessmentPolicyDetail } from "./page/manage/labelling-assessment-policy/LabellingAssessmentPolicyDetail/LabellingAssessmentPolicyDetail";
import { LabelSandBags } from "./page/manage/label-sand-bags/LabelSandBags/LabelSandBags";
import { LabelSandBagsCreate } from "./page/manage/label-sand-bags/LabelSandBagsCreate/LabelSandBagsCreate";
import { DailyAudit } from "./page/manage/admin/audit-manage/DailyAudit/DailyAudit";
import { CompositeAudit } from "./page/manage/admin/audit-manage/CompositeAudit/CompositeAudit";
import { PerformanceAudit } from "./page/manage/admin/audit-manage/PerformanceAudit/PerformanceAudit";
import { RankAudit } from "./page/manage/admin/audit-manage/RankAudit/RankAudit";
import { initApi } from "api/Api";
import NoPermission from "component/NoPermission";
let App = class App extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
            error: null
        };
    }
    async componentDidMount() {
        appStore.init();
        this.showVersionNumber();
    }
    componentDidCatch(error, errorInfo) {
        if (error || errorInfo) {
            this.setState({
                hasError: true,
                error: error
            });
        }
    }
    renderManageRoutes() {
        var _a, _b;
        const status = appStore.status;
        const isGroupAdmin = (_a = status.loginUser) === null || _a === void 0 ? void 0 : _a.isGroupAdmin;
        const isSystemAdmin = (_b = status.loginUser) === null || _b === void 0 ? void 0 : _b.isSystemAdmin;
        return [
            { path: "/manage/label_packages", component: LabelPackage },
            { path: "/manage/daily_works", component: DailyWork },
            { path: "/manage/daily_work/:fillDate/schedule", component: ScheduleList },
            isGroupAdmin && { path: "/manage/label_tasks", component: LabelTasks },
            isGroupAdmin && { path: "/manage/label_task/create", component: LabelTaskCreate },
            isGroupAdmin && { path: "/manage/label_task/:labelTaskId/modify", component: LabelTaskModify },
            isGroupAdmin && { path: "/manage/label_task/:labelTaskId/clone", component: LabelTaskClone },
            isGroupAdmin && { path: "/manage/label_task/:labelTaskId/packages", component: LabelTaskPackages },
            isGroupAdmin && { path: "/manage/label_sand_packages", component: LabelSandPackage },
            isGroupAdmin && { path: "/manage/labelling_assessment_policies", component: LabellingAssessmentPolicies },
            isGroupAdmin && {
                path: "/manage/labelling_assessment_policy/create",
                component: LabellingAssessmentPolicyCreate
            },
            isGroupAdmin && {
                path: "/manage/labelling_assessment_policy/:policyId/detail",
                component: LabellingAssessmentPolicyDetail
            },
            isGroupAdmin && { path: "/manage/task_templates", component: TaskTemplates },
            isGroupAdmin && { path: "/manage/task_template/create", component: TaskTemplateCreate },
            isGroupAdmin && { path: "/manage/task_template/:taskTemplateId/modify", component: TaskTemplateModify },
            isGroupAdmin && { path: "/manage/label_sand_bags", component: LabelSandBags },
            isGroupAdmin && { path: "/manage/label_sand_bag/create", component: LabelSandBagsCreate },
            { path: "/manage/account", component: Account },
            isSystemAdmin && { path: "/manage/admin/role_manage", component: Admin },
            isSystemAdmin && { path: "/manage/admin/task_businesses", component: TaskBusinesses },
            isSystemAdmin && { path: "/manage/admin/daily_audit", component: DailyAudit },
            isSystemAdmin && { path: "/manage/admin/composite_audit", component: CompositeAudit },
            isSystemAdmin && { path: "/manage/admin/performance_audit", component: PerformanceAudit },
            isSystemAdmin && { path: "/manage/admin/rank_audit", component: RankAudit }
        ]
            .filter(i => i)
            .map((routerProps, index) => <Route key={index} {...routerProps}/>);
    }
    showVersionNumber() {
        var _a;
        const script = document.body.getElementsByTagName("script");
        if (script.length > 0) {
            const matchRes = ((_a = script[0]) === null || _a === void 0 ? void 0 : _a.src.match(new RegExp(/js\/app\.(.+).js$/))) || [];
            console.log(`%cVersion: ${matchRes[1]}`, "color: #5bc5b1; font-size: 14px; padding: 4px 6px;");
        }
    }
    render() {
        const status = appStore.status;
        if (this.state.hasError) {
            return (<pre>
          <pre>{this.state.error.message}</pre>
          <pre>{this.state.error.stack}</pre>
        </pre>);
        }
        if (status.isLoading) {
            return <Loading />;
        }
        return (<Router history={routerStore}>
        <Switch>
          <Route path="/" exact={true} strict={true} component={Landing}/>
          <Route path="/no-permission" exact={true} strict={true} component={NoPermission}/>
          <Route path="/manage">
            <ManageLayout>
              <Switch>
                {this.renderManageRoutes()}
                <Route path="/manage/*" component={NotFound}/>
              </Switch>
            </ManageLayout>
          </Route>

          <Route path="/label">
            <LabelLayout>
              <Switch>
                <Route path="/label/image/label_task/:labelTaskId/label_package/:labelPackageId" component={ImageLabel}/>
                <Route path="/label/image_multi/label_task/:labelTaskId/label_package/:labelPackageId" component={ImageLabelMulti}/>
                <Route path="/label/video/label_task/:labelTaskId/label_package/:labelPackageId" component={VideoLabel}/>
                <Route path="/label/image/label_task/:labelTaskId/label_sand_bags/:labelPackageId" component={ImageLabel}/>
                <Route path="/label/*" component={NotFound}/>
              </Switch>
            </LabelLayout>
          </Route>

          <Route path="/sign_up" component={SignUp}/>
          <Route path="/sign_in" component={SignIn}/>
          <Route path="*" component={NotFound}/>
        </Switch>
      </Router>);
    }
};
App = __decorate([
    observer
], App);
fetch("/config.json")
    .then(body => body.json())
    .then(data => {
    window.config = data;
    initApi(data.dataocean_grpc_endpoint);
    Sentry.init({
        dsn: "https://353be2682a9e4dd1898f285066ba1745@o4505162793811968.ingest.sentry.io/4505197898235904",
        integrations: [new Sentry.Replay()],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: data.env,
        release: getVersion()
    });
    ReactDOM.render(<ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>, document.getElementById("main-view-wrapper"));
});
function getVersion() {
    var _a;
    const script = document.body.getElementsByTagName("script");
    if (script.length > 0) {
        const matchRes = ((_a = script[0]) === null || _a === void 0 ? void 0 : _a.src.match(new RegExp(/js\/app\.(.+).js$/))) || [];
        return matchRes[1];
    }
}
