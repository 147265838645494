import * as _ from "lodash";
import moment from "moment";
export const formatTime = (t) => {
    if (_.isEmpty(t)) {
        return "-";
    }
    return moment(t).format("YYYY-MM-DD HH:mm:ss");
};
export const getWeek = (dateStr) => {
    let week = moment(dateStr).day();
    switch (week) {
        case 1:
            return "周一";
        case 2:
            return "周二";
        case 3:
            return "周三";
        case 4:
            return "周四";
        case 5:
            return "周五";
        case 6:
            return "周六";
        case 0:
            return "周日";
    }
};
export function showDuration(ss) {
    const time = moment.duration(ss);
    const h = Math.floor(time.asHours());
    const m = Math.floor(time.asMinutes()) % 60;
    const s = Math.floor(time.asSeconds()) % 60;
    return h >= 1 ? `${h}时${m}分${s}秒` : m >= 1 ? `${m}分${s}秒` : `${s}秒`;
}
