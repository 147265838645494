var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Modal } from "antd";
export default class KeyboardDialog extends React.Component {
    render() {
        const _a = this.props, { type } = _a, rest = __rest(_a, ["type"]);
        return (<Modal width={800} footer={null} {...rest}>
        {type === "segment2d" && (<table width="100%">
            <tr style={{ textAlign: "center" }}>
              <th>标注工具</th>
              <th>快捷键</th>
              <th>作用范围</th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>显示所有元素</td>
              <td>s</td>
              <td>作用在画板层级上，处于非编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>隐藏所有元素</td>
              <td>h</td>
              <td>作用在画板层级上，处于非编辑模式下</td>
            </tr>
            
            <tr style={{ textAlign: "center" }}>
              <td>删除标注点</td>
              <td>Shift + Delete</td>
              <td>作用在选中对象的层级上</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>删除标注对象</td>
              <td>Delete</td>
              <td>作用在选中对象的层级上</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>撤销点</td>
              <td>w</td>
              <td>作用于画板层级上，处于可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>共边模式</td>
              <td>q</td>
              <td>作用于当前选中的标注点上，可编辑和不可编辑模式都可</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>切换共边</td>
              <td>v</td>
              <td>作用于共边模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>选中多个元素</td>
              <td>Shift + 左键</td>
              <td>作用于画板层级上，处于不可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>关联组合/取消关联组合</td>
              <td>Shift + g</td>
              <td>作用于当前选中的多个（二个或二个以上）标注对象，处于不可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>取消当前绘制</td>
              <td>Esc</td>
              <td>作用于当前标注对象上，处于可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>向上微调</td>
              <td>↑</td>
              <td>作用在当前选中的像素点的层级上，处于可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>向下微调</td>
              <td>↓</td>
              <td>作用在当前选中的像素点的层级上，处于可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>向左微调</td>
              <td>←</td>
              <td>作用在当前选中的像素点的层级上，处于可编辑模式下</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>向右微调</td>
              <td>→</td>
              <td>作用在当前选中的像素点的层级上，处于可编辑模式下</td>
            </tr>

            <tr style={{ textAlign: "center" }}>
              <td>图像尺寸恢复</td>
              <td>Control + x</td>
              <td></td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>撤销操作</td>
              <td>Control + z</td>
              <td>
                作用于标注操作，<span style={{ color: "red" }}>缩放操作后可能会导致外层分割狂漂移！</span>
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>取消撤销操作</td>
              <td>Shift + Control + z</td>
              <td>作用于所有操作</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>切换上一个/下一个对象</td>
              <td>j/k</td>
              <td>作用于同一个标签下的所有对象</td>
            </tr>
          </table>)}
        {type === "segment3d" && (<table width="100%">
            <tr style={{ textAlign: "center" }}>
              <th>标注工具</th>
              <th>快捷键</th>
              <th>作用范围</th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>移动点云</td>
              <td>shift/command/control + 鼠标右键</td>
              <td>作用在画板层级上</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td>选择点云</td>
              <td>鼠标左键</td>
              <td>作用在画板层级上</td>
            </tr>
          </table>)}
      </Modal>);
    }
}
