export var ISand;
(function (ISand) {
    let Mime;
    (function (Mime) {
        Mime[Mime["Image"] = 0] = "Image";
        Mime[Mime["Video"] = 1] = "Video";
        Mime[Mime["Audio"] = 2] = "Audio";
        Mime[Mime["Text"] = 3] = "Text";
    })(Mime = ISand.Mime || (ISand.Mime = {}));
    let CalType;
    (function (CalType) {
        CalType[CalType["Self"] = 0] = "Self";
        CalType[CalType["All"] = 1] = "All";
    })(CalType = ISand.CalType || (ISand.CalType = {}));
})(ISand || (ISand = {}));
