import { AnchorTool } from "../../../../../../../../definition/entity/label-task";
import { imageLabelStore } from "../../../../ImageLabelStore";
import { PainterDom } from "../../../../provider/painter-dom";
import { BasePainter } from "../../painter/base-painter";
export class DotPainter extends BasePainter {
    Draw_MouseDown(event) {
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel } = status;
        if (!(curLabel.tool === AnchorTool.Dot && curLabel.type === "anchor"))
            return;
        const id = PainterDom.generateId(curLabel.key, 2);
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        status.layerElements.push({
            id,
            label: curLabel,
            visible: true,
            attributes: {
                cx: offsetX,
                cy: offsetY
            },
            detection: {
                key: curLabel.key,
                value: null,
                tool: AnchorTool.Dot,
                trails: [
                    {
                        cx: offsetX / status.zoomRatio,
                        cy: offsetY / status.zoomRatio,
                        frame: imageLabelStore.currentFrame,
                        keyFrame: 0
                    }
                ]
            },
            display: "normal"
        });
    }
    Move_MouseMove(event) {
        if (!this.isValidSelectOneEle(AnchorTool.Dot, "anchor"))
            return;
        const { selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        const { x, y } = element.initAttribute;
        const { movementX, movementY } = PainterDom.getDotMovementOfPainterBGWithLimit({
            movementX: offsetX - x,
            movementY: offsetY - y
        }, {
            cx: x,
            cy: y
        });
        Object.assign(element.attributes, {
            cx: x + movementX,
            cy: y + movementY
        });
    }
    Move_MouseUp() {
        if (!this.isValidSelectOneEle(AnchorTool.Dot, "anchor"))
            return;
        const { currentFrame, selectedElements, status } = imageLabelStore;
        const element = selectedElements[0];
        if (element) {
            const attributes = element.attributes;
            const targetPath = element.detection.trails.filter(v => v.frame === currentFrame)[0];
            if (targetPath) {
                targetPath.cx = attributes.cx / status.zoomRatio;
                targetPath.cy = attributes.cy / status.zoomRatio;
            }
            else {
                element.detection.trails.push({
                    cx: attributes.cx / status.zoomRatio,
                    cy: attributes.cy / status.zoomRatio,
                    frame: currentFrame
                });
                element.detection.trails = element.detection.trails.sort((a, b) => a.frame - b.frame);
            }
        }
    }
}
export const dotPainter = new DotPainter();
