import React, { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./timeline.less";
export const TimelineContext = createContext(null);
function format(num) {
    const h = Math.floor(num / (1000 * 60 * 60));
    const m = Math.floor((num / 1000 - h * 60 * 60) / 60);
    const s = Math.floor((num / 1000) % 60);
    const ss = Math.floor(num % 1000);
    return `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}:${String(s).padStart(2, "0")}:${String(ss).padStart(2, "0")}`;
}
const Timeline = props => {
    const { type = "horizontal", zoom = 1, width: widthProps, height: heightProps, unit = 50, unitValue = 120, direction = "start", style = { width: "100%", height: "100%" }, backgroundColor = "#333333", textColor = "#777777", lineColor = "#777777", value: valueProps = 0, scrollPos: scrollPosProps, onChange = () => { }, end, _helper, sample } = props;
    const [valueState, setValueState] = useState(0);
    const [scrollPosState, setScrollPosState] = useState(0);
    const [offset, setOffset] = useState(0);
    const canvasElement = useRef();
    const timeRuleElement = useRef();
    const timelineElement = useRef();
    const timelineWrapElement = useRef();
    const savedEvent = useRef({
        startX: 0,
        offset: 0
    });
    const isValuePropsUndefined = valueProps === undefined;
    const transformValueToOffset = useCallback(value => {
        return ((value / unitValue) * unit - scrollPosState) * zoom;
    }, [unitValue, unit, scrollPosState, zoom]);
    const transformOffsetToValue = useCallback(offset => {
        return ((offset + scrollPosState * zoom) / zoom / unit) * unitValue;
    }, [scrollPosState, zoom, unit, unitValue]);
    const getValue = useCallback(() => {
        return !isValuePropsUndefined ? valueProps : valueState;
    }, [isValuePropsUndefined, valueProps, valueState]);
    const setValue = useCallback((value, offset = transformValueToOffset(value)) => {
        if (value < 0) {
            value = 0;
        }
        if (isValuePropsUndefined) {
            setValueState(value);
            setOffset(offset);
        }
        onChange(value, offset);
    }, [isValuePropsUndefined, transformValueToOffset]);
    const cacheOffset = offset => {
        savedEvent.current.offset = offset;
    };
    useEffect(() => {
        resize();
        return () => {
            removeSeekLineEvents();
        };
    }, []);
    useLayoutEffect(() => {
        draw();
    }, [unitValue, unit, scrollPosState, zoom, end]);
    useLayoutEffect(() => {
        resize();
    }, [widthProps, heightProps]);
    useLayoutEffect(() => {
        const value = getValue();
        const offset = transformValueToOffset(value);
        setOffset(offset);
        if (typeof _helper === "function") {
            _helper(value, offset);
        }
    }, [unitValue, unit, zoom, scrollPosState, valueState, valueProps]);
    useLayoutEffect(() => {
        const computedOffset = transformValueToOffset(valueProps);
        const bounds = timelineElement.current.getBoundingClientRect();
        const maxOffset = Math.max(savedEvent.current.offset, bounds.width * 0.7);
        const delta = (computedOffset - maxOffset) / zoom;
        let scrollPos = scrollPosState + delta;
        if (scrollPos < 0) {
            scrollPos = 0;
        }
        setScrollPosState(scrollPos);
    }, [zoom, savedEvent.current]);
    function addSeekLineEvents() {
        window.addEventListener("mousemove", onSeekLineMouseMove);
        window.addEventListener("mouseup", onSeekLineMouseUp);
    }
    function removeSeekLineEvents() {
        window.removeEventListener("mousemove", onSeekLineMouseMove);
        window.removeEventListener("mouseup", onSeekLineMouseUp);
    }
    const onSeekLineMouseDown = (e) => {
        savedEvent.current.startX = e.pageX;
        cacheOffset(transformValueToOffset(getValue()));
        removeSeekLineEvents();
        addSeekLineEvents();
    };
    const onSeekLineMouseMove = (e) => {
        const { startX, offset: startOffset } = savedEvent.current;
        const bounds = timelineElement.current.getBoundingClientRect();
        const currentX = Math.max(bounds.left, Math.min(bounds.right, e.pageX));
        let offset = startOffset + currentX - startX;
        let value = ((offset + scrollPosState * zoom) / zoom / unit) * unitValue;
        setValue(value, offset);
    };
    const onSeekLineMouseUp = (e) => {
        const { startX, offset: startOffset } = savedEvent.current;
        const bounds = timelineElement.current.getBoundingClientRect();
        const currentX = Math.max(bounds.left, Math.min(bounds.right, e.pageX));
        let offset = startOffset + currentX - startX;
        let value = ((offset + scrollPosState * zoom) / zoom / unit) * unitValue;
        setValue(value, offset);
        cacheOffset(offset);
        removeSeekLineEvents();
    };
    const onTimeRuleMouseDown = (e) => {
        const current = e.pageX;
        const start = timeRuleElement.current.getBoundingClientRect().left;
        const delta = current - start;
        const value = ((delta + scrollPosState * zoom) / zoom / unit) * unitValue;
        const offset = transformValueToOffset(value);
        cacheOffset(offset);
        setValue(value, offset);
    };
    function resize() {
        const canvas = canvasElement.current;
        canvas.width = (widthProps || canvas.offsetWidth) * 2;
        canvas.height = (heightProps || canvas.offsetHeight) * 2;
        draw();
    }
    function draw() {
        const canvas = canvasElement.current;
        const context = canvas.getContext("2d");
        const width = widthProps || canvas.offsetWidth;
        const height = heightProps || canvas.offsetHeight;
        const scrollPos = scrollPosProps !== undefined ? scrollPosProps : scrollPosState;
        const isHorizontal = type === "horizontal";
        const isDirectionStart = direction === "start";
        if (backgroundColor === "transparent") {
            context.clearRect(0, 0, width * 2, height * 2);
        }
        else {
            context.rect(0, 0, width * 2, height * 2);
            context.fillStyle = backgroundColor;
            context.fill();
        }
        context.save();
        context.scale(2, 2);
        context.strokeStyle = lineColor;
        context.lineWidth = 1;
        context.font = "10px sans-serif";
        context.fillStyle = textColor;
        if (isDirectionStart) {
            context.textBaseline = "top";
        }
        context.translate(0.5, 0);
        context.beginPath();
        const size = isHorizontal ? width : height;
        const zoomUnit = zoom * unit;
        const minRange = Math.floor((scrollPos * zoom) / zoomUnit);
        const maxRange = Math.ceil((scrollPos * zoom + size) / zoomUnit);
        const length = maxRange - minRange;
        for (let i = 0; i < length; ++i) {
            const startPos = ((i + minRange) * unit - scrollPos) * zoom;
            if (startPos >= -zoomUnit && startPos < size) {
                const [startX, startY] = isHorizontal
                    ? [startPos + 3, isDirectionStart ? 17 : height - 17]
                    : [isDirectionStart ? 17 : width - 17, startPos - 4];
                let text = format((i + minRange) * unitValue);
                if (isHorizontal) {
                    context.fillText(text, startX, startY);
                }
                else {
                    context.save();
                    context.translate(startX, startY);
                    context.rotate(-Math.PI / 2);
                    context.fillText(text, 0, 0);
                    context.restore();
                }
            }
            for (let j = 0; j < 10; ++j) {
                const pos = startPos + (j / 10) * zoomUnit;
                if (pos < 0 || pos >= size) {
                    continue;
                }
                const lineSize = j === 0 ? (isHorizontal ? height / 2 : width / 2) : j % 5 === 0 ? 10 : 7;
                const [x1, y1] = isHorizontal
                    ? [pos, isDirectionStart ? 0 : height - lineSize]
                    : [isDirectionStart ? 0 : width - lineSize, pos];
                const [x2, y2] = isHorizontal ? [x1, height - lineSize] : [x1 + lineSize, y1];
                context.moveTo(x1, height);
                context.lineTo(x2, y2);
            }
        }
        context.stroke();
        if (end != null) {
            context.beginPath();
            const lineWidth = 2;
            const endPos = ((end / unitValue) * unit - scrollPos) * zoom;
            context.strokeStyle = "red";
            context.lineWidth = lineWidth;
            context.beginPath();
            context.moveTo(endPos, 0);
            context.lineTo(endPos, height);
            context.stroke();
        }
        if (sample) {
            context.fillStyle = "rgba(102,51,153,0.5)";
            sample.forEach(sample => {
                const left = (((sample.start * 1000) / unitValue) * unit - scrollPos) * zoom;
                const right = (((sample.end * 1000) / unitValue) * unit - scrollPos) * zoom;
                context.rect(left, 0, right - left, height);
            });
            context.fill();
        }
        context.restore();
    }
    const providerValue = useMemo(() => {
        return {
            transformOffsetToValue,
            transformValueToOffset,
            setValue,
            end,
            width: widthProps
        };
    }, [transformOffsetToValue, transformValueToOffset, setValue, end, widthProps]);
    return (<div className="timeline" ref={timelineElement}>
      <div className="time-rule-wrap" ref={timelineWrapElement}>
        <div className="seek-line" style={{ transform: `translateX(${offset}px)` }}>
          
          <div className="head" onMouseDown={onSeekLineMouseDown}></div>
          <div className="line"></div>
        </div>
        <div className="time-rule" ref={timeRuleElement} onMouseDown={onTimeRuleMouseDown} style={{ width: widthProps, height: heightProps }}>
          <canvas style={style} ref={canvasElement}/>
          <div></div>
        </div>
        <TimelineContext.Provider value={providerValue}>{props.children}</TimelineContext.Provider>
      </div>
    </div>);
};
export default Timeline;
