var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AnchorTool } from "definition/entity/label-task";
import { observer } from "mobx-react";
import { PainterMode } from "page/label/image/interface/common";
import React from "react";
import { imageLabelStore } from "../../../ImageLabelStore";
import { DotAnchor } from "./DotAnchor";
import * as _ from "lodash";
import { PainterDom } from "../../../provider/painter-dom";
let AnchorElement = class AnchorElement extends React.Component {
    constructor() {
        super(...arguments);
        this.handleOnMouseDown = event => {
            var _a;
            const status = imageLabelStore.status;
            if (status.mode === PainterMode.DRAW)
                return;
            (_a = document.getElementById(this.props.element.id)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        };
        this.handleOnContextMenu = event => {
            return;
            const status = imageLabelStore.status;
            if (!_.isEmpty(status.creatingElement))
                return;
            const element = this.props.element;
            const { offsetX, offsetY } = PainterDom.getOffsetOfWrapper(event);
            imageLabelStore.status.selectedElementIds = [element.id];
            imageLabelStore.status.spriteContextmenu = {
                visible: true,
                element: element,
                left: offsetX,
                top: offsetY
            };
        };
    }
    render() {
        return (<g onMouseDown={this.handleOnMouseDown} onContextMenu={this.handleOnContextMenu}>
        {this.props.element.label.tool === AnchorTool.Dot && <DotAnchor element={this.props.element}/>}
      </g>);
    }
};
AnchorElement = __decorate([
    observer
], AnchorElement);
export { AnchorElement };
