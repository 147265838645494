var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { sharedSandBagsStore } from "./SharedSandBagsStore";
import { Button, Divider, Table, Popconfirm } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
let SharedSandBags = class SharedSandBags extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "名称",
                dataIndex: "id",
                ellipsis: true,
                render: (value, record) => {
                    return <div>{record.name}</div>;
                }
            },
            {
                title: "沙子数量",
                width: 120,
                dataIndex: "sandCount"
            },
            {
                title: "分享者",
                width: 240,
                render: (value, record) => {
                    var _a;
                    return <span>{(_a = record.user) === null || _a === void 0 ? void 0 : _a.nickname}</span>;
                }
            },
            {
                title: "分享日期",
                align: "center",
                width: 160,
                render: (value, record) => {
                    return moment(record.sharedAt).format("YYYY-MM-DD");
                }
            },
            {
                title: "操作",
                key: "action",
                align: "center",
                width: 240,
                render: (value, record) => {
                    return (<div>
            <Popconfirm title="确认要接受这个分享的沙子包么？" onConfirm={async () => {
                        await sharedSandBagsStore.acceptLabelSandBag(record.id);
                    }}>
              <Button size="small" type="link">
                接受
              </Button>
            </Popconfirm>
            <Divider type="vertical"/>
            <Popconfirm title="确认要拒绝这个分享的沙子包么？" onConfirm={async () => {
                        await sharedSandBagsStore.refuseLabelSandBag(record.id);
                    }}>
              <Button size="small" type="link">
                拒绝
              </Button>
            </Popconfirm>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        sharedSandBagsStore.loadData();
    }
    componentWillUnmount() {
        sharedSandBagsStore.resetStatus();
    }
    render() {
        const status = sharedSandBagsStore.status;
        return (<div>
        <Table style={{ marginTop: "20px" }} bordered={true} rowKey={record => "" + record.id} dataSource={toJS(status.sharedSandBags)} columns={this.columns} pagination={{ showSizeChanger: false }}/>
      </div>);
    }
};
SharedSandBags = __decorate([
    observer
], SharedSandBags);
export { SharedSandBags };
