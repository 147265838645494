import * as labelTask from "./label-task.api";
import * as segment from "./segment-api";
import { init as initDataocean } from "./dataocean";
let dataoceanApi = null;
export const Api = {
    user: require("./user.api"),
    labelTask,
    taskTemplate: require("./task-template.api"),
    membership: require("./membership.api"),
    admin: require("./admin.api"),
    oauth: require("./oauth-api"),
    notify: require("./notify.api"),
    taskBusiness: require("./task-business.api"),
    audit: require("./audit.api"),
    labellingAssessmentPolicy: require("./labelling-assessment-policy.api"),
    labelSandBags: require("./label-sand-bags.api"),
    dailyWork: require("./daily-work.api"),
    segment,
    dataocean: dataoceanApi
};
export function initApi(endpoint) {
    Api.dataocean = initDataocean(endpoint, {});
}
