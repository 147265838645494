import * as React from "react";
import moment from "moment";
import { ILabelPackage } from "../../../../../../definition/entity/label-package";
import { Button, Divider, message, Popconfirm, Modal, Timeline, Tag } from "antd";
import { AssignModal } from "../AssignModal/AssignModal";
import { ILabelPackageAssignment } from "../../../../../../definition/entity/label-package-assignment";
import { Api } from "../../../../../../api/Api";
var Type = ILabelPackageAssignment.Type;
import { ILabelTask } from "definition/entity/label-task";
function is2dSegment(label) {
    var _a;
    return (_a = label === null || label === void 0 ? void 0 : label.segments) === null || _a === void 0 ? void 0 : _a.length;
}
export class TaskPackageAction extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isAssignVisible: false,
            assignType: undefined,
            isAddToSand: false,
            assessLogList: [],
            isAssessLogModalVisible: false,
            visible2: false,
            details: []
        };
        this.assignPackage = async (type, labelTaskId, labelPackageId, userId) => {
            await Api.labelTask.assignPackage(labelTaskId, labelPackageId, {
                userId: userId,
                type
            });
            this.setState({
                isAssignVisible: false,
                assignType: undefined
            });
            this.props.callback();
        };
        this.revokePackage = () => { };
        this.rejectPackage = async (type, labelTaskId, labelPackageId) => {
            await Api.labelTask.rejectPackage(labelTaskId, labelPackageId, {
                type
            });
            this.props.callback();
        };
        this.archivePackage = async (labelTaskId, labelPackageId) => {
            await Api.labelTask.archivePackage(labelTaskId, labelPackageId);
            this.props.callback();
        };
        this.turnToSand = async (labelTaskId, labelPackageId) => {
            await Api.labelTask.updateLabelPackage(labelTaskId, labelPackageId, {
                isSand: true
            });
            this.props.callback();
        };
        this.addToSand = async (labelPackageId) => {
            this.setState({
                isAddToSand: true
            });
            const addToSandRes = await Api.labelSandBags.createLabelSandBagByArchived({ packageId: labelPackageId });
            if (addToSandRes.code === 200) {
                this.props.callback();
            }
            this.setState({
                isAddToSand: false
            });
        };
        this.revertTurnToSand = async (labelTaskId, labelPackageId) => {
            await Api.labelTask.updateLabelPackage(labelTaskId, labelPackageId, {
                isSand: false
            });
            this.props.callback();
        };
        this.renderInner = () => {
            const { record } = this.props;
            const { status, taskId: labelTaskId, id: labelPackageId } = record;
            switch (status) {
                case ILabelPackage.Status.Unassigned: {
                    return (<React.Fragment>
            <Divider type="vertical"/>
            <Button size="small" type="link" onClick={() => {
                        this.setState({
                            isAssignVisible: true,
                            assignType: Type.Label
                        });
                    }}>
              分配标注
            </Button>
          </React.Fragment>);
                }
                case ILabelPackage.Status.Assigned: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.Labelling: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.Assessing: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.AssessFailed: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.AssessPassed: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.AssessError: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.Labeled: {
                    return (<React.Fragment>
            <Divider type="vertical"/>
            <Popconfirm title="确认要驳回标注提交申请么？" onConfirm={async () => {
                        await this.rejectPackage(Type.Label, labelTaskId, labelPackageId);
                    }}>
              <Button size="small" type="link">
                驳回标注
              </Button>
            </Popconfirm>
            <Divider type="vertical"/>
            <Button size="small" type="link" onClick={() => {
                        this.setState({
                            isAssignVisible: true,
                            assignType: Type.Review
                        });
                    }}>
              分配复审
            </Button>
            <Divider type="vertical"/>
            <Popconfirm title="确认要归档这个分包么？" onConfirm={async () => {
                        await this.archivePackage(labelTaskId, labelPackageId);
                    }}>
              <Button size="small" type="link">
                归档
              </Button>
            </Popconfirm>
            {this.renderAssessBtn(record)}
          </React.Fragment>);
                }
                case ILabelPackage.Status.AssignReview: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.Reviewing: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.Reviewed: {
                    return (<React.Fragment>
            <Divider type="vertical"/>
            <Popconfirm title="确认要驳回复审提交申请么？" onConfirm={async () => {
                        await this.rejectPackage(Type.Review, labelTaskId, labelPackageId);
                    }}>
              <Button size="small" type="link">
                驳回复审
              </Button>
            </Popconfirm>
            <Divider type="vertical"/>
            <Button size="small" type="link" onClick={() => {
                        this.setState({
                            isAssignVisible: true,
                            assignType: Type.FinalReview
                        });
                    }}>
              分配终审
            </Button>
            <Divider type="vertical"/>
            <Popconfirm title="确认要归档这个分包么？" onConfirm={async () => {
                        await this.archivePackage(labelTaskId, labelPackageId);
                    }}>
              <Button size="small" type="link">
                归档
              </Button>
            </Popconfirm>
            {this.renderAssessBtn(record)}
          </React.Fragment>);
                }
                case ILabelPackage.Status.AssignFinalReview: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.FinalReviewing: {
                    return this.renderAssessBtn(record);
                }
                case ILabelPackage.Status.FinalReviewed: {
                    return (<React.Fragment>
            <Divider type="vertical"/>
            <Popconfirm title="确认要驳回终审提交申请么？" onConfirm={async () => {
                        await this.rejectPackage(Type.FinalReview, labelTaskId, labelPackageId);
                    }}>
              <Button size="small" type="link">
                驳回终审
              </Button>
            </Popconfirm>
            <Divider type="vertical"/>
            <Popconfirm title="确认要归档这个分包么？" onConfirm={async () => {
                        await this.archivePackage(labelTaskId, labelPackageId);
                    }}>
              <Button size="small" type="link">
                归档
              </Button>
            </Popconfirm>
            {this.renderAssessBtn(record)}
          </React.Fragment>);
                }
                case ILabelPackage.Status.Archived:
                    return (<React.Fragment>
            <Divider type="vertical"/>
            <Popconfirm disabled={this.state.isAddToSand} trigger="click" title="确认要将这个包添加到沙子库么？" onConfirm={async () => {
                        await this.addToSand(labelPackageId);
                    }}>
              <Button size="small" type="link" disabled={this.state.isAddToSand}>
                添加到沙子库
              </Button>
            </Popconfirm>
            {this.renderAssessBtn(record)}
          </React.Fragment>);
                default:
                    break;
            }
        };
    }
    async getLabelPackageAssessLog(labelTaskId, labelPackageId) {
        const assessLog = await Api.labelTask.getLabelPackageAssessLog(labelTaskId, labelPackageId);
        if (assessLog.code === 200) {
            assessLog.data.forEach(v => {
                try {
                    v.log = JSON.stringify(JSON.parse(v.log), null, 2);
                }
                catch (e) { }
            });
            this.setState({
                assessLogList: assessLog.data
            });
        }
    }
    async getLabelPackageAssessDetail(labelTaskId, labelPackageId) {
        const { data, code } = await Api.labelTask.getLabelPackageAssessDetail(labelTaskId, labelPackageId);
        if (code === 200) {
            this.setState({
                details: data
            });
        }
    }
    renderAssessBtn(record) {
        const { taskId: labelTaskId, id: labelPackageId, type: labelPackageType, labelTask: { type, label } } = record;
        return ([ILabelTask.Type.NewMix, ILabelTask.Type.Extract].includes(type) &&
            labelPackageType === ILabelPackage.Type.Normal && (<React.Fragment>
          <Divider type="vertical"/>
          <Button size="small" type="link" onClick={async () => {
            await this.getLabelPackageAssessLog(labelTaskId, labelPackageId);
            this.setState({
                isAssessLogModalVisible: true
            });
        }}>
            评估日志
          </Button>
          {is2dSegment(label) ? (<React.Fragment>
              <Divider type="vertical"/>
              <Button size="small" type="link" onClick={async () => {
            await this.getLabelPackageAssessDetail(labelTaskId, labelPackageId);
            this.setState({
                visible2: true
            });
        }}>
                评估结果
              </Button>
            </React.Fragment>) : (undefined)}
        </React.Fragment>));
    }
    render() {
        const { record } = this.props;
        const { taskId: labelTaskId, id: labelPackageId } = record;
        const { isAssignVisible, assignType } = this.state;
        return (<React.Fragment>
        {this.renderInner()}
        {isAssignVisible && (<AssignModal isVisible={isAssignVisible} onCancel={() => {
            this.setState({
                isAssignVisible: false,
                assignType: undefined
            });
        }} onOk={async (userId) => {
            if (!userId) {
                message.warning("分配人员不能为空");
                return;
            }
            await this.assignPackage(assignType, labelTaskId, labelPackageId, userId);
        }}/>)}

        {this.state.isAssessLogModalVisible && (<Modal title="历史评估日志" width={800} bodyStyle={{
            height: "70vh",
            overflowY: "scroll"
        }} open={true} footer={null} maskClosable={false} onCancel={() => {
            this.setState({
                isAssessLogModalVisible: false
            });
        }}>
            <Timeline>
              {this.state.assessLogList.map((item, index) => (<Timeline.Item key={index}>
                  <Tag color="blue">{item.result}</Tag>&nbsp;
                  <span>{moment(item.createAt).format("YYYY 年 MM 月 DD 日 HH 时 mm 分")}</span>
                  <pre>{item.log || "无"}</pre>
                </Timeline.Item>))}
            </Timeline>
          </Modal>)}

        <Modal title="评估结果" width={800} bodyStyle={{
            height: "70vh",
            overflowY: "scroll"
        }} open={this.state.visible2} footer={null} maskClosable={false} onCancel={() => {
            this.setState({
                visible2: false
            });
        }}>
          <Timeline>
            {this.state.details.map((item, index) => (<Timeline.Item key={index}>
                
                <span>{moment(item.createdAt).format("YYYY 年 MM 月 DD 日 HH 时 mm 分")}</span>
                <pre>{JSON.stringify(item.details, null, 2) || "无"}</pre>
              </Timeline.Item>))}
          </Timeline>
        </Modal>
      </React.Fragment>);
    }
}
