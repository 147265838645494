var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { labelPackageStore } from "./LabelPackageStore";
import { Button, Card, Divider, Input, Modal, PageHeader, Table, Tag, Popconfirm, Timeline } from "antd";
import { toJS } from "mobx";
import { ILabelPackage } from "../../../definition/entity/label-package";
import { TransferModal } from "./component/TransferModal/TransferModal";
import { transformLabelPackageStatus } from "../../../util/status-transform";
import { ILabelPackageAssignment } from "../../../definition/entity/label-package-assignment";
import { OnceComponent } from "../../../component/OnceComponent/OnceComponent";
import { Api } from "api/Api";
import { appStore } from "store/AppStore";
var Type = ILabelPackageAssignment.Type;
import { ILabelTask } from "definition/entity/label-task";
import { IResource } from "definition/entity/resource";
let LabelPackage = class LabelPackage extends React.Component {
    constructor() {
        super(...arguments);
        this.ENABLEASSESSNUM = 1;
        this.columns = [
            {
                title: "名称",
                width: 360,
                ellipsis: true,
                dataIndex: "name",
                render: (value, record) => {
                    return <div>{record.name}</div>;
                }
            },
            {
                title: "状态",
                align: "center",
                width: 120,
                key: "status",
                render: (value, record) => {
                    return <div>{transformLabelPackageStatus(record)}</div>;
                }
            },
            {
                title: "人员",
                key: "assign",
                ellipsis: true,
                width: 360,
                render: (value, record) => {
                    return (<div>
            {record.assignments
                        .filter(item => item.isStatusLast && item.userId === appStore.status.loginUser.id)
                        .map(assign => {
                        var _a;
                        const labelMode = assign.type === ILabelPackageAssignment.Type.Label;
                        const reviewMode = assign.type === ILabelPackageAssignment.Type.Review;
                        const finalReviewMode = assign.type === ILabelPackageAssignment.Type.FinalReview;
                        return (<Tag key={assign.id} color={(labelMode && "#5bc5b1") ||
                            (reviewMode && "#87d068") ||
                            (finalReviewMode && "#40a9ff") ||
                            "#5bc5b1"}>
                    {labelMode && "标注 "}
                    {reviewMode && "复审 "}
                    {finalReviewMode && "终审"}
                    {(_a = assign.user) === null || _a === void 0 ? void 0 : _a.name}
                  </Tag>);
                    })}
          </div>);
                }
            },
            {
                title: "标注文档",
                width: 120,
                ellipsis: true,
                render: (value, record) => {
                    return (record.taskTemplate && (<a onClick={() => {
                        labelPackageStore.previewTaskTemplateDocument(record.taskTemplate.id);
                    }}>
              {record.taskTemplate.objectName ? record.taskTemplate.objectName : ""}
            </a>));
                }
            },
            {
                title: "操作",
                key: "action",
                align: "center",
                fixed: "right",
                width: 420,
                render: (value, record) => {
                    const curLabelPending = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Pending).length;
                    const curLabelRunning = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Running).length;
                    const curLabelAssessing = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Assessed).length;
                    const curLabelFinished = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label &&
                        item.userId === appStore.status.loginUser.id &&
                        item.status === ILabelPackageAssignment.Status.Finished).length;
                    const curReviewPending = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Review &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Pending).length;
                    const curReviewRunning = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Review &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Running).length;
                    const curReviewFinished = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Review && item.status === ILabelPackageAssignment.Status.Finished).length;
                    const curFinalReviewPending = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.FinalReview &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Pending).length;
                    const curFinalReviewRunning = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.FinalReview &&
                        item.userId === appStore.status.loginUser.id &&
                        item.isStatusLast &&
                        item.status === ILabelPackageAssignment.Status.Running).length;
                    const curFinalReviewFinished = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.FinalReview &&
                        item.status === ILabelPackageAssignment.Status.Finished).length;
                    switch (record.status) {
                        case ILabelPackage.Status.Unassigned:
                            break;
                        case ILabelPackage.Status.Assigned:
                            if (curLabelPending > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    const startRes = await Api.labelTask.startPackage(record.taskId, record.id);
                                    if (startRes.code === 200) {
                                        labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                    }
                                }}>
                    {curLabelFinished > 0 ? "修改" : "开始标注"}
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.status.curPackage = record;
                                    labelPackageStore.status.isTransferModalVisible = true;
                                }}>
                    转包
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.Labelling:
                            if (curLabelRunning > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    if (record.labelTask.resourceMime === IResource.Mime.Image) {
                                        labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                    }
                                    else if (record.labelTask.resourceMime === IResource.Mime.Video) {
                                        labelPackageStore.routerToVideoLabel(record.taskId, record.id);
                                    }
                                    else if (record.labelTask.resourceMime === IResource.Mime.Cloud) {
                                        labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                    }
                                }}>
                    进入标注
                  </Button>
                  <Divider type="vertical"/>
                  {(record.labelTask.type === ILabelTask.Type.Normal || record.type == ILabelPackage.Type.Extract) && (<Button size="small" type="link" onClick={() => {
                                    let remark = undefined;
                                    const once = new OnceComponent();
                                    once.show(<Modal open={true} title="提交确认" onCancel={() => once.destroy()} onOk={async () => {
                                        await labelPackageStore.submitPackage({
                                            labelTaskId: record.taskId,
                                            labelPackageId: record.id,
                                            curStatus: record.status,
                                            remark: remark
                                        });
                                        once.destroy();
                                    }}>
                            <h3>提交后将无法继续标注，确认要提交么？</h3>
                            <br />
                            <Input placeholder="备注说明（选填）" onChange={event => {
                                        remark = event.target.value;
                                    }}/>
                          </Modal>);
                                }}>
                      提交
                    </Button>)}
                  {record.type === ILabelPackage.Type.Normal &&
                                    (record.labelTask.type === ILabelTask.Type.NewMix ||
                                        record.labelTask.type === ILabelTask.Type.Extract) && (<React.Fragment>
                        <Popconfirm disabled={record.labelProcess < this.ENABLEASSESSNUM} title="确认要评估标注么？" onConfirm={async () => {
                                    const assessRes = await Api.labelTask.assessPackage(record.taskId, record.id, {
                                        taskType: record.labelTask.type
                                    });
                                    if (assessRes.code === 200) {
                                        await labelPackageStore.loadData();
                                    }
                                }}>
                          <Button disabled={record.labelProcess < this.ENABLEASSESSNUM} size="small" type="link">
                            评估
                          </Button>
                        </Popconfirm>
                        <Divider type="vertical"/>
                        <Button size="small" type="link" onClick={async () => {
                                    await labelPackageStore.getLabelPackageAssessLog(record.taskId, record.id);
                                    labelPackageStore.status.isAssessLogModalVisible = true;
                                }}>
                          评估日志
                        </Button>
                      </React.Fragment>)}
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.AssessFailed:
                            if (curLabelPending > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    const startRes = await Api.labelTask.startPackage(record.taskId, record.id);
                                    if (startRes.code === 200) {
                                        labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                    }
                                }}>
                    修改
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    await labelPackageStore.getLabelPackageAssessLog(record.taskId, record.id);
                                    labelPackageStore.status.isAssessLogModalVisible = true;
                                }}>
                    评估日志
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.AssessError:
                            if (curLabelRunning > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                }}>
                    进入标注
                  </Button>
                  <Divider type="vertical"/>
                  <Popconfirm disabled={record.labelProcess < this.ENABLEASSESSNUM} title="确认要评估标注么？" onConfirm={async () => {
                                    const assessRes = await Api.labelTask.assessPackage(record.taskId, record.id, {
                                        taskType: record.labelTask.type
                                    });
                                    if (assessRes.code === 200) {
                                        await labelPackageStore.loadData();
                                    }
                                }}>
                    <Button disabled={record.labelProcess < this.ENABLEASSESSNUM} size="small" type="link">
                      重新评估
                    </Button>
                  </Popconfirm>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    await labelPackageStore.getLabelPackageAssessLog(record.taskId, record.id);
                                    labelPackageStore.status.isAssessLogModalVisible = true;
                                }}>
                    评估日志
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.Assessing:
                            break;
                        case ILabelPackage.Status.AssessPassed:
                            if (curLabelAssessing > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    let remark = undefined;
                                    const once = new OnceComponent();
                                    once.show(<Modal open={true} title="提交确认" onCancel={() => once.destroy()} onOk={async () => {
                                        await labelPackageStore.submitPackage({
                                            labelTaskId: record.taskId,
                                            labelPackageId: record.id,
                                            curStatus: record.status,
                                            remark: remark
                                        });
                                        once.destroy();
                                    }}>
                          <h3>提交后将无法继续标注，确认要提交么？</h3>
                          <br />
                          <Input placeholder="备注说明（选填）" onChange={event => {
                                        remark = event.target.value;
                                    }}/>
                        </Modal>);
                                }}>
                    提交
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    await labelPackageStore.getLabelPackageAssessLog(record.taskId, record.id);
                                    labelPackageStore.status.isAssessLogModalVisible = true;
                                }}>
                    评估日志
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.Labeled:
                            break;
                        case ILabelPackage.Status.AssignReview:
                            if (curReviewPending > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    const startRes = await Api.labelTask.startPackage(record.taskId, record.id);
                                    if (startRes.code === 200) {
                                        labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                    }
                                }}>
                    开始复审
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.status.curPackage = record;
                                    labelPackageStore.status.isTransferModalVisible = true;
                                }}>
                    转包
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.Reviewing:
                            if (curReviewRunning > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                }}>
                    进入复审
                  </Button>
                  <Divider type="vertical"/>
                  <Popconfirm title="确认要驳回标注提交申请么？" onConfirm={async () => {
                                    const rejectRes = await Api.labelTask.rejectPackage(record.taskId, record.id, {
                                        type: Type.Label
                                    });
                                    if (rejectRes.code === 200) {
                                        labelPackageStore.loadData();
                                    }
                                }}>
                    <Button size="small" type="link">
                      驳回标注
                    </Button>
                  </Popconfirm>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    let remark = undefined;
                                    const once = new OnceComponent();
                                    once.show(<Modal open={true} title="提交确认" onCancel={() => once.destroy()} onOk={async () => {
                                        await labelPackageStore.submitPackage({
                                            labelTaskId: record.taskId,
                                            labelPackageId: record.id,
                                            curStatus: record.status,
                                            remark: remark
                                        });
                                        once.destroy();
                                    }}>
                          <h3>提交后将无法继续标注，确认要提交么？</h3>
                          <br />
                          <Input placeholder="备注说明（选填）" onChange={event => {
                                        remark = event.target.value;
                                    }}/>
                        </Modal>);
                                }}>
                    提交
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.Reviewed:
                            break;
                        case ILabelPackage.Status.AssignFinalReview:
                            if (curFinalReviewPending > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={async () => {
                                    const startRes = await Api.labelTask.startPackage(record.taskId, record.id);
                                    if (startRes.code === 200) {
                                        labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                    }
                                }}>
                    开始终审
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.status.curPackage = record;
                                    labelPackageStore.status.isTransferModalVisible = true;
                                }}>
                    转包
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.FinalReviewing:
                            if (curFinalReviewRunning > 0) {
                                return (<React.Fragment>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id, { readonly: true });
                                }}>
                    查看
                  </Button>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    labelPackageStore.routerToImageLabel(record.taskId, record.id);
                                }}>
                    进入终审
                  </Button>
                  <Divider type="vertical"/>
                  <Popconfirm title="确认要驳回复审提交申请么？" onConfirm={async () => {
                                    const rejectRes = await Api.labelTask.rejectPackage(record.taskId, record.id, {
                                        type: Type.Review
                                    });
                                    if (rejectRes.code === 200) {
                                        labelPackageStore.loadData();
                                    }
                                }}>
                    <Button size="small" type="link">
                      驳回复审
                    </Button>
                  </Popconfirm>
                  <Divider type="vertical"/>
                  <Button size="small" type="link" onClick={() => {
                                    let remark = undefined;
                                    const once = new OnceComponent();
                                    once.show(<Modal open={true} title="提交确认" onCancel={() => once.destroy()} onOk={async () => {
                                        await labelPackageStore.submitPackage({
                                            labelTaskId: record.taskId,
                                            labelPackageId: record.id,
                                            curStatus: record.status,
                                            remark: remark
                                        });
                                        once.destroy();
                                    }}>
                          <h3>提交后将无法继续标注，确认要提交么？</h3>
                          <br />
                          <Input placeholder="备注说明（选填）" onChange={event => {
                                        remark = event.target.value;
                                    }}/>
                        </Modal>);
                                }}>
                    提交
                  </Button>
                </React.Fragment>);
                            }
                            break;
                        case ILabelPackage.Status.FinalReviewed:
                            break;
                        case ILabelPackage.Status.Archived:
                            break;
                        default:
                            break;
                    }
                }
            }
        ];
    }
    componentDidMount() {
        labelPackageStore.loadData();
    }
    render() {
        const status = labelPackageStore.status;
        return (<PageHeader title="分包标注列表" style={{ background: "#FFF" }}>
        <Card>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        }}>
            <Input.Search style={{ width: "240px" }} value={status.nameFilter} enterButton="搜索" onSearch={value => {
            labelPackageStore.loadData();
        }} onChange={event => {
            status.nameFilter = event.target.value;
        }}/>
          </div>
          <Table rowKey={(record) => String(record.id)} bordered={true} loading={status.isLoading} style={{ marginTop: "20px" }} scroll={{ x: "100%" }} dataSource={toJS(status.packages)} columns={this.columns} pagination={{
            current: status.pager.getCurPage(),
            total: status.pager.getTotalCount(),
            showSizeChanger: false,
            onChange: (page, pageSize) => {
                labelPackageStore.setStatus({
                    pager: status.pager.setCurPage(page).setPerPage(pageSize)
                });
                labelPackageStore.loadData();
            }
        }}/>
        </Card>

        <TransferModal isVisible={status.isTransferModalVisible} onCancel={() => (status.isTransferModalVisible = false)} onOk={(toUserId) => {
            labelPackageStore.transferPackage(toUserId);
        }}/>

        {status.isAssessLogModalVisible && (<Modal title="历史评估日志" width={800} bodyStyle={{
            height: "70vh",
            overflowY: "scroll"
        }} open={true} footer={null} maskClosable={false} onCancel={() => {
            status.isAssessLogModalVisible = false;
        }}>
            <Timeline>
              {status.assessLogList.map((item, index) => (<Timeline.Item key={index}>
                  <Tag color="blue">{item.result}</Tag>&nbsp;
                  <span>{moment(item.createAt).format("YYYY 年 MM 月 DD 日 HH 时 mm 分")}</span>
                  <pre>{item.log || "无"}</pre>
                </Timeline.Item>))}
            </Timeline>
          </Modal>)}
      </PageHeader>);
    }
};
LabelPackage = __decorate([
    observer
], LabelPackage);
export { LabelPackage };
