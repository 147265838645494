var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Button, Modal, Table, Form, Input, Popconfirm, Divider, Switch } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { groupManageStore } from "./GroupManageStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
let GroupManage = class GroupManage extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            { title: "单位名称", dataIndex: "name" },
            {
                title: "标注管理单位",
                align: "center",
                dataIndex: "admin",
                render: (value, record) => {
                    return <div>{value === 1 ? "是" : "否"}</div>;
                }
            },
            {
                title: "操作",
                align: "center",
                render: (value, record) => {
                    return (<div>
            <Button type="link" size="small" onClick={() => {
                        groupManageStore.status.isModifyModalVisible = true;
                        groupManageStore.status.curModifyGroup = {
                            id: record.id,
                            name: record.name,
                            admin: record.admin === 1
                        };
                    }}>
              修改
            </Button>
            <Divider type="vertical"/>
            <Popconfirm title="确认要删除这个单位么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={() => {
                        groupManageStore.deleteGroup(record.id);
                    }}>
              <Button type="link" size="small">
                删除
              </Button>
            </Popconfirm>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        groupManageStore.loadData();
    }
    render() {
        var _a, _b;
        const status = groupManageStore.status;
        return (<div>
        <Button type="primary" onClick={() => {
            status.isCreateModalVisible = true;
        }}>
          新建单位
        </Button>
        <Table rowKey="id" bordered={true} style={{ marginTop: "20px" }} dataSource={toJS(status.groups)} columns={this.columns} pagination={{ showSizeChanger: false }}/>

        <Modal open={status.isCreateModalVisible} title="新建单位" onOk={() => {
            groupManageStore.createGroup();
        }} onCancel={() => {
            status.groupName = null;
            status.isCreateModalVisible = false;
        }}>
          <Form.Item label="单位名称" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <Input value={status.groupName} onChange={event => {
            status.groupName = event.target.value;
        }}/>
          </Form.Item>
        </Modal>

        <Modal open={status.isModifyModalVisible} title="编辑单位" onOk={() => {
            groupManageStore.updateGroup();
        }} onCancel={() => {
            status.isModifyModalVisible = false;
            status.curModifyGroup = null;
        }}>
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="名称">
              <Input value={(_a = status.curModifyGroup) === null || _a === void 0 ? void 0 : _a.name} onChange={event => {
            status.curModifyGroup.name = event.target.value;
            groupManageStore.setStatus({
                curModifyGroup: status.curModifyGroup
            });
        }}/>
            </Form.Item>
            <Form.Item label="标注管理单位">
              <Switch checked={(_b = status.curModifyGroup) === null || _b === void 0 ? void 0 : _b.admin} onChange={checked => {
            status.curModifyGroup.admin = checked;
            groupManageStore.setStatus({
                curModifyGroup: status.curModifyGroup
            });
        }}/>
            </Form.Item>
          </Form>
        </Modal>
      </div>);
    }
};
GroupManage = __decorate([
    observer
], GroupManage);
export { GroupManage };
