export var ILabelPackage;
(function (ILabelPackage) {
    let Status;
    (function (Status) {
        Status[Status["Unassigned"] = 1] = "Unassigned";
        Status[Status["Assigned"] = 7] = "Assigned";
        Status[Status["Labelling"] = 2] = "Labelling";
        Status[Status["Labeled"] = 3] = "Labeled";
        Status[Status["AssessFailed"] = 9] = "AssessFailed";
        Status[Status["Assessing"] = 10] = "Assessing";
        Status[Status["AssessPassed"] = 11] = "AssessPassed";
        Status[Status["AssessError"] = 12] = "AssessError";
        Status[Status["AssignReview"] = 8] = "AssignReview";
        Status[Status["Reviewing"] = 4] = "Reviewing";
        Status[Status["Reviewed"] = 5] = "Reviewed";
        Status[Status["AssignFinalReview"] = 13] = "AssignFinalReview";
        Status[Status["FinalReviewing"] = 14] = "FinalReviewing";
        Status[Status["FinalReviewed"] = 15] = "FinalReviewed";
        Status[Status["Archived"] = 6] = "Archived";
        Status[Status["Slicing"] = 21] = "Slicing";
        Status[Status["Sliced"] = 22] = "Sliced";
        Status[Status["SliceError"] = 23] = "SliceError";
    })(Status = ILabelPackage.Status || (ILabelPackage.Status = {}));
    let Type;
    (function (Type) {
        Type[Type["Normal"] = 0] = "Normal";
        Type[Type["Mix"] = 1] = "Mix";
        Type[Type["Extract"] = 2] = "Extract";
    })(Type = ILabelPackage.Type || (ILabelPackage.Type = {}));
})(ILabelPackage || (ILabelPackage = {}));
