import * as React from "react";
import { routerStore } from "../../store/routerStore";
export class Link extends React.Component {
    render() {
        const { to, children } = this.props;
        return (<a href={to} onClick={event => {
            event.preventDefault();
            routerStore.push(to);
        }}>
        {children}
      </a>);
    }
}
