var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { Card, Collapse, Row, Button, Select } from "antd";
import { imageLabelStore } from "../../ImageLabelStore";
import { DetectionSelector } from "./DetectionSelector";
import "./LabelSelector.less";
import { ClassificationSelector } from "./ClassificationSelector";
import AnchorSelector from "./AnchorSelector";
import ImageFilters from "../PainterWrapper/ImageFilters";
import Tool3D from "../PainterWrapper/Tool3D";
import { Segment3DSelector } from "./Segment3DSelector";
import { Detection3DSelector } from "./Detection3DSelector";
import { ZoomOutOutlined, ZoomInOutlined } from "@ant-design/icons";
let LabelSelector = class LabelSelector extends React.Component {
    constructor() {
        super(...arguments);
        this.selectRef = React.createRef();
        this.state = {
            flag: false,
            zoomRate: 0.2,
            zoomRateOptions: [
                { label: "20%", value: 0.2 },
                { label: "40%", value: 0.4 },
                { label: "60%", value: 0.6 },
                { label: "80%", value: 0.8 },
                { label: "100%", value: 1 },
                { label: "200%", value: 2 },
                { label: "400%", value: 4 },
                { label: "600%", value: 6 },
                { label: "800%", value: 8 },
                { label: "1000%", value: 10 }
            ]
        };
        this.onKeyDown = (e) => {
            if (e.ctrlKey && e.key.toLowerCase() == "x") {
                if (this.state.flag) {
                    this.reset();
                }
                else {
                    this.scale(this.state.zoomRate);
                }
            }
        };
    }
    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
    }
    reset() {
        var _a;
        imageLabelStore.updateZoomRatio(imageLabelStore.status._zoomRatio);
        (_a = imageLabelStore.editor3DRef) === null || _a === void 0 ? void 0 : _a.mainCloud.moveCamera();
        this.setState({ flag: false });
    }
    scale(rate) {
        var _a;
        imageLabelStore.updateZoomRatio(imageLabelStore.status._zoomRatio * rate);
        (_a = imageLabelStore.editor3DRef) === null || _a === void 0 ? void 0 : _a.mainCloud.moveCamera();
        this.setState({ flag: true });
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        const status = imageLabelStore.status;
        const title = ((_b = (_a = status.labelInfo) === null || _a === void 0 ? void 0 : _a.labelTask) === null || _b === void 0 ? void 0 : _b.taskType) == 1 ? "切割工具" : "标注工具";
        const hasSegment2D = !!((_c = status.selectableLabel.segments) === null || _c === void 0 ? void 0 : _c.length);
        const hasSegment3D = !!((_d = status.selectableLabel.segments3D) === null || _d === void 0 ? void 0 : _d.length);
        const hasDetection3D = !!((_e = status.selectableLabel.detections3D) === null || _e === void 0 ? void 0 : _e.length);
        const is3D = !!((_f = status.selectableLabel.segments3D) === null || _f === void 0 ? void 0 : _f.length) || !!((_g = status.selectableLabel.detections3D) === null || _g === void 0 ? void 0 : _g.length);
        const { brightness, contrast } = status;
        const _row = (<Row align="middle" justify="space-between">
        {title}
        <Button style={{ padding: 0 }} onClick={() => this.reset()} type="link">
          复位
        </Button>
        <div>
          <Select ref={this.selectRef} value={this.state.zoomRate} onChange={v => {
            this.setState({ zoomRate: v });
            this.selectRef.current.blur();
        }} options={this.state.zoomRateOptions} listHeight={400}></Select>
          {this.state.flag ? (<ZoomInOutlined onClick={() => {
            this.reset();
        }}/>) : (<ZoomOutOutlined onClick={() => {
            this.scale(this.state.zoomRate);
        }}/>)}
        </div>
      </Row>);
        return (<div className="label-selector-component">
        <Card title={_row} bodyStyle={{ padding: "8px" }}>
          <Collapse className="label-selector-collapse" defaultActiveKey={["detection", "classification", "anchor"]}>
            {status.selectableLabel.detections.length > 0 && (<Collapse.Panel key="detection" header="检测标签">
                <div className="detections">
                  {status.selectableLabel.detections.map((label, index) => (<DetectionSelector key={label.key} idx={index} label={label}/>))}
                </div>
              </Collapse.Panel>)}
            {status.selectableLabel.classifications.length > 0 && (<Collapse.Panel key="classification" header="属性标签">
                <div className="classifications">
                  {status.selectableLabel.classifications.map(label => (<ClassificationSelector key={label.key} label={label}/>))}
                </div>
              </Collapse.Panel>)}
            {((_j = (_h = status.selectableLabel) === null || _h === void 0 ? void 0 : _h.anchors) === null || _j === void 0 ? void 0 : _j.length) > 0 && (<Collapse.Panel key="anchor" header="时空锚">
                <div className="anchors">
                  <AnchorSelector anchors={(_k = status.selectableLabel) === null || _k === void 0 ? void 0 : _k.anchors}></AnchorSelector>
                </div>
              </Collapse.Panel>)}
            {((_m = (_l = status.selectableLabel) === null || _l === void 0 ? void 0 : _l.segments) === null || _m === void 0 ? void 0 : _m.length) > 0 && (<Collapse.Panel key="detection" header="分割标签">
                <div className="detections">
                  {status.selectableLabel.segments.map((label, index) => (<DetectionSelector key={label.key} idx={index} label={label}/>))}
                </div>
              </Collapse.Panel>)}
            {((_p = (_o = status.selectableLabel) === null || _o === void 0 ? void 0 : _o.segments3D) === null || _p === void 0 ? void 0 : _p.length) > 0 && (<Collapse.Panel key="detection" header="分割标签">
                <div className="detections">
                  {status.selectableLabel.segments3D.map((label, index) => (<Segment3DSelector key={label.key} idx={index} label={label}/>))}
                </div>
              </Collapse.Panel>)}
            {((_r = (_q = status.selectableLabel) === null || _q === void 0 ? void 0 : _q.detections3D) === null || _r === void 0 ? void 0 : _r.length) > 0 && (<Collapse.Panel key="detection" header="3D检测标签">
                <div className="detections">
                  {status.selectableLabel.detections3D.map((label, index) => (<Detection3DSelector key={label.key} idx={index} label={label}/>))}
                </div>
              </Collapse.Panel>)}
          </Collapse>
        </Card>
        {hasSegment2D && (<ImageFilters brightness={brightness} contrast={contrast} alpha={(_s = imageLabelStore.selectedElements[0]) === null || _s === void 0 ? void 0 : _s.detection.alpha} onBrightnessChange={value => {
            imageLabelStore.status.brightness = value;
            imageLabelStore.painterWrapperRef.resize();
        }} onContrastChange={value => {
            imageLabelStore.status.contrast = value;
            imageLabelStore.painterWrapperRef.resize();
        }} onAlphaChange={value => {
            imageLabelStore.selectedElements.forEach(e => (e.detection.alpha = value));
            imageLabelStore.painterWrapperRef.rerender();
        }}></ImageFilters>)}
        {is3D && (<Tool3D tool={imageLabelStore.status.tool3D} mode={imageLabelStore.status.mode3D} shaderMode={imageLabelStore.status.shaderMode3D} viewType={imageLabelStore.status.viewType} pointSize={imageLabelStore.status.pointSize} onToolChange={tool => (imageLabelStore.status.tool3D = tool)} onModeChange={mode => (imageLabelStore.status.mode3D = mode)} onShaderModeChange={mode => {
            imageLabelStore.status.shaderMode3D = mode;
            imageLabelStore.editor3DRef.changeShaderMode(mode);
        }} onPointSizeChange={size => {
            imageLabelStore.status.pointSize = size;
            imageLabelStore.editor3DRef.changePointSize(size);
        }} onRotate={() => {
            imageLabelStore.editor3DRef.rotateGeometry(Math.PI / 2, 0, 0);
        }} onViewTypeChange={type => {
            var _a;
            imageLabelStore.status.viewType = type;
            (_a = imageLabelStore.editor3DRef) === null || _a === void 0 ? void 0 : _a.changeViewType(type);
        }} hasSegment3D={hasSegment3D} hasDetection3D={hasDetection3D}/>)}
      </div>);
    }
};
LabelSelector = __decorate([
    observer
], LabelSelector);
export { LabelSelector };
