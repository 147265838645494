import * as React from "react";
import { Form, Input, Modal } from "antd";
export const PasswordModify = props => {
    const { visible, onCancel, onOk } = props;
    const [form] = Form.useForm();
    return (<Modal open={visible} title="密码修改" onCancel={() => onCancel()} onOk={async () => {
        const values = await form.getFieldsValue();
        onOk(values.oldPwd, values.newPwd);
    }}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="原密码" name="oldPwd">
          <Input type="password"/>
        </Form.Item>
        <Form.Item label="新密码" name="newPwd">
          <Input type="password"/>
        </Form.Item>
      </Form>
    </Modal>);
};
