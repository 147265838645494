var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Select, Table, Button } from "antd";
import { comprehensiveAuditStore } from "./ComprehensiveAuditStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { transformLabelPackageStatus } from "util/status-transform";
import * as _ from "lodash";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
let ComprehensiveAudit = class ComprehensiveAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "用户名称",
                dataIndex: "username",
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.nickname}(${record === null || record === void 0 ? void 0 : record.username})`
            },
            {
                title: "当前分包总数",
                align: "center",
                dataIndex: "totalPackageCount"
            },
            {
                title: "标注中分包数量",
                align: "center",
                dataIndex: "totalLabelPackageCount"
            },
            {
                title: "修改中分包数量",
                align: "center",
                dataIndex: "totalModifyPackageCount"
            },
            {
                title: "复审中分包数量",
                align: "center",
                dataIndex: "totalReviewPackageCount"
            }
        ];
        this.childColumns = [
            {
                title: "分包名称",
                dataIndex: "packageName",
                width: 160,
                ellipsis: true
            },
            {
                title: "所属任务",
                width: 120,
                ellipsis: true,
                dataIndex: "taskName"
            },
            {
                title: "分包状态",
                width: 160,
                ellipsis: true,
                render(value, record) {
                    return transformLabelPackageStatus(record);
                }
            },
            {
                title: "分包标注进度",
                width: 120,
                ellipsis: true,
                render(value, record) {
                    return `${((record.labeledResourceLen / record.totalResourceLen) * 100).toFixed(2)}% (${record.labeledResourceLen}/${record.totalResourceLen})`;
                }
            },
            {
                title: "开始标注时间",
                align: "center",
                width: 120,
                render(value, record) {
                    var _a, _b;
                    const startLabelTime = (_a = record === null || record === void 0 ? void 0 : record.timeline) === null || _a === void 0 ? void 0 : _a.find(item => { var _a; return (_a = item[1]) === null || _a === void 0 ? void 0 : _a.includes("开始标注"); });
                    return startLabelTime ? (_b = moment(startLabelTime[0])) === null || _b === void 0 ? void 0 : _b.format("YYYY-MM-DD") : "-";
                }
            }
        ];
        this.csvHeaders = [
            {
                label: "用户名称",
                key: "username"
            },
            {
                label: "包名",
                key: "packageName"
            },
            {
                label: "状态",
                key: "packageStatus"
            },
            {
                label: "所属任务",
                key: "taskName"
            },
            {
                label: "分包标注进度",
                key: "labelProcess"
            },
            {
                label: "开始标注时间",
                key: "startLabelTime"
            }
        ];
        this.expandedRowRender = (record) => {
            const status = comprehensiveAuditStore.status;
            const childData = status.comprehensiveAudits.filter(item => record.userId === item.userId);
            let sortedPackages = [];
            if (childData.length > 0) {
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("标注中")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("修改中")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("复审中")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("评估不通过")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("评估失败")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("评估通过")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("已分配标注")));
                sortedPackages.push(...childData.filter(_package => transformLabelPackageStatus(_package).includes("已分配复审")));
            }
            sortedPackages = _.orderBy(sortedPackages, ["taskCreatedAt"], ["desc"]);
            return (<Table rowKey={record => "detail" + record.id} columns={this.childColumns} dataSource={sortedPackages} pagination={false}/>);
        };
    }
    componentDidMount() {
        comprehensiveAuditStore.loadGroups();
        comprehensiveAuditStore.loadUsers();
    }
    render() {
        const status = comprehensiveAuditStore.status;
        return (<div>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
          <div>
            <Select showSearch allowClear mode="multiple" placeholder="请选择单位" style={{ width: 240, marginLeft: 10 }} value={toJS(status.curGroupIds)} onChange={value => {
            status.curGroupIds = value;
            status.curUserIds = [];
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {status.groups.map(group => (<Select.Option key={group.id} title={group.name} value={group.id}>
                  {group.name}
                </Select.Option>))}
            </Select>
            <Select placeholder="请选择用户" showSearch mode="multiple" value={toJS(status.curUserIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curUserIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
              {toJS(comprehensiveAuditStore.filterUsers).map(user => (<Select.Option key={user.id} title={`${user.nickname}(${user.name})`} value={user.id}>
                  {`${user.nickname}(${user.name})`}
                </Select.Option>))}
            </Select>
            <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={comprehensiveAuditStore.isUnableSearch} onClick={() => {
            comprehensiveAuditStore.loadData();
        }}></Button>
          </div>

          <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename} headers={this.csvHeaders}>
            <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={comprehensiveAuditStore.isUnableDownload} onClick={() => comprehensiveAuditStore.downAnnotationAuditFile()}/>
          </CSVLink>
        </div>

        <Table loading={status.loading} scroll={{ x: true }} rowKey={record => "workload" + record.userId} bordered={true} style={{ marginTop: "20px" }} dataSource={toJS(comprehensiveAuditStore.transformedAnnotation)} columns={this.columns} expandedRowRender={this.expandedRowRender}/>
      </div>);
    }
};
ComprehensiveAudit = __decorate([
    observer
], ComprehensiveAudit);
export { ComprehensiveAudit };
