import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { Modal, Form, Input, message, Select, InputNumber, Radio, DatePicker } from "antd";
import { DailyWork } from "definition/entity/daily-work";
import { Api } from "api/Api";
export class ModifyScheduleModal extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            fillDate: undefined,
            workType: undefined,
            affair: undefined,
            taskId: undefined,
            duration: undefined,
            remark: undefined,
            tasks: []
        };
    }
    async componentDidMount() {
        const tasksRes = await Api.labelTask.listSimpleLabelTasks();
        const { fillDate, workType, taskId, duration, remark, affair } = this.props.schedule;
        this.setState({
            tasks: tasksRes.data,
            fillDate: moment(fillDate),
            affair,
            workType,
            taskId,
            duration,
            remark
        });
    }
    render() {
        const { onCancel, onOk } = this.props;
        return (<Modal title="修改日程" width="60vw" open={true} onCancel={() => {
            onCancel();
        }} onOk={() => {
            const { fillDate, workType, affair, duration, remark, taskId } = this.state;
            if (_.isNil(fillDate)) {
                return message.error("请选择日期");
            }
            if (_.isNil(workType)) {
                return message.error("请选择类型");
            }
            if (_.isNil(duration)) {
                return message.error("请输入时长");
            }
            if (workType === DailyWork.WorkType.Other) {
                if (!affair || affair.length > 20) {
                    return;
                }
            }
            else {
                if (_.isNil(taskId)) {
                    return message.error("请输入任务名称");
                }
            }
            onOk(fillDate.format("YYYY-MM-DD"), workType, affair, duration, remark, taskId);
        }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item label={<span className="label-required">日期</span>}>
            <DatePicker disabled value={this.state.fillDate}/>
          </Form.Item>
          <Form.Item label={<span className="label-required">工作类型</span>}>
            <Radio.Group value={this.state.workType} onChange={e => {
            this.setState({
                workType: e.target.value
            });
        }}>
              <Radio value={DailyWork.WorkType.Label}>标注</Radio>
              <Radio value={DailyWork.WorkType.Modify}>修改</Radio>
              <Radio value={DailyWork.WorkType.Review}>复审</Radio>
              <Radio value={DailyWork.WorkType.Other}>其它</Radio>
            </Radio.Group>
          </Form.Item>
          {this.state.workType === DailyWork.WorkType.Other ? (<Form.Item label={<span className="label-required">任务名称</span>} validateStatus={(!this.state.affair || this.state.affair.length > 20) && "error"} help={(!this.state.affair || this.state.affair.length > 20) && "请输入任务并且任务长度不超过 20 字符"}>
              <Input disabled placeholder="请输入任务" value={this.state.affair} onChange={event => {
            this.setState({
                affair: event.target.value
            });
        }}/>
            </Form.Item>) : (<Form.Item label={<span className="label-required">任务名称</span>}>
              <Select allowClear showSearch disabled optionFilterProp="children" value={this.state.taskId} placeholder="请选择任务" onChange={value => {
            this.setState({
                taskId: value
            });
        }}>
                {this.state.tasks.map(task => (<Select.Option value={task.id} key={task.id}>
                    {task.name}
                  </Select.Option>))}
              </Select>
            </Form.Item>)}
          <Form.Item label="说明">
            <Input placeholder="请输入工作说明" value={this.state.remark} onChange={event => {
            this.setState({
                remark: event.target.value
            });
        }}/>
          </Form.Item>
          <Form.Item label={<span className="label-required">工作时长</span>}>
            <InputNumber value={this.state.duration} min={0} step={0.1} onChange={value => {
            this.setState({
                duration: value
            });
        }}/>
            &nbsp;小时
          </Form.Item>
        </Form>
      </Modal>);
    }
}
