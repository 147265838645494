var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { imageLabelMultiStore as store } from "../../ImageLabelMultiStore";
import { Divider } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import "./LabelFooterStatus.less";
import { ClassificationTool } from "../../../../../../definition/entity/label-task";
let LabelFooterStatus = class LabelFooterStatus extends React.Component {
    classificationFilterStr() {
        const { curClassificationFilter, selectableLabel } = store.status;
        if (!curClassificationFilter) {
            return "ALL";
        }
        const classification = _.find(selectableLabel.classifications, item => item.key === curClassificationFilter.key);
        switch (classification.tool) {
            case ClassificationTool.Switch:
                return classification.locale || classification.key;
            default: {
                const option = _.find(classification.options, item => item.key === curClassificationFilter.value);
                return (classification.locale || classification.key) + " " + ((option === null || option === void 0 ? void 0 : option.locale) || (option === null || option === void 0 ? void 0 : option.key));
            }
        }
    }
    render() {
        const status = store.status;
        return (<div className="multi-label-footer-status-component">
        {status.isLoading ? (<div>
            <LoadingOutlined />
            &nbsp;<span>加载中</span>
          </div>) : (<React.Fragment>
            <div>
              <CheckCircleOutlined />
              &nbsp;<span>加载完毕</span>
            </div>
            <Divider type="vertical"/>
            <div>当前查看&nbsp;{this.classificationFilterStr()}</div>
            <Divider type="vertical"/>
            <div>每页&nbsp;{status.pager.getPerPage()}&nbsp;张</div>
            <Divider type="vertical"/>
            <div>总量&nbsp;{status.pager.getTotalCount()}&nbsp;张</div>
            <Divider type="vertical"/>
            <div>
              当前页码&nbsp;{status.pager.getCurPage()}&nbsp;/&nbsp;{status.pager.getTotalPage()}
            </div>
          </React.Fragment>)}
      </div>);
    }
};
LabelFooterStatus = __decorate([
    observer
], LabelFooterStatus);
export { LabelFooterStatus };
