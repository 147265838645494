export var PainterMode;
(function (PainterMode) {
    PainterMode["UNSET"] = "unset";
    PainterMode["MOVE"] = "move";
    PainterMode["RESIZE"] = "resize";
    PainterMode["DRAW"] = "draw";
})(PainterMode || (PainterMode = {}));
export var PainterMouseEvent;
(function (PainterMouseEvent) {
    PainterMouseEvent["MouseDown"] = "mousedown";
    PainterMouseEvent["MouseUp"] = "mouseup";
    PainterMouseEvent["MouseMove"] = "mousemove";
    PainterMouseEvent["ContextMenu"] = "contextmenu";
})(PainterMouseEvent || (PainterMouseEvent = {}));
export var ResizeMode;
(function (ResizeMode) {
    ResizeMode["UNSET"] = "unset";
    ResizeMode["RESIZE_NW"] = "resize_nw";
    ResizeMode["RESIZE_N"] = "resize_n";
    ResizeMode["RESIZE_NE"] = "resize_ne";
    ResizeMode["RESIZE_W"] = "resize_w";
    ResizeMode["RESIZE_E"] = "resize_e";
    ResizeMode["RESIZE_SW"] = "resize_sw";
    ResizeMode["RESIZE_S"] = "resize_s";
    ResizeMode["RESIZE_SE"] = "resize_se";
})(ResizeMode || (ResizeMode = {}));
export var MouseButton;
(function (MouseButton) {
    MouseButton[MouseButton["NONE"] = 0] = "NONE";
    MouseButton[MouseButton["LEFT"] = 1] = "LEFT";
    MouseButton[MouseButton["RIGHT"] = 2] = "RIGHT";
    MouseButton[MouseButton["LEFT_AND_RIGHT"] = 3] = "LEFT_AND_RIGHT";
    MouseButton[MouseButton["MIDDLE"] = 4] = "MIDDLE";
    MouseButton[MouseButton["FOUR"] = 8] = "FOUR";
    MouseButton[MouseButton["FIVE"] = 16] = "FIVE";
})(MouseButton || (MouseButton = {}));
export var KeyboardEventKey;
(function (KeyboardEventKey) {
    KeyboardEventKey["Delete"] = "Delete";
    KeyboardEventKey["Backspace"] = "Backspace";
    KeyboardEventKey["Escape"] = "Escape";
    KeyboardEventKey["ArrowLeft"] = "ArrowLeft";
    KeyboardEventKey["ArrowRight"] = "ArrowRight";
})(KeyboardEventKey || (KeyboardEventKey = {}));
