import { imageLabelStore } from "../ImageLabelStore";
import { DetectionTool } from "../../../../../definition/entity/label-task";
const getOffsetTopByBody = el => {
    let offsetTop = 0;
    while (el && el.tagName !== "BODY") {
        offsetTop += el.offsetTop;
        el = el.offsetParent;
    }
    return offsetTop;
};
const getOffsetLeftByBody = el => {
    let offsetLeft = 0;
    while (el && el.tagName !== "BODY") {
        offsetLeft += el.offsetLeft;
        el = el.offsetParent;
    }
    return offsetLeft;
};
export class PainterDom {
    static generateId(key, num = 1) {
        let result = "";
        if (this.idSeqMap.get(key)) {
            let value = this.idSeqMap.get(key);
            this.idSeqMap.set(key, value + 1);
            result = key + "_" + value;
        }
        else {
            this.idSeqMap.set(key, 1);
            result = this.generateId(key);
        }
        num--;
        if (num > 0) {
            return this.generateId(result, num);
        }
        return result;
    }
    static generateFeatureId() {
        return this.id++;
    }
    static initFeatureId(id = 0) {
        this.id = id;
    }
    static resetIdSeq() {
        this.idSeqMap = new Map();
    }
    static getOffsetOfWrapper(e) {
        const painterWrapper = imageLabelStore.painterWrapper.get()[0];
        const offsetX = imageLabelStore.svgCanvas.scrollLeft() + e.pageX - getOffsetLeftByBody(painterWrapper);
        const offsetY = imageLabelStore.svgCanvas.scrollTop() + e.pageY - getOffsetTopByBody(painterWrapper);
        return { offsetX: offsetX, offsetY: offsetY };
    }
    static adjustPainterSize() {
        var _a, _b, _c;
        const { workspace, painterWrapper, painterBackground, painterContent, status } = imageLabelStore;
        const { painterBGSize } = status.config;
        const { zoomRatio, curResource } = status;
        let adjustWrapperWidth = painterBGSize.width, adjustWrapperHeight = painterBGSize.height;
        if (((_b = (_a = curResource.annotation) === null || _a === void 0 ? void 0 : _a.detections) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            const widthList = [], negativeWidthList = [], negativeHeightList = [], heightList = [];
            curResource.annotation.detections.forEach(element => {
                var _a;
                if (element.tool === "box") {
                    (_a = element.value) === null || _a === void 0 ? void 0 : _a.forEach(point => {
                        if (point[0] < 0) {
                            negativeWidthList.push(point[0]);
                        }
                        else if (point[0] > painterBGSize.width) {
                            widthList.push(point[0] - painterBGSize.width);
                        }
                        if (point[1] < 0) {
                            negativeHeightList.push(point[1]);
                        }
                        else if (point[1] > painterBGSize.height) {
                            heightList.push(point[1] - painterBGSize.height);
                        }
                    });
                }
            });
            if (widthList.length > 0) {
                if (negativeWidthList.length > 0) {
                    adjustWrapperWidth += 2 * Math.max(Math.abs(Math.max(...widthList)), Math.abs(Math.min(...widthList)));
                }
                adjustWrapperWidth += 2 * Math.abs(Math.max(...widthList));
            }
            if (heightList.length > 0) {
                if (negativeHeightList.length > 0) {
                    adjustWrapperHeight += 2 * Math.max(Math.abs(Math.max(...heightList)), Math.abs(Math.min(...heightList)));
                }
                adjustWrapperHeight += 2 * Math.abs(Math.max(...heightList));
            }
        }
        painterWrapper.css("width", Math.max(workspace.width(), adjustWrapperWidth + 640) * zoomRatio);
        painterWrapper.css("height", Math.max(workspace.height(), adjustWrapperHeight + 240) * zoomRatio);
        painterBackground.attr("width", painterBGSize.width * zoomRatio);
        painterBackground.attr("height", painterBGSize.height * zoomRatio);
        painterBackground.attr("x", (painterWrapper.width() - +painterBackground.attr("width")) / 2);
        painterBackground.attr("y", (painterWrapper.height() - +painterBackground.attr("height")) / 2);
        painterContent.attr("x", (painterWrapper.width() - +painterBackground.attr("width")) / 2);
        painterContent.attr("y", (painterWrapper.height() - +painterBackground.attr("height")) / 2);
        (_c = imageLabelStore.painterWrapperRef) === null || _c === void 0 ? void 0 : _c.resize();
    }
    static adjustScroll(preZoomRatio, nowZoomRatio) {
        const { painterWrapper, svgCanvas } = imageLabelStore;
        const workspaceDiv = svgCanvas.get()[0];
        workspaceDiv &&
            workspaceDiv.scrollTo((painterWrapper.width() - svgCanvas.width()) / 2, (painterWrapper.height() - svgCanvas.height()) / 2);
        const svgCanvasEl = imageLabelStore.svgCanvas.get()[0];
        if (!svgCanvasEl)
            return;
        const $canvas = imageLabelStore.svgCanvas.find(".canvas");
        if ($canvas.length) {
            const $div = svgCanvas.children("div");
            svgCanvas.scrollLeft(($div.width() - svgCanvas.width()) / 2);
            svgCanvas.scrollTop(($div.height() - svgCanvas.height()) / 2);
        }
    }
    static adjustScrollWithWheelEvent(evt, preZoomRatio, nowZoomRatio) {
        const { offsetX, offsetY } = PainterDom.getOffsetOfWrapper(evt);
        const dPosition = {
            dx: (nowZoomRatio * offsetX) / preZoomRatio - offsetX,
            dy: (nowZoomRatio * offsetY) / preZoomRatio - offsetY
        };
        const svgCanvasEl = imageLabelStore.svgCanvas.get()[0];
        if (!svgCanvasEl)
            return;
        const left = svgCanvasEl.scrollLeft + dPosition.dx;
        const top = svgCanvasEl.scrollTop + dPosition.dy;
        const svgCanvas = imageLabelStore.svgCanvas;
        const $canvas = imageLabelStore.svgCanvas.find(".canvas");
        if ($canvas.length) {
            const $div = svgCanvas.children("div");
            const left = evt.pageX - $div.offset().left;
            const top = evt.pageY - $div.offset().top;
            const delta = nowZoomRatio / preZoomRatio;
            const newScrollLeft = svgCanvas.scrollLeft() + (delta - 1) * left;
            const newScrollTop = svgCanvas.scrollTop() + (delta - 1) * top;
            svgCanvas.scrollLeft(newScrollLeft);
            svgCanvas.scrollTop(newScrollTop);
        }
        else {
            svgCanvasEl.scrollTo({
                left,
                top
            });
        }
    }
    static hideRuler() {
        imageLabelStore.status.rulerStatus = {
            x: -1,
            y: -1,
            text: "",
            width: imageLabelStore.status.canvasWidth,
            height: imageLabelStore.status.canvasHeight
        };
    }
    static adjustRuler(e) {
        const { zoomRatio, creatingElement } = imageLabelStore.status;
        const { offsetX, offsetY } = this.getOffsetOfWrapper(e);
        const { offsetX: originOffsetX, offsetY: originOffsetY } = this.getOffsetOfPainterBG(e);
        let text;
        if (creatingElement && creatingElement.label.tool === DetectionTool.Rectangle) {
            const attributes = creatingElement.attributes;
            text = `${(attributes.width / zoomRatio).toFixed(0)} * ${(attributes.height / zoomRatio).toFixed(0)}`;
        }
        else {
            text = `(${(originOffsetX / zoomRatio).toFixed(0)}, ${(originOffsetY / zoomRatio).toFixed(0)})`;
        }
        imageLabelStore.status.rulerStatus = {
            x: offsetX,
            y: offsetY,
            text: text,
            width: imageLabelStore.status.canvasWidth,
            height: imageLabelStore.status.canvasHeight
        };
    }
}
PainterDom.idSeqMap = new Map();
PainterDom.id = 0;
PainterDom.getOffsetOfPainterBG = (e) => {
    const { offsetX: offsetWrapperX, offsetY: offsetWrapperY } = PainterDom.getOffsetOfWrapper(e);
    return {
        offsetX: offsetWrapperX - +imageLabelStore.painterBackground.attr("x"),
        offsetY: offsetWrapperY - +imageLabelStore.painterBackground.attr("y")
    };
};
PainterDom.getOffsetOfPainterBGWithLimit = (e) => {
    const position = PainterDom.getOffsetOfPainterBG(e);
    const limit = PainterDom.getPainterBGSize();
    let resultX = position.offsetX, resultY = position.offsetY;
    if (position.offsetX < 0) {
        resultX = 0;
    }
    else if (position.offsetX > limit.width) {
        resultX = limit.width;
    }
    if (position.offsetY < 0) {
        resultY = 0;
    }
    else if (position.offsetY > limit.height) {
        resultY = limit.height;
    }
    return {
        offsetX: resultX,
        offsetY: resultY
    };
};
PainterDom.getRectMovementOfPainterBGWithLimit = (movement, rect) => {
    const limit = {
        width: +imageLabelStore.painterBackground.attr("width"),
        height: +imageLabelStore.painterBackground.attr("height")
    };
    const maxMoveX = limit.width - rect.x - rect.width;
    const minMoveX = -rect.x;
    const maxMoveY = limit.height - rect.y - rect.height;
    const minMoveY = -rect.y;
    let resultX = movement.movementX, resultY = movement.movementY;
    const { movementX, movementY } = movement;
    if (movementX < minMoveX) {
        resultX = minMoveX;
    }
    else if (movementX > maxMoveX) {
        resultX = maxMoveX;
    }
    if (movementY < minMoveY) {
        resultY = minMoveY;
    }
    else if (movementY > maxMoveY) {
        resultY = maxMoveY;
    }
    return {
        movementX: resultX,
        movementY: resultY
    };
};
PainterDom.getDotMovementOfPainterBGWithLimit = (movement, circle) => {
    const limit = {
        width: +imageLabelStore.painterBackground.attr("width"),
        height: +imageLabelStore.painterBackground.attr("height")
    };
    const maxMoveX = limit.width - circle.cx;
    const minMoveX = -circle.cx;
    const maxMoveY = limit.height - circle.cy;
    const minMoveY = -circle.cy;
    const { movementX, movementY } = movement;
    let resultX = movementX, resultY = movementY;
    if (movementX < minMoveX) {
        resultX = minMoveX;
    }
    else if (movementX > maxMoveX) {
        resultX = maxMoveX;
    }
    if (movementY < minMoveY) {
        resultY = minMoveY;
    }
    else if (movementY > maxMoveY) {
        resultY = maxMoveY;
    }
    return {
        movementX: resultX,
        movementY: resultY
    };
};
PainterDom.getPainterBGSize = () => {
    return {
        width: +imageLabelStore.painterBackground.attr("width"),
        height: +imageLabelStore.painterBackground.attr("height")
    };
};
