var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { createdSandBagsStore } from "./CreatedSandBagsStore";
import { Button, Divider, Popconfirm, Table, Modal, Form, Select } from "antd";
import { toJS } from "mobx";
import { routerStore } from "store/routerStore";
import { observer } from "mobx-react";
let CreatedSandBags = class CreatedSandBags extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "名称",
                dataIndex: "id",
                ellipsis: true,
                render: (value, record) => {
                    return <div>{record.name}</div>;
                }
            },
            {
                title: "沙子数量",
                align: "center",
                width: 120,
                dataIndex: "sandCount"
            },
            {
                title: "创建人",
                width: 240,
                render: (value, record) => {
                    var _a;
                    return <span>{(_a = record.user) === null || _a === void 0 ? void 0 : _a.nickname}</span>;
                }
            },
            {
                title: "创建日期",
                align: "center",
                width: 160,
                render: (value, record) => {
                    return moment(record.createdAt).format("YYYY-MM-DD");
                }
            },
            {
                title: "操作",
                key: "action",
                align: "center",
                width: 300,
                render: (value, record) => {
                    return (<div>
            <Button size="small" type="link" onClick={() => {
                        routerStore.push(`/label/image/label_task/0/label_sand_bags/${record.id}`);
                    }}>
              修改
            </Button>
            <Divider type="vertical"/>
            <Button size="small" type="link" onClick={() => {
                        window.open(`/api/label_sand_bags/${record.id}/sands`);
                    }}>
              下载
            </Button>
            <Divider type="vertical"/>
            <Button size="small" type="link" onClick={() => {
                        createdSandBagsStore.status.curBagId = record.id;
                        createdSandBagsStore.status.isShareSandBagModal = true;
                    }}>
              分享
            </Button>
            <Divider type="vertical"/>
            <Popconfirm title="确认要将这个沙子包删除么？" onConfirm={async () => {
                        await createdSandBagsStore.deleteLabelSandBag(record.id);
                    }}>
              <Button size="small" type="link">
                删除
              </Button>
            </Popconfirm>
          </div>);
                }
            }
        ];
    }
    async componentDidMount() {
        await createdSandBagsStore.loadData();
        await createdSandBagsStore.loadUsers();
    }
    componentWillUnmount() {
        createdSandBagsStore.resetStatus();
    }
    render() {
        const status = createdSandBagsStore.status;
        return (<div>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
          <Button type="primary" onClick={() => {
            routerStore.push("/manage/label_sand_bag/create");
        }}>
            新建
          </Button>
        </div>
        <Table style={{ marginTop: "20px" }} bordered={true} rowKey={record => "" + record.id} dataSource={toJS(status.sandBags)} columns={this.columns} pagination={{ showSizeChanger: false }}/>
        <Modal title="分享沙子包" open={status.isShareSandBagModal} onOk={async () => {
            await createdSandBagsStore.shareSandBag();
        }} onCancel={() => {
            status.sharedTo = null;
            status.isShareSandBagModal = false;
        }}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="用户">
              <Select showSearch={true} allowClear={true} value={status.sharedTo} onChange={value => {
            status.sharedTo = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                {status.users.map(user => (<Select.Option key={String(user.id)} title={`${user.nickname}(${user.name})`} value={user.id}>
                    {`${user.nickname}(${user.name})`}
                  </Select.Option>))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>);
    }
};
CreatedSandBags = __decorate([
    observer
], CreatedSandBags);
export { CreatedSandBags };
