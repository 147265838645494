var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import BasicStore from "../../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../../api/Api";
import Pager from "../../../../model/Pager";
import { routerStore } from "../../../../store/routerStore";
import localforage from "../../../../util/localforage";
import { notification } from "antd";
import * as React from "react";
const MultiLabelProgressKey = "multiLabelProgress";
const Scale = "scale";
class Status {
    constructor() {
        this.isLoading = true;
        this.readonly = false;
        this.columnNum = 5;
        this.scale = 0;
        this.labelTaskId = null;
        this.labelPackageId = null;
        this.labelInfo = null;
        this.selectableLabel = {
            detections: [],
            classifications: []
        };
        this.pager = new Pager();
        this.curClassification = null;
        this.curClassificationFilter = null;
        this.resources = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "readonly", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "columnNum", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "scale", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "labelTaskId", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "labelPackageId", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "labelInfo", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "selectableLabel", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Pager !== "undefined" && Pager) === "function" ? _a : Object)
], Status.prototype, "pager", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "curClassification", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "curClassificationFilter", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "resources", void 0);
class ImageLabelMultiStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
        this.isProcessing = false;
    }
    resetStatus() {
        this.status = new Status();
    }
    async initLabelPage(labelTaskId, labelPackageId) {
        this.status.isLoading = true;
        this.status.labelTaskId = labelTaskId;
        this.status.labelPackageId = labelPackageId;
        const scale = await localforage.getItem(Scale);
        if (!_.isNil(scale)) {
            this.status.scale = scale;
        }
        await this.loadLabelPackageInfo();
        await this.loadImageInfo();
        this.status.isLoading = false;
    }
    async loadLabelPackageInfo() {
        const { labelTaskId, labelPackageId } = this.status;
        const packageInfo = await Api.labelTask.getPackageWithTask(labelTaskId, labelPackageId);
        this.status.selectableLabel = packageInfo.data.labelTask.label;
        this.status.labelInfo = packageInfo.data;
        this.status.pager = this.status.pager.setTotal(packageInfo.data.resourceCount);
    }
    async loadImageInfo() {
        const { labelTaskId, labelPackageId, pager, curClassificationFilter, readonly } = this.status;
        const resources = await Api.labelTask.getLabelPackageResources({
            labelTaskId: labelTaskId,
            labelPackageId: labelPackageId,
            offset: pager.getOffset(),
            limit: pager.getPerPage(),
            filter: curClassificationFilter && {
                classification: {
                    key: curClassificationFilter.key,
                    tool: curClassificationFilter.tool,
                    value: curClassificationFilter.value
                }
            }
        });
        this.status.resources = resources.data;
        this.status.pager.setTotal(resources.total);
        if (readonly) {
            routerStore.replace(`?perPage=${pager.getPerPage()}&curPage=${pager.getCurPage()}&readonly=${readonly}`);
        }
        else {
            routerStore.replace(`?perPage=${pager.getPerPage()}&curPage=${pager.getCurPage()}`);
        }
    }
    async nextPage() {
        try {
            if (this.isProcessing === false) {
                this.isProcessing = true;
                if (this.status.pager.getCurPage() < this.status.pager.getTotalPage()) {
                    this.status.isLoading = true;
                    this.status.pager = this.status.pager.nextPage();
                    await this.loadImageInfo();
                    this.updateMultiLabelProgress();
                    this.status.isLoading = false;
                }
                this.isProcessing = false;
            }
        }
        catch (e) {
            this.isProcessing = false;
        }
    }
    async prevPage() {
        try {
            if (this.isProcessing === false) {
                this.isProcessing = true;
                if (this.status.pager.getCurPage() > 1) {
                    this.status.isLoading = true;
                    this.status.pager = this.status.pager.prevPage();
                    await this.loadImageInfo();
                    this.updateMultiLabelProgress();
                    this.status.isLoading = false;
                }
                this.isProcessing = false;
            }
        }
        catch (e) {
            this.isProcessing = false;
        }
    }
    async jumpPage(page) {
        try {
            if (this.isProcessing === false) {
                this.isProcessing = true;
                if (page > 0 && page <= this.status.pager.getTotalPage()) {
                    this.status.pager = this.status.pager.setCurPage(page);
                    this.status.isLoading = true;
                    await this.loadImageInfo();
                    this.updateMultiLabelProgress();
                    this.status.isLoading = false;
                }
                this.isProcessing = false;
            }
        }
        catch (e) {
            this.isProcessing = false;
        }
    }
    async updateResourceClassification(resource, updatedClassification) {
        const { labelTaskId, labelPackageId } = this.status;
        if (!resource.annotation) {
            resource.annotation = {
                classifications: [],
                detections: []
            };
        }
        if (!resource.annotation.classifications
            .map(i => {
            if (i.key === updatedClassification.key) {
                i.value = updatedClassification.value;
                i.labelUserId = null;
                i.labelTime = null;
                i.labelUpdateTime = null;
                i.isPreset = null;
            }
            return i.key;
        })
            .includes(updatedClassification.key)) {
            resource.annotation.classifications.push(Object.assign(Object.assign({}, updatedClassification), { labelUserId: null, labelTime: null, labelUpdateTime: null, isPreset: null }));
        }
        await Api.labelTask.updateResource(resource.id, {
            labelTaskId: labelTaskId,
            labelPackageId: labelPackageId,
            annotation: resource.annotation,
            meta: resource.meta
        });
    }
    async adjustPerPage(perPage) {
        this.status.pager = this.status.pager.setPerPage(perPage);
        this.status.isLoading = true;
        await this.loadImageInfo();
        this.status.isLoading = false;
    }
    async adjustScale(scale) {
        await localforage.setItem(Scale, scale);
        this.status.scale = scale;
    }
    async loadMultiLabelProgress() {
        const { labelTaskId, labelPackageId } = this.status;
        const multiLabelProgress = await localforage.getItem(MultiLabelProgressKey);
        const match = _.find(multiLabelProgress, progress => progress.taskId === labelTaskId && progress.packageId === labelPackageId);
        if (match) {
            notification.info({
                message: "操作提醒",
                description: (<div>
            检测到上次标注到第 {match.curPage} 页，
            <a onClick={async () => {
                    this.status.pager = this.status.pager.setPerPage(match.perPage);
                    await this.jumpPage(match.curPage);
                }}>
              继续标注
            </a>
          </div>),
                placement: "topRight",
                duration: 2
            });
        }
    }
    async updateMultiLabelProgress() {
        const { labelTaskId, labelPackageId, pager } = this.status;
        let multiLabelProgress = await localforage.getItem(MultiLabelProgressKey);
        if (_.isNil(multiLabelProgress)) {
            multiLabelProgress = [];
        }
        const match = _.find(multiLabelProgress, progress => progress.taskId === labelTaskId && progress.packageId === labelPackageId);
        if (match) {
            match.curPage = pager.getCurPage();
            match.perPage = pager.getPerPage();
        }
        else {
            if (multiLabelProgress.length >= 10) {
                multiLabelProgress.shift();
            }
            multiLabelProgress.push({
                taskId: labelTaskId,
                packageId: labelPackageId,
                curPage: pager.getCurPage(),
                perPage: pager.getPerPage()
            });
        }
        await localforage.setItem(MultiLabelProgressKey, multiLabelProgress);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelMultiStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ImageLabelMultiStore.prototype, "resetStatus", null);
export const imageLabelMultiStore = new ImageLabelMultiStore();
