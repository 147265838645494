import * as React from "react";
import { Loading } from "../../../component/Loading/Loading";
import { routerStore } from "../../../store/routerStore";
export class Landing extends React.Component {
    componentDidMount() {
        routerStore.push("/manage/label_packages");
    }
    render() {
        return <Loading />;
    }
}
