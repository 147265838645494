import React, { createRef, Children, cloneElement } from "react";
export default class Listener extends React.Component {
    constructor() {
        super(...arguments);
        this.child = createRef();
        this.onMouseDown = (e) => {
            var _a, _b;
            e.stopPropagation();
            const start = {
                pageX: e.pageX,
                pageY: e.pageY
            };
            const current = Object.assign({}, start);
            const data = (_b = (_a = this.props).onDragStart) === null || _b === void 0 ? void 0 : _b.call(_a, { start, current });
            const onMouseMove = e => {
                var _a, _b;
                const current = {
                    pageX: e.pageX,
                    pageY: e.pageY
                };
                (_b = (_a = this.props).onDrag) === null || _b === void 0 ? void 0 : _b.call(_a, { start, current, data, type: "mouse" });
            };
            const onMouseUp = e => {
                var _a, _b;
                const current = {
                    pageX: e.pageX,
                    pageY: e.pageY
                };
                (_b = (_a = this.props).onDragEnd) === null || _b === void 0 ? void 0 : _b.call(_a, { start, current, data });
                window.removeEventListener("mousemove", onMouseMove);
                window.removeEventListener("mouseup", onMouseUp);
            };
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
        };
        this.onKeyDown = (e) => {
            if (e.key === "a" || e.key === "w" || e.key === "s" || e.key === "d") {
                let direction = "";
                switch (e.key) {
                    case "a":
                        direction = "left";
                        break;
                    case "w":
                        direction = "top";
                        break;
                    case "d":
                        direction = "right";
                        break;
                    case "s":
                        direction = "bottom";
                        break;
                }
                this.props.onDrag({ direction, type: "keyboard" });
            }
        };
    }
    addEvents() {
        const target = this.child.current;
        if (this.props.drag) {
            target.addEventListener("mousedown", this.onMouseDown);
        }
        if (this.props.enableDragKeyboard) {
            window.addEventListener("keydown", this.onKeyDown);
        }
    }
    removeEvents() {
        const target = this.child.current;
        target.removeEventListener("mousedown", this.onMouseDown);
        window.removeEventListener("keydown", this.onKeyDown);
    }
    componentDidMount() {
        this.addEvents();
    }
    componentWillUnmount() {
        this.removeEvents();
    }
    render() {
        const { children } = this.props;
        return <React.Fragment>{cloneElement(Children.only(children), { ref: this.child })}</React.Fragment>;
    }
}
Listener.defaultProps = {
    drag: true,
    enableDragKeyboard: false
};
