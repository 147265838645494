var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Card, Input, InputNumber } from "antd";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import "./PolicyThreshold.less";
import { observer } from "mobx-react";
import { IResource } from "definition/entity/resource";
let PolicyThreshold = class PolicyThreshold extends React.Component {
    render() {
        let { name, item, videoItem, segment, type } = this.props;
        return (<React.Fragment>
        {IResource.Mime.Image === type && (<Card size="small" style={{ marginBottom: 10 }} className="policy-threshold-comp">
            {segment == "none" && (<React.Fragment>
                <div className="item-desc-name">
                  <Input className="input-item" placeholder="请输入名称" value={name.value} onChange={event => {
            name.value = event.target.value;
        }}/>
                </div>
                <div className="item-desc-detail">
                  {item.map((e, i) => {
            return (<React.Fragment key={"item" + i}>
                        {e.key === "precision" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>
                                {"precision"}
                              </span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                        {e.key === "recall" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>{"recall"}</span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                        {e.key === "iou" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>{"iou"}</span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                        {!(e.key === "name" || e.key === "precision" || e.key === "recall" || e.key === "iou") && (<InputNumber min={0} step={0.1} value={e.value} addonBefore={<Input maxLength={20} value={e.key} style={{ width: 100, border: "none" }} onChange={val => {
                e.key = val.target.value;
            }}/>} onChange={value => {
                e.value = value;
            }}/>)}
                      </React.Fragment>);
        })}
                  <div>
                    <PlusCircleFilled style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.props.add(undefined, undefined)}/>
                    <MinusCircleFilled style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.props.minus()}/>
                  </div>
                </div>
              </React.Fragment>)}
            {segment == "2d" && (<React.Fragment>
                <div className="item-desc-name">
                  <Input className="input-item" placeholder="请输入名称" value={name.value} onChange={event => {
            name.value = event.target.value;
        }}/>
                </div>
                <div className="item-desc-detail">
                  {item.map((e, i) => {
            return (<React.Fragment key={"item" + i}>
                        {e.key === "box_siou_th" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>
                                {"box_siou_th"}
                              </span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                        {e.key === "mask_siou_th" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>
                                {"mask_siou_th"}
                              </span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}

                        {!(e.key === "name" || e.key === "box_siou_th" || e.key === "mask_siou_th") && (<InputNumber min={0} step={0.1} value={e.value} addonBefore={<Input maxLength={20} value={e.key} style={{ width: 100, border: "none" }} onChange={val => {
                e.key = val.target.value;
            }}/>} onChange={value => {
                e.value = value;
            }}/>)}
                      </React.Fragment>);
        })}
                  <div>
                    <PlusCircleFilled style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.props.add(undefined, undefined)}/>
                    <MinusCircleFilled style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.props.minus()}/>
                  </div>
                </div>
              </React.Fragment>)}
          </Card>)}
        {type === IResource.Mime.Video && (<Card size="small" style={{ marginBottom: 10 }} className="policy-threshold-comp">
            <React.Fragment>
              <div className="item-desc-name">
                <Input className="input-item" placeholder="请输入名称" value={name.value} onChange={event => {
            name.value = event.target.value;
        }}/>
              </div>

              <div className="item-desc-detail">
                {videoItem.map((e, i) => {
            return (<React.Fragment key={"item" + i}>
                      {e.key === "mota" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>{"mota"}</span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                      {e.key === "motp" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>{"motp"}</span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                      {e.key === "mt" && (<InputNumber min={0} step={0.1} addonBefore={<span style={{ display: "inline-block", width: 100, padding: "0 5px" }}>{"mt"}</span>} value={e.value} onChange={value => {
                e.value = value;
            }}/>)}
                      {!(e.key === "name" || e.key === "mota" || e.key === "motp" || e.key === "mt") && (<InputNumber value={e.value} min={0} step={0.1} addonBefore={<Input value={e.key} maxLength={20} style={{ width: 100, border: "none" }} onChange={val => {
                e.key = val.target.value;
            }}/>} onChange={value => {
                e.value = value;
            }}/>)}
                    </React.Fragment>);
        })}
                <div>
                  <PlusCircleFilled style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.props.add(undefined, undefined)}/>
                  <MinusCircleFilled style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.props.minus()}/>
                </div>
              </div>
            </React.Fragment>
          </Card>)}
      </React.Fragment>);
    }
};
PolicyThreshold = __decorate([
    observer
], PolicyThreshold);
export { PolicyThreshold };
