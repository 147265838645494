var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Select, Table, DatePicker, Button, PageHeader, Card } from "antd";
import { rankAuditStore } from "./RankAuditStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { DailyWork } from "definition/entity/daily-work";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { ILabelPackage } from "definition/entity/label-package";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const MULTIPLE = 1000;
let RankAudit = class RankAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.disabledDate = (current) => {
            let { calendarDate } = rankAuditStore.status;
            if (!calendarDate || calendarDate.length === 0) {
                return false;
            }
            let startTime = calendarDate[0];
            const start = startTime && startTime.format("YYYYMMDD") > current.format("YYYYMMDD");
            const end = startTime &&
                moment(startTime)
                    .add(30, "days")
                    .format("YYYYMMDD") < current.format("YYYYMMDD");
            return start || end;
        };
        this.columns = [
            {
                title: "标注员",
                dataIndex: "username",
                render: (value, record) => `${record === null || record === void 0 ? void 0 : record.nickname}(${record === null || record === void 0 ? void 0 : record.username})`
            },
            {
                title: "标注总时长",
                align: "center",
                dataIndex: "totalLabelDuration"
            },
            {
                title: "修改总时长",
                align: "center",
                dataIndex: "totalModifyDuration"
            },
            {
                title: "复审总时长",
                align: "center",
                dataIndex: "totalReviewDuration"
            },
            {
                title: "其它总时长",
                align: "center",
                dataIndex: "totalOtherDuration"
            },
            {
                title: "工作总时长",
                align: "center",
                render: (value, record) => (record === null || record === void 0 ? void 0 : record.totalLabelDuration) + (record === null || record === void 0 ? void 0 : record.totalModifyDuration) + (record === null || record === void 0 ? void 0 : record.totalReviewDuration) + (record === null || record === void 0 ? void 0 : record.totalOtherDuration)
            },
            {
                title: "综合绩效",
                align: "center",
                dataIndex: "performanceVal"
            },
            {
                title: "排名",
                align: "center",
                dataIndex: "order"
            }
        ];
        this.childColumns = [
            {
                title: "任务名称",
                dataIndex: "taskName",
                ellipsis: true
            },
            {
                title: "计价单位",
                align: "center",
                width: 160,
                render: (value, record) => {
                    switch (record === null || record === void 0 ? void 0 : record.taskType) {
                        case LabelTaskBusiness.Type.Box:
                            return <span>个（检测框）</span>;
                        case LabelTaskBusiness.Type.Piece:
                            return <span>张（图片）</span>;
                        case LabelTaskBusiness.Type.MaskBox:
                            return <span>个（分割框）</span>;
                        case LabelTaskBusiness.Type.Object:
                            return <span>个（关键帧）</span>;
                        case LabelTaskBusiness.Type.Chunk:
                            return <span>段（分类内容）</span>;
                        case LabelTaskBusiness.Type.Anchor:
                            return <span>个（时空锚对象）</span>;
                        case LabelTaskBusiness.Type.Cloud:
                            return <span>个（点云）</span>;
                        default:
                            return <span>未知</span>;
                    }
                }
            },
            {
                title: "标注量",
                width: 120,
                dataIndex: "workload",
                align: "center"
            },
            {
                title: "标注时长",
                width: 120,
                align: "center",
                dataIndex: "labelDuration"
            },
            {
                title: "标注效率",
                align: "center",
                dataIndex: "efficient"
            },
            {
                title: "修改时长",
                width: 120,
                align: "center",
                dataIndex: "modifyDuration"
            },
            {
                title: "修改结果",
                align: "center",
                dataIndex: "result"
            },
            {
                title: "复审时长",
                align: "center",
                dataIndex: "reviewDuration"
            },
            {
                title: "其它时长",
                align: "center",
                dataIndex: "otherDuration"
            }
        ];
        this.csvHeaders = [
            {
                label: "标注员",
                key: "username"
            },
            {
                label: "任务名称",
                key: "taskName"
            },
            {
                label: "计价单位",
                key: "taskType"
            },
            {
                label: "标注量",
                key: "workload"
            },
            {
                label: "标注时长",
                key: "labelDuration"
            },
            {
                label: "标注效率",
                key: "efficient"
            },
            {
                label: "修改时长",
                key: "modifyDuration"
            },
            {
                label: "修改结果",
                key: "result"
            },
            {
                label: "复审时长",
                key: "reviewDuration"
            },
            {
                label: "其它时长",
                key: "otherDuration"
            }
        ];
        this.expandedRowRender = (record) => {
            const status = rankAuditStore.status;
            const childData = status.rankAudits.filter(item => record.userId === item.creatorId);
            const tasksMap = new Map();
            childData.forEach(item => {
                if (tasksMap.has(item.taskId)) {
                    const _prevEfficient = tasksMap.get(item.taskId).efficient;
                    const _prevWorkload = tasksMap.get(item.taskId).workload;
                    if (item.workType === DailyWork.WorkType.Label) {
                        if (_.isNil(item.cloudWorkload)) {
                            tasksMap.get(item.taskId).workload = [_prevWorkload[0] + item.workload];
                            tasksMap.get(item.taskId).labelDuration =
                                (tasksMap.get(item.taskId).labelDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                            tasksMap.get(item.taskId).efficient = _prevEfficient.map((prevEfficient, i) => {
                                return !_.isNil(item.standardNum[i])
                                    ? (prevEfficient * MULTIPLE +
                                        ((((item === null || item === void 0 ? void 0 : item.workload) * MULTIPLE || 0) - ((item === null || item === void 0 ? void 0 : item.standardNum[i]) / 7) * item.duration * MULTIPLE) /
                                            MULTIPLE) *
                                            MULTIPLE) /
                                        MULTIPLE
                                    : prevEfficient;
                            });
                        }
                        else {
                            tasksMap.get(item.taskId).workload = _prevWorkload.map((workload, i) => {
                                return workload + item.cloudWorkload[i];
                            });
                            tasksMap.get(item.taskId).labelDuration =
                                (tasksMap.get(item.taskId).labelDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                            tasksMap.get(item.taskId).efficient = _prevEfficient.map((prevEfficient, i) => {
                                return !_.isNil(item.standardNum[i])
                                    ? (prevEfficient * MULTIPLE +
                                        ((((item === null || item === void 0 ? void 0 : item.cloudWorkload[i]) * MULTIPLE || 0) -
                                            ((item === null || item === void 0 ? void 0 : item.standardNum[i]) / 7) * item.duration * MULTIPLE) /
                                            MULTIPLE) *
                                            MULTIPLE) /
                                        MULTIPLE
                                    : prevEfficient;
                            });
                        }
                    }
                    if (item.workType === DailyWork.WorkType.Modify) {
                        tasksMap.get(item.taskId).modifyDuration =
                            (tasksMap.get(item.taskId).modifyDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    }
                    if (item.workType === DailyWork.WorkType.Review) {
                        tasksMap.get(item.taskId).reviewDuration =
                            (tasksMap.get(item.taskId).reviewDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    }
                    if (item.workType === DailyWork.WorkType.Other) {
                        tasksMap.get(item.taskId).otherDuration =
                            (tasksMap.get(item.taskId).otherDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    }
                }
                else {
                    let auditMapValue = {
                        taskId: item.taskId || Math.random(),
                        taskName: item.taskName || item.affair,
                        taskType: item.taskType,
                        workload: [0],
                        labelDuration: 0,
                        efficient: [0],
                        modifyDuration: 0,
                        result: "",
                        reviewDuration: 0,
                        otherDuration: 0
                    };
                    if (item.workType === DailyWork.WorkType.Label) {
                        if (_.isNil(item.cloudWorkload)) {
                            auditMapValue.workload = [item.workload];
                            auditMapValue.labelDuration = item.duration;
                            auditMapValue.efficient = item.standardNum.map(standardNum => {
                                return !_.isNil(standardNum)
                                    ? (((item === null || item === void 0 ? void 0 : item.workload) * MULTIPLE || 0) - (standardNum / 7) * item.duration * MULTIPLE) / MULTIPLE
                                    : 0;
                            });
                        }
                        else {
                            auditMapValue.workload = item.cloudWorkload;
                            auditMapValue.labelDuration = item.duration;
                            auditMapValue.efficient = item.standardNum.map((standardNum, idx) => {
                                return !_.isNil(standardNum)
                                    ? (((item === null || item === void 0 ? void 0 : item.cloudWorkload[idx]) * MULTIPLE || 0) - (standardNum / 7) * item.duration * MULTIPLE) / MULTIPLE
                                    : 0;
                            });
                        }
                    }
                    if (item.workType === DailyWork.WorkType.Modify) {
                        auditMapValue.modifyDuration = item.duration;
                    }
                    if (item.workType === DailyWork.WorkType.Review) {
                        auditMapValue.reviewDuration = item.duration;
                    }
                    if (item.workType === DailyWork.WorkType.Other) {
                        auditMapValue.otherDuration = item.duration;
                    }
                    const assessPackages = item.packages.filter(i => i.assessNum >= 2);
                    if (assessPackages.length > 0) {
                        const adopt = assessPackages.filter(i => [
                            ILabelPackage.Status.Reviewed,
                            ILabelPackage.Status.Archived,
                            ILabelPackage.Status.AssessPassed,
                            ILabelPackage.Status.AssignReview,
                            ILabelPackage.Status.Labeled,
                            ILabelPackage.Status.Reviewing,
                            ILabelPackage.Status.AssignFinalReview,
                            ILabelPackage.Status.FinalReviewing,
                            ILabelPackage.Status.FinalReviewed
                        ].includes(i.status)).length;
                        auditMapValue.result = `${adopt} 通过，${assessPackages.length - adopt} 不通过`;
                    }
                    else {
                        auditMapValue.result = "-";
                    }
                    tasksMap.set(item.taskId || Math.random(), auditMapValue);
                }
            });
            const dataSource = [...tasksMap.values()].map(i => (Object.assign(Object.assign({}, i), { efficient: i.efficient.map(v => v.toFixed(3)).join("|"), workload: i.workload.join("|") })));
            return (<Table rowKey={record => "detail" + record.taskId} columns={this.childColumns} dataSource={dataSource} pagination={false}/>);
        };
    }
    componentDidMount() {
        rankAuditStore.loadGroups();
        rankAuditStore.loadUsers();
    }
    render() {
        const status = rankAuditStore.status;
        return (<PageHeader title="绩效统计" style={{ background: "#fff" }}>
        <Card>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <div>
              <RangePicker disabledDate={this.disabledDate} value={toJS(status.calendarDate)} onCalendarChange={date => {
            status.calendarDate = date;
        }} onChange={date => {
            status.calendarDate = date;
        }}/>
              <Select showSearch allowClear mode="multiple" placeholder="请选择单位" style={{ width: 240, marginLeft: 10 }} value={toJS(status.curGroupIds)} onChange={value => {
            status.curGroupIds = value;
            status.curUserIds = [];
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                {status.groups.map(group => (<Select.Option key={group.id} title={group.name} value={group.id}>
                    {group.name}
                  </Select.Option>))}
              </Select>
              <Select placeholder="请选择用户" showSearch mode="multiple" value={toJS(status.curUserIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curUserIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                {toJS(rankAuditStore.filterUsers).map(user => (<Select.Option key={user.id} title={`${user.nickname}(${user.name})`} value={user.id}>
                    {`${user.nickname}(${user.name})`}
                  </Select.Option>))}
              </Select>
              <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={rankAuditStore.isUnableSearch} onClick={() => {
            rankAuditStore.loadData();
        }}></Button>
            </div>

            <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename}>
              <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={rankAuditStore.isUnableDownload} onClick={() => rankAuditStore.downAnnotationAuditFile()}/>
            </CSVLink>
          </div>

          <Table loading={status.loading} rowKey={record => "workload" + record.userId} bordered={true} style={{ marginTop: "20px" }} dataSource={toJS(rankAuditStore.transformedAnnotation)} columns={this.columns} expandedRowRender={this.expandedRowRender}/>
        </Card>
      </PageHeader>);
    }
};
RankAudit = __decorate([
    observer
], RankAudit);
export { RankAudit };
