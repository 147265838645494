var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import Color from "color";
import { imageLabelStore } from "../../ImageLabelStore";
import { observer } from "mobx-react";
const padding = 0;
let RectBounding = class RectBounding extends React.Component {
    render() {
        const element = this.props.element;
        const status = imageLabelStore.status;
        const { id, label: { color } } = element;
        const attributes = element.attributes;
        const { x, y, width, height } = attributes;
        return (<svg x={x - padding} y={y - padding} width={width + padding * 2} height={height + padding * 2} style={{ overflow: "visible" }}>
        <foreignObject x="0" y="0" width="100%" height="100%" style={{
            overflow: "visible",
            pointerEvents: "none",
            backgroundColor: Color(color).fade(0.9)
        }}>
          <div style={{
            color: color,
            position: "absolute",
            left: 0,
            top: -18,
            lineHeight: 1,
            whiteSpace: "nowrap"
        }}>
            {id}
          </div>
          <div style={{
            color: color,
            position: "absolute",
            right: 4,
            bottom: 4,
            lineHeight: 1,
            whiteSpace: "nowrap"
        }}>
            {(width / status.zoomRatio).toFixed(0)} * {(height / status.zoomRatio).toFixed(0)}
          </div>
        </foreignObject>
      </svg>);
    }
};
RectBounding = __decorate([
    observer
], RectBounding);
export { RectBounding };
