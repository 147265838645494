import * as React from "react";
import { Button, Divider, PageHeader } from "antd";
import "./NotFound.less";
export class NotFound extends React.Component {
    render() {
        return (<PageHeader title="" className="not-found-component" style={{ background: "#FFF" }}>
        <h2>404</h2>
        <h3>Page Not Found</h3>
        <Divider />
        <Button type="primary" onClick={() => {
            window.location.href = "/manage/label_packages";
        }}>
          返回主页
        </Button>
      </PageHeader>);
    }
}
