var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import classNames from "classnames";
import { AnchorTool } from "definition/entity/label-task";
import { observer } from "mobx-react";
import { PainterMode } from "page/label/image/interface/common";
import React from "react";
import { IconFont } from "../../../../../../component/IconFont/IconFont";
import { imageLabelStore } from "../../ImageLabelStore";
import { keymapStore } from "../../provider/keymap-store";
import { KeymapEditor } from "../KeymapEditor/keymapEditor";
let AnchorSelector = class AnchorSelector extends React.Component {
    constructor() {
        super(...arguments);
        this.anchorMap = {
            [AnchorTool.Dot]: { value: AnchorTool.Dot, text: "锚点" }
        };
        this.onClick = anchor => {
            imageLabelStore.status.curSelectedLabel = anchor;
            imageLabelStore.status.mode = PainterMode.DRAW;
        };
    }
    render() {
        const { anchors } = this.props;
        return (<div className="anchor-selector">
        {anchors
            .map(anchor => (Object.assign(Object.assign({}, anchor), this.anchorMap[anchor.tool])))
            .map((anchor, idx) => {
            var _a, _b;
            return (<div className={classNames({
                "label-item anchor": true,
                selected: ((_b = (_a = imageLabelStore.status) === null || _a === void 0 ? void 0 : _a.curSelectedLabel) === null || _b === void 0 ? void 0 : _b.key) === anchor.key
            })} onClick={() => {
                this.onClick(anchor);
            }} key={idx}>
              {anchor.tool === AnchorTool.Dot && (<React.Fragment>
                  <IconFont type="icon-dot" style={{ color: anchor.color, fontSize: "20px" }}/>
                  <span>{anchor.locale || anchor.key}</span>
                  <KeymapEditor keyStr={keymapStore.getKeyByActionTag(`anchor_${anchor.key}`)} onChange={keyStr => {
                keymapStore.register({
                    key: keyStr,
                    actionTag: `anchor_${anchor.key}`,
                    action: () => {
                        this.onClick(anchor);
                    }
                });
            }}/>
                </React.Fragment>)}
            </div>);
        })}
      </div>);
    }
};
AnchorSelector.defaultProps = {
    anchors: []
};
AnchorSelector = __decorate([
    observer
], AnchorSelector);
export default AnchorSelector;
