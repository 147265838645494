var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Color from "color";
import Listener from "component/Listener";
import { find } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { PainterMode } from "../../../interface/common";
import { imageLabelStore } from "../../ImageLabelStore";
import { isLeftMouseClick } from "../../provider/mouse-button";
import { PainterDom } from "../../provider/painter-dom";
import "./BoxDetection.less";
let BoxDetection = class BoxDetection extends React.Component {
    constructor() {
        super(...arguments);
        this.onMouseDown = event => {
            const status = imageLabelStore.status;
            if (!isLeftMouseClick(event))
                return;
            if (status.mode === PainterMode.DRAW)
                return;
            const { element } = this.props;
            if (status.mode === PainterMode.UNSET) {
                status.mode = PainterMode.MOVE;
                const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBG(event);
                const attributes = element.attributes;
                element.initAttribute = {
                    cursorX: offsetX,
                    cursorY: offsetY,
                    x: attributes.x,
                    y: attributes.y
                };
                status.selectedElementIds = [element.id];
            }
        };
        this.handleResizeModeStart = (event, resizeMode) => {
            if (!isLeftMouseClick(event))
                return;
            PainterDom.hideRuler();
            const status = imageLabelStore.status;
            status.preMode = status.mode;
            const element = this.props.element;
            const attributes = element.attributes;
            imageLabelStore.status.mode = PainterMode.RESIZE;
            imageLabelStore.status.resizeMode = resizeMode;
            imageLabelStore.status.selectedElementIds = [element.id];
            element.initAttribute = {
                x: attributes.x,
                y: attributes.y,
                width: attributes.width,
                height: attributes.height
            };
        };
    }
    render() {
        var _a, _b;
        const { element } = this.props;
        const attributes = element.attributes;
        const { id, label: { color } } = element;
        const orientation = attributes.orientation;
        const [p0, p1, p2, p3, p4, p5, p6, p7] = attributes.points;
        let front;
        let back;
        switch (orientation) {
            case "n":
                front = [p0, p1, p2, p3];
                back = [p4, p5, p6, p7];
                break;
            case "e":
                front = [p1, p5, p6, p2];
                back = [p0, p4, p7, p3];
                break;
            case "s":
                front = [p4, p5, p6, p7];
                back = [p0, p1, p2, p3];
                break;
            case "w":
                front = [p0, p4, p7, p3];
                back = [p1, p5, p6, p2];
                break;
        }
        const side = [p0, p4, p7, p3, p1, p5, p6, p2];
        const frontPolygonPoints = front.map(v => `${v[0]},${v[1]}`).join(" ");
        const backPolygonPoints = back.map(v => `${v[0]},${v[1]}`).join(" ");
        const sidePoints = side.map(v => `${v[0]},${v[1]}`);
        const line1 = [front[0], back[0]];
        const line2 = [front[1], back[1]];
        const line3 = [front[2], back[2]];
        const line4 = [front[3], back[3]];
        const fillColor = Color(color)
            .alpha(0.3)
            .toString();
        const p = toJS(attributes.points);
        const xs = p.map(v => v[0]);
        const ys = p.map(v => v[1]);
        const min = [Math.min.apply(null, xs), Math.min.apply(null, ys)];
        const max = [Math.max.apply(null, xs), Math.max.apply(null, ys)];
        const x = min[0];
        const y = min[1];
        const width = max[0] - min[0];
        const height = max[1] - min[1];
        const selected = imageLabelStore.status.selectedElementIds.includes(id);
        const polyLineLength = Math.floor(Math.min(width, height) / 3);
        const selectedBorderColor = Color(color)
            .lighten(1)
            .toString();
        const face = ((_a = find(element.detection.classifications, { key: "face" })) === null || _a === void 0 ? void 0 : _a.value) || "back";
        const indices = face === "back" ? [0, 3, 1, 2, 4, 7, 5, 6] : [1, 2, 0, 3, 5, 6, 4, 7];
        return (<React.Fragment>
        {element.visible && (<g onMouseDown={this.onMouseDown}>
            <polygon strokeLinejoin="round" points={frontPolygonPoints} style={{ stroke: selected ? selectedBorderColor : color, fill: fillColor }}/>
            <polygon strokeLinejoin="round" points={backPolygonPoints} style={{ stroke: selected ? selectedBorderColor : color, fill: "transparent" }}/>

            <polygon points={sidePoints.slice(0, 4).join(" ")} fill="transparent"></polygon>
            <polygon points={sidePoints.slice(4).join(" ")} fill="transparent"></polygon>

            <line x1={line1[0][0]} y1={line1[0][1]} x2={line1[1][0]} y2={line1[1][1]} strokeDasharray="4 4" style={{ stroke: selected ? selectedBorderColor : color }}/>
            <line x1={line2[0][0]} y1={line2[0][1]} x2={line2[1][0]} y2={line2[1][1]} strokeDasharray="4 4" style={{ stroke: selected ? selectedBorderColor : color }}/>
            <line x1={line3[0][0]} y1={line3[0][1]} x2={line3[1][0]} y2={line3[1][1]} strokeDasharray="4 4" style={{ stroke: selected ? selectedBorderColor : color }}/>
            <line x1={line4[0][0]} y1={line4[0][1]} x2={line4[1][0]} y2={line4[1][1]} strokeDasharray="4 4" style={{ stroke: selected ? selectedBorderColor : color }}/>

            
            {selected && (<React.Fragment>
                <text x={min[0]} y={min[1] - 5} fill={color} fontSize={14}>
                  {id}
                </text>
                <Listener drag={true} enableDragKeyboard={true} onDragStart={() => toJS(attributes.points)} onDrag={e => {
            const { start, current, data, type, direction } = e;
            if (type === "mouse") {
                const deltaX = current.pageX - start.pageX;
                const deltaY = current.pageY - start.pageY;
                attributes.points.forEach((v, idx) => {
                    v[0] = data[idx][0] + deltaX;
                    v[1] = data[idx][1] + deltaY;
                });
            }
            else {
                let deltaX = 0;
                let deltaY = 0;
                const step = 1;
                switch (direction) {
                    case "left":
                        deltaX = -1 * step;
                        break;
                    case "top":
                        deltaY = -1 * step;
                        break;
                    case "right":
                        deltaX = 1 * step;
                        break;
                    case "bottom":
                        deltaY = 1 * step;
                        break;
                }
                attributes.points.forEach(v => {
                    v[0] += deltaX;
                    v[1] += deltaY;
                });
            }
        }}>
                  <rect x={x} y={y} width={width} height={height} fill="transparent" stroke={"#fff"} strokeWidth={2} strokeDasharray="5, 5"></rect>
                </Listener>
                {indices.map((v, idx) => {
            const p = attributes.points[v];
            return (<g>
                      <defs>
                        <filter x="0" y="0" width="1" height="1" id="solid">
                          <feFlood flood-color="yellow" result="bg"/>
                          <feMerge>
                            <feMergeNode in="bg"/>
                            <feMergeNode in="SourceGraphic"/>
                          </feMerge>
                        </filter>
                      </defs>
                      <text filter="url(#solid)" x={p[0]} y={p[1]}>
                        {idx}
                      </text>
                    </g>);
        })}
              </React.Fragment>)}

            {((_b = imageLabelStore.status.editingElement) === null || _b === void 0 ? void 0 : _b.id) === id && (<React.Fragment>
                {front.map((p, idx) => {
            return (<Listener key={idx} onDragStart={() => toJS(p)} onDrag={e => {
                const { start, current, data } = e;
                const diffX = current.pageX - start.pageX;
                const diffY = current.pageY - start.pageY;
                p[0] = data[0] + diffX;
                p[1] = data[1] + diffY;
            }}>
                      <circle cx={p[0]} cy={p[1]} r={5} fill="#FFF" strokeWidth={1} stroke={color} className="element-circle" style={{ cursor: "pointer" }}/>
                    </Listener>);
        })}
                {back.map((p, idx) => {
            return (<Listener key={idx} onDragStart={() => toJS(p)} onDrag={e => {
                const { start, current, data } = e;
                const diffX = current.pageX - start.pageX;
                const diffY = current.pageY - start.pageY;
                p[0] = data[0] + diffX;
                p[1] = data[1] + diffY;
            }}>
                      <circle key={idx} cx={p[0]} cy={p[1]} r={5} fill="#FFF" strokeWidth={1} stroke={color} className="element-circle" style={{ cursor: "pointer" }}/>
                    </Listener>);
        })}
              </React.Fragment>)}
          </g>)}
      </React.Fragment>);
    }
};
BoxDetection.defaultProps = {
    showId: false
};
BoxDetection = __decorate([
    observer
], BoxDetection);
export { BoxDetection };
