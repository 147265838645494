export default class MediaInfoHelper {
    constructor(url) {
        this.url = url;
    }
    async request(url, opts = { offset: 0, size: 1 }) {
        const { offset, size } = opts;
        return fetch(url, {
            headers: {
                range: `bytes=${offset}-${offset + size - 1}`
            }
        });
    }
    async getSize() {
        if (this.contentLength === undefined) {
            try {
                const res = await this.request(this.url);
                const rangeReceived = res.headers.get("Content-Range");
                if (!rangeReceived) {
                    console.error(`无法获取 Content-Range , url: ${this.url}`);
                    this.contentLength = 1;
                }
                else {
                    let sizeIndex = rangeReceived.indexOf("/");
                    if (sizeIndex > -1) {
                        this.contentLength = +rangeReceived.slice(sizeIndex + 1);
                    }
                }
            }
            catch (e) {
                console.error(e);
                this.contentLength = 1;
            }
        }
        return this.contentLength;
    }
    async getChunk(size, offset) {
        let chunk = new ArrayBuffer(0);
        try {
            const res = await this.request(this.url, { size, offset });
            chunk = await res.arrayBuffer();
        }
        catch (e) {
            console.error(e);
        }
        return new Uint8Array(chunk);
    }
}
