import { BasePainter } from "./base-painter";
import { imageLabelStore } from "../../../ImageLabelStore";
import { DetectionTool } from "../../../../../../../definition/entity/label-task";
import { PainterDom } from "../../../provider/painter-dom";
export class BoxPainter extends BasePainter {
    constructor() {
        super(...arguments);
        this._drawing = false;
        this.edit = null;
    }
    reset() {
        this.edit = null;
    }
    Draw_MouseDown(event) {
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel } = status;
        if (!(curLabel.tool === DetectionTool.Box && curLabel.type === "detection"))
            return;
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBG(event);
        this._drawing = true;
        if (this.edit == null) {
            this.edit = "front";
            const id = PainterDom.generateId(curLabel.key);
            imageLabelStore.status.creatingElement = {
                id: id,
                label: curLabel,
                visible: true,
                attributes: {
                    points: Array.from(Array(8)).fill([offsetX, offsetY]),
                    orientation: "n"
                },
                initAttribute: {
                    cursorX: offsetX,
                    cursorY: offsetY
                },
                detection: {
                    key: curLabel.key,
                    value: null,
                    tool: DetectionTool.Rectangle,
                    classifications: curLabel.classifications.map(cls => ({ key: cls.key, value: cls.defaultValue })),
                    trails: []
                },
                display: "normal"
            };
        }
        else if (this.edit === "back") {
            const status = imageLabelStore.status;
            const { creatingElement, layerElements } = status;
            if (creatingElement) {
                status.layerElements = layerElements.concat(creatingElement);
            }
            status.creatingElement = null;
            this.edit = null;
        }
    }
    Draw_MouseUp(event) {
        if (!this.isValidCreatingEle(DetectionTool.Box, "detection"))
            return;
        if (this.edit === "front") {
            const attributes = imageLabelStore.status.creatingElement.attributes;
            const [p0, p1, p2, p3] = attributes.points;
            if (Math.abs(p0[0] - p1[0]) < 8 || Math.abs(p0[1] - p3[1]) < 8) {
                imageLabelStore.status.creatingElement = null;
                this.edit = null;
                return;
            }
            const status = imageLabelStore.status;
            const { creatingElement } = status;
            const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBG(event);
            creatingElement.initAttribute.cursorX = offsetX;
            creatingElement.initAttribute.cursorY = offsetY;
            this.edit = "back";
        }
        this._drawing = false;
    }
    Draw_MouseMove(event) {
        if (!this.isValidCreatingEle(DetectionTool.Box, "detection"))
            return;
        const status = imageLabelStore.status;
        const { creatingElement } = status;
        const { cursorX, cursorY } = creatingElement.initAttribute;
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBG(event);
        const x = Math.min(cursorX, offsetX);
        const y = Math.min(cursorY, offsetY);
        const width = Math.abs(cursorX - offsetX);
        const height = Math.abs(cursorY - offsetY);
        const diffX = offsetX - cursorX;
        const diffY = offsetY - cursorY;
        const p0 = [x, y];
        const p1 = [x + width, y];
        const p2 = [x + width, y + height];
        const p3 = [x, y + height];
        const attributes = creatingElement.attributes;
        if (this._drawing && this.edit === "front") {
            attributes.points[0] = p0;
            attributes.points[1] = p1;
            attributes.points[2] = p2;
            attributes.points[3] = p3;
        }
        if (!this._drawing && this.edit === "back") {
            const _p0 = attributes.points[0];
            const _p1 = attributes.points[1];
            const _p2 = attributes.points[2];
            const _p3 = attributes.points[3];
            const p4 = [_p0[0] + diffX, _p0[1] + diffY];
            const p5 = [_p1[0] + diffX, _p1[1] + diffY];
            const p6 = [_p2[0] + diffX, _p2[1] + diffY];
            const p7 = [_p3[0] + diffX, _p3[1] + diffY];
            attributes.points[4] = p4;
            attributes.points[5] = p5;
            attributes.points[6] = p6;
            attributes.points[7] = p7;
        }
    }
    Move_MouseMove(event) { }
    Move_MouseUp(event) { }
    Resize_MouseUp(event) { }
    Resize_MouseMove(event) { }
    keyboard(event) {
        return;
        const orientations = ["n", "e", "s", "w"];
        if (event.key === "q" && imageLabelStore.status.selectedElementIds.length) {
            const el = imageLabelStore.selectedElements[0];
            const { points: [p0, p1, p2, p3, p4, p5, p6, p7], orientation } = el.attributes;
            el.attributes.orientation = orientations[(orientations.indexOf(orientation) + 1) % orientations.length];
        }
    }
}
export const boxPainter = new BoxPainter();
