export var LabelTaskBusiness;
(function (LabelTaskBusiness) {
    let Type;
    (function (Type) {
        Type[Type["Box"] = 0] = "Box";
        Type[Type["Piece"] = 1] = "Piece";
        Type[Type["Object"] = 2] = "Object";
        Type[Type["Chunk"] = 3] = "Chunk";
        Type[Type["Anchor"] = 4] = "Anchor";
        Type[Type["MaskBox"] = 5] = "MaskBox";
        Type[Type["Cloud"] = 6] = "Cloud";
    })(Type = LabelTaskBusiness.Type || (LabelTaskBusiness.Type = {}));
})(LabelTaskBusiness || (LabelTaskBusiness = {}));
