import { BlobServicePromiseClient } from "atom-proto/web/dataocean/blob_grpc_web_pb";
import { BubbleServicePromiseClient } from "atom-proto/web/dataocean/bubble_grpc_web_pb";
import { DropletServicePromiseClient } from "atom-proto/web/dataocean/droplet_grpc_web_pb";
import { DatasetServicePromiseClient } from "atom-proto/web/dataocean/dataset_grpc_web_pb";
import * as _ from "lodash";
window.ENABLE_GRPC_DEBUG = false;
function checkGRPCObject(obj, keyName) {
    return !_.isEmpty(obj[keyName]) && typeof obj[keyName].toObject === "function";
}
function debugGRPC(key = "default: ", obj) {
    try {
        if (ENABLE_GRPC_DEBUG) {
            console.log(`=== ${key} ===`);
            console.log({
                req: checkGRPCObject(obj, "req") ? obj.req.toObject() : "",
                resp: checkGRPCObject(obj, "resp") ? obj.resp.toObject() : ""
            });
            console.log("");
        }
    }
    catch (e) {
        console.error(e);
    }
}
function createService(Client, endpoint, metadata) {
    const client = new Client(endpoint, null, {
        withCredentials: true
    });
    const methods = {};
    for (let name in client) {
        if (typeof client[name] === "function") {
            methods[name] = async function (req, meta) {
                let resp;
                try {
                    resp = await client[name](req, meta || metadata);
                    return resp;
                }
                catch (e) {
                    if (e.code === 16) {
                        const url = `${endpoint}/v2/login/browser?return_to=${encodeURIComponent(location.origin)}`;
                        window.location.href = url;
                        return;
                    }
                    throw e;
                }
                finally {
                    debugGRPC(name, { req, resp });
                }
            };
        }
    }
    return methods;
}
export function init(endpoint, metadata) {
    return {
        blob: createService(BlobServicePromiseClient, endpoint, metadata),
        bubble: createService(BubbleServicePromiseClient, endpoint, metadata),
        droplet: createService(DropletServicePromiseClient, endpoint, metadata),
        dataset: createService(DatasetServicePromiseClient, endpoint, metadata)
    };
}
