import axios from "util/axios";
export const getAssessmentPolicyVerification = (verifyId) => {
    return axios.get(`/labelling_assessment_policies/verifies/${verifyId}`);
};
export const createAssessmentPolicyVerification = (params) => {
    return axios.post("/labelling_assessment_policies/verifies", params);
};
export const getAssessmentPolicyVerificationLogs = (verifyId) => {
    return axios.get(`/labelling_assessment_policies/verifies/${verifyId}/logs`);
};
export const getLabellingAssessmentPolicies = (params) => {
    return axios.get("/labelling_assessment_policies/policies", {
        params: params
    });
};
export const getLabellingAssessmentPolicy = (policyId) => {
    return axios.get(`/labelling_assessment_policies/policies/${policyId}`);
};
export const createLabellingAssessmentPolicy = (params) => {
    return axios.post("/labelling_assessment_policies/policies", params);
};
export const modifyLabellingAssessmentPolicy = (policyId, params) => {
    return axios.patch(`/labelling_assessment_policies/policies/${policyId}`, params);
};
export const updateLabellingAssessmentPolicy = (policyId, params) => {
    return axios.put(`/labelling_assessment_policies/policies/${policyId}`, params);
};
export const deleteLabellingAssessmentPolicy = (policyId) => {
    return axios.delete(`/labelling_assessment_policies/policies/${policyId}`);
};
export const enableLabellingAssessmentPolicy = (policyId) => {
    return axios.post(`/labelling_assessment_policies/policies/${policyId}/_enable`);
};
export const disableLabellingAssessmentPolicy = (policyId) => {
    return axios.post(`/labelling_assessment_policies/policies/${policyId}/_disable`);
};
