import * as React from "react";
import { LabelEditorViewer } from "../../../../component/LabelEditorViewer/LabelEditorViewer";
import { Modal } from "antd";
import "./SandPackageLabelModal.less";
export class SandPackageLabelModal extends React.Component {
    render() {
        const { visible, onCancel, label } = this.props;
        return (<Modal className="sand-package-label-modal" open={visible} title="沙子包标签" width="60vw" onCancel={() => {
            onCancel();
        }} footer={null}>
        <LabelEditorViewer label={label}/>
      </Modal>);
    }
}
