import { IResource } from "definition/entity/resource";
import * as _ from "lodash";
import videoLabelStore from "page/label/video/VideoLabel/VideoLabelStore";
import { DetectionTool } from "../../../../../../../definition/entity/label-task";
import { PainterMode, ResizeMode } from "../../../../interface/common";
import { imageLabelStore } from "../../../ImageLabelStore";
import { PainterDom } from "../../../provider/painter-dom";
import { BasePainter } from "./base-painter";
export function isArrowKey(key) {
    return key === "ArrowLeft" || key === "ArrowDown" || key === "ArrowRight" || key === "ArrowUp";
}
export class RectPainter extends BasePainter {
    Draw_MouseDown(event) {
        const status = imageLabelStore.status;
        const { curSelectedLabel: curLabel } = status;
        if (!(curLabel.tool === DetectionTool.Rectangle && curLabel.type === "detection"))
            return;
        const id = PainterDom.generateId(curLabel.key);
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        imageLabelStore.status.creatingElement = {
            id: id,
            label: curLabel,
            visible: true,
            attributes: {
                x: offsetX,
                y: offsetY,
                width: 0,
                height: 0
            },
            initAttribute: {
                cursorX: offsetX,
                cursorY: offsetY
            },
            detection: {
                key: curLabel.key,
                value: null,
                tool: DetectionTool.Rectangle,
                classifications: curLabel.classifications.map(cls => ({ key: cls.key, value: cls.defaultValue })),
                trails: []
            },
            display: "normal"
        };
    }
    Draw_MouseUp(event) {
        if (!this.isValidCreatingEle(DetectionTool.Rectangle, "detection"))
            return;
        const status = imageLabelStore.status;
        const { creatingElement, zoomRatio, layerElements } = status;
        const attributes = creatingElement.attributes;
        if (attributes.width / zoomRatio >= 8 && attributes.height / zoomRatio >= 8) {
            status.layerElements = layerElements.concat(creatingElement);
        }
        const currentFrame = imageLabelStore.currentFrame;
        creatingElement.detection.trails.push({
            x: attributes.x / status.zoomRatio,
            y: attributes.y / status.zoomRatio,
            width: attributes.width / status.zoomRatio,
            height: attributes.height / status.zoomRatio,
            inview: true,
            cover: false,
            hidden: false,
            frame: currentFrame
        });
        status.creatingElement = null;
    }
    Draw_MouseMove(event) {
        if (!this.isValidCreatingEle(DetectionTool.Rectangle, "detection"))
            return;
        const status = imageLabelStore.status;
        const { creatingElement } = status;
        const { cursorX, cursorY } = creatingElement.initAttribute;
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        Object.assign(creatingElement.attributes, {
            x: Math.min(cursorX, offsetX),
            y: Math.min(cursorY, offsetY),
            width: Math.abs(cursorX - offsetX),
            height: Math.abs(cursorY - offsetY)
        });
    }
    Move_MouseMove(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Rectangle, "detection"))
            return;
        const { selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        const { cursorX, cursorY, x, y } = element.initAttribute;
        const attributes = element.attributes;
        const { movementX, movementY } = PainterDom.getRectMovementOfPainterBGWithLimit({
            movementX: offsetX - cursorX,
            movementY: offsetY - cursorY
        }, {
            x: x,
            y: y,
            width: attributes.width,
            height: attributes.height
        });
        Object.assign(element.attributes, {
            x: x + movementX,
            y: y + movementY
        });
    }
    Move_MouseUp(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Rectangle, "detection"))
            return;
        const { status, selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        if (element) {
            const attributes = element.attributes;
            const currentFrame = imageLabelStore.currentFrame;
            element.detection.trails = element.detection.trails || [];
            const targetPath = element.detection.trails.filter(v => v.frame === currentFrame)[0];
            if (targetPath) {
                targetPath.x = attributes.x / status.zoomRatio;
                targetPath.y = attributes.y / status.zoomRatio;
            }
            else {
                const prevTrail = this.getPrevSiblingTrail(currentFrame);
                element.detection.trails.push({
                    x: attributes.x / status.zoomRatio,
                    y: attributes.y / status.zoomRatio,
                    width: attributes.width / status.zoomRatio,
                    height: attributes.height / status.zoomRatio,
                    inview: prevTrail ? prevTrail.inview : true,
                    cover: prevTrail ? prevTrail.cover : false,
                    frame: currentFrame
                });
                element.detection.trails = element.detection.trails.sort((a, b) => a.frame - b.frame);
            }
        }
    }
    getPrevSiblingTrail(frame) {
        var _a;
        const { selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const trails = element.detection.trails || [];
        for (let i = 0; i < trails.length; i++) {
            if (frame >= trails[i].frame && frame < (((_a = trails[i + 1]) === null || _a === void 0 ? void 0 : _a.frame) || Infinity)) {
                return trails[i];
            }
        }
    }
    Resize_MouseUp(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Rectangle, "detection"))
            return;
        const { status, selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const { zoomRatio } = status;
        const { width, height } = element.attributes;
        if (status.preMode) {
            status.mode = status.preMode;
            status.preMode = PainterMode.UNSET;
        }
        else {
            status.mode = PainterMode.UNSET;
        }
        const currentFrame = imageLabelStore.currentFrame;
        element.detection.trails = element.detection.trails || [];
        const targetPath = element.detection.trails.filter(v => v.frame === currentFrame)[0];
        const attributes = element.attributes;
        if (targetPath) {
            Object.assign(targetPath, {
                x: attributes.x / status.zoomRatio,
                y: attributes.y / status.zoomRatio,
                width: attributes.width / status.zoomRatio,
                height: attributes.height / status.zoomRatio
            });
        }
        else {
            const prevTrail = this.getPrevSiblingTrail(currentFrame);
            element.detection.trails.push({
                x: attributes.x / status.zoomRatio,
                y: attributes.y / status.zoomRatio,
                width: attributes.width / status.zoomRatio,
                height: attributes.height / status.zoomRatio,
                inview: prevTrail ? prevTrail.inview : true,
                cover: prevTrail ? prevTrail.cover : false,
                frame: currentFrame
            });
            element.detection.trails = element.detection.trails.sort((a, b) => a.frame - b.frame);
        }
        if (width / zoomRatio < 8 || height / zoomRatio < 8) {
            if (imageLabelStore.status.curResource.mime === IResource.Mime.Image) {
                status.layerElements = _.filter(status.layerElements, ele => {
                    return !status.selectedElementIds.includes(ele.id);
                });
                status.selectedElementIds = [];
            }
            else if (imageLabelStore.status.curResource.mime === IResource.Mime.Video) {
                const selected = _.filter(status.layerElements, ele => {
                    return status.selectedElementIds.includes(ele.id);
                })[0];
                const currentTrailIdx = _.findIndex(selected.detection.trails || [], { frame: videoLabelStore.currentFrame });
                if (currentTrailIdx > -1) {
                    if (element.detection.trails.length === 1) {
                        status.layerElements = _.filter(status.layerElements, ele => {
                            return !status.selectedElementIds.includes(ele.id);
                        });
                        status.selectedElementIds = [];
                    }
                    else {
                        selected.detection.trails.splice(currentTrailIdx, 1);
                        videoLabelStore.videoStage.drawLabel();
                    }
                }
            }
        }
    }
    Resize_MouseMove(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Rectangle, "detection"))
            return;
        const { status: { resizeMode }, selectedElements } = imageLabelStore;
        const element = selectedElements[0];
        const initAttr = element.initAttribute;
        const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBGWithLimit(event);
        switch (resizeMode) {
            case ResizeMode.RESIZE_NW: {
                const comparePos = {
                    x: initAttr.x + initAttr.width,
                    y: initAttr.y + initAttr.height
                };
                Object.assign(element.attributes, {
                    x: Math.min(offsetX, comparePos.x),
                    y: Math.min(offsetY, comparePos.y),
                    width: Math.abs(offsetX - comparePos.x),
                    height: Math.abs(offsetY - comparePos.y)
                });
                break;
            }
            case ResizeMode.RESIZE_NE: {
                const comparePos = {
                    x: initAttr.x,
                    y: initAttr.y + initAttr.height
                };
                Object.assign(element.attributes, {
                    x: Math.min(offsetX, comparePos.x),
                    y: Math.min(offsetY, comparePos.y),
                    width: Math.abs(offsetX - comparePos.x),
                    height: Math.abs(offsetY - comparePos.y)
                });
                break;
            }
            case ResizeMode.RESIZE_SW: {
                const comparePos = {
                    x: initAttr.x + initAttr.width,
                    y: initAttr.y
                };
                Object.assign(element.attributes, {
                    x: Math.min(offsetX, comparePos.x),
                    y: Math.min(offsetY, comparePos.y),
                    width: Math.abs(offsetX - comparePos.x),
                    height: Math.abs(offsetY - comparePos.y)
                });
                break;
            }
            case ResizeMode.RESIZE_SE: {
                const comparePos = {
                    x: initAttr.x,
                    y: initAttr.y
                };
                Object.assign(element.attributes, {
                    x: Math.min(offsetX, comparePos.x),
                    y: Math.min(offsetY, comparePos.y),
                    width: Math.abs(offsetX - comparePos.x),
                    height: Math.abs(offsetY - comparePos.y)
                });
                break;
            }
            default:
                break;
        }
    }
    keyboard(event) {
        if (!this.isValidSelectOneEle(DetectionTool.Rectangle, "detection"))
            return;
        if (isArrowKey(event.key)) {
            const step = 1;
            if (imageLabelStore.status.selectedElementIds.length) {
                let diffX = 0;
                let diffY = 0;
                if (event.key === "ArrowLeft") {
                    diffX -= step;
                }
                else if (event.key === "ArrowUp") {
                    diffY -= step;
                }
                else if (event.key === "ArrowRight") {
                    diffX += step;
                }
                else if (event.key === "ArrowDown") {
                    diffY += step;
                }
                const { selectedElements } = imageLabelStore;
                const element = selectedElements[0];
                const maxWidth = +imageLabelStore.painterBackground.attr("width") - element.attributes.width;
                const maxHeight = +imageLabelStore.painterBackground.attr("height") - element.attributes.height;
                let x = element.attributes.x + diffX;
                let y = element.attributes.y + diffY;
                if (x < 0) {
                    x = 0;
                }
                else if (x > maxWidth) {
                    x = maxWidth;
                }
                if (y < 0) {
                    y = 0;
                }
                else if (y > maxHeight) {
                    y = maxHeight;
                }
                Object.assign(element.attributes, {
                    x,
                    y
                });
                const { status } = imageLabelStore;
                if (element) {
                    const attributes = element.attributes;
                    const currentFrame = imageLabelStore.currentFrame;
                    element.detection.trails = element.detection.trails || [];
                    const targetPath = element.detection.trails.filter(v => v.frame === currentFrame)[0];
                    if (targetPath) {
                        targetPath.x = attributes.x / status.zoomRatio;
                        targetPath.y = attributes.y / status.zoomRatio;
                    }
                    else {
                        const prevTrail = this.getPrevSiblingTrail(currentFrame);
                        element.detection.trails.push({
                            x: attributes.x / status.zoomRatio,
                            y: attributes.y / status.zoomRatio,
                            width: attributes.width / status.zoomRatio,
                            height: attributes.height / status.zoomRatio,
                            inview: prevTrail ? prevTrail.inview : true,
                            cover: prevTrail ? prevTrail.cover : false,
                            frame: currentFrame
                        });
                        element.detection.trails = element.detection.trails.sort((a, b) => a.frame - b.frame);
                    }
                }
            }
        }
    }
}
export const rectPainter = new RectPainter();
