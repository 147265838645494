import { imageLabelStore } from "../../ImageLabelStore";
import BaseRender from "./base";
import { clearCanvas, drawRect } from "./helper";
export default class RectangleRender extends BaseRender {
    constructor(canvas, cursor, selectionCanvas) {
        super(canvas);
        this.cursor = cursor;
        this.selectionCanvas = selectionCanvas;
        this.cache = null;
        this.rect = { x: 0, y: 0, width: 0, height: 0 };
    }
    render(elements) { }
    getTransformHandlers(rect, size = 4) {
        const { x, y, width, height } = rect;
        return {
            n: [x, y - size / 2, width, size],
            e: [x + width - size / 2, y, size, height],
            s: [x, y + height - size / 2, width, size],
            w: [x - size / 2, y, size, height]
        };
    }
    isInsideTransformHandle(x, y, transformHandle) {
        return (x >= transformHandle[0] &&
            x <= transformHandle[0] + transformHandle[2] &&
            y >= transformHandle[1] &&
            y <= transformHandle[1] + transformHandle[3]);
    }
    getOffset(e) {
        const { offsetLeft, offsetTop } = imageLabelStore;
        const offsetX = e.pageX - this.canvas.getBoundingClientRect().left - offsetLeft;
        const offsetY = e.pageY - this.canvas.getBoundingClientRect().top - offsetTop;
        return {
            offsetX,
            offsetY
        };
    }
    resizeTest(e, elements) {
        const { offsetX, offsetY } = this.getOffset(e);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.detection.rect) {
                const handlers = this.getTransformHandlers(element.detection.rect);
                const directions = Object.keys(handlers);
                for (let j = 0; j < directions.length; j++) {
                    const direction = directions[j];
                    if (this.isInsideTransformHandle(offsetX, offsetY, handlers[direction])) {
                        return {
                            direction,
                            element
                        };
                    }
                }
            }
        }
        const rects = Object.values(imageLabelStore.status.groupRects);
        for (let rect of rects) {
            if (rect) {
                const handlers = this.getTransformHandlers(rect);
                const directions = Object.keys(handlers);
                for (let j = 0; j < directions.length; j++) {
                    const direction = directions[j];
                    if (this.isInsideTransformHandle(offsetX, offsetY, handlers[direction])) {
                        return {
                            direction,
                            rect
                        };
                    }
                }
            }
        }
    }
    onResizeStart(e) {
        this.cache = Object.assign({}, (e.rect ? e.rect : e.element.detection.rect));
    }
    onResize(e) {
        const { current, start, element, rect: _rect } = e;
        const bounding = this.canvas.getBoundingClientRect();
        let startX = start.x - bounding.left - imageLabelStore.offsetLeft;
        let startY = start.y - bounding.top - imageLabelStore.offsetTop;
        let currentX = current.x - bounding.left - imageLabelStore.offsetLeft;
        let currentY = current.y - bounding.top - imageLabelStore.offsetTop;
        if (currentX < 0) {
            currentX = 0;
        }
        else if (currentX > imageLabelStore.backgroundWidth) {
            currentX = imageLabelStore.backgroundWidth;
        }
        if (currentY < 0) {
            currentY = 0;
        }
        else if (currentY > imageLabelStore.backgroundHeight) {
            currentY = imageLabelStore.backgroundHeight;
        }
        const deltaX = currentX - startX;
        const deltaY = currentY - startY;
        const originRect = this.cache;
        const rect = element ? element.detection.rect : _rect;
        if (e.direction) {
            if (e.direction === "n") {
                rect.y = originRect.y + deltaY;
                rect.height = originRect.height - deltaY;
            }
            else if (e.direction === "e") {
                rect.width = originRect.width + deltaX;
            }
            else if (e.direction === "s") {
                rect.height = originRect.height + deltaY;
            }
            else if (e.direction === "w") {
                rect.x = originRect.x + deltaX;
                rect.width = originRect.width - deltaX;
            }
        }
    }
    onSelectStart(e) {
        const x = e.start.x - this.selectionCanvas.getBoundingClientRect().left;
        const y = e.start.y - this.selectionCanvas.getBoundingClientRect().top;
        this.rect.x = x;
        this.rect.y = y;
    }
    onSelect(e) {
        const deltaX = e.current.x - e.start.x;
        const deltaY = e.current.y - e.start.y;
        this.rect.width = deltaX;
        this.rect.height = deltaY;
        clearCanvas(this.selectionCanvas);
        drawRect(this.selectionCanvas, this.rect, "#fff");
    }
    onSelectEnd(e) {
        var _a, _b;
        imageLabelStore.status.selectedPointIndex = [];
        this.rect = fixRectangle(this.rect);
        (_b = (_a = imageLabelStore.status.editingElement) === null || _a === void 0 ? void 0 : _a.attributes.points) === null || _b === void 0 ? void 0 : _b.forEach((p, idx) => {
            if (this.isPointInRectangle([p[0] + imageLabelStore.offsetLeft, p[1] + imageLabelStore.offsetTop], this.rect)) {
                imageLabelStore.status.selectedPointIndex.push(idx);
            }
        });
        clearCanvas(this.selectionCanvas);
        imageLabelStore.painterWrapperRef.rerender();
        this.rect = { x: 0, y: 0, width: 0, height: 0 };
    }
    isPointInRectangle(point, rect) {
        const endX = rect.x + rect.width;
        const endY = rect.y + rect.height;
        return point[0] >= rect.x && point[0] <= endX && point[1] >= rect.y && point[1] <= endY;
    }
}
function fixRectangle({ x, y, width, height }) {
    if (width < 0) {
        x = x + width;
        width = Math.abs(width);
    }
    if (height < 0) {
        y = y + height;
        height = Math.abs(height);
    }
    return { x, y, width, height };
}
