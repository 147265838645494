var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { toJS } from "mobx";
import { Card, PageHeader, Select, DatePicker, Pagination, Button, Skeleton } from "antd";
import { performanceAuditStore } from "./PerformanceAuditStore";
import { observer } from "mobx-react";
import "./PerformanceAudit.less";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
let PerformanceAudit = class PerformanceAudit extends React.Component {
    constructor() {
        super(...arguments);
        this.getCSVHeaders = () => {
            return [
                {
                    label: "计价类型",
                    key: "businessName"
                }
            ].concat(toJS(performanceAuditStore.labelUserColumns).map(item => {
                return {
                    label: item.nickname,
                    key: item.username
                };
            }));
        };
        this.disabledDate = (current) => {
            let { calendarDate } = performanceAuditStore.status;
            if (!calendarDate || calendarDate.length === 0) {
                return false;
            }
            let startTime = calendarDate[0];
            const start = startTime && startTime.format("YYYYMMDD") > current.format("YYYYMMDD");
            const end = startTime &&
                moment(startTime)
                    .add(30, "days")
                    .format("YYYYMMDD") < current.format("YYYYMMDD");
            return start || end;
        };
    }
    componentDidMount() {
        performanceAuditStore.status.calendarDate = [moment().startOf("month"), moment()];
        performanceAuditStore.loadGroups();
        performanceAuditStore.loadUsers();
        performanceAuditStore.loadData();
    }
    render() {
        const status = performanceAuditStore.status;
        return (<PageHeader title="绩效统计" className="performance-audit" style={{ background: "#FFF" }}>
        <Card>
          <div>
            <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
              <div>
                <RangePicker disabledDate={this.disabledDate} value={toJS(status.calendarDate)} onCalendarChange={date => {
            status.calendarDate = date;
        }} onChange={date => {
            status.calendarDate = date;
        }}/>
                <Select showSearch allowClear mode="multiple" placeholder="请选择单位" style={{ width: 240, marginLeft: 10 }} value={toJS(status.curGroupIds)} onChange={value => {
            status.curGroupIds = value;
            status.curUserIds = [];
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                  {status.groups.map(group => (<Select.Option key={group === null || group === void 0 ? void 0 : group.id} title={group.name} value={group === null || group === void 0 ? void 0 : group.id}>
                      {group.name}
                    </Select.Option>))}
                </Select>
                <Select placeholder="请选择用户" mode="multiple" allowClear showSearch value={toJS(status.curUserIds)} style={{ width: 300, marginLeft: 10 }} onChange={value => {
            status.curUserIds = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                  {toJS(performanceAuditStore.filterUsers).map(user => (<Select.Option key={user.id} title={`${user.nickname}(${user.name})`} value={user.id}>
                      {`${user.nickname}(${user.name})`}
                    </Select.Option>))}
                </Select>
                <Button style={{
            marginLeft: 10
        }} icon={<SearchOutlined />} disabled={performanceAuditStore.isUnableSearch} onClick={() => {
            performanceAuditStore.loadData();
        }}></Button>
              </div>

              <CSVLink data={toJS(status.exportCSVData)} filename={status.CSVFilename} headers={this.getCSVHeaders()}>
                <Button type="primary" shape="round" icon={<DownloadOutlined />} disabled={performanceAuditStore.isUnableDownload} onClick={() => performanceAuditStore.downAnnotationAuditFile()}/>
              </CSVLink>
            </div>

            <div className="audit-table-wrapper">
              {status.loading ? (<Skeleton active/>) : (<React.Fragment>
                  <div className="audit-table">
                    <div className="audit-table-body">
                      <table>
                        <thead className="audit-table-thead">
                          <tr>
                            <th>计价类型</th>
                            {(toJS(performanceAuditStore.labelUserColumns) || []).map((column, index) => (<th key={index}>{column.nickname}</th>))}
                          </tr>
                        </thead>
                        <tbody className="audit-table-tbody">
                          {toJS(performanceAuditStore.transformedAnnotation)
            .slice(status.offset, status.offset + status.limit)
            .map((item, index) => (<tr key={index}>
                                <td>{item.businessName || "未分配类型"}</td>
                                {toJS(performanceAuditStore.labelUserColumns).map((column, index) => {
            return item[`${column.username}`] ? (<td key={index}>{item[`${column.username}`].totalWorkload}</td>) : (<td key={index}>
                                      <span>-</span>
                                    </td>);
        })}
                              </tr>))}
                        </tbody>
                      </table>
                    </div>
                    {toJS(performanceAuditStore.transformedAnnotation).length === 0 && (<div className="audit-table-placeholder">
                        <div className="audit-empty audit-empty-normal">
                          <div className="audit-empty-image">
                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                <g fillRule="nonzero" stroke="#D9D9D9">
                                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                  <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <p className="audit-empty-description">暂无数据</p>
                        </div>
                      </div>)}
                  </div>
                  {toJS(performanceAuditStore.transformedAnnotation).length > 0 && (<div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 20
        }}>
                      <Pagination total={performanceAuditStore.transformedAnnotation.length} current={status.offset + 1} pageSize={status.limit} onChange={(page, pageSize) => {
            status.offset = page - 1;
        }}/>
                    </div>)}
                </React.Fragment>)}
            </div>
          </div>
        </Card>
      </PageHeader>);
    }
};
PerformanceAudit = __decorate([
    observer
], PerformanceAudit);
export { PerformanceAudit };
