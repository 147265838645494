var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observer } from "mobx-react";
import * as React from "react";
import { PainterMode, ResizeMode } from "../../../interface/common";
import { imageLabelStore } from "../../ImageLabelStore";
import { isLeftMouseClick } from "../../provider/mouse-button";
import { PainterDom } from "../../provider/painter-dom";
let RectDetection = class RectDetection extends React.Component {
    constructor() {
        super(...arguments);
        this.onMouseDown = event => {
            const status = imageLabelStore.status;
            if (!isLeftMouseClick(event))
                return;
            if (status.mode === PainterMode.DRAW)
                return;
            const { element } = this.props;
            if (status.mode === PainterMode.UNSET) {
                status.mode = PainterMode.MOVE;
                const { offsetX, offsetY } = PainterDom.getOffsetOfPainterBG(event);
                const attributes = element.attributes;
                element.initAttribute = {
                    cursorX: offsetX,
                    cursorY: offsetY,
                    x: attributes.x,
                    y: attributes.y
                };
                status.selectedElementIds = [element.id];
            }
        };
        this.handleResizeModeStart = (event, resizeMode) => {
            if (!isLeftMouseClick(event))
                return;
            PainterDom.hideRuler();
            const status = imageLabelStore.status;
            status.preMode = status.mode;
            const element = this.props.element;
            const attributes = element.attributes;
            imageLabelStore.status.mode = PainterMode.RESIZE;
            imageLabelStore.status.resizeMode = resizeMode;
            imageLabelStore.status.selectedElementIds = [element.id];
            element.initAttribute = {
                x: attributes.x,
                y: attributes.y,
                width: attributes.width,
                height: attributes.height
            };
        };
    }
    render() {
        const { element, showId } = this.props;
        const attributes = element.attributes;
        const { label: { color } } = element;
        const { x, y, width, height } = attributes;
        const polyLineLength = Math.floor(Math.min(width, height) / 3);
        return (<React.Fragment>
        {element.display !== "hidden" && (<React.Fragment>
            {element.visible && (<React.Fragment>
                <rect {...attributes} fill={"transparent"} stroke={color} strokeWidth={2} strokeDasharray="5, 5" onMouseDown={this.onMouseDown}/>
                <svg x={x} y={y} width={width} height={height} style={{ overflow: "visible" }}>
                  <g fill="none" stroke={color} strokeWidth={2}>
                    {element.display === "border" && (<animate attributeType="CSS" attributeName="opacity" from="1" to="0" begin="0.2s" dur="0.5s" repeatCount="indefinite"></animate>)}
                    <polyline className={`bbox-resize ${ResizeMode.RESIZE_NW}`} points={`0,${polyLineLength} 0,0 ${polyLineLength},0`} style={{ cursor: "nw-resize" }} onMouseDown={event => this.handleResizeModeStart(event, ResizeMode.RESIZE_NW)}/>
                    <polyline className={`bbox-resize ${ResizeMode.RESIZE_NE}`} points={`${width - polyLineLength},0 ${width},0 ${width},${polyLineLength}`} style={{ cursor: "ne-resize" }} onMouseDown={event => this.handleResizeModeStart(event, ResizeMode.RESIZE_NE)}/>
                    <polyline className={`bbox-resize ${ResizeMode.RESIZE_SW}`} points={`0,${height - polyLineLength} 0,${height} ${polyLineLength},${height}`} style={{ cursor: "sw-resize" }} onMouseDown={event => this.handleResizeModeStart(event, ResizeMode.RESIZE_SW)}/>
                    <polyline className={`bbox-resize ${ResizeMode.RESIZE_SE}`} points={`${width},${height - polyLineLength} ${width},${height} ${width -
            polyLineLength},${height}`} style={{ cursor: "se-resize" }} onMouseDown={event => this.handleResizeModeStart(event, ResizeMode.RESIZE_SE)}/>
                  </g>
                  {showId && (<foreignObject x="0" y="0" width="100%" height="100%" style={{
            overflow: "visible",
            pointerEvents: "none"
        }}>
                      <div style={{
            color: color,
            position: "absolute",
            left: 0,
            top: -18,
            lineHeight: 1,
            whiteSpace: "nowrap"
        }}>
                        {element.id}
                      </div>
                    </foreignObject>)}
                </svg>
              </React.Fragment>)}
            
          </React.Fragment>)}
      </React.Fragment>);
    }
};
RectDetection.defaultProps = {
    showId: false
};
RectDetection = __decorate([
    observer
], RectDetection);
export { RectDetection };
