import axios from "../util/axios";
export const listGroups = () => {
    return axios.get("/groups");
};
export const getGroup = (groupId) => {
    return axios.get(`/group/${groupId}`);
};
export const createGroup = (params) => {
    return axios.post("/group", params);
};
export const updateGroup = (groupId, params) => {
    return axios.post(`/group/${groupId}`, {
        name: params.name,
        admin: params.admin
    });
};
export const deleteGroup = (groupId) => {
    return axios.delete(`/group/${groupId}`);
};
export const joinGroup = (groupId, userIds) => {
    return axios.post(`/group/join/${groupId}`, {
        userIds: userIds
    });
};
export const leaveGroup = (userIds) => {
    return axios.post(`/group/leave`, {
        userIds: userIds
    });
};
