var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observer } from "mobx-react";
import React from "react";
import SliderBox from "./SliderBox";
import { TimelineContext } from "./timeline";
let SliderBar = class SliderBar extends React.Component {
    render() {
        const { chunks, onDoubleClick, selectedChunkIds, onSelect, onContextMenu, onChange, frameRate } = this.props;
        const { transformValueToOffset, transformOffsetToValue, setValue, end, width } = this.context;
        return (<div className="slider-bar" style={{ width }} onDoubleClick={onDoubleClick}>
        {chunks.map((chunk, idx) => {
            return (<SliderBox visible={chunk.visible} currentId={chunk.id} key={idx} color={chunk.label.color} bounding={{
                left: transformValueToOffset(chunk.detection.value.startTime),
                right: transformValueToOffset(chunk.detection.value.endTime)
            }} selected={selectedChunkIds.includes(chunk.id)} onSelect={() => {
                onSelect(chunk);
            }} onContextMenu={e => {
                onContextMenu(e, chunk);
            }} onDragStart={e => {
                const left = transformValueToOffset(chunk.detection.value.startTime);
                const right = transformValueToOffset(chunk.detection.value.endTime);
                const size = right - left;
                return [left, right, size];
            }} onDragChange={(e, value) => {
                const deltaX = e.currentX - e.startX;
                const size = value[2];
                let startValue = transformOffsetToValue(value[0] + deltaX);
                let endValue = transformOffsetToValue(value[1] + deltaX);
                if (startValue < 0) {
                    startValue = 0;
                    endValue = transformOffsetToValue(size);
                }
                if (endValue > end) {
                    endValue = end;
                    startValue = transformOffsetToValue(transformValueToOffset(endValue) - size);
                }
                const timePerFrame = 1000 / frameRate;
                startValue = Math.floor(startValue / timePerFrame) * timePerFrame;
                endValue = Math.floor(endValue / timePerFrame) * timePerFrame;
                if (deltaX > 0) {
                    setValue(endValue);
                }
                else {
                    setValue(startValue);
                }
                onChange(chunk, startValue, "startTime");
                onChange(chunk, endValue, "endTime");
            }} onResizeStart={e => {
                const time = e.sash === "left" ? chunk.detection.value.startTime : chunk.detection.value.endTime;
                return transformValueToOffset(time);
            }} onResizeChange={(e, startOffset) => {
                let offset, value;
                const field = e.sash === "left" ? "startTime" : "endTime";
                const timePerFrame = 1000 / frameRate;
                if (e.type === "mouse") {
                    const deltaX = e.currentX - e.startX;
                    offset = startOffset + deltaX;
                    value = transformOffsetToValue(offset);
                }
                else if (e.type === "keyboard") {
                    if (e.direction === "left") {
                        value = chunk.detection.value[field] - timePerFrame;
                    }
                    else if (e.direction === "right") {
                        value = chunk.detection.value[field] + timePerFrame;
                    }
                }
                if (e.sash === "left") {
                    if (value > chunk.detection.value.endTime) {
                        value = chunk.detection.value.endTime;
                    }
                    if (value < 0) {
                        value = 0;
                    }
                }
                else if (e.sash === "right") {
                    if (value < chunk.detection.value.startTime) {
                        value = chunk.detection.value.startTime;
                    }
                    if (value > end) {
                        value = end;
                    }
                }
                value = Math.floor(value / timePerFrame) * timePerFrame;
                onChange(chunk, value, field);
                setValue(value, offset);
            }}/>);
        })}
      </div>);
    }
};
SliderBar.contextType = TimelineContext;
SliderBar = __decorate([
    observer
], SliderBar);
export default SliderBar;
