var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Bounding } from "../Bounding/Bounding";
import { Menu, message } from "antd";
import { IResource } from "definition/entity/resource";
import $ from "jquery";
import * as _ from "lodash";
import { observer } from "mobx-react";
import qs from "qs";
import * as React from "react";
import VideoPainter from "../../../../video/VideoLabel/VideoPainter";
import Asset from "../../../asset";
import { PainterMode, PainterMouseEvent } from "../../../interface/common";
import { imageLabelStore } from "../../ImageLabelStore";
import { isLeftMouseClick } from "../../provider/mouse-button";
import { NS } from "../../provider/namespace";
import { PainterDom } from "../../provider/painter-dom";
import { DetectionElement } from "../DetectionElement/DetectionElement";
import { painterWrapperHandler } from "../DetectionElement/painter/painter-wrapper-handler";
import "./PainterWrapper.less";
import { AnchorElement } from "../DetectionElement/anchor/AnchorElement";
import { DetectionTool } from "definition/entity/label-task";
import videoLabelStore from "page/label/video/VideoLabel/VideoLabelStore";
import { SplitViewContext } from "component/SplitView";
import PolygonRender from "../canvas/polygon";
import RectangleRender from "../canvas/rectangle";
import { capitalize, clearCanvas } from "../canvas/helper";
import { toJS } from "mobx";
import Ruler from "./Ruler";
import ComparisonDialog from "./ComparisonDialog";
import ContextMenu from "./ContextMenu";
import { CreateGroupDialog, DeleteGroupDialog, ExitGroupDialog } from "./GroupDialog";
import Cursor from "../canvas/cursor";
import randomColor from "randomcolor";
import Editor3D from "page/label/3d/Editor3D";
let PainterWrapper = class PainterWrapper extends React.Component {
    constructor() {
        super(...arguments);
        this._imgDirty = true;
        this.state = {
            contextMenus: [],
            contextMenuVisible: false,
            contextMenuLeft: 0,
            contextMenuTop: 0,
            createGroupDialogVisible: false,
            deleteGroupDialogVisible: false,
            exitGroupDialogVisible: false,
            comparisonDialogVisible: false,
            maskImageUrl: ""
        };
        this.painterWrapperMouseDown = event => {
            if (!isLeftMouseClick(event))
                return;
            const status = imageLabelStore.status;
            switch (imageLabelStore.status.mode) {
                case PainterMode.UNSET:
                    status.selectedElementIds = [];
                    status.mode = PainterMode.MOVE;
                    status.editingElementId = null;
                    break;
                case PainterMode.DRAW:
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseDown);
                    break;
            }
        };
        this.painterWrapperMouseUp = event => {
            const status = imageLabelStore.status;
            switch (status.mode) {
                case PainterMode.DRAW: {
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseUp);
                    break;
                }
                case PainterMode.RESIZE: {
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseUp);
                    break;
                }
                case PainterMode.MOVE: {
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseUp);
                    status.mode = PainterMode.UNSET;
                    break;
                }
            }
        };
        this.painterWrapperMouseMove = event => {
            const { status } = imageLabelStore;
            const { selectedElementIds } = status;
            switch (imageLabelStore.status.mode) {
                case PainterMode.DRAW: {
                    PainterDom.adjustRuler(event);
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseMove);
                    break;
                }
                case PainterMode.MOVE: {
                    if (!isLeftMouseClick(event))
                        return;
                    if (selectedElementIds.length === 1) {
                        painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseMove);
                    }
                    else {
                        const { movementX, movementY } = event;
                        const { svgCanvas } = imageLabelStore;
                        const workspaceDiv = imageLabelStore.svgCanvas.get()[0];
                        workspaceDiv.scrollTo(svgCanvas.scrollLeft() - movementX, svgCanvas.scrollTop() - movementY);
                    }
                    break;
                }
                case PainterMode.RESIZE: {
                    if (!isLeftMouseClick(event))
                        return;
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.MouseMove);
                }
                case PainterMode.UNSET:
                    imageLabelStore.status.editingElement && PainterDom.adjustRuler(event);
            }
        };
        this.painterWrapperContextMenu = event => {
            const { status } = imageLabelStore;
            switch (status.mode) {
                case PainterMode.DRAW:
                    painterWrapperHandler.handle(event, status.mode, PainterMouseEvent.ContextMenu);
            }
        };
        this.onKeyDown = (e) => {
            painterWrapperHandler.handleKeyboard(e);
            if (e.key === "w" && imageLabelStore.status.curSelectedLabel) {
                this.polygonRender.onDrawUndo();
                this.rerender();
            }
        };
        this.reposition = () => {
            const $canvas = imageLabelStore.svgCanvas.find(".canvas");
            if ($canvas.length) {
                this.resize();
                $canvas.css({
                    top: imageLabelStore.svgCanvas.scrollTop(),
                    left: imageLabelStore.svgCanvas.scrollLeft()
                });
            }
        };
        this.onCanvasMouseDown = (e) => {
            let type = null;
            let tool = null;
            let element = null;
            let pointIndex = null;
            let suggestedPointIndex = null;
            let direction = null;
            let rect = null;
            const start = { x: e.pageX, y: e.pageY };
            const onMouseMove = (e) => {
                const current = { x: e.pageX, y: e.pageY };
                this.onEvent(type, tool, { start, current, origin: e, element, pointIndex, direction, rect });
            };
            const onMouseUp = (e) => {
                const current = { x: e.pageX, y: e.pageY };
                this.onEvent(type + "End", tool, { start, current, origin: e, element, pointIndex, direction, rect });
                window.removeEventListener("mousemove", onMouseMove);
                window.removeEventListener("mouseup", onMouseUp);
            };
            if (imageLabelStore.status.curSelectedLabel) {
                type = "draw";
                tool = "polygon";
            }
            else {
                if (imageLabelStore.status.editingElement) {
                    const ret = this.polygonRender.resizeTest(e);
                    if (ret) {
                        type = "resize";
                        element = ret.element;
                        pointIndex = ret.pointIndex;
                        suggestedPointIndex = ret.suggestedPointIndex;
                        tool = "polygon";
                        if (pointIndex == null && suggestedPointIndex !== null) {
                            this.polygonRender.addPoint({ origin: e, element, suggestedPointIndex });
                            pointIndex = suggestedPointIndex;
                            suggestedPointIndex = null;
                        }
                        imageLabelStore.status.selectedPointIndex = [pointIndex];
                        imageLabelStore.status.selectedSegmentElementId = element.id;
                    }
                    else {
                        imageLabelStore.status.selectedSegmentElementId = null;
                    }
                }
                if (imageLabelStore.selectedElements.length && !type) {
                    const ret = this.rectangleRender.resizeTest(e, imageLabelStore.selectedElements);
                    if (ret) {
                        type = "resize";
                        element = ret.element;
                        direction = ret.direction;
                        tool = "rectangle";
                        rect = ret.rect;
                    }
                }
                if (!type) {
                    const ret = this.polygonRender.dragTest(e);
                    if (ret) {
                        type = "drag";
                        element = ret.element;
                        tool = "polygon";
                    }
                }
                if (!type) {
                    type = "select";
                    tool = "rectangle";
                }
            }
            if (type) {
                this.onEvent(type + "Start", tool, { start, origin: e, element, pointIndex, direction, rect });
                window.addEventListener("mousemove", onMouseMove);
                window.addEventListener("mouseup", onMouseUp);
            }
            else {
                imageLabelStore.status.selectedElementIds = [];
                imageLabelStore.status.editingElementId = null;
                this.rerender();
            }
            this.setState({ contextMenuVisible: false });
        };
        this.onCanvasMouseMove = (e) => {
            if (imageLabelStore.status.mode === PainterMode.DRAW) {
                const { zoomRatio } = imageLabelStore.status;
                const { offsetX, offsetY } = this.polygonRender.getOffset(e);
                const bounding = this.canvas.getBoundingClientRect();
                const text = `(${(offsetX / zoomRatio).toFixed(0)}, ${(offsetY / zoomRatio).toFixed(0)})`;
                imageLabelStore.status.rulerStatus = {
                    x: e.pageX - bounding.left,
                    y: e.pageY - bounding.top,
                    text,
                    width: imageLabelStore.status.canvasWidth,
                    height: imageLabelStore.status.canvasHeight
                };
            }
            if (imageLabelStore.status.creatingElement) {
                const { offsetX, offsetY } = this.polygonRender.getOffset(e);
                imageLabelStore.status.creatingElement.attributes.nextPos = {
                    x: offsetX,
                    y: offsetY
                };
                this.rerender();
            }
            if (imageLabelStore.selectedElements.length) {
                const ret = this.rectangleRender.resizeTest(e, imageLabelStore.selectedElements);
                if (ret) {
                    switch (ret.direction) {
                        case "n":
                        case "s":
                            this.cursor.set("ns-resize");
                            break;
                        case "e":
                        case "w":
                            this.cursor.set("ew-resize");
                            break;
                    }
                }
                else {
                    this.cursor.set("auto");
                }
            }
        };
        this.onCanvasContextMenu = (e) => {
            if (imageLabelStore.status.curSelectedLabel) {
                this.polygonRender.completeDraw();
            }
            else {
                imageLabelStore.selectedElements.forEach(element => {
                    const { offsetX, offsetY } = this.polygonRender.getOffset(e);
                    if (this.polygonRender.isInside(offsetX, offsetY, element.attributes.points)) {
                        this.currentElementOnContextMenu = element;
                        const bounding = this.canvas.getBoundingClientRect();
                        const group = imageLabelStore.group.findGroup(element.id);
                        const menus = [
                            {
                                key: "changeKey",
                                text: "修改分割",
                                children: imageLabelStore.status.selectableLabel.segments.map(v => ({ key: v.key, text: v.key }))
                            },
                            { key: "hidden", text: "隐藏对象" },
                            ...(group ? [{ key: "exitGroup", text: "退出关联组合" }] : []),
                            { key: "delete", text: "删除对象" },
                            { key: "cancel", text: "取消" }
                        ];
                        this.setState({
                            contextMenus: menus,
                            contextMenuVisible: true,
                            contextMenuLeft: e.pageX - bounding.left,
                            contextMenuTop: e.pageY - bounding.top
                        });
                    }
                });
            }
        };
        this.onCanvasDoubleClick = (e) => {
            const ret = this.polygonRender.dragTest(e);
            if (ret) {
                imageLabelStore.status.editingElementId = ret.element.id;
                this.rerender();
            }
        };
        this.onContextMenuClick = (keys) => {
            const action = _.last(keys);
            const key = keys[0];
            switch (action) {
                case "hidden":
                    this.currentElementOnContextMenu.visible = false;
                    break;
                case "delete":
                    const status = imageLabelStore.status;
                    for (const id of status.selectedElementIds) {
                        const groupId = imageLabelStore.group.findGroupId(id);
                        if (groupId == null) {
                            imageLabelStore.group.deleteGroup(groupId);
                            delete imageLabelStore.status.groupRects[groupId];
                        }
                    }
                    status.layerElements = _.filter(status.layerElements, ele => {
                        return !status.selectedElementIds.includes(ele.id);
                    });
                    status.selectedElementIds = [];
                    message.info("该对象已删除");
                    break;
                case "exitGroup":
                    this.setState({
                        exitGroupDialogVisible: true
                    });
                    break;
                case "changeKey":
                    const groupId = imageLabelStore.group.findGroupId(imageLabelStore.selectedElements[0].id);
                    if (groupId) {
                        imageLabelStore.group.deleteGroup(groupId);
                    }
                    imageLabelStore.selectedElements.forEach((el, idx) => {
                        el.id = PainterDom.generateId(key);
                        el.detection.key = key;
                        const toLabel = _.find(imageLabelStore.status.selectableLabel.segments, segment => segment.key === key);
                        el.label = toLabel;
                        el.color = toLabel.type === "mask-semantic" ? toLabel.color : randomColor();
                        el.detection.layer = toLabel.layer;
                        imageLabelStore.status.selectedElementIds[idx] = el.id;
                    });
                    if (groupId) {
                        imageLabelStore.group.createGroup(imageLabelStore.selectedElements);
                    }
                    break;
                case "cancel":
                    break;
            }
            this.setState({
                contextMenuVisible: false
            });
            this.rerender();
        };
        this.resize = () => {
            if (this.canvas) {
                const { workspace, status } = imageLabelStore;
                const { painterBGSize } = status.config;
                const { zoomRatio } = status;
                const canvasWidth = Math.max(workspace.width(), (painterBGSize.width + 240) * zoomRatio);
                const canvasHeight = Math.max(workspace.height(), (painterBGSize.height + 240) * zoomRatio);
                const backgroundWidth = painterBGSize.width * zoomRatio;
                const backgroundHeight = painterBGSize.height * zoomRatio;
                imageLabelStore.status.canvasWidth = canvasWidth;
                imageLabelStore.status.canvasHeight = canvasHeight;
                this.selectionCanvas.width = this.backgroundCanvas.width = this.canvas.width = workspace.width();
                this.selectionCanvas.height = this.backgroundCanvas.height = this.canvas.height = workspace.height();
                const offsetLeft = (canvasWidth - backgroundWidth) / 2;
                const offsetTop = (canvasHeight - backgroundHeight) / 2;
                const $svgCanvas = imageLabelStore.svgCanvas;
                imageLabelStore.offsetLeft = imageLabelStore.hasScrollBar("horizontal")
                    ? -$svgCanvas.scrollLeft() + offsetLeft
                    : offsetLeft;
                imageLabelStore.offsetTop = imageLabelStore.hasScrollBar("vertical")
                    ? -$svgCanvas.scrollTop() + offsetTop
                    : offsetTop;
                imageLabelStore.backgroundWidth = backgroundWidth;
                imageLabelStore.backgroundHeight = backgroundHeight;
                this._imgDirty = true;
                this.rerender(true);
            }
        };
        this.rerender = (renderBackground = false) => {
            Promise.resolve()
                .then(() => {
                if (this.backgroundCanvas && renderBackground) {
                    this._imgDirty = true;
                    const ctx = this.backgroundCanvas.getContext("2d");
                    clearCanvas(this.backgroundCanvas);
                    return this.drawImage(ctx, imageLabelStore.offsetLeft, imageLabelStore.offsetTop);
                }
            })
                .then(() => {
                if (this.canvas) {
                    clearCanvas(this.canvas);
                    const elements = imageLabelStore.status.layerElements;
                    const creatingElement = imageLabelStore.status.creatingElement;
                    const selectedPointIdx = imageLabelStore.status.selectedPointIndex;
                    this.polygonRender.render(elements, creatingElement, selectedPointIdx);
                }
            });
        };
        this.generateMaskImg = () => {
            const canvas = document.createElement("canvas");
            const imgWidth = this._img.width;
            const imgHeight = this._img.height;
            canvas.width = imgWidth;
            canvas.height = imgHeight;
            const elements = toJS(imageLabelStore.status.layerElements);
            const zoomRatio = imageLabelStore.status.zoomRatio;
            elements.forEach(e => (e.attributes.points = e.attributes.points.map(p => [p[0] / zoomRatio, p[1] / zoomRatio])));
            this.polygonRender.renderToMask(elements, canvas);
            const base64 = canvas.toDataURL();
            return base64;
        };
    }
    handleClickSpriteContextmenu(element, key) {
        const status = imageLabelStore.status;
        switch (key) {
            case "hide": {
                element.visible = false;
                status.spriteContextmenu = { visible: false };
                status.selectedElementIds = [];
                break;
            }
            case "delete":
                _.remove(status.layerElements, ele => ele.id === element.id);
                status.spriteContextmenu = { visible: false };
                break;
            case "cancel":
                status.spriteContextmenu = { visible: false };
                break;
            default: {
                status.spriteContextmenu = { visible: false };
                if (key.startsWith("select_")) {
                    if (element.label.type === "anchor") {
                        const anchorKey = key.replace("select_", "");
                        if (anchorKey !== element.label.key) {
                            const toLabel = _.find(status.selectableLabel.anchors, anchor => anchor.key === anchorKey);
                            element.label = toLabel;
                            element.id = PainterDom.generateId(toLabel.key, 2);
                            element.detection.key = toLabel.key;
                        }
                    }
                    else if (element.label.type === "detection") {
                        const detectionKey = key.replace("select_", "");
                        if (detectionKey !== element.label.key) {
                            const fromLabel = element.label;
                            const toLabel = _.find(status.selectableLabel.detections, detection => detection.key === detectionKey);
                            const fromClsMap = new Map(), toClsMap = new Map();
                            fromLabel.classifications.forEach(cls => {
                                var _a;
                                fromClsMap.set(cls.key, {
                                    tool: cls.tool,
                                    value: (_a = _.find(element.detection.classifications, detectionCls => detectionCls.key === cls.key)) === null || _a === void 0 ? void 0 : _a.value
                                });
                            });
                            toLabel.classifications.forEach(cls => {
                                toClsMap.set(cls.key, {
                                    tool: cls.tool,
                                    value: null
                                });
                            });
                            element.label = toLabel;
                            element.id = PainterDom.generateId(toLabel.key);
                            element.detection.key = toLabel.key;
                            element.detection.classifications = toLabel.classifications.map(item => {
                                const fromCls = fromClsMap.get(item.key), toCls = toClsMap.get(item.key);
                                if (fromCls && toCls && fromCls.tool === toCls.tool) {
                                    return { key: item.key, value: fromCls === null || fromCls === void 0 ? void 0 : fromCls.value };
                                }
                                else {
                                    return {
                                        key: item.key,
                                        value: null
                                    };
                                }
                            });
                        }
                    }
                }
                break;
            }
        }
    }
    isVideo() {
        return imageLabelStore.status.curResource.mime === IResource.Mime.Video;
    }
    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
        this.cursor = new Cursor();
        this.rectangleRender = new RectangleRender(this.canvas, this.cursor, this.selectionCanvas);
        this.polygonRender = new PolygonRender(this.canvas, this.rectangleRender);
        if (imageLabelStore.svgCanvas) {
            imageLabelStore.svgCanvas.on("scroll", this.reposition);
        }
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
    }
    onEvent(type, tool, data) {
        var _a;
        const render = this[tool + "Render"];
        if (render) {
            (_a = render["on" + capitalize(type)]) === null || _a === void 0 ? void 0 : _a.call(render, data);
            this.rerender();
        }
    }
    drawImage(ctx, offsetLeft, offsetTop) {
        const { painterBGSize } = imageLabelStore.status.config;
        const { zoomRatio, curResource, brightness, contrast } = imageLabelStore.status;
        const backgroundWidth = painterBGSize.width * zoomRatio;
        const backgroundHeight = painterBGSize.height * zoomRatio;
        const draw = () => {
            ctx.filter = `brightness(${1 + brightness / 100}) contrast(${1 + contrast / 100})`;
            ctx.drawImage(this._img, offsetLeft, offsetTop, backgroundWidth, backgroundHeight);
        };
        return new Promise(resolve => {
            if (this._img) {
                if (this._imgDirty === true) {
                    draw();
                    this._imgDirty = false;
                }
                resolve(null);
            }
            else {
                const img = document.createElement("img");
                const url = new URL(curResource.url);
                url.searchParams.append("_a", `${Date.now()}`);
                img.src = url.toString();
                img.setAttribute("crossOrigin", "anonymous");
                img.onload = () => {
                    draw();
                    resolve(null);
                };
                this._img = img;
            }
        });
    }
    showComparisonDialog() {
        const dataUrl = this.generateMaskImg();
        this.setState({
            comparisonDialogVisible: true,
            maskImageUrl: dataUrl
        });
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        const status = imageLabelStore.status;
        const index = qs.parse(location.search.slice(1)).index;
        const mime = ((_a = status.curResource) === null || _a === void 0 ? void 0 : _a.mime) === IResource.Mime.Video ? "video" : "image";
        const detections = status.layerElements.filter(e => e.label.type === "detection");
        const anchors = status.layerElements.filter(e => e.label.type === "anchor");
        const selectedElement = imageLabelStore.selectedElements[0];
        const text = (selectedElement === null || selectedElement === void 0 ? void 0 : selectedElement.label.type) === "detection" ? "检测" : "锚点";
        const hasSegment2D = !!((_b = status.selectableLabel.segments) === null || _b === void 0 ? void 0 : _b.length);
        const hasSegment3D = !!((_c = status.selectableLabel.segments3D) === null || _c === void 0 ? void 0 : _c.length);
        const is3D = !!((_d = status.selectableLabel.segments3D) === null || _d === void 0 ? void 0 : _d.length) || !!((_e = status.selectableLabel.detections3D) === null || _e === void 0 ? void 0 : _e.length);
        return (<div style={{ width: "100%", height: "100%" }}>
        {hasSegment2D ? (<React.Fragment>
            <div className="scroll-container" ref={el => {
            imageLabelStore.svgCanvas = $(el);
        }}>
              <div style={{ width: imageLabelStore.status.canvasWidth, height: imageLabelStore.status.canvasHeight }}></div>
              <div className="canvas" onMouseDown={this.onCanvasMouseDown} onMouseMove={this.onCanvasMouseMove} onContextMenu={this.onCanvasContextMenu} onDoubleClick={this.onCanvasDoubleClick}>
                <canvas id="background" className="background" ref={el => (this.backgroundCanvas = el)}></canvas>
                <canvas className="elements" ref={el => {
            this.canvas = el;
        }}></canvas>
                <canvas className="selection" ref={el => (this.selectionCanvas = el)}></canvas>
              </div>
            </div>
            {status.mode === PainterMode.DRAW && status.curSelectedLabel.tool !== DetectionTool.Chunk && (<Ruler {...status.rulerStatus} color={imageLabelStore.status.curSelectedLabel.color}></Ruler>)}
          </React.Fragment>) : is3D ? (<Editor3D ref={el => (imageLabelStore.editor3DRef = el)} width={this.context.size} height={window.innerHeight - 80} cursor={this.cursor} url={(_f = imageLabelStore.status.curResource) === null || _f === void 0 ? void 0 : _f.url} pcdUrl={(_h = (_g = imageLabelStore.status.curResource) === null || _g === void 0 ? void 0 : _g.pcd) === null || _h === void 0 ? void 0 : _h.url} pointSize={imageLabelStore.status.pointSize} selector={imageLabelStore.status.tool3D} mode={imageLabelStore.status.mode3D} label={imageLabelStore.status.curSelectedLabel} segments3D={imageLabelStore.status.segments3D} segments3DLabels={imageLabelStore.status.selectableLabel.segments3D} onChange={value => {
            imageLabelStore.status.segments3D = value;
            imageLabelStore.labelDisplay.forceUpdate();
        }} segments={toJS((_k = (_j = imageLabelStore.status.curResource) === null || _j === void 0 ? void 0 : _j.annotation) === null || _k === void 0 ? void 0 : _k.segments)} resource={toJS(imageLabelStore.status.curResource)} detections3DLabels={imageLabelStore.status.selectableLabel.detections3D} selectedBoxId={toJS(imageLabelStore.status.selectedBoxId)} boxes={toJS(imageLabelStore.status.boxes)} boxFaceProjection={toJS(imageLabelStore.status.boxFaceProjection)} boxVectorsProjection={imageLabelStore.status.boxVectorsProjection} params={toJS((_l = imageLabelStore.status.curResource) === null || _l === void 0 ? void 0 : _l.pcd.params)}></Editor3D>) : (<div className="svg-canvas-wrapper" onMouseMove={event => this.painterWrapperMouseMove(event)} onMouseUp={event => this.painterWrapperMouseUp(event)}>
            <div className={"svg-canvas " + mime} ref={el => (imageLabelStore.svgCanvas = $(el))}>
              <div id="svgWrapper" ref={ref => (imageLabelStore.painterWrapper = $(ref))}>
                <svg id="svgRoot" x={0} y={0} xmlns={NS.SVG} xmlnsXlink={NS.XLINK} style={{ width: "100%", height: "100%", transform: "translateZ(0px)" }} ref={ref => (imageLabelStore.painterRoot = $(ref))} onMouseDown={event => this.painterWrapperMouseDown(event)} onMouseDownCapture={event => {
            var _a;
            if (((_a = status.spriteContextmenu) === null || _a === void 0 ? void 0 : _a.visible) === true) {
                status.spriteContextmenu = { visible: false };
                if (status.mode === PainterMode.DRAW) {
                    event.stopPropagation();
                }
            }
        }} onContextMenu={event => this.painterWrapperContextMenu(event)}>
                  <svg id="svgBackground" ref={ref => (imageLabelStore.painterBackground = $(ref))}>
                    {!status.isLoading && (<image x={0} y={0} width="100%" xlinkHref={imageLabelStore.status.curResource.mime == IResource.Mime.Image
            ? _.get(imageLabelStore.status.curResource, "url").replaceAll("#", "%23")
            : _.get(imageLabelStore.status.curResource, "dataUrl").replaceAll("#", "%23")}/>)}
                  </svg>
                  <svg id="svgContent" style={{ overflow: "visible" }} ref={ref => (imageLabelStore.painterContent = $(ref))}>
                    {detections
            .filter(layerElement => !status.selectedElementIds.includes(layerElement.id))
            .map(element => (<React.Fragment key={element.id}>
                          <DetectionElement key={"layer" + element.id} element={element} showId={this.isVideo() ? true : false}/>
                        </React.Fragment>))}
                    {detections
            .filter(layerElement => status.selectedElementIds.includes(layerElement.id))
            .map(element => (<React.Fragment key={element.id}>
                          <DetectionElement key={"layer-selected" + element.id} element={element}/>
                          {<Bounding key={"bounding" + element.id} element={element}/>}
                        </React.Fragment>))}

                    {anchors
            .filter(anchor => !status.selectedElementIds.includes(anchor.id))
            .map(element => (<AnchorElement key={"layer" + element.id} element={element}/>))}
                    {anchors
            .filter(anchor => status.selectedElementIds.includes(anchor.id))
            .map(element => (<React.Fragment key={element.id}>
                          <AnchorElement key={"layer-selected" + element.id} element={element}/>
                          {<Bounding key={"bounding" + element.id} element={element}/>}
                        </React.Fragment>))}

                    {status.creatingElement && (<DetectionElement showId={this.isVideo() ? true : false} element={status.creatingElement}/>)}
                  </svg>
                  {((status.mode === PainterMode.DRAW && status.curSelectedLabel.tool !== DetectionTool.Chunk) ||
            !!status.editingElement) && (<svg id="svgRuler" style={{ pointerEvents: "none" }}>
                      <line id="vRuler" x1={status.rulerStatus.x} y1={0} x2={status.rulerStatus.x} y2="100%" stroke={((_m = status.curSelectedLabel) === null || _m === void 0 ? void 0 : _m.color) || ((_o = status.editingElement.label) === null || _o === void 0 ? void 0 : _o.color)} strokeDasharray="5, 5"/>
                      <line id="hRuler" x1={0} y1={status.rulerStatus.y} x2="100%" y2={status.rulerStatus.y} stroke={((_p = status.curSelectedLabel) === null || _p === void 0 ? void 0 : _p.color) || ((_q = status.editingElement.label) === null || _q === void 0 ? void 0 : _q.color)} strokeDasharray="5, 5"/>
                      <text id="rulerText" x={status.rulerStatus.x + 5} y={status.rulerStatus.y - 5} fill={((_r = status.curSelectedLabel) === null || _r === void 0 ? void 0 : _r.color) || ((_s = status.editingElement.label) === null || _s === void 0 ? void 0 : _s.color)}>
                        {status.rulerStatus.text}
                      </text>
                    </svg>)}
                </svg>

                {((_t = status.spriteContextmenu) === null || _t === void 0 ? void 0 : _t.visible) && (<Menu mode="vertical" style={{
            position: "absolute",
            left: status.spriteContextmenu.left,
            top: status.spriteContextmenu.top,
            borderRadius: "5px"
        }} onClick={e => {
            this.handleClickSpriteContextmenu(status.spriteContextmenu.element, e.key);
            e.domEvent.stopPropagation();
        }}>
                    <Menu.SubMenu title={`修改${text}`}>
                      {(_u = imageLabelStore.status.selectableLabel.detections) === null || _u === void 0 ? void 0 : _u.filter(detection => {
            var _a, _b, _c;
            if (((_a = status.spriteContextmenu.element.label) === null || _a === void 0 ? void 0 : _a.tool) === DetectionTool.Path) {
                if ((_b = status.spriteContextmenu.element.label) === null || _b === void 0 ? void 0 : _b.setPoint) {
                    return (detection.tool === status.spriteContextmenu.element.label.tool && (detection === null || detection === void 0 ? void 0 : detection.setPoint) &&
                        (detection === null || detection === void 0 ? void 0 : detection.points) === ((_c = status.spriteContextmenu.element.label) === null || _c === void 0 ? void 0 : _c.points));
                }
                else {
                    return (!(detection === null || detection === void 0 ? void 0 : detection.setPoint) && detection.tool === status.spriteContextmenu.element.label.tool);
                }
            }
            else {
                return detection.tool === status.spriteContextmenu.element.label.tool;
            }
        }).map(detection => (<Menu.Item key={"select_" + detection.key}>{detection.locale || detection.key}</Menu.Item>))}
                      {(_v = imageLabelStore.status.selectableLabel.anchors) === null || _v === void 0 ? void 0 : _v.filter(anchor => anchor.tool === status.spriteContextmenu.element.label.tool).map(acnhor => (<Menu.Item key={"select_" + acnhor.key}>{acnhor.locale || acnhor.key}</Menu.Item>))}
                    </Menu.SubMenu>
                    <Menu.Item key="hide">隐藏{text}框</Menu.Item>
                    <Menu.Item key="delete">删除{text}框</Menu.Item>
                    <Menu.Item key="cancel">取消</Menu.Item>
                  </Menu>)}
              </div>
            </div>

            {((_w = status.curResource) === null || _w === void 0 ? void 0 : _w.mime) === IResource.Mime.Video && (<VideoPainter width={this.context.size} key={index} url={_.get(imageLabelStore.status.curResource, "url")}></VideoPainter>)}
            {imageLabelStore.status.isLoading && (<div className="loading-mask">
                <div className="inner">
                  <p>{Math.floor(imageLabelStore.status.progress * 100) + "%"}</p>
                  <img alt="" src={Asset.loadingSVG}/>
                </div>
              </div>)}
            {!videoLabelStore.drawable && (<div className="mask" style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "calc(100% - 1.44rem)" }}></div>)}
          </div>)}

        <ComparisonDialog open={this.state.comparisonDialogVisible} aUrl={(_x = status.curResource) === null || _x === void 0 ? void 0 : _x.url} bUrl={this.state.maskImageUrl} onCancel={() => {
            this.setState({ comparisonDialogVisible: false });
        }}></ComparisonDialog>
        <ContextMenu menus={this.state.contextMenus} visible={this.state.contextMenuVisible} left={this.state.contextMenuLeft} top={this.state.contextMenuTop} onClick={this.onContextMenuClick}></ContextMenu>
        <CreateGroupDialog open={this.state.createGroupDialogVisible} onCancel={() => {
            this.setState({ createGroupDialogVisible: false });
        }} onOk={() => {
            imageLabelStore.history.push({
                path: "status.layerElements",
                getValue: (oz, cz, ov) => {
                    const els = ov || toJS(status.layerElements);
                    els.forEach(el => {
                        el.attributes.points.forEach(p => {
                            p[0] = (p[0] * cz) / oz;
                            p[1] = (p[1] * cz) / oz;
                        });
                    });
                    return els;
                },
                group: imageLabelStore.group.get()
            });
            const groupIds = imageLabelStore.selectedElements.map(el => imageLabelStore.group.findGroupId(el.id));
            _.uniq(_.compact(groupIds)).forEach(groupId => {
                imageLabelStore.group.deleteGroup(groupId);
            });
            imageLabelStore.group.createGroup(imageLabelStore.selectedElements);
            this.setState({ createGroupDialogVisible: false });
            this.rerender();
        }}></CreateGroupDialog>
        <DeleteGroupDialog open={this.state.deleteGroupDialogVisible} onCancel={() => {
            this.setState({ deleteGroupDialogVisible: false });
        }} onOk={() => {
            const groupId = imageLabelStore.group.findGroupId(imageLabelStore.selectedElements[0].id);
            imageLabelStore.group.deleteGroup(groupId);
            this.setState({ deleteGroupDialogVisible: false });
            this.rerender();
        }}></DeleteGroupDialog>
        <ExitGroupDialog open={this.state.exitGroupDialogVisible} onCancel={() => this.setState({ exitGroupDialogVisible: false })} onOk={() => {
            this.setState({ exitGroupDialogVisible: false });
            imageLabelStore.group.exitGroup(this.currentElementOnContextMenu.id);
            this.rerender();
        }}></ExitGroupDialog>
      </div>);
    }
};
PainterWrapper.contextType = SplitViewContext;
PainterWrapper = __decorate([
    observer
], PainterWrapper);
export { PainterWrapper };
