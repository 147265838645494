var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import BasicStore from "../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../api/Api";
import { DailyWork } from "definition/entity/daily-work";
class Status {
    constructor() {
        this.fillDate = undefined;
        this.works = [];
        this.modifyDailyWork = undefined;
        this.isLoading = true;
        this.showCreateModal = false;
        this.showModifyModal = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "fillDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "works", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof DailyWork !== "undefined" && DailyWork.AsObject) === "function" ? _a : Object)
], Status.prototype, "modifyDailyWork", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "showCreateModal", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "showModifyModal", void 0);
class DailyWorkStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const worksRes = await Api.dailyWork.getDailyWorks({ fillDate: this.status.fillDate });
        this.status.works = worksRes.data;
        this.status.isLoading = false;
    }
    async createDailyWork(params) {
        const createRes = await Api.dailyWork.createDailyWork(params);
        if (createRes.code === 200) {
            await this.loadData();
            this.status.showCreateModal = false;
        }
    }
    async updateDailyWork(dailyWorkId, params) {
        const updateRes = await Api.dailyWork.updateDailyWork(dailyWorkId, params);
        if (updateRes.code === 200) {
            await this.loadData();
            this.status.showModifyModal = false;
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], DailyWorkStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], DailyWorkStore.prototype, "resetStatus", null);
export const dailyWorkStore = new DailyWorkStore();
