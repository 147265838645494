var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, observable } from "mobx";
import { AnchorTool, ClassificationTool, DetectionTool } from "../../../../definition/entity/label-task";
import BasicStore from "../../../../store/BasicStore";
import { Color } from "../../../../util/color";
import { randomString } from "../../../../util/random-string";
export const genDefaultDetectTool = (tool = DetectionTool.Rectangle) => {
    return {
        uniqKey: `detection_${randomString()}_${new Date().getUTCSeconds()}`,
        type: "detection",
        key: null,
        color: Color.nextColor(),
        locale: null,
        setPoint: false,
        points: undefined,
        tool,
        classifications: []
    };
};
export const genDefaultClassification = () => {
    return {
        uniqKey: `classification_${randomString()}_${new Date().getUTCSeconds()}`,
        type: "classification",
        key: null,
        locale: null,
        tool: ClassificationTool.Switch,
        options: [],
        defaultValue: false
    };
};
export const genDefaultOption = () => {
    return {
        uniqKey: `option_${randomString()}_${new Date().getUTCSeconds()}`,
        key: "",
        color: Color.nextColor(),
        locale: ""
    };
};
export const genDefaultAnchor = () => {
    return {
        uniqKey: `anchor_${randomString()}_${new Date().getUTCSeconds()}`,
        type: "anchor",
        key: null,
        color: Color.nextColor(),
        locale: null,
        tool: AnchorTool.Dot
    };
};
export const genDefaultSegment = () => {
    return {
        uniqKey: `segment_${randomString()}_${new Date().getUTCSeconds()}`,
        type: "mask-instance",
        key: null,
        color: Color.nextColor(),
        locale: null,
        layer: 0,
        tool: "path",
        classifications: []
    };
};
export const genDefaultSegment3D = () => {
    return {
        uniqKey: `segment_3d_${randomString()}_${new Date().getUTCSeconds()}`,
        key: null,
        color: Color.nextColor(),
        locale: null,
        type: "mask-semantic"
    };
};
export const genDefaultDetection3D = () => {
    return {
        uniqKey: `detection_3d_${randomString()}_${new Date().getUTCSeconds()}`,
        type: "detection3D",
        key: null,
        color: Color.nextColor(),
        locale: null,
        tool: "box"
    };
};
class Status {
    constructor() {
        this.detections = [];
        this.classifications = [];
        this.anchors = [];
        this.segments = [];
        this.segments3D = [];
        this.detections3D = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "detections", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "classifications", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "anchors", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "segments", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "segments3D", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "detections3D", void 0);
class LabelEditorStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    isValidatePass() {
        try {
            this.status.detections.forEach(detection => {
                this.checkDetection(detection);
            });
            this.status.classifications.forEach(classification => {
                this.checkClassification(classification);
            });
            this.status.anchors.forEach(anchor => {
                this.checkAnchor(anchor);
            });
            this.status.segments.forEach(segment => {
                this.checkSegment(segment);
            });
            this.status.segments3D.forEach(segment => {
                this.checkSegment(segment);
            });
        }
        catch (e) {
            return [false, e.message];
        }
        return [true, null];
    }
    checkClassification(classification) {
        const reg = /\s/;
        if (!classification.key || reg.test(classification.key)) {
            throw new Error("属性 key 不能为空，并且不能包含空格");
        }
        (classification.options || []).forEach(option => {
            if (!option.key || reg.test(option.key)) {
                throw new Error("选项 key 不能为空，并且不能包含空格");
            }
        });
        const optionKeys = (classification.options || []).map(i => i.key);
        if (new Set(optionKeys).size !== optionKeys.length) {
            throw new Error("选项 key 不能存在重复的值");
        }
    }
    checkDetection(detection) {
        const reg = /\s/;
        if (!detection.key || reg.test(detection.key)) {
            throw new Error("检测 key 不能为空，并且不能包含空格");
        }
        if (detection.tool === DetectionTool.Path && detection.setPoint && !detection.points) {
            throw new Error("标注点数不能为空");
        }
        detection.classifications.forEach(classification => {
            this.checkClassification(classification);
        });
    }
    checkAnchor(anchor) {
        const reg = /\s/;
        if (!anchor.key || reg.test(anchor.key)) {
            throw new Error("锚点 key 不能为空，并且不能包含空格");
        }
    }
    checkSegment(segment) {
        const reg = /\s/;
        if (!segment.key || reg.test(segment.key)) {
            throw new Error("标注 key 不能为空，并且不能包含空格");
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelEditorStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelEditorStore.prototype, "resetStatus", null);
export const labelEditorStore = new LabelEditorStore();
