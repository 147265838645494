import { IResource } from "definition/entity/resource";
import { imageLabelStore } from "../ImageLabelStore";
import { genRectFromPolygon } from "../component/canvas/helper";
import { find } from "lodash";
export class AnnotationParser {
    static RectangleParser(painterElement) {
        var _a;
        const attributes = painterElement.attributes;
        const { x, y, width, height } = attributes;
        const { zoomRatio } = imageLabelStore.status;
        return Object.assign({ id: painterElement.id, key: painterElement.label.key, tool: painterElement.label.tool, value: {
                x: x / zoomRatio,
                y: y / zoomRatio,
                width: width / zoomRatio,
                height: height / zoomRatio
            }, labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId, labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime, labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime, isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset, classifications: ((_a = painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection) === null || _a === void 0 ? void 0 : _a.classifications) || [] }, (imageLabelStore.status.mime === IResource.Mime.Video ? { trails: painterElement.detection.trails } : null));
    }
    static DotParser(painterElement) {
        var _a;
        const attributes = painterElement.attributes;
        const { cx, cy } = attributes;
        const { zoomRatio } = imageLabelStore.status;
        return Object.assign({ id: painterElement.id, key: painterElement.label.key, tool: painterElement.label.tool, value: {
                cx: cx / zoomRatio,
                cy: cy / zoomRatio
            }, labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId, labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime, labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime, isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset, classifications: ((_a = painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection) === null || _a === void 0 ? void 0 : _a.classifications) || [] }, (imageLabelStore.status.mime === IResource.Mime.Video ? { trails: painterElement.detection.trails } : null));
    }
    static PathParser(painterElement) {
        var _a;
        const attributes = painterElement.attributes;
        const { points } = attributes;
        const { zoomRatio } = imageLabelStore.status;
        return {
            id: painterElement.id,
            key: painterElement.label.key,
            tool: painterElement.label.tool,
            value: points.map(point => [point[0] / zoomRatio, point[1] / zoomRatio]),
            labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId,
            labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime,
            labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime,
            isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset,
            classifications: ((_a = painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection) === null || _a === void 0 ? void 0 : _a.classifications) || []
        };
    }
    static SegmentPathParser(painterElement) {
        var _a, _b, _c, _d;
        const attributes = painterElement.attributes;
        const { points } = attributes;
        const { zoomRatio } = imageLabelStore.status;
        const rect = painterElement.detection.rect ||
            imageLabelStore.status.groupRects[imageLabelStore.group.findGroupId(painterElement.id)] ||
            genRectFromPolygon(points);
        const { x, y, width, height } = rect;
        return {
            id: painterElement.id,
            key: painterElement.label.key,
            tool: painterElement.label.tool,
            value: points.map(point => [point[0] / zoomRatio, point[1] / zoomRatio]),
            labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId,
            labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime,
            labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime,
            isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset,
            layer: (_a = painterElement.detection.layer) !== null && _a !== void 0 ? _a : painterElement.detection.layer,
            type: (_b = painterElement.detection.type) !== null && _b !== void 0 ? _b : painterElement.detection.type,
            featureId: (_c = painterElement.detection.featureId) !== null && _c !== void 0 ? _c : painterElement.detection.featureId,
            rect: {
                x: x / zoomRatio,
                y: y / zoomRatio,
                width: width / zoomRatio,
                height: height / zoomRatio
            },
            color: painterElement.color,
            classifications: ((_d = painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection) === null || _d === void 0 ? void 0 : _d.classifications) || []
        };
    }
    static parseAnchorDot(painterElement) {
        const attributes = painterElement.attributes;
        const { cx, cy } = attributes;
        const { zoomRatio } = imageLabelStore.status;
        return {
            id: painterElement.id,
            key: painterElement.label.key,
            tool: painterElement.label.tool,
            value: {
                cx: cx / zoomRatio,
                cy: cy / zoomRatio
            },
            labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId,
            labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime,
            labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime,
            isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset,
            trails: painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection.trails
        };
    }
    static parseChunk(painterElement) {
        var _a;
        const value = painterElement.detection.value;
        return {
            id: painterElement.id,
            key: painterElement.label.key,
            tool: painterElement.label.tool,
            value: {
                startTime: Math.floor(value.startTime),
                startFrame: Math.floor(value.startFrame),
                endTime: Math.floor(value.endTime),
                endFrame: Math.floor(value.endFrame)
            },
            labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId,
            labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime,
            labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime,
            isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset,
            trails: [],
            classifications: ((_a = painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection) === null || _a === void 0 ? void 0 : _a.classifications) || []
        };
    }
    static parseBox(painterElement) {
        var _a, _b;
        const { zoomRatio } = imageLabelStore.status;
        const { attributes: { points: _points, orientation }, detection: { classifications } } = painterElement;
        const [p0, p1, p2, p3, p4, p5, p6, p7] = _points.map(v => [v[0] / zoomRatio, v[1] / zoomRatio]);
        const face = ((_a = find(classifications, { key: "face" })) === null || _a === void 0 ? void 0 : _a.value) || "back";
        let points = null;
        switch ("n") {
            case "n":
                if (face === "front") {
                    points = p1[0] > p0[0] ? [p1, p2, p0, p3, p5, p6, p4, p7] : [p0, p3, p1, p2, p4, p7, p5, p6];
                }
                else {
                    points = p1[0] < p0[0] ? [p1, p2, p0, p3, p5, p6, p4, p7] : [p0, p3, p1, p2, p4, p7, p5, p6];
                }
                break;
        }
        return {
            id: painterElement.id,
            key: painterElement.label.key,
            tool: painterElement.label.tool,
            value: points,
            labelUserId: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUserId,
            labelTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelTime,
            labelUpdateTime: painterElement === null || painterElement === void 0 ? void 0 : painterElement.labelUpdateTime,
            isPreset: painterElement === null || painterElement === void 0 ? void 0 : painterElement.isPreset,
            classifications: ((_b = painterElement === null || painterElement === void 0 ? void 0 : painterElement.detection) === null || _b === void 0 ? void 0 : _b.classifications) || [],
            _orientation: orientation
        };
    }
}
