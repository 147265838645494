var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { Checkbox, Input, Radio, Switch } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ClassificationTool } from "../../../../../../definition/entity/label-task";
import { KeymapEditor } from "../KeymapEditor/keymapEditor";
import { keymapStore } from "../../provider/keymap-store";
import { EventBlock } from "../../../../../../component/EventBlock/EventBlock";
import { toJS } from "mobx";
import { imageLabelStore } from "../../ImageLabelStore";
let ClassificationSelector = class ClassificationSelector extends React.Component {
    getClassificationVal(labelKey) {
        var _a;
        return (_a = toJS(imageLabelStore.status).resourceClassification[labelKey]) === null || _a === void 0 ? void 0 : _a.value;
    }
    setClassificationVal(labelKey, newVal) {
        imageLabelStore.status.resourceClassification[labelKey] = {
            value: newVal,
            tool: null,
            labelUserId: null,
            labelTime: null,
            labelUpdateTime: null,
            isPreset: null
        };
    }
    render() {
        var _a, _b;
        const { label } = this.props;
        return (<div key={label.key} className="classification">
        <div className="label-item">
          <QuestionCircleOutlined style={{ fontSize: "20px" }}/>
          <span>{label.locale || label.key}</span>
          <div />
        </div>

        {label.tool === ClassificationTool.Switch && (<div className="label-item">
            <div />
            <Switch style={{ width: "min-content" }} checked={this.getClassificationVal(label.key)} onChange={checked => {
            this.setClassificationVal(label.key, checked);
        }}/>
            <div />
          </div>)}

        {label.tool === ClassificationTool.Radio && (<Radio.Group style={{ display: "list-item" }} value={this.getClassificationVal(label.key)} onChange={event => {
            this.setClassificationVal(label.key, event.target.value);
        }}>
            {(_a = label.options) === null || _a === void 0 ? void 0 : _a.map(option => (<div key={option.key} className="label-item">
                <div />
                <Radio value={option.key}>{option.locale || option.key}</Radio>
                <KeymapEditor keyStr={keymapStore.getKeyByActionTag(`classification_${label.key}_${option.key}`)} onChange={keyStr => {
            keymapStore.register({
                key: keyStr,
                actionTag: `classification_${label.key}_${option.key}`,
                action: () => this.setClassificationVal(label.key, option.key)
            });
        }}/>
              </div>))}
          </Radio.Group>)}

        {label.tool === ClassificationTool.Checkbox && (<Checkbox.Group style={{ display: "list-item" }} value={this.getClassificationVal(label.key)} onChange={checked => {
            this.setClassificationVal(label.key, checked);
        }}>
            {(_b = label.options) === null || _b === void 0 ? void 0 : _b.map(option => (<div key={option.key} className="label-item">
                <div />
                <Checkbox value={option.key}>{option.locale || option.key}</Checkbox>
              </div>))}
          </Checkbox.Group>)}

        {label.tool === ClassificationTool.Text && (<div className="label-item" style={{
            gridTemplateColumns: "0.2rem 1fr"
        }}>
            <div />
            <EventBlock blockEvent="keydown">
              <Input value={this.getClassificationVal(label.key)} onChange={event => {
            this.setClassificationVal(label.key, event.target.value);
        }}/>
            </EventBlock>
          </div>)}
      </div>);
    }
};
ClassificationSelector = __decorate([
    observer
], ClassificationSelector);
export { ClassificationSelector };
