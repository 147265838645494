import axios from "../util/axios";
import { deleteNilProperty } from "../util/delete-nil-property";
export const getUser = () => {
    return axios.get("/user");
};
export const listUsers = (params) => {
    return axios.get("/users", {
        params: deleteNilProperty(params)
    });
};
export const updateUser = (id, params) => {
    return axios.put(`/user/${id}`, params);
};
export const signUp = (username, nickname, password) => {
    return axios.post("/sign_up", {
        name: username,
        nickname: nickname,
        password: password
    });
};
export const signIn = (username, password) => {
    return axios.post("/sign_in", {
        name: username,
        password: password
    });
};
export const logout = () => {
    return axios.get("/v2/login/logout");
};
export const changePassword = (params) => {
    return axios.post("/user/password", params);
};
export const deleteUser = (userId) => {
    return axios.delete(`/user/${userId}`);
};
