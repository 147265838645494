var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { IAnnotationAudit } from "definition/entity/annotation-audit";
import { Api } from "api/Api";
import { ILabelPackage } from "definition/entity/label-package";
import { transformLabelPackageStatus } from "util/status-transform";
import { ILabelPackageAssignment } from "definition/entity/label-package-assignment";
import { message } from "antd";
export var OrderBy;
(function (OrderBy) {
    OrderBy["LabelUser"] = "labelUser";
    OrderBy["ReviewUser"] = "reviewUser";
})(OrderBy || (OrderBy = {}));
class Status {
    constructor() {
        this.loading = false;
        this.compositeAudits = [];
        this.tasks = [];
        this.auditPeriod = IAnnotationAudit.AuditPeriod.Daily;
        this.calendarDate = [];
        this.columnDate = [];
        this.curTaskIds = [];
        this.exportCSVData = [];
        this.CSVFilename = undefined;
        this.orderBy = { key: undefined, order: false };
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "compositeAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "tasks", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IAnnotationAudit !== "undefined" && IAnnotationAudit.AuditPeriod) === "function" ? _a : Object)
], Status.prototype, "auditPeriod", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "calendarDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "columnDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curTaskIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "orderBy", void 0);
class TaskCompositeAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        let auditDateString = this.auditDateString;
        if (auditDateString.length === 0) {
            return;
        }
        const { curTaskIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const compositeAudits = await Api.audit.getTaskCompositeAudits({
                auditDateStart: auditDateString[0],
                auditDateEnd: moment(auditDateString[1])
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                taskIds: curTaskIds
            });
            this.status.compositeAudits = compositeAudits.data;
            this.status.columnDate = [moment(auditDateString[0]), moment(auditDateString[1])];
            this.status.CSVFilename = `${auditDateString[0]}_${auditDateString[1]}_taskCompositeAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadTasks() {
        const getTasksRes = await Api.labelTask.listSimpleLabelTasks();
        this.status.tasks = getTasksRes.data;
    }
    downTaskCompositeAuditFile() {
        const { exportCSVData } = this.status;
        exportCSVData.length = 0;
        exportCSVData.push([
            "任务名称",
            "标注进度",
            "标注剩余天数(标注要求完成日期)",
            "任务进度",
            "任务剩余天数(任务要求完成日期)",
            "分包名称",
            "分包状态",
            "标注人员",
            "复审人员",
            "分包标注进度"
        ]);
        this.transformedAudits.forEach(task => {
            task.labelPackage.forEach((_package, index) => {
                const labelUser = _package.assignments.filter(i => i.type === ILabelPackageAssignment.Type.Label).pop();
                const reviewUser = _package.assignments.filter(i => i.type === ILabelPackageAssignment.Type.Review).pop();
                if (index === 0) {
                    exportCSVData.push([
                        `${task.taskName}`,
                        `${((task.labelPackage.filter(i => [
                            ILabelPackage.Status.Labeled,
                            ILabelPackage.Status.AssignReview,
                            ILabelPackage.Status.Reviewing,
                            ILabelPackage.Status.Reviewed,
                            ILabelPackage.Status.AssignFinalReview,
                            ILabelPackage.Status.FinalReviewing,
                            ILabelPackage.Status.FinalReviewed,
                            ILabelPackage.Status.Archived
                        ].includes(i.status)).length /
                            task.labelPackage.length) *
                            100).toFixed(2)}% (${task.labelPackage.filter(i => [
                            ILabelPackage.Status.Labeled,
                            ILabelPackage.Status.AssignReview,
                            ILabelPackage.Status.Reviewing,
                            ILabelPackage.Status.Reviewed,
                            ILabelPackage.Status.AssignFinalReview,
                            ILabelPackage.Status.FinalReviewing,
                            ILabelPackage.Status.FinalReviewed,
                            ILabelPackage.Status.Archived
                        ].includes(i.status)).length}/${task.labelPackage.length})`,
                        task.labelDeadline
                            ? `${moment(task.labelDeadline).diff(moment(), "day") + 1} (${moment(task.labelDeadline).format("YYYY-MM-DD")})`
                            : "-",
                        `${((task.labelPackage.filter(i => [
                            ILabelPackage.Status.Reviewed,
                            ILabelPackage.Status.FinalReviewed,
                            ILabelPackage.Status.Archived
                        ].includes(i.status)).length /
                            task.labelPackage.length) *
                            100).toFixed(2)}% (${task.labelPackage.filter(i => [
                            ILabelPackage.Status.Reviewed,
                            ILabelPackage.Status.FinalReviewed,
                            ILabelPackage.Status.Archived
                        ].includes(i.status)).length}/${task.labelPackage.length})`,
                        task.taskDeadline
                            ? `${moment(task.taskDeadline).diff(moment(), "day") + 1} (${moment(task.taskDeadline).format("YYYY-MM-DD")})`
                            : "-",
                        `${_package.packageName}`,
                        `${transformLabelPackageStatus(_package)}`,
                        `${(labelUser === null || labelUser === void 0 ? void 0 : labelUser.isVisible) ? labelUser === null || labelUser === void 0 ? void 0 : labelUser.user.nickname : "-"}`,
                        `${(reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.isVisible) ? reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.user.nickname : "-"}`,
                        _package.status === ILabelPackage.Status.Labelling
                            ? `${((_package.labeledResourceLen / _package.totalResourceLen) * 100).toFixed(2)}% (${_package.labeledResourceLen}/${_package.totalResourceLen})`
                            : "-"
                    ]);
                }
                else {
                    exportCSVData.push([
                        "",
                        "",
                        "",
                        "",
                        "",
                        `${_package.packageName}`,
                        `${transformLabelPackageStatus(_package)}`,
                        `${(labelUser === null || labelUser === void 0 ? void 0 : labelUser.isVisible) ? labelUser === null || labelUser === void 0 ? void 0 : labelUser.user.nickname : "-"}`,
                        `${(reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.isVisible) ? reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.user.nickname : "-"}`,
                        _package.status === ILabelPackage.Status.Labelling
                            ? `${((_package.labeledResourceLen / _package.totalResourceLen) * 100).toFixed(2)}% (${_package.labeledResourceLen}/${_package.totalResourceLen})`
                            : "-"
                    ]);
                }
            });
        });
    }
    get transformedAudits() {
        const { orderBy, compositeAudits } = this.status;
        if (orderBy.key === OrderBy.LabelUser && orderBy.order) {
            return compositeAudits.map(task => {
                let transformedLabelPackage = task.labelPackage.map(_package => {
                    const labelUser = _package.assignments.filter(i => i.type === ILabelPackageAssignment.Type.Label).pop();
                    return Object.assign(Object.assign({}, _package), { sortUsername: (labelUser === null || labelUser === void 0 ? void 0 : labelUser.isVisible) && (labelUser === null || labelUser === void 0 ? void 0 : labelUser.user.name) });
                });
                transformedLabelPackage = _.orderBy(transformedLabelPackage, "sortUsername");
                return Object.assign(Object.assign({}, task), { labelPackage: transformedLabelPackage });
            });
        }
        if (orderBy.key === OrderBy.ReviewUser && orderBy.order) {
            return compositeAudits.map(task => {
                let transformedLabelPackage = task.labelPackage.map(_package => {
                    const reviewUser = _package.assignments.filter(i => i.type === ILabelPackageAssignment.Type.Review).pop();
                    return Object.assign(Object.assign({}, _package), { sortUsername: (reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.isVisible) && (reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.user.name) });
                });
                transformedLabelPackage = _.orderBy(transformedLabelPackage, "sortUsername");
                return Object.assign(Object.assign({}, task), { labelPackage: transformedLabelPackage });
            });
        }
        return compositeAudits;
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.compositeAudits));
    }
    get isUnableSearch() {
        return _.isEmpty(toJS(this.status.calendarDate)) || this.status.loading;
    }
    get auditDateString() {
        const { calendarDate, auditPeriod } = this.status;
        let auditDateString = [];
        switch (auditPeriod) {
            case IAnnotationAudit.AuditPeriod.Daily:
                auditDateString = calendarDate.map(item => {
                    return item.format("YYYY-MM-DD");
                });
                break;
            case IAnnotationAudit.AuditPeriod.Weekly:
                auditDateString = calendarDate.map(item => {
                    return item.endOf("week").format("YYYY-MM-DD");
                });
                break;
            case IAnnotationAudit.AuditPeriod.Monthly:
                auditDateString = calendarDate.map(item => {
                    return item.endOf("month").format("YYYY-MM-DD");
                });
                break;
            default:
                return;
        }
        return auditDateString;
    }
    get filterTasks() {
        const { tasks, calendarDate } = toJS(this.status);
        if (_.isEmpty(calendarDate)) {
            return tasks;
        }
        return tasks.filter(item => {
            var _a, _b;
            return moment(item.createdAt).isAfter((_a = calendarDate[0]) === null || _a === void 0 ? void 0 : _a.clone().startOf("day")) &&
                moment(item.createdAt).isBefore((_b = calendarDate[1]) === null || _b === void 0 ? void 0 : _b.clone().endOf("day"));
        });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TaskCompositeAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TaskCompositeAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TaskCompositeAuditStore.prototype, "transformedAudits", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskCompositeAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskCompositeAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TaskCompositeAuditStore.prototype, "auditDateString", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TaskCompositeAuditStore.prototype, "filterTasks", null);
export const taskCompositeAuditStore = new TaskCompositeAuditStore();
