var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Collapse, Input } from "antd";
import { observer } from "mobx-react";
let SegmentItemViewer = class SegmentItemViewer extends React.Component {
    render() {
        const { segment } = this.props;
        return (<div className="detect-item-viewer-comp">
        <Collapse expandIconPosition="end">
          <Collapse.Panel key={segment.uniqKey} header={<div className="detect-outside" onClick={e => {
            e.stopPropagation();
        }}>
                <div style={{ width: 40, height: 40, borderRadius: "50%", background: segment.color }}/>
                <Input placeholder="标注 key" value={segment.key}/>
                <Input placeholder="标注说明" value={segment.locale}/>
              </div>}></Collapse.Panel>
        </Collapse>
      </div>);
    }
};
SegmentItemViewer = __decorate([
    observer
], SegmentItemViewer);
export { SegmentItemViewer };
