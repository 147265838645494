var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { Api } from "api/Api";
class Status {
    constructor() {
        this.sandBags = [];
        this.filterCreator = "";
        this.users = [];
        this.isShareSandBagModal = false;
        this.curBagId = undefined;
        this.sharedTo = undefined;
    }
}
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "sandBags", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "filterCreator", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isShareSandBagModal", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "curBagId", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "sharedTo", void 0);
class TotalSandBagsStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        const getLabelSandBagsRes = await Api.labelSandBags.getLabelSandBags();
        if (getLabelSandBagsRes.code === 200) {
            this.status.sandBags = getLabelSandBagsRes.data;
        }
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        if (getUsersRes.code === 200) {
            this.status.users = getUsersRes.data;
        }
    }
    get filteredSandBags() {
        const { sandBags, filterCreator } = this.status;
        return toJS(sandBags).filter(item => { var _a; return (filterCreator ? ((_a = item.user) === null || _a === void 0 ? void 0 : _a.nickname.indexOf(filterCreator)) > -1 : true); });
    }
}
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TotalSandBagsStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TotalSandBagsStore.prototype, "filteredSandBags", null);
export const totalSandBagsStore = new TotalSandBagsStore();
