var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Collapse, Input, Popover, Select, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import * as React from "react";
import { CompactPicker } from "react-color";
import "./SegmentItem.less";
import { ClassificationItem } from "../ClassificationItem/ClassificationItem";
import { genDefaultClassification } from "../../LabelEditorStore";
import { extendObservable } from "mobx";
import * as _ from "lodash";
let SegmentItem = class SegmentItem extends React.Component {
    render() {
        var _a;
        const { segment, onDelete } = this.props;
        return (<div className="segment-item-comp">
        <Collapse expandIconPosition="end">
          <Collapse.Panel key={segment.uniqKey} header={<div className="segment-outside" onClick={e => {
            e.stopPropagation();
        }}>
                <Popover content={<CompactPicker style={{ width: 200 }} value={segment.color} onChange={(color) => {
            segment.color = color.hex;
        }}/>}>
                  <div style={{ width: 40, height: 40, borderRadius: "50%", background: segment.color }}/>
                </Popover>
                <Input placeholder="标注 key" value={segment.key} onChange={event => {
            segment.key = event.target.value;
        }}/>
                <Input placeholder="标注说明" value={segment.locale} onChange={event => {
            segment.locale = event.target.value;
        }}/>
                <Select value={segment.type} onChange={(value, option) => {
            segment.type = value;
        }} placeholder="选择类型">
                  <Select.Option key={"mask-instance"}>实例分割</Select.Option>
                  <Select.Option key={"mask-semantic"}>语义分割</Select.Option>
                </Select>
                <Select value={segment.layer} onChange={(value, option) => {
            segment.layer = value;
        }} placeholder="选择层级">
                  <Select.Option value={0}>顶层</Select.Option>
                  <Select.Option value={1}>中间层</Select.Option>
                  <Select.Option value={2}>底层</Select.Option>
                </Select>
                <DeleteOutlined onClick={() => {
            onDelete();
        }}/>
              </div>}>
            {(_a = segment.classifications) === null || _a === void 0 ? void 0 : _a.map(classification => (<ClassificationItem key={classification.uniqKey} classification={classification} withDefaultValue={true} onDelete={() => {
            segment.classifications = _.filter(segment.classifications, item => item.uniqKey != classification.uniqKey);
        }}/>))}
            <Button onClick={() => {
            if (!Object.prototype.hasOwnProperty.call(segment, "classifications")) {
                extendObservable(segment, {
                    classifications: []
                });
                this.forceUpdate();
            }
            segment.classifications.push(genDefaultClassification());
        }}>
              添加属性
            </Button>
          </Collapse.Panel>
        </Collapse>
      </div>);
    }
};
SegmentItem = __decorate([
    observer
], SegmentItem);
export { SegmentItem };
