var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../../store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "../../../../api/Api";
class Status {
    constructor() {
        this.isLoading = true;
        this.taskTemplates = [];
        this.nameFilter = "";
    }
}
class TaskTemplatesStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        this.setStatus({ isLoading: true });
        const taskTemplatesRes = await Api.taskTemplate.listTaskTemplates({
            name: this.status.nameFilter
        });
        this.setStatus({
            isLoading: false,
            taskTemplates: taskTemplatesRes.data
        });
    }
    async deleteTaskTemplate(taskTemplateId) {
        const deleteRes = await Api.taskTemplate.deleteTaskTemplate(taskTemplateId);
        if (deleteRes.code === 200) {
            this.loadData();
        }
    }
    async downTaskTemplateFile(taskTemplateId) {
        window.open(`/api/task_template/${taskTemplateId}/document/download`);
    }
    async previewTaskTemplateDocument(taskTemplateId) {
        const getDocumentUrl = await Api.taskTemplate.getTaskTemplateDocumentUrl(taskTemplateId);
        window.open(getDocumentUrl.data);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TaskTemplatesStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TaskTemplatesStore.prototype, "resetStatus", null);
export const taskTemplatesStore = new TaskTemplatesStore();
