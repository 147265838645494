import * as React from "react";
export class EventBlock extends React.Component {
    constructor() {
        super(...arguments);
        this.ref = null;
        this.stopPropagation = event => {
            event.stopPropagation();
        };
    }
    componentDidMount() {
        const { blockEvent } = this.props;
        if (Array.isArray(blockEvent)) {
            blockEvent.forEach(evt => {
                this.ref.addEventListener(evt, this.stopPropagation);
            });
        }
        else {
            this.ref.addEventListener(blockEvent, this.stopPropagation);
        }
    }
    componentWillUnmount() {
        const { blockEvent } = this.props;
        if (Array.isArray(blockEvent)) {
            blockEvent.forEach(evt => {
                this.ref.removeEventListener(evt, this.stopPropagation);
            });
        }
        else {
            this.ref.removeEventListener(blockEvent, this.stopPropagation);
        }
    }
    render() {
        return <div ref={ref => (this.ref = ref)}>{this.props.children}</div>;
    }
}
