var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterMode } from "../../../interface/common";
let PathBounding = class PathBounding extends React.Component {
    constructor() {
        super(...arguments);
        this.onCircleMouseDown = (element, index) => {
            const status = imageLabelStore.status;
            status.mode = PainterMode.RESIZE;
            element.initAttribute = {
                selectedPointIdx: index
            };
        };
    }
    render() {
        const { element } = this.props;
        const { label } = element;
        const attributes = element.attributes;
        const { points, isClosed } = toJS(attributes);
        return (<svg>
        {points.map((point, index) => {
            var _a;
            return (<circle key={"unclosed-" + index} cx={point[0]} cy={point[1]} r={((_a = element.initAttribute) === null || _a === void 0 ? void 0 : _a.selectedPointIdx) === index ? 6 : 5} fill="#FFF" strokeWidth={1} stroke={label.color} style={{ cursor: "pointer" }} onMouseDown={() => {
                this.onCircleMouseDown(element, index);
            }}/>);
        })}

        {isClosed &&
            points.length > 2 &&
            points.map((point, index) => {
                let lastPoint = index === 0 ? points[points.length - 1] : points[index - 1];
                const midPoint = [(lastPoint[0] + point[0]) / 2, (lastPoint[1] + point[1]) / 2];
                return (<circle key={"closed-" + index} cx={midPoint[0]} cy={midPoint[1]} r={4} fill="#FFF" opacity={0.5} strokeWidth={1} stroke={label.color} style={{ cursor: "pointer" }} onMouseDown={() => {
                    if (!element.label.setPoint) {
                        let newPoints = [...points.slice(0, index), midPoint, ...points.slice(index)];
                        element.attributes = Object.assign(element.attributes, {
                            points: newPoints
                        });
                    }
                    this.onCircleMouseDown(element, index);
                }}/>);
            })}
      </svg>);
    }
};
PathBounding = __decorate([
    observer
], PathBounding);
export { PathBounding };
