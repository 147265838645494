var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { DetectionTool } from "../../../../../../definition/entity/label-task";
import { RectBounding } from "./RectBounding";
import { observer } from "mobx-react";
import { CircleBounding } from "./CircleBounding";
import { PathBounding } from "./PathBounding";
let Bounding = class Bounding extends React.Component {
    render() {
        const { element } = this.props;
        return (<React.Fragment>
        {element.label.tool === DetectionTool.Rectangle && <RectBounding element={element}/>}
        {element.label.tool === DetectionTool.Dot && <CircleBounding element={element}/>}
        {element.label.tool === DetectionTool.Path && <PathBounding element={element}/>}
      </React.Fragment>);
    }
};
Bounding = __decorate([
    observer
], Bounding);
export { Bounding };
