import axios from "../util/axios";
import { deleteNilProperty } from "../util/delete-nil-property";
export const listTaskTemplates = (params) => {
    params = deleteNilProperty(params);
    return axios.get("/task_templates", {
        params: params
    });
};
export const listSimpleTaskTemplates = (params) => {
    return axios.get("/task_templates/simple", { params });
};
export const getTaskTemplate = (id) => {
    return axios.get(`/task_template/${id}`);
};
export const createTaskTemplate = (params) => {
    return axios.post("/task_template", params);
};
export const updateTaskTemplate = (id, params) => {
    return axios.post(`/task_template/${id}`, params);
};
export const deleteTaskTemplate = (id) => {
    return axios.delete(`/task_template/${id}`);
};
export const getTaskTemplateDocumentUrl = (id) => {
    return axios.get(`/task_template/${id}/document`);
};
