var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a, _b;
import BasicStore from "../../../../store/BasicStore";
import * as _ from "lodash";
import { action, observable, toJS, computed } from "mobx";
import { Api } from "../../../../api/Api";
import { routerStore } from "../../../../store/routerStore";
import { ILabelSandBags } from "definition/entity/label-sand-bags";
import { appStore } from "store/AppStore";
import { randomString } from "../../../../util/random-string";
import { IResource } from "definition/entity/resource";
import { ILabelTask } from "definition/entity/label-task";
import { isLabelTaskLabelEqual } from "util/label-task-compare";
class Status {
    constructor() {
        this.isLoading = true;
        this.labelTask = null;
        this.isCloneLabelTask = false;
        this.taskTemplates = [];
        this.taskBusinesses = [];
        this.businessId = undefined;
        this.sandBags = [];
        this.assessmentPolicies = [];
        this.mixPolicyThresholds = [];
        this.extractPolicyThresholds = [];
        this.videoExtractPolicyThresholds = [];
        this.remark = "";
        this.propertySet = new Set();
        this.propertySelected = ["url"];
        this.fileName = "";
        this.displayResourceUrls = [];
        this.resources = [];
        this.isMixLabelModalVisible = false;
        this.curMixSandBag = null;
        this.mixRatio = null;
        this.extractRatio = null;
        this.perPackageCount = 2000;
        this.isEditorValid = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "labelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "isCloneLabelTask", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskTemplates", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskBusinesses", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "businessId", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "sandBags", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "assessmentPolicies", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "mixPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "extractPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "videoExtractPolicyThresholds", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object)
], Status.prototype, "propertySet", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "propertySelected", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "fileName", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "displayResourceUrls", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "isMixLabelModalVisible", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof ILabelSandBags !== "undefined" && ILabelSandBags.AsObject) === "function" ? _b : Object)
], Status.prototype, "curMixSandBag", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "mixRatio", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "extractRatio", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "perPackageCount", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "isEditorValid", void 0);
class LabelTaskCloneStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadTaskBusinesses() {
        const taskBusinessesRes = await Api.taskBusiness.listTaskBusinesses();
        this.status.taskBusinesses = taskBusinessesRes.data;
    }
    async loadTaskTemplates() {
        const taskTemplatesRes = await Api.taskTemplate.listSimpleTaskTemplates();
        this.status.taskTemplates = taskTemplatesRes.data;
    }
    async loadSandBags() {
        let sandBagsRes = null;
        if (appStore.status.loginUser.isSystemAdmin) {
            sandBagsRes = await Api.labelSandBags.getLabelSandBags();
        }
        else {
            sandBagsRes = await Api.labelSandBags.getLabelSandBags({ creatorId: appStore.status.loginUser.id });
        }
        this.status.sandBags = sandBagsRes.data;
    }
    async loadAssessmentPolicies() {
        const assessmentPoliciesRes = await Api.labellingAssessmentPolicy.getLabellingAssessmentPolicies();
        this.status.assessmentPolicies = assessmentPoliciesRes.data;
    }
    handleChangeTemplate(templateId) {
        const taskTemplate = _.find(this.status.taskTemplates, template => {
            return template.id === templateId;
        });
        this.status.mixPolicyThresholds = [];
        this.status.extractPolicyThresholds = [];
        this.status.videoExtractPolicyThresholds = [];
        if (!taskTemplate) {
            this.status.remark = "";
            this.status.labelTask.label = {
                detections: [],
                classifications: [],
                anchors: [],
                segments: [],
                segments3D: [],
                detections3D: []
            };
            this.status.curMixSandBag = null;
            this.status.labelTask.type = ILabelTask.Type.Normal;
            this.status.labelTask.policyId = undefined;
            this.status.mixRatio = undefined;
            this.status.extractRatio = undefined;
            this.status.mixPolicyThresholds = [];
            this.status.extractPolicyThresholds = [];
            this.status.videoExtractPolicyThresholds = [];
        }
        else {
            const { name, label: { detections = [], classifications = [], anchors = [], segments = [], segments3D = [], detections3D = [] } = {
                detections: [],
                classifications: [],
                anchors: []
            } } = taskTemplate;
            this.status.remark = name;
            this.status.labelTask.label = {
                detections: detections,
                classifications: classifications,
                anchors: anchors,
                segments,
                segments3D,
                detections3D
            };
            detections.forEach(detection => {
                this.status.mixPolicyThresholds.push({
                    uniqKey: detection.uniqKey,
                    nameConfig: { value: detection.key },
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.extractPolicyThresholds.push({
                    nameConfig: { value: detection.key },
                    uniqKey: detection.uniqKey,
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.videoExtractPolicyThresholds.push({
                    nameConfig: { value: detection.key },
                    uniqKey: detection.uniqKey,
                    configs: [
                        { key: "mota", value: 0 },
                        { key: "motp", value: 0 },
                        { key: "mt", value: 0 }
                    ]
                });
            });
            classifications.forEach(classification => {
                this.status.mixPolicyThresholds.push({
                    nameConfig: { value: classification.key },
                    uniqKey: classification.uniqKey,
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.extractPolicyThresholds.push({
                    nameConfig: { value: classification.key },
                    uniqKey: classification.uniqKey,
                    configs: [
                        { key: "precision", value: 0 },
                        { key: "recall", value: 0 },
                        { key: "iou", value: 0 }
                    ]
                });
                this.status.videoExtractPolicyThresholds.push({
                    nameConfig: { value: classification.key },
                    uniqKey: classification.uniqKey,
                    configs: [
                        { key: "mota", value: 0 },
                        { key: "motp", value: 0 },
                        { key: "mt", value: 0 }
                    ]
                });
            });
            if (this.status.labelTask.resourceMime === IResource.Mime.Image &&
                this.status.labelTask.labelTaskType === ILabelTask.LabelTaskType.Segmentation) {
                this.status.mixPolicyThresholds.push({
                    nameConfig: { value: "log" },
                    uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                    configs: [
                        { key: "box_siou_th", value: undefined },
                        { key: "mask_siou_th", value: undefined }
                    ]
                });
            }
        }
    }
    changeResourceFile(file) {
        this.status.fileName = file.name;
        const reader = new FileReader();
        reader.onload = (ev) => {
            const resultText = ev.target.result.trim();
            const resourceArr = [];
            const tmpPropertySet = new Set();
            resultText.split("\n").forEach(line => {
                if (line && !(line.length === 1 && line.charCodeAt(0))) {
                    try {
                        const lineObj = JSON.parse(line);
                        Object.getOwnPropertyNames(lineObj).forEach(name => {
                            tmpPropertySet.add(name);
                        });
                        resourceArr.push(lineObj);
                    }
                    catch (e) {
                        if (e instanceof SyntaxError) {
                            tmpPropertySet.add("url");
                            resourceArr.push({
                                url: line
                            });
                        }
                    }
                }
            });
            this.status.resources = resourceArr;
            this.status.displayResourceUrls = resourceArr.map(i => ({
                url: i.url
            }));
            this.status.propertySet = tmpPropertySet;
            this.status.propertySelected = _.filter(Array.from(tmpPropertySet), i => {
                return ["url", "meta", "annotation", "pcd", "params"].includes(i);
            });
        };
        reader.readAsText(file, "utf8");
    }
    removeResourceFile() {
        this.status.fileName = null;
        this.status.resources = [];
        this.status.displayResourceUrls = [];
        this.status.propertySet = new Set();
        this.status.propertySelected = [];
    }
    chooseDataOceanBlob(resources) {
        this.status.resources = resources;
        this.status.displayResourceUrls = resources.map(i => ({ url: i.url }));
        const tmpPropertySet = new Set();
        tmpPropertySet.add("url").add("meta");
        this.status.propertySet = tmpPropertySet;
        this.status.propertySelected = ["url", "meta"];
    }
    async handleChangeSandPackage(sandBagId) {
        this.status.isMixLabelModalVisible = false;
        this.status.curMixSandBag = _.find(this.status.sandBags, i => i.id === sandBagId);
        console.log(this.status.curMixSandBag);
    }
    checkIsLabelEqual(curLabel) {
        return isLabelTaskLabelEqual(_.cloneDeep(toJS(curLabel)), _.cloneDeep(toJS(this.status.curMixSandBag.label)));
    }
    validateAnnotation(annotation) {
        return true;
    }
    async cloneLabelTask(label) {
        const { id, name, resourceMime, level, taskDeadline, labelDeadline, taskTemplateId, labelTaskType, policyId, biz } = this.status.labelTask;
        const { businessId, remark, perPackageCount, curMixSandBag, mixRatio, mixPolicyThresholds, extractRatio, extractPolicyThresholds, videoExtractPolicyThresholds, propertySelected } = this.status;
        const req = Object.assign({
            type: this.status.labelTask.type,
            name,
            businessId,
            remark,
            resourceMime,
            level,
            taskDeadline,
            labelDeadline,
            taskTemplateId,
            label,
            perPackageCount,
            needAllLabeled: this.status.labelTask.needAllLabeled ? 1 : 0,
            labelTaskType,
            propertySelected,
            biz
        }, this.status.labelTask.type === ILabelTask.Type.NewMix && {
            mix: {
                sandBagId: curMixSandBag === null || curMixSandBag === void 0 ? void 0 : curMixSandBag.id,
                ratio: mixRatio / 100,
                policyId,
                policyThresholds: mixPolicyThresholds.map(item => {
                    const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                    const keys = rest.configs.map(v => v.key);
                    const values = rest.configs.map(v => v.value);
                    const configs = _.zipObject(keys, values);
                    return {
                        [`${nameConfig.value}`]: configs
                    };
                })
            }
        }, this.status.labelTask.type === ILabelTask.Type.Extract && {
            extract: {
                ratio: extractRatio / 100,
                policyId,
                policyThresholds: this.status.labelTask.resourceMime === IResource.Mime.Image
                    ? extractPolicyThresholds.map(item => {
                        const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const configs = _.zipObject(keys, values);
                        return {
                            [`${nameConfig.value}`]: configs
                        };
                    })
                    : videoExtractPolicyThresholds.map(item => {
                        const { uniqKey, nameConfig } = item, rest = __rest(item, ["uniqKey", "nameConfig"]);
                        const keys = rest.configs.map(v => v.key);
                        const values = rest.configs.map(v => v.value);
                        const configs = _.zipObject(keys, values);
                        return {
                            [`${nameConfig.value}`]: configs
                        };
                    })
            }
        });
        const createLabelTaskRes = await Api.labelTask.cloneLabelTask(id, req);
        this.status.isCloneLabelTask = false;
        if (createLabelTaskRes.code === 200) {
            routerStore.goBack();
        }
    }
    async loadData(labelTaskId) {
        const labelTaskRes = await Api.labelTask.getLabelTask(labelTaskId);
        this.setStatus({
            isLoading: false,
            labelTask: labelTaskRes.data,
            businessId: _.isNil(labelTaskRes.data.businessId) ? undefined : labelTaskRes.data.businessId
        });
        if (labelTaskRes.data.type === ILabelTask.Type.NewMix) {
            this.setStatus({
                mixRatio: labelTaskRes.data.ratio * 100
            });
            if (labelTaskRes.data.labelTaskType === ILabelTask.LabelTaskType.Segmentation) {
                labelTaskRes.data.policyThresholds.forEach(threshold => {
                    this.status.mixPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "box_siou_th" || v === "mask_siou_th")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "box_siou_th", value: threshold[key]["box_siou_th"] },
                                { key: "mask_siou_th", value: threshold[key]["mask_siou_th"] },
                                ...items
                            ]
                        };
                    }));
                });
            }
            else {
                labelTaskRes.data.policyThresholds.forEach(threshold => {
                    this.status.mixPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "precision" || v === "recall" || v === "iou")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `mix_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "precision", value: threshold[key]["precision"] },
                                { key: "recall", value: threshold[key]["recall"] },
                                { key: "iou", value: threshold[key]["iou"] },
                                ...items
                            ]
                        };
                    }));
                });
            }
        }
        if (labelTaskRes.data.type === ILabelTask.Type.Extract) {
            this.setStatus({
                extractRatio: labelTaskRes.data.ratio * 100
            });
            labelTaskRes.data.policyThresholds.forEach(threshold => {
                if (this.status.labelTask.resourceMime === IResource.Mime.Image) {
                    this.status.extractPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "precision" || v === "recall" || v === "iou")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "precision", value: threshold[key]["precision"] },
                                { key: "recall", value: threshold[key]["recall"] },
                                { key: "iou", value: threshold[key]["iou"] },
                                ...items
                            ]
                        };
                    }));
                }
                else {
                    this.status.videoExtractPolicyThresholds.push(...Object.keys(threshold).map(key => {
                        const keys = Object.keys(threshold[key]).filter(v => {
                            if (v === "mota" || v === "motp" || v === "mt")
                                return;
                            return v;
                        });
                        const items = keys.map(v => {
                            return { key: v, value: threshold[key][v] };
                        });
                        return {
                            uniqKey: `extract_${randomString()}_${new Date().getUTCSeconds()}`,
                            nameConfig: { value: key },
                            configs: [
                                { key: "mota", value: threshold[key]["mota"] },
                                { key: "motp", value: threshold[key]["motp"] },
                                { key: "mt", value: threshold[key]["mt"] },
                                ...items
                            ]
                        };
                    }));
                }
            });
        }
    }
    get isEnableClone() {
        const { name, type, resourceMime, resourcesInfo, policyId } = toJS(this.status.labelTask);
        const { isEditorValid, mixRatio, curMixSandBag, mixPolicyThresholds, extractPolicyThresholds, extractRatio, videoExtractPolicyThresholds } = toJS(this.status);
        return (isEditorValid &&
            name &&
            resourcesInfo.length &&
            (type === ILabelTask.Type.Normal ||
                (type === ILabelTask.Type.NewMix && mixRatio && (curMixSandBag === null || curMixSandBag === void 0 ? void 0 : curMixSandBag.id) && policyId && mixPolicyThresholds.length) ||
                (type === ILabelTask.Type.Extract &&
                    resourceMime === IResource.Mime.Image &&
                    extractRatio &&
                    extractPolicyThresholds.length &&
                    policyId) ||
                (type === ILabelTask.Type.Extract &&
                    resourceMime === IResource.Mime.Video &&
                    videoExtractPolicyThresholds.length)));
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelTaskCloneStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelTaskCloneStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabelTaskCloneStore.prototype, "isEnableClone", null);
export const labelTaskCloneStore = new LabelTaskCloneStore();
