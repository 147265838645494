import * as React from "react";
import { Api } from "../../../../../api/Api";
import { appStore } from "../../../../../store/AppStore";
import { Form, Input, Modal, Select } from "antd";
export class TransferModal extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            users: [],
            selectedUserId: null
        };
    }
    async componentDidMount() {
        var _a, _b, _c;
        const usersRes = await Api.user.listUsers({
            groupId: (_c = (_b = (_a = appStore.status) === null || _a === void 0 ? void 0 : _a.loginUser) === null || _b === void 0 ? void 0 : _b.group) === null || _c === void 0 ? void 0 : _c.id
        });
        this.setState({
            users: usersRes.data
        });
    }
    render() {
        var _a;
        const { isVisible, onCancel, onOk } = this.props;
        const { loginUser } = appStore.status;
        return (<Modal open={isVisible} title="转包" onCancel={() => onCancel()} onOk={() => {
            onOk(this.state.selectedUserId);
        }}>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="单位">
            <Input style={{ width: "100%" }} disabled={true} value={(_a = loginUser === null || loginUser === void 0 ? void 0 : loginUser.group) === null || _a === void 0 ? void 0 : _a.name}/>
          </Form.Item>
          <Form.Item label="人员">
            <Select style={{ width: "100%" }} showSearch={true} notFoundContent={<div>暂无数据</div>} value={this.state.selectedUserId} onChange={(value) => {
            this.setState({
                selectedUserId: +value
            });
        }}>
              {this.state.users.map(user => (<Select.Option key={String(user.id)} value={user.id}>
                  {user.name}
                </Select.Option>))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>);
    }
}
