import axios from "../util/axios";
import { deleteNilProperty } from "../util/delete-nil-property";
export const grantRole = (params) => {
    return axios.post("/admin/role/grant", {
        userId: params.userId,
        role: params.role
    });
};
export const revokeRole = (params) => {
    return axios.post("/admin/role/revoke", {
        userId: params.userId,
        role: params.role
    });
};
export const listAnnotationAudits = (params) => {
    return axios.get("/admin/annotation_audits", {
        params: deleteNilProperty(params)
    });
};
