var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Carousel, Modal } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import "./PreviewDialog.less";
const PreviewDialog = props => {
    const { imageObjs } = props, modalProps = __rest(props, ["imageObjs"]);
    const viewWidth = window.innerWidth - 240 - 230 + 20;
    const maxWidth = Math.min(viewWidth, Math.max.apply(null, [...imageObjs.map(v => v.width), 400]));
    return (<Modal width={maxWidth} footer={false} className="preview-dialog" maskClosable={true} {...modalProps}>
      <div className="content" style={{ padding: "20px 0" }}>
        <Carousel fade={true} dotPosition="top">
          {imageObjs.map((obj, i) => {
        return (<div key={i}>
                <img style={{ margin: "0 auto", maxWidth: maxWidth, width: obj.width }} src={obj.dataImage} alt=""/>
              </div>);
    })}
        </Carousel>
      </div>
    </Modal>);
};
export default observer(PreviewDialog);
