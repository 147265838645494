import * as THREE from "three";
import { OrbitControls } from "./orbitControls.js";
import * as TWEEN from "@tweenjs/tween.js";
import EventEmitter from "eventemitter3";
import Color from "color";
import { Box3 } from "./tool/box";
import { imageLabelStore } from "../image/ImageLabel/ImageLabelStore";
const PI = Math.PI;
const DOUBLEPI = PI * 2;
export const modulo = x => ((x % DOUBLEPI) + DOUBLEPI) % DOUBLEPI;
const moduloHalfPI = x => modulo(x + PI) - PI;
export const hslColorMap = (() => {
    const obj = {};
    Array.from(Array(360)).map((v, i) => {
        obj[i] = Color.hsl([i, 50, 50]).hex();
    });
    return obj;
})();
export default class Cloud extends EventEmitter {
    constructor(editor, cameraMode, canvas, container, labels, shaderMode, width, height, zoomToCursor) {
        super();
        this.editor = editor;
        this.cameraMode = cameraMode;
        this.canvas = canvas;
        this.container = container;
        this.labels = labels;
        this.shaderMode = shaderMode;
        this.width = width;
        this.height = height;
        this.zoomToCursor = zoomToCursor;
        this.highlightedIndex = undefined;
        this.pointer = new THREE.Vector2();
        this.pixelProjection = new Map();
        this.frustumIndices = new Set();
        this.visibleIndices = new Set();
        this.hiddenIndices = new Set();
        this.selectedIndices = [];
        this.tween = false;
        this.tweenDuration = 500;
        this.cameraState = {
            moving: false,
            position: 0,
            originalTarget: new THREE.Vector3(),
            fromTheta: 0,
            toTheta: 0,
            rangeTheta: 0,
            fromPhi: 0,
            toPhi: 0,
            rangePhi: 0,
            fromRadius: 0,
            toRadius: 0,
            rangeRadius: 0,
            fromX: 0,
            toX: 0,
            fromY: 0,
            toY: 0,
            fromZ: 0,
            toZ: 0,
            rangeX: 0,
            rangeY: 0,
            rangeZ: 0
        };
        this.endCameraTween = () => {
            this.cameraState.moving = false;
            interval(300, () => (this.isPixelProjectionDirty = true));
        };
        this.cameraTween = new TWEEN.Tween(this.cameraState).onStop(this.endCameraTween).onComplete(this.endCameraTween);
        this.orbiting = false;
        this.isRaycasterDirty = false;
        this.isPixelProjectionDirty = false;
        this.pointSize = 2;
        this.cameraSlope = 0;
        this.autoFitView = false;
        this.hslColorMap = hslColorMap;
        this.animate = (time) => {
            this.updateCamera(time);
            this.updateRaycaster();
            this.updatePixelProjection();
            requestAnimationFrame(time => this.animate(time));
        };
        this.onMouseMove = (e) => {
            const { left, top } = this.container.getBoundingClientRect();
            this.pointer.x = ((e.pageX - left) / this.width) * 2 - 1;
            this.pointer.y = -((e.pageY - top) / this.height) * 2 + 1;
            this.isRaycasterDirty = true;
        };
        this.render = () => {
            this.renderer.render(this.scene, this.camera);
        };
        this.init();
    }
    get targetIndices() {
        return this.cameraPresetTarget === "visible"
            ? this.visibleIndices
            : this.selectedIndices.length
                ? this.selectedIndices
                : this.visibleIndices;
    }
    init() {
        const canvasWidth = this.width;
        const canvasHeight = this.height;
        const scene = (this.scene = new THREE.Scene());
        scene.background = new THREE.Color(0x111111);
        const renderer = (this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            canvas: this.canvas,
            powerPreference: "high-performance"
        }));
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(canvasWidth, canvasHeight);
        let light = new THREE.PointLight(0xffffff, 1, 1000);
        light.position.set(0, -50, 50);
        scene.add(light);
        light = new THREE.PointLight(0xffffff, 1, 100);
        light.position.set(50, -50, 0);
        scene.add(light);
        light = new THREE.PointLight(0xffffff, 1, 100);
        light.position.set(50, -50, 50);
        scene.add(light);
        light = new THREE.PointLight(0xffffff, 1, 100);
        light.position.set(-50, -50, 50);
        scene.add(light);
        const container = this.container;
        this.initCamera();
        container.addEventListener("mousemove", this.onMouseMove);
        this.raycaster = new THREE.Raycaster();
        this.frustum = new THREE.Frustum();
        this.sphere = new THREE.Sphere();
        this.scene.add(new THREE.AxesHelper(2));
        this.render();
        window.requestAnimationFrame(this.animate);
    }
    initCamera() {
        const canvasWidth = this.width;
        const canvasHeight = this.height;
        this.scene.remove(this.camera);
        const camera = (this.camera =
            this.cameraMode === "perspective"
                ? new THREE.PerspectiveCamera(75, canvasWidth / canvasHeight, 0.01, 20000)
                : new THREE.OrthographicCamera(canvasWidth / -2, canvasWidth / 2, canvasHeight / 2, canvasHeight / -2));
        camera.updateProjectionMatrix();
        camera.up.set(0, 0, 1);
        this.scene.add(camera);
        const controls = (this.controls = new OrbitControls(camera, this.container));
        controls.zoomToCursor = this.zoomToCursor;
        controls.addEventListener("change", () => {
            this.render();
        });
        controls.addEventListener("end", () => {
            interval(300, () => {
                this.isPixelProjectionDirty = true;
            });
        });
        if (this.cameraMode === "orthographic") {
        }
    }
    dispose() {
        this.scene.clear();
        this.camera.clear();
        this.controls.dispose();
        this.renderer.dispose();
        this.renderer
            .getContext()
            .getExtension("WEBGL_lose_context")
            .loseContext();
    }
    renderPCDFile(position = this.position, label = this.label, object = this.object, intensity = this.intensity) {
        this.scene.remove(this.cloudObject);
        console.log("render pcd");
        this.position = position;
        this.label = label;
        this.intensity = intensity;
        this.object = object;
        const geometry = new THREE.BufferGeometry();
        const cloudData = (this.cloudData = []);
        let obj;
        position.forEach((v, i) => {
            switch (i % 3) {
                case 0:
                    obj = { x: v };
                    break;
                case 1:
                    obj.y = v;
                    break;
                case 2:
                    obj.z = v;
                    cloudData.push(obj);
                    break;
            }
        });
        this.visibleIndices = new Set([...Array(cloudData.length).keys()]);
        const colors = [];
        if (label) {
            label.forEach((v, i) => {
                if (cloudData[i]) {
                    cloudData[i].labelIndex = v;
                    const rgb = hex2rgb(this.getPointColor(i));
                    colors.push(rgb[0], rgb[1], rgb[2]);
                }
            });
        }
        geometry.setAttribute("position", new THREE.Float32BufferAttribute(position, 3));
        geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));
        const material = new THREE.PointsMaterial({ size: this.pointSize, vertexColors: true });
        material.sizeAttenuation = false;
        const cloudObject = (this.cloudObject = new THREE.Points(geometry, material));
        this.scene.add(cloudObject);
        this.updateSphere();
        this.render();
    }
    cameraPreset(position = "front", slope = 0, autoFitView = true, target = "visible", tween = false) {
        this.cameraPosition = position;
        this.cameraSlope = slope;
        this.autoFitView = autoFitView;
        this.cameraPresetTarget = target;
        this.tween = tween;
    }
    getCenter(indices) {
        const geometry = new THREE.BufferGeometry();
        const points = [];
        indices.forEach(idx => {
            const item = this.cloudData[idx];
            points.push(new THREE.Vector3(item.x, item.y, item.z));
        });
        geometry.setFromPoints(points);
        return new THREE.Box3().setFromObject(new THREE.Mesh(geometry)).getCenter(new THREE.Vector3());
    }
    getProjection(indices) {
        const points = indices.map(idx => {
            const item = this.cloudData[idx];
            return new THREE.Vector3(item.x, item.y, item.z);
        });
        points.forEach(p => {
            return p.project(this.camera);
        });
    }
    fitView(eye3, tar3, box) {
        const eyeVec3 = eye3;
        const targetVec3 = tar3;
        const line = new THREE.Line3(targetVec3, eyeVec3);
        const radius = this.sphere.radius || 10;
        let distance = 0;
        let theta = undefined;
        if (this.camera instanceof THREE.PerspectiveCamera) {
            const fov = this.camera.fov;
            const angle = (fov * Math.PI) / 360;
            const distanceToCenter = radius / Math.tan(angle);
            distance = 1.5 * distanceToCenter;
        }
        else if (this.camera instanceof THREE.OrthographicCamera) {
            distance = radius;
            if (box) {
                const { boundingBox } = box;
                let zoom = 1;
                let rate = 0.7;
                switch (this.cameraPosition) {
                    case "front":
                        zoom =
                            Math.min(this.width / (boundingBox.max.x - boundingBox.min.x), this.height / (boundingBox.max.z - boundingBox.min.z)) * rate;
                        break;
                    case "side":
                        zoom =
                            Math.min(this.width / (boundingBox.max.y - boundingBox.min.y), this.height / (boundingBox.max.z - boundingBox.min.z)) * rate;
                        break;
                    case "top":
                        zoom =
                            Math.min(this.width / (boundingBox.max.x - boundingBox.min.x), this.height / (boundingBox.max.y - boundingBox.min.y)) * rate;
                        break;
                }
                this.camera.zoom = zoom;
                theta = box.rotation.z;
            }
        }
        const delta = distance / line.distance();
        const newEyeVec3 = line.at(delta, new THREE.Vector3());
        this._moveCamera(newEyeVec3, targetVec3);
    }
    moveCamera(arg0, arg1) {
        let eye;
        let target;
        let box3;
        if (arg0 instanceof Box3) {
            box3 = arg0;
            const { points: [p0, p1, p2, p3, p4, p5, p6, p7] } = this.editor.transformBox2Points(box3);
            switch (this.cameraPosition) {
                case "front":
                    eye = p0
                        .clone()
                        .add(p3)
                        .divideScalar(2);
                    break;
                case "side":
                    eye = p0
                        .clone()
                        .add(p5)
                        .divideScalar(2);
                    break;
                case "top":
                    eye = p0
                        .clone()
                        .add(p6)
                        .divideScalar(2);
                    eye.setY(eye.y * 0.99999);
                    break;
            }
            target = box3.position;
            const vec = new THREE.Vector3();
            const obb = box3.obb;
            this.cloudData.forEach((v, idx) => {
                vec.set(v.x, v.y, v.z);
                if (!obb.containsPoint(vec)) {
                    this.hidePoint(idx);
                }
                else {
                    this.showPoint(idx);
                }
            });
            this.cloudObject.geometry.attributes.position.needsUpdate = true;
        }
        else {
            eye = arg0;
            target = arg1;
            const center = this.getCenter(this.targetIndices);
            const z = center.z + this.cameraSlope;
            if (!eye) {
                switch (this.cameraPosition) {
                    case "front":
                        eye = new THREE.Vector3(center.x, center.y - 1, z);
                        break;
                    case "side":
                        eye = new THREE.Vector3(center.x - 1, center.y, center.z);
                        break;
                    case "top":
                        eye = new THREE.Vector3(center.x, center.y * 0.99999, -1);
                        break;
                }
            }
            if (!target) {
                target = center;
            }
        }
        if (this.autoFitView) {
            this.fitView(eye, target, box3);
        }
        else {
            this._moveCamera(eye, target);
        }
    }
    _moveCamera(eye, target) {
        this.orbiting = true;
        const orientedRange = (a0, a1) => {
            const da = (a1 - a0) % DOUBLEPI;
            return moduloHalfPI(((2 * da) % DOUBLEPI) - da);
        };
        this.cameraState.moving = true;
        const target0 = this.controls.target;
        const target1 = target || this.controls.target;
        const eye0FromTarget = new THREE.Spherical().setFromVector3(this.camera.position.sub(target1)).makeSafe();
        const eye1FromTarget = eye ? new THREE.Spherical().setFromVector3(eye.sub(target1)).makeSafe() : eye0FromTarget;
        const state = this.cameraState;
        state.originalTarget.copy(target1);
        state.position = this.tween ? 0 : 1;
        state.fromPhi = moduloHalfPI(eye0FromTarget.phi);
        state.fromTheta = moduloHalfPI(eye0FromTarget.theta);
        state.fromRadius = eye0FromTarget.radius;
        state.toPhi = moduloHalfPI(eye1FromTarget.phi);
        state.toTheta = moduloHalfPI(eye1FromTarget.theta);
        state.toRadius = eye1FromTarget.radius;
        state.rangePhi = orientedRange(eye0FromTarget.phi, eye1FromTarget.phi);
        state.rangeTheta = orientedRange(eye0FromTarget.theta, eye1FromTarget.theta);
        state.rangeRadius = this.cameraState.toRadius - this.cameraState.fromRadius;
        state.fromX = target0.x;
        state.fromY = target0.y;
        state.fromZ = target0.z;
        state.toX = target1.x;
        state.toY = target1.y;
        state.toZ = target1.z;
        state.rangeX = target1.x - target0.x;
        state.rangeY = target1.y - target0.y;
        state.rangeZ = target1.z - target0.z;
        this.cameraTween
            .to({ position: 1 }, this.tweenDuration)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }
    highlightIndex(index) {
        this.highlightedIndex = index;
    }
    rotateGeometry(rx, ry, rz) {
        this.cloudObject.geometry
            .rotateX(rx)
            .rotateY(ry)
            .rotateZ(rz);
        const position = this.cloudObject.geometry.getAttribute("position").array;
        let obj, idx = 0;
        position.forEach((v, i) => {
            switch (i % 3) {
                case 0:
                    obj = { x: v };
                    break;
                case 1:
                    obj.y = v;
                    break;
                case 2:
                    obj.z = v;
                    Object.assign(this.cloudData[idx], obj);
                    idx++;
                    break;
            }
        });
        this.updateSphere();
        this.cameraPreset(this.cameraPosition, this.cameraSlope, this.autoFitView);
    }
    hidePoint(idx) {
        this.hiddenIndices.add(idx);
        this.visibleIndices.delete(idx);
        this.setPosition(idx, 10e20, 10e20, 10e20);
    }
    showPoint(idx) {
        this.hiddenIndices.delete(idx);
        this.visibleIndices.add(idx);
        const { x, y, z } = this.cloudData[idx];
        this.setPosition(idx, x, y, z);
    }
    setPosition(idx, x, y, z) {
        const positions = this.cloudObject.geometry.getAttribute("position").array;
        positions[idx * 3 + ""] = x;
        positions[idx * 3 + 1 + ""] = y;
        positions[idx * 3 + 2 + ""] = z;
    }
    setColor(idx, color) {
        const colors = this.cloudObject.geometry.getAttribute("color").array;
        const altColor = hex2rgb(color);
        colors[idx * 3 + ""] = altColor[0];
        colors[idx * 3 + 1 + ""] = altColor[1];
        colors[idx * 3 + 2 + ""] = altColor[2];
    }
    removeColor(idx) {
        const color = this.getPointColor(idx);
        this.setColor(idx, color);
    }
    updateCamera(time) {
        if (this.cameraState.moving) {
            this.cameraTween.update(time);
            const state = this.cameraState;
            const pos = state.position;
            const x = state.fromX + state.rangeX * pos;
            const y = state.fromY + state.rangeY * pos;
            const z = state.fromZ + state.rangeZ * pos;
            this.controls.target.copy({ x, y, z });
            const phi = state.fromPhi + state.rangePhi * pos;
            const theta = state.fromTheta + state.rangeTheta * pos;
            const radius = state.fromRadius + state.rangeRadius * pos;
            const sphericalPosition = new THREE.Spherical(radius, phi, theta).makeSafe();
            const newCamPosition = new THREE.Vector3().setFromSpherical(sphericalPosition);
            this.camera.position.copy(newCamPosition.add(this.cameraState.originalTarget));
            this.camera.updateMatrix();
            if (this.camera instanceof THREE.OrthographicCamera) {
                this.camera.updateProjectionMatrix();
            }
            this.controls.update();
            this.render();
            if (this.cameraPosition === "top") {
                const box = this.editor.boxTool.getBox(imageLabelStore.status.selectedBoxId);
                if (box) {
                    this.controls.rotate(-box.rotation.z);
                }
            }
        }
    }
    updateSphere() {
        const points = [];
        this.targetIndices.forEach(idx => {
            const item = this.cloudData[idx];
            points.push(new THREE.Vector3(item.x, item.y, item.z));
        });
        this.sphere.setFromPoints(points);
    }
    updateRaycaster() {
        if (this.raycaster && this.isRaycasterDirty) {
            this.raycaster.setFromCamera(this.pointer, this.camera);
            const intersects = (this.intersects = this.raycaster
                .intersectObjects(this.scene.children, true)
                .sort((a, b) => (a.distanceToRay < b.distanceToRay ? -1 : 1)));
            if (intersects.length) {
                const closer = intersects[0];
                this.highlightIndex(closer.index);
            }
            else {
                this.highlightIndex(undefined);
            }
            this.isRaycasterDirty = false;
        }
    }
    updatePixelProjection(idx) {
        if (!this.cloudData || !this.isPixelProjectionDirty)
            return;
        this.frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse));
        const vector = new THREE.Vector3();
        let projection;
        const halfW = this.width / 2;
        const halfH = this.height / 2;
        const toScreen = (item, idx) => {
            vector.set(item.x, item.y, item.z);
            projection = this.pixelProjection.get(item);
            if (!projection) {
                projection = {};
                this.pixelProjection.set(item, projection);
            }
            const inFrustum = this.frustum.containsPoint(vector);
            if (inFrustum)
                this.frustumIndices.add(idx);
            else {
                this.frustumIndices.delete(idx);
            }
            if (inFrustum) {
                vector.project(this.camera);
                projection.pixelX = Math.round(vector.x * halfW + halfW);
                projection.pixelY = Math.round(-vector.y * halfH + halfH);
            }
            else {
                projection.pixelX = projection.pixelY = NaN;
            }
            return { x: projection.pixelX, y: projection.pixelY };
        };
        if (idx !== undefined) {
            return toScreen(this.cloudData[idx], idx);
        }
        else {
            this.cloudData.forEach((pt, idx) => {
                toScreen(pt, idx);
            });
        }
        this.isPixelProjectionDirty = false;
    }
    getPointColor(idx) {
        var _a, _b;
        let color = (_b = (_a = this.labels) === null || _a === void 0 ? void 0 : _a[this.label[idx]]) === null || _b === void 0 ? void 0 : _b.color;
        if (color) {
            return color;
        }
        switch (this.shaderMode) {
            case "height":
            case "gray":
                color = "#CFCFCF";
                break;
            case "intensity":
                color = this.hslColorMap[this.intensity[idx]];
                break;
        }
        return color;
    }
    resize(width, height) {
        this.width = width;
        this.height = height;
        this.renderer.setSize(width, height);
    }
}
function hex2rgb(hex) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16) / 256;
    const g = parseInt(hex.substring(2, 4), 16) / 256;
    const b = parseInt(hex.substring(4, 6), 16) / 256;
    return [r, g, b];
}
function interval(timeout, callback) {
    let requestTime = null;
    const count = time => {
        if (requestTime == null) {
            requestTime = time;
        }
        if (time - requestTime > timeout) {
            callback();
        }
        else {
            requestAnimationFrame(count);
        }
    };
    requestAnimationFrame(count);
}
