var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { IAnnotationAudit } from "definition/entity/annotation-audit";
import { Api } from "api/Api";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { message } from "antd";
class Status {
    constructor() {
        this.loading = false;
        this.performanceAudits = [];
        this.groups = [];
        this.users = [];
        this.auditPeriod = IAnnotationAudit.AuditPeriod.Daily;
        this.calendarDate = [];
        this.exportCSVData = [];
        this.CSVFilename = undefined;
        this.curGroupIds = [];
        this.curUserIds = [];
        this.offset = 0;
        this.limit = 10;
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "performanceAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IAnnotationAudit !== "undefined" && IAnnotationAudit.AuditPeriod) === "function" ? _a : Object)
], Status.prototype, "auditPeriod", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "calendarDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curGroupIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curUserIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "offset", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "limit", void 0);
class PerformanceAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        let auditDateString = this.auditDateString;
        if (auditDateString.length === 0) {
            return;
        }
        const { auditPeriod, curGroupIds, curUserIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const userAudits = await Api.audit.getPerformanceAudits({
                auditPeriod: auditPeriod,
                auditDateStart: auditDateString[0],
                auditDateEnd: moment(auditDateString[1])
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                groupIds: curGroupIds,
                userIds: curUserIds
            });
            this.status.performanceAudits = userAudits.data;
            this.status.CSVFilename = `${auditDateString[0]}_${auditDateString[1]}_performanceAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadGroups() {
        const getGroupsRes = await Api.membership.listGroups();
        this.status.groups = getGroupsRes.data;
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        this.status.users = getUsersRes.data;
    }
    downAnnotationAuditFile() {
        const { exportCSVData } = this.status;
        exportCSVData.length = 0;
        this.transformedAnnotation.forEach(audit => {
            const CSVColumn = {
                businessName: audit.businessName || "未分配类型"
            };
            this.labelUserColumns.forEach(item => {
                if (_.isNil(audit[item.username])) {
                    CSVColumn[item.username] = "-";
                }
                else {
                    CSVColumn[item.username] = audit[item.username].totalWorkload;
                }
            });
            exportCSVData.push(CSVColumn);
        });
    }
    get transformedAnnotation() {
        const annotationAuditsMap = new Map();
        this.status.performanceAudits.forEach(item => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            let totalWorkload = 0;
            if (((_a = item.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Box) {
                totalWorkload = item.detectionCount;
            }
            if (((_b = item.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Piece) {
                totalWorkload = item.classificationCount;
            }
            if (annotationAuditsMap.has((_c = item.labelTaskBusiness) === null || _c === void 0 ? void 0 : _c.id)) {
                if (annotationAuditsMap.get((_d = item.labelTaskBusiness) === null || _d === void 0 ? void 0 : _d.id)[`${item.user.name}`]) {
                    annotationAuditsMap.get((_e = item.labelTaskBusiness) === null || _e === void 0 ? void 0 : _e.id)[`${item.user.name}`].totalWorkload += totalWorkload;
                }
                else {
                    annotationAuditsMap.get((_f = item.labelTaskBusiness) === null || _f === void 0 ? void 0 : _f.id)[item.user.name] = {
                        totalWorkload: totalWorkload
                    };
                }
            }
            else {
                annotationAuditsMap.set((_g = item.labelTaskBusiness) === null || _g === void 0 ? void 0 : _g.id, {
                    businessId: (_h = item.labelTaskBusiness) === null || _h === void 0 ? void 0 : _h.id,
                    businessName: (_j = item.labelTaskBusiness) === null || _j === void 0 ? void 0 : _j.name,
                    [`${item.user.name}`]: {
                        totalWorkload: totalWorkload
                    }
                });
            }
        });
        return [...annotationAuditsMap.values()];
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.performanceAudits));
    }
    get isUnableSearch() {
        return _.isEmpty(toJS(this.status.calendarDate)) || this.status.loading;
    }
    get labelUserColumns() {
        const { performanceAudits } = this.status;
        const labelUserColumnsMap = new Map();
        performanceAudits.forEach(item => {
            var _a, _b, _c, _d, _e;
            if (!labelUserColumnsMap.has((_a = item.user) === null || _a === void 0 ? void 0 : _a.id)) {
                labelUserColumnsMap.set((_b = item.user) === null || _b === void 0 ? void 0 : _b.id, {
                    userId: (_c = item.user) === null || _c === void 0 ? void 0 : _c.id,
                    username: (_d = item.user) === null || _d === void 0 ? void 0 : _d.name,
                    nickname: (_e = item.user) === null || _e === void 0 ? void 0 : _e.nickname
                });
            }
        });
        return [...labelUserColumnsMap.values()];
    }
    get auditDateString() {
        const { calendarDate, auditPeriod } = this.status;
        let auditDateString = undefined;
        switch (auditPeriod) {
            case IAnnotationAudit.AuditPeriod.Daily:
                auditDateString = calendarDate.map(item => item.format("YYYY-MM-DD"));
                break;
            case IAnnotationAudit.AuditPeriod.Weekly:
                auditDateString = calendarDate.map(item => item.endOf("week").format("YYYY-MM-DD"));
                break;
            case IAnnotationAudit.AuditPeriod.Monthly:
                auditDateString = calendarDate.map(item => item.endOf("month").format("YYYY-MM-DD"));
                break;
            default:
                return;
        }
        return auditDateString;
    }
    get filterUsers() {
        const { users, curGroupIds } = toJS(this.status);
        if (_.isEmpty(curGroupIds)) {
            return users;
        }
        return users.filter(item => { var _a; return curGroupIds.includes((_a = item === null || item === void 0 ? void 0 : item.group) === null || _a === void 0 ? void 0 : _a.id); });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], PerformanceAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PerformanceAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], PerformanceAuditStore.prototype, "transformedAnnotation", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], PerformanceAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], PerformanceAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], PerformanceAuditStore.prototype, "labelUserColumns", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], PerformanceAuditStore.prototype, "auditDateString", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], PerformanceAuditStore.prototype, "filterUsers", null);
export const performanceAuditStore = new PerformanceAuditStore();
