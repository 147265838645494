var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Alert, Button, message, Tooltip } from "antd";
import { ClassificationTool, DetectionTool, ILabelTask } from "definition/entity/label-task";
import { IResource } from "definition/entity/resource";
import * as _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { randomString } from "util/random-string";
import { AnchorItem } from "./component/AnchorItem/AnchorItem";
import { ClassificationItem } from "./component/ClassificationItem/ClassificationItem";
import { Detection3DItem } from "./component/Detection3DItem/Detection3DItem";
import { DetectItem } from "./component/DetectItem/DetectItem";
import { Segment3DItem } from "./component/Segment3DItem/Segment3DItem";
import { SegmentItem } from "./component/SegmentItem/SegmentItem";
import { genDefaultSegment3D, genDefaultAnchor, genDefaultClassification, genDefaultDetectTool, genDefaultSegment, labelEditorStore as store, genDefaultDetection3D } from "./LabelEditorStore";
let LabelEditor = class LabelEditor extends React.Component {
    constructor() {
        super(...arguments);
        this.labelEditorCheckInterval = null;
        this.onAddOption = () => {
            const { resourceMime, labelTaskType } = this.props;
            const status = store.status;
            if (_.isNil(labelTaskType)) {
                return message.error("请先选择任务类型");
            }
            switch (resourceMime) {
                case IResource.Mime.Image:
                    if (labelTaskType === ILabelTask.LabelTaskType.Classification) {
                        status.classifications.push(genDefaultClassification());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Detection) {
                        status.detections.push(genDefaultDetectTool());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Segmentation) {
                        status.segments.push(genDefaultSegment());
                    }
                    break;
                case IResource.Mime.Video:
                    if (labelTaskType === ILabelTask.LabelTaskType.Classification) {
                        status.classifications.push(genDefaultClassification());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Detection) {
                        status.detections.push(genDefaultDetectTool());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Anchor) {
                        status.anchors.push(genDefaultAnchor());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Clipping) {
                        const detection = genDefaultDetectTool(DetectionTool.Chunk);
                        detection.classifications = [
                            {
                                uniqKey: `classification_${randomString()}_${new Date().getUTCSeconds()}`,
                                type: "classification",
                                key: "违法车辆数",
                                locale: null,
                                tool: ClassificationTool.Number,
                                options: [],
                                defaultValue: null
                            },
                            {
                                uniqKey: `classification_${randomString()}_${new Date().getUTCSeconds()}`,
                                type: "classification",
                                key: "点位信息",
                                locale: null,
                                tool: ClassificationTool.Text,
                                options: [],
                                defaultValue: null
                            },
                            {
                                uniqKey: `classification_${randomString()}_${new Date().getUTCSeconds()}`,
                                type: "classification",
                                key: "摄像头日期",
                                locale: null,
                                tool: ClassificationTool.Date,
                                options: [],
                                defaultValue: null
                            },
                            {
                                uniqKey: `classification_${randomString()}_${new Date().getUTCSeconds()}`,
                                type: "classification",
                                key: "进入时间",
                                locale: null,
                                tool: ClassificationTool.Time,
                                options: [],
                                defaultValue: null
                            },
                            {
                                uniqKey: `classification_${randomString()}_${new Date().getUTCSeconds()}`,
                                type: "classification",
                                key: "离开时间",
                                locale: null,
                                tool: ClassificationTool.Time,
                                options: [],
                                defaultValue: null
                            }
                        ];
                        status.detections.push(detection);
                    }
                    break;
                case IResource.Mime.Cloud:
                    if (labelTaskType === ILabelTask.LabelTaskType.Classification) {
                        status.classifications.push(genDefaultClassification());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Detection) {
                        status.detections3D.push(genDefaultDetection3D());
                    }
                    else if (labelTaskType === ILabelTask.LabelTaskType.Segmentation) {
                        status.segments3D.push(genDefaultSegment3D());
                    }
                    break;
            }
        };
    }
    getStatus() {
        return toJS(store.status);
    }
    componentDidMount() {
        var _a, _b, _c, _d, _e, _f;
        if (this.props.defaultLabel) {
            const detections = toJS((_a = this.props.defaultLabel) === null || _a === void 0 ? void 0 : _a.detections);
            const classifications = toJS((_b = this.props.defaultLabel) === null || _b === void 0 ? void 0 : _b.classifications);
            const anchors = toJS((_c = this.props.defaultLabel) === null || _c === void 0 ? void 0 : _c.anchors) || [];
            const segments = toJS((_d = this.props.defaultLabel) === null || _d === void 0 ? void 0 : _d.segments) || [];
            const segments3D = toJS((_e = this.props.defaultLabel) === null || _e === void 0 ? void 0 : _e.segments3D) || [];
            const detections3D = toJS((_f = this.props.defaultLabel) === null || _f === void 0 ? void 0 : _f.detections3D) || [];
            store.setStatus({
                detections: detections.map(det => {
                    if (!det.color) {
                        det.color = "#000";
                    }
                    det.classifications.forEach(cls => {
                        if (cls.defaultValue === undefined) {
                            cls.defaultValue = null;
                        }
                    });
                    return det;
                }) || [],
                classifications: classifications || [],
                anchors: anchors.map(det => {
                    if (!det.color) {
                        det.color = "#000";
                    }
                    return det;
                }),
                segments,
                segments3D,
                detections3D
            });
        }
        if (this.props.checkIntervalCallback) {
            this.labelEditorCheckInterval = setInterval(() => {
                this.props.checkIntervalCallback(store.isValidatePass()[0]);
            }, 500);
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.defaultLabel !== this.props.defaultLabel) {
            store.setStatus({
                detections: _.get(nextProps.defaultLabel, "detections", []),
                classifications: _.get(nextProps.defaultLabel, "classifications", []),
                anchors: _.get(nextProps.defaultLabel, "anchors", []),
                segments: _.get(nextProps.defaultLabel, "segments", []),
                segments3D: _.get(nextProps.defaultLabel, "segments3D", []),
                detections3D: _.get(nextProps.defaultLabel, "detections3D", [])
            });
        }
    }
    componentWillUnmount() {
        store.resetStatus();
        clearInterval(this.labelEditorCheckInterval);
    }
    render() {
        const status = store.status;
        const { resourceMime, labelTaskType } = this.props;
        const [isValidatePass, validateErrMessage] = store.isValidatePass();
        return (<div className="label-editor-com">
        {status.detections.map(detection => (<DetectItem resourceMime={resourceMime} key={detection.uniqKey} detect={detection} labelTaskType={labelTaskType} onDelete={() => {
            status.detections = status.detections.filter(item => item.uniqKey !== detection.uniqKey);
        }}/>))}
        {status.classifications.map(classification => (<ClassificationItem key={classification.uniqKey} classification={classification} withDefaultValue={true} onDelete={() => {
            status.classifications = _.filter(status.classifications, item => item.uniqKey != classification.uniqKey);
        }}/>))}
        {status.anchors.map(anchor => (<AnchorItem key={anchor.uniqKey} anchor={anchor} onDelete={() => {
            status.anchors = status.anchors.filter(item => item.uniqKey !== anchor.uniqKey);
        }}/>))}
        {status.segments.map(segment => (<SegmentItem key={segment.uniqKey} segment={segment} onDelete={() => {
            status.segments = status.segments.filter(item => item.uniqKey !== segment.uniqKey);
        }}/>))}
        {status.segments3D.map(segment => (<Segment3DItem key={segment.uniqKey} segment={segment} onDelete={() => {
            status.segments3D = status.segments3D.filter(item => item.uniqKey !== segment.uniqKey);
        }}></Segment3DItem>))}
        {status.detections3D.map(detection3D => (<Detection3DItem key={detection3D.uniqKey} detection3D={detection3D} onDelete={() => {
            status.detections3D = status.detections3D.filter(item => item.uniqKey !== detection3D.uniqKey);
        }}></Detection3DItem>))}
        <div style={{ marginBottom: "10px" }}>
          <Button onClick={this.onAddOption}>添加选项</Button>
          <Button style={{ marginLeft: "8px" }} onClick={() => {
            status.classifications.push(genDefaultClassification());
        }}>
            添加属性
          </Button>{" "}
          <Tooltip title="分类任务的添加选项与添加属性功能一致">
            <span className="question">?</span>
          </Tooltip>
        </div>
        <Alert style={{
            height: 40,
            visibility: isValidatePass ? "hidden" : "inherit"
        }} message={validateErrMessage} type="error"/>
      </div>);
    }
};
LabelEditor = __decorate([
    observer
], LabelEditor);
export { LabelEditor };
