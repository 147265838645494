var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../../../store/BasicStore";
import { action, observable } from "mobx";
class Status {
    constructor() {
        this.activeKey = null;
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "activeKey", void 0);
class AdminStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        const originActiveKey = this.status.activeKey;
        this.status = new Status();
        this.status.activeKey = originActiveKey;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], AdminStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AdminStore.prototype, "resetStatus", null);
export const adminStore = new AdminStore();
