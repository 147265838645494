var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Button, Card, Divider, Input, PageHeader, Popconfirm, Table, Dropdown } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { resourceMimeDesc } from "../../../../util/enum-desc-convert";
import { routerStore } from "../../../../store/routerStore";
import { taskTemplatesStore } from "./TaskTemplatesStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { ILabelTask } from "definition/entity/label-task";
let TaskTemplates = class TaskTemplates extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "名称",
                ellipsis: true,
                dataIndex: "name"
            },
            {
                title: "资源类型",
                dataIndex: "resourceMime",
                align: "center",
                width: 120,
                render: resourceMime => resourceMimeDesc(resourceMime)
            },
            {
                title: "任务类型",
                dataIndex: "labelTaskType",
                align: "center",
                width: 120,
                render: labelTaskType => ILabelTask.LabelTaskTypeCN[labelTaskType]
            },
            {
                title: "备注",
                ellipsis: true,
                dataIndex: "remark"
            },
            {
                title: "标注文档",
                ellipsis: true,
                dataIndex: "objectName",
                align: "center",
                render: (value, record) => {
                    return (<a onClick={() => {
                        taskTemplatesStore.previewTaskTemplateDocument(record.id);
                    }}>
            {value}
          </a>);
                }
            },
            {
                title: "操作",
                align: "center",
                width: 240,
                render: (value, record) => {
                    return (<div>
            <a onClick={() => {
                        routerStore.push(`/manage/task_template/${record.id}/modify`);
                    }}>
              修改
            </a>
            {record.objectName && (<React.Fragment>
                <Divider type="vertical"/>
                <Dropdown menu={{
                        items: [
                            {
                                key: "1",
                                onClick() {
                                    taskTemplatesStore.downTaskTemplateFile(record.id);
                                },
                                label: "标注文档"
                            }
                        ]
                    }}>
                  <Button type="link" size="small" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    下载
                  </Button>
                </Dropdown>
              </React.Fragment>)}
            <Divider type="vertical"/>
            <Popconfirm title="确认要删除这个任务模板么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={() => {
                        taskTemplatesStore.deleteTaskTemplate(record.id);
                    }}>
              <a>删除</a>
            </Popconfirm>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        taskTemplatesStore.loadData();
    }
    render() {
        const status = toJS(taskTemplatesStore.status);
        return (<PageHeader title="任务模板列表" style={{ background: "#FFF" }}>
        <Card>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <Button type="primary" onClick={() => {
            routerStore.push("/manage/task_template/create");
        }}>
              新建任务模板
            </Button>
            <Input.Search style={{ width: "240px" }} value={status.nameFilter} enterButton="搜索" onSearch={value => {
            taskTemplatesStore.loadData();
        }} onChange={event => {
            taskTemplatesStore.setStatus({
                nameFilter: event.target.value
            });
        }}/>
          </div>
          <Table loading={status.isLoading} rowKey="id" bordered={true} style={{ marginTop: "20px" }} columns={this.columns} dataSource={status.taskTemplates} pagination={{ showSizeChanger: false }}/>
        </Card>
      </PageHeader>);
    }
};
TaskTemplates = __decorate([
    observer
], TaskTemplates);
export { TaskTemplates };
