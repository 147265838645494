var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { imageLabelStore } from "../../ImageLabelStore";
import { Divider } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { PainterMode } from "../../../interface/common";
import { formatTime } from "../../../../../../util/time-transform";
import "./LabelFooterStatus.less";
let LabelFooterStatus = class LabelFooterStatus extends React.Component {
    painterModeTranslate(mode) {
        switch (mode) {
            case PainterMode.DRAW:
                return "基本绘制";
            case PainterMode.MOVE:
                return "标注移动";
            case PainterMode.RESIZE:
                return "标注调整";
            case PainterMode.UNSET:
                return "标注查看";
            default:
                return "未知";
        }
    }
    render() {
        var _a, _b;
        const status = imageLabelStore.status;
        return (<div className="label-footer-status-component">
        {status.isLoading ? (<div>
            <LoadingOutlined />
            &nbsp;<span>加载中</span>
          </div>) : (<React.Fragment>
            <div>
              <CheckCircleOutlined />
              &nbsp;<span>加载完毕</span>
            </div>
            <Divider type="vertical"/>
            <div>
              <span>模式: {this.painterModeTranslate(status.mode)}</span>
            </div>
            <Divider type="vertical"/>
            <div>
              <span>图片缩放&nbsp;{Math.floor(status.zoomRatio * 100)}&nbsp;%</span>
            </div>
            <Divider type="vertical"/>
            <div>
              <span>
                当前页码&nbsp;{status.curOffset + 1}&nbsp;/&nbsp;{(_a = status.labelInfo) === null || _a === void 0 ? void 0 : _a.resourceCount}
              </span>
            </div>
            <Divider type="vertical"/>
            <div>
              <span>最近更新&nbsp;{formatTime((_b = status.curResource) === null || _b === void 0 ? void 0 : _b.annotationUpdatedAt)}</span>
            </div>
            {status.commonMode && <span style={{ marginLeft: 5, backgroundColor: "red" }}>共边模式</span>}
          </React.Fragment>)}
      </div>);
    }
};
LabelFooterStatus = __decorate([
    observer
], LabelFooterStatus);
export { LabelFooterStatus };
