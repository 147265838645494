import React, { createRef } from "react";
import Sash, { Orientation } from "component/Sash";
import { PointerContext } from "./SubView";
import "./interactiveBox.less";
export default class InteractiveBox extends React.Component {
    constructor() {
        super(...arguments);
        this.boxRef = createRef();
        this.sashRefs = [
            createRef(),
            createRef(),
            createRef(),
            createRef()
        ];
        this.knobRefs = [
            createRef(),
            createRef(),
            createRef(),
            createRef()
        ];
        this.onDragMouseDown = (e) => {
            const { onDragStart, onDrag, onDragEnd } = this.props;
            const dragEvent = {
                target: e.target,
                startX: e.pageX,
                startY: e.pageY,
                currentX: e.pageX,
                currentY: e.pageY
            };
            if (!this.isFromSash(e.target)) {
                onDragStart(dragEvent);
                addDragEvents();
            }
            function addDragEvents() {
                window.addEventListener("mousemove", onWindowMouseMove);
                window.addEventListener("mouseup", onWindowMouseUp);
            }
            function removeDragEvents() {
                window.removeEventListener("mousemove", onWindowMouseMove);
                window.removeEventListener("mouseup", onWindowMouseUp);
            }
            function onWindowMouseMove(e) {
                dragEvent.currentX = e.pageX;
                dragEvent.currentY = e.pageY;
                onDrag(dragEvent);
            }
            function onWindowMouseUp(e) {
                dragEvent.currentX = e.pageX;
                dragEvent.currentY = e.pageY;
                onDragEnd(dragEvent);
                removeDragEvents();
            }
        };
        this.onResizeMouseDown = (e, dir) => {
            const { onResizeStart, onResize, onResizeEnd } = this.props;
            const resizeEvent = {
                target: e.target,
                startX: e.pageX,
                startY: e.pageY,
                currentX: e.pageX,
                currentY: e.pageY,
                type: "mouse",
                dir
            };
            onResizeStart(Object.assign({}, resizeEvent));
            addResizeEvents();
            function addResizeEvents() {
                window.addEventListener("mousemove", onWindowMouseMove);
                window.addEventListener("mouseup", onWindowMouseUp);
            }
            function removeResizeEvents() {
                window.removeEventListener("mousemove", onWindowMouseMove);
                window.removeEventListener("mouseup", onWindowMouseUp);
            }
            function onWindowMouseMove(e) {
                onResize(Object.assign(Object.assign({}, resizeEvent), { currentX: e.pageX, currentY: e.pageY }));
            }
            function onWindowMouseUp(e) {
                onResizeEnd(Object.assign(Object.assign({}, resizeEvent), { currentX: e.pageX, currentY: e.pageY }));
                removeResizeEvents();
            }
        };
        this.onRotateMouseDown = (e) => {
            const { onRotateStart, onRotate, onRotateEnd, size, position } = this.props;
            const center = {
                x: position.x + size.width / 2,
                y: position.y + size.height / 2
            };
            const getAngle = () => {
                const pointer = this.context.pointer;
                const angle = Math.atan2(pointer.y - center.y, pointer.x - center.x) + Math.PI / 2;
                if (angle >= 2 * Math.PI) {
                    return angle - 2 * Math.PI;
                }
                return angle;
            };
            const onWindowMouseMove = (e) => {
                onRotate({ rotation: getAngle() });
            };
            const onWindowMouseUp = (e) => {
                onRotateEnd({ rotation: getAngle() });
                removeRotateEvents();
            };
            function addRotateEvents() {
                window.addEventListener("mousemove", onWindowMouseMove);
                window.addEventListener("mouseup", onWindowMouseUp);
            }
            function removeRotateEvents() {
                window.removeEventListener("mousemove", onWindowMouseMove);
                window.removeEventListener("mouseup", onWindowMouseUp);
            }
            onRotateStart({ rotation: getAngle() });
            addRotateEvents();
        };
    }
    componentDidMount() {
        this.updateBoundingRect();
    }
    isFromSash(target) {
        return this.sashRefs.map(v => v.current).some(el => el.contains(target));
    }
    isFromKnob(target) {
        return this.knobRefs.map(v => v.current).some(el => el.contains(target));
    }
    updateBoundingRect() {
        var _a;
        this.boxRect = (_a = this.boxRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    }
    render() {
        const { size: { width, height }, position, rotation, head, onResizeStart = () => { }, onResize = () => { }, onResizeEnd = () => { }, knob, enableRotate } = this.props;
        let headPosStyle = {};
        switch (head) {
            case "top":
                headPosStyle = { bottom: "100%", left: "50%" };
                break;
            case "right":
                headPosStyle = { left: "100%", top: "50%" };
                break;
            case "bottom":
                headPosStyle = { top: "100%", left: "50%" };
                break;
            case "left":
                headPosStyle = { right: "100%", top: "50%" };
                break;
            default:
                headPosStyle = { bottom: "100%", left: "50%" };
        }
        let headStyle = {};
        if (head === "top" || head === "bottom") {
            headStyle = { width: 0, height: 10, borderRight: "1px solid #fff" };
        }
        else {
            headStyle = { width: 10, height: 0, borderBottom: "1px solid #fff" };
        }
        const rotationDeg = (rotation * 180) / Math.PI;
        return (<div ref={this.boxRef} className="interactive-box" style={{ width, height, left: position.x, top: position.y, transform: `rotate(${rotationDeg}deg)` }}>
        <div className="content" style={{ width, height }} onMouseDown={this.onDragMouseDown}></div>

        <div style={Object.assign({ position: "absolute" }, headPosStyle)}>
          {enableRotate && <div className="circle" onMouseDown={this.onRotateMouseDown}></div>}
          {head && <div className="orientation" style={Object.assign({}, headStyle)}></div>}
        </div>
        <div className="sashes">
          <Sash size={3} onStart={e => onResizeStart(Object.assign(Object.assign({}, e), { dir: "n" }))} onChange={e => onResize(Object.assign(Object.assign({}, e), { dir: "n" }))} onEnd={e => onResizeEnd(Object.assign(Object.assign({}, e), { dir: "n" }))} ref={this.sashRefs[0]} orientation={Orientation.HORIZONTAL} position={-2}></Sash>
          <Sash size={3} onStart={e => onResizeStart(Object.assign(Object.assign({}, e), { dir: "s" }))} onChange={e => onResize(Object.assign(Object.assign({}, e), { dir: "s" }))} onEnd={e => onResizeEnd(Object.assign(Object.assign({}, e), { dir: "s" }))} ref={this.sashRefs[1]} orientation={Orientation.HORIZONTAL} position={height - 2}></Sash>
          <Sash size={3} onStart={e => onResizeStart(Object.assign(Object.assign({}, e), { dir: "w" }))} onChange={e => onResize(Object.assign(Object.assign({}, e), { dir: "w" }))} onEnd={e => onResizeEnd(Object.assign(Object.assign({}, e), { dir: "w" }))} ref={this.sashRefs[2]} orientation={Orientation.VERTICAL} position={-2}></Sash>
          <Sash size={3} onStart={e => onResizeStart(Object.assign(Object.assign({}, e), { dir: "e" }))} onChange={e => onResize(Object.assign(Object.assign({}, e), { dir: "e" }))} onEnd={e => onResizeEnd(Object.assign(Object.assign({}, e), { dir: "e" }))} ref={this.sashRefs[3]} orientation={Orientation.VERTICAL} position={width - 2}></Sash>
        </div>
        {knob && (<div className="knobs">
            <div className="knob knob-nw" ref={this.knobRefs[0]} onMouseDown={e => this.onResizeMouseDown(e, "nw")}></div>
            <div className="knob knob-ne" ref={this.knobRefs[1]} onMouseDown={e => this.onResizeMouseDown(e, "ne")}></div>
            <div className="knob knob-sw" ref={this.knobRefs[2]} onMouseDown={e => this.onResizeMouseDown(e, "sw")}></div>
            <div className="knob knob-se" ref={this.knobRefs[3]} onMouseDown={e => this.onResizeMouseDown(e, "se")}></div>
          </div>)}
      </div>);
    }
}
InteractiveBox.contextType = PointerContext;
InteractiveBox.defaultProps = {
    onResizeStart: () => { },
    onResize: () => { },
    onResizeEnd: () => { },
    onDragStart: () => { },
    onDrag: () => { },
    onDragEnd: () => { },
    onRotateStart: () => { },
    onRotate: () => { },
    onRotateEnd: () => { },
    knob: true,
    enableRotate: false
};
