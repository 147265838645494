var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Modal } from "antd";
import React from "react";
export class CreateGroupDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.onKeyDown = (e) => {
            if (e.key === "Enter" && this.props.visible) {
                this.props.onOk();
            }
        };
    }
    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
    }
    render() {
        const rest = __rest(this.props, []);
        return (<Modal title="生成关联组合" {...rest}>
        <div>关联选中的标注对象，并赋予相同的实例编号？</div>
      </Modal>);
    }
}
export class DeleteGroupDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.onKeyDown = (e) => {
            if (e.key === "Enter" && this.props.visible) {
                this.props.onOk();
            }
        };
    }
    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
    }
    render() {
        const rest = __rest(this.props, []);
        return (<Modal title="解散关联组合" {...rest}>
        <div>描述：解散选中的关联组合，并分别赋予不同的实例编号？</div>
      </Modal>);
    }
}
export class ExitGroupDialog extends React.Component {
    render() {
        const rest = __rest(this.props, []);
        return (<Modal title="退出关联组合" {...rest}>
        <div>描述：取消该对象的关联关系，并赋予新的实例编号？</div>
      </Modal>);
    }
}
