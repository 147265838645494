import { imageLabelStore } from "page/label/image/ImageLabel/ImageLabelStore";
import * as THREE from "three";
import Tool from "./tool";
import _ from "lodash";
import { PainterDom } from "page/label/image/ImageLabel/provider/painter-dom";
import { OBB } from "three/examples/jsm/math/OBB";
import fontdata from "three/examples/fonts/gentilis_regular.typeface.json";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import { message } from "antd";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { modulo } from "../cloud";
const font = new Font(fontdata);
export default class BoxTool extends Tool {
    constructor(editor) {
        super();
        this.editor = editor;
        this.box3Map = new Map();
    }
    clean() {
        this.rotation = null;
        this.box3 = null;
        this.label = null;
    }
    unproject(vec, camera) {
        if (camera instanceof THREE.PerspectiveCamera) {
            const pos = new THREE.Vector3();
            vec.unproject(camera);
            vec.sub(camera.position).normalize();
            const distance = -camera.position.z / vec.z;
            pos.copy(camera.position).add(vec.multiplyScalar(distance));
            return pos;
        }
        else {
            return vec.unproject(camera);
        }
    }
    project(vec, camera) {
        return vec.clone().project(camera);
    }
    onDrawStart(e, label) {
        this.rotation = this.editor.mainCloud.camera.rotation.clone();
        this.position = this.editor.mainCloud.camera.position.clone();
        this.label = label;
        const _uid = PainterDom.generateId(this.label.key);
        this.createBox({
            size: { width: 0, height: 0, length: 0 },
            position: new THREE.Vector3(0, 0, 0),
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            color: label.color,
            title: _uid
        });
    }
    onDraw(e, _me) {
        const { start, current } = e;
        const startVector3 = this.unproject(new THREE.Vector3(start.x, start.y, start.z), this.editor.mainCloud.camera);
        const currentVector3 = this.unproject(new THREE.Vector3(current.x, current.y, current.z), this.editor.mainCloud.camera);
        const deltaX = currentVector3.x - startVector3.x;
        const deltaY = currentVector3.y - startVector3.y;
        const yaw = this.rotation.z;
        const theta = deltaX === 0 ? 0 : Math.atan((deltaX / deltaY) * -1);
        const angle = Math.PI / 2 - theta + yaw;
        const distance = currentVector3.distanceTo(startVector3);
        const size = {
            width: Math.abs(distance * Math.cos(angle)),
            length: Math.abs(distance * Math.sin(angle)),
            height: 3
        };
        const position = startVector3
            .clone()
            .add(currentVector3)
            .divideScalar(2)
            .setZ(size.height / 2);
        const orientation = current.y - start.y > 0 ? 1 : -1;
        const rotation = {
            x: 0,
            y: 0,
            z: this.rotation.z + (orientation === -1 ? Math.PI : 0)
        };
        this.box3.update({ size, position, rotation });
        this.editor.mainCloud.render();
    }
    onDrawEnd(e) {
        const { mainCloud } = this.editor;
        const indices = this.getInsidePointIndices(this.box3);
        const insidePoints = indices.map(v => mainCloud.cloudData[v]);
        if (insidePoints.length >= 4) {
            const max = _.maxBy(insidePoints, v => v.z);
            this.box3.resizeZ(max.z - this.box3.size.height);
            const { size, position, rotation, id, title } = this.box3;
            const box = {
                id,
                _uid: title,
                size,
                position,
                rotation,
                label: this.label,
                visible: true,
                titleVisible: true
            };
            imageLabelStore.status.boxes.push(box);
            imageLabelStore.status.selectedBoxId = id;
            this.editor.updateBoxVectorsProjection(id);
        }
        else {
            message.error("当前立体框内少于4个点, 无法生成立体框!");
            this.removeBox(this.box3);
        }
        mainCloud.render();
        this.editor.updateBoxPositionProjection();
        this.clean();
    }
    getInsidePointIndices(box3) {
        const vector = new THREE.Vector3();
        const obb = box3.obb;
        const indices = [];
        this.editor.mainCloud.cloudData.forEach((v, idx) => {
            vector.set(v.x, v.y, v.z);
            if (obb.containsPoint(vector)) {
                indices.push(idx);
            }
        });
        return indices;
    }
    getBox(id) {
        if (typeof id === "number") {
            return this.box3Map.get(id);
        }
        return id;
    }
    createBox(box) {
        const { position, size, rotation, color, title } = box;
        this.position = this.position || this.editor.mainCloud.camera.position;
        const box3 = (this.box3 = new Box3(size, position, rotation, color, title, this.position));
        this.box3Map.set(box3.id, box3);
        this.editor.mainCloud.scene.add(box3.object);
        this.editor.boxTool;
        return box3;
    }
    removeBox(id) {
        const box3 = this.getBox(id);
        this.box3Map.delete(box3.id);
        this.editor.mainCloud.scene.remove(box3.object);
        this.editor.mainCloud.render();
    }
    resizeBox(box3, distance, direction = 1) {
        box3 = this.getBox(box3);
        const box = _.find(imageLabelStore.status.boxes, { id: box3.id });
        const { x, y, z } = distance;
        if (x) {
            box3.resizeX(x, direction);
            box.size.width = box3.size.width;
        }
        if (y) {
            box3.resizeY(y, direction);
            box.size.length = box3.size.length;
        }
        if (z) {
            box3.resizeZ(z, direction);
            box.size.height = box3.size.height;
        }
        box.position = box3.position;
    }
    rotateBox(box3, angle) {
        box3 = this.getBox(box3);
        const box = _.find(imageLabelStore.status.boxes, { id: box3.id });
        const { z } = angle;
        z && box3.rotateZ(z);
        box.rotation.z = box3.rotation.z;
    }
    translateBox(box3, distance) {
        if (typeof box3 === "number") {
            box3 = this.getBox(box3);
        }
        const box = _.find(imageLabelStore.status.boxes, { id: box3.id });
        const { x, y, z } = distance;
        if (x) {
            box3.translateX(x);
        }
        if (y) {
            box3.translateY(y);
        }
        if (z) {
            box3.translateZ(z);
        }
        box.position = box3.position;
    }
    intersect(intersects) {
        var _a;
        return (_a = intersects === null || intersects === void 0 ? void 0 : intersects.filter(v => v.object.type === "Mesh")) === null || _a === void 0 ? void 0 : _a[0];
    }
}
export class Box3 {
    constructor(size, position, rotation, color, title, cameraPosition) {
        this.size = size;
        this.position = position;
        this.rotation = rotation;
        this.color = color;
        this.title = title;
        this.cameraPosition = cameraPosition;
        this._selected = false;
        const geometry = new THREE.BoxGeometry(0, 0, 0);
        const material = new THREE.MeshBasicMaterial();
        this.object = new THREE.Mesh(geometry, material);
        this.boundingBox = new THREE.Box3();
        this.obb = new OBB();
        this.update({ size, position, rotation, color, title });
    }
    get visible() {
        return this.object.visible;
    }
    set visible(visible) {
        this.object.visible = visible;
    }
    set selected(selected) {
        this._selected = selected;
        const color = new THREE.Color(selected ? "white" : this.color);
        this.line.material.color = color;
    }
    get selected() {
        return this._selected;
    }
    get id() {
        return this.object.id;
    }
    create(size, rotation, color, title) {
        const { width, height, length } = size;
        const geometry = new THREE.BoxGeometry(width, length, height);
        const mesh = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color, opacity: 0.2, transparent: true }));
        const _color = new THREE.Color(this._selected ? "white" : color);
        const edges = new THREE.EdgesGeometry(geometry);
        const wideEdges = new LineSegmentsGeometry().fromEdgesGeometry(edges);
        const edgesMaterial = new LineMaterial({
            color: _color.getHex(),
            linewidth: 2
        });
        const line = new LineSegments2(wideEdges, edgesMaterial);
        edgesMaterial.resolution.set(window.innerWidth, window.innerHeight);
        mesh.add(line);
        const dir = new THREE.Vector3(0, 1, 0);
        const arrow = new THREE.ArrowHelper(dir, new THREE.Vector3(0, 0, 0), length * 0.7);
        mesh.add(arrow);
        const plane = new THREE.Mesh(new THREE.PlaneGeometry(width, height), new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            depthWrite: false,
            opacity: 0.7,
            color
        }));
        plane.rotateX(Math.PI / 2);
        plane.position.setY(length / 2);
        mesh.add(plane);
        return { box: mesh, line };
    }
    update({ size, position, rotation, color, title } = {}) {
        size && (this.size = size);
        position && (this.position = position);
        rotation && (this.rotation = rotation);
        color && (this.color = color);
        title && (this.title = title);
        this.object.remove(this.box);
        const { box, line } = this.create(this.size, this.rotation, this.color, this.title);
        this.box = box;
        this.line = line;
        this.object.position.copy(this.position);
        this.object.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
        this.object.add(this.box);
        this.object.updateMatrix();
        this.object.updateMatrixWorld();
        this.updateObb();
        this.box.geometry.computeBoundingBox();
        this.boundingBox = this.box.geometry.boundingBox;
    }
    updateObb() {
        this.obb.set(new THREE.Vector3(), new THREE.Vector3(), new THREE.Matrix3());
        const size = this.size;
        this.obb.halfSize
            .copy(new THREE.Vector3(size.width * 1.3, size.length * 1.3, size.height * 1.3))
            .multiplyScalar(0.5);
        this.obb.applyMatrix4(this.object.matrixWorld);
    }
    translateX(distance) {
        this.position.setX((this.position.x += distance));
        this.update();
    }
    translateY(distance) {
        this.position.setY((this.position.y += distance));
        this.update();
    }
    translateZ(distance) {
        this.position.setZ((this.position.z += distance));
        this.update();
    }
    resizeX(distance, direction = 1) {
        this.size.width += distance;
        const theta = this.rotation.z < 0 ? Math.PI * 2 + this.rotation.z : this.rotation.z;
        let x = 0;
        let y = 0;
        if (theta >= 0 && theta < Math.PI / 2) {
            console.log(1);
            x = distance * Math.cos(theta);
            y = distance * Math.sin(theta);
        }
        else if (theta >= Math.PI / 2 && theta < Math.PI) {
            console.log(2);
            x = distance * Math.cos(theta) * -1;
            y = distance * Math.sin(theta) * -1;
        }
        else if (theta >= Math.PI && theta < Math.PI * 1.5) {
            console.log(3);
            x = distance * Math.cos(theta) * -1;
            y = distance * Math.sin(theta) * -1;
        }
        else {
            console.log(4);
            x = distance * Math.cos(theta);
            y = distance * Math.sin(theta);
        }
        this.position.setX((this.position.x += (x * direction) / 2));
        this.position.setY((this.position.y += (y * direction) / 2));
        this.update();
    }
    resizeY(distance, direction = 1) {
        this.size.length += distance;
        const theta = modulo(this.rotation.z + Math.PI / 2);
        let x = 0;
        let y = 0;
        if (theta >= 0 && theta < Math.PI / 2) {
            console.log(1);
            x = distance * Math.cos(theta);
            y = distance * Math.sin(theta);
        }
        else if (theta >= Math.PI / 2 && theta < Math.PI) {
            console.log(2);
            x = distance * Math.cos(theta);
            y = distance * Math.sin(theta);
        }
        else if (theta >= Math.PI && theta < Math.PI * 1.5) {
            console.log(3);
            x = distance * Math.cos(theta) * -1;
            y = distance * Math.sin(theta) * -1;
        }
        else {
            console.log(4);
            x = distance * Math.cos(theta) * -1;
            y = distance * Math.sin(theta) * -1;
        }
        this.position.setX((this.position.x += (x * direction) / 2));
        this.position.setY((this.position.y += (y * direction) / 2));
        this.update();
    }
    resizeZ(distance, direction = 1) {
        this.size.height += distance;
        this.position.setZ((this.position.z += (distance * direction) / 2));
        this.update();
    }
    rotateZ(angle) {
        this.rotation.z += angle;
        this.rotation.z = normalizeAngle(this.rotation.z);
        this.update();
    }
}
function mod(n, m) {
    return ((n % m) + m) % m;
}
export function normalizeAngle(angle) {
    if (angle >= 2 * Math.PI) {
        return angle - 2 * Math.PI;
    }
    else if (angle <= -2 * Math.PI) {
        return angle + 2 * Math.PI;
    }
    if (angle > Math.PI) {
        return -Math.PI * 2 + angle;
    }
    else if (angle < -Math.PI) {
        return Math.PI * 2 + angle;
    }
    return angle;
}
