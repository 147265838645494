var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { IAnnotationAudit } from "definition/entity/annotation-audit";
import { Api } from "api/Api";
import { message } from "antd";
import { DailyWork } from "definition/entity/daily-work";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { ILabelPackage } from "definition/entity/label-package";
const MULTIPLE = 1000;
class Status {
    constructor() {
        this.loading = false;
        this.rankAudits = [];
        this.groups = [];
        this.users = [];
        this.auditPeriod = IAnnotationAudit.AuditPeriod.Daily;
        this.calendarDate = [];
        this.exportCSVData = [];
        this.CSVFilename = undefined;
        this.curGroupIds = [];
        this.curUserIds = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "rankAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IAnnotationAudit !== "undefined" && IAnnotationAudit.AuditPeriod) === "function" ? _a : Object)
], Status.prototype, "auditPeriod", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "calendarDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curGroupIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curUserIds", void 0);
class RankAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        let auditDateString = this.auditDateString;
        if (auditDateString.length === 0) {
            return;
        }
        const { curGroupIds, curUserIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const rankAudits = await Api.audit.getRankAudits({
                auditDateStart: auditDateString[0],
                auditDateEnd: moment(auditDateString[1]).format("YYYY-MM-DD"),
                groupIds: curGroupIds,
                userIds: curUserIds
            });
            rankAudits.data.forEach(v => {
                v.standardNum = Array.isArray(v.standardNum) ? v.standardNum : [v.standardNum];
            });
            this.status.rankAudits = rankAudits.data;
            this.status.CSVFilename = `${auditDateString[0]}_${auditDateString[1]}_rankAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadGroups() {
        const getGroupsRes = await Api.membership.listGroups();
        this.status.groups = getGroupsRes.data;
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        this.status.users = getUsersRes.data;
    }
    downAnnotationAuditFile() {
        const { exportCSVData, rankAudits } = this.status;
        exportCSVData.length = 0;
        this.transformedAnnotation.forEach(audit => {
            exportCSVData.push(["标注员", "标注总时长", "修改总时长", "复审总时长", "其它总时长", "工作总时长", "综合绩效", "排名"], [
                audit.username,
                audit.totalLabelDuration,
                audit.totalModifyDuration,
                audit.totalReviewDuration,
                audit.totalOtherDuration,
                audit.totalLabelDuration + audit.totalModifyDuration + audit.totalReviewDuration + audit.totalOtherDuration,
                audit.performanceVal,
                audit.order
            ], ["任务名称", "计价单位", "标注量", "标注时长", "标注效率", "修改时长", "修改结果", "复审时长", "其它时长"]);
            const childData = rankAudits.filter(item => audit.userId === item.creatorId);
            const tasksMap = new Map();
            childData.forEach(item => {
                let taskType = undefined;
                if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.Box) {
                    taskType = "个（检测框）";
                }
                else if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.Piece) {
                    taskType = "张（图片）";
                }
                else if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.MaskBox) {
                    taskType = "个（分割框）";
                }
                else if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.Object) {
                    taskType = "个 (关键帧) ";
                }
                else if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.Chunk) {
                    taskType = "段 (分类内容) ";
                }
                else if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.Anchor) {
                    taskType = "个 (时空锚对象) ";
                }
                else if ((item === null || item === void 0 ? void 0 : item.taskType) === LabelTaskBusiness.Type.Cloud) {
                    taskType = "个（点云）";
                }
                else {
                    taskType = "未知";
                }
                if (tasksMap.has(item.taskId)) {
                    const _prevEfficient = tasksMap.get(item.taskId).efficient;
                    const _workload = tasksMap.get(item.taskId).workload;
                    if (item.workType === DailyWork.WorkType.Label) {
                        if (_.isNil(item.cloudWorkload)) {
                            if (!_.isNil(item.taskType) && item.taskType === LabelTaskBusiness.Type.Cloud) {
                                tasksMap.get(item.taskId).efficient = _prevEfficient.map((prevEfficient, i) => {
                                    return !_.isNil(item.standardNum[i])
                                        ? (prevEfficient * MULTIPLE +
                                            ((0 - ((item === null || item === void 0 ? void 0 : item.standardNum[i]) / 7) * item.duration * MULTIPLE) / MULTIPLE) * MULTIPLE) /
                                            MULTIPLE
                                        : prevEfficient;
                                });
                            }
                            else {
                                tasksMap.get(item.taskId).efficient = _prevEfficient.map((prevEfficient, i) => {
                                    return !_.isNil(item.standardNum[i])
                                        ? (prevEfficient * MULTIPLE +
                                            ((((item === null || item === void 0 ? void 0 : item.workload) * MULTIPLE || 0) - ((item === null || item === void 0 ? void 0 : item.standardNum[i]) / 7) * item.duration * MULTIPLE) /
                                                MULTIPLE) *
                                                MULTIPLE) /
                                            MULTIPLE
                                        : prevEfficient;
                                });
                            }
                            tasksMap.get(item.taskId).workload = _workload.map((i, idx) => i + item.workload);
                            tasksMap.get(item.taskId).labelDuration =
                                (tasksMap.get(item.taskId).labelDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                        }
                        else {
                            tasksMap.get(item.taskId).workload = _workload.map((i, idx) => i + item.cloudWorkload[idx]);
                            tasksMap.get(item.taskId).labelDuration =
                                (tasksMap.get(item.taskId).labelDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                            tasksMap.get(item.taskId).efficient = item.standardNum.map((standardNum, idx) => {
                                return !_.isNil(standardNum)
                                    ? (((item === null || item === void 0 ? void 0 : item.cloudWorkload[idx]) * MULTIPLE || 0) - (standardNum / 7) * item.duration * MULTIPLE) /
                                        MULTIPLE
                                    : 0;
                            });
                        }
                    }
                    if (item.workType === DailyWork.WorkType.Modify) {
                        tasksMap.get(item.taskId).modifyDuration =
                            (tasksMap.get(item.taskId).modifyDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    }
                    if (item.workType === DailyWork.WorkType.Review) {
                        tasksMap.get(item.taskId).reviewDuration =
                            (tasksMap.get(item.taskId).reviewDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    }
                    if (item.workType === DailyWork.WorkType.Other) {
                        tasksMap.get(item.taskId).otherDuration =
                            (tasksMap.get(item.taskId).otherDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    }
                }
                else {
                    let auditMapValue = {
                        taskId: item.taskId || Math.random(),
                        taskName: item.taskName || item.affair,
                        taskType,
                        workload: [0],
                        labelDuration: 0,
                        efficient: [0],
                        modifyDuration: 0,
                        result: "",
                        reviewDuration: 0,
                        otherDuration: 0
                    };
                    if (item.workType === DailyWork.WorkType.Label) {
                        if (_.isNil(item.cloudWorkload)) {
                            if (!_.isNil(item.taskType) && item.taskType === LabelTaskBusiness.Type.Cloud) {
                                auditMapValue.efficient = item.standardNum.map(standardNum => {
                                    return !_.isNil(standardNum) ? (-(standardNum / 7) * item.duration * MULTIPLE) / MULTIPLE : 0;
                                });
                            }
                            else {
                                auditMapValue.efficient = item.standardNum.map(standardNum => {
                                    return !_.isNil(standardNum)
                                        ? (((item === null || item === void 0 ? void 0 : item.workload) * MULTIPLE || 0) - (standardNum / 7) * item.duration * MULTIPLE) / MULTIPLE
                                        : 0;
                                });
                            }
                            auditMapValue.workload = [item.workload];
                            auditMapValue.labelDuration = item.duration;
                        }
                        else {
                            auditMapValue.workload = item.cloudWorkload;
                            auditMapValue.labelDuration = item.duration;
                            auditMapValue.efficient = item.standardNum.map((standardNum, idx) => {
                                return !_.isNil(standardNum)
                                    ? (((item === null || item === void 0 ? void 0 : item.cloudWorkload[idx]) * MULTIPLE || 0) - (standardNum / 7) * item.duration * MULTIPLE) /
                                        MULTIPLE
                                    : 0;
                            });
                        }
                    }
                    if (item.workType === DailyWork.WorkType.Modify) {
                        auditMapValue.modifyDuration = item.duration;
                    }
                    if (item.workType === DailyWork.WorkType.Review) {
                        auditMapValue.reviewDuration = item.duration;
                    }
                    if (item.workType === DailyWork.WorkType.Other) {
                        auditMapValue.otherDuration = item.duration;
                    }
                    const assessPackages = item.packages.filter(i => i.assessNum >= 2);
                    if (assessPackages.length > 0) {
                        const adopt = assessPackages.filter(i => [
                            ILabelPackage.Status.Reviewed,
                            ILabelPackage.Status.Archived,
                            ILabelPackage.Status.AssessPassed,
                            ILabelPackage.Status.AssignReview,
                            ILabelPackage.Status.Labeled,
                            ILabelPackage.Status.Reviewing,
                            ILabelPackage.Status.AssignFinalReview,
                            ILabelPackage.Status.FinalReviewing,
                            ILabelPackage.Status.FinalReviewed
                        ].includes(i.status)).length;
                        auditMapValue.result = `${adopt} 通过，${assessPackages.length - adopt} 不通过`;
                    }
                    else {
                        auditMapValue.result = "-";
                    }
                    tasksMap.set(item.taskId || Math.random(), auditMapValue);
                }
            });
            [...tasksMap.values()].forEach(item => {
                exportCSVData.push([
                    item.taskName,
                    item.taskType,
                    item.workload.join("|"),
                    item.labelDuration,
                    item.efficient.map(v => v.toFixed(3)).join("|"),
                    item.modifyDuration,
                    item.result,
                    item.reviewDuration,
                    item.otherDuration
                ]);
            });
        });
    }
    get transformedAnnotation() {
        var _a;
        const rankAuditsMap = new Map();
        (_a = this.status.rankAudits) === null || _a === void 0 ? void 0 : _a.forEach(item => {
            if (rankAuditsMap.has(item.creatorId)) {
                const _prevPerformanceVal = rankAuditsMap.get(item.creatorId).performanceVal;
                if (item.workType === DailyWork.WorkType.Label) {
                    rankAuditsMap.get(item.creatorId).totalLabelDuration =
                        (rankAuditsMap.get(item.creatorId).totalLabelDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    if (_.isNil(item.cloudWorkload)) {
                        if (!_.isNil(item.taskType) && item.taskType === LabelTaskBusiness.Type.Cloud) {
                            rankAuditsMap.get(item.creatorId).performanceVal = [
                                _prevPerformanceVal.reduce((pre, i) => pre + i) - (item.duration * MULTIPLE) / (7 * MULTIPLE)
                            ];
                        }
                        else {
                            rankAuditsMap.get(item.creatorId).performanceVal = _prevPerformanceVal.map((prevPerformanceVal, i) => {
                                return !_.isNil(item.standardNum[i])
                                    ? item.standardNum[i] !== 0
                                        ? (prevPerformanceVal * MULTIPLE +
                                            ((((((item === null || item === void 0 ? void 0 : item.workload) * MULTIPLE || 0) - ((item === null || item === void 0 ? void 0 : item.standardNum[i]) / 7) * item.duration * MULTIPLE) /
                                                MULTIPLE) *
                                                MULTIPLE) /
                                                (item.standardNum[i] * MULTIPLE)) *
                                                MULTIPLE) /
                                            MULTIPLE
                                        : prevPerformanceVal
                                    : prevPerformanceVal;
                            });
                        }
                    }
                    else {
                        const performanceVal = item.standardNum.map((standardNum, idx) => {
                            return !_.isNil(standardNum)
                                ? standardNum !== 0
                                    ? ((item === null || item === void 0 ? void 0 : item.cloudWorkload[idx]) * MULTIPLE || 0) / (standardNum * MULTIPLE)
                                    : 0
                                : 0;
                        });
                        rankAuditsMap.get(item.creatorId).performanceVal = [
                            _prevPerformanceVal.reduce((pre, i) => pre + i) +
                                performanceVal.reduce((pre, i) => pre + i) -
                                (item.duration * MULTIPLE) / (7 * MULTIPLE)
                        ];
                    }
                }
                if (item.workType === DailyWork.WorkType.Modify) {
                    rankAuditsMap.get(item.creatorId).totalModifyDuration =
                        (rankAuditsMap.get(item.creatorId).totalModifyDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                    rankAuditsMap.get(item.creatorId).performanceVal = _prevPerformanceVal.map((prevPerformanceVal, i) => {
                        return !_.isNil(item.standardNum[i])
                            ? (prevPerformanceVal * MULTIPLE + ((-item.duration * MULTIPLE) / (7 * MULTIPLE)) * MULTIPLE) / MULTIPLE
                            : prevPerformanceVal;
                    });
                }
                if (item.workType === DailyWork.WorkType.Review) {
                    rankAuditsMap.get(item.creatorId).totalReviewDuration =
                        (rankAuditsMap.get(item.creatorId).totalReviewDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                }
                if (item.workType === DailyWork.WorkType.Other) {
                    rankAuditsMap.get(item.creatorId).totalOtherDuration =
                        (rankAuditsMap.get(item.creatorId).totalOtherDuration * MULTIPLE + item.duration * MULTIPLE) / MULTIPLE;
                }
            }
            else {
                let auditMapValue = {
                    userId: item.creatorId,
                    username: item.userNickname,
                    nickname: item.userNickname,
                    totalLabelDuration: 0,
                    totalModifyDuration: 0,
                    totalReviewDuration: 0,
                    totalOtherDuration: 0,
                    performanceVal: [0]
                };
                if (item.workType === DailyWork.WorkType.Label) {
                    auditMapValue.totalLabelDuration = (item.duration * MULTIPLE) / MULTIPLE;
                    if (_.isNil(item.cloudWorkload)) {
                        if (!_.isNil(item.taskType) && item.taskType === LabelTaskBusiness.Type.Cloud) {
                            auditMapValue.performanceVal = [-(item.duration * MULTIPLE) / (7 * MULTIPLE)];
                        }
                        else {
                            auditMapValue.performanceVal = item.standardNum.map((standardNum, idx) => {
                                return !_.isNil(standardNum)
                                    ? standardNum !== 0
                                        ? (((((item === null || item === void 0 ? void 0 : item.workload) * MULTIPLE || 0) - (standardNum / 7) * item.duration * MULTIPLE) / MULTIPLE) *
                                            MULTIPLE) /
                                            (standardNum * MULTIPLE)
                                        : 0
                                    : 0;
                            });
                        }
                    }
                    else {
                        const performanceVal = item.standardNum.map((standardNum, idx) => {
                            return !_.isNil(standardNum)
                                ? standardNum !== 0
                                    ? ((item === null || item === void 0 ? void 0 : item.cloudWorkload[idx]) * MULTIPLE || 0) / (standardNum * MULTIPLE)
                                    : 0
                                : 0;
                        });
                        auditMapValue.performanceVal = [
                            performanceVal.reduce((pre, i) => pre + i) - (item.duration * MULTIPLE) / (7 * MULTIPLE)
                        ];
                    }
                }
                if (item.workType === DailyWork.WorkType.Modify) {
                    auditMapValue.totalModifyDuration = (item.duration * MULTIPLE) / MULTIPLE;
                    auditMapValue.performanceVal = item.standardNum.map(standardNum => {
                        return !_.isNil(standardNum) ? (-item.duration * MULTIPLE) / (7 * MULTIPLE) : 0;
                    });
                }
                if (item.workType === DailyWork.WorkType.Review) {
                    auditMapValue.totalReviewDuration = (item.duration * MULTIPLE) / MULTIPLE;
                }
                if (item.workType === DailyWork.WorkType.Other) {
                    auditMapValue.totalOtherDuration = (item.duration * MULTIPLE) / MULTIPLE;
                }
                rankAuditsMap.set(item.creatorId, auditMapValue);
            }
        });
        const res = _.orderBy([...rankAuditsMap.values()], a => {
            return a.performanceVal.reduce((s, a) => s + a, 0);
        }, ["desc"]).map((item, index) => {
            var _a;
            return (Object.assign(Object.assign({}, item), { performanceVal: (_a = item.performanceVal) === null || _a === void 0 ? void 0 : _a.reduce((arr, cur) => arr + cur), order: index + 1 }));
        });
        return res;
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.rankAudits));
    }
    get isUnableSearch() {
        return _.isEmpty(toJS(this.status.calendarDate)) || this.status.loading;
    }
    get auditDateString() {
        const { calendarDate, auditPeriod } = this.status;
        let auditDateString = undefined;
        switch (auditPeriod) {
            case IAnnotationAudit.AuditPeriod.Daily:
                auditDateString = calendarDate.map(item => item.format("YYYY-MM-DD"));
                break;
            case IAnnotationAudit.AuditPeriod.Weekly:
                auditDateString = calendarDate.map(item => item.endOf("week").format("YYYY-MM-DD"));
                break;
            case IAnnotationAudit.AuditPeriod.Monthly:
                auditDateString = calendarDate.map(item => item.endOf("month").format("YYYY-MM-DD"));
                break;
            default:
                return;
        }
        return auditDateString;
    }
    get filterUsers() {
        const { users, curGroupIds } = toJS(this.status);
        if (_.isEmpty(curGroupIds)) {
            return users;
        }
        return users.filter(item => { var _a; return curGroupIds.includes((_a = item === null || item === void 0 ? void 0 : item.group) === null || _a === void 0 ? void 0 : _a.id); });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], RankAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], RankAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], RankAuditStore.prototype, "transformedAnnotation", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], RankAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], RankAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], RankAuditStore.prototype, "auditDateString", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], RankAuditStore.prototype, "filterUsers", null);
export const rankAuditStore = new RankAuditStore();
