var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Card, Input, Popover, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AnchorTool } from "definition/entity/label-task";
import { observer } from "mobx-react";
import React from "react";
import { CompactPicker } from "react-color";
import "./AnchorItem.less";
let AnchorItem = class AnchorItem extends React.Component {
    render() {
        const { anchor, onDelete } = this.props;
        return (<div className="anchor-item-comp">
        <Card className="classification-item-comp">
          <div className="anchor-outside" onClick={e => {
            e.stopPropagation();
        }}>
            <Popover content={<CompactPicker style={{ width: 200 }} value={anchor.color} onChange={(color) => {
            anchor.color = color.hex;
        }}/>}>
              <div style={{ width: 40, height: 40, borderRadius: "50%", background: anchor.color }}/>
            </Popover>
            <Input placeholder="标注 key" value={anchor.key} onChange={event => {
            anchor.key = event.target.value;
        }}/>
            <Input placeholder="中文名称" value={anchor.locale} onChange={event => {
            anchor.locale = event.target.value;
        }}/>
            <Select value={anchor.tool} onChange={(value, option) => {
            anchor.tool = value;
        }} placeholder="选择类型">
              <Select.Option key={AnchorTool.Dot}>锚点</Select.Option>
              
              
            </Select>
            <DeleteOutlined onClick={() => {
            onDelete();
        }}/>
          </div>
        </Card>
      </div>);
    }
};
AnchorItem = __decorate([
    observer
], AnchorItem);
export { AnchorItem };
