var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import { Form, Input, Modal, Select, Switch, Table, Button } from "antd";
import { IUserRole } from "../../../../../../../definition/entity/user-role";
import { userManageStore } from "./UserManageStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { ILabelPackage } from "definition/entity/label-package";
import { transformLabelPackageStatus } from "util/status-transform";
let UserManage = class UserManage extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            { title: "用户名称", dataIndex: "name" },
            { title: "GitLab 邮箱", key: "gitlabEmail", render: (value, record) => { var _a; return (_a = record.gitlab) === null || _a === void 0 ? void 0 : _a.email; } },
            { title: "单位名称", render: (value, record) => { var _a; return (_a = record.group) === null || _a === void 0 ? void 0 : _a.name; } },
            {
                title: "系统管理员",
                align: "center",
                render: (value, record) => {
                    const userRole = _.find(record.userRoles, role => role.role === IUserRole.Role.Admin);
                    return userRole ? "是" : "否";
                }
            },
            {
                title: "待处理任务",
                dataIndex: "pendingTask",
                align: "center",
                render(value, record) {
                    return (<Button type="link" size="small" onClick={async () => {
                        userManageStore.status.curUserId = record.id;
                        userManageStore.status.curUsername = record.name;
                        await userManageStore.loadPendingTasks();
                        userManageStore.status.isPendingTaskModalVisible = true;
                    }}>
            {value}
          </Button>);
                }
            },
            {
                title: "待处理包",
                dataIndex: "pendingPackage",
                align: "center",
                render(value, record) {
                    return (<Button type="link" size="small" onClick={async () => {
                        userManageStore.status.curUserId = record.id;
                        userManageStore.status.curUsername = record.name;
                        await userManageStore.loadPendingPackages();
                        userManageStore.status.isPendingPackageModalVisible = true;
                    }}>
            {value}
          </Button>);
                }
            },
            {
                title: "操作",
                align: "center",
                render: (value, record) => {
                    return (<div>
            <Button type="link" size="small" onClick={async () => {
                        var _a, _b;
                        await userManageStore.loadGroups();
                        userManageStore.status.isModifyModalVisible = true;
                        userManageStore.status.curModifyUser = {
                            id: record.id,
                            name: record.name,
                            nickname: record.nickname,
                            admin: !!_.find(record.userRoles, role => role.role === IUserRole.Role.Admin),
                            group: {
                                id: (_a = record.group) === null || _a === void 0 ? void 0 : _a.id,
                                name: (_b = record.group) === null || _b === void 0 ? void 0 : _b.name
                            }
                        };
                    }}>
              修改
            </Button>
            
          </div>);
                }
            }
        ];
        this.pendingTaskColumns = [
            { title: "任务名称", width: 240, ellipsis: true, dataIndex: "name" },
            {
                title: "计价类型",
                render(value, record) {
                    var _a;
                    return (_a = record.taskBusiness) === null || _a === void 0 ? void 0 : _a.name;
                }
            },
            {
                title: "操作",
                align: "center",
                render: (value, record) => {
                    return (<div>
            <Button type="link" size="small" onClick={() => {
                        userManageStore.status.selectedTasks = [record];
                        userManageStore.status.isConfirmPendingTaskModalVisible = true;
                    }}>
              转标
            </Button>
          </div>);
                }
            }
        ];
        this.pendingPackageColumns = [
            { title: "标注包名称", width: 240, ellipsis: true, dataIndex: "name" },
            {
                title: "分包状态",
                align: "center",
                render: (value, record) => {
                    return <div>{transformLabelPackageStatus(record)}</div>;
                }
            },
            {
                title: "操作",
                align: "center",
                render: (value, record) => {
                    if (record.status === ILabelPackage.Status.Assessing) {
                        return "";
                    }
                    return (<div>
            <Button type="link" size="small" onClick={() => {
                        userManageStore.status.selectedPackages = [record];
                        userManageStore.status.isConfirmPendingPackageModalVisible = true;
                    }}>
              转包
            </Button>
            <Button type="link" size="small" onClick={() => {
                        userManageStore.status.selectedPackages = [record];
                        userManageStore.status.isRecoverPendingPackageModalVisible = true;
                    }}>
              回收
            </Button>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        userManageStore.loadData();
        userManageStore.loadAllUsers();
    }
    render() {
        var _a, _b, _c, _d, _e;
        const status = userManageStore.status;
        return (<div>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        }}>
          <Input.Search style={{ width: "240px" }} value={status.nameFilter} enterButton="搜索" onSearch={value => {
            userManageStore.loadData();
        }} onChange={event => {
            userManageStore.setStatus({
                nameFilter: event.target.value
            });
        }}/>
        </div>

        <Table rowKey={record => "user-manage-" + record.id} bordered={true} style={{ marginTop: "20px" }} dataSource={toJS(status.users)} columns={this.columns} pagination={{ showSizeChanger: false }}/>

        <Modal title="用户修改" open={status.isModifyModalVisible} onOk={() => {
            userManageStore.updateModifyingUser();
        }} onCancel={() => {
            userManageStore.status.curModifyUser = null;
            userManageStore.status.isModifyModalVisible = false;
        }}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="用户名">
              <Input disabled={true} value={(_a = status.curModifyUser) === null || _a === void 0 ? void 0 : _a.name}/>
            </Form.Item>
            <Form.Item label="昵称">
              <Input value={(_b = status.curModifyUser) === null || _b === void 0 ? void 0 : _b.nickname} onChange={event => (status.curModifyUser.nickname = event.target.value)}/>
            </Form.Item>
            <Form.Item label="单位">
              <Select showSearch={true} allowClear={true} value={(_d = (_c = status.curModifyUser) === null || _c === void 0 ? void 0 : _c.group) === null || _d === void 0 ? void 0 : _d.id} onChange={(value, option) => {
            const matchGroup = _.find(status.groups, item => item.id === value);
            if (matchGroup) {
                status.curModifyUser.group = {
                    id: matchGroup.id,
                    name: matchGroup.name
                };
                userManageStore.setStatus({
                    curModifyUser: status.curModifyUser
                });
            }
            else {
                status.curModifyUser.group = null;
            }
        }}>
                {status.groups.map(group => (<Select.Option key={String(group.id)} value={group.id}>
                    {group.name}
                  </Select.Option>))}
              </Select>
            </Form.Item>
            <Form.Item label="系统管理员">
              <Switch checked={(_e = status.curModifyUser) === null || _e === void 0 ? void 0 : _e.admin} onChange={checked => {
            status.curModifyUser.admin = checked;
            userManageStore.setStatus({
                curModifyUser: status.curModifyUser
            });
        }}/>
            </Form.Item>
          </Form>
        </Modal>

        <Modal title={`处理用户${status.curUsername}的任务迁移`} width={800} open={status.isPendingTaskModalVisible} maskClosable={false} footer={null} onCancel={() => {
            userManageStore.status.isPendingTaskModalVisible = false;
        }}>
          <Button type="primary" style={{ marginBottom: 10 }} disabled={status.selectedTasks.length === 0} onClick={() => {
            if (toJS(status.selectedTaskIds).length > 0) {
                status.isConfirmPendingTaskModalVisible = true;
            }
        }}>
            转标
          </Button>
          <Table columns={this.pendingTaskColumns} dataSource={toJS(status.pendingTasks)} rowKey={record => "user-manage-" + record.id} bordered={true} pagination={false} rowSelection={{
            selectedRowKeys: userManageStore.status.selectedTaskIds,
            onChange: (selectedRowKeys, selectedRows) => {
                userManageStore.setStatus({
                    selectedTaskIds: selectedRowKeys,
                    selectedTasks: selectedRows
                });
            }
        }}/>
        </Modal>

        <Modal title="提示" width={800} open={status.isConfirmPendingTaskModalVisible} maskClosable={false} onOk={async () => {
            await userManageStore.updatePendingTasks();
            status.selectedTasks = [];
            status.selectedTaskIds = [];
            status.isPendingTaskModalVisible = false;
            status.isConfirmPendingTaskModalVisible = false;
            await userManageStore.loadData();
        }} onCancel={() => {
            status.selectedTasks = [];
            status.selectedTaskIds = [];
            status.isConfirmPendingTaskModalVisible = false;
        }}>
          <div style={{ width: "100%" }}>
            <p>请确认是否将下列{status.selectedTasks.length}个标注任务进行转标</p>
            {status.selectedTasks.map(i => (<p key={i.id}>{i.name}</p>))}
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <Form.Item label="转标用户">
                <Select showSearch={true} allowClear={true} value={status.assignTaskUserId} onChange={(value, option) => {
            status.assignTaskUserId = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                  {status.allUsers.map(user => (<Select.Option key={String(user.id)} title={user.name} value={user.id}>
                      {user.name}
                    </Select.Option>))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal title={`处理用户${status.curUsername}的标注包迁移`} width={800} open={status.isPendingPackageModalVisible} maskClosable={false} footer={null} onCancel={() => {
            userManageStore.status.isPendingPackageModalVisible = false;
        }}>
          <Button type="primary" style={{ marginBottom: 10, marginRight: 10 }} disabled={status.selectedPackages.length === 0} onClick={() => {
            if (toJS(status.selectedPackageIds).length > 0) {
                status.isConfirmPendingPackageModalVisible = true;
            }
        }}>
            转包
          </Button>
          <Button type="primary" style={{ marginBottom: 10 }} disabled={status.selectedPackages.length === 0} onClick={() => {
            if (toJS(status.selectedPackageIds).length > 0) {
                status.isRecoverPendingPackageModalVisible = true;
            }
        }}>
            回收
          </Button>
          <Table columns={this.pendingPackageColumns} dataSource={toJS(status.pendingPackages)} rowKey={record => "user-manage-" + record.id} bordered={true} pagination={false} rowSelection={{
            getCheckboxProps(record) {
                if (record.status === ILabelPackage.Status.Assessing) {
                    return {
                        disabled: true
                    };
                }
            },
            selectedRowKeys: userManageStore.status.selectedPackageIds,
            onChange: (selectedRowKeys, selectedRows) => {
                userManageStore.setStatus({
                    selectedPackageIds: selectedRowKeys,
                    selectedPackages: selectedRows
                });
            }
        }}/>
        </Modal>

        <Modal title="提示" width={800} open={status.isConfirmPendingPackageModalVisible} maskClosable={false} onOk={async () => {
            await userManageStore.updatePendingPackages();
            status.selectedPackages = [];
            status.selectedPackageIds = [];
            status.isPendingPackageModalVisible = false;
            status.isConfirmPendingPackageModalVisible = false;
            await userManageStore.loadData();
        }} onCancel={() => {
            status.selectedPackages = [];
            status.selectedPackageIds = [];
            status.isConfirmPendingPackageModalVisible = false;
        }}>
          <div style={{ width: "100%" }}>
            <p>请确认是否将下列{status.selectedPackages.length}个标注包进行转包</p>
            {status.selectedPackages.map(i => (<p key={i.id}>{i.name}</p>))}
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <Form.Item label="转包用户">
                <Select showSearch={true} allowClear={true} value={status.assignPackageUserId} onChange={(value, option) => {
            status.assignPackageUserId = value;
        }} filterOption={(input, option) => {
            if (option && option.props && option.props.title) {
                return option.props.title === input || option.props.title.indexOf(input) !== -1;
            }
            else {
                return true;
            }
        }}>
                  {status.allUsers.map(user => (<Select.Option key={String(user.id)} title={user.name} value={user.id}>
                      {user.name}
                    </Select.Option>))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal title="提示" width={800} open={status.isRecoverPendingPackageModalVisible} onOk={async () => {
            await userManageStore.recoverPendingPackages();
            status.selectedPackages = [];
            status.selectedPackageIds = [];
            status.isPendingPackageModalVisible = false;
            status.isRecoverPendingPackageModalVisible = false;
            await userManageStore.loadData();
        }} onCancel={() => {
            status.selectedPackages = [];
            status.selectedPackageIds = [];
            status.isRecoverPendingPackageModalVisible = false;
        }}>
          <div style={{ width: "100%" }}>
            <p>请确认是否将下列{status.selectedPackages.length}个标注包进行回收</p>
            {status.selectedPackages.map(i => (<p key={i.id}>{i.name}</p>))}
          </div>
        </Modal>
      </div>);
    }
};
UserManage = __decorate([
    observer
], UserManage);
export { UserManage };
