var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { IAnnotationAudit } from "definition/entity/annotation-audit";
import { Api } from "api/Api";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { message } from "antd";
import { transformLabelPackageStatus } from "util/status-transform";
class Status {
    constructor() {
        this.loading = false;
        this.taskAudits = [];
        this.businesses = [];
        this.tasks = [];
        this.auditPeriod = IAnnotationAudit.AuditPeriod.Daily;
        this.calendarDate = moment();
        this.exportCSVData = [];
        this.CSVFilename = undefined;
        this.curBusinessIds = [];
        this.curTaskIds = [];
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "businesses", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "tasks", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IAnnotationAudit !== "undefined" && IAnnotationAudit.AuditPeriod) === "function" ? _a : Object)
], Status.prototype, "auditPeriod", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "calendarDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curBusinessIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curTaskIds", void 0);
class TaskAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        let auditDateString = this.auditDateString;
        if (!auditDateString) {
            return;
        }
        const { auditPeriod, curBusinessIds, curTaskIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const taskAudits = await Api.audit.getTaskAudits({
                auditPeriod: auditPeriod,
                auditDateString: auditDateString,
                businessIds: curBusinessIds,
                taskIds: curTaskIds
            });
            this.status.taskAudits = _.sortBy(taskAudits.data, item => item.labelTask.id);
            this.status.CSVFilename = `${auditDateString}_taskAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadBusinesses() {
        const getBusinessesRes = await Api.taskBusiness.listTaskBusinesses();
        this.status.businesses = getBusinessesRes.data;
    }
    async loadTasks() {
        const getTasksRes = await Api.labelTask.listLabelTasks();
        this.status.tasks = getTasksRes.data;
    }
    downAnnotationAuditFile() {
        const { taskAudits, exportCSVData } = this.status;
        exportCSVData.length = 0;
        taskAudits.forEach(audit => {
            var _a, _b, _c, _d;
            let businessType = undefined;
            let detectionCount = "-";
            let classificationCount = "-";
            let segmentCount = "-";
            let segment3DCount = "-";
            let objectCount = 0;
            let trailCount = 0;
            let chunkCount = 0;
            let duration = 0;
            switch ((_a = audit.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) {
                case LabelTaskBusiness.Type.Box:
                    businessType = "个（检测框）";
                    detectionCount = `${audit.detectionCount} (框)`;
                    break;
                case LabelTaskBusiness.Type.Piece:
                    businessType = "张（图片）";
                    classificationCount = `${audit.classificationCount} (张)`;
                    break;
                case LabelTaskBusiness.Type.MaskBox:
                    businessType = "个（分割框）";
                    segmentCount = `${audit.segmentCount} (框)`;
                    break;
                case LabelTaskBusiness.Type.Object:
                    businessType = "个（关键帧）";
                    objectCount = audit.objectCount;
                    trailCount = audit.trailCount;
                    break;
                case LabelTaskBusiness.Type.Chunk:
                    chunkCount = audit.chunkCount;
                    duration = audit.duration;
                    businessType = "段（分类内容）";
                    break;
                case LabelTaskBusiness.Type.Anchor:
                    objectCount = audit.anchorCount;
                    trailCount = audit.anchorTrailCount;
                    businessType = "个（时空锚对象）";
                    break;
                case LabelTaskBusiness.Type.Cloud:
                    segment3DCount = `${audit.segment3dCount}(${audit.segment3dCount - audit.inheritedSegment3dCount} + ${audit.inheritedSegment3dCount})`;
                default:
                    businessType = "未知";
                    break;
            }
            exportCSVData.push({
                labelUser: `${audit.user.nickname}(${audit.user.name})`,
                packageName: (_b = audit.labelPackage) === null || _b === void 0 ? void 0 : _b.name,
                packageStatus: transformLabelPackageStatus(audit.labelPackage),
                taskName: (_c = audit.labelTask) === null || _c === void 0 ? void 0 : _c.name,
                businessName: ((_d = audit.labelTaskBusiness) === null || _d === void 0 ? void 0 : _d.name) || "未分配类型",
                businessType: businessType,
                detectionCount: detectionCount,
                classificationCount: classificationCount,
                segmentCount,
                segment3DCount,
                objectCount,
                trailCount,
                chunkCount,
                duration
            });
        });
    }
    get transformedAnnotation() {
        const taskAuditsMap = new Map();
        this.status.taskAudits.forEach(item => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
            if (taskAuditsMap.has((_a = item.labelTask) === null || _a === void 0 ? void 0 : _a.id)) {
                if (((_b = item.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Box) {
                    taskAuditsMap.get((_c = item.labelTask) === null || _c === void 0 ? void 0 : _c.id).totalDetectionCount += item.detectionCount;
                }
                if (((_d = item.labelTaskBusiness) === null || _d === void 0 ? void 0 : _d.type) === LabelTaskBusiness.Type.Piece) {
                    taskAuditsMap.get((_e = item.labelTask) === null || _e === void 0 ? void 0 : _e.id).totalClassificationCount += item.classificationCount;
                }
                if (((_f = item.labelTaskBusiness) === null || _f === void 0 ? void 0 : _f.type) === LabelTaskBusiness.Type.MaskBox) {
                    taskAuditsMap.get((_g = item.labelTask) === null || _g === void 0 ? void 0 : _g.id).totalSegmentCount += item.segmentCount;
                }
                if (((_h = item.labelTaskBusiness) === null || _h === void 0 ? void 0 : _h.type) === LabelTaskBusiness.Type.Cloud) {
                    taskAuditsMap.get((_j = item.labelTask) === null || _j === void 0 ? void 0 : _j.id).totalSegment3dCount += item.segment3dCount;
                    taskAuditsMap.get((_k = item.labelTask) === null || _k === void 0 ? void 0 : _k.id).totalInheritedSegment3dCount += item.inheritedSegment3dCount;
                }
                if (((_l = item.labelTaskBusiness) === null || _l === void 0 ? void 0 : _l.type) === LabelTaskBusiness.Type.Object) {
                    taskAuditsMap.get((_m = item.labelTask) === null || _m === void 0 ? void 0 : _m.id).totalObjectCount += item.objectCount;
                    taskAuditsMap.get((_o = item.labelTask) === null || _o === void 0 ? void 0 : _o.id).totalTrailCount += item.trailCount;
                }
                if (((_p = item.labelTaskBusiness) === null || _p === void 0 ? void 0 : _p.type) === LabelTaskBusiness.Type.Anchor) {
                    taskAuditsMap.get((_q = item.labelTask) === null || _q === void 0 ? void 0 : _q.id).totalObjectCount += item.anchorCount;
                    taskAuditsMap.get((_r = item.labelTask) === null || _r === void 0 ? void 0 : _r.id).totalTrailCount += item.anchorTrailCount;
                }
                if (((_s = item.labelTaskBusiness) === null || _s === void 0 ? void 0 : _s.type) === LabelTaskBusiness.Type.Chunk) {
                    taskAuditsMap.get((_t = item.labelTask) === null || _t === void 0 ? void 0 : _t.id).totalChunkCount += item.chunkCount;
                    taskAuditsMap.get((_u = item.labelTask) === null || _u === void 0 ? void 0 : _u.id).totalDuration += item.duration;
                }
            }
            else {
                let auditMap = {
                    taskId: (_v = item.labelTask) === null || _v === void 0 ? void 0 : _v.id,
                    taskName: (_w = item.labelTask) === null || _w === void 0 ? void 0 : _w.name,
                    businessName: (_x = item.labelTaskBusiness) === null || _x === void 0 ? void 0 : _x.name,
                    businessType: (_y = item.labelTaskBusiness) === null || _y === void 0 ? void 0 : _y.type,
                    totalDetectionCount: 0,
                    totalClassificationCount: 0,
                    totalSegmentCount: 0,
                    totalSegment3dCount: 0,
                    totalInheritedSegment3dCount: 0,
                    totalObjectCount: 0,
                    totalTrailCount: 0,
                    totalChunkCount: 0,
                    totalDuration: 0,
                    resourceMime: item.resourceMime
                };
                if (((_z = item.labelTaskBusiness) === null || _z === void 0 ? void 0 : _z.type) === LabelTaskBusiness.Type.Box) {
                    auditMap.totalDetectionCount = item.detectionCount;
                }
                if (((_0 = item.labelTaskBusiness) === null || _0 === void 0 ? void 0 : _0.type) === LabelTaskBusiness.Type.Piece) {
                    auditMap.totalClassificationCount = item.classificationCount;
                }
                if (((_1 = item.labelTaskBusiness) === null || _1 === void 0 ? void 0 : _1.type) === LabelTaskBusiness.Type.MaskBox) {
                    auditMap.totalSegmentCount = item.segmentCount;
                }
                if (((_2 = item.labelTaskBusiness) === null || _2 === void 0 ? void 0 : _2.type) === LabelTaskBusiness.Type.Cloud) {
                    auditMap.totalSegment3dCount = item.segment3dCount;
                    auditMap.totalInheritedSegment3dCount = item.inheritedSegment3dCount;
                }
                if (((_3 = item.labelTaskBusiness) === null || _3 === void 0 ? void 0 : _3.type) === LabelTaskBusiness.Type.Object) {
                    auditMap.totalObjectCount = item.objectCount;
                    auditMap.totalTrailCount = item.trailCount;
                }
                if (((_4 = item.labelTaskBusiness) === null || _4 === void 0 ? void 0 : _4.type) === LabelTaskBusiness.Type.Anchor) {
                    auditMap.totalObjectCount = item.anchorCount;
                    auditMap.totalTrailCount = item.anchorTrailCount;
                }
                if (((_5 = item.labelTaskBusiness) === null || _5 === void 0 ? void 0 : _5.type) === LabelTaskBusiness.Type.Chunk) {
                    auditMap.totalChunkCount = item.chunkCount;
                    auditMap.totalDuration = item.duration;
                }
                taskAuditsMap.set((_6 = item.labelTask) === null || _6 === void 0 ? void 0 : _6.id, auditMap);
            }
        });
        return [...taskAuditsMap.values()];
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.taskAudits));
    }
    get isUnableSearch() {
        return _.isNil(toJS(this.status.calendarDate)) || this.status.loading;
    }
    get auditDateString() {
        const { calendarDate, auditPeriod } = this.status;
        let auditDateString = undefined;
        switch (auditPeriod) {
            case IAnnotationAudit.AuditPeriod.Daily:
                auditDateString = calendarDate.format("YYYY-MM-DD");
                break;
            case IAnnotationAudit.AuditPeriod.Weekly:
                auditDateString = calendarDate.endOf("week").format("YYYY-MM-DD");
                break;
            case IAnnotationAudit.AuditPeriod.Monthly:
                auditDateString = calendarDate.endOf("month").format("YYYY-MM-DD");
                break;
            default:
                return;
        }
        return auditDateString;
    }
    get filterTasks() {
        const { tasks, curBusinessIds } = toJS(this.status);
        if (_.isEmpty(curBusinessIds)) {
            return tasks;
        }
        return tasks.filter(item => curBusinessIds.includes(item.businessId));
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TaskAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TaskAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TaskAuditStore.prototype, "transformedAnnotation", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TaskAuditStore.prototype, "auditDateString", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TaskAuditStore.prototype, "filterTasks", null);
export const taskAuditStore = new TaskAuditStore();
