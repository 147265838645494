var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import BasicStore from "../../../../store/BasicStore";
import { action, computed, observable, toJS } from "mobx";
import { IResource } from "../../../../definition/entity/resource";
import { Api } from "../../../../api/Api";
import { routerStore } from "../../../../store/routerStore";
import { ILabelTask } from "../../../../definition/entity/label-task";
class Status {
    constructor() {
        this.name = "";
        this.remark = "";
        this.resourceMime = IResource.Mime.Image;
        this.isEditorValid = false;
        this.isLoading = false;
        this.fileName = "";
        this.file = null;
        this.taskType = ILabelTask.TaskType.Label;
        this.label = {};
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "remark", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IResource !== "undefined" && IResource.Mime) === "function" ? _a : Object)
], Status.prototype, "resourceMime", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof ILabelTask !== "undefined" && ILabelTask.LabelTaskType) === "function" ? _b : Object)
], Status.prototype, "labelTaskType", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isEditorValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "fileName", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "file", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "taskType", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "label", void 0);
class TaskTemplateCreateStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async createTaskTemplate(label) {
        const { name, remark, resourceMime, file, taskType, labelTaskType } = this.status;
        this.status.isLoading = true;
        let reqParams = new FormData();
        reqParams.append("name", name);
        reqParams.append("remark", remark);
        reqParams.append("resourceMime", `${resourceMime}`);
        reqParams.append("label", JSON.stringify(label));
        reqParams.append("taskType", taskType);
        reqParams.append("labelTaskType", labelTaskType);
        if (file) {
            reqParams.append("labelDoc", file);
        }
        await Api.taskTemplate.createTaskTemplate(reqParams);
        this.status.isLoading = false;
        routerStore.goBack();
    }
    get isEnableCreate() {
        const { isEditorValid, name } = toJS(this.status);
        return isEditorValid && name;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TaskTemplateCreateStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TaskTemplateCreateStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], TaskTemplateCreateStore.prototype, "isEnableCreate", null);
export const taskTemplateCreateStore = new TaskTemplateCreateStore();
