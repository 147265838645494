var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import BasicStore from "../../../../store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { Api } from "../../../../api/Api";
import { FilterPrefix } from "../../../../definition/entity/label-task";
import Pager from "../../../../model/Pager";
import qs from "qs";
class Status {
    constructor() {
        this.isLoading = true;
        this.labelTasks = [];
        this.filterField = "";
        this.filterPrefix = FilterPrefix.Name;
        this.pager = new Pager(10);
        this.filterCreator = "";
        this.resourceMime = [];
        this.labelTaskType = [];
        this.labelTaskTypeList = [
            { text: "分类", value: 0 },
            { text: "检测", value: 1 },
            { text: "分割", value: 2 },
            { text: "时空锚", value: 3 },
            { text: "切割", value: 4 }
        ];
        this.status = [];
        this.sorter = undefined;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "labelTasks", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "filterField", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof FilterPrefix !== "undefined" && FilterPrefix) === "function" ? _a : Object)
], Status.prototype, "filterPrefix", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "pager", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "filterCreator", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "resourceMime", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "labelTaskType", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "labelTaskTypeList", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "status", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "sorter", void 0);
class LabelTasksStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        this.setStatus({ isLoading: true });
        const labelTasksRes = await Api.labelTask.listLabelTasks({
            filterPrefix: this.status.filterPrefix,
            filterField: this.status.filterField,
            resourceMime: toJS(this.status.resourceMime),
            labelTaskType: toJS(this.status.labelTaskType),
            status: toJS(this.status.status),
            sorter: toJS(this.status.sorter),
            offset: this.status.pager.getOffset(),
            limit: this.status.pager.getPerPage()
        });
        this.setStatus({
            isLoading: false,
            labelTasks: labelTasksRes.data,
            pager: this.status.pager.setTotal(labelTasksRes.total)
        });
    }
    get filteredLabelTasks() {
        const { labelTasks, filterCreator } = this.status;
        return toJS(labelTasks).filter(item => {
            var _a;
            return filterCreator ? ((_a = item.user) === null || _a === void 0 ? void 0 : _a.nickname.indexOf(filterCreator)) > -1 : true;
        });
    }
    async deleteLabelTask(labelTaskId) {
        await Api.labelTask.deleteLabelTask(labelTaskId);
        await this.loadData();
    }
    downTaskAnnotationFile(taskId, full = false, needImage = false) {
        let url = `/api/label_task/${taskId}/generate_annotation_file`;
        const query = {};
        if (full) {
            query.full = 1;
        }
        if (needImage) {
            query.needImage = 1;
        }
        const str = qs.stringify(query);
        window.open(url + "?" + str);
    }
    downTaskRawAnnotationFile(taskId) {
        let url = `/api/label_task/${taskId}/raw_jsonlist`;
        window.open(url);
    }
    downTaskOriginSandAnnotationFile(taskId) {
        window.open(`/api/label_task/${taskId}/generate_origin_sand_annotation_file`);
    }
    async previewTaskTemplateDocument(taskTemplateId) {
        const getDocumentUrl = await Api.taskTemplate.getTaskTemplateDocumentUrl(taskTemplateId);
        window.open(getDocumentUrl.data);
    }
    downTaskExtractAnnotationFile(taskId, full = false) {
        let url = `/api/label_task/${taskId}/generate_extract_annotation_file`;
        if (full === true) {
            url += "?full=1";
        }
        window.open(url);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelTasksStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelTasksStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabelTasksStore.prototype, "filteredLabelTasks", null);
export const labelTaskStore = new LabelTasksStore();
