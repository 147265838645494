var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import * as _ from "lodash";
import * as React from "react";
import BasicStore from "../../../../store/BasicStore";
import { action, autorun, computed, observable, toJS } from "mobx";
import { PainterDom } from "./provider/painter-dom";
import { PainterMode, ResizeMode } from "../interface/common";
import { Api } from "../../../../api/Api";
import { routerStore } from "../../../../store/routerStore";
import { AnnotationParser } from "./provider/annotation-parser";
import { DetectionTool, AnchorTool } from "../../../../definition/entity/label-task";
import { IResource, Resource } from "../../../../definition/entity/resource";
import { ModelPainterElement } from "./provider/ModelPainterElement";
import { message, notification, Alert } from "antd";
import localforage from "../../../../util/localforage";
import Events from "../../../../util/events";
import videoLabelStore from "../../video/VideoLabel/VideoLabelStore";
import { parseLocationSearch } from "util/parse-location-search";
import Progress from "../../../../util/progress";
import { buildObject } from "util/common";
import { GetBubbleReq } from "atom-proto/web/dataocean/bubble_pb";
import Group from "./group";
import { IShaderMode } from "page/label/3d/cloud";
import * as THREE from "three";
import { normalizeAngle } from "page/label/3d/tool/box";
import { ILabelPackage } from "definition/entity/label-package";
class History {
    constructor(store) {
        this.store = store;
        this.undoStack = [];
        this.redoStack = [];
        this.maxStackLength = 10;
    }
    push(state) {
        const originZoomRatio = this.store.status.zoomRatio;
        const currentZoomRatio = this.store.status.zoomRatio;
        const value = state.getValue(originZoomRatio, currentZoomRatio);
        this.undoStack.push(Object.assign(Object.assign({}, state), { value,
            originZoomRatio }));
        if (this.undoStack.length > this.maxStackLength) {
            this.undoStack.shift();
        }
        if (this.redoStack.length) {
            this.redoStack = [];
        }
    }
    undo() {
        var _a;
        const record = this.undoStack.pop();
        if (record) {
            this.redoStack.push({
                path: record.path,
                getValue: record.getValue,
                group: this.store.group.get(),
                value: toJS(_.get(this.store, record.path)),
                originZoomRatio: this.store.status.zoomRatio
            });
            _.set(this.store, record.path, record.getValue(record.originZoomRatio, this.store.status.zoomRatio, record.value));
            if (record.group) {
                this.store.group.set(record.group);
            }
        }
        (_a = this.store.painterWrapperRef) === null || _a === void 0 ? void 0 : _a.rerender();
    }
    redo() {
        var _a;
        const record = this.redoStack.pop();
        if (record) {
            this.undoStack.push({
                path: record.path,
                getValue: record.value,
                value: toJS(_.get(this.store, record.path)),
                group: this.store.group.get(),
                originZoomRatio: this.store.status.zoomRatio
            });
            _.set(this.store, record.path, record.getValue(record.originZoomRatio, this.store.status.zoomRatio, record.value));
            if (record.group) {
                this.store.group.set(record.group);
            }
        }
        (_a = this.store.painterWrapperRef) === null || _a === void 0 ? void 0 : _a.rerender();
    }
    reset() {
        this.undoStack = [];
        this.redoStack = [];
    }
}
const progress = new Progress({
    onChange(status) {
        imageLabelStore.status.progress = status;
    }
});
export const LabelProgressKey = "labelProgress";
export const videoEvents = new Events();
class Status {
    constructor() {
        this.config = {
            strokeWidth: 2,
            painterBGSize: {
                width: 640,
                height: 480
            }
        };
        this.isLoading = true;
        this.readonly = false;
        this.mode = PainterMode.UNSET;
        this.preMode = PainterMode.UNSET;
        this.resizeMode = ResizeMode.UNSET;
        this.selectableLabel = {
            detections: [],
            classifications: [],
            anchors: [],
            segments: [],
            segments3D: [],
            detections3D: []
        };
        this.zoomRatio = 1;
        this._zoomRatio = 1;
        this.curSelectedLabel = null;
        this.creatingElement = null;
        this.selectedElementIds = [];
        this.layerElements = [];
        this.resourceClassification = {};
        this.labelTaskId = null;
        this.labelPackageId = null;
        this.labelInfo = null;
        this.curResource = null;
        this.curFilename = undefined;
        this.curOffset = 0;
        this.enableContextMenu = true;
        this.spriteContextmenu = {
            element: null,
            visible: false,
            left: 0,
            top: 0
        };
        this.rulerStatus = {
            x: -1,
            y: -1,
            text: "",
            width: 0,
            height: 0
        };
        this.progress = progress.status;
        this.isResourceLoading = true;
        this.mime = IResource.Mime.Image;
        this.workspaceTheme = false;
        this.canvasWidth = 0;
        this.canvasHeight = 0;
        this.commonMode = false;
        this.selectedPointIndex = [];
        this.selectedSegmentElementId = null;
        this.brightness = 0;
        this.contrast = 0;
        this.groupRects = {};
        this.editingElementId = null;
        this.rawResource = null;
        this.hiddenDetection = false;
        this.tool3D = "lasso";
        this.mode3D = "add";
        this.shaderMode3D = "intensity";
        this.viewType = "3d";
        this.pointSize = 2;
        this.boxes = [];
        this.boxFaceProjection = [];
        this.boxPositionProjection = [];
        this.boxVectorsProjection = [];
        this.pcd = null;
        this.resources = [];
    }
    get editingElement() {
        return _.find(this.layerElements, { id: this.editingElementId });
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "config", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "readonly", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof PainterMode !== "undefined" && PainterMode) === "function" ? _a : Object)
], Status.prototype, "mode", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof PainterMode !== "undefined" && PainterMode) === "function" ? _b : Object)
], Status.prototype, "preMode", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_c = typeof ResizeMode !== "undefined" && ResizeMode) === "function" ? _c : Object)
], Status.prototype, "resizeMode", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "selectableLabel", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "zoomRatio", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "_zoomRatio", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "curSelectedLabel", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_f = typeof ModelPainterElement !== "undefined" && ModelPainterElement) === "function" ? _f : Object)
], Status.prototype, "creatingElement", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedElementIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "layerElements", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "resourceClassification", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "labelTaskId", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "labelPackageId", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "labelInfo", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "curResource", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "curFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "curOffset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "enableContextMenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "spriteContextmenu", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "rulerStatus", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "progress", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "isResourceLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_h = typeof IResource !== "undefined" && IResource.Mime) === "function" ? _h : Object)
], Status.prototype, "mime", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "workspaceTheme", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "canvasWidth", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "canvasHeight", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "commonMode", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "selectedPointIndex", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "selectedSegmentElementId", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "brightness", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "contrast", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "groupRects", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "editingElementId", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Status.prototype, "editingElement", null);
__decorate([
    observable,
    __metadata("design:type", typeof (_j = typeof Resource !== "undefined" && Resource) === "function" ? _j : Object)
], Status.prototype, "rawResource", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "hiddenDetection", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "tool3D", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "mode3D", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_k = typeof IShaderMode !== "undefined" && IShaderMode) === "function" ? _k : Object)
], Status.prototype, "shaderMode3D", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "viewType", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "pointSize", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "boxes", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "boxFaceProjection", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "boxPositionProjection", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "boxVectorsProjection", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], Status.prototype, "selectedBoxId", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "resources", void 0);
export class ImageLabelStore extends BasicStore {
    constructor() {
        super();
        this.status = new Status();
        this.isProcessing = false;
        this.workspace = null;
        this.svgCanvas = null;
        this.painterWrapper = null;
        this.painterRoot = null;
        this.painterBackground = null;
        this.painterContent = null;
        this.frontViewOffset = {
            left: 0,
            top: 0,
            width: 300,
            height: 300
        };
        this.frontViewZIndex = 0;
        this.sideViewOffset = {
            left: 0,
            top: 0,
            width: 300,
            height: 300
        };
        this.sideViewZIndex = 0;
        this.topViewOffset = {
            left: 0,
            top: 0,
            width: 300,
            height: 300
        };
        this.topViewZIndex = 0;
        this.image2DViewZIndex = 0;
        this.projectionRange = [0.1, 4];
        this.projection = true;
        this.inheritThreshold = 0.1;
        this.history = new History(this);
        this.group = new Group(this);
        autorun(() => {
            if (this.status.selectedBoxId != null) {
                if (this.editor3DRef) {
                    const box3 = this.editor3DRef.boxTool.getBox(this.status.selectedBoxId);
                    if (box3) {
                        this.editor3DRef.selectBox(this.status.selectedBoxId);
                        this.editor3DRef.frontCloud.moveCamera(box3);
                        this.editor3DRef.sideCloud.moveCamera(box3);
                        this.editor3DRef.topCloud.moveCamera(box3);
                    }
                    else {
                        this.editor3DRef.unselectBox();
                        this.editor3DRef.frontCloud.moveCamera();
                        this.editor3DRef.sideCloud.moveCamera();
                        this.editor3DRef.topCloud.moveCamera();
                    }
                }
            }
        });
        autorun(() => {
            var _a;
            if (this.status.selectedElementIds) {
                (_a = this.painterWrapperRef) === null || _a === void 0 ? void 0 : _a.rerender();
            }
        });
        autorun(() => {
            if (!this.status.selectedElementIds.includes(this.status.editingElementId)) {
                this.status.editingElementId = null;
            }
        });
    }
    resetStatus() {
        this.status = new Status();
    }
    async initLabelPage(labelTaskId, labelPackageId) {
        this.status.isLoading = true;
        this.status.labelTaskId = labelTaskId;
        this.status.labelPackageId = labelPackageId;
        await this.loadLabelPackageInfo();
        await this.loadImageInfo();
        this.status.isLoading = false;
    }
    async loadLabelPackageInfo() {
        const { labelTaskId, labelPackageId } = this.status;
        const packageInfo = await Api.labelTask.getPackageWithTask(labelTaskId, labelPackageId);
        this.status.selectableLabel = packageInfo.data.labelTask.label;
        this.status.labelInfo = packageInfo.data;
        if (this.status.labelInfo.labelPackage.status == ILabelPackage.Status.Archived) {
            this.status.readonly = true;
            message.warning("进入状态为已归档的分包，任何操作都不保存！");
        }
    }
    async loadImageInfo() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        progress.start();
        this.status.isResourceLoading = true;
        const { readonly, labelTaskId, labelPackageId, curOffset } = this.status;
        const resources = await Api.labelTask.getLabelPackageResources({
            labelTaskId: labelTaskId,
            labelPackageId: labelPackageId,
            offset: curOffset,
            limit: 2
        });
        const resource = resources.data[0];
        this.emit("resourceLoaded", resource);
        if ((_a = resource.annotation) === null || _a === void 0 ? void 0 : _a.segments3D) {
            this.status.segments3D = resource.annotation.segments3D;
        }
        if ((_b = this.status.selectableLabel.segments3D) === null || _b === void 0 ? void 0 : _b.length) {
            this.status.resources = (await Api.labelTask.getLabelPackageResources({
                labelTaskId: labelTaskId,
                labelPackageId: labelPackageId,
                offset: 0,
                limit: undefined
            })).data;
        }
        const mime = (this.status.mime = resource.mime);
        if (readonly) {
            routerStore.replace(`?index=${curOffset + 1}&readonly=${readonly}`);
        }
        else {
            routerStore.replace(`?index=${curOffset + 1}`);
        }
        if (mime === IResource.Mime.Video) {
            await this.renderVideo(resource);
        }
        else {
            await this.renderImage(resource);
        }
        PainterDom.resetIdSeq();
        process.nextTick(() => {
            const nextResource = resources.data[1];
            if (nextResource && nextResource.mime === IResource.Mime.Image) {
                this.preLoadImage(nextResource.url);
            }
        });
        const resultClassification = {};
        this.status.selectableLabel.classifications.map(item => {
            var _a, _b, _c;
            const resourceClassifications = ((_a = resource.annotation) === null || _a === void 0 ? void 0 : _a.classifications) || [];
            const matchClassification = _.find(resourceClassifications, innerItem => item.key === innerItem.key);
            resultClassification[item.key] = {
                value: (_b = matchClassification === null || matchClassification === void 0 ? void 0 : matchClassification.value) !== null && _b !== void 0 ? _b : item.defaultValue,
                tool: (_c = matchClassification === null || matchClassification === void 0 ? void 0 : matchClassification.tool) !== null && _c !== void 0 ? _c : item.tool,
                labelUserId: matchClassification === null || matchClassification === void 0 ? void 0 : matchClassification.labelUserId,
                labelTime: matchClassification === null || matchClassification === void 0 ? void 0 : matchClassification.labelTime,
                labelUpdateTime: matchClassification === null || matchClassification === void 0 ? void 0 : matchClassification.labelUpdateTime,
                isPreset: matchClassification === null || matchClassification === void 0 ? void 0 : matchClassification.isPreset
            };
        });
        this.status.resourceClassification = resultClassification;
        const detections = ((_d = (_c = resource.annotation) === null || _c === void 0 ? void 0 : _c.detections) === null || _d === void 0 ? void 0 : _d.map(detection => {
            var _a;
            const label = _.find(this.status.selectableLabel.detections, item => item.key === detection.key);
            if (!label) {
                return null;
            }
            let attributes = undefined;
            switch (label.tool) {
                case DetectionTool.Rectangle: {
                    const detectionVal = detection.value;
                    attributes = {
                        x: detectionVal.x * this.status.zoomRatio,
                        y: detectionVal.y * this.status.zoomRatio,
                        width: detectionVal.width * this.status.zoomRatio,
                        height: detectionVal.height * this.status.zoomRatio
                    };
                    break;
                }
                case DetectionTool.Dot: {
                    const detectionVal = detection.value;
                    attributes = {
                        cx: detectionVal.cx * this.status.zoomRatio,
                        cy: detectionVal.cy * this.status.zoomRatio
                    };
                    break;
                }
                case DetectionTool.Path: {
                    const detectionVal = detection.value;
                    attributes = {
                        points: detectionVal.map(point => [point[0] * this.status.zoomRatio, point[1] * this.status.zoomRatio]),
                        nextPos: null,
                        isClosed: true
                    };
                    break;
                }
                case DetectionTool.Box:
                    const _points = detection.value;
                    const orientation = "n";
                    const [p0, p1, p2, p3, p4, p5, p6, p7] = _points.map(v => [
                        v[0] * this.status.zoomRatio,
                        v[1] * this.status.zoomRatio
                    ]);
                    const face = ((_a = _.find(detection.classifications, { key: "face" })) === null || _a === void 0 ? void 0 : _a.value) || "back";
                    let points = null;
                    switch (orientation) {
                        case "n":
                            points = face === "front" ? [p2, p0, p1, p3, p6, p4, p5, p7] : [p0, p2, p3, p1, p4, p6, p7, p5];
                            break;
                        case "e":
                            points = [p4, p0, p1, p5, p6, p2, p3, p7];
                            break;
                        case "s":
                            points = face === "front" ? [p6, p4, p5, p7, p2, p0, p1, p3] : [p4, p6, p7, p5, p0, p2, p3, p1];
                            break;
                        case "w":
                            points = [p2, p6, p7, p3, p0, p4, p5, p1];
                            break;
                    }
                    attributes = {
                        points,
                        orientation
                    };
            }
            return {
                id: PainterDom.generateId(detection.key),
                label: label,
                visible: true,
                attributes: attributes,
                detection: {
                    key: detection.key,
                    tool: detection.tool,
                    value: detection.value,
                    classifications: label.classifications.map(cls => {
                        const matchCls = _.find(detection.classifications, iCls => iCls.key === cls.key);
                        return {
                            key: cls.key,
                            tool: cls.tool,
                            value: matchCls ? matchCls.value : cls.defaultValue
                        };
                    }),
                    trails: detection.trails
                },
                initAttribute: {},
                labelUserId: detection === null || detection === void 0 ? void 0 : detection.labelUserId,
                labelTime: detection === null || detection === void 0 ? void 0 : detection.labelTime,
                labelUpdateTime: detection === null || detection === void 0 ? void 0 : detection.labelUpdateTime,
                isPreset: detection === null || detection === void 0 ? void 0 : detection.isPreset,
                display: "normal"
            };
        }).filter(i => i)) || [];
        const anchors = (_f = (_e = resource.annotation) === null || _e === void 0 ? void 0 : _e.anchors) === null || _f === void 0 ? void 0 : _f.map(anchors => {
            let ancestorId = "";
            return anchors.map(anchor => {
                const label = _.find(this.status.selectableLabel.anchors, item => item.key === anchor.key);
                if (!label) {
                    return null;
                }
                let attributes = undefined;
                switch (label.tool) {
                    case AnchorTool.Dot: {
                        const detectionVal = anchor.value;
                        attributes = {
                            cx: detectionVal.cx * this.status.zoomRatio,
                            cy: detectionVal.cy * this.status.zoomRatio
                        };
                        break;
                    }
                }
                if (!ancestorId) {
                    ancestorId = PainterDom.generateId(anchor.key);
                }
                return {
                    id: PainterDom.generateId(ancestorId),
                    label: label,
                    visible: true,
                    attributes: attributes,
                    detection: {
                        key: anchor.key,
                        tool: anchor.tool,
                        value: anchor.value,
                        trails: anchor.trails
                    },
                    initAttribute: {},
                    labelUserId: anchor === null || anchor === void 0 ? void 0 : anchor.labelUserId,
                    labelTime: anchor === null || anchor === void 0 ? void 0 : anchor.labelTime,
                    labelUpdateTime: anchor === null || anchor === void 0 ? void 0 : anchor.labelUpdateTime,
                    isPreset: anchor === null || anchor === void 0 ? void 0 : anchor.isPreset,
                    display: "normal"
                };
            });
        });
        const _segments = ((_h = (_g = resource.annotation) === null || _g === void 0 ? void 0 : _g.segments) === null || _h === void 0 ? void 0 : _h.map(segment => {
            var _a;
            const label = _.find(this.status.selectableLabel.segments, item => item.key === segment.key);
            if (!label) {
                return null;
            }
            let attributes = undefined;
            switch (label.tool) {
                case "path": {
                    const detectionVal = segment.value;
                    attributes = {
                        points: detectionVal.map(point => [point[0] * this.status.zoomRatio, point[1] * this.status.zoomRatio]),
                        nextPos: null,
                        isClosed: true
                    };
                    break;
                }
            }
            const { x, y, width, height } = segment.rect || {};
            const zoomRatio = this.status.zoomRatio;
            return {
                id: PainterDom.generateId(segment.key),
                label: label,
                visible: true,
                attributes: attributes,
                detection: Object.assign(Object.assign(Object.assign(Object.assign({}, segment), { alpha: 0.1 }), (segment.rect
                    ? { rect: { x: x * zoomRatio, y: y * zoomRatio, width: width * zoomRatio, height: height * zoomRatio } }
                    : null)), { classifications: (_a = label.classifications) === null || _a === void 0 ? void 0 : _a.map(cls => {
                        const matchCls = _.find(segment.classifications, iCls => iCls.key === cls.key);
                        return {
                            key: cls.key,
                            tool: cls.tool,
                            value: matchCls ? matchCls.value : cls.defaultValue
                        };
                    }) }),
                initAttribute: {},
                labelUserId: segment === null || segment === void 0 ? void 0 : segment.labelUserId,
                labelTime: segment === null || segment === void 0 ? void 0 : segment.labelTime,
                labelUpdateTime: segment === null || segment === void 0 ? void 0 : segment.labelUpdateTime,
                isPreset: segment === null || segment === void 0 ? void 0 : segment.isPreset,
                display: "normal",
                color: segment.color
            };
        })) || [];
        const segments = _.compact(_segments);
        if (segments.length) {
            const groups = _.groupBy(segments, v => v.detection.featureId);
            const maxFeatureId = Math.max.apply(null, Object.keys(groups));
            PainterDom.initFeatureId(maxFeatureId + 1);
            Object.keys(groups).forEach(featureId => {
                const group = groups[featureId];
                this.group.createGroup(group);
            });
        }
        if (resource.annotation) {
            const { detections3D = [] } = resource.annotation;
            detections3D.forEach(d => {
                const { label, position: _position, size, rotation: _rotation, box: _box } = d;
                const position = new THREE.Vector3(_position.x, _position.y, _position.z);
                const rotation = Object.assign(Object.assign({}, _rotation), { z: _rotation.z - Math.PI / 2 });
                const _uid = PainterDom.generateId(label.key);
                const { id } = this.editor3DRef.boxTool.createBox({
                    size,
                    position,
                    rotation,
                    color: label.color,
                    title: _uid
                });
                this.status.boxes.push({
                    id,
                    _uid,
                    size,
                    position,
                    rotation,
                    label,
                    visible: true,
                    titleVisible: true
                });
                this.status.boxVectorsProjection.push(Object.assign(Object.assign({}, _box), { id,
                    _uid }));
            });
            if (detections3D.length) {
                this.editor3DRef.mainCloud.render();
                this.editor3DRef.updateBoxPositionProjection();
            }
        }
        this.status.layerElements = [...detections, ..._.compact(_.flatMap(anchors)), ...segments];
        this.status.isResourceLoading = false;
        this.status.rawResource = resource;
    }
    async nextResource() {
        try {
            if (this.isProcessing === false) {
                this.isProcessing = true;
                const { labelInfo, curOffset } = this.status;
                if (curOffset + 1 !== labelInfo.resourceCount) {
                    if ((await this.saveCurResourceAnnotation()) === true) {
                        this.status.isLoading = true;
                        this.status.curOffset += 1;
                        videoLabelStore.reset();
                        this.reset();
                        await this.loadImageInfo();
                        this.updateLabelProgress();
                        this.status.isLoading = false;
                        if (this.status.mime === IResource.Mime.Video) {
                            this.syncVideoInfo();
                        }
                        this.status.spriteContextmenu.visible = false;
                    }
                }
                this.isProcessing = false;
            }
        }
        catch (e) {
            this.isProcessing = false;
        }
    }
    async prevResource() {
        try {
            if (this.isProcessing === false) {
                this.isProcessing = true;
                const { curOffset } = this.status;
                if (curOffset > 0) {
                    if ((await this.saveCurResourceAnnotation()) === true) {
                        this.status.isLoading = true;
                        this.status.curOffset -= 1;
                        videoLabelStore.reset();
                        this.reset();
                        await this.loadImageInfo();
                        this.updateLabelProgress();
                        this.status.isLoading = false;
                        if (this.status.mime === IResource.Mime.Video) {
                            this.syncVideoInfo();
                        }
                    }
                }
                this.status.isLoading = false;
                this.isProcessing = false;
                this.status.spriteContextmenu.visible = false;
            }
        }
        catch (e) {
            this.isProcessing = false;
        }
    }
    async jumpResource(page) {
        try {
            if (this.isProcessing === false) {
                this.isProcessing = true;
                if (page > 0 && page <= this.status.labelInfo.resourceCount) {
                    if ((await this.saveCurResourceAnnotation()) === true) {
                        this.status.isLoading = true;
                        this.status.curOffset = page - 1;
                        videoLabelStore.reset();
                        this.reset();
                        await this.loadImageInfo();
                        this.status.isLoading = false;
                        if (this.status.mime === IResource.Mime.Video) {
                            this.syncVideoInfo();
                        }
                    }
                }
                this.isProcessing = false;
                this.status.spriteContextmenu.visible = false;
            }
        }
        catch (e) {
            this.isProcessing = false;
        }
    }
    reset() {
        this.status.selectedPointIndex = [];
        this.status.brightness = 0;
        this.status.contrast = 0;
        this.status.segments3D = undefined;
        this.status.boxes = [];
        this.status.selectedBoxId = null;
        this.status.boxPositionProjection = [];
        this.status.boxFaceProjection = [];
        this.status.boxVectorsProjection = [];
        PainterDom.initFeatureId(0);
        this.group.reset();
        this.history.reset();
        this.status.groupRects = {};
    }
    syncVideoInfo() {
        var _a;
        const annotation = imageLabelStore.getAnnotation();
        const detectionTrails = (_a = annotation.detections) === null || _a === void 0 ? void 0 : _a.reduce((s, v) => {
            return [...s, ...v.trails];
        }, []);
        const anchorTrails = _.flatten(annotation.anchors || []).reduce((s, v) => {
            return [...s, ...v.trails];
        }, []);
        const totalTrails = [...detectionTrails, ...anchorTrails];
        if (totalTrails.length) {
            videoLabelStore.currentFrame = Math.max.apply(null, totalTrails.map(v => v.frame));
        }
    }
    async saveCurResourceAnnotation(justSave = false) {
        var _a;
        if ((_a = this.editor3DRef) === null || _a === void 0 ? void 0 : _a.disabled) {
            return;
        }
        if (this.status.readonly) {
            return;
        }
        const { labelTaskId, labelPackageId, curResource, config: { painterBGSize } } = this.status;
        const annotation = this.getAnnotation();
        const errs = this.validateAnnotation(annotation, this.status.labelInfo.labelTask);
        if (errs == null) {
            const updatedResourceRes = await Api.labelTask.updateResource(curResource.id, {
                labelTaskId: labelTaskId,
                labelPackageId: labelPackageId,
                annotation: Object.assign(Object.assign({}, this.status.rawResource.annotation), annotation),
                meta: {
                    width: painterBGSize.width,
                    height: painterBGSize.height
                }
            });
            if (justSave === true) {
                return true;
            }
            else {
                if (this.status.mime === IResource.Mime.Image) {
                    this.status.curResource.annotationUpdatedAt = updatedResourceRes.data.annotationUpdatedAt;
                    imageLabelStore.painterWrapperRef.resize();
                }
                else {
                    this.status.curResource.annotationUpdatedAt = updatedResourceRes.data.annotationUpdatedAt;
                }
            }
            return true;
        }
        else {
            const err = errs[0];
            message.warn(<Alert style={{ width: 400 }} message={err.message} type="error"/>);
            return false;
        }
    }
    validateAnnotation(annotation, labelTask, autoFocus = true) {
        const { detections, classifications } = annotation;
        const errors = [];
        if (detections) {
            detections.forEach(detection => {
                var _a;
                (_a = detection.classifications) === null || _a === void 0 ? void 0 : _a.forEach(classification => {
                    const d = matchLabel(labelTask.label.detections, detection.key);
                    const c = matchLabel(d.classifications, classification.key);
                    if (classification.value == null && c.required === true) {
                        errors.push(Error(`必填字段 ${c.locale || c.key} 不能为空`, { cause: detection.id }));
                    }
                });
            });
        }
        if (classifications) {
            classifications.forEach(classification => {
                const c = matchLabel(classifications, classification.key);
                if (classification.value == null && c.required === true) {
                    errors.push(Error(`必填字段 ${c.locale || c.key} 不能为空`));
                }
            });
        }
        function matchLabel(labels, key) {
            return _.find(labels, { key });
        }
        if (autoFocus && errors.length) {
            this.status.selectedElementIds = [errors[0].cause];
        }
        return errors.length ? errors : null;
    }
    renderImage(resource) {
        return new Promise((resolve, reject) => {
            this.status.mode = PainterMode.UNSET;
            this.status.resizeMode = ResizeMode.UNSET;
            this.status.curSelectedLabel = null;
            this.status.creatingElement = null;
            this.status.layerElements = [];
            this.status.selectedElementIds = [];
            this.status.curResource = resource;
            const url = new URL(resource.url);
            this.status.curFilename = decodeURIComponent(url.pathname.split("/")[url.pathname.split("/").length - 1]);
            const imageNode = new Image();
            imageNode.onload = ev => {
                this.status.config = Object.assign(this.status.config, {
                    painterBGSize: {
                        width: imageNode.width,
                        height: imageNode.height
                    }
                });
                this.status.zoomRatio = Math.min((this.workspace.width() * 0.8) / imageNode.width, (this.workspace.height() * 0.8) / imageNode.height);
                this.status._zoomRatio = this.status.zoomRatio;
                this.status.curResource = resource;
                PainterDom.adjustPainterSize();
                PainterDom.adjustScroll();
                resolve();
            };
            imageNode.onerror = event => {
                this.status.curResource = resource;
                PainterDom.adjustPainterSize();
                PainterDom.adjustScroll();
                resolve();
            };
            imageNode.src = resource.url;
        });
    }
    preLoadImage(url) {
        let image = new Image();
        image.onload = ev => {
            image = null;
        };
        image.src = url;
    }
    resolveUrl(resource) {
        return new Promise(resolve => {
            if (/^dataocean:\/\/\//.test(resource.url)) {
                Api.dataocean.bubble
                    .getBubble(buildObject(new GetBubbleReq(), req => {
                    req.setName(resource.url.slice(13));
                }))
                    .then(res => {
                    const bubble = res.toObject();
                    resource.url = bubble.status.downloadUrl;
                    this.status.curFilename = bubble.spec.filename;
                    resolve(null);
                })
                    .catch(e => {
                    console.log(e);
                });
            }
            else if (/^[^:]+:\/\//.test(resource.url)) {
                const url = new URL(resource.url);
                this.status.curFilename = decodeURIComponent(url.pathname.split("/")[url.pathname.split("/").length - 1]);
                resolve(null);
            }
        });
    }
    renderVideo(resource) {
        this.status.mode = PainterMode.UNSET;
        this.status.resizeMode = ResizeMode.UNSET;
        this.status.curSelectedLabel = null;
        this.status.creatingElement = null;
        this.status.layerElements = [];
        this.status.selectedElementIds = [];
        this.status.curResource = Object.assign(Object.assign({}, resource), { dataUrl: "" });
        return new Promise(resolve => {
            this.status.isLoading = true;
            const video = videoLabelStore.videoStage.video;
            videoLabelStore.meta.frameRate = resource.meta.frameRate || resource.meta.ratio;
            videoLabelStore.meta.startTime = resource.meta.startTime;
            videoLabelStore.meta.sample = resource.meta.sample;
            video.addEventListener("canplay", () => {
                progress.done();
                resolve(null);
            });
            video.addEventListener("loadedmetadata", () => {
                this.status.config = Object.assign(this.status.config, {
                    painterBGSize: {
                        width: video.videoWidth,
                        height: video.videoHeight
                    }
                });
                this.status.zoomRatio = Math.min((this.workspace.width() * 0.8) / video.videoWidth, (this.workspace.height() * 0.8) / video.videoHeight);
                PainterDom.adjustPainterSize();
                PainterDom.adjustScroll();
            });
        });
    }
    hasScrollBar(orientation) {
        const $svgCanvas = this.svgCanvas;
        if (orientation === "horizontal") {
            return $svgCanvas[0].scrollWidth > $svgCanvas.width();
        }
        else {
            return $svgCanvas[0].scrollHeight > $svgCanvas.height();
        }
    }
    updateZoomRatio(zoomRatio, evt) {
        const preZoomRatio = toJS(this.status.zoomRatio);
        const delta = zoomRatio / preZoomRatio;
        this.status.zoomRatio = zoomRatio;
        const layerElements = _.cloneDeep(toJS(this.status.layerElements));
        layerElements.forEach(layerElement => {
            switch (layerElement.label.tool) {
                case DetectionTool.Rectangle: {
                    const { x, y, width, height } = layerElement.attributes;
                    Object.assign(layerElement.attributes, {
                        x: x * delta,
                        y: y * delta,
                        width: width * delta,
                        height: height * delta
                    });
                    break;
                }
                case DetectionTool.Dot: {
                    const { cx, cy } = layerElement.attributes;
                    Object.assign(layerElement.attributes, {
                        cx: cx * delta,
                        cy: cy * delta
                    });
                    break;
                }
                case DetectionTool.Path: {
                    const { points } = layerElement.attributes;
                    Object.assign(layerElement.attributes, {
                        points: points.map(point => [point[0] * delta, point[1] * delta])
                    });
                    if (layerElement.detection.rect) {
                        const { x, y, width, height } = layerElement.detection.rect;
                        layerElement.detection.rect = {
                            x: x * delta,
                            y: y * delta,
                            width: width * delta,
                            height: height * delta
                        };
                    }
                    break;
                }
                case DetectionTool.Box: {
                    const attributes = layerElement.attributes;
                    const points = attributes.points;
                    attributes.points = points.map(point => [point[0] * delta, point[1] * delta]);
                    break;
                }
                default: {
                    break;
                }
            }
        });
        this.status.layerElements = layerElements;
        if (!_.isEmpty(this.status.creatingElement)) {
            const creatingElement = this.status.creatingElement;
            switch (creatingElement.label.tool) {
                case DetectionTool.Rectangle: {
                    const { x, y, width, height } = creatingElement.attributes;
                    creatingElement.attributes = Object.assign(creatingElement.attributes, {
                        x: x * delta,
                        y: y * delta,
                        width: width * delta,
                        height: height * delta
                    });
                    break;
                }
                case DetectionTool.Dot: {
                    const { cx, cy } = creatingElement.attributes;
                    creatingElement.attributes = Object.assign(creatingElement.attributes, {
                        cx: cx * delta,
                        cy: cy * delta
                    });
                    break;
                }
                case DetectionTool.Path: {
                    const { points } = creatingElement.attributes;
                    creatingElement.attributes = Object.assign(creatingElement.attributes, {
                        points: points.map(point => [point[0] * delta, point[1] * delta])
                    });
                    break;
                }
                case DetectionTool.Box: {
                    const { points } = creatingElement.attributes;
                    creatingElement.attributes = Object.assign(creatingElement.attributes, {
                        points: points.map(point => [point[0] * delta, point[1] * delta])
                    });
                    creatingElement.initAttribute = {
                        cursorX: creatingElement.initAttribute.cursorX * delta,
                        cursorY: creatingElement.initAttribute.cursorY * delta
                    };
                    break;
                }
                default: {
                    break;
                }
            }
        }
        PainterDom.adjustPainterSize();
        if (evt) {
            PainterDom.adjustScrollWithWheelEvent(evt, preZoomRatio, zoomRatio);
        }
        else {
            process.nextTick(() => {
                PainterDom.adjustScroll(preZoomRatio, zoomRatio);
            });
        }
        if (evt) {
            PainterDom.adjustRuler(evt);
        }
        Object.keys(imageLabelStore.status.groupRects).forEach(key => {
            const { x, y, width, height } = imageLabelStore.status.groupRects[key];
            imageLabelStore.status.groupRects[key] = {
                x: x * delta,
                y: y * delta,
                width: width * delta,
                height: height * delta
            };
        });
    }
    getAnnotation() {
        var _a, _b, _c, _d;
        const { resourceClassification } = toJS(this.status);
        const detections = this.status.layerElements
            .filter(e => e.label.type === "detection")
            .map(i => {
            switch (i.label.tool) {
                case DetectionTool.Rectangle:
                    return AnnotationParser.RectangleParser(i);
                case DetectionTool.Dot:
                    return AnnotationParser.DotParser(i);
                case DetectionTool.Path:
                    return AnnotationParser.PathParser(i);
                case DetectionTool.Chunk:
                    return AnnotationParser.parseChunk(i);
                case DetectionTool.Box:
                    return AnnotationParser.parseBox(i);
                default:
                    return null;
            }
        })
            .filter(i => i);
        const classifications = Object.keys(resourceClassification).map(key => {
            const cls = resourceClassification[key];
            const value = cls === null || cls === void 0 ? void 0 : cls.value;
            return {
                key: key,
                tool: cls === null || cls === void 0 ? void 0 : cls.tool,
                value: value,
                labelUserId: cls === null || cls === void 0 ? void 0 : cls.labelUserId,
                labelTime: cls === null || cls === void 0 ? void 0 : cls.labelTime,
                labelUpdateTime: cls === null || cls === void 0 ? void 0 : cls.labelUpdateTime,
                isPreset: cls === null || cls === void 0 ? void 0 : cls.isPreset
            };
        });
        const _anchors = this.status.layerElements
            .filter(e => e.label.type === "anchor")
            .map(ele => {
            switch (ele.label.tool) {
                case AnchorTool.Dot:
                    return AnnotationParser.parseAnchorDot(ele);
            }
        });
        const anchors = Object.values(_.groupBy(_anchors, ele => {
            return ele.id
                .split(/_/g)
                .slice(0, -1)
                .join("_");
        }));
        const needAnchor = !!((_b = (_a = this.status.labelInfo.labelTask.label) === null || _a === void 0 ? void 0 : _a.anchors) === null || _b === void 0 ? void 0 : _b.length);
        const segments = this.status.layerElements
            .filter(e => /^mask-/.test(e.label.type))
            .map(ele => {
            switch (ele.label.tool) {
                case DetectionTool.Path:
                    return AnnotationParser.SegmentPathParser(ele);
            }
        });
        const needSegment = !!((_d = (_c = this.status.labelInfo.labelTask.label) === null || _c === void 0 ? void 0 : _c.segments) === null || _d === void 0 ? void 0 : _d.length);
        const detections3D = this.status.boxes.map((v, idx) => {
            const { position, size, rotation: _rotation, label, _uid } = v;
            const rotation = Object.assign(Object.assign({}, _rotation), { z: normalizeAngle(_rotation.z + Math.PI / 2) });
            return {
                id: _uid,
                label,
                position,
                size,
                rotation,
                box: this.status.boxVectorsProjection[idx]
            };
        });
        return Object.assign(Object.assign(Object.assign({ detections: detections, classifications: classifications }, (needAnchor ? { anchors } : null)), (needSegment ? { segments } : null)), { segments3D: this.status.segments3D, detections3D });
    }
    getAnchors() {
        const anchors = this.status.layerElements
            .filter(e => e.label.type === "anchor")
            .map(ele => {
            switch (ele.label.tool) {
                case AnchorTool.Dot:
                    return AnnotationParser.parseAnchorDot(ele);
            }
        });
        return _.groupBy(anchors, ele => {
            return ele.id
                .split(/_/g)
                .slice(0, -1)
                .join("_");
        });
    }
    async loadLabelProgress() {
        const { labelTaskId, labelPackageId, mime } = this.status;
        const labelProgress = await localforage.getItem(LabelProgressKey);
        const match = _.find(labelProgress, progress => progress.taskId === labelTaskId && progress.packageId === labelPackageId);
        if (match) {
            if (mime === IResource.Mime.Image || mime === IResource.Mime.Cloud) {
                notification.info({
                    message: "操作提醒",
                    description: (<div>
              检测到上次标注到第 {match.curOffset + 1} 页，
              <a onClick={async () => {
                        await this.jumpResource(match.curOffset + 1);
                    }}>
                继续标注
              </a>
            </div>),
                    placement: "topRight",
                    duration: 2
                });
            }
            else if (mime === IResource.Mime.Video) {
                const query = parseLocationSearch(location.search);
                if (query.index != match.curOffset + 1) {
                    notification.info({
                        message: "操作提醒",
                        description: (<div>
                检测到上次标注到第 {match.curOffset + 1} 个视频，
                <a onClick={async () => {
                            await this.jumpResource(match.curOffset + 1);
                        }}>
                  继续标注
                </a>
              </div>),
                        placement: "topRight",
                        duration: 2
                    });
                }
            }
        }
    }
    async updateLabelProgress() {
        const { labelTaskId, labelPackageId, curOffset } = this.status;
        let labelProgress = await localforage.getItem(LabelProgressKey);
        if (_.isNil(labelProgress)) {
            labelProgress = [];
        }
        const match = _.find(labelProgress, progress => progress.taskId === labelTaskId && progress.packageId === labelPackageId);
        if (match) {
            match.curOffset = curOffset;
        }
        else {
            if (labelProgress.length >= 10) {
                labelProgress.shift();
            }
            let progress = {
                taskId: labelTaskId,
                packageId: labelPackageId,
                curOffset: curOffset
            };
            if (this.status.mime === IResource.Mime.Video) {
                progress.layerElements = this.status.layerElements;
            }
            labelProgress.push(progress);
        }
        await localforage.setItem(LabelProgressKey, labelProgress);
    }
    get selectedElements() {
        return this.status.layerElements.filter(i => this.status.selectedElementIds.includes(i.id));
    }
    get currentFrame() {
        return videoLabelStore.currentFrame;
    }
    get anchorElements() {
        return this.status.layerElements.filter(e => e.label.type === "anchor");
    }
    updatePcd(pcd) {
        Api.labelTask.updatePcd(pcd);
    }
    _selectedElementGroup() {
        const elObjs = _.groupBy(this.status.layerElements, v => v.label.key);
        const keys = Object.keys(elObjs);
        for (const key of keys) {
            const group = elObjs[key];
            const idx0 = _.findIndex(group, { id: this.status.selectedElementIds[0] });
            const idx1 = _.findIndex(group, { id: _.last(this.status.selectedElementIds) });
            if (idx0 > -1) {
                return [[idx0, idx1], group];
            }
        }
        return null;
    }
    _selectedBoxGroup() {
        const boxObjs = _.groupBy(this.status.boxes, v => v.label.key);
        const keys = Object.keys(boxObjs);
        for (const key of keys) {
            const group = boxObjs[key];
            const idx = _.findIndex(group, { id: imageLabelStore.status.selectedBoxId });
            if (idx > -1) {
                return [idx, group];
            }
        }
        return null;
    }
    selectNext() {
        const group1 = this._selectedBoxGroup();
        const group2 = this._selectedElementGroup();
        if (group1) {
            const [idx, group] = group1;
            const next = group[idx + 1];
            if (next) {
                this.status.selectedBoxId = next.id;
            }
        }
        if (group2) {
            const [[idx0, idx1], group] = group2;
            const next = group[idx1 + 1];
            if (next) {
                this.status.selectedElementIds = [next.id];
            }
        }
    }
    selectPrev() {
        const group1 = this._selectedBoxGroup();
        const group2 = this._selectedElementGroup();
        if (group1) {
            const [idx, group] = group1;
            const next = group[idx - 1];
            if (next) {
                this.status.selectedBoxId = next.id;
            }
        }
        if (group2) {
            const [[idx0, idx1], group] = group2;
            const next = group[idx0 - 1];
            if (next) {
                this.status.selectedElementIds = [next.id];
            }
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ImageLabelStore.prototype, "resetStatus", null);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "frontViewOffset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "frontViewZIndex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "sideViewOffset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "sideViewZIndex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "topViewOffset", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "topViewZIndex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "image2DViewZIndex", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "projectionRange", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "projection", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], ImageLabelStore.prototype, "inheritThreshold", void 0);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ImageLabelStore.prototype, "selectedElements", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], ImageLabelStore.prototype, "currentFrame", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], ImageLabelStore.prototype, "anchorElements", null);
export const imageLabelStore = (window.imageLabelStore = new ImageLabelStore());
