import axios from "../util/axios";
import { deleteNilProperty } from "util/delete-nil-property";
export const getDailyWorks = (params) => {
    params = deleteNilProperty(params);
    return axios.get("/daily_works", { params });
};
export const createDailyWork = (params) => {
    return axios.post("/daily_works", params);
};
export const updateDailyWork = (dailyWorkId, params) => {
    return axios.put(`/daily_works/${dailyWorkId}`, params);
};
export const deleteDailyWork = (dailyWorkId) => {
    return axios.delete(`/daily_works/${dailyWorkId}`);
};
