var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import BasicStore from "../../../../store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { Api } from "../../../../api/Api";
class Status {
    constructor() {
        this.isLoading = true;
        this.labellingAssessmentPolicies = [];
        this.nameFilter = "";
        this.filterCreator = "";
    }
}
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "filterCreator", void 0);
class LabellingAssessmentPoliciesStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        this.setStatus({ isLoading: true });
        const labellingAssessmentPoliciesRes = await Api.labellingAssessmentPolicy.getLabellingAssessmentPolicies();
        const labellingAssessmentPoliciesResMap = new Map();
        labellingAssessmentPoliciesRes.data.forEach(item => {
            if (labellingAssessmentPoliciesResMap.has(item.name))
                return;
            labellingAssessmentPoliciesResMap.set(item.name, item);
        });
        this.setStatus({
            isLoading: false,
            labellingAssessmentPolicies: [...labellingAssessmentPoliciesResMap.values()]
        });
    }
    get filteredLabellingAssessmentPolicies() {
        const { labellingAssessmentPolicies, filterCreator } = this.status;
        return toJS(labellingAssessmentPolicies).filter(item => { var _a; return filterCreator ? ((_a = item.creator) === null || _a === void 0 ? void 0 : _a.indexOf(filterCreator)) > -1 : true; });
    }
    async deleteLabellingAssessmentPolicy(policyId) {
        const deleteRes = await Api.labellingAssessmentPolicy.deleteLabellingAssessmentPolicy(policyId);
        if (deleteRes.code === 200) {
            this.loadData();
        }
    }
    async enableLabellingAssessmentPolicy(policyId) {
        const enableRes = await Api.labellingAssessmentPolicy.enableLabellingAssessmentPolicy(policyId);
        if (enableRes.code === 200) {
            this.loadData();
        }
    }
    async disableLabellingAssessmentPolicy(policyId) {
        const disableRes = await Api.labellingAssessmentPolicy.disableLabellingAssessmentPolicy(policyId);
        if (disableRes.code === 200) {
            this.loadData();
        }
    }
    async downloadPolicyScript(policyId) {
        window.open(`/api/labelling_assessment_policies/policies/${policyId}/policy_script`);
    }
    async downloadGroundTruthExample(policyId) {
        window.open(`/api/labelling_assessment_policies/policies/${policyId}/ground_truth_example`);
    }
    async downloadAssessingExample(policyId) {
        window.open(`/api/labelling_assessment_policies/policies/${policyId}/assessing_example`);
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabellingAssessmentPoliciesStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabellingAssessmentPoliciesStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabellingAssessmentPoliciesStore.prototype, "filteredLabellingAssessmentPolicies", null);
export const labellingAssessmentPoliciesStore = new LabellingAssessmentPoliciesStore();
