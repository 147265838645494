var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import cls from "classnames";
import { DetectionTool } from "../../../../../../definition/entity/label-task";
import { observer } from "mobx-react";
import { IconFont } from "../../../../../../component/IconFont/IconFont";
import { KeymapEditor } from "../KeymapEditor/keymapEditor";
import { keymapStore } from "../../provider/keymap-store";
import { imageLabelStore } from "../../ImageLabelStore";
import { PainterMode } from "../../../interface/common";
let DetectionSelector = class DetectionSelector extends React.Component {
    handleClickDetectLabel(label) {
        imageLabelStore.status.curSelectedLabel = label;
        imageLabelStore.status.mode = PainterMode.DRAW;
    }
    render() {
        const status = imageLabelStore.status;
        const { idx, label } = this.props;
        return (<div key={label.key} className={cls({
            "label-item detection": true,
            selected: _.get(status.curSelectedLabel, "key") === label.key
        })} onClick={e => {
            this.handleClickDetectLabel(label);
        }}>
        {label.tool === DetectionTool.Rectangle && (<React.Fragment>
            <IconFont type="icon-rectangle_fill" style={{ color: label.color, fontSize: "20px" }}/>
            <span>{label.locale || label.key}</span>
          </React.Fragment>)}

        {label.tool === DetectionTool.Dot && (<React.Fragment>
            <IconFont type="icon-dot" style={{ color: label.color, fontSize: "20px" }}/>
            <span>{label.locale || label.key}</span>
          </React.Fragment>)}

        {label.tool === DetectionTool.Path && (<React.Fragment>
            <IconFont type="icon-draw-polygon-solid" style={{ color: label.color, fontSize: "20px" }}/>
            <span>{label.locale || label.key}</span>
          </React.Fragment>)}

        {label.tool === DetectionTool.Chunk && (<React.Fragment>
            <IconFont type="icon-rectangle_fill" style={{ color: label.color, fontSize: "20px" }}/>
            <span>{label.locale || label.key}</span>
          </React.Fragment>)}

        {label.tool === DetectionTool.Box && (<React.Fragment>
            <IconFont type="icon-rectangle_fill" style={{ color: label.color, fontSize: "20px" }}/>
            <span>{label.locale || label.key}</span>
          </React.Fragment>)}

        <KeymapEditor keyStr={keymapStore.getKeyByActionTag(`detection_${label.key}`)} default={() => {
            if (idx < 9) {
                keymapStore.register({
                    key: String(idx + 1),
                    actionTag: `detection_${label.key}`,
                    action: () => this.handleClickDetectLabel(label)
                });
            }
        }} onChange={keyStr => {
            keymapStore.register({
                key: keyStr,
                actionTag: `detection_${label.key}`,
                action: () => this.handleClickDetectLabel(label)
            });
        }}/>
      </div>);
    }
};
DetectionSelector = __decorate([
    observer
], DetectionSelector);
export { DetectionSelector };
