import axios from "../util/axios";
const taskResource = "/segment_task";
export function createTask(body) {
    return axios.post(taskResource, body);
}
export function updateTask(taskId, body) {
    return axios.post(`${taskResource}/${taskId}`, body);
}
export function showTask(taskId) {
    return axios.get(`${taskResource}/${taskId}`);
}
export function indexTask(params) {
    return axios.get(taskResource, { params });
}
const packageResource = "/segment_package";
export function indexPackages(taskId) {
    return axios.get(packageResource, { params: { taskId } });
}
export function assignPackage() { }
export function achivePackage() { }
export function downloadPackage() { }
