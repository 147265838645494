import * as React from "react";
import moment from "moment";
import { Modal, Badge } from "antd";
import { Api } from "../../../../api/Api";
import localforage from "../../../../util/localforage";
const NotifyKey = "notify";
export class Notify extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showDot: false,
            isNotifyModalVisible: false,
            notifyContent: ""
        };
    }
    async componentDidMount() {
        const latestNotify = (await Api.notify.getNotify()).data;
        const existLocalStorage = await localforage.getItem(NotifyKey);
        if (!latestNotify) {
            this.setState({
                showDot: false
            });
        }
        else if (existLocalStorage === null ||
            latestNotify.id > existLocalStorage.id ||
            moment(latestNotify.updatedAt).isAfter(moment(existLocalStorage.updatedAt))) {
            localforage.setItem(NotifyKey, {
                isRead: false,
                id: latestNotify.id,
                updatedAt: latestNotify.updatedAt
            });
            this.setState({
                showDot: true,
                notifyContent: latestNotify.content
            });
        }
        else {
            this.setState({
                showDot: !existLocalStorage.isRead,
                notifyContent: latestNotify.content
            });
        }
    }
    async handleViewNotify() {
        const existLocalStorage = await localforage.getItem(NotifyKey);
        if (existLocalStorage) {
            await localforage.setItem(NotifyKey, Object.assign(existLocalStorage, {
                isRead: true
            }));
        }
        this.setState({
            showDot: false,
            isNotifyModalVisible: true
        });
    }
    render() {
        return (<React.Fragment>
        <div style={{ marginRight: "14px" }} onClick={() => this.handleViewNotify()}>
          <Badge dot={this.state.showDot}>
            <span>系统公告</span>
          </Badge>
        </div>
        <Modal title="系统公告" open={this.state.isNotifyModalVisible} footer={null} onCancel={() => {
            this.setState({
                isNotifyModalVisible: false
            });
        }}>
          <pre style={{ lineHeight: "1.8", margin: "0" }}>{this.state.notifyContent || "暂无公告"}</pre>
        </Modal>
      </React.Fragment>);
    }
}
