import axios from "util/axios";
import { deleteNilProperty } from "util/delete-nil-property";
export const getDailyAudits = (params) => {
    return axios.get("audit/daily_audits", {
        params: deleteNilProperty(params)
    });
};
export const getUserAudits = (params) => {
    return axios.get("audit/user_audits", {
        params: deleteNilProperty(params)
    });
};
export const getTaskAudits = (params) => {
    return axios.get("audit/task_audits", {
        params: deleteNilProperty(params)
    });
};
export const getWorkAudits = (params) => {
    return axios.get("audit/work_audits", {
        params: deleteNilProperty(params)
    });
};
export const getUserCompositeAudits = (params) => {
    return axios.get("audit/user_composite_audits", {
        params: deleteNilProperty(params)
    });
};
export const getTaskCompositeAudits = (params) => {
    return axios.get("audit/task_composite_audits", {
        params: deleteNilProperty(params)
    });
};
export const getComprehensiveAudits = (params) => {
    return axios.get("audit/comprehensive_audits", {
        params: deleteNilProperty(params)
    });
};
export const getPerformanceAudits = (params) => {
    return axios.get("audit/performance_audits", {
        params: deleteNilProperty(params)
    });
};
export const getRankAudits = (params) => {
    return axios.get("audit/rank_audits", {
        params: deleteNilProperty(params)
    });
};
