var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Collapse, Input, Popover } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import * as React from "react";
import { CompactPicker } from "react-color";
import "./Segment3DItem.less";
let Segment3DItem = class Segment3DItem extends React.Component {
    render() {
        const { segment, onDelete } = this.props;
        return (<div className="segment3d-item-comp">
        <Collapse.Panel key={segment.uniqKey} header={<div className="segment-outside" onClick={e => {
            e.stopPropagation();
        }}>
              <Popover content={<CompactPicker style={{ width: 200 }} value={segment.color} onChange={(color) => {
            segment.color = color.hex;
        }}/>}>
                <div style={{ width: 40, height: 40, borderRadius: "50%", background: segment.color }}/>
              </Popover>
              <Input placeholder="标注 key" value={segment.key} onChange={event => {
            segment.key = event.target.value;
        }}/>
              <Input placeholder="标注说明" value={segment.locale} onChange={event => {
            segment.locale = event.target.value;
        }}/>
              <DeleteOutlined onClick={() => {
            onDelete();
        }}/>
            </div>}></Collapse.Panel>
      </div>);
    }
};
Segment3DItem = __decorate([
    observer
], Segment3DItem);
export { Segment3DItem };
