var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { message } from "antd";
import { DetectionTool } from "definition/entity/label-task";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { imageLabelStore } from "../../image/ImageLabel/ImageLabelStore";
import videoLabelStore from "./VideoLabelStore";
import UAParser from "ua-parser-js";
let VideoStage = class VideoStage extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    componentDidMount() {
        this.ctx = this.canvas.getContext("2d");
        this.isSupported(can => {
            if (!can) {
                message.error("该浏览器暂不支持视频标注，请使用 chrome 浏览器打开并升级至最新版本");
            }
        });
    }
    componentWillUnmount() {
        this.video.src = "";
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.playbackRate !== undefined) {
            this.setVideoPlaybackRate(nextProps.playbackRate);
        }
        if (nextProps.width !== undefined && videoLabelStore.meta.duration) {
            const units = Math.floor(this.props.width / videoLabelStore.unit) - 0.5;
            videoLabelStore.unitValue = Math.ceil(videoLabelStore.meta.duration / units);
        }
    }
    setVideoPlaybackRate(rate) {
        if (this.video) {
            this.video.playbackRate = rate;
        }
    }
    isSupported(cb) {
        if (typeof this.video.requestVideoFrameCallback !== "function") {
            return cb(false);
        }
        const parser = new UAParser();
        const agent = parser.getResult();
        const major = parseInt(agent.browser.major);
        if (major < 112) {
            cb(false);
        }
    }
    async play() {
        await this.video.play();
        this.frameRAF();
        this.imageRAF();
        this.labelRAF();
    }
    pause() {
        if (this.video.paused === true)
            return;
        this.video.pause();
        const duration = videoLabelStore.meta.duration;
        const currentTime = videoLabelStore.meta.currentTime;
        const timePerFrame = videoLabelStore.meta.timePerFrame;
        setTimeout(() => {
            let timems = Math.round(currentTime / timePerFrame) * timePerFrame;
            if (timems > duration) {
                timems -= timePerFrame;
            }
            this.video.currentTime = timems / 1000;
            videoLabelStore.meta.currentTime = timems;
        }, 200);
    }
    frameRAF() {
        this.computeFrame();
        if (this.video.paused || this.video.ended)
            return;
        this.video.requestVideoFrameCallback((now, metaData) => {
            videoLabelStore.meta.currentTime = metaData.mediaTime * 1000;
            this.frameRAF();
        });
    }
    imageRAF() {
        this.draw();
        if (this.video.paused || this.video.ended)
            return;
        this.video.requestVideoFrameCallback(() => this.imageRAF());
    }
    labelRAF() {
        this.drawLabel();
        if (this.video.paused || this.video.ended)
            return;
        this.video.requestVideoFrameCallback((now, metaData) => this.labelRAF(metaData.mediaTime));
    }
    draw(quality = 0.4) {
        imageLabelStore.status.curResource &&
            (imageLabelStore.status.curResource.dataUrl = this.canvas.toDataURL("image/jpeg", quality));
    }
    setCurrentTime(timems) {
        const { meta } = videoLabelStore;
        if (timems < 0) {
            timems = 0;
        }
        if (timems > meta.duration) {
            timems = meta.duration;
        }
        this.video.currentTime = timems / 1000;
        meta.currentTime = timems;
    }
    back(frame) {
        const { meta } = videoLabelStore;
        let current = meta.currentTime;
        let time = current - frame * meta.timePerFrame;
        this.setCurrentTime(time);
    }
    forward(frame) {
        const { meta } = videoLabelStore;
        let current = meta.currentTime;
        let time = current + frame * meta.timePerFrame;
        this.setCurrentTime(time);
    }
    drawLabel() {
        imageLabelStore.status.layerElements.forEach(ele => {
            const trails = ele.detection.trails;
            if (ele.label.tool === DetectionTool.Rectangle && trails) {
                this.drawRect(ele);
            }
            else if (ele.label.tool === DetectionTool.Dot && trails) {
                this.drawCircle(ele);
            }
            else if (ele.label.tool === DetectionTool.Path && trails) {
            }
        });
    }
    computeFrame() {
        var width = this.canvas.width;
        var height = this.canvas.height;
        this.ctx.drawImage(this.video, 0, 0, width, height);
    }
    drawRect(ele) {
        const trails = ele.detection.trails;
        const headTrail = trails[0];
        const currentFrame = videoLabelStore.meta.currentTime / videoLabelStore.meta.timePerFrame;
        const currentFrameInt = Math.round(currentFrame);
        const attributes = ele.attributes;
        if (currentFrameInt < headTrail.frame) {
            ele.display = "hidden";
        }
        else {
            ele.display = "normal";
            const { x, y, width, height, cover, hidden } = videoLabelStore.getTweenTrail(trails, ele.detection.tool, currentFrame);
            if (hidden == true) {
                ele.display = "hidden";
            }
            if (width <= 0 || height <= 0) {
                ele.display = "hidden";
            }
            if (cover) {
                ele.display = "border";
            }
            this.setAttributes(attributes, { x, y, width, height });
        }
    }
    setAttributes(attributes, attrs) {
        const { status: { zoomRatio, config: { painterBGSize } } } = imageLabelStore;
        let { width, height, x, y } = attrs;
        if (width != null) {
            width < 0 && (width = 0);
            attributes.width = width;
        }
        if (height != null) {
            height < 0 && (height = 0);
            attributes.height = height;
        }
        if (x != null) {
            x < 0 && (x = 0);
            x > painterBGSize.width * zoomRatio && (x = painterBGSize.width * zoomRatio);
            attributes.x = x;
        }
        if (y != null) {
            y < 0 && (y = 0);
            y > painterBGSize.height * zoomRatio && (y = painterBGSize.height * zoomRatio);
            attributes.y = y;
        }
    }
    drawCircle(ele) {
        const { status: { zoomRatio } } = imageLabelStore;
        const attributes = ele.attributes;
        const _trails = ele.detection.trails;
        const currentFrame = this.video.currentTime * videoLabelStore.meta.frameRate;
        const currentFrameInt = Math.round(currentFrame);
        const trails = _trails.map(v => {
            return Object.assign(Object.assign({}, v), { cx: v.cx * zoomRatio, cy: v.cy * zoomRatio });
        });
        if (trails.length >= 2) {
            let headPos = trails[0];
            let tailPos = trails[trails.length - 1];
            for (let i = 0; i < trails.length - 1; i++) {
                let pos = trails[i];
                let nextPos = trails[i + 1];
                if (pos.frame <= currentFrameInt && currentFrameInt <= nextPos.frame) {
                    ele.display = "normal";
                    let deltaFrame = nextPos.frame - pos.frame;
                    let kX = (nextPos.cx - pos.cx) / deltaFrame;
                    let kY = (nextPos.cy - pos.cy) / deltaFrame;
                    attributes.cx = kX * (currentFrame - pos.frame) + pos.cx;
                    attributes.cy = kY * (currentFrame - pos.frame) + pos.cy;
                }
                else if (currentFrameInt < headPos.frame) {
                    ele.attributes.cx = headPos.cx;
                    ele.attributes.cy = headPos.cy;
                    ele.display = "hidden";
                }
                else if (currentFrameInt > tailPos.frame) {
                    attributes.cx = tailPos.cx;
                    attributes.cy = tailPos.cy;
                    ele.display = "normal";
                }
            }
        }
        else {
            if (currentFrameInt < trails[0].frame) {
                ele.display = "hidden";
            }
            else {
                ele.display = "normal";
            }
        }
        if (ele.label.type === "anchor") {
            const startPos = _.find(trails, { keyFrame: 0 });
            const endPos = _.find(trails, { keyFrame: 1 });
            if (currentFrameInt < (startPos === null || startPos === void 0 ? void 0 : startPos.frame) || currentFrameInt > (endPos === null || endPos === void 0 ? void 0 : endPos.frame)) {
                ele.display = "hidden";
            }
        }
    }
    render() {
        return (<div style={{ display: "none" }}>
        <canvas ref={el => (this.canvas = el)}></canvas>
        <video crossOrigin="anonymous" src={this.props.src} ref={el => (this.video = el)} onLoadedData={() => {
            this.video.currentTime = videoLabelStore.currentTime;
        }} onLoadedMetadata={() => {
            videoLabelStore.isLoading = false;
            this.canvas.width = this.video.videoWidth;
            this.canvas.height = this.video.videoHeight;
            process.nextTick(() => {
                videoLabelStore.meta.duration = this.video.duration * 1000;
                const units = Math.floor(this.props.width / videoLabelStore.unit) - 0.5;
                videoLabelStore.unitValue = Math.ceil(videoLabelStore.meta.duration / units);
            });
        }} onDurationChange={() => {
            videoLabelStore.meta.duration = this.video.duration * 1000;
        }} onTimeUpdate={() => {
            if (this.video.paused) {
                this.computeFrame();
                this.draw();
                this.drawLabel();
            }
        }} onEnded={() => {
            videoLabelStore.paused = true;
        }} onSeeking={() => {
        }} onSeeked={() => {
        }} onWaiting={() => {
            this.props.onLoadingChange(true);
        }} onCanPlay={() => {
            this.props.onLoadingChange(false);
        }} controls width="300"></video>
      </div>);
    }
};
VideoStage.defaultProps = {
    onLoading: () => { },
    playbackRate: 1,
    src: ""
};
VideoStage = __decorate([
    observer
], VideoStage);
export default VideoStage;
