var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Card, Input, Select, Popover, Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ClassificationTool, DetectionTool } from "definition/entity/label-task";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { CompactPicker } from "react-color";
import { Color } from "../../../../util/color";
let ClassificationFormItem = class ClassificationFormItem extends React.Component {
    constructor() {
        var _a, _b, _c, _d;
        super(...arguments);
        this.data = observable({
            type: "classification",
            key: ((_a = this.props.value) === null || _a === void 0 ? void 0 : _a.key) || "",
            locale: ((_b = this.props.value) === null || _b === void 0 ? void 0 : _b.locale) || "",
            tool: ((_c = this.props.value) === null || _c === void 0 ? void 0 : _c.tool) || ClassificationTool.Switch,
            defaultValue: this.props.value.defaultValue || null,
            options: ((_d = this.props.value) === null || _d === void 0 ? void 0 : _d.options) || []
        });
        this.onChange = (value) => {
            if (this.props.onChange) {
                this.props.onChange(toJS(value));
            }
        };
    }
    componentWillReceiveProps(nextProps) {
        Object.assign(this.data, nextProps.value);
    }
    render() {
        var _a;
        const classification = this.data;
        const onChange = this.onChange;
        return (<Card className="classification-item-comp">
        <div className="classification-body-wrapper">
          <div>属性</div>
          <div className="item-desc-detail">
            <Input value={classification.key} onChange={e => {
            classification.key = e.target.value;
            onChange(classification);
        }} placeholder="问题 key"/>
            <Input value={classification.locale} onChange={e => {
            classification.locale = e.target.value;
            onChange(classification);
        }} placeholder="问题说明"/>

            <Select className="item-type" value={classification.tool} onChange={e => {
            classification.tool = e;
            classification.options = [];
            classification.defaultValue = null;
            onChange(classification);
        }} placeholder="选择类型">
              <Select.Option key="switch">布尔</Select.Option>
              <Select.Option key="radio">单选</Select.Option>
              <Select.Option key="checkbox">多选</Select.Option>
              <Select.Option key="text">文本</Select.Option>
              <Select.Option key="number">数字</Select.Option>
              <Select.Option key="date">日期</Select.Option>
              <Select.Option key="time">时间</Select.Option>
              <Select.Option key="select">多选框</Select.Option>
            </Select>
            {this.props.detectionTool === DetectionTool.Chunk &&
            ["违法车辆数", "点位信息", "摄像头日期", "进入时间", "离开时间"].includes(classification.key) ? (undefined) : (<DeleteOutlined className="item-delete-icon" onClick={() => {
            this.props._onDelete();
        }}/>)}
          </div>
          {[ClassificationTool.Checkbox, ClassificationTool.Radio, ClassificationTool.Select].includes(classification.tool) && (<div>
              <div>标签选项</div>
              {(_a = classification.options) === null || _a === void 0 ? void 0 : _a.map((option, index) => (<div key={index} className="item-options-detail">
                  <Popover content={<CompactPicker value={option.color} onChange={(color) => {
            option.color = color.hex;
            onChange(classification);
        }} style={{ width: 200 }}/>}>
                    <div style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            background: option.color
        }}/>
                  </Popover>
                  <Input value={option.key} onChange={e => {
            option.key = e.target.value;
            onChange(classification);
        }} placeholder="选项 key"/>
                  <Input value={option.locale} onChange={e => {
            option.locale = e.target.value;
            onChange(classification);
        }} placeholder="选项说明"/>

                  <DeleteOutlined onClick={() => {
            classification.options.splice(index, 1);
            onChange(classification);
        }}/>
                </div>))}
              <Button icon={<PlusOutlined />} style={{ width: "min-content" }} onClick={() => {
            classification.options = classification.options || [];
            classification.options.push({
                key: "",
                color: Color.nextColor(),
                locale: ""
            });
            onChange(classification);
        }}>
                添加选项
              </Button>
            </div>)}
        </div>
      </Card>);
    }
};
ClassificationFormItem = __decorate([
    observer
], ClassificationFormItem);
export default ClassificationFormItem;
