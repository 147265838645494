import * as React from "react";
import { imageLabelMultiStore } from "./ImageLabelMultiStore";
import { LabelSelector } from "./component/LabelSelector/LabelSelector";
import { PainterWrapper } from "./component/PainterWrapper/PainterWrapper";
import { LabelFooterStatus } from "./component/LabelFooterStatus/LabelFooterStatus";
import { LabelHeader } from "./component/LabelHeader/LabelHeader";
import { parseLocationSearch } from "../../../../util/parse-location-search";
import { keymapStore } from "../ImageLabel/provider/keymap-store";
import { KeyboardEventKey } from "../interface/common";
import "./ImageLabelMulti.less";
export class ImageLabelMulti extends React.Component {
    constructor() {
        super(...arguments);
        this.imageLabelMultiOnKeyDown = async (event) => {
            const { status } = imageLabelMultiStore;
            if (status.readonly) {
                await this.readonlyOnKeyDown(event);
            }
            else {
                await this.normalOnKeyDown(event);
            }
        };
        this.normalOnKeyDown = async (event) => {
            keymapStore.trigger(event);
            if (event.key === KeyboardEventKey.ArrowLeft) {
                event.preventDefault();
                await imageLabelMultiStore.prevPage();
                return;
            }
            if (event.key === KeyboardEventKey.ArrowRight) {
                event.preventDefault();
                await imageLabelMultiStore.nextPage();
                return;
            }
            if (event.key === KeyboardEventKey.Escape) {
                event.preventDefault();
                imageLabelMultiStore.status.curClassification = null;
                return;
            }
        };
        this.readonlyOnKeyDown = async (event) => {
            if (event.key === KeyboardEventKey.ArrowLeft) {
                event.preventDefault();
                await imageLabelMultiStore.prevPage();
                return;
            }
            if (event.key === KeyboardEventKey.ArrowRight) {
                event.preventDefault();
                await imageLabelMultiStore.nextPage();
                return;
            }
        };
    }
    async componentDidMount() {
        const { labelTaskId, labelPackageId } = this.props.match.params;
        const { perPage, curPage, readonly } = parseLocationSearch(this.props.location.search);
        if (readonly) {
            imageLabelMultiStore.status.readonly = true;
        }
        if (perPage && curPage) {
            imageLabelMultiStore.status.pager = imageLabelMultiStore.status.pager.setCurPage(+curPage).setPerPage(+perPage);
        }
        await imageLabelMultiStore.initLabelPage(+labelTaskId, +labelPackageId);
        imageLabelMultiStore.loadMultiLabelProgress();
        document.body.addEventListener("keydown", this.imageLabelMultiOnKeyDown);
    }
    componentWillUnmount() {
        imageLabelMultiStore.resetStatus();
        keymapStore.releaseAll();
        document.body.removeEventListener("keydown", this.imageLabelMultiOnKeyDown);
    }
    render() {
        const { readonly } = imageLabelMultiStore.status;
        return (<div className="image-label-multi-component" tabIndex={-1} onContextMenu={event => event.preventDefault()}>
        <div className="image-label-header">
          <LabelHeader />
        </div>
        <div className="image-label-content">
          <div className="image-label-content-left" style={{ width: 240 }}>
            <LabelSelector />
          </div>
          <div className="image-label-content-center">
            <PainterWrapper />
          </div>

          
          {readonly && (<div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#8FB0D1",
            opacity: 0.3
        }}></div>)}
        </div>
        <div className="image-label-footer">
          <LabelFooterStatus />
        </div>
      </div>);
    }
}
