var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import * as _ from "lodash";
import moment from "moment";
import BasicStore from "store/BasicStore";
import { action, observable, computed, toJS } from "mobx";
import { IAnnotationAudit } from "definition/entity/annotation-audit";
import { Api } from "api/Api";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { message } from "antd";
export const businessTypeDesc = (type) => {
    switch (type) {
        case LabelTaskBusiness.Type.Box:
            return "框";
        case LabelTaskBusiness.Type.Piece:
            return "张";
        case LabelTaskBusiness.Type.Object:
            return "个";
        case LabelTaskBusiness.Type.Chunk:
            return "段";
        case LabelTaskBusiness.Type.Anchor:
            return "个";
        case LabelTaskBusiness.Type.MaskBox:
            return "框";
        case LabelTaskBusiness.Type.Cloud:
            return "个";
        default:
            return "未知";
    }
};
class Status {
    constructor() {
        this.loading = false;
        this.compositeAudits = [];
        this.workAudits = [];
        this.groups = [];
        this.users = [];
        this.auditPeriod = IAnnotationAudit.AuditPeriod.Daily;
        this.calendarDate = [];
        this.columnDate = [];
        this.curGroupIds = [];
        this.curUserIds = [];
        this.exportCSVData = [];
        this.CSVFilename = undefined;
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "compositeAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "workAudits", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "groups", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "users", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof IAnnotationAudit !== "undefined" && IAnnotationAudit.AuditPeriod) === "function" ? _a : Object)
], Status.prototype, "auditPeriod", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "calendarDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "columnDate", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curGroupIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "curUserIds", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "exportCSVData", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "CSVFilename", void 0);
class UserCompositeAuditStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        let auditDateString = this.auditDateString;
        if (auditDateString.length === 0) {
            return;
        }
        const { auditPeriod, curGroupIds, curUserIds } = toJS(this.status);
        try {
            this.status.loading = true;
            const compositeAudits = await Api.audit.getUserCompositeAudits({
                auditPeriod: auditPeriod,
                auditDateStart: auditDateString[0],
                auditDateEnd: moment(auditDateString[1])
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                groupIds: curGroupIds,
                userIds: curUserIds
            });
            const workAudits = await Api.audit.getWorkAudits({
                auditDateStart: auditDateString[0],
                auditDateEnd: moment(auditDateString[1])
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                groupIds: curGroupIds,
                userIds: curUserIds
            });
            this.status.compositeAudits = compositeAudits.data;
            this.status.workAudits = workAudits.data;
            this.status.columnDate = [moment(auditDateString[0]), moment(auditDateString[1])];
            this.status.CSVFilename = `${auditDateString[0]}_${auditDateString[1]}_userCompositeAudits`;
        }
        catch (err) {
            message.error(err.message);
        }
        finally {
            this.status.loading = false;
        }
    }
    async loadGroups() {
        const getGroupRes = await Api.membership.listGroups();
        this.status.groups = getGroupRes.data;
    }
    async loadUsers() {
        const getUsersRes = await Api.user.listUsers();
        this.status.users = getUsersRes.data;
    }
    downAnnotationAuditFile() {
        const { exportCSVData } = this.status;
        exportCSVData.length = 0;
        const curMergeUser = [[], ["计价类型"], ["总工作量"], ["负责人"], [""]];
        this.transformedAnnotation.forEach(audit => {
            curMergeUser[0].push(audit.nickname);
            this.tableColumns.forEach(column => {
                if (audit[column]) {
                    audit[column].forEach(item => {
                        curMergeUser[0].push(column);
                        curMergeUser[1].push(item.businessName || "未分配类型");
                        curMergeUser[2].push(`${item.totalWorkload}(${businessTypeDesc(item === null || item === void 0 ? void 0 : item.businessType)})`);
                        curMergeUser[3].push(item.createTaskUser);
                        curMergeUser[4].push("");
                    });
                }
                else {
                    curMergeUser[0].push(column);
                    curMergeUser[1].push("-");
                    curMergeUser[2].push("-");
                    curMergeUser[3].push("-");
                    curMergeUser[4].push("");
                }
            });
            exportCSVData.push(...curMergeUser);
            curMergeUser[0].length = 0;
            curMergeUser[1].length = 1;
            curMergeUser[2].length = 1;
            curMergeUser[3].length = 1;
            curMergeUser[4].length = 1;
        });
    }
    get transformedAnnotation() {
        const annotationAuditsMap = new Map();
        this.status.compositeAudits.forEach(audit => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
            let totalWorkload = 0;
            if (((_a = audit.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.type) === LabelTaskBusiness.Type.Box) {
                totalWorkload = audit.detectionCount;
            }
            if (((_b = audit.labelTaskBusiness) === null || _b === void 0 ? void 0 : _b.type) === LabelTaskBusiness.Type.Piece) {
                totalWorkload = audit.classificationCount;
            }
            if (((_c = audit.labelTaskBusiness) === null || _c === void 0 ? void 0 : _c.type) === LabelTaskBusiness.Type.MaskBox) {
                totalWorkload = audit.segmentCount;
            }
            if (((_d = audit.labelTaskBusiness) === null || _d === void 0 ? void 0 : _d.type) === LabelTaskBusiness.Type.Cloud) {
                totalWorkload = audit.segment3dCount;
            }
            if (((_e = audit.labelTaskBusiness) === null || _e === void 0 ? void 0 : _e.type) === LabelTaskBusiness.Type.Object) {
                totalWorkload = audit.trailCount;
            }
            if (((_f = audit.labelTaskBusiness) === null || _f === void 0 ? void 0 : _f.type) === LabelTaskBusiness.Type.Chunk) {
                totalWorkload = audit.chunkCount;
            }
            if (((_g = audit.labelTaskBusiness) === null || _g === void 0 ? void 0 : _g.type) === LabelTaskBusiness.Type.Anchor) {
                totalWorkload = audit.anchorCount;
            }
            if (annotationAuditsMap.has((_h = audit.user) === null || _h === void 0 ? void 0 : _h.id)) {
                if (annotationAuditsMap.get((_j = audit.user) === null || _j === void 0 ? void 0 : _j.id)[`${audit.auditDate}`]) {
                    let theSameBusiness = annotationAuditsMap
                        .get((_k = audit.user) === null || _k === void 0 ? void 0 : _k.id)[`${audit.auditDate}`].find(item => { var _a; return item.businessId === ((_a = audit.labelTaskBusiness) === null || _a === void 0 ? void 0 : _a.id); });
                    if (theSameBusiness) {
                        theSameBusiness.totalWorkload += totalWorkload;
                    }
                    else {
                        annotationAuditsMap.get((_l = audit.user) === null || _l === void 0 ? void 0 : _l.id)[`${audit.auditDate}`].push({
                            businessId: (_m = audit.labelTaskBusiness) === null || _m === void 0 ? void 0 : _m.id,
                            businessName: (_o = audit.labelTaskBusiness) === null || _o === void 0 ? void 0 : _o.name,
                            businessType: (_p = audit.labelTaskBusiness) === null || _p === void 0 ? void 0 : _p.type,
                            totalWorkload: totalWorkload,
                            createTaskUser: (_q = audit.createTaskUser) === null || _q === void 0 ? void 0 : _q.nickname
                        });
                    }
                }
                else {
                    const dailyWork = this.status.workAudits.filter(item => { var _a; return item.creatorId === ((_a = audit.user) === null || _a === void 0 ? void 0 : _a.id) && item.fillDate === audit.auditDate; });
                    annotationAuditsMap.get((_r = audit.user) === null || _r === void 0 ? void 0 : _r.id)[`${audit.auditDate}`] = [
                        {
                            businessId: (_s = audit.labelTaskBusiness) === null || _s === void 0 ? void 0 : _s.id,
                            businessName: (_t = audit.labelTaskBusiness) === null || _t === void 0 ? void 0 : _t.name,
                            businessType: (_u = audit.labelTaskBusiness) === null || _u === void 0 ? void 0 : _u.type,
                            totalWorkload: totalWorkload,
                            createTaskUser: (_v = audit.createTaskUser) === null || _v === void 0 ? void 0 : _v.nickname,
                            duration: dailyWork.reduce((prev, cur) => {
                                return prev + cur.duration;
                            }, 0)
                        }
                    ];
                }
            }
            else {
                const dailyWork = this.status.workAudits.filter(item => { var _a; return item.creatorId === ((_a = audit.user) === null || _a === void 0 ? void 0 : _a.id) && item.fillDate === audit.auditDate; });
                annotationAuditsMap.set((_w = audit.user) === null || _w === void 0 ? void 0 : _w.id, {
                    userId: (_x = audit.user) === null || _x === void 0 ? void 0 : _x.id,
                    username: (_y = audit.user) === null || _y === void 0 ? void 0 : _y.name,
                    nickname: (_z = audit.user) === null || _z === void 0 ? void 0 : _z.nickname,
                    [`${audit.auditDate}`]: [
                        {
                            businessId: (_0 = audit.labelTaskBusiness) === null || _0 === void 0 ? void 0 : _0.id,
                            businessName: (_1 = audit.labelTaskBusiness) === null || _1 === void 0 ? void 0 : _1.name,
                            businessType: (_2 = audit.labelTaskBusiness) === null || _2 === void 0 ? void 0 : _2.type,
                            totalWorkload: totalWorkload,
                            createTaskUser: (_3 = audit.createTaskUser) === null || _3 === void 0 ? void 0 : _3.nickname,
                            duration: dailyWork.reduce((prev, cur) => {
                                return prev + cur.duration;
                            }, 0)
                        }
                    ]
                });
            }
        });
        return [...annotationAuditsMap.values()];
    }
    get isUnableDownload() {
        return _.isEmpty(toJS(this.status.compositeAudits));
    }
    get isUnableSearch() {
        return _.isEmpty(toJS(this.status.calendarDate)) || this.status.loading;
    }
    get auditDateString() {
        const { calendarDate, auditPeriod } = this.status;
        let auditDateString = [];
        switch (auditPeriod) {
            case IAnnotationAudit.AuditPeriod.Daily:
                auditDateString = calendarDate.map(item => {
                    return item.format("YYYY-MM-DD");
                });
                break;
            case IAnnotationAudit.AuditPeriod.Weekly:
                auditDateString = calendarDate.map(item => {
                    return item.endOf("week").format("YYYY-MM-DD");
                });
                break;
            case IAnnotationAudit.AuditPeriod.Monthly:
                auditDateString = calendarDate.map(item => {
                    return item.endOf("month").format("YYYY-MM-DD");
                });
                break;
            default:
                return;
        }
        return auditDateString;
    }
    get filterUsers() {
        const { users, curGroupIds } = toJS(this.status);
        if (_.isEmpty(curGroupIds)) {
            return users;
        }
        return users.filter(item => { var _a; return curGroupIds.includes((_a = item === null || item === void 0 ? void 0 : item.group) === null || _a === void 0 ? void 0 : _a.id); });
    }
    get tableColumns() {
        var _a;
        const { columnDate } = toJS(this.status);
        const tableColumns = [];
        let curDate = (_a = columnDate[0]) === null || _a === void 0 ? void 0 : _a.clone();
        while (curDate <= columnDate[1]) {
            tableColumns.push(curDate.format("YYYY-MM-DD"));
            curDate = curDate.add(1, "day");
        }
        return tableColumns;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], UserCompositeAuditStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserCompositeAuditStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], UserCompositeAuditStore.prototype, "transformedAnnotation", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UserCompositeAuditStore.prototype, "isUnableDownload", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], UserCompositeAuditStore.prototype, "isUnableSearch", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], UserCompositeAuditStore.prototype, "auditDateString", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], UserCompositeAuditStore.prototype, "filterUsers", null);
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], UserCompositeAuditStore.prototype, "tableColumns", null);
export const userCompositeAuditStore = new UserCompositeAuditStore();
