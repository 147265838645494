import * as ReactDOM from "react-dom";
export class OnceComponent {
    show(children) {
        this.node = document.createElement("div");
        document.body.appendChild(this.node);
        ReactDOM.render(children, this.node);
    }
    destroy() {
        ReactDOM.unmountComponentAtNode(this.node);
        document.body.removeChild(this.node);
    }
}
