var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { message } from "antd";
import _, { isEqual } from "lodash";
import * as math from "mathjs";
import { autorun, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React, { createRef } from "react";
import * as THREE from "three";
import ContextMenu from "../image/ImageLabel/component/PainterWrapper/ContextMenu";
import { imageLabelStore } from "../image/ImageLabel/ImageLabelStore";
import { PainterDom } from "../image/ImageLabel/provider/painter-dom";
import Cloud from "./cloud";
import Image2D from "./Image2D";
import InteractiveBox from "./InteractiveBox";
import PCDLoader from "./pcdLoader.js";
import LassoSelector from "./selector/lasso";
import RectSelector from "./selector/rect";
import SubView from "./SubView";
import BoxTool from "./tool/box";
import "./Editor3D.less";
import localforage from "localforage";
new PCDLoader(THREE);
let Editor3D = class Editor3D extends React.Component {
    constructor() {
        super(...arguments);
        this.projectionPoints = [];
        this.state = {
            contextMenu: {
                menus: [],
                left: 0,
                top: 0,
                visible: false
            }
        };
        this.image2DRef = createRef();
        this.containerRef = createRef();
        this.sourceCanvasRef = createRef();
        this.selectionCanvasRef = createRef();
        this.mouseCanvasRef = createRef();
        this.frontViewRef = createRef();
        this.sideViewRef = createRef();
        this.topViewRef = createRef();
        this.frontViewCanvasRef = createRef();
        this.sideViewCanvasRef = createRef();
        this.topViewCanvasRef = createRef();
        this.mouseAction = null;
        this.selector = null;
        this.tool = null;
        this.clouds = [];
        this.disabled = false;
        this.loader = new THREE.PCDLoader();
        this.onMouseDown = (e) => {
            const { props: { selector, label }, containerBounding } = this;
            const startEvent = { x: e.pageX, y: e.pageY };
            if (e.button === 0) {
                const x = e.pageX - containerBounding.left;
                const y = e.pageY - containerBounding.top;
                if (!label) {
                    const closer = this.boxTool.intersect(this.mainCloud.intersects);
                    if (closer) {
                        imageLabelStore.status.selectedBoxId = closer.object.parent.id;
                    }
                }
                else {
                    if (label.type === "detection3D") {
                        this.tool = this.boxTool;
                        const start = {
                            x: (x / containerBounding.width) * 2 - 1,
                            y: -(y / containerBounding.height) * 2 + 1,
                            z: 0
                        };
                        const onMouseMove = (e) => {
                            const x = e.pageX - containerBounding.left;
                            const y = e.pageY - containerBounding.top;
                            const current = {
                                x: (x / containerBounding.width) * 2 - 1,
                                y: -(y / containerBounding.height) * 2 + 1,
                                z: 0
                            };
                            this.tool.onDraw({ start, current }, { start: startEvent, current: { x: e.pageX, y: e.pageY } });
                        };
                        const onMouseUp = (e) => {
                            const x = e.pageX - containerBounding.left;
                            const y = e.pageY - containerBounding.top;
                            const current = {
                                x: (x / containerBounding.width) * 2 - 1,
                                y: -(y / containerBounding.height) * 2 + 1,
                                z: 0
                            };
                            this.tool.onDrawEnd({ start, current });
                            this.mouseAction = null;
                            window.removeEventListener("mousemove", onMouseMove);
                            window.removeEventListener("mouseup", onMouseUp);
                        };
                        window.addEventListener("mousemove", onMouseMove);
                        window.addEventListener("mouseup", onMouseUp);
                        this.tool.onDrawStart({ start, current: Object.assign({}, start) }, label);
                    }
                    if (selector && label.type !== "detection3D") {
                        const start = { x, y };
                        const onMouseMove = (e) => {
                            const current = { x: e.pageX - containerBounding.left, y: e.pageY - containerBounding.top };
                            this.selector.onDraw({ start, current });
                        };
                        const onMouseUp = (e) => {
                            const current = { x: e.pageX - containerBounding.left, y: e.pageY - containerBounding.top };
                            this.selector.onDrawEnd({ start, current });
                            this.mouseAction = null;
                            window.removeEventListener("mousemove", onMouseMove);
                            window.removeEventListener("mouseup", onMouseUp);
                        };
                        window.addEventListener("mousemove", onMouseMove);
                        window.addEventListener("mouseup", onMouseUp);
                        switch (selector) {
                            case "lasso":
                                this.selector = this.lassoSelector;
                                break;
                            case "rect":
                                this.selector = this.rectSelector;
                                break;
                            case "circle":
                                this.selector = this.circleSelector;
                                break;
                        }
                        this.mouseAction = "draw";
                        this.selector.onDrawStart({ start, current: Object.assign({}, start) });
                    }
                }
            }
            else if (e.button === 2) {
                if (e.ctrlKey || e.metaKey || e.shiftKey) {
                    this.mouseAction = "drag";
                }
                else if (selector && label) {
                    this.mouseAction = "select";
                }
            }
        };
        this.onMouseMove = (e) => {
            if (this.props.segments3DLabels.length) {
                this.drawMouseCanvas();
            }
        };
        this.onMouseUp = () => {
            const { props: { label } } = this;
            if ((label === null || label === void 0 ? void 0 : label.type) !== "detection3D") {
                if (this.mouseAction == "select") {
                    if (this.mainCloud.highlightedIndex != null) {
                        this.onSelect([this.mainCloud.highlightedIndex], this.labelIndex);
                    }
                }
                this.drawMouseCanvas();
                this.mouseAction = null;
            }
        };
        this.onA = () => {
            if (!imageLabelStore.status.selectedBoxId)
                return;
            const menus = [
                {
                    key: "changeKey",
                    text: "修改标签",
                    children: imageLabelStore.status.selectableLabel.detections3D.map(v => ({ key: v.key, text: v.key }))
                },
                { key: "hidden", text: "隐藏立体框" },
                { key: "delete", text: "删除立体框" },
                { key: "cancel", text: "取消" }
            ];
            const box3 = this.boxTool.getBox(imageLabelStore.status.selectedBoxId);
            const width = this.mainCloud.width;
            const height = this.mainCloud.height;
            const pp = this.boxTool.project(box3.position, this.mainCloud.camera);
            const pixelX = ((pp.x + 1) / 2) * width;
            const pixelY = (-(pp.y - 1) / 2) * height;
            this.setState({
                contextMenu: Object.assign(Object.assign({}, this.state.contextMenu), { menus, visible: true, left: pixelX, top: pixelY })
            });
        };
        this.onContextMenuClick = (keys) => {
            const action = _.last(keys);
            const params = _.slice(keys, 0, -1);
            const id = imageLabelStore.status.selectedBoxId;
            const box = _.find(this.props.boxes, { id });
            switch (action) {
                case "changeKey":
                    this.removeBox(id);
                    const matchedLabel = _.find(imageLabelStore.status.selectableLabel.detections3D, { key: params[0] });
                    const _uid = PainterDom.generateId(matchedLabel.key);
                    const newBox = this.boxTool.createBox(Object.assign(Object.assign({}, box), { color: matchedLabel.color, title: _uid }));
                    imageLabelStore.status.boxes.push(Object.assign(Object.assign({}, box), { id: newBox.id, _uid, label: matchedLabel, visible: true }));
                    this.updateBoxPositionProjection();
                    this.updateBoxVectorsProjection(newBox.id);
                    break;
                case "hidden":
                    this.setBoxVisible(id, false);
                    break;
                case "delete":
                    this.removeBox(id);
                    break;
                case "cancel":
                    break;
            }
            this.setState({
                contextMenu: Object.assign(Object.assign({}, this.state.contextMenu), { visible: false })
            });
        };
        this.onKeyDown = (e) => {
            const status = imageLabelStore.status;
            if (e.key.toLowerCase() === "s") {
                status.boxes
                    .filter(item => status.selectedBoxId !== item.id)
                    .forEach(e => {
                    this.setBoxVisible(e.id, true);
                });
                return;
            }
            if (e.key.toLowerCase() === "h") {
                status.boxes
                    .filter(item => status.selectedBoxId !== item.id)
                    .forEach(e => {
                    this.setBoxVisible(e.id, false);
                });
                return;
            }
            if (e.key.toLowerCase() === "d") {
                status.boxes
                    .filter(item => status.selectedBoxId !== item.id)
                    .forEach(e => {
                    this.setBoxTitleVisible(e.id, true);
                });
                return;
            }
            if (e.key.toLowerCase() === "f") {
                status.boxes
                    .filter(item => status.selectedBoxId !== item.id)
                    .forEach(e => {
                    this.setBoxTitleVisible(e.id, false);
                });
                return;
            }
            if (e.key === "Escape") {
                imageLabelStore.status.selectedBoxId = null;
            }
            else if (e.key === "Delete" || e.key === "Backspace") {
                if (imageLabelStore.status.selectedBoxId != null) {
                    this.removeBox(imageLabelStore.status.selectedBoxId);
                    imageLabelStore.status.selectedBoxId = null;
                }
            }
            else if (e.key === "a") {
                this.onA();
            }
        };
    }
    get filteredCloudData() {
        var _a;
        return ((_a = this.mainCloud) === null || _a === void 0 ? void 0 : _a.cloudData) ? this.mainCloud.cloudData.filter(v => v.z > imageLabelStore.projectionRange[0] && v.z < imageLabelStore.projectionRange[1])
            : [];
    }
    get canvasOffset() {
        return {
            width: this.props.width,
            height: this.props.height
        };
    }
    get labelIndex() {
        return _.findIndex(this.props.segments3DLabels, this.props.label);
    }
    async componentDidMount() {
        this.addEvents();
        this.init();
        const subViewHeight = Math.floor(this.props.height / 3);
        imageLabelStore.frontViewOffset.height = subViewHeight;
        imageLabelStore.frontViewOffset.left = this.props.width - 300;
        imageLabelStore.frontViewOffset.top = 0;
        imageLabelStore.sideViewOffset.height = subViewHeight;
        imageLabelStore.sideViewOffset.left = this.props.width - 300;
        imageLabelStore.sideViewOffset.top = subViewHeight * 2;
        imageLabelStore.topViewOffset.height = subViewHeight;
        imageLabelStore.topViewOffset.left = this.props.width - 300;
        imageLabelStore.topViewOffset.top = subViewHeight;
        this.getSubViewOffset().then(viewOffset => {
            var _a, _b, _c;
            const { frontViewOffset, sideViewOffset, topViewOffset } = viewOffset || {};
            if ((frontViewOffset === null || frontViewOffset === void 0 ? void 0 : frontViewOffset.width) && (frontViewOffset === null || frontViewOffset === void 0 ? void 0 : frontViewOffset.height)) {
                (_a = this.frontCloud) === null || _a === void 0 ? void 0 : _a.resize(frontViewOffset.width, frontViewOffset.height);
            }
            if ((sideViewOffset === null || sideViewOffset === void 0 ? void 0 : sideViewOffset.width) && (sideViewOffset === null || sideViewOffset === void 0 ? void 0 : sideViewOffset.height)) {
                (_b = this.sideCloud) === null || _b === void 0 ? void 0 : _b.resize(sideViewOffset.width, sideViewOffset.height);
            }
            if ((topViewOffset === null || topViewOffset === void 0 ? void 0 : topViewOffset.width) && (topViewOffset === null || topViewOffset === void 0 ? void 0 : topViewOffset.height)) {
                (_c = this.topCloud) === null || _c === void 0 ? void 0 : _c.resize(topViewOffset.width, topViewOffset.height);
            }
            if (frontViewOffset && !isEqual(frontViewOffset, imageLabelStore.frontViewOffset)) {
                Object.assign(imageLabelStore.frontViewOffset, frontViewOffset);
            }
            if (sideViewOffset && !isEqual(sideViewOffset, imageLabelStore.sideViewOffset)) {
                Object.assign(imageLabelStore.sideViewOffset, sideViewOffset);
            }
            if (topViewOffset && !isEqual(topViewOffset, imageLabelStore.topViewOffset)) {
                Object.assign(imageLabelStore.topViewOffset, topViewOffset);
            }
        });
        autorun(() => {
            this.updateCloudProjection();
        });
    }
    componentWillUnmount() {
        const container = this.containerRef.current;
        container.removeEventListener("mousedown", this.onMouseDown);
        container.removeEventListener("mousemove", this.onMouseMove);
        container.removeEventListener("mouseup", this.onMouseUp);
        this.clouds.forEach(cloud => {
            cloud.dispose();
        });
        this.mainCloud = null;
        this.frontCloud = null;
        this.sideCloud = null;
        this.topCloud = null;
        imageLabelStore.off("hidden-points");
        imageLabelStore.off("show-points");
        imageLabelStore.off("resourceLoaded");
        this.props.cursor.set("default");
        this.removeEvents();
    }
    init() {
        this.containerBounding = this.containerRef.current.getBoundingClientRect();
        this.mouseContext = this.mouseCanvasRef.current.getContext("2d");
        this.rectSelector = new RectSelector(this, this.selectionCanvasRef.current);
        this.lassoSelector = new LassoSelector(this, this.selectionCanvasRef.current);
        this.boxTool = new BoxTool(this);
        const shaderMode = imageLabelStore.status.shaderMode3D;
        const mainCloud = (this.mainCloud = new Cloud(this, "perspective", this.sourceCanvasRef.current, this.containerRef.current, [], shaderMode, this.canvasOffset.width, this.canvasOffset.height, true));
        this.changeViewType(imageLabelStore.status.viewType);
        const cameraMode = this.props.detections3DLabels ? "orthographic" : "perspective";
        const { frontViewOffset, sideViewOffset, topViewOffset } = imageLabelStore;
        const frontCloud = (this.frontCloud = new Cloud(this, cameraMode, this.frontViewCanvasRef.current, this.frontViewRef.current, [], shaderMode, frontViewOffset.width, frontViewOffset.height, false));
        frontCloud.controls.enableRotate = false;
        const sideCloud = (this.sideCloud = new Cloud(this, cameraMode, this.sideViewCanvasRef.current, this.sideViewRef.current, [], shaderMode, sideViewOffset.width, sideViewOffset.height, false));
        sideCloud.controls.enableRotate = false;
        const topCloud = (this.topCloud = new Cloud(this, cameraMode, this.topViewCanvasRef.current, this.topViewRef.current, [], shaderMode, topViewOffset.width, topViewOffset.height, false));
        topCloud.controls.enableRotate = false;
        this.clouds.push(mainCloud, frontCloud, sideCloud, topCloud);
        function ensureSafeLabel(label, pcdLabel) {
            if (label.length < pcdLabel.length) {
                for (let i = label.length; i < pcdLabel.length; i++) {
                    label[i] = -1;
                }
            }
            return label;
        }
        if (this.props.url && this.props.pcdUrl) {
            Promise.all([this.waitForResource(), this.loadPCDFile(this.props.pcdUrl)]).then(([resource, pcd]) => {
                var _a, _b;
                imageLabelStore.status.pcd = this.pcd = pcd;
                const label = (this.label = ensureSafeLabel(toJS(((_b = (_a = resource === null || resource === void 0 ? void 0 : resource.annotation) === null || _a === void 0 ? void 0 : _a.segments3D) === null || _b === void 0 ? void 0 : _b.label) || pcd.label), toJS(pcd.label)));
                const position = (this.position = pcd.position);
                const object = (this.object = pcd.object);
                const intensity = (this.intensity = pcd.intensity);
                mainCloud.labels = this.props.segments3DLabels;
                mainCloud.renderPCDFile(position, label, object, intensity);
                mainCloud.cameraPreset("front", 0.5);
                mainCloud.moveCamera();
                mainCloud.controls.addEventListener("drag", () => {
                    if (this.mouseAction !== "drag") {
                        this.mouseAction = "drag";
                    }
                });
                mainCloud.controls.addEventListener("end", () => {
                    setTimeout(() => {
                        this.mouseAction = null;
                    });
                });
                mainCloud.controls.addEventListener("change", () => {
                    this.updateBoxPositionProjection();
                });
                const container = this.containerRef.current;
                container.addEventListener("mousedown", this.onMouseDown);
                container.addEventListener("mousemove", this.onMouseMove);
                container.addEventListener("mouseup", this.onMouseUp);
                imageLabelStore.on("hidden-points", (indices) => {
                    indices.forEach(idx => {
                        this.hidePoint(idx);
                    });
                    this.clouds.forEach(cloud => {
                        cloud.cloudObject.geometry.attributes.position.needsUpdate = true;
                        cloud.render();
                    });
                });
                imageLabelStore.on("show-points", (indices) => {
                    indices.forEach(idx => {
                        this.showPoint(idx);
                    });
                    this.clouds.forEach(cloud => {
                        cloud.cloudObject.geometry.attributes.position.needsUpdate = true;
                        cloud.render();
                    });
                });
                imageLabelStore.on("selectLabel", () => {
                    this.props.cursor.set("crosshair");
                });
                imageLabelStore.on("unselectLabel", () => {
                    this.props.cursor.set("default");
                });
                imageLabelStore.labelDisplay.forceUpdate();
                this.updateCloudProjection();
                this.image2DRef.current.renderProjection();
                frontCloud.labels = this.props.segments3DLabels;
                frontCloud.renderPCDFile(position, label, object, intensity);
                frontCloud.cameraPreset("front", 0, true, "selected");
                frontCloud.moveCamera();
                frontCloud.controls.addEventListener("change", () => {
                    this.updateBoxFaceProjection();
                });
                sideCloud.labels = this.props.segments3DLabels;
                sideCloud.renderPCDFile(position, label, object, intensity);
                sideCloud.cameraPreset("side", 0, true, "selected");
                sideCloud.moveCamera();
                sideCloud.controls.addEventListener("change", () => {
                    this.updateBoxFaceProjection();
                });
                topCloud.labels = this.props.segments3DLabels;
                topCloud.renderPCDFile(position, label, object, intensity);
                topCloud.cameraPreset("top", 0, true, "selected");
                topCloud.moveCamera();
                topCloud.controls.addEventListener("change", () => {
                    this.updateBoxFaceProjection();
                });
            });
        }
    }
    drawMouseCanvas() {
        this.cleanMouseCanvas();
        if (this.mouseAction === "draw") {
            if (this.props.selector === "lasso") {
                const polygon = this.selector.polygon;
                drawPolygon(this.mouseCanvasRef.current, polygon, "#fff", 1);
            }
            else if (this.props.selector === "rect") {
                const rect = this.selector.rect;
                drawRect(this.mouseCanvasRef.current, rect, "#fff", 1);
            }
        }
        else {
            if (this.props.selector === "lasso" && this.props.label) {
                if (this.mainCloud.highlightedIndex !== null) {
                    const data = this.mainCloud.cloudData[this.mainCloud.highlightedIndex];
                    const pj = this.mainCloud.pixelProjection.get(data);
                    if (pj) {
                        drawCircle(this.mouseCanvasRef.current, [pj.pixelX, pj.pixelY], 5, 0, 360, "#FFFF00", 1);
                    }
                }
            }
        }
    }
    selectBox(id) {
        var _a, _b;
        this.unselectBox();
        const box3 = this.boxTool.getBox(id);
        box3.selected = true;
        this.mainCloud.render();
        (_b = (_a = this.image2DRef.current) === null || _a === void 0 ? void 0 : _a.renderDetections3DRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView();
    }
    unselectBox() {
        imageLabelStore.status.boxes.map(b => this.boxTool.getBox(b.id)).forEach(b => (b.selected = false));
        this.mainCloud.render();
    }
    setBoxVisible(id, visible) {
        const box3 = this.boxTool.getBox(id);
        _.find(imageLabelStore.status.boxes, { id: box3.id }).visible = visible;
        box3.visible = visible;
        this.updateBoxPositionProjection();
        this.mainCloud.render();
    }
    setBoxTitleVisible(id, visible) {
        const box3 = this.boxTool.getBox(id);
        _.find(imageLabelStore.status.boxes, { id: box3.id }).titleVisible = visible;
    }
    removeBox(id) {
        _.remove(imageLabelStore.status.boxes, { id });
        this.boxTool.removeBox(id);
        this.updateBoxPositionProjection();
        this.updateBoxVectorsProjection(id);
    }
    cleanMouseCanvas() {
        this.mouseContext.clearRect(0, 0, this.canvasOffset.width, this.canvasOffset.height);
    }
    loadPCDFile(url) {
        return new Promise(resolve => {
            this.loader.load(url, arg => {
                resolve(arg);
            });
        });
    }
    waitForResource() {
        return new Promise(resolve => {
            imageLabelStore.on("resourceLoaded", resource => {
                resolve(resource);
            });
            if (imageLabelStore.status.curResource) {
                resolve(imageLabelStore.status.curResource);
            }
        });
    }
    transformBox2Points(box3) {
        const { rotation, boundingBox: { min, max } } = box3;
        const points = [
            { x: min.x, y: max.y, z: max.z },
            { x: min.x, y: max.y, z: min.z },
            { x: max.x, y: max.y, z: max.z },
            { x: max.x, y: max.y, z: min.z },
            { x: min.x, y: min.y, z: max.z },
            { x: min.x, y: min.y, z: min.z },
            { x: max.x, y: min.y, z: max.z },
            { x: max.x, y: min.y, z: min.z }
        ];
        return {
            points: points.map(p => {
                const vec = new THREE.Vector3(p.x, p.y, p.z);
                return vec.applyMatrix4(box3.object.matrixWorld);
            })
        };
    }
    transformPoints2Face(points) {
        function pixelProjection(camera, size) {
            return points.map(p => {
                const pp = p.clone().project(camera);
                return {
                    pixelX: ((pp.x + 1) / 2) * size.width,
                    pixelY: (-(pp.y - 1) / 2) * size.height
                };
            });
        }
        const fpp = pixelProjection(this.frontCloud.camera, this.frontCloud);
        const spp = pixelProjection(this.sideCloud.camera, this.sideCloud);
        const tpp = pixelProjection(this.topCloud.camera, this.topCloud);
        return {
            front: {
                size: {
                    width: Math.abs(fpp[0].pixelX - fpp[2].pixelX),
                    height: Math.abs(fpp[1].pixelY - fpp[0].pixelY)
                },
                position: { x: fpp[2].pixelX, y: fpp[2].pixelY },
                rotation: 0,
                head: null
            },
            side: {
                size: {
                    width: Math.abs(spp[7].pixelX - spp[3].pixelX),
                    height: Math.abs(spp[6].pixelY - spp[7].pixelY)
                },
                position: { x: spp[0].pixelX, y: spp[0].pixelY },
                rotation: 0,
                head: "left"
            },
            top: {
                size: {
                    width: Math.abs(tpp[6].pixelX - tpp[4].pixelX),
                    height: Math.abs(tpp[6].pixelY - tpp[2].pixelY)
                },
                position: { x: tpp[0].pixelX, y: tpp[0].pixelY },
                rotation: 0,
                head: "top"
            }
        };
    }
    transformBox2Face(box3) {
        const { points } = this.transformBox2Points(box3);
        const face = this.transformPoints2Face(points);
        return face;
    }
    updateBoxVectorsProjection(id) {
        const project = (box) => {
            const { id, label, _uid } = box;
            const box3 = this.boxTool.box3Map.get(box.id);
            const { points } = this.transformBox2Points(box3);
            return {
                id,
                label,
                _uid,
                value: this.imgProjection(points.map(p => p.toArray()).reduce((s, a) => s.concat([a]), []), this.props.params.obstacle_D, this.props.params.obstacle_K, this.props.params.calib_obstacle_to_velodyne128)
            };
        };
        if (id) {
            const box = _.find(imageLabelStore.status.boxes, { id });
            const boxVectors = _.find(imageLabelStore.status.boxVectorsProjection, { id });
            if (box) {
                const b = project(box);
                if (boxVectors) {
                    boxVectors.value = b.value;
                }
                else {
                    imageLabelStore.status.boxVectorsProjection.push(b);
                }
            }
            else {
                _.remove(imageLabelStore.status.boxVectorsProjection, { id });
            }
        }
        else {
            imageLabelStore.status.boxVectorsProjection = imageLabelStore.status.boxes.map(box => {
                return project(box);
            });
        }
    }
    updateBoxPositionProjection() {
        imageLabelStore.status.boxPositionProjection = imageLabelStore.status.boxes.map(box => {
            const box3 = this.boxTool.getBox(box.id);
            let position = { x: NaN, y: NaN };
            if (box3) {
                const pp = this.boxTool.project(box3.position, this.mainCloud.camera);
                position = {
                    x: ((pp.x + 1) / 2) * this.mainCloud.width,
                    y: (-(pp.y - 1) / 2) * this.mainCloud.height
                };
            }
            return {
                id: box3.id,
                position
            };
        });
    }
    updateBoxFaceProjection() {
        imageLabelStore.status.boxFaceProjection = imageLabelStore.status.boxes.map(box => {
            const box3 = this.boxTool.getBox(box.id);
            return {
                id: box.id,
                face: this.transformBox2Face(box3)
            };
        });
    }
    updateCloudProjection() {
        var _a;
        if (((_a = this.mainCloud) === null || _a === void 0 ? void 0 : _a.cloudData) &&
            imageLabelStore.projection &&
            this.props.params.obstacle_D &&
            this.props.params.obstacle_K &&
            this.props.params.calib_obstacle_to_velodyne128) {
            this.projectionPoints = this.imgProjection(this.filteredCloudData.map(v => [v.x, v.y, v.z]), this.props.params.obstacle_D, this.props.params.obstacle_K, this.props.params.calib_obstacle_to_velodyne128);
        }
        else {
            this.projectionPoints = [];
        }
    }
    onBoxResizeStart(e, face, selectedBoxId) {
        this._projectionBox = _.cloneDeep(_.find(imageLabelStore.status.boxFaceProjection, { id: selectedBoxId }));
    }
    onBoxResize(e, face, selectedBoxId) {
        const projectionBox = _.find(imageLabelStore.status.boxFaceProjection, { id: selectedBoxId });
        const f = projectionBox.face[face];
        const _f = this._projectionBox.face[face];
        if (e.type === "mouse") {
            const deltaX = e.currentX - e.startX;
            const deltaY = e.currentY - e.startY;
            switch (e.dir) {
                case "n":
                    f.position.y = _f.position.y + deltaY;
                    f.size.height = _f.size.height - deltaY;
                    break;
                case "s":
                    f.size.height = _f.size.height + deltaY;
                    break;
                case "w":
                    f.position.x = _f.position.x + deltaX;
                    f.size.width = _f.size.width - deltaX;
                    break;
                case "e":
                    f.size.width = _f.size.width + deltaX;
                    break;
                case "nw":
                    f.position.y = _f.position.y + deltaY;
                    f.position.x = _f.position.x + deltaX;
                    f.size.height = _f.size.height - deltaY;
                    f.size.width = _f.size.width - deltaX;
                    break;
                case "ne":
                    f.position.y = _f.position.y + deltaY;
                    f.size.height = _f.size.height - deltaY;
                    f.size.width = _f.size.width + deltaX;
                    break;
                case "sw":
                    f.size.height = _f.size.height + deltaY;
                    f.size.width = _f.size.width - deltaX;
                    f.position.x = _f.position.x + deltaX;
                    break;
                case "se":
                    f.size.height = _f.size.height + deltaY;
                    f.size.width = _f.size.width + deltaX;
                    break;
            }
        }
    }
    onBoxResizeEnd(e, face, selectedBoxId) {
        const box3 = this.boxTool.getBox(selectedBoxId);
        if (e.type === "mouse") {
            let cloud;
            switch (face) {
                case "front":
                    cloud = this.frontCloud;
                    break;
                case "side":
                    cloud = this.sideCloud;
                    break;
                case "top":
                    cloud = this.topCloud;
                    break;
            }
            const camera = cloud.camera;
            const bounding = cloud.canvas.getBoundingClientRect();
            const startX = e.startX - bounding.left;
            const startY = e.startY - bounding.top;
            const currentX = e.currentX - bounding.left;
            const currentY = e.currentY - bounding.top;
            const mouseDirections = {
                x: currentX - startX > 0 ? 1 : -1,
                y: currentY - startY > 0 ? 1 : -1
            };
            const ss = {
                x: (startX / bounding.width) * 2 - 1,
                y: -(startY / bounding.height) * 2 + 1,
                z: (camera.near + camera.far) / (camera.near - camera.far)
            };
            const sc = {
                x: (currentX / bounding.width) * 2 - 1,
                y: -(currentY / bounding.height) * 2 + 1,
                z: (camera.near + camera.far) / (camera.near - camera.far)
            };
            const startVector3 = new THREE.Vector3(ss.x, ss.y, ss.z).unproject(cloud.camera);
            const currentVector3 = new THREE.Vector3(sc.x, sc.y, sc.z).unproject(cloud.camera);
            const deltaX = currentVector3.x - startVector3.x;
            const deltaY = currentVector3.y - startVector3.y;
            const deltaZ = currentVector3.z - startVector3.z;
            const diffX = Math.abs(deltaX);
            const diffY = Math.abs(deltaY);
            const diffZ = Math.abs(deltaZ);
            const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
            const axisDirections = {
                x: deltaX > 0 ? 1 : -1,
                y: deltaY > 0 ? 1 : -1,
                z: deltaZ > 0 ? 1 : -1
            };
            if (face === "front") {
                switch (e.dir) {
                    case "n":
                        this.boxTool.resizeBox(box3, { z: deltaZ });
                        break;
                    case "s":
                        this.boxTool.resizeBox(box3, { z: -deltaZ }, -1);
                        break;
                    case "w":
                        this.boxTool.resizeBox(box3, { x: distance * mouseDirections.x * -1 }, mouseDirections.x * axisDirections.x * -1);
                        break;
                    case "e":
                        this.boxTool.resizeBox(box3, { x: distance * mouseDirections.x }, mouseDirections.x * axisDirections.x);
                        break;
                    case "nw":
                        this.boxTool.resizeBox(box3, { z: deltaZ });
                        this.boxTool.resizeBox(box3, { x: distance * mouseDirections.x * -1 }, mouseDirections.x * axisDirections.x * -1);
                        break;
                    case "ne":
                        this.boxTool.resizeBox(box3, { z: deltaZ });
                        this.boxTool.resizeBox(box3, { x: distance * mouseDirections.x }, mouseDirections.x * axisDirections.x);
                        break;
                    case "sw":
                        this.boxTool.resizeBox(box3, { z: -deltaZ }, -1);
                        this.boxTool.resizeBox(box3, { x: distance * mouseDirections.x * -1 }, mouseDirections.x * axisDirections.x * -1);
                        break;
                    case "se":
                        this.boxTool.resizeBox(box3, { z: -deltaZ }, -1);
                        this.boxTool.resizeBox(box3, { x: distance * mouseDirections.x }, mouseDirections.x * axisDirections.x);
                        break;
                }
            }
            else if (face === "side") {
                switch (e.dir) {
                    case "n":
                        this.boxTool.resizeBox(box3, { z: deltaZ });
                        break;
                    case "s":
                        this.boxTool.resizeBox(box3, { z: -deltaZ }, -1);
                        break;
                    case "w":
                        this.boxTool.resizeBox(box3, { y: distance * mouseDirections.x * -1 }, mouseDirections.x * axisDirections.y * -1);
                        break;
                    case "e":
                        this.boxTool.resizeBox(box3, { y: distance * mouseDirections.x }, mouseDirections.x * axisDirections.y);
                        break;
                    case "nw":
                        this.boxTool.resizeBox(box3, { z: deltaZ });
                        this.boxTool.resizeBox(box3, { y: distance * mouseDirections.x * -1 }, mouseDirections.x * axisDirections.y * -1);
                        break;
                    case "ne":
                        this.boxTool.resizeBox(box3, { z: deltaZ });
                        this.boxTool.resizeBox(box3, { y: distance * mouseDirections.x }, mouseDirections.x * axisDirections.y);
                        break;
                    case "sw":
                        this.boxTool.resizeBox(box3, { z: -deltaZ }, -1);
                        this.boxTool.resizeBox(box3, { y: distance * mouseDirections.x * -1 }, mouseDirections.x * axisDirections.y * -1);
                        break;
                    case "se":
                        this.boxTool.resizeBox(box3, { z: -deltaZ }, -1);
                        this.boxTool.resizeBox(box3, { y: distance * mouseDirections.x }, mouseDirections.x * axisDirections.y);
                        break;
                }
            }
            else if (face === "top") {
                const start = this._unproject({ x: startX, y: startY }, face);
                const currentOnX = this._unproject({ x: startX, y: currentY }, face);
                const currentOnY = this._unproject({ x: currentX, y: startY }, face);
                const start1 = start.setZ(0);
                const currentOnX1 = currentOnX.setZ(0);
                const currentOnY1 = currentOnY.setZ(0);
                const distanceY = new THREE.Line3(start1, currentOnX1).distance();
                const distanceX = new THREE.Line3(start1, currentOnY1).distance();
                const axisDirections1 = {
                    x: currentOnY1.x - start1.x > 0 ? 1 : -1,
                    y: currentOnX1.y - start1.y > 0 ? 1 : -1
                };
                switch (e.dir) {
                    case "n":
                        this.boxTool.resizeBox(box3, { y: distanceY * mouseDirections.y * -1 }, mouseDirections.y * axisDirections1.y * -1);
                        break;
                    case "s":
                        this.boxTool.resizeBox(box3, { y: distanceY * mouseDirections.y }, mouseDirections.y * axisDirections1.y);
                        break;
                    case "w":
                        this.boxTool.resizeBox(box3, { x: distanceX * mouseDirections.x * -1 }, mouseDirections.x * axisDirections1.x * -1);
                        break;
                    case "e":
                        this.boxTool.resizeBox(box3, { x: distanceX * mouseDirections.x }, mouseDirections.x * axisDirections1.x);
                        break;
                    case "nw":
                        this.boxTool.resizeBox(box3, { y: diffY * mouseDirections.y * -1 }, mouseDirections.y * axisDirections1.y * -1);
                        this.boxTool.resizeBox(box3, { x: diffX * mouseDirections.x * -1 }, mouseDirections.x * axisDirections1.x * -1);
                        break;
                    case "ne":
                        this.boxTool.resizeBox(box3, { y: diffY * mouseDirections.y * -1 }, mouseDirections.y * axisDirections1.y * -1);
                        this.boxTool.resizeBox(box3, { x: diffX * mouseDirections.x }, mouseDirections.x * axisDirections1.x);
                        break;
                    case "sw":
                        this.boxTool.resizeBox(box3, { y: diffY * mouseDirections.y }, mouseDirections.y * axisDirections1.y);
                        this.boxTool.resizeBox(box3, { x: diffX * mouseDirections.x * -1 }, mouseDirections.x * axisDirections1.x * -1);
                        break;
                    case "se":
                        this.boxTool.resizeBox(box3, { y: diffY * mouseDirections.y }, mouseDirections.y * axisDirections1.y);
                        this.boxTool.resizeBox(box3, { x: diffX * mouseDirections.x }, mouseDirections.x * axisDirections1.x);
                        break;
                }
            }
            this.frontCloud.moveCamera(box3);
            this.sideCloud.moveCamera(box3);
            this.topCloud.moveCamera(box3);
            this.mainCloud.render();
            this.frontCloud.render();
            this.sideCloud.render();
            this.topCloud.render();
        }
        this.updateBoxVectorsProjection(selectedBoxId);
    }
    onBoxDragStart(e, face, selectedBoxId) {
        this._projectionBox = _.cloneDeep(_.find(imageLabelStore.status.boxFaceProjection, { id: selectedBoxId }));
    }
    onBoxDrag(e, face, selectedBoxId) {
        const projectionBox = _.find(imageLabelStore.status.boxFaceProjection, { id: selectedBoxId });
        const f = projectionBox.face[face];
        const _f = this._projectionBox.face[face];
        const { startX, startY, currentX, currentY } = e;
        const deltaX = currentX - startX;
        const deltaY = currentY - startY;
        f.position.x = _f.position.x + deltaX;
        f.position.y = _f.position.y + deltaY;
    }
    onBoxDragEnd(e, face, selectedBoxId) {
        const box3 = this.boxTool.getBox(selectedBoxId);
        const startVector3 = this._unproject({ x: e.startX, y: e.startY }, face);
        const currentVector3 = this._unproject({ x: e.currentX, y: e.currentY }, face);
        const deltaX = currentVector3.x - startVector3.x;
        const deltaY = currentVector3.y - startVector3.y;
        const deltaZ = currentVector3.z - startVector3.z;
        switch (face) {
            case "front":
                this.boxTool.translateBox(box3, { x: deltaX, y: deltaY, z: deltaZ });
                break;
            case "side":
                this.boxTool.translateBox(box3, { x: deltaX, y: deltaY, z: deltaZ });
                break;
            case "top":
                this.boxTool.translateBox(box3, { x: deltaX, y: deltaY });
                break;
        }
        this.updateBoxVectorsProjection(selectedBoxId);
        this.frontCloud.moveCamera(box3);
        this.sideCloud.moveCamera(box3);
        this.topCloud.moveCamera(box3);
        this.mainCloud.render();
    }
    onBoxRotateStart(e, face, selectedBoxId) {
        this._projectionBox = _.cloneDeep(_.find(imageLabelStore.status.boxFaceProjection, { id: selectedBoxId }));
    }
    onBoxRotate(e, face, selectedBoxId) {
        const f = _.find(imageLabelStore.status.boxFaceProjection, { id: selectedBoxId }).face[face];
        f.rotation = e.rotation;
    }
    onBoxRotateEnd(e, face, selectedBoxId) {
        const box3 = this.boxTool.getBox(selectedBoxId);
        this.boxTool.rotateBox(box3, { z: -e.rotation });
        this.updateBoxVectorsProjection(selectedBoxId);
        this.frontCloud.moveCamera(box3);
        this.sideCloud.moveCamera(box3);
        this.topCloud.moveCamera(box3);
        this.mainCloud.render();
    }
    onToggleOrientation(selectedBoxId) {
        const box = _.find(imageLabelStore.status.boxes, { id: selectedBoxId });
        const box3 = this.boxTool.getBox(selectedBoxId);
        box3.rotateZ(Math.PI);
        box.rotation = box3.rotation;
        this.updateBoxFaceProjection();
        this.updateBoxVectorsProjection(selectedBoxId);
        this.frontCloud.moveCamera(box3);
        this.sideCloud.moveCamera(box3);
        this.topCloud.moveCamera(box3);
        this.mainCloud.render();
    }
    onSubViewSelect(view) {
        const maxZIndex = Math.max(imageLabelStore.frontViewZIndex, imageLabelStore.sideViewZIndex, imageLabelStore.topViewZIndex, imageLabelStore.image2DViewZIndex);
        if (imageLabelStore[view + "ZIndex"] <= maxZIndex) {
            imageLabelStore[view + "ZIndex"] = maxZIndex + 1;
        }
    }
    onReposition(view, offset) {
        if (offset.left < 0) {
            offset.left = 0;
        }
        if (offset.left + offset.width > this.canvasOffset.width) {
            offset.left = this.canvasOffset.width - offset.width;
        }
        if (offset.top < 0) {
            offset.top = 0;
        }
        if (offset.top + 22 > this.canvasOffset.height) {
            offset.top = this.canvasOffset.height - 22;
        }
        imageLabelStore[view + "Offset"] = offset;
        this.saveSubViewOffset(view, offset);
    }
    render() {
        var _a;
        const { cursor, url, segments, selectedBoxId, boxFaceProjection, boxes, boxVectorsProjection } = this.props;
        const { contextMenu } = this.state;
        const { frontViewOffset, sideViewOffset, topViewOffset } = imageLabelStore;
        const width = this.canvasOffset.width;
        const height = this.canvasOffset.height;
        const selectedBox = _.find(boxes, { id: selectedBoxId });
        const selectedProjectionBox = _.find(boxFaceProjection, { id: selectedBoxId });
        return (<div className="editor3d">
        <div className="main-view">
          <div className="pcd-view" ref={this.containerRef} onMouseEnter={() => {
        }} onMouseLeave={() => {
        }}>
            <canvas className="source-canvas" ref={this.sourceCanvasRef} width={width} height={height}></canvas>
            <canvas className="selection-canvas" ref={this.selectionCanvasRef} width={width} height={height}></canvas>
            <canvas className="mouse-canvas" ref={this.mouseCanvasRef} width={width} height={height}></canvas>
            {(_a = imageLabelStore.status.boxPositionProjection) === null || _a === void 0 ? void 0 : _a.map(({ position, id }, idx) => {
            var _a;
            const box = (_a = imageLabelStore.status.boxes) === null || _a === void 0 ? void 0 : _a.find(v => v.id === id);
            if (box) {
                const { _uid, visible, titleVisible } = box;
                return (visible &&
                    titleVisible && (<span key={idx} onClick={() => {
                    imageLabelStore.status.selectedBoxId = id;
                }} className="box-title" style={{
                    left: isNaN(position.x) ? -100 : position.x,
                    top: isNaN(position.y) ? -100 : position.y
                }}>
                      {_uid}
                    </span>));
            }
            return null;
        })}
            {selectedBox && (<div className="box-tag">
                <p>标签: {selectedBox.label.locale || selectedBox.label.key}</p>
                <p>长度: {_.floor(selectedBox.size.length, 2)}m</p>
                <p>宽度: {_.floor(selectedBox.size.width, 2)}m</p>
                <p>高度: {_.floor(selectedBox.size.height, 2)}m</p>
                <p>
                  位置:{" "}
                  {`(${_.floor(selectedBox.position.x)}, ${_.floor(selectedBox.position.y)}, ${_.floor(selectedBox.position.z)})m`}
                </p>
              </div>)}
          </div>
          <ContextMenu left={contextMenu.left} top={contextMenu.top} menus={contextMenu.menus} visible={contextMenu.visible} onClick={this.onContextMenuClick}></ContextMenu>
        </div>
        <Image2D ref={this.image2DRef} segments={segments} detections3D={boxVectorsProjection} url={url} selectedBoxId={selectedBoxId} projectionPoints={this.projectionPoints} cloudData={this.filteredCloudData} maxWidth={this.canvasOffset.width} onSelect={() => this.onSubViewSelect("image2DView")}/>
        <SubView title={"正视"} {...frontViewOffset} canvasRef={this.frontViewCanvasRef} containerRef={this.frontViewRef} onResize={(width, height, left, top) => {
            imageLabelStore.frontViewOffset.width = width;
            imageLabelStore.frontViewOffset.height = height;
            imageLabelStore.frontViewOffset.left = left;
            imageLabelStore.frontViewOffset.top = top;
            this.frontCloud.resize(width, height);
            this.saveSubViewOffset("frontView", { width, height, left, top });
            this.updateBoxFaceProjection();
        }} onReposition={(left, top) => {
            this.onReposition("frontView", Object.assign(Object.assign({}, frontViewOffset), { left, top }));
        }} zIndex={imageLabelStore.frontViewZIndex} onSelect={() => this.onSubViewSelect("frontView")}>
          {selectedProjectionBox && selectedBox && (<React.Fragment>
              <InteractiveBox {...selectedProjectionBox.face.front} onResizeStart={e => this.onBoxResizeStart(e, "front", selectedBoxId)} onResize={e => this.onBoxResize(e, "front", selectedBoxId)} onResizeEnd={e => this.onBoxResizeEnd(e, "front", selectedBoxId)} onDragStart={e => this.onBoxDragStart(e, "front", selectedBoxId)} onDrag={e => this.onBoxDrag(e, "front", selectedBoxId)} onDragEnd={e => this.onBoxDragEnd(e, "front", selectedBoxId)}></InteractiveBox>
              
            </React.Fragment>)}
        </SubView>
        <SubView title={"俯视"} {...topViewOffset} canvasRef={this.topViewCanvasRef} containerRef={this.topViewRef} onResize={(width, height, left, top) => {
            imageLabelStore.topViewOffset.width = width;
            imageLabelStore.topViewOffset.height = height;
            imageLabelStore.topViewOffset.left = left;
            imageLabelStore.topViewOffset.top = top;
            this.topCloud.resize(width, height);
            this.saveSubViewOffset("topView", { width, height, left, top });
            this.updateBoxFaceProjection();
        }} onReposition={(left, top) => {
            this.onReposition("topView", Object.assign(Object.assign({}, topViewOffset), { left, top }));
        }} zIndex={imageLabelStore.topViewZIndex} onSelect={() => this.onSubViewSelect("topView")}>
          {selectedProjectionBox && selectedBox && (<React.Fragment>
              <InteractiveBox {...selectedProjectionBox.face.top} onResizeStart={e => this.onBoxResizeStart(e, "top", selectedBoxId)} onResize={e => this.onBoxResize(e, "top", selectedBoxId)} onResizeEnd={e => this.onBoxResizeEnd(e, "top", selectedBoxId)} onRotateStart={e => this.onBoxRotateStart(e, "top", selectedBoxId)} onRotate={e => this.onBoxRotate(e, "top", selectedBoxId)} onRotateEnd={e => this.onBoxRotateEnd(e, "top", selectedBoxId)} onDragStart={e => this.onBoxDragStart(e, "top", selectedBoxId)} onDrag={e => this.onBoxDrag(e, "top", selectedBoxId)} onDragEnd={e => this.onBoxDragEnd(e, "top", selectedBoxId)} enableRotate={true}></InteractiveBox>
              
              <div className="btn-toggle-orientation" onMouseDown={e => {
            this.onToggleOrientation(selectedBoxId);
        }}>
                <svg viewBox="0 0 1024 1024" version="1.1" width="20" height="20">
                  <path d="M635.733333 217.6L810.666667 392.533333l-51.2 51.2-123.733334-123.733333V896H554.666667V128l81.066666 89.6zM388.266667 810.666667L213.333333 635.733333l51.2-51.2 123.733334 123.733334V128H469.333333v768l-81.066666-85.333333z" fill="#fff"></path>
                </svg>
              </div>
            </React.Fragment>)}
        </SubView>
        <SubView title="侧视" {...sideViewOffset} canvasRef={this.sideViewCanvasRef} containerRef={this.sideViewRef} onResize={(width, height, left, top) => {
            imageLabelStore.sideViewOffset.width = width;
            imageLabelStore.sideViewOffset.height = height;
            imageLabelStore.sideViewOffset.left = left;
            imageLabelStore.sideViewOffset.top = top;
            this.sideCloud.resize(width, height);
            this.saveSubViewOffset("sideView", { width, height, left, top });
            this.updateBoxFaceProjection();
        }} onReposition={(left, top) => {
            this.onReposition("sideView", Object.assign(Object.assign({}, sideViewOffset), { left, top }));
        }} zIndex={imageLabelStore.sideViewZIndex} onSelect={() => this.onSubViewSelect("sideView")}>
          {selectedProjectionBox && selectedBox && (<React.Fragment>
              <InteractiveBox {...selectedProjectionBox.face.side} onResizeStart={e => this.onBoxResizeStart(e, "side", selectedBoxId)} onResize={e => this.onBoxResize(e, "side", selectedBoxId)} onResizeEnd={e => this.onBoxResizeEnd(e, "side", selectedBoxId)} onDragStart={e => this.onBoxDragStart(e, "side", selectedBoxId)} onDrag={e => this.onBoxDrag(e, "side", selectedBoxId)} onDragEnd={e => this.onBoxDragEnd(e, "side", selectedBoxId)}></InteractiveBox>
              
            </React.Fragment>)}
        </SubView>
      </div>);
    }
    changePointSize(size) {
        this.clouds.forEach(cloud => {
            cloud.cloudObject.material.size = size;
            cloud.render();
        });
    }
    changeShaderMode(mode) {
        this.clouds.forEach(cloud => {
            cloud.shaderMode = mode;
            cloud.renderPCDFile(cloud.cloudObject.geometry.getAttribute("position").array);
        });
    }
    changeViewType(view) {
        if (view === "3d") {
            this.mainCloud.cameraMode = "perspective";
            this.mainCloud.cameraPreset("front", 0.5);
            this.mainCloud.initCamera();
            this.mainCloud.moveCamera();
        }
        else if (view === "top") {
            this.mainCloud.cameraMode = "orthographic";
            this.mainCloud.cameraPreset("top", 0);
            this.mainCloud.initCamera();
            this.mainCloud.moveCamera();
            this.mainCloud.controls.minPolarAngle = 0;
            this.mainCloud.controls.maxPolarAngle = 0;
        }
        setTimeout(() => {
            this.updateBoxPositionProjection();
        }, 100);
    }
    onSelect(selectedIndices, labelIndex) {
        if (this.disabled) {
            return message.warn("请点击确认后继续操作");
        }
        const label = this.label;
        const unlabeledIndices = selectedIndices.filter(index => {
            return label[index] === -1;
        });
        const matchCurrentLabelIndices = selectedIndices.filter(index => {
            return label[index] === labelIndex;
        });
        const color = this.props.segments3DLabels[labelIndex].color;
        if (this.props.mode === "add") {
            unlabeledIndices.forEach(idx => {
                label[idx] = labelIndex;
                this.setColor(idx, color);
            });
        }
        else if (this.props.mode === "remove") {
            matchCurrentLabelIndices.forEach(idx => {
                label[idx] = -1;
                this.removeColor(idx);
            });
        }
        else if (this.props.mode === "toggle") {
            unlabeledIndices.forEach(idx => {
                label[idx] = labelIndex;
                this.setColor(idx, color);
            });
            matchCurrentLabelIndices.forEach(idx => {
                label[idx] = -1;
                this.removeColor(idx);
            });
        }
        this.props.onChange({ label });
        const center = this.mainCloud.getCenter(selectedIndices);
        this.clouds.forEach(cloud => {
            cloud.cloudObject.geometry.attributes.color.needsUpdate = true;
            cloud.selectedIndices = selectedIndices;
            cloud.updateSphere();
        });
        this.mainCloud.render();
        this.frontCloud.moveCamera();
        this.sideCloud.moveCamera();
        this.topCloud.moveCamera();
    }
    changeLabel(labels) {
        const label = this.label;
        this.clouds.forEach(cloud => {
            cloud.cloudObject.geometry.attributes.color.needsUpdate = false;
        });
        for (let i = 0; i < label.length; i++) {
            label[i] = -1;
            this.removeColor(i);
        }
        labels.forEach(v => {
            label[v.index] = v.label;
            this.setColor(v.index, this.props.segments3DLabels[v.label].color);
        });
        this.clouds.forEach(cloud => {
            cloud.cloudObject.geometry.attributes.color.needsUpdate = true;
            cloud.render();
        });
        this.props.onChange({ label });
    }
    hidePoint(idx) {
        this.clouds.slice(0, 1).forEach(cloud => {
            cloud.hidePoint(idx);
        });
    }
    showPoint(idx) {
        this.clouds.slice(0, 1).forEach(cloud => {
            cloud.showPoint(idx);
        });
    }
    setColor(idx, color) {
        this.clouds.forEach(cloud => cloud.setColor(idx, color));
    }
    removeColor(idx) {
        this.clouds.forEach(cloud => cloud.removeColor(idx));
    }
    imgProjection(vector, d, k, rt01) {
        function _add(m, a) {
            return m.map((val, idx) => {
                return val.map(v => (v += a[idx]));
            });
        }
        function _divide(a, b) {
            return a.map(val => {
                return val.map((v, i) => v / b[i]);
            });
        }
        function RT_transform(_corners, _R, _loc) {
            const t = math.transpose(_corners);
            return math.transpose(_add(math.multiply(_R, t), _loc));
        }
        function proj_cam2img(pt, cam_to_img) {
            const t = math.transpose(pt);
            const point = math.multiply(cam_to_img, t);
            const a = _divide(point.slice(0, 2), point[2]);
            return math.transpose(a);
        }
        const [r00, r10, r20, _v0, r01, r11, r21, _v1, r02, r12, r22, _v2, tx, ty, tz] = rt01;
        const R = [
            [r00, r01, r02],
            [r10, r11, r12],
            [r20, r21, r22]
        ];
        const T = [tx, ty, tz];
        const K = math.reshape(k, [3, 3]);
        const pc = RT_transform(vector, R, T);
        const pix = proj_cam2img(pc, K);
        return pix;
    }
    rotateGeometry(rx, ry, rz) {
        this.clouds.forEach(cloud => {
            cloud.rotateGeometry(rx, ry, rz);
            cloud.render();
        });
    }
    addEvents() {
        window.addEventListener("keydown", this.onKeyDown);
    }
    removeEvents() {
        window.removeEventListener("keydown", this.onKeyDown);
    }
    _unproject(pixel, face) {
        let cloud;
        switch (face) {
            case "front":
                cloud = this.frontCloud;
                break;
            case "side":
                cloud = this.sideCloud;
                break;
            case "top":
                cloud = this.topCloud;
                break;
        }
        const camera = cloud.camera;
        const bounding = cloud.canvas.getBoundingClientRect();
        const startX = pixel.x;
        const startY = pixel.y;
        const ss = {
            x: (startX / bounding.width) * 2 - 1,
            y: -(startY / bounding.height) * 2 + 1,
            z: (camera.near + camera.far) / (camera.near - camera.far)
        };
        const vec3 = new THREE.Vector3(ss.x, ss.y, ss.z).unproject(cloud.camera);
        return vec3;
    }
    saveSubViewOffset(view, offset) {
        this.getSubViewOffset().then(viewOffset => {
            const { frontViewOffset, sideViewOffset, topViewOffset } = viewOffset || {};
            const newOffset = Object.assign({}, viewOffset);
            if (view === "frontView") {
                newOffset.frontViewOffset = Object.assign(Object.assign({}, frontViewOffset), offset);
            }
            else if (view === "sideView") {
                newOffset.sideViewOffset = Object.assign(Object.assign({}, sideViewOffset), offset);
            }
            else if (view === "topView") {
                newOffset.topViewOffset = Object.assign(Object.assign({}, topViewOffset), offset);
            }
            localforage.setItem(`${imageLabelStore.status.labelTaskId}-view`, newOffset);
        });
    }
    getSubViewOffset() {
        return localforage.getItem(`${imageLabelStore.status.labelTaskId}-view`);
    }
};
Editor3D.defaultProps = {
    segments: [],
    segments3D: {},
    params: {}
};
__decorate([
    observable,
    __metadata("design:type", Object)
], Editor3D.prototype, "projectionPoints", void 0);
Editor3D = __decorate([
    observer
], Editor3D);
export default Editor3D;
function drawPolygon(canvas, points, color, thickness = 1) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i][0], points[i][1]);
    }
    ctx.stroke();
    ctx.restore();
}
function drawCircle(canvas, point, radius, startAngleDeg, endAngleDeg, color, thickness = 1) {
    const ctx = canvas.getContext("2d");
    const startAngleRad = (startAngleDeg * Math.PI) / 180;
    const endAngleRad = (endAngleDeg * Math.PI) / 180;
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.beginPath();
    ctx.arc(point[0], point[1], radius, startAngleRad, endAngleRad);
    ctx.stroke();
    ctx.restore();
}
function drawRect(canvas, rect, color, thickness = 1, dash = 0) {
    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.setLineDash([dash, dash]);
    ctx.beginPath();
    ctx.rect(rect.x, rect.y, rect.width, rect.height);
    ctx.stroke();
    ctx.restore();
}
