var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import { Button, Card, Form, Input, PageHeader, Radio, Upload, message } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { routerStore } from "../../../../store/routerStore";
import { IResource } from "../../../../definition/entity/resource";
import { LabelEditor } from "../../component/LabelEditor/LabelEditor";
import { taskTemplateCreateStore } from "./TaskTemplateCreateStore";
import "./TaskTemplateCreate.less";
import { ILabelTask } from "definition/entity/label-task";
import LabelConfigureFormItem from "page/manage/component/TaskForm/LabelConfigureFormItem";
let TaskTemplateCreate = class TaskTemplateCreate extends React.Component {
    constructor() {
        super(...arguments);
        this.editorRef = null;
    }
    componentWillUnmount() {
        taskTemplateCreateStore.resetStatus();
    }
    render() {
        const status = taskTemplateCreateStore.status;
        return (<PageHeader title="创建任务模板" style={{ background: "#FFF" }} onBack={() => {
            routerStore.push("/manage/task_templates");
        }}>
        <Card>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label={<span className="label-required">模板名称</span>}>
              <Input value={status.name} onChange={event => {
            status.name = event.target.value;
        }}/>
            </Form.Item>
            <Form.Item label="备注">
              <Input value={status.remark} onChange={event => {
            status.remark = event.target.value;
        }}/>
            </Form.Item>
            
            <Form.Item label="标注文档">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Upload accept=".pdf" showUploadList={false} beforeUpload={file => {
            status.fileName = file.name;
            status.file = file;
            return false;
        }}>
                  <Button>
                    <UploadOutlined />
                    上传文件
                  </Button>
                </Upload>
                {status.fileName && (<div style={{ marginLeft: "8px" }}>
                    {status.fileName}&nbsp;
                    <DeleteOutlined onClick={() => {
            status.fileName = null;
            status.file = null;
        }}/>
                  </div>)}
              </div>
            </Form.Item>
            <Form.Item label={<span className="label-required">数据形式</span>}>
              <Radio.Group value={status.resourceMime} onChange={event => {
            status.resourceMime = event.target.value;
        }}>
                <Radio value={IResource.Mime.Image}>图片</Radio>
                <Radio value={IResource.Mime.Video}>视频</Radio>
                <Radio value={IResource.Mime.Cloud}>点云</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={<span className="label-required">任务类型</span>}>
              <Radio.Group value={status.labelTaskType} onChange={e => {
            status.labelTaskType = e.target.value;
        }}>
                {status.resourceMime === IResource.Mime.Image && (<React.Fragment>
                    <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                  </React.Fragment>)}
                {status.resourceMime === IResource.Mime.Video && (<React.Fragment>
                    <Radio value={ILabelTask.LabelTaskType.Classification}>分类</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Anchor}>时空锚</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Clipping}>切割</Radio>
                  </React.Fragment>)}
                {status.resourceMime === IResource.Mime.Cloud && (<React.Fragment>
                    <Radio value={ILabelTask.LabelTaskType.Detection}>检测</Radio>
                    <Radio value={ILabelTask.LabelTaskType.Segmentation}>分割</Radio>
                  </React.Fragment>)}
              </Radio.Group>
            </Form.Item>
            <Form.Item label={<span className="label-required">标签配置</span>}>
              {status.taskType === ILabelTask.TaskType.Label && (<LabelEditor ref={ref => (this.editorRef = ref)} resourceMime={status.resourceMime} labelTaskType={status.labelTaskType}/>)}
              {status.taskType === ILabelTask.TaskType.Segment && (<LabelConfigureFormItem onChange={value => {
            status.label = value;
        }} type="segment"/>)}
            </Form.Item>
            <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 20 }}>
              <div style={{ float: "right" }}>
                <Button onClick={() => {
            routerStore.goBack();
        }}>
                  取消
                </Button>
                <Button style={{ marginLeft: "10px" }} loading={status.isLoading} onClick={() => {
            var _a, _b, _c, _d, _e, _f;
            if (this.editorRef) {
                status.label = this.editorRef.getStatus();
            }
            if (!status.name) {
                return message.error("请输入模版名称");
            }
            if (status.labelTaskType == null) {
                return message.error("请选择任务类型");
            }
            if (!((_a = status.label.detections) === null || _a === void 0 ? void 0 : _a.length) &&
                !((_b = status.label.classifications) === null || _b === void 0 ? void 0 : _b.length) &&
                !((_c = status.label.anchors) === null || _c === void 0 ? void 0 : _c.length) &&
                !((_d = status.label.segments) === null || _d === void 0 ? void 0 : _d.length) &&
                !((_e = status.label.segments3D) === null || _e === void 0 ? void 0 : _e.length) &&
                !((_f = status.label.detections3D) === null || _f === void 0 ? void 0 : _f.length)) {
                message.warning("标签配置不能为空");
                return;
            }
            taskTemplateCreateStore.createTaskTemplate(status.label);
        }}>
                  创建
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </PageHeader>);
    }
};
TaskTemplateCreate = __decorate([
    observer
], TaskTemplateCreate);
export { TaskTemplateCreate };
