var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import BasicStore from "store/BasicStore";
import { action, observable } from "mobx";
import { Api } from "api/Api";
import { LabelTaskBusiness } from "definition/entity/label-task-business";
import { message } from "antd";
class Status {
    constructor() {
        this.isLoading = true;
        this.showCreateModal = false;
        this.showModifyModal = false;
        this.modifyTaskBusiness = null;
        this.taskBusinesses = [];
        this.name = undefined;
        this.type = undefined;
        this.standardNum = [0];
        this.nameFilter = "";
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "showCreateModal", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "showModifyModal", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof LabelTaskBusiness !== "undefined" && LabelTaskBusiness.AsObject) === "function" ? _a : Object)
], Status.prototype, "modifyTaskBusiness", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "taskBusinesses", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof LabelTaskBusiness !== "undefined" && LabelTaskBusiness.Type) === "function" ? _b : Object)
], Status.prototype, "type", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "standardNum", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "nameFilter", void 0);
class TaskBusinessesStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async loadData() {
        this.status.isLoading = true;
        try {
            const taskBusinessesRes = await Api.taskBusiness.listTaskBusinesses({
                name: this.status.nameFilter
            });
            this.status.taskBusinesses = taskBusinessesRes.data;
        }
        catch (e) {
            message.error(e.message);
        }
        finally {
            this.status.isLoading = false;
        }
    }
    async createTaskBusiness() {
        const { name, type, standardNum } = this.status;
        const createRes = await Api.taskBusiness.createTaskBusiness({
            name,
            type,
            standardNum
        });
        if (createRes.code === 200) {
            await this.loadData();
            this.status.name = undefined;
            this.status.type = undefined;
            this.status.standardNum = [0];
            this.status.showCreateModal = false;
        }
    }
    async modifyTaskBusiness(params) {
        const modifyRes = await Api.taskBusiness.updateTaskBusiness({
            taskBusinessId: this.status.modifyTaskBusiness.id,
            name: params.name,
            type: params.type,
            standardNum: params.standardNum,
            isValid: params.isValid
        });
        if (modifyRes.code === 200) {
            await this.loadData();
            this.status.modifyTaskBusiness = null;
            this.status.showModifyModal = false;
        }
    }
    async deleteTaskBusiness(taskBusinessId) {
        const deleteRes = await Api.taskBusiness.deleteTaskBusiness(taskBusinessId);
        if (deleteRes.code === 200) {
            await this.loadData();
        }
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], TaskBusinessesStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TaskBusinessesStore.prototype, "resetStatus", null);
export const taskBusinessesStore = new TaskBusinessesStore();
