import React, { useRef, useState, createContext } from "react";
import Sash, { Orientation } from "./Sash";
import "./SplitView.less";
import classNames from "classnames";
export const SplitViewContext = createContext(null);
const SplitView = props => {
    const [sizes, setSizes] = useState(props._views.map(v => v.initialSize));
    const saved = useRef(null);
    const onStart = (e) => {
        saved.current = [...sizes];
    };
    const onChange = (e, idx) => {
        if (e.type === "mouse") {
            const deltaX = e.currentX - e.startX;
            const deltaY = e.currentY - e.startY;
            const delta = props.orientation === Orientation.VERTICAL ? deltaX : deltaY;
            const maxSize = props._views[idx].maxSize || sizes[idx] + sizes[idx + 1];
            const minSize = props._views[idx].minSize || 0;
            const diff = delta > 0 ? Math.min(delta, maxSize - sizes[idx]) : Math.max(delta, minSize - sizes[0]);
            sizes[idx] = saved.current[idx] + diff;
            sizes[idx + 1] = saved.current[idx + 1] - diff;
            setSizes([...sizes]);
            props.onChange();
        }
    };
    const getPosition = (idx) => {
        let position = 0;
        for (let i = 0; i <= idx; i++) {
            position += sizes[i];
        }
        return position - 2;
    };
    const childs = React.Children.toArray(props.children);
    return (<div className="split-view">
      <div className="sash-container">
        {props._views.slice(0, -1).map((el, idx) => (<Sash key={idx} position={getPosition(idx)} onStart={e => onStart(e)} onChange={e => onChange(e, idx)} orientation={props.orientation}/>))}
      </div>
      <div className={classNames([
        "view-container",
        props.orientation === Orientation.VERTICAL ? "vertical" : "horizontal"
    ])}>
        {props._views.map((el, idx) => (<ViewItem orientation={props.orientation} size={sizes[idx]} key={idx}>
            <SplitViewContext.Provider value={{ size: sizes[idx] }}>{childs[idx]}</SplitViewContext.Provider>
          </ViewItem>))}
      </div>
    </div>);
};
const ViewItem = props => {
    const computedStyle = props.orientation === Orientation.VERTICAL
        ? {
            width: props.size,
            height: "100%"
        }
        : {
            height: props.size,
            width: "100%"
        };
    return (<div className="view" style={computedStyle}>
      {props.children}
    </div>);
};
export default SplitView;
