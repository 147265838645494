import classNames from "classnames";
import Color from "color";
import React, { useRef } from "react";
import Sash, { Orientation } from "../../../../component/Sash";
const SliderBox = props => {
    const savedDragEvent = useRef({
        target: null,
        startX: 0,
        startY: 0,
        currentX: 0,
        currentY: 0
    });
    const savedDragValue = useRef();
    const sashRefs = [useRef(null), useRef(null)];
    const { color, visible, bounding = { left: 0, right: 0 }, onContextMenu = () => { }, onDragStart = () => { }, onDragChange = () => { }, onDragEnd = () => { }, onResizeStart = () => { }, onResizeChange = () => { }, selected = false, onSelect = () => { } } = props;
    const computedStyle = {
        left: bounding.left,
        width: bounding.right - bounding.left,
        display: visible ? undefined : "none"
    };
    const computedClassName = classNames({ "slider-box": true, selected });
    const onMouseDown = (e) => {
        var _a;
        savedDragEvent.current.target = e.target;
        savedDragEvent.current.startX = e.pageX;
        savedDragEvent.current.startY = e.pageY;
        onSelect();
        if (!isFromSash(e.target)) {
            savedDragValue.current = onDragStart(savedDragEvent.current);
            addDragEvents();
        }
        (_a = document.getElementById(props.currentId)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
    };
    function addDragEvents() {
        window.addEventListener("mousemove", onWindowMouseMove);
        window.addEventListener("mouseup", onWindowMouseUp);
    }
    function removeDragEvents() {
        window.removeEventListener("mousemove", onWindowMouseMove);
        window.removeEventListener("mouseup", onWindowMouseUp);
    }
    function onWindowMouseMove(e) {
        savedDragEvent.current.currentX = e.pageX;
        savedDragEvent.current.currentY = e.pageY;
        onDragChange(savedDragEvent.current, savedDragValue.current);
    }
    function onWindowMouseUp(e) {
        savedDragEvent.current.currentX = e.pageX;
        savedDragEvent.current.currentY = e.pageY;
        onDragEnd(savedDragEvent.current);
        removeDragEvents();
    }
    function isFromSash(target) {
        return sashRefs.map(v => v.current).some(el => el.contains(target));
    }
    return (<div onContextMenu={onContextMenu} onMouseDown={onMouseDown} className={computedClassName} style={computedStyle}>
      <div className="body" style={Object.assign({ backgroundColor: Color(color).fade(0.5) }, (selected ? { borderColor: Color(color).darken(0.5) } : null))}></div>
      <Sash key="left" ref={sashRefs[0]} flag="left" color={color} orientation={Orientation.VERTICAL} position={-2} useKeyboard={true} onStart={e => {
        return onResizeStart(Object.assign(Object.assign({}, e), { sash: "left" }));
    }} onChange={(e, startOffset) => {
        onResizeChange(Object.assign(Object.assign({}, e), { sash: "left" }), startOffset);
    }} style={{ bottom: 0, height: "calc(100% + 12px)" }}/>
      <Sash key="right" ref={sashRefs[1]} flag="right" color={color} orientation={Orientation.VERTICAL} position={bounding.right - bounding.left - 2} useKeyboard={true} onStart={e => {
        return onResizeStart(Object.assign(Object.assign({}, e), { sash: "right" }));
    }} onChange={(e, startOffset) => {
        onResizeChange(Object.assign(Object.assign({}, e), { sash: "right" }), startOffset);
    }} style={{ bottom: 0, height: "calc(100% + 12px)" }}/>
    </div>);
};
export default SliderBox;
