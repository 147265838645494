export default class RectSelector {
    constructor(editor, canvas) {
        this.editor = editor;
        this.canvas = canvas;
        this.rect = { x: 0, y: 0, width: 0, height: 0 };
    }
    onDrawStart(e) {
        this.clean();
        this.rect.x = e.start.x;
        this.rect.y = e.start.y;
    }
    onDraw(e) {
        const deltaX = e.current.x - e.start.x;
        const deltaY = e.current.y - e.start.y;
        this.rect.width = deltaX;
        this.rect.height = deltaY;
    }
    onDrawEnd() {
        const { x, y, width, height } = this.rect;
        if (width < 0) {
            this.rect.x = x + width;
            this.rect.width = -this.rect.width;
        }
        if (height < 0) {
            this.rect.y = y + height;
            this.rect.height = -this.rect.height;
        }
        const editor = this.editor;
        editor.drawMouseCanvas();
        const inside = [];
        const outside = [];
        const mainCloud = editor.mainCloud;
        mainCloud.cloudData.forEach((data, idx) => {
            if (mainCloud.frustumIndices.has(idx)) {
                const p = mainCloud.pixelProjection.get(data);
                const inPolygon = isInside(p.pixelX, p.pixelY, this.rect);
                if (inPolygon) {
                    inside.push(idx);
                }
                else {
                    outside.push(idx);
                }
            }
            else {
                outside.push(idx);
            }
        });
        editor.onSelect(inside, editor.labelIndex);
    }
    clean() {
        this.rect = { x: 0, y: 0, width: 0, height: 0 };
    }
}
function isInside(x, y, rect) {
    return x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height;
}
