var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import BasicStore from "./BasicStore";
import { action, observable } from "mobx";
import { Api } from "../api/Api";
import { routerStore } from "./routerStore";
class Status {
    constructor() {
        this.isLoading = true;
        this.loginUser = null;
    }
}
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "loginUser", void 0);
class AppStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    async init() {
        try {
            const { data } = await Api.user.getUser();
            this.status.loginUser = Object.assign(Object.assign({}, data), { isSystemAdmin: data.permissions.includes("la:system:managing"), isGroupAdmin: data.permissions.includes("la:release:using") });
            if (!data.permissions.length) {
                routerStore.replace("/no-permission");
            }
        }
        finally {
            this.status.isLoading = false;
        }
    }
    async reload() {
        await this.init();
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], AppStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppStore.prototype, "resetStatus", null);
export const appStore = new AppStore();
