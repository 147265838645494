var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { Button, Card, Divider, Dropdown, Input, PageHeader, Popconfirm, Table, Tooltip, Tag, Select } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { routerStore } from "../../../../store/routerStore";
import { observer } from "mobx-react";
import { resourceMimeDesc } from "../../../../util/enum-desc-convert";
import { FilterPrefix, ILabelTask } from "../../../../definition/entity/label-task";
import { labelTaskStore } from "./LabelTasksStore";
import { Link } from "../../../../component/Link/Link";
import { IResource } from "definition/entity/resource";
let LabelTasks = class LabelTasks extends React.Component {
    constructor() {
        super(...arguments);
        this.handleTableChange = (pagination, filters, sorter) => {
            const { status } = labelTaskStore;
            const { current, pageSize } = pagination;
            this.handleLabelTaskType((filters === null || filters === void 0 ? void 0 : filters.resourceMime) || [], (filters === null || filters === void 0 ? void 0 : filters.labelTaskType) || []);
            labelTaskStore.setStatus({
                status: filters === null || filters === void 0 ? void 0 : filters.status,
                sorter: sorter.length
                    ? sorter.map(item => ({ order: item.order, field: item.field }))
                    : Object.keys(sorter).length
                        ? [{ order: sorter.order, field: sorter.field }]
                        : undefined,
                pager: status.pager.setCurPage(current).setPerPage(pageSize)
            });
            labelTaskStore.loadData();
        };
    }
    componentDidMount() {
        labelTaskStore.loadData();
    }
    handleLabelTaskType(resourceMime, labelTaskType) {
        if ((resourceMime === null || resourceMime === void 0 ? void 0 : resourceMime.length) === 0) {
            labelTaskStore.setStatus({
                resourceMime,
                labelTaskType,
                labelTaskTypeList: [
                    { text: "分类", value: 0 },
                    { text: "检测", value: 1 },
                    { text: "分割", value: 2 },
                    { text: "时空锚", value: 3 },
                    { text: "切割", value: 4 }
                ]
            });
        }
        else {
            const options = [{ text: "检测", value: 1 }];
            if (resourceMime === null || resourceMime === void 0 ? void 0 : resourceMime.includes(0)) {
                options.push(...[
                    { text: "分类", value: 0 },
                    { text: "分割", value: 2 }
                ]);
            }
            if (resourceMime === null || resourceMime === void 0 ? void 0 : resourceMime.includes(1)) {
                options.push(...[
                    { text: "分类", value: 0 },
                    { text: "时空锚", value: 3 },
                    { text: "切割", value: 4 }
                ]);
            }
            if (resourceMime === null || resourceMime === void 0 ? void 0 : resourceMime.includes(4)) {
                options.push({ text: "分割", value: 2 });
            }
            const sortAndUniqLabelTaskTypeList = _.sortBy(_.uniqBy(options, "value"), "value");
            labelTaskStore.setStatus({
                resourceMime,
                labelTaskType: labelTaskType === null || labelTaskType === void 0 ? void 0 : labelTaskType.filter(item => sortAndUniqLabelTaskTypeList.map(item => item.value).includes(item)),
                labelTaskTypeList: sortAndUniqLabelTaskTypeList
            });
        }
    }
    render() {
        const status = labelTaskStore.status;
        return (<PageHeader title="标注任务列表" style={{ background: "#FFF" }}>
        <Card>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <Button type="primary" onClick={() => {
            routerStore.push("/manage/label_task/create");
        }}>
              新建任务
            </Button>
            <Input.Search style={{ width: "300px" }} value={status.filterField} addonBefore={<Select style={{ width: 80 }} value={status.filterPrefix} onChange={value => {
            status.filterPrefix = value;
        }}>
                  <Select.Option value={FilterPrefix.Name}>名称</Select.Option>
                  <Select.Option value={FilterPrefix.Creator}>创建人</Select.Option>
                </Select>} enterButton="搜索" onSearch={value => {
            labelTaskStore.setStatus({
                pager: status.pager.resetPage()
            });
            labelTaskStore.loadData();
        }} onChange={event => {
            labelTaskStore.setStatus({
                filterField: event.target.value
            });
        }}/>
          </div>

          <Table rowKey="id" bordered={true} scroll={{ x: "100%" }} loading={status.isLoading} style={{ marginTop: "20px" }} columns={[
            {
                title: "名称",
                width: 360,
                ellipsis: true,
                dataIndex: "name",
                render: (name, record) => {
                    return <Link to={`/manage/label_task/${record.id}/packages`}>{name}</Link>;
                }
            },
            {
                title: "资源类型",
                width: 120,
                align: "center",
                dataIndex: "resourceMime",
                filters: [
                    { text: "图片", value: 0 },
                    { text: "视频", value: 1 },
                    { text: "点云", value: 4 }
                ],
                filteredValue: status.resourceMime,
                render: resourceMime => resourceMimeDesc(resourceMime)
            },
            {
                title: "任务类型",
                width: 120,
                align: "center",
                dataIndex: "labelTaskType",
                filters: status.labelTaskTypeList,
                filteredValue: status.labelTaskType,
                render: labelTaskType => ILabelTask.LabelTaskTypeCN[labelTaskType]
            },
            {
                title: "创建人",
                width: 120,
                align: "center",
                render: (value, record) => {
                    var _a;
                    return <span>{(_a = record.user) === null || _a === void 0 ? void 0 : _a.name}</span>;
                }
            },
            {
                title: "优先级",
                width: 100,
                align: "center",
                dataIndex: "level",
                sorter: true,
                render: (value, record) => {
                    const enumLevel = {
                        1: "一级",
                        2: "二级",
                        3: "三级",
                        4: "四级"
                    };
                    return <span>{enumLevel[`${value}`]}</span>;
                }
            },
            {
                title: "任务状态",
                width: 120,
                align: "center",
                dataIndex: "status",
                filters: [
                    { text: "创建失败", value: -1 },
                    { text: "创建中", value: 0 },
                    { text: "未开始", value: 1 },
                    { text: "进行中", value: 2 },
                    { text: "已完成", value: 3 }
                ],
                filteredValue: status.status,
                render: (value, record) => {
                    const statusObj = {
                        "-1": "创建失败",
                        "0": "创建中",
                        "1": "未开始",
                        "2": "进行中",
                        "3": "已完成"
                    };
                    return `${statusObj[value]} ${record.progress}`;
                }
            },
            {
                title: "评估方案",
                align: "center",
                width: 100,
                render: (value, record) => {
                    const enumTaskType = {
                        1: "无",
                        2: "掺沙",
                        3: "专家",
                        4: "掺沙"
                    };
                    return <span>{enumTaskType[`${record.type}`]}</span>;
                }
            },
            {
                title: "评估方法",
                width: 200,
                align: "center",
                ellipsis: true,
                dataIndex: "policyId",
                render: (value, record) => {
                    var _a, _b, _c, _d, _e;
                    if (!_.isNil(record.policyId)) {
                        return (<Tooltip title={<div>
                            <div>评估方法：{(_a = record.assessmentPolicy) === null || _a === void 0 ? void 0 : _a.name}</div>
                            <div>version：{(_b = record.assessmentPolicy) === null || _b === void 0 ? void 0 : _b.version}</div>
                            <div>
                              tags：
                              {(_c = record.assessmentPolicy) === null || _c === void 0 ? void 0 : _c.tags.map((tag, index) => (<Tag color="blue" key={index}>
                                  {tag}
                                </Tag>))}
                            </div>
                            <div>commit_id：{(_d = record.assessmentPolicy) === null || _d === void 0 ? void 0 : _d.commitId}</div>
                          </div>} placement="bottom">
                        <span>{(_e = record.assessmentPolicy) === null || _e === void 0 ? void 0 : _e.name}</span>
                      </Tooltip>);
                    }
                    return "无";
                }
            },
            {
                title: "标注文档",
                width: 120,
                ellipsis: true,
                dataIndex: "taskTemplateId",
                align: "center",
                render: (value, record) => {
                    return (record.taskTemplate && (<a onClick={() => {
                        labelTaskStore.previewTaskTemplateDocument(record.taskTemplateId);
                    }}>
                        {record.taskTemplate.objectName ? record.taskTemplate.objectName : ""}
                      </a>));
                }
            },
            {
                title: "创建日期",
                width: 120,
                align: "center",
                sorter: true,
                dataIndex: "createdAt",
                render: (value, record) => {
                    return <span>{moment(value).format("YYYY-MM-DD")}</span>;
                }
            },
            {
                title: "操作",
                align: "center",
                fixed: "right",
                width: 300,
                render: (value, record) => {
                    var _a, _b;
                    const hasSegment = !!((_b = (_a = record.label) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.length);
                    return (<div>
                      <Button type="link" size="small" disabled={record.status === 0 || record.status === -1} onClick={() => {
                        routerStore.push(`/manage/label_task/${record.id}/modify`);
                    }}>
                        修改
                      </Button>
                      <Divider type="vertical"/>
                      <Button type="link" size="small" disabled={record.status === 0 || record.status === -1 || record.type !== ILabelTask.Type.Normal} onClick={() => {
                        routerStore.push(`/manage/label_task/${record.id}/clone`);
                    }}>
                        拷贝
                      </Button>
                      <Divider type="vertical"/>
                      <Dropdown disabled={record.status === 0 || record.status === -1} menu={{
                        items: [
                            {
                                key: "1",
                                onClick() {
                                    labelTaskStore.downTaskRawAnnotationFile(record.id);
                                },
                                label: "原始任务标注文件"
                            },
                            {
                                key: "2",
                                onClick() {
                                    labelTaskStore.downTaskAnnotationFile(record.id);
                                },
                                label: "任务标注文件"
                            },
                            record.resourceMime === IResource.Mime.Video && {
                                key: "3",
                                onClick() {
                                    labelTaskStore.downTaskAnnotationFile(record.id, true);
                                },
                                label: "全量任务标注文件"
                            },
                            hasSegment && {
                                key: "4",
                                onClick() {
                                    labelTaskStore.downTaskAnnotationFile(record.id, true);
                                },
                                label: "全量任务标注文件"
                            },
                            hasSegment && {
                                key: "4",
                                onClick() {
                                    labelTaskStore.downTaskAnnotationFile(record.id, true, true);
                                },
                                label: "全量任务标注文件(仅图片)"
                            },
                            record.sandPackageId && {
                                key: "5",
                                onClick() {
                                    labelTaskStore.downTaskOriginSandAnnotationFile(record.id);
                                },
                                label: "原始沙子标注文件"
                            },
                            record.type === ILabelTask.Type.Extract && {
                                key: "6",
                                onClick() {
                                    labelTaskStore.downTaskExtractAnnotationFile(record.id, record.resourceMime === IResource.Mime.Video);
                                },
                                label: "专家标注文件"
                            }
                        ]
                    }}>
                        <Button type="link" size="small" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                          下载
                        </Button>
                      </Dropdown>
                      <Divider type="vertical"/>
                      <Popconfirm disabled={record.status === 0} title="确认要删除这个标注任务么？" icon={<QuestionCircleOutlined style={{ color: "red" }}/>} onConfirm={() => {
                        labelTaskStore.deleteLabelTask(record.id);
                    }}>
                        <Button disabled={record.status === 0} type="link" size="small">
                          删除
                        </Button>
                      </Popconfirm>
                    </div>);
                }
            }
        ]} dataSource={labelTaskStore.filteredLabelTasks} pagination={{
            current: status.pager.getCurPage(),
            total: status.pager.getTotalCount(),
            showSizeChanger: false
        }} onChange={this.handleTableChange}/>
        </Card>
      </PageHeader>);
    }
};
LabelTasks = __decorate([
    observer
], LabelTasks);
export { LabelTasks };
