var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Button, Collapse, Input, Popover, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ClassificationTool, DetectionTool } from "definition/entity/label-task";
import { last } from "lodash";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { CompactPicker } from "react-color";
import { Color } from "util/color";
import { randomString } from "util/random-string";
import ClassificationFormItem from "./ClassificationFormItem";
import "./LabelConfigureFromItem.less";
let LabelConfigureFormItem = class LabelConfigureFormItem extends React.Component {
    constructor() {
        var _a, _b, _c;
        super(...arguments);
        this.data = observable({
            detections: ((_a = this.props.value) === null || _a === void 0 ? void 0 : _a.detections) || [],
            classifications: ((_b = this.props.value) === null || _b === void 0 ? void 0 : _b.classifications) || [],
            anchors: ((_c = this.props.value) === null || _c === void 0 ? void 0 : _c.anchors) || []
        });
        this.onChange = (value) => {
            if (this.props.onChange) {
                this.props.onChange(toJS(value));
            }
        };
    }
    static validate(value, cb) {
        const callback = (err) => {
            if (cb) {
                cb(err);
            }
            else {
                return new Promise((r, j) => {
                    err ? j(err) : r(null);
                });
            }
        };
        if (value) {
            const { classifications = [], detections = [], anchors = [] } = value;
            for (let i = 0; i < classifications.length; i++) {
                if (!classifications[i].key) {
                    return callback("请输入属性 key");
                }
            }
            for (let i = 0; i < detections.length; i++) {
                const detection = detections[i];
                if (!detection.key) {
                    return callback("请输入检测 key");
                }
                if (detection.classifications) {
                    for (let j = 0; j < detection.classifications.length; j++) {
                        const classification = detection.classifications[j];
                        if (!classification.key) {
                            return callback("请输入属性 key");
                        }
                    }
                }
            }
            for (let i = 0; i < anchors.length; i++) {
                if (!anchors[i].key) {
                    return callback("请输入属性 key");
                }
            }
            if (!classifications.length && !detections.length && !anchors.length) {
                return callback("请添加标签配置");
            }
            return callback();
        }
        else {
            return callback("请添加标签配置");
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== undefined) {
            if (nextProps.value === null) {
                this.data.classifications = [];
                this.data.detections = [];
            }
            else {
                Object.assign(this.data, nextProps.value);
            }
        }
    }
    addDetection(d) {
        const data = this.data;
        const { detections } = data;
        detections.push(d || {
            key: "",
            locale: "",
            color: Color.nextColor(),
            tool: DetectionTool.Chunk,
            classifications: [],
            type: "detection"
        });
        this.onChange(data);
    }
    addClassification(c, detection) {
        const data = this.data;
        const classifications = (detection === null || detection === void 0 ? void 0 : detection.classifications) || data.classifications;
        c = c || {
            type: "classification",
            key: null,
            locale: null,
            tool: ClassificationTool.Switch,
            options: [],
            defaultValue: null
        };
        if (Array.isArray(c)) {
            classifications.push(...c);
        }
        else {
            classifications.push(c);
        }
        this.onChange(data);
    }
    render() {
        const data = this.data;
        const { detections, classifications } = data;
        const { type } = this.props;
        const onChange = this.onChange;
        return (<div className="label-info">
        <div className="detection-list">
          {detections === null || detections === void 0 ? void 0 : detections.map((detection, idx) => {
            var _a;
            return (<Collapse key={idx} defaultActiveKey={[idx]} expandIconPosition="end" className="detection-item">
                <Collapse.Panel key={idx} header={<div onClick={e => e.stopPropagation()} className="detection">
                      <Popover content={<CompactPicker value={detection.color} onChange={(color) => {
                detection.color = color.hex;
                onChange(data);
            }} style={{ width: 200 }}/>}>
                        <div style={{ width: 40, height: 40, borderRadius: "50%", background: detection.color }}/>
                      </Popover>
                      <Input value={detection.key} onChange={e => {
                detection.key = e.target.value;
                onChange(data);
            }} placeholder="标注 key"/>
                      <Input value={detection.locale} onChange={e => {
                detection.locale = e.target.value;
                onChange(data);
            }} placeholder="标注说明"/>
                      <Select value={detection.tool} onChange={e => {
                detection.tool = e;
                onChange(data);
            }} placeholder="选择类型">
                        
                        <Select.Option key={DetectionTool.Chunk}>视频切割工具</Select.Option>
                      </Select>
                      <DeleteOutlined onClick={() => {
                detections.splice(idx, 1);
                onChange(data);
            }}/>
                    </div>}>
                  {(_a = detection.classifications) === null || _a === void 0 ? void 0 : _a.map((classification, i) => (<ClassificationFormItem key={i} value={toJS(classification)} detectionTool={detection.tool} onChange={value => {
                detection.classifications[i] = value;
                onChange(data);
            }} _onDelete={() => {
                detection.classifications.splice(i, 1);
                onChange(data);
            }}></ClassificationFormItem>))}
                  <Button onClick={() => {
                detection.classifications.push({
                    type: "classification",
                    key: null,
                    locale: null,
                    tool: ClassificationTool.Switch,
                    options: [],
                    defaultValue: null,
                    uniqKey: randomString()
                });
                onChange(data);
            }}>
                    添加属性
                  </Button>
                </Collapse.Panel>
              </Collapse>);
        })}
        </div>
        {classifications === null || classifications === void 0 ? void 0 : classifications.map((classification, i) => (<ClassificationFormItem key={i} value={classification} detectionTool={null} onChange={value => {
            classifications[i] = value;
            onChange(data);
        }} _onDelete={() => {
            classifications.splice(i, 1);
            onChange(data);
        }}></ClassificationFormItem>))}
        {type === "label" && (<React.Fragment>
            <Button style={{ marginRight: 10 }} onClick={() => this.addDetection()}>
              添加检测
            </Button>
            <Button onClick={() => this.addClassification()}>添加属性</Button>
          </React.Fragment>)}
        {type === "segment" && (<Button onClick={() => {
            this.addDetection();
            const d = last(this.data.detections);
            this.addClassification([
                {
                    type: "classification",
                    key: "违法车辆数",
                    locale: null,
                    tool: ClassificationTool.Number,
                    options: [],
                    defaultValue: null
                },
                {
                    type: "classification",
                    key: "点位信息",
                    locale: null,
                    tool: ClassificationTool.Text,
                    options: [],
                    defaultValue: null
                },
                {
                    type: "classification",
                    key: "摄像头日期",
                    locale: null,
                    tool: ClassificationTool.Date,
                    options: [],
                    defaultValue: null
                },
                {
                    type: "classification",
                    key: "进入时间",
                    locale: null,
                    tool: ClassificationTool.Time,
                    options: [],
                    defaultValue: null
                },
                {
                    type: "classification",
                    key: "离开时间",
                    locale: null,
                    tool: ClassificationTool.Time,
                    options: [],
                    defaultValue: null
                }
            ], d);
        }}>
            添加工具
          </Button>)}
      </div>);
    }
};
LabelConfigureFormItem = __decorate([
    observer
], LabelConfigureFormItem);
export default LabelConfigureFormItem;
