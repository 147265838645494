var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { Button, Card, Divider, Dropdown, message, PageHeader, Table, Tag } from "antd";
import { labelTaskPackagesStore } from "./LabelTaskPackagesStore";
import { routerStore } from "../../../../store/routerStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { ILabelPackage } from "../../../../definition/entity/label-package";
import { AssignModal } from "./component/AssignModal/AssignModal";
import { TaskPackageAction } from "./component/TaskPackageAction/TaskPackageAction";
import { transformLabelPackageStatus } from "../../../../util/status-transform";
import { ILabelPackageAssignment } from "../../../../definition/entity/label-package-assignment";
import { Timeline } from "./component/TimeLine/Timeline";
import { IResource } from "definition/entity/resource";
let LabelTaskPackages = class LabelTaskPackages extends React.Component {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: "名称",
                dataIndex: "name",
                render: (value, record) => {
                    return <div>{record.name}</div>;
                }
            },
            {
                title: "状态",
                align: "center",
                width: 120,
                render: (value, record) => {
                    return <div>{transformLabelPackageStatus(record)}</div>;
                }
            },
            {
                title: "人员",
                key: "assign",
                render: (value, record) => {
                    let labelUser = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Label).pop();
                    let reviewUser = record.assignments.filter(item => item.type === ILabelPackageAssignment.Type.Review).pop();
                    let finalReviewUser = record.assignments
                        .filter(item => item.type === ILabelPackageAssignment.Type.FinalReview)
                        .pop();
                    return (<div>
            {(labelUser === null || labelUser === void 0 ? void 0 : labelUser.isVisible) && (<Tag style={{ margin: "4px 4px 4px 0" }} key={labelUser.id} color={(labelUser && "#5bc5b1") || "#5bc5b1"} closable={labelUser && record.status === ILabelPackage.Status.Assigned} onClose={async () => {
                        await labelTaskPackagesStore.revokeLabelPackage(record === null || record === void 0 ? void 0 : record.id, labelUser === null || labelUser === void 0 ? void 0 : labelUser.userId, labelUser === null || labelUser === void 0 ? void 0 : labelUser.type);
                    }}>
                标注&nbsp;{labelUser.user.name}
              </Tag>)}
            {(reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.isVisible) && (<Tag style={{ margin: "4px 4px 4px 0" }} key={reviewUser.id} color={(reviewUser && "#87d068") || "#5bc5b1"} closable={reviewUser && record.status === ILabelPackage.Status.AssignReview} onClose={async () => {
                        await labelTaskPackagesStore.revokeLabelPackage(record === null || record === void 0 ? void 0 : record.id, reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.userId, reviewUser === null || reviewUser === void 0 ? void 0 : reviewUser.type);
                    }}>
                复审&nbsp;{reviewUser.user.name}
              </Tag>)}
            {(finalReviewUser === null || finalReviewUser === void 0 ? void 0 : finalReviewUser.isVisible) && (<Tag style={{ margin: "4px 4px 4px 0" }} key={finalReviewUser.id} color={(finalReviewUser && "#40a9ff") || "#5bc5b1"} closable={finalReviewUser && record.status === ILabelPackage.Status.AssignFinalReview} onClose={async () => {
                        await labelTaskPackagesStore.revokeLabelPackage(record === null || record === void 0 ? void 0 : record.id, finalReviewUser === null || finalReviewUser === void 0 ? void 0 : finalReviewUser.userId, finalReviewUser === null || finalReviewUser === void 0 ? void 0 : finalReviewUser.type);
                    }}>
                终审&nbsp;{finalReviewUser.user.name}
              </Tag>)}
          </div>);
                }
            },
            {
                title: "时间线",
                align: "center",
                key: "timeline",
                width: 100,
                render: (value, record) => {
                    return (<Button type="link" onClick={() => {
                        labelTaskPackagesStore.setStatus({
                            isTimelineVisible: true,
                            curTimelinePackage: record
                        });
                    }}>
            时间线
          </Button>);
                }
            },
            {
                title: "是否专家",
                align: "center",
                key: "type",
                width: 100,
                render: (value, record) => {
                    return record.type == ILabelPackage.Type.Extract ? "是" : "否";
                }
            },
            {
                title: "操作",
                fixed: "right",
                width: 420,
                render: (value, record) => {
                    var _a, _b;
                    const hasSegment = (_b = (_a = record.labelTask.label) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.length;
                    return (<div>
            <Button size="small" type="link" onClick={() => {
                        let dict = {
                            [IResource.Mime.Image]: `/label/image/label_task/${record.taskId}/label_package/${record.id}`,
                            [IResource.Mime.Video]: `/label/video/label_task/${record.taskId}/label_package/${record.id}`,
                            [IResource.Mime.Cloud]: `/label/image/label_task/${record.taskId}/label_package/${record.id}`
                        };
                        routerStore.push(dict[record.labelTask.resourceMime]);
                    }}>
              审查
            </Button>
            <TaskPackageAction record={record} callback={async () => {
                        await labelTaskPackagesStore.loadData();
                    }}/>
            <Divider type="vertical"/>
            <Dropdown menu={{
                        items: [
                            {
                                key: "1",
                                onClick() {
                                    labelTaskPackagesStore.downPackageAnnotationFile(record);
                                },
                                label: "分包标注文件"
                            },
                            (hasSegment || record.labelTask.taskType === 1) && {
                                key: "2",
                                onClick() {
                                    labelTaskPackagesStore.downPackageAnnotationFile(record, true);
                                },
                                label: "全量分包标注文件"
                            },
                            hasSegment && {
                                key: "3",
                                onClick() {
                                    labelTaskPackagesStore.downPackageAnnotationFile(record, true, true);
                                },
                                label: "全量分包标注文件(仅图片)"
                            },
                            record.labelTask.sandPackageId && {
                                key: "4",
                                onClick() {
                                    labelTaskPackagesStore.downPackageSandAnnotationFile(record);
                                },
                                label: "分包沙子标注文件"
                            },
                            record.labelTask.sandPackageId && {
                                key: "5",
                                onClick() {
                                    labelTaskPackagesStore.downPackageOriginSandAnnotationFile(record);
                                },
                                label: "原始沙子标注文件"
                            }
                        ]
                    }}>
              <Button size="small" type="link">
                下载
              </Button>
            </Dropdown>
          </div>);
                }
            }
        ];
    }
    componentDidMount() {
        labelTaskPackagesStore.status.labelTaskId = +this.props.match.params.labelTaskId;
        labelTaskPackagesStore.loadData();
    }
    render() {
        var _a;
        const status = labelTaskPackagesStore.status;
        return (<PageHeader title="分包列表" onBack={() => {
            labelTaskPackagesStore.resetStatus();
            routerStore.push("/manage/label_tasks");
        }} style={{ background: "#FFF" }}>
        <Card>
          <div style={{ fontSize: 20 }}>
            <span>当前任务名称:</span>
            <span style={{ marginLeft: 8 }}>{((_a = status.labelTask) === null || _a === void 0 ? void 0 : _a.name) || "-"}</span>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Dropdown menu={{
            items: [
                {
                    key: "1",
                    disabled: (status.selectedPackages.filter(labelPackage => labelPackage.type === ILabelPackage.Type.Normal)
                        .length > 0 &&
                        status.selectedPackages.filter(labelPackage => labelPackage.type === ILabelPackage.Type.Extract)
                            .length > 0) ||
                        status.selectedPackages.length === 0 ||
                        status.selectedPackages.filter(labelPackage => labelPackage.status !== ILabelPackage.Status.Unassigned).length > 0,
                    onClick() {
                        if (toJS(status.selectedPackageIds).length > 0) {
                            status.isAssignModalVisible = true;
                            status.assignMode = ILabelPackageAssignment.Type.Label;
                        }
                    },
                    label: "分配标注"
                },
                {
                    key: "2",
                    disabled: status.selectedPackages.length === 0 ||
                        status.selectedPackages.filter(labelPackage => labelPackage.status !== ILabelPackage.Status.Labeled).length > 0,
                    onClick() {
                        if (toJS(status.selectedPackageIds).length > 0) {
                            status.isAssignModalVisible = true;
                            status.assignMode = ILabelPackageAssignment.Type.Review;
                        }
                    },
                    label: "分配复审"
                },
                {
                    key: "3",
                    disabled: status.selectedPackages.length === 0 ||
                        status.selectedPackages.filter(labelPackage => labelPackage.status !== ILabelPackage.Status.Reviewed).length > 0,
                    onClick() {
                        if (toJS(status.selectedPackageIds).length > 0) {
                            status.isAssignModalVisible = true;
                            status.assignMode = ILabelPackageAssignment.Type.FinalReview;
                        }
                    },
                    label: "分配终审"
                }
            ]
        }}>
              <Button type="primary">批量分配</Button>
            </Dropdown>
          </div>
          <Table rowKey="id" bordered={true} scroll={{ x: true }} style={{ marginTop: "20px" }} columns={this.columns} dataSource={toJS(status.packages)} rowSelection={{
            getCheckboxProps: record => {
                return {
                    disabled: ![
                        ILabelPackage.Status.Unassigned,
                        ILabelPackage.Status.Labeled,
                        ILabelPackage.Status.Reviewed
                    ].includes(record.status)
                };
            },
            selectedRowKeys: status.selectedPackageIds,
            onChange: (selectedRowKeys, selectedRows) => {
                labelTaskPackagesStore.setStatus({
                    selectedPackageIds: selectedRowKeys,
                    selectedPackages: selectedRows
                });
            }
        }} pagination={{
            current: status.pager.getCurPage(),
            pageSize: status.pager.getPerPage(),
            showSizeChanger: false,
            onChange: (page, pageSize) => {
                labelTaskPackagesStore.setStatus({
                    pager: status.pager.setCurPage(page).setPerPage(pageSize)
                });
            }
        }}/>
        </Card>
        {status.isAssignModalVisible && (<AssignModal isVisible={status.isAssignModalVisible} onCancel={() => {
            labelTaskPackagesStore.setStatus({
                isAssignModalVisible: false
            });
        }} onOk={async (userId) => {
            if (!userId) {
                message.warning("分配人员不能为空");
                return;
            }
            await labelTaskPackagesStore.assignLabelPackage(userId);
        }}/>)}
        {status.isTimelineVisible && status.curTimelinePackage && (<Timeline timeline={status.curTimelinePackage.timeline} onCancel={() => {
            labelTaskPackagesStore.setStatus({
                isTimelineVisible: false,
                curTimelinePackage: null
            });
        }}/>)}
      </PageHeader>);
    }
};
LabelTaskPackages = __decorate([
    observer
], LabelTaskPackages);
export { LabelTaskPackages };
