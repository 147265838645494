var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import * as _ from "lodash";
import { action, computed, observable, toJS } from "mobx";
import BasicStore from "../../../../store/BasicStore";
import { Api } from "../../../../api/Api";
import { ISand } from "../../../../definition/entity/label-sand";
import { routerStore } from "../../../../store/routerStore";
import { isLabelTaskLabelEqual } from "../../../../util/label-task-compare";
import { message } from "antd";
class Status {
    constructor() {
        this.propertySet = new Set();
        this.propertySelected = [];
        this.sandFilename = "";
        this.displaySandUrls = [];
        this.labelFilename = "";
        this.label = undefined;
        this.name = "";
        this.sandMime = ISand.Mime.Image;
        this.sands = [];
        this.isCreatingLabelSandBags = false;
    }
}
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object)
], Status.prototype, "propertySet", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "propertySelected", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "sandFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Array)
], Status.prototype, "displaySandUrls", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "labelFilename", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], Status.prototype, "label", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], Status.prototype, "name", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof ISand !== "undefined" && ISand.Mime) === "function" ? _b : Object)
], Status.prototype, "sandMime", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], Status.prototype, "isCreatingLabelSandBags", void 0);
class LabelSandBagsCreateStore extends BasicStore {
    constructor() {
        super(...arguments);
        this.status = new Status();
    }
    resetStatus() {
        this.status = new Status();
    }
    changeSandFile(file) {
        this.status.sandFilename = file.name;
        const reader = new FileReader();
        reader.onload = (ev) => {
            const resultText = ev.target.result.trim();
            const sandArr = [];
            const tmpPropertySet = new Set();
            resultText.split("\n").forEach(line => {
                if (line && !(line.length === 1 && line.charCodeAt(0))) {
                    try {
                        const lineObj = JSON.parse(line);
                        Object.getOwnPropertyNames(lineObj).forEach(name => {
                            tmpPropertySet.add(name);
                        });
                        sandArr.push(lineObj);
                    }
                    catch (e) {
                        if (e instanceof SyntaxError) {
                            tmpPropertySet.add("url");
                            sandArr.push({
                                url: line
                            });
                        }
                    }
                }
            });
            this.status.sands = sandArr;
            this.status.displaySandUrls = sandArr.map(i => ({
                url: i.url
            }));
            this.status.propertySet = tmpPropertySet;
            this.status.propertySelected = _.filter(Array.from(tmpPropertySet), i => {
                return ["url", "meta", "annotation"].includes(i);
            });
        };
        reader.readAsText(file, "utf8");
    }
    removeSandFile() {
        this.status.sandFilename = null;
        this.status.sands = [];
        this.status.displaySandUrls = [];
        this.status.propertySet = new Set();
        this.status.propertySelected = [];
    }
    changeLabelFile(file) {
        this.status.labelFilename = file.name;
        const reader = new FileReader();
        reader.onload = (ev) => {
            this.status.label = ev.target.result;
        };
        reader.readAsText(file, "utf8");
    }
    removeLabelFile() {
        this.status.labelFilename = undefined;
        this.status.label = undefined;
    }
    async createLabelSandBagByUploaded() {
        var _a;
        this.status.isCreatingLabelSandBags = true;
        const { name, sandMime, sands, label } = this.status;
        for (let i = 0; i < sands.length; i++) {
            if (!this.checkIsLabelEqual((_a = sands[i]) === null || _a === void 0 ? void 0 : _a.annotation)) {
                this.status.isCreatingLabelSandBags = false;
                message.error("当前标签配置与沙子标签不一致");
                return;
            }
        }
        const createLabelSandBagRes = await Api.labelSandBags.createLabelSandBagByUploaded({
            name: name,
            label: JSON.parse(label),
            sandMime: sandMime,
            sands: sands.map(sand => {
                const res = {};
                this.status.propertySelected.forEach(key => {
                    res[key] = sand[key];
                });
                return res;
            })
        });
        this.status.isCreatingLabelSandBags = false;
        if (createLabelSandBagRes.code === 200) {
            routerStore.goBack();
        }
    }
    checkIsLabelEqual(curLabel) {
        return isLabelTaskLabelEqual(_.cloneDeep(toJS(curLabel)), _.cloneDeep(JSON.parse(this.status.label)), false);
    }
    get isEnableCreate() {
        const { name, displaySandUrls, label } = toJS(this.status);
        return name && displaySandUrls.length && label;
    }
}
__decorate([
    observable,
    __metadata("design:type", Object)
], LabelSandBagsCreateStore.prototype, "status", void 0);
__decorate([
    action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], LabelSandBagsCreateStore.prototype, "resetStatus", null);
__decorate([
    computed,
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], LabelSandBagsCreateStore.prototype, "isEnableCreate", null);
export const labelSandBagsCreateStore = new LabelSandBagsCreateStore();
