import * as React from "react";
import $ from "jquery";
import cls from "classnames";
import { Input, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./KeymapEditor.less";
export class KeymapEditor extends React.Component {
    constructor() {
        super(...arguments);
        this.ref = null;
        this.interval = null;
        this.state = {
            isEditing: false,
            keymapKey: ""
        };
        this.handleKeydown = event => {
            if (/^(([a-z])|([0-9]))$/.test(event.key)) {
                this.setState({
                    keymapKey: event.key
                });
            }
            return false;
        };
    }
    componentDidMount() {
        if (this.props.default) {
            this.props.default();
        }
        this.interval = setInterval(() => {
            $(this.ref).on("keydown", this.handleKeydown);
        }, 500);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
        $(this.ref).off("keydown", this.handleKeydown);
    }
    render() {
        const { isEditing, keymapKey } = this.state;
        return (<div>
        <div className={cls({
            "keymap-editor-comp": true,
            editing: isEditing
        })} onClick={e => {
            this.setState({
                isEditing: true
            });
            e.stopPropagation();
        }}>
          {this.props.keyStr ? (<span className="keymap-text">{this.props.keyStr}</span>) : (<EditOutlined className="keymap-text"/>)}
          <EditOutlined className="edit-icon"/>
        </div>
        <div onClick={e => e.stopPropagation()}>
          <Modal open={isEditing} title="修改快捷键" onCancel={() => {
            this.setState({
                isEditing: false
            });
        }} onOk={() => {
            this.props.onChange(keymapKey);
            this.setState({
                isEditing: false
            });
        }}>
            <div ref={ref => (this.ref = ref)}>
              <Input autoFocus={true} value={keymapKey}/>
            </div>
          </Modal>
        </div>
      </div>);
    }
}
