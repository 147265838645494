var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import cls from "classnames";
import { Button, Card, Collapse, Popover } from "antd";
import { QuestionCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { IconFont } from "../../../../../../component/IconFont/IconFont";
import { KeymapEditor } from "../../../ImageLabel/component/KeymapEditor/keymapEditor";
import { keymapStore } from "../../../ImageLabel/provider/keymap-store";
import { ClassificationTool, DetectionTool } from "../../../../../../definition/entity/label-task";
import { imageLabelMultiStore } from "../../ImageLabelMultiStore";
import { observer } from "mobx-react";
import "./LabelSelector.less";
let LabelSelector = class LabelSelector extends React.Component {
    handleClickClassificationLabel(key, optionKey) {
        imageLabelMultiStore.status.curClassification = {
            key: key,
            value: optionKey
        };
    }
    async handleUpdateClassificationFilter(updatedClassification) {
        imageLabelMultiStore.status.curClassificationFilter = updatedClassification;
        imageLabelMultiStore.status.pager = imageLabelMultiStore.status.pager.resetPage();
        imageLabelMultiStore.status.isLoading = true;
        await imageLabelMultiStore.loadImageInfo();
        imageLabelMultiStore.status.isLoading = false;
    }
    render() {
        var _a, _b, _c, _d;
        const status = imageLabelMultiStore.status;
        return (<div className="multi-label-selector-component">
        <Card title="标注工具" bodyStyle={{ padding: "8px" }}>
          {(((_b = (_a = status.selectableLabel) === null || _a === void 0 ? void 0 : _a.detections) === null || _b === void 0 ? void 0 : _b.length) > 0 || ((_d = (_c = status.selectableLabel) === null || _c === void 0 ? void 0 : _c.classifications) === null || _d === void 0 ? void 0 : _d.length) > 0) && (<Collapse className="label-selector-collapse" defaultActiveKey={["detection", "classification"]}>
              {status.selectableLabel.detections.length > 0 && (<Collapse.Panel key="detection" header="检测标签">
                  <div className="detections" style={{ cursor: "not-allowed" }}>
                    {status.selectableLabel.detections.map((label, index) => (<div key={label.key} className="label-item detection selectable">
                        {label.tool === DetectionTool.Rectangle && (<React.Fragment>
                            <IconFont type="icon-rectangle_fill" style={{ color: label.color, fontSize: "20px" }}/>
                            <span>{label.locale || label.key}</span>
                          </React.Fragment>)}

                        {label.tool === DetectionTool.Dot && (<React.Fragment>
                            <IconFont type="icon-dot" style={{ color: label.color, fontSize: "20px" }}/>
                            <span>{label.locale || label.key}</span>
                          </React.Fragment>)}

                        {label.tool === DetectionTool.Path && (<React.Fragment>
                            <IconFont type="icon-draw-polygon-solid" style={{ color: label.color, fontSize: "20px" }}/>
                            <span>{label.locale || label.key}</span>
                          </React.Fragment>)}
                      </div>))}
                  </div>
                </Collapse.Panel>)}
              {status.selectableLabel.classifications.length > 0 && (<Collapse.Panel key="classification" header="属性标签">
                  <div className="classifications">
                    {status.selectableLabel.classifications.map(label => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return (<div key={label.key} className="classification">
                        {label.tool === ClassificationTool.Switch && (<React.Fragment>
                            <div className="label-item">
                              <QuestionCircleOutlined style={{ fontSize: "20px" }}/>
                              <span>{label.locale || label.key}</span>
                              <Popover placement="right" title="分类查看" content={<div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: "4px" }}>
                                    <Button onClick={() => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === true) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: true
                    });
                }
            }}>
                                      {label.locale || label.key}&nbsp;true
                                    </Button>
                                    <Button onClick={() => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === false) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: false
                    });
                }
            }}>
                                      {label.locale || label.key}&nbsp;false
                                    </Button>
                                    <Button onClick={() => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === null) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: null
                    });
                }
            }}>
                                      未标注
                                    </Button>
                                  </div>}>
                                <UnorderedListOutlined />
                              </Popover>
                            </div>
                            <div className={cls({
                "label-item selectable": true,
                selected: ((_a = status.curClassification) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassification) === null || _b === void 0 ? void 0 : _b.value) === true,
                chosen: ((_c = status.curClassificationFilter) === null || _c === void 0 ? void 0 : _c.key) === label.key &&
                    ((_d = status.curClassificationFilter) === null || _d === void 0 ? void 0 : _d.value) === true
            })} onClick={() => this.handleClickClassificationLabel(label.key, true)} onContextMenu={event => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === true) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: true
                    });
                }
            }}>
                              <div />
                              <div>{label.locale || label.key}&nbsp;true</div>
                              <KeymapEditor keyStr={keymapStore.getKeyByActionTag(`classification_${label.key}_true`)} onChange={keyStr => {
                keymapStore.register({
                    key: keyStr,
                    actionTag: `classification_${label.key}_true`,
                    action: () => {
                        this.handleClickClassificationLabel(label.key, true);
                    }
                });
            }}/>
                            </div>
                            <div className={cls({
                "label-item selectable": true,
                selected: ((_e = status.curClassification) === null || _e === void 0 ? void 0 : _e.key) === label.key &&
                    ((_f = status.curClassification) === null || _f === void 0 ? void 0 : _f.value) === false,
                chosen: ((_g = status.curClassificationFilter) === null || _g === void 0 ? void 0 : _g.key) === label.key &&
                    ((_h = status.curClassificationFilter) === null || _h === void 0 ? void 0 : _h.value) === false
            })} onClick={() => this.handleClickClassificationLabel(label.key, false)} onContextMenu={event => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === false) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: false
                    });
                }
            }}>
                              <div />
                              <div>{label.locale || label.key}&nbsp;false</div>
                              <KeymapEditor keyStr={keymapStore.getKeyByActionTag(`classification_${label.key}_false`)} onChange={keyStr => {
                keymapStore.register({
                    key: keyStr,
                    actionTag: `classification_${label.key}_false`,
                    action: () => {
                        this.handleClickClassificationLabel(label.key, false);
                    }
                });
            }}/>
                            </div>
                          </React.Fragment>)}
                        {label.tool === ClassificationTool.Radio && (<React.Fragment>
                            <div className="label-item">
                              <QuestionCircleOutlined style={{ fontSize: "20px" }}/>
                              <span>{label.locale || label.key}</span>
                              <Popover placement="right" title="分类查看" content={<div style={{ display: "grid", gridTemplateColumns: "1fr", gridRowGap: "4px" }}>
                                    {label.options.map(option => (<Button key={option.key} onClick={() => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === option.key) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: option.key
                    });
                }
            }}>
                                        {option.locale || option.key}
                                      </Button>))}
                                    <Button onClick={() => {
                var _a, _b;
                if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                    ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === null) {
                    this.handleUpdateClassificationFilter(null);
                }
                else {
                    this.handleUpdateClassificationFilter({
                        key: label.key,
                        value: null
                    });
                }
            }}>
                                      未标注
                                    </Button>
                                  </div>}>
                                <UnorderedListOutlined />
                              </Popover>
                            </div>
                            <div style={{ display: "list-item" }}>
                              {label.options.map(option => {
                var _a, _b, _c, _d;
                return (<div key={option.key} className={cls({
                    "label-item selectable": true,
                    selected: ((_a = status.curClassification) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                        ((_b = status.curClassification) === null || _b === void 0 ? void 0 : _b.value) === option.key,
                    chosen: ((_c = status.curClassificationFilter) === null || _c === void 0 ? void 0 : _c.key) === label.key &&
                        ((_d = status.curClassificationFilter) === null || _d === void 0 ? void 0 : _d.value) === option.key
                })} onClick={() => this.handleClickClassificationLabel(label.key, option.key)} onContextMenu={event => {
                    var _a, _b;
                    if (((_a = status.curClassificationFilter) === null || _a === void 0 ? void 0 : _a.key) === label.key &&
                        ((_b = status.curClassificationFilter) === null || _b === void 0 ? void 0 : _b.value) === option.key) {
                        this.handleUpdateClassificationFilter(null);
                    }
                    else {
                        this.handleUpdateClassificationFilter({
                            key: label.key,
                            value: option.key
                        });
                    }
                }}>
                                  <IconFont type="icon-rectangle_fill" style={{ color: option === null || option === void 0 ? void 0 : option.color, fontSize: "20px" }}/>
                                  <div>{option.locale || option.key}</div>
                                  <KeymapEditor keyStr={keymapStore.getKeyByActionTag(`classification_${label.key}_${option.key}`)} onChange={keyStr => {
                    keymapStore.register({
                        key: keyStr,
                        actionTag: `classification_${label.key}_${option.key}`,
                        action: () => {
                            this.handleClickClassificationLabel(label.key, option.key);
                        }
                    });
                }}/>
                                </div>);
            })}
                            </div>
                          </React.Fragment>)}
                      </div>);
        })}
                  </div>
                </Collapse.Panel>)}
            </Collapse>)}
        </Card>
      </div>);
    }
};
LabelSelector = __decorate([
    observer
], LabelSelector);
export { LabelSelector };
