var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import * as _ from "lodash";
import moment from "moment";
import { routerStore } from "../../../../store/routerStore";
import { Button, Card, Form, PageHeader, Divider, Upload, Modal, Spin, Table, Tag } from "antd";
import { Controlled as CodeMirror } from "react-codemirror2";
import { observer } from "mobx-react";
import { labellingAssessmentPolicyDetailStore } from "./LabellingAssessmentPolicyDetailStore";
import { Loading } from "../../../../component/Loading/Loading";
import "codemirror/lib/codemirror.css";
import "codemirror/lib/codemirror.js";
import "codemirror/theme/blackboard.css";
import "codemirror/mode/python/python.js";
import "./LabellingAssessmentPolicyDetail.less";
import "codemirror/addon/selection/active-line.js";
import { toJS } from "mobx";
import { UploadOutlined } from "@ant-design/icons";
let LabellingAssessmentPolicyDetail = class LabellingAssessmentPolicyDetail extends React.Component {
    constructor() {
        super(...arguments);
        this.editorRef = null;
    }
    async componentDidMount() {
        const policyId = +this.props.match.params.policyId;
        await labellingAssessmentPolicyDetailStore.loadData(policyId);
        await labellingAssessmentPolicyDetailStore.getHistoryAssessmentPolicyVerification();
    }
    render() {
        const status = labellingAssessmentPolicyDetailStore.status;
        return (<PageHeader className="labelling-assessment-policy-detail" title="评估方法详情" style={{ background: "#FFF" }} onBack={() => {
            routerStore.push("/manage/labelling_assessment_policies");
        }}>
        <Card>
          {status.isLoading ? (<Loading />) : (<div style={{
            display: "grid",
            gridTemplateColumns: "40% 60%",
            gap: "10px"
        }}>
              <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <Divider orientation="left">基础信息</Divider>
                <Form.Item label="方法名称">
                  <span className="ant-form-text">{status.LabellingAssessmentPolicy.name}</span>
                </Form.Item>
                <Form.Item label="当前版本">
                  <span className="ant-form-text">{status.LabellingAssessmentPolicy.version}</span>
                </Form.Item>
                <Form.Item label="Tags">
                  <span className="ant-form-text">
                    {status.LabellingAssessmentPolicy.tags.map((tag, index) => (<Tag color="blue" key={index}>
                        {tag}
                      </Tag>))}
                  </span>
                </Form.Item>
                <Form.Item label="Commit ID">
                  <span className="ant-form-text">{status.LabellingAssessmentPolicy.commitId}</span>
                </Form.Item>
                <Form.Item label="描述">
                  <span className="ant-form-text">{status.LabellingAssessmentPolicy.remark}</span>
                </Form.Item>
                <Form.Item label="历史版本信息"></Form.Item>
                <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                  <Table bordered={true} rowKey="id" columns={[
            {
                title: "日期",
                dataIndex: "createdAt",
                width: "120px",
                render: (value, record) => {
                    return moment(record.createdAt).format("YYYY-MM-DD");
                }
            },
            { title: "Version", dataIndex: "version", align: "center", width: "120px" },
            { title: "Commit ID", dataIndex: "commitId", ellipsis: true }
        ]} dataSource={toJS(status.historyLabellingAssessmentPolicies)} pagination={{ showSizeChanger: false }}/>
                </Form.Item>
              </Form>
              <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                <Divider orientation="left">测试用例及评估脚本</Divider>
                <Form.Item label="基准标注">
                  <Upload fileList={status.groundTruthExampleFilelist} beforeUpload={file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = result => {
                status.groundTruthExample = result.target.result;
            };
            return false;
        }} onChange={info => {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            status.groundTruthExampleFilelist = fileList;
        }} onRemove={file => {
            status.groundTruthExample = status.LabellingAssessmentPolicy.groundTruthExample;
        }}>
                    <Button>
                      <UploadOutlined /> 替换 jsonlist 文件
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="待评价标注">
                  <Upload fileList={status.assessingExampleFilelist} beforeUpload={file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = result => {
                status.assessingExample = result.target.result;
            };
            return false;
        }} onChange={info => {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            status.assessingExampleFilelist = fileList;
        }} onRemove={file => {
            status.assessingExample = status.LabellingAssessmentPolicy.assessingExample;
        }}>
                    <Button>
                      <UploadOutlined /> 替换 jsonlist 文件
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="评估阈值">
                  <Upload fileList={status.assessingThresholdExampleFilelist} accept=".json" beforeUpload={file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = result => {
                status.assessingThresholdExample = result.target.result;
            };
            return false;
        }} onChange={info => {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            status.assessingThresholdExampleFilelist = fileList;
        }} onRemove={file => {
            status.assessingThresholdExample = status.LabellingAssessmentPolicy.assessingThresholdExample;
        }}>
                    <Button>
                      <UploadOutlined /> 替换 JSON 文件
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="评估脚本" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                  <Upload fileList={status.policyScriptFilelist} accept=".py" beforeUpload={file => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = result => {
                status.policyScript = result.target.result;
            };
            return false;
        }} showUploadList={false} onChange={info => {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            status.policyScriptFilelist = fileList;
        }}>
                    <Button>
                      <UploadOutlined /> 替换 Python 脚本
                    </Button>
                  </Upload>
                  {!_.isNil(status.policyScript) && (<CodeMirror className="code-mirror" value={status.policyScript} options={{
            mode: "text/x-python",
            theme: "blackboard",
            lineNumbers: true,
            styleActiveLine: true
        }} onBeforeChange={(editor, data, value) => {
            status.policyScript = value;
        }}/>)}
                </Form.Item>
                <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                  <div style={{ float: "right" }}>
                    <Button disabled={!labellingAssessmentPolicyDetailStore.isEnableAssessment || status.isAssessing} onClick={() => {
            labellingAssessmentPolicyDetailStore.temporaryVerify();
        }}>
                      验证
                    </Button>
                    <Button style={{ marginLeft: 10 }} disabled={!status.assessingExampleResult} onClick={() => {
            status.isShowAssessingResultModal = true;
        }}>
                      日志
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>)}
        </Card>
        <Modal title="验证结果" width={800} open={status.isShowAssessingResultModal} footer={null} maskClosable={false} onCancel={() => {
            status.isShowAssessingResultModal = false;
        }}>
          <pre>{status.assessingExampleResult}</pre>
        </Modal>
        
        {status.isAssessing && (<div style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#000000",
            opacity: 0.5,
            display: "grid",
            gridTemplateColumns: "1fr",
            justifyItems: "center",
            alignItems: "center",
            zIndex: 999
        }}>
            <Spin tip="加载中"/>
          </div>)}
      </PageHeader>);
    }
};
LabellingAssessmentPolicyDetail = __decorate([
    observer
], LabellingAssessmentPolicyDetail);
export { LabellingAssessmentPolicyDetail };
